<template>
    <div v-if="!loading">
        <i
            class="material-icons"
            v-on:click.prevent="$emit('edit')"
            v-if="isEdit"
        >edit</i>
        <i
            class="material-icons"
            v-on:click.prevent="$emit('delete')"
            v-if="isDelete"
        >delete</i>
        <i
            class="material-icons is-pointer"
            v-on:click.prevent="$emit('cancel')"
            v-if="isCancel"
        >close</i>
        <i
            class="material-icons is-pointer"
            v-on:click.prevent="$emit('submit')"
            v-if="isSubmit"
        >check</i>
    </div>

    <i class="material-icons icon-spin" v-else>refresh</i>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        isEdit: function () {
            return !!this.$listeners.edit;
        },

        isDelete: function () {
            return !!this.$listeners.delete;
        },

        isCancel: function () {
            return !!this.$listeners.cancel;
        },

        isSubmit: function () {
            return !!this.$listeners.submit;
        }
    }
}
</script>

