var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Zugpreis bearbeiten",
        createTitle: "Neuen Zugpreis hinzufügen",
      },
    },
    [
      _c(
        "table",
        [
          _vm.initialValue
            ? [
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label" }, [_vm._v("Preis:")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "toggle-switch",
                        {
                          model: {
                            value: _vm.priceType,
                            callback: function ($$v) {
                              _vm.priceType = $$v
                            },
                            expression: "priceType",
                          },
                        },
                        [
                          _c("option", { attrs: { value: "calculation" } }, [
                            _vm._v("Kalkulation"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "provider" } }, [
                            _vm._v("Anbieter"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "agency" } }, [
                            _vm._v("Agentur"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.priceType === "agency"
                  ? _c("tr", [
                      _c("th", [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Agentur:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("async-select", {
                            attrs: { api: "agencies" },
                            model: {
                              value: _vm.agency,
                              callback: function ($$v) {
                                _vm.agency = $$v
                              },
                              expression: "agency",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.priceType === "provider" || _vm.priceType === "agency"
                  ? _c("tr", [
                      _c("th", [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Zuggesellschaft:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("async-select", {
                            attrs: { api: "trains" },
                            model: {
                              value: _vm.train,
                              callback: function ($$v) {
                                _vm.train = $$v
                              },
                              expression: "train",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [_vm._v("Bezeichnung:")]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "toggle-switch",
                  {
                    model: {
                      value: _vm.ownName,
                      callback: function ($$v) {
                        _vm.ownName = $$v
                      },
                      expression: "ownName",
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v("Individuell"),
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v("Automatisch (nach Datum)"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.ownName
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Eigene Bezeichnung:"),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "control" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.name,
                          expression: "form.name",
                        },
                      ],
                      staticClass: "input",
                      attrs: { type: "text", id: "name" },
                      domProps: { value: _vm.form.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "name", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("th", { staticClass: "is-top" }, [
              _c("label", { staticClass: "label" }, [
                _vm._v("\n                    Zeitraum:\n                    "),
                _vm.canAddPeriod
                  ? _c(
                      "i",
                      {
                        staticClass: "material-icons is-pointer",
                        on: { click: _vm.addPeriod },
                      },
                      [_vm._v("add_circle")]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              _vm._l(_vm.timePeriods, function (period) {
                return _c(
                  "div",
                  { key: period.key, staticClass: "level-left" },
                  [
                    _c(
                      "div",
                      { staticClass: "flatpickr-combined level-item" },
                      [
                        _c("input-date", {
                          staticClass: "is-small",
                          model: {
                            value: period.startAt,
                            callback: function ($$v) {
                              _vm.$set(period, "startAt", $$v)
                            },
                            expression: "period.startAt",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("-")]),
                        _vm._v(" "),
                        _c("input-date", {
                          staticClass: "is-small",
                          model: {
                            value: period.endAt,
                            callback: function ($$v) {
                              _vm.$set(period, "endAt", $$v)
                            },
                            expression: "period.endAt",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "level-item" },
                      [
                        _c("days", {
                          attrs: { picker: "" },
                          model: {
                            value: period.days,
                            callback: function ($$v) {
                              _vm.$set(period, "days", $$v)
                            },
                            expression: "period.days",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.timePeriods.length > 1
                      ? _c(
                          "i",
                          {
                            staticClass: "material-icons is-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.deletePeriod(period)
                              },
                            },
                          },
                          [_vm._v("delete")]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("service-day-time-field", {
            attrs: {
              label: "Gültig für",
              fields: ["startTimeOfDay", "endTimeOfDay", "checkInTimeOfDay"],
              "predefined-day-times": _vm.predefinedDayTimes,
            },
            model: {
              value: _vm.form.serviceDayTimes,
              callback: function ($$v) {
                _vm.$set(_vm.form, "serviceDayTimes", $$v)
              },
              expression: "form.serviceDayTimes",
            },
          }),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [
              _c("h2", [_vm._v("Preise")]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [_vm._v("Überprüft:")]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("import-checked-field", {
                  model: {
                    value: _vm.importChecked,
                    callback: function ($$v) {
                      _vm.importChecked = $$v
                    },
                    expression: "importChecked",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [_vm._v("Währung:")]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("multiselect", {
                  staticStyle: { width: "70px" },
                  attrs: {
                    options: _vm.currencies,
                    "allow-empty": false,
                    placeholder: "",
                  },
                  model: {
                    value: _vm.currency,
                    callback: function ($$v) {
                      _vm.currency = $$v
                    },
                    expression: "currency",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.form.route && _vm.form.route.type === "night"
            ? [
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getAccommodationLabel(
                            "second",
                            2,
                            _vm.allCabinTypes
                          )
                        ) + ":"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("input-price", {
                        staticClass: "is-medium",
                        attrs: { placeholder: "0,00", prefix: _vm.currency },
                        model: {
                          value: _vm.doubleSecond,
                          callback: function ($$v) {
                            _vm.doubleSecond = $$v
                          },
                          expression: "doubleSecond",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getAccommodationLabel(
                            "second",
                            1,
                            _vm.allCabinTypes
                          )
                        ) + ":"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("input-price", {
                        staticClass: "is-medium",
                        attrs: { placeholder: "0,00", prefix: _vm.currency },
                        model: {
                          value: _vm.singleSecond,
                          callback: function ($$v) {
                            _vm.singleSecond = $$v
                          },
                          expression: "singleSecond",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.form.route && _vm.form.route.type !== "night"
            ? [
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getAccommodationLabel(
                            "second",
                            1,
                            _vm.allCabinTypes
                          )
                        ) + ":"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("input-price", {
                        staticClass: "is-medium",
                        attrs: { placeholder: "0,00", prefix: _vm.currency },
                        model: {
                          value: _vm.singleSecond,
                          callback: function ($$v) {
                            _vm.singleSecond = $$v
                          },
                          expression: "singleSecond",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.firstClass
                  ? _c("tr", [
                      _c("th", [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.getAccommodationLabel(
                                "first",
                                1,
                                _vm.allCabinTypes
                              )
                            ) + ":"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("input-price", {
                            staticClass: "is-medium",
                            attrs: {
                              placeholder: "0,00",
                              prefix: _vm.currency,
                            },
                            model: {
                              value: _vm.singleFirst,
                              callback: function ($$v) {
                                _vm.singleFirst = $$v
                              },
                              expression: "singleFirst",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.threeBedCabins
            ? [
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getAccommodationLabel(
                            "second",
                            3,
                            _vm.allCabinTypes
                          )
                        ) + ":"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "level-left" }, [
                      _c(
                        "div",
                        { staticClass: "level-item" },
                        [
                          _c("input-price", {
                            staticClass: "is-medium",
                            attrs: {
                              placeholder: "0,00",
                              prefix: _vm.currency,
                            },
                            model: {
                              value: _vm.tripleSecond,
                              callback: function ($$v) {
                                _vm.tripleSecond = $$v
                              },
                              expression: "tripleSecond",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.fourBedCabins
            ? [
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getAccommodationLabel(
                            "second",
                            4,
                            _vm.allCabinTypes
                          )
                        ) + ":"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "level-left" }, [
                      _c(
                        "div",
                        { staticClass: "level-item" },
                        [
                          _c("input-price", {
                            staticClass: "is-medium",
                            attrs: {
                              placeholder: "0,00",
                              prefix: _vm.currency,
                            },
                            model: {
                              value: _vm.quadrupleSecond,
                              callback: function ($$v) {
                                _vm.quadrupleSecond = $$v
                              },
                              expression: "quadrupleSecond",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.firstClass && _vm.form.route && _vm.form.route.type === "night"
            ? [
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getAccommodationLabel(
                            "first",
                            2,
                            _vm.allCabinTypes
                          )
                        ) + ":"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "level-left" }, [
                      _c(
                        "div",
                        { staticClass: "level-item" },
                        [
                          _c("input-price", {
                            staticClass: "is-medium",
                            attrs: {
                              placeholder: "0,00",
                              prefix: _vm.currency,
                            },
                            model: {
                              value: _vm.doubleFirst,
                              callback: function ($$v) {
                                _vm.doubleFirst = $$v
                              },
                              expression: "doubleFirst",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getAccommodationLabel(
                            "first",
                            1,
                            _vm.allCabinTypes
                          )
                        ) + ":"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "level-left" }, [
                      _c(
                        "div",
                        { staticClass: "level-item" },
                        [
                          _c("input-price", {
                            staticClass: "is-medium",
                            attrs: {
                              placeholder: "0,00",
                              prefix: _vm.currency,
                            },
                            model: {
                              value: _vm.singleFirst,
                              callback: function ($$v) {
                                _vm.singleFirst = $$v
                              },
                              expression: "singleFirst",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm.threeBedCabins
                  ? _c("tr", [
                      _c("th", [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.getAccommodationLabel(
                                "first",
                                3,
                                _vm.allCabinTypes
                              )
                            ) + ":"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("div", { staticClass: "level-left" }, [
                          _c(
                            "div",
                            { staticClass: "level-item" },
                            [
                              _c("input-price", {
                                staticClass: "is-medium",
                                attrs: {
                                  placeholder: "0,00",
                                  prefix: _vm.currency,
                                },
                                model: {
                                  value: _vm.tripleFirst,
                                  callback: function ($$v) {
                                    _vm.tripleFirst = $$v
                                  },
                                  expression: "tripleFirst",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.fourBedCabins
                  ? _c("tr", [
                      _c("th", [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.getAccommodationLabel(
                                "first",
                                4,
                                _vm.allCabinTypes
                              )
                            ) + ":"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("div", { staticClass: "level-left" }, [
                          _c(
                            "div",
                            { staticClass: "level-item" },
                            [
                              _c("input-price", {
                                staticClass: "is-medium",
                                attrs: {
                                  placeholder: "0,00",
                                  prefix: _vm.currency,
                                },
                                model: {
                                  value: _vm.quadrupleFirst,
                                  callback: function ($$v) {
                                    _vm.quadrupleFirst = $$v
                                  },
                                  expression: "quadrupleFirst",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.threeBedCabins &&
          _vm.form.route &&
          _vm.form.route.type === "night"
            ? _c("tr", [
                _c("th"),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.threeBedCabins = true
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("add_circle"),
                      ]),
                      _vm._v(" 3-Bett Zugabteil\n                "),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.fourBedCabins &&
          _vm.form.route &&
          _vm.form.route.type === "night"
            ? _c("tr", [
                _c("th"),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.fourBedCabins = true
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("add_circle"),
                      ]),
                      _vm._v(" 4-Bett Zugabteil\n                "),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.firstClass
            ? _c("tr", [
                _c("th"),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.firstClass = true
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("add_circle"),
                      ]),
                      _vm._v(" 1. Klasse\n                "),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.existingPrices.length > 0
            ? _c("tr", [
                _c("td", { staticClass: "alert is-danger" }),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "alert is-danger" }, [
                    _c("p", [_c("strong", [_vm._v("Preise überschreiben?")])]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                        Im angegebenen Preise existieren bereits folgende Preise, die beim erneuten Klick auf Speichern überschrieben werden:\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      _vm._l(_vm.existingPrices, function (price) {
                        return _c("li", [_vm._v(_vm._s(_vm.priceTitle(price)))])
                      }),
                      0
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }