<template>

    <div class="has-margin-top">
        <div class="bp-panel has-no-title" style="min-height: auto">
            <div class="panel-content">
                <div class="columns has-seperating-borders">
                    <div class="column" v-for="website in websites">
                        <div class="field">
                            <div class="control">
                                <label class="checkbox">
                                    <input type="checkbox" v-bind:value="website.id" v-model="form.websites">
                                    {{website.url}}
                                </label>
                            </div>
                            <p class="is-small-text"
                               :class="{
                                'is-turqoise-text': !!getPublishDate(website.id),
                                'is-text-red': !getPublishDate(website.id)
                            }"
                            >{{getPublishDate(website.id) ? getPublishDate(website.id) : 'noch nicht veröffentlicht'}}</p>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <div class="control">
                                <button
                                   @click="publish"
                                    class="button is-smaller">
                                    <i class="material-icons">public</i> Veröffentlichen
                                </button>
                            </div>
                            <p class="is-small-text">letzte Datenänderung am {{updatedAt}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>

import moment from 'moment';
import {dateISOtoView, dateWithLocalTime} from "@utilities/functions";
import Mixin from '../../tab';
import {getWebflowHomepages} from "@api";


//todo: check when system changes updatedAt


export default {
    mixins: [ Mixin ],

    props: {
        component: {
            type: Object,
            required: true,
        }
    },

    data: function() {
        return {
            form: {
                websites: []
            },
            websites: [],
        }
    },

    methods: {
        getWebflowCollection: function(homepageId) {
            let website = this.websites.find(website => website.id === homepageId);
            if(!website || !website.webflowCollections){
                return null;
            }
            return website.webflowCollections.find(collection => collection.name === this.component.props.collectionName)
        },

        getWebflowLink: function(homepageId) {
            if(!this.item.webflowLinks){
                return null;
            }
            return this.item.webflowLinks.find(link => link.webflowCollection && link.webflowCollection.name === this.component.props.collectionName && link.webflowCollection.webflowHomepage.id === homepageId);
        },

        getPublishDate: function (homepageId) {

            let link = this.getWebflowLink(homepageId);
            return link && link.publishedAt ? 'veröffentlicht am ' + dateISOtoView(link.publishedAt) : null
        },

        publish: function () {
            this.$emit('saveItem', 'webflowLinks', this.websites.filter(website => website.webflowCollections.length > 0).map(website => ({
                webflowCollection: '/api/webflow_collections/' + this.getWebflowCollection(website.id).id,
                ...this.api === 'order_concepts' && {orderConcept: '/api/' + this.api + '/' + this.item.id},
                ...this.api === 'day_concepts' && {dayConcept: '/api/' + this.api + '/' + this.item.id},
                ...this.api === 'hotels' && {hotel: '/api/' + this.api + '/' + this.item.id},
                ...this.api === 'destinations' && {destination: '/api/' + this.api + '/' + this.item.id},
                ...this.form.websites.indexOf(website.id) === -1 ? {publishedAt: null} : {publishedAt:  moment(new Date()).format('DD.MM.YYYY HH:mm:ss')},
            })), true, true);

        }
    },

    computed: {
        updatedAt: function () {
            return this.item.updatedAt ? dateISOtoView(this.item.updatedAt) : '-';
        },
    },

    mounted: function () {
        this.item.webflowLinks.forEach(link => {
              if(link.publishedAt){
                  this.form.websites.push(link.webflowCollection.webflowHomepage.id)
              }
        })
        getWebflowHomepages({'_groups[]': 'webflow_homepage:webflow_collection'}).then(response => {
            this.websites = response.data;
        });
    },

}
</script>
