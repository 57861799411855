<template>
    <span v-if="value">{{roomType}}</span>
</template>

<script>
import find from 'lodash/find';
import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        roomType: function () {
            const { persons, type } = this.value;
            const roomType = find(this.options.roomType, {
                persons,
                type
            });

            if (roomType) {
                return roomType.name;
            }
        }
    }
}
</script>

