var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "crud-table",
    {
      ref: "table",
      attrs: {
        api: "users",
        storeKey: `clients/${_vm.item.id}/organisations`,
        filter: { "organisations.id": _vm.item.id },
        loadOptions: false,
        label: "Stammdaten",
        name: { singular: "user", plural: "users" },
        "delete-row": false,
        columns: ["username", "firstName", "lastName", "email", "lastLogin"],
      },
    },
    [
      _c("h2", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("REISEÜBERSICHT"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }