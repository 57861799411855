<template>
    <tiny-mce v-model="catalogText" />
</template>

<script>
import debounce from 'lodash/debounce'
import { TinyMce }        from '@components/form';


import { notifySuccess, notifyError } from '@components/Notification';

import Mixin from './tab';

export default {

    mixins: [ Mixin ],

    data() {
        return {
            catalogText: ''
        }
    },

    activated() {
        this.catalogText = this.item.catalogText
    },

    methods: {
        storeCatalogText: debounce( function (data) {
            this.$store.dispatch('storeProperty', {key: 'catalogText', data, update: false})
                .then(() => notifySuccess('Der Katalogtext wurde aktualisiert!'), error => notifyError('Der Katalogtext konnte nicht gespeichert werden!'))
        }, 1000),
    },

    watch: {
        'catalogText': function (value) {
            this.storeCatalogText(value);
        },
    },

    components: {
        TinyMce
    }
}
</script>
