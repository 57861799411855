var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Kasse oder Konto bearbeiten",
        createTitle: "Kasse oder Konto anlegen",
      },
    },
    [
      _c(
        "table",
        [
          _c("tr", [
            _c("th", [_c("label", { staticClass: "label" }, [_vm._v("Art:")])]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "toggle-switch",
                  {
                    model: {
                      value: _vm.form.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "type", $$v)
                      },
                      expression: "form.type",
                    },
                  },
                  [
                    _c("option", { attrs: { value: "bank_account" } }, [
                      _vm._v("Bankonto"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "option",
                      { attrs: { value: "equity_holder_account" } },
                      [_vm._v("Aktionärskonto")]
                    ),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "cash_account" } }, [
                      _vm._v("Kasse"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "name" } }, [
                _vm._v("Bezeichnung:"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.name,
                      expression: "form.name",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", id: "name" },
                  domProps: { value: _vm.form.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "name", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "shortName" } },
                [_vm._v("Kurzname:")]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.shortName,
                      expression: "form.shortName",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", id: "shortName" },
                  domProps: { value: _vm.form.shortName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "shortName", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.form.type === "cash_account"
            ? [
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Unterkasse:"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "toggle-switch",
                        {
                          model: {
                            value: _vm.subFund,
                            callback: function ($$v) {
                              _vm.subFund = $$v
                            },
                            expression: "subFund",
                          },
                        },
                        [
                          _c("option", { domProps: { value: true } }, [
                            _vm._v("Ja"),
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: false } }, [
                            _vm._v("Nein"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.subFund
                  ? _c("tr", [
                      _c("th", [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Hauptkasse:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c(
                            "error-wrapper",
                            {
                              attrs: {
                                message: _vm.errors.mainOrganisationBankAccount,
                              },
                            },
                            [
                              _c("async-select", {
                                attrs: {
                                  api: "organisation_bank_accounts",
                                  id: "organisationBankAccounts",
                                },
                                model: {
                                  value: _vm.form.mainOrganisationBankAccount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "mainOrganisationBankAccount",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.mainOrganisationBankAccount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            : [
                _c("tr", [
                  _c("th", [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "accountHolder" } },
                      [_vm._v("Kontoinhaber:")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.accountHolder,
                            expression: "form.accountHolder",
                          },
                        ],
                        staticClass: "input",
                        attrs: { type: "text", id: "accountHolder" },
                        domProps: { value: _vm.form.accountHolder },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "accountHolder",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "iban" } },
                      [_vm._v("IBAN:")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.iban,
                            expression: "form.iban",
                          },
                        ],
                        staticClass: "input",
                        attrs: { type: "text", id: "iban" },
                        domProps: { value: _vm.form.iban },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "iban", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label" }, [_vm._v("Bank:")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "error-wrapper",
                        { attrs: { message: _vm.errors.bank } },
                        [
                          _c("async-select", {
                            attrs: { api: "banks", id: "bank" },
                            model: {
                              value: _vm.form.bank,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "bank", $$v)
                              },
                              expression: "form.bank",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
          _vm._v(" "),
          !_vm.form.id && _vm.form.balance
            ? _c("tr", [
                _c("th", [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "currency" } },
                    [_vm._v("Währung:")]
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "control" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.balance.currency,
                          expression: "form.balance.currency",
                        },
                      ],
                      staticClass: "input",
                      attrs: { type: "text", id: "currency" },
                      domProps: { value: _vm.form.balance.currency },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.form.balance,
                            "currency",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.form.id && _vm.form.balance
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Kontostand:"),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("input-price", {
                        attrs: { placeholder: "0,00" },
                        model: {
                          value: _vm.form.balance.amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.balance, "amount", $$v)
                          },
                          expression: "form.balance.amount",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [
                _vm._v("Mehrere Währungen:"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "toggle-switch",
                  {
                    model: {
                      value: _vm.form.showMultipleCurrencies,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "showMultipleCurrencies", $$v)
                      },
                      expression: "form.showMultipleCurrencies",
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [_vm._v("ja")]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v("nein"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [
                _vm._v("Buchhaltungskonto:"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "error-wrapper",
                  { attrs: { message: _vm.errors.accountPlanItem } },
                  [
                    _c("async-select", {
                      attrs: {
                        api: "account_plan_items",
                        "custom-label": (itemNumber) =>
                          itemNumber.accountingNumber +
                          " (" +
                          itemNumber.name +
                          ")",
                        placeholder: "nach Nummer oder Konto suchen",
                      },
                      model: {
                        value: _vm.form.accountPlanItem,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "accountPlanItem", $$v)
                        },
                        expression: "form.accountPlanItem",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.form.type === "bank_account"
            ? [
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("h2", [
                      _vm._v("Schecks "),
                      _c(
                        "i",
                        {
                          staticClass: "material-icons",
                          on: { click: _vm.addRow },
                        },
                        [_vm._v("add_circle")]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm._l(_vm.form.checkbooks, function (row) {
                  return _c("tr", [
                    _c("th", [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("Name / Laufende Nr:"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("div", { staticClass: "columns" }, [
                        _c("div", { staticClass: "column" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: row.name,
                                expression: "row.name",
                              },
                            ],
                            staticClass: "input",
                            attrs: { placeholder: "Bezeichnung" },
                            domProps: { value: row.name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(row, "name", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "column" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: row.currentNumber,
                                expression: "row.currentNumber",
                              },
                            ],
                            staticClass: "input",
                            attrs: { placeholder: "Laufende Nr" },
                            domProps: { value: row.currentNumber },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  row,
                                  "currentNumber",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "column is-narrow",
                            staticStyle: { "flex-direction": "row" },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn-delete",
                                attrs: { type: "button", title: "Löschen" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeRow(row)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("delete"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }