var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("crud-table", {
    ref: "table",
    attrs: {
      api: "other_services",
      url: _vm.url,
      name: { singular: "anbieter", plural: "anbieter" },
      filter: {
        "otherServiceType.id": _vm.parent.id,
        "provider[existsOr]": true,
        "agency[existsOr]": true,
      },
      columns: [
        { key: "serviceProvider", label: "Leistungsträger" },
        "otherServiceType.name",
        "otherServiceType.routeDescription",
        { key: "location", label: "Ort/Raum/Destination" },
      ],
    },
    on: { add: _vm.openItemForm },
    scopedSlots: _vm._u(
      [
        {
          key: "actions",
          fn: function (row) {
            return _vm.url(row)
              ? _c("a", { attrs: { href: _vm.url(row), target: "_blank" } }, [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("launch"),
                  ]),
                ])
              : _vm._e()
          },
        },
        {
          key: "form",
          fn: function ({ options }) {
            return _c("item-form", {
              ref: "itemForm",
              attrs: { options: _vm.formOptions(options), parent: _vm.parent },
              on: { submit: _vm.storeProvider },
            })
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }