var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "cell" }, [_vm._v("Buspreis")]),
      _vm._v(" "),
      _c("div", { staticClass: "cell" }),
      _vm._v(" "),
      _c("div", { staticClass: "cell" }, [_vm._v("1")]),
      _vm._v(" "),
      _c("div", { staticClass: "cell" }, [
        _vm._v(
          _vm._s(
            _vm._f("priceView")(_vm.calculations.contingent.busPrice.amount)
          )
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cell" }, [
        _c("b", [
          _vm._v(
            _vm._s(
              _vm._f("priceView")(_vm.calculations.contingent.busPrice.amount)
            )
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "cell" }, [_vm._v("Steuern - Bus")]),
      _vm._v(" "),
      _c("div", { staticClass: "cell" }),
      _vm._v(" "),
      _c("div", { staticClass: "cell" }, [_vm._v("1")]),
      _vm._v(" "),
      _c("div", { staticClass: "cell" }, [
        _vm._v(
          _vm._s(_vm._f("priceView")(_vm.calculations.contingent.busTax.amount))
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cell" }, [
        _c("b", [
          _vm._v(
            _vm._s(
              _vm._f("priceView")(_vm.calculations.contingent.busTax.amount)
            )
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "cell" }, [_vm._v("Steuern - pro Person")]),
      _vm._v(" "),
      _c("div", { staticClass: "cell" }),
      _vm._v(" "),
      _c("div", { staticClass: "cell" }, [
        _vm._v(
          _vm._s(
            _vm.calculations.personsReserved +
              _vm.calculations.personsExtraReserved
          )
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cell" }, [
        _vm._v(
          _vm._s(
            _vm._f("priceView")(_vm.calculations.contingent.ticketTax.amount)
          )
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cell" }, [
        _c("b", [
          _vm._v(
            _vm._s(
              _vm._f("priceView")(
                (_vm.calculations.personsReserved +
                  _vm.calculations.personsExtraReserved) *
                  _vm.calculations.contingent.ticketTax.amount
              )
            )
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }