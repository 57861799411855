<template>
    <div>
        <row v-on:dblclick.native="openModal">
            <template slot="type">
                <i class="material-icons">{{icon}}</i>
            </template>
            <template slot="date">{{date}}</template>
            <template slot="nights">
                <template v-if="nights">
                    <template v-if="isHotel || isNight">{{nights}} N</template>
                    <template v-else>{{nights + 1}} T</template>
                </template>
                <span class="empty" v-else>-</span>
            </template>
            <template slot="name">{{name}}</template>
            <template slot="provider">
                <template v-if="info.agency || info.provider">
                    <template v-if="info.agency">
                        <a :href="`/agencies/${info.agency.id}`" target="_blank">{{info.agency.name}}</a>
                    </template>
                    <template v-if="info.agency && info.provider"> - </template>
                    <template v-if="info.provider">
                        <a :href="info.provider.api" target="_blank">{{info.provider.name}}</a>
                    </template>
                </template>
                <template v-else>-</template>
            </template>
            <template slot="currency">
                <multiselect
                    v-if="editCurrency"
                    v-model="placeholderCurrency"
                    v-bind:options="potentialCurrencies"
                    v-on:input="updateOrderCurrency"
                    placeholder="EUR"
                >
                </multiselect>
                <template v-else>
                    <div class="is-currency level-left">
                        <span class="level-item">{{currency}}</span>
                        <i class="material-icons level-item is-actions" v-if="!editCurrency" v-on:click="editCurrency = true">edit</i>
                    </div>
                </template>
            </template>
            <template slot="sellPrice">
                <span class="empty" v-if="!hasGroupPrice && !groupPrice">-</span>
                <input-price
                    v-else
                    v-model="groupPrice"
                    :readOnly="!hasGroupPrice"
                    :linked="linked"
                    :value-in-order-currency="groupPriceInOrderCurrency"
                    :provider-prices="getProviderPrices(groupPriceType)"
                    :price-currency="currency"
                    v-on:unlink="handleUnlink"
                />
            </template>
            <template slot="profit">
                <span class="empty" v-if="!hasPersonPrice && !personPrice">-</span>
                <input-price
                    v-else
                    v-model="personPrice"
                    :readOnly="!hasPersonPrice || linked"
                    :linked="linked"
                    :highlight="!!highlight.person"
                    :value-in-order-currency="personPriceInOrderCurrency"
                    :provider-prices="getProviderPrices(personPriceType)"
                    :price-currency="currency"
                    v-on:unlink="handleUnlink"
                />
            </template>
            <template slot="margin">
                <span class="empty" v-if="!hasSinglePrice">-</span>
                <input-price
                    v-else-if="!isNight"
                    :value="singlePrice"
                    :readOnly="true"
                    :linked="linked"
                    :highlight="!!highlight.single"
                    :value-in-order-currency="singlePriceInOrderCurrency"
                    :price-currency="currency"
                    v-on:unlink="handleUnlink"
                />
                <input-price
                    v-else
                    v-model="singlePrice"
                    :readOnly="!hasSinglePrice || linked"
                    :linked="linked"
                    :highlight="!!highlight.single"
                    :value-in-order-currency="singlePriceInOrderCurrency"
                    :provider-prices="getProviderPrices(singlePriceType)"
                    :price-currency="currency"
                    v-on:unlink="handleUnlink"
                />
            </template>

            <template slot="actions">
                <template v-if="isOther">
                    <i class="material-icons" v-on:click.prevent.stop="togglePriceType">
                        <template v-if="isPerPerson">people</template>
                        <template v-else>person</template>
                    </i>
                </template>
                <i class="material-icons" v-on:click.prevent.stop="toggleStatus">remove_shopping_cart</i>
                <i class="material-icons" v-on:click.prevent.stop="saveInCRUD" v-if="!isAirline">save</i>
            </template>
        </row>

        <slot />
    </div>
</template>

<script>
import has from 'lodash/has';
import { editPlaceholder } from '@api';
import { priceMixin, placeholderPriceMixin } from '@calculations/functions'
import Multiselect from '@components/form/Multiselect'
import InputPrice from '@calculations/components/InputPrice'

export default {
    mixins: [ priceMixin, placeholderPriceMixin ],

    components: {
        InputPrice,
        Multiselect
    },

    data: function () {
        return {
            editCurrency: false,
            placeholderCurrency: this.price.placeholder.placeholderCurrency
        }
    },

    computed: {
        highlight: function () { return this.$store.state.highlight },

        potentialCurrencies: function () { return this.$store.state.options && this.$store.state.options.potentialCurrencies ? this.$store.state.options.potentialCurrencies : []},

        singlePersons: function () { return this.$store.getters.rooms.single },
        doublePersons: function () { return this.$store.getters.rooms.double },
        persons: function () { return this.$store.getters.persons },

        hasGroupPrice: function () { return false },
        hasPersonPrice: function () { return false },
        hasSinglePrice: function () { return  false },
        isNight: function () { return true },

        groupPrice: function () { return false },
        personPrice: function () { return this.groupPrice && this.doublePersons ? this.groupPrice.amount / this.doublePersons : 0 },
        singlePrice: function () { return this.price.buyPrice.amount },

        currency: function() {
            return this.placeholder && this.placeholder.placeholderCurrency? this.placeholder.placeholderCurrency : null;
        },

        exchangeRate: function () {
            return this.$store.state.exchangeRates && this.$store.state.exchangeRates[this.currency] ? this.$store.state.exchangeRates[this.currency] : 0;
        },

        //orderCurrency not correct label: should be calculationCurrency
        personPriceInOrderCurrency: function () { return this.personPrice * this.exchangeRate },
        groupPriceInOrderCurrency: function () { return this.groupPrice * this.exchangeRate },
        singlePriceInOrderCurrency: function () { return this.singlePrice * this.exchangeRate},

        providerPrices: function () { return !!this.price.providerPrices ? this.price.providerPrices : []},
        personPriceType: function () { return 'none' },
        groupPriceType: function () { return 'none' },
        singlePriceType: function () { return 'none' },

        linked: function () { return !!this.placeholder.calculationRequest || this.itemPrices.length > 0 },
        itemPrices: function () {
            return this.placeholder.calculationRequest
                ? this.placeholder.calculationRequest.itemPrices
                    .filter(itemPrice => itemPrice.inCalculation)
                : []
        },

        info: function () {
            const { agency, provider } = this.placeholder.info;
            return { agency, provider }
        },

        isHotel: function () { return has(this.price.placeholder, 'hotelType') },


    },

    methods: {
        getProviderPrices: function(type) {
            return this.providerPrices[type] ? this.providerPrices[type] : [];
        },

        updateOrderCurrency: function(){
            this.$store.commit('updateStatus', true)
            editPlaceholder({
                type: `${this.price._type}_placeholders`,
                id: this.placeholder.id,
                data: {
                    placeholderCurrency: this.placeholderCurrency
                },
            }).then(response => {
                let placeholder = {
                    ...this.price.placeholder,
                    placeholderCurrency: response.data.placeholderCurrency
                }
                this.$store.commit('updatePrice', {[this.price._type + 'Prices']: {...this.price, placeholder: placeholder, currencyChange: true}})
                this.$store.dispatch('fetchExchangeRates');
            }, error => { })
                .then(() => {
                    this.editCurrency = false
                    this.$store.commit('updateStatus', false)
                })
        },

        handleUnlink: function () {
            if (confirm('You want to unlink?')) {
                editPlaceholder({
                    type: `${this.price._type}_placeholders`,
                    id: this.placeholder.id,
                    data: {
                        calculationRequest: null
                    },
                    params: '?_groups[]=modal_read'
                }).then(response => {
                    this.price.placeholder.calculationRequest = null
                })
            }
        },

        openModal: function() {
            this.$store.commit('updateModalPlaceholder', this.price)
        },

        saveInCRUD: function() {
            this.$store.commit('updateModalCRUD', this.price)
        }
    }
}
</script>
