var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "transition-group",
        {
          staticClass: "columns is-multiline",
          attrs: { name: "column", tag: "div" },
        },
        [
          _vm._l(_vm.items, function (item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass:
                  "column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-widescreen",
              },
              [_vm._t("default", null, { item: item })],
              2
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              key: "add",
              staticClass:
                "column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-widescreen",
            },
            [
              _c(
                "div",
                {
                  staticClass: "bp-panel is-add",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("add")
                    },
                  },
                },
                [_c("i", { staticClass: "material-icons" }, [_vm._v("add")])]
              ),
            ]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("form", null, { property: _vm.property }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }