var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "columns",
    {
      attrs: { property: "infos", filter: { type: "note" } },
      on: { add: _vm.openForm },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function ({ item }) {
            return _c(
              "column-item",
              {
                staticClass: "is-flex",
                attrs: {
                  update: {
                    updatedBy: item.updatedBy,
                    updatedAt: item.updatedAt,
                  },
                  api: "/api/infos/" + item.id,
                  label: _vm.importance(item),
                },
                on: {
                  edit: function ($event) {
                    return _vm.openForm(item)
                  },
                  delete: function ($event) {
                    return _vm.deleteItem("infos", item)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "content-grow" },
                  [
                    item.title
                      ? _c("ul", [
                          _c("li", [
                            _c("strong", [_vm._v(_vm._s(item.title))]),
                          ]),
                          _vm._v(" "),
                          item.content || item.images.length > 0
                            ? _c("hr", { staticClass: "my-sm mb-sm" })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.images.length > 0
                      ? [
                          _c(
                            "div",
                            { staticClass: "columns is-multiline mb-0" },
                            _vm._l(item.images, function (image) {
                              return _c(
                                "div",
                                {
                                  key: image.id,
                                  staticClass: "column is-one-third",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "image",
                                      attrs: {
                                        href: image.publicPath,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: image.publicPath,
                                          alt: image.title,
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          item.content
                            ? _c("hr", { staticClass: "my-sm mb-sm" })
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    item.content
                      ? _c("p", {
                          staticClass: "nl2br",
                          domProps: { innerHTML: _vm._s(item.content) },
                        })
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                item.sections.length > 0 || item.showInConnectedProviders
                  ? _c("hr", { staticClass: "mt-sm mb-sm" })
                  : _vm._e(),
                _vm._v(" "),
                item.sections.length > 0 || item.showInConnectedProviders
                  ? _c(
                      "div",
                      {
                        staticClass: "buttons",
                        staticStyle: { "padding-bottom": "20px" },
                      },
                      [
                        item.sections
                          ? [
                              _vm.api !== "agencies" &&
                              item.showInConnectedProviders
                                ? _c(
                                    "span",
                                    { staticClass: "button is-small" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#60A4AB" },
                                          attrs: {
                                            href: `/agencies/${item.agency.id}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "von " + _vm._s(item.agency.name)
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.api === "agencies" &&
                              item.showInConnectedProviders
                                ? _c(
                                    "span",
                                    { staticClass: "button is-small" },
                                    [
                                      _c(
                                        "a",
                                        { staticStyle: { color: "#60A4AB" } },
                                        [
                                          _vm._v(
                                            "Gültig für verbundene Anbieter"
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(item.sections, function (section) {
                                return _c(
                                  "span",
                                  {
                                    key: section,
                                    staticClass: "button is-small",
                                  },
                                  [_vm._v(_vm._s(_vm.sectionName(section)))]
                                )
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            )
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("info-form", { ref: "form", attrs: { slot: "form" }, slot: "form" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }