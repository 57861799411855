<template>
    <popover trigger="hover">
        <deadline v-if="value" :value="value" />
        <span v-else class="bp-deadline">-</span>

        <template slot="popover" v-if="todo">
            <div class="level level-left">
                <div class="level-item">
                    <user-image v-if="todo.user" v-bind:user="todo.user" small/>
                </div>
                <p
                    v-bind:class="{'is-line-through': todo.status === 'done'}"
                ><strong>{{todo.name}}</strong></p>
            </div>
        </template>
    </popover>
</template>

<script>
import Deadline from '@components/Deadline'
import Popover from '@components/Popover'
import UserImage from '@components/UserImage'
import Cell from './cell';

export default {
    mixins: [ Cell ],

    components: {
        Deadline,
        Popover,
        UserImage
    },

    computed: {
        todo: function () {
            return this.row.mostUrgentTodo
        }
    }
}
</script>

