var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "details-score" }, [
    _c("div", { staticClass: "score", attrs: { "data-score": _vm.score } }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.rating))]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "score-circle" }, [
      _c("div", { staticClass: "full slice" }, [
        _c("div", { staticClass: "fill" }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "slice" }, [
        _c("div", { staticClass: "fill" }),
        _vm._v(" "),
        _c("div", { staticClass: "fill bar" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }