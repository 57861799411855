var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "level" }, [
        _c(
          "div",
          { staticClass: "level-left" },
          [
            _c(
              "toggle-switch",
              {
                attrs: { "div-class": "is-half", "btn-class": "is-large" },
                model: {
                  value: _vm.shortVersion,
                  callback: function ($$v) {
                    _vm.shortVersion = $$v
                  },
                  expression: "shortVersion",
                },
              },
              [
                _c("option", { domProps: { value: false } }, [
                  _vm._v("Large Version"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: true } }, [
                  _vm._v("Kurze Version"),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "button",
            {
              staticClass: "button is-success",
              class: { "is-loading": _vm.isLoading },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.generate.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "material-icons" }, [
                _vm._v("add_circle"),
              ]),
              _vm._v("Textdatel generieren"),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("h2", { staticClass: "is-headline" }, [_vm._v("Einleitungstext")]),
          _vm._v(" "),
          _c("tip-tap", {
            on: { input: _vm.storeCatalogText },
            model: {
              value: _vm.item.catalogText,
              callback: function ($$v) {
                _vm.$set(_vm.item, "catalogText", $$v)
              },
              expression: "item.catalogText",
            },
          }),
          _vm._v(" "),
          _c("br"),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.orderedDays, function (day) {
        return _c("catalog-day", {
          key: day.id,
          attrs: {
            "short-version": _vm.shortVersion,
            "is-concept": true,
            day: day,
            options: { ..._vm.options, orderOfDays: _vm.item.orderOfDays },
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }