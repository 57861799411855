var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section" },
    [
      _c("div", { staticClass: "section-title" }, [
        _c("span", { staticClass: "h2" }, [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c(
            "i",
            {
              staticClass: "material-icons",
              on: {
                click: function ($event) {
                  _vm.showForm = true
                },
              },
            },
            [_vm._v("add_circle")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "draggable",
        {
          staticClass: "columns is-multiline",
          attrs: {
            element: "div",
            value: _vm.typeProviderTripDetails,
            options: { draggable: ".draggable" },
          },
          on: { input: _vm.updateSequence },
        },
        [
          _vm._l(
            _vm.typeProviderTripDetails,
            function (providerTripDetail, index) {
              return _c(
                "div",
                {
                  key: providerTripDetail.id,
                  staticClass: "column is-half draggable",
                },
                [
                  _c(
                    "item",
                    {
                      attrs: { value: providerTripDetail },
                      on: {
                        delete: function ($event) {
                          return _vm.handleDelete(providerTripDetail)
                        },
                        submit: _vm.handleSubmit,
                        edit: function ($event) {
                          return _vm.editInfo(providerTripDetail.info)
                        },
                      },
                    },
                    [_vm._v(_vm._s(index + 1))]
                  ),
                ],
                1
              )
            }
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "column is-half" },
            [
              !_vm.showForm
                ? _c(
                    "div",
                    {
                      staticClass: "bp-panel is-add",
                      on: {
                        click: function ($event) {
                          _vm.showForm = true
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("add"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "panel-actions" }, [
                        _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.addInfo()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("map"),
                            ]),
                            _vm._v(
                              "\n                        Neuen Hinweis hinzufügen\n                    "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _c("item", {
                    attrs: { value: { type: _vm.type } },
                    on: {
                      submit: _vm.handleSubmit,
                      delete: function ($event) {
                        _vm.showForm = false
                      },
                    },
                  }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("info-form", {
        ref: "infoForm",
        on: { success: _vm.handleInfoSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }