var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    { attrs: { href: _vm.googleMapsLink, target: "_blank" } },
    [
      _vm.value.title
        ? [_c("strong", [_vm._v(_vm._s(_vm.value.title))]), _vm._v(",")]
        : _vm._e(),
      _vm._v(" "),
      _vm.value.streetAddress
        ? [_vm._v(_vm._s(_vm.value.streetAddress) + ",")]
        : _vm._e(),
      _vm._v(" "),
      _vm.value.zip
        ? [_vm._v(_vm._s(_vm.value.zip) + " " + _vm._s(_vm.value.city) + ",")]
        : _vm._e(),
      _vm._v(" "),
      _vm.value.country ? [_vm._v(_vm._s(_vm.country))] : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }