<template>
    <modal
        v-bind:classes  = "'placeholder'"
        v-bind:visible  = "isModalVisible"
        v-on:closeModal = "closeModal"
    >
        <template v-if="modalLoading">
            <div slot="content">
                <loading textInFront="Daten werden aktualisiert"></loading>
            </div>
        </template>


        <template v-else>
            <template slot="header">
                <div class="is-saving">
                    <i class="material-icons" v-if="isSaving === 'ok'">check</i>
                    <i class="material-icons icon-spin" v-else-if="isSaving">refresh</i>
                </div>

                <div class="name">{{ localPlaceholder.serviceDescription }}</div>
                <div class="bp-row-form extra-info">
                    <div>
                        <div>
                            <i class="material-icons" title="Zug">directions_train</i>

                            <div class="buttons has-addons toggle-switch has-margin-right">
                                <button
                                    class="button is-large"
                                    v-on:click.prevent="localPlaceholder.type = 'night'"
                                    v-bind:class="{ 'is-primary': localPlaceholder.type === 'night' }"
                                >Nacht</button>
                                <button
                                    class="button is-large"
                                    v-on:click.prevent="localPlaceholder.type = 'day'"
                                    v-bind:class="{ 'is-primary': localPlaceholder.type === 'day' }"
                                >Tag</button>
                            </div>
                            <async-select
                                api     = "train_stations"
                                class   = "has-margin-right"
                                ref     = "startTrainStation"
                                v-model = "localPlaceholder.startTrainStation"
                                v-bind:custom-label = "item => item.name"
                                crudLinkTitle       = "Fügen Sie einen neuen Bahnhof hinzu"
                                v-bind:clearable    = "false"
                                v-bind:allow-empty  = "false"
                                placeholder         ="Abfahrtsbahnhof"
                            />
                            <div>
                                <button
                                    class        = "btn-single-toggle has-margin-right"
                                    v-bind:class = "[endTrainStationRestriction ? '' : 'is-active']"
                                    v-on:click   = "endTrainStationRestriction = !endTrainStationRestriction"
                                    title = "Nur bestehende Zugverbindungen suchen"
                                ><i class="material-icons" style="vertical-align: -2px;">timeline</i></button>
                            </div>


                            <async-select
                                api     = "train_stations"
                                v-model = "localPlaceholder.endTrainStation"
                                v-bind:custom-label = "item => item.name"
                                crudLinkTitle       = "Fügen Sie einen neuen Bahnhof hinzu"
                                v-bind:clearable    = "false"
                                v-bind:allow-empty  = "false"
                                :params="endTrainStationRestriction && localPlaceholder.startTrainStation ? {_groups: ['trainStation:service_day_time', 'provider_train_route', 'provider_read', 'location_train_station:provider_train_route'], 'endRoutes.startTrainStation.id': localPlaceholder.startTrainStation.id } : {_groups: ['trainStation:service_day_time']}"
                                placeholder         = "Ankunftsbahnhof"
                            />
                        </div>
                    </div>

                    <div class="is-160 has-time">
                        <div>
                            <button
                                class        = "btn-single-toggle has-margin-right"
                                v-bind:class = "[onlyTime ? '' : 'is-active']"
                                v-on:click   = "onlyTime = !onlyTime"
                                title = "Uhrzeit"
                                v-if="serviceDayTimes.length > 0"
                            ><i class="material-icons" style="vertical-align: -1px;">access_time</i></button>
                            <i class="material-icons" v-else>access_time</i>
                            <template v-if="onlyTime">
                                <date-time class="has-margin-right" v-model="localPlaceholder.startAt"></date-time> -
                                <date-time class="has-margin-left" v-model="localPlaceholder.endAt"></date-time>
                            </template>
                            <template v-else>
                                <div class="select">
                                    <multiselect
                                        v-bind:options="serviceDayTimes"
                                        track-by="id"
                                        v-bind:custom-label="serviceDayTimeLabel"
                                        v-model="serviceDayTimeSelected"
                                        v-on:select="selectServiceTime"
                                    />
                                </div>
                            </template>
                        </div>
                        <div v-if="onlyTime">
                            <i title="Einkunft am Bahnhof" class="material-icons">av_timer</i>
                            <date-time v-model="localPlaceholder.checkInAt"></date-time>
                        </div>
                    </div>

                   <div>
                       <div>
                           <i class="material-icons" title="Klasse">receipt</i>

                           <multiselect
                               style="width: 180px"
                               v-if="(options.trainLabels.categorySelect)"
                               v-model="category"
                               track-by="key"
                               label="value"
                               placeholder="Klasse"
                               v-bind:options="objectToKeyValueArray(options.trainLabels.categorySelect)"
                           />
                       </div>

                       <div>
                           <i class="material-icons" title="Klasse">receipt</i>

                           <multiselect
                               style="width: 180px"
                               v-if="(trainTypes)"
                               v-model="trainType"
                               track-by="key"
                               label="value"
                               placeholder="Besonderer Zug"
                               v-bind:options="objectToKeyValueArray(trainTypes)"
                           />
                       </div>
                       <div v-if="localPlaceholder.trainType !== 'standard' && localPlaceholder.type === 'day'">
                           <i class="material-icons" title="Klasse">receipt</i>

                           <multiselect
                               style="width: 180px"
                               v-if="(wagonTypes)"
                               v-model="wagonType"
                               track-by="key"
                               label="value"
                               placeholder="Wagen"
                               v-bind:options="objectToKeyValueArray(wagonTypes)"
                           />
                       </div>
                   </div>

                    <div>
                        <div>
                            <i title="Set as Additional" class="material-icons">shopping_cart</i>
                            <div class="select">
                                <select v-model="localPlaceholder.clientOffer.status">
                                    <option value="included">inkludiert</option>
                                    <option value="optional">optional</option>
                                    <option value="additional">gesondert</option>
                                </select>
                            </div>
                        </div>

                    </div>
                    <div v-if="hasOwnRouteDescription">
                        <div>
                            <textarea id="description" class="textarea" rows="4" v-model="localPlaceholder.routeDescription" style="resize: none"></textarea>
                        </div>
                    </div>
                </div>

                <button class="button is-large is-success is-add-new" v-on:click="addNewRequest"><i class="fa fa-plus-circle" aria-hidden="true"></i> Leistungsträger hinzufügen</button>
            </template>


            <div slot="content">
                <request
                    v-for="(request, index) in localPlaceholder.requests"
                    v-bind:key="request.id"
                    v-if="request.id"
                    v-bind:placeholderInfo="placeholderInfo"
                    v-model="localPlaceholder.requests[index]"
                    v-on:closeModal="$emit('closeModal', localPlaceholder)"
                    v-bind:calculationRequest="calculationRequest"
                    v-on:setCalculationRequest="setCalculationRequest"
                    v-on:saved="handleRequestSaved"
                ></request>


                <new-request
                    v-if="isNewRequestVisible"
                    v-bind:placeholderID="localPlaceholder.id"
                    v-bind:placeholderInfo="placeholderInfo"
                    v-model="localPlaceholder.requests[localPlaceholder.requests.length - 1]"
                    v-on:close="closeNewRequestModal"
                    v-on:closeModal="$emit('closeModal', localPlaceholder)"
                    v-on:saved="handleRequestSaved"
                ></new-request>
            </div>
        </template>
    </modal>
</template>



<script>
import pick from 'lodash/pick';
import mixins from './mixins';
import Request    from './requests/Train';
import NewRequest from './newRequests/Train';
import {addDays, fullYear, objectToKeyValueArray, time} from "@utilities/functions";
import moment from "moment";
import {getAxios} from "@api";


export default {
    mixins: [mixins],


    computed: {
        serviceDayTimes: function () {
            let serviceDayTimes = [];
            this.trainRoutes.forEach(route => {
                route.allServiceDayTimes.forEach(time => {
                    serviceDayTimes.push({
                        ...time,
                        route: route,
                    })
                })
            });
            return serviceDayTimes;
        },

        wagonTypes: function () {
            let trainLabels = this.options.trainLabels.wagonTypes;
            trainLabels.standard = 'Standardwagen';
            return trainLabels;
        },
        hasOwnRouteDescription: function () {
            return this.options.clientConfig && this.options.clientConfig.crud.train.hasOwnRouteDescription;
        },
        trainTypes: function () {
            let trainLabels = this.options.trainLabels.trainTypes;
            trainLabels.standard = 'Keine Besonderheit';
            return trainLabels;
        },
        options: function () {
            return this.$store.state.options;
        },
        trainType: {
            get: function () {
                return objectToKeyValueArray(this.trainTypes).find(label => label.key === this.localPlaceholder.trainType);
            },
            set: function (value) {
                this.localPlaceholder.trainType = value.key;
            }
        },
        category: {
            get: function () {
                return objectToKeyValueArray(this.options.trainLabels.categorySelect).find(label => label.key === this.localPlaceholder.category);

            },
            set: function (value) {
                this.localPlaceholder.category = value.key;
            }
        },
        wagonType: {
            get: function () {
                return objectToKeyValueArray(this.wagonTypes).find(label => label.key === this.localPlaceholder.wagonType);
            },
            set: function (value) {
                this.localPlaceholder.wagonType = value.key;

            }
        },

        today: function () {
            let date = new Date();
            date.setHours(0, 0, 0, 0);
        },

        emptyRequest: function () {
            return {
                agency:      null,
                train:       null,
                placeholder: '/api/train_placeholders/' + this.localPlaceholder.id,
                contingent: {
                    doubleCabins: this.localPlaceholder.type === 'night' ? [
                        {
                            askedAmount: 0,
                            type: {
                                persons: 2,
                                type: 'second'
                            }
                        },
                        {
                            askedAmount: 0,
                            type: {
                                persons: 2,
                                type: 'first'
                            }
                        }
                    ] : [],
                    singleCabins: [
                        {
                            askedAmount: 0,
                            type: {
                                persons: 1,
                                type: 'second'
                            }
                        },
                        {
                            askedAmount: 0,
                            type: {
                                persons: 1,
                                type: 'first'
                            }
                        }
                    ],
                    otherCabins:  [],
                },
                optionNr:    null,
                trainNumber: null,
                startTrack: null,
                endTrack: null,
                optionTodo: {dueDate: null},
                itemPrices:  []
            }
        },


        placeholderInfo: function () { return pick(this.localPlaceholder, 'startTrainStation', 'endTrainStation', 'type', 'trainType', 'category'); },
    },


    data: function () {
        return {
            typeOfPlaceholder: 'train',
            apiEndpoint: 'train_placeholders',
            endTrainStationRestriction: true,
            onlyTime: true,
            trainRoutes: [],
            routeTemplates: [],
            selectedRouteTemplate: null,
            serviceDayTimeSelected: null,
        }
    },


    methods: {
        fillRouteDescription: function (input) {
            if(input) {
                this.localPlaceholder.routeDescription = input.routeDescription;
            }
        },
        objectToKeyValueArray,
        serviceDayTimeLabel: function (serviceDayTime) {
            let customLabel = serviceDayTime.startTimeOfDay.substring(0, serviceDayTime.startTimeOfDay.length - 3);
            customLabel += serviceDayTime.endTimeOfDay ? ' - ' + serviceDayTime.endTimeOfDay.substring(0, serviceDayTime.endTimeOfDay.length - 3) : '';
            customLabel += serviceDayTime.checkInTimeOfDay ? ' (Einschiffung: ' + serviceDayTime.checkInTimeOfDay.substring(0, serviceDayTime.checkInTimeOfDay.length - 3) + ')' : '';
            customLabel += ' - ' + serviceDayTime.provider;
            return customLabel;
        },
        selectServiceTime: function (selectedTime) {
            //setTime
            if(selectedTime){
                const startAt = selectedTime.startTimeOfDay ? selectedTime.startTimeOfDay : '00:00:00';
                const endAt = selectedTime.endTimeOfDay ? selectedTime.endTimeOfDay : '00:00:00';
                const checkInAt = selectedTime.checkInTimeOfDay ? selectedTime.checkInTimeOfDay : '00:00:00';
                this.localPlaceholder.startAt = moment(this.localPlaceholder.startAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') + ' ' + startAt;
                this.localPlaceholder.endAt = moment(this.localPlaceholder.endAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') + ' ' + endAt;
                this.localPlaceholder.checkInAt = moment(this.localPlaceholder.checkInAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') + ' ' + checkInAt;
                this.onlyTime = true;
            }
        },
    },
    watch: {
        'localPlaceholder': {
            deep: true,
            handler: function (value) {
                value=_.pick(value, 'id', 'type', 'startTrainStation', 'endTrainStation', 'category', 'trainType', 'wagonType', 'startAt', 'endAt', 'serviceDayTimeSelected', 'checkInAt', 'clientOffer.status', 'routeDescription');
                if (this.oldValue && JSON.stringify(this.oldValue) !== JSON.stringify(value) ) {
                    this.savePlaceholder(value)
                }
                this.oldValue= JSON.parse(JSON.stringify(value))
            }
        },
        'localPlaceholder.type': function (newValue) {
            let endTime = time(this.localPlaceholder.endAt),
                date    = '';

            if (newValue === 'day') {
                // Making the same day as the startAt
                date = fullYear(this.localPlaceholder.startAt);

            } else {
                // Adding one day difference form startAt
                date = fullYear(addDays(this.localPlaceholder.startAt, 1));
            }

            this.localPlaceholder.endAt = date + ' ' + endTime + ':00';
        },
        'localPlaceholder.endTrainStation': function(newValue, oldValue) {
            if(this.localPlaceholder.endTrainStation && this.localPlaceholder.startTrainStation && newValue.id !== oldValue.id){

                getAxios('train_routes', {
                    'startTrainStation.id': this.localPlaceholder.startTrainStation.id,
                    'endTrainStation.id': this.localPlaceholder.endTrainStation.id,
                    '_groups': ['service_day_time']
                }).then(response => {
                    this.trainRoutes = response.data;
                    if(this.trainRoutes.length > 0) {
                        this.localPlaceholder.trainType = this.trainRoutes[0].trainType;
                        this.localPlaceholder.wagonType = this.trainRoutes[0].wagonType;
                        if(this.hasOwnRouteDescription) {
                            this.localPlaceholder.routeDescription = this.trainRoutes[0].routeDescription;
                        }
                    }
                    this.onlyTime = this.serviceDayTimes.length === 0;
                })
            }
        },
    },

    components: {
        NewRequest,
        Request
    }
}
</script>
