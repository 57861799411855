var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row is-headline is-small" }, [
        _c(
          "div",
          {
            staticClass: "level level-left mb-0",
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.toggleCollapse.apply(null, arguments)
              },
            },
          },
          [
            _vm.hasPrices
              ? _c(
                  "i",
                  {
                    staticClass: "collapse-icon material-icons level-item",
                    class: { "is-rotated": !_vm.isOpen },
                  },
                  [_vm._v("keyboard_arrow_up")]
                )
              : _c("i", { staticClass: "material-icons level-item" }, [
                  _vm._v("close"),
                ]),
            _vm._v(" "),
            _c("span", { staticClass: "level-item" }, [
              _vm._v(_vm._s(_vm.route.route.name)),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", [
          _c("i", {
            staticClass: "fa fa-calendar-plus-o fa-lg",
            on: {
              click: function ($event) {
                return _vm.$emit("addPriceGroup")
              },
            },
          }),
          _vm._v(" "),
          _c(
            "i",
            {
              staticClass: "material-icons is-pointer",
              on: {
                click: function ($event) {
                  return _vm.$emit("editRoute")
                },
              },
            },
            [_vm._v("edit")]
          ),
          _vm._v(" "),
          _c(
            "i",
            {
              staticClass: "material-icons",
              on: {
                click: function ($event) {
                  return _vm.$emit("deleteRoute")
                },
              },
            },
            [_vm._v("delete")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.isOpen
        ? _vm._l(_vm.groups, function (priceGroup) {
            return _c("price-group-row", {
              key: priceGroup.id,
              attrs: {
                priceGroup: priceGroup,
                options: _vm.options,
                "show-next-row": _vm.route.groups.length < 2,
                "price-type": "ferry",
              },
              on: {
                updatePriceGroup: _vm.updatePriceGroup,
                editPriceGroup: function ($event) {
                  return _vm.$emit("editPriceGroup", priceGroup)
                },
                deletePriceGroup: function ($event) {
                  return _vm.$emit("deletePriceGroup", priceGroup)
                },
              },
            })
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }