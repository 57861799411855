var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row is-filters" },
    [
      _c("i", { staticClass: "fa fa-lg fa-filter" }),
      _vm._v(" "),
      _vm.hotel || _vm.ferry
        ? [
            _c("multiselect", {
              attrs: {
                "track-by": "key",
                label: "label",
                options: _vm.options.types,
                placeholder: "Art filtern",
              },
              model: {
                value: _vm.filters.type,
                callback: function ($$v) {
                  _vm.$set(_vm.filters, "type", $$v)
                },
                expression: "filters.type",
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("multiselect", {
        attrs: {
          options: _vm.options.agencies,
          "track-by": "id",
          label: "name",
          placeholder: "Agentur filtern",
        },
        model: {
          value: _vm.filters.agency,
          callback: function ($$v) {
            _vm.$set(_vm.filters, "agency", $$v)
          },
          expression: "filters.agency",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flatpickr-combined" },
        [
          _c("input-date", {
            model: {
              value: _vm.filters.startAt,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "startAt", $$v)
              },
              expression: "filters.startAt",
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("-")]),
          _vm._v(" "),
          _c("input-date", {
            model: {
              value: _vm.filters.endAt,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "endAt", $$v)
              },
              expression: "filters.endAt",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }