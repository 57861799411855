import has from "lodash/has";
import {compareDates, fullYear, getRequestType, nights} from "@utilities/functions";

export const prepareForBackend = function (state) {
    return {
        order: `/api/orders/${state.order.id}`,

        hotelPrices: parsePrices(state.prices.hotelPrices)
            .map(item => ({
                id: item.id,
                doubleRoomBuyPrice: item.doubleRoomBuyPrice,
                doubleRoomSellPrice: item.doubleRoomSellPrice,
                extrasDoubleRoom: item.extrasDoubleRoom,
                extrasSingleRoom: item.extrasSingleRoom,
                freePlacesDoubleRoom: item.freePlacesDoubleRoom,
                freePlacesSingleRoom: item.freePlacesSingleRoom,
                singleRoomPremiumBuyPrice: item.singleRoomPremiumBuyPrice,
                singleRoomPremiumSellPrice: item.singleRoomPremiumSellPrice,
                marginTax: item.marginTax
            })),
        ferryPrices: parsePrices(state.prices.ferryPrices)
            .map(item => ({
                id: item.id,
                busBuyPrice: item.busBuyPrice,
                busSellPrice: item.busSellPrice,
                dayFreePlaces: item.dayFreePlaces,
                dayTicketBuyPrice: item.dayTicketBuyPrice,
                dayTicketSellPrice: item.dayTicketSellPrice,
                extrasDoubleCabin: item.extrasDoubleCabin,
                extrasSingleCabin: item.extrasSingleCabin,
                freePlacesDoubleCabin: item.freePlacesDoubleCabin,
                freePlacesSingleCabin: item.freePlacesSingleCabin,
                insideDoubleCabinBuyPrice: item.insideDoubleCabinBuyPrice,
                insideDoubleCabinSellPrice: item.insideDoubleCabinSellPrice,
                insideSingleCabinPremiumBuyPrice: item.insideSingleCabinPremiumBuyPrice,
                insideSingleCabinPremiumSellPrice: item.insideSingleCabinPremiumSellPrice,
                outsideDoubleCabinBuyPrice: item.outsideDoubleCabinBuyPrice,
                outsideDoubleCabinSellPrice: item.outsideDoubleCabinSellPrice,
                outsideSingleCabinPremiumBuyPrice: item.outsideSingleCabinPremiumBuyPrice,
                outsideSingleCabinPremiumSellPrice: item.outsideSingleCabinPremiumSellPrice,
                marginTax: item.marginTax
            })),
        trainPrices: parsePrices(state.prices.trainPrices)
            .map(item => ({
                id: item.id,
                secondDoubleCabinBuyPrice: item.secondDoubleCabinBuyPrice,
                secondDoubleCabinSellPrice: item.secondDoubleCabinSellPrice,
                secondSingleCabinPremiumBuyPrice: item.secondSingleCabinPremiumBuyPrice,
                secondSingleCabinPremiumSellPrice: item.secondSingleCabinPremiumSellPrice,
                firstDoubleCabinBuyPrice: item.firstDoubleCabinBuyPrice,
                firstDoubleCabinSellPrice: item.firstDoubleCabinSellPrice,
                firstSingleCabinPremiumBuyPrice: item.firstSingleCabinPremiumBuyPrice,
                firstSingleCabinPremiumSellPrice: item.firstSingleCabinPremiumSellPrice,
                marginTax: item.marginTax
            })),
        otherPrices: parsePrices(state.prices.otherPrices)
            .map(item => ({
                id: item.id,
                sellPrice: item.sellPrice,
                buyPrice: item.buyPrice,
                freePlaces: item.freePlaces,
                marginTax: item.marginTax
            })),
        airlinePrices: parsePrices(state.prices.airlinePrices)
            .map(item => ({
                id: item.id,
                ...!!item.airlinePrice && { airlinePrice : `/api/calculation_price_airlines/${item.airlinePrice}`},
                sellPrice: item.sellPrice,
                buyPrice: item.buyPrice,
                marginTax: item.marginTax
            })),

        contingentPrices: parsePrices(state.prices.contingentPrices)
            .map(item => ({
                ...has(item, 'id') && { id: item.id },
                numberOfPersons: item.numberOfPersons,
                buyPrice: item.buyPrice,
                sellPrice: item.sellPrice,
                marginTax: item.marginTax,
                ...!!item.orderHotelRoom && { orderHotelRoom: `/api/order_contingent_rooms/${item.orderHotelRoom.id}` },
                ...!!item.orderFerryCabin && { orderFerryCabin: `/api/order_contingent_cabins/${item.orderFerryCabin.id}` }
            })),
        extraPrices: parsePrices(state.prices.extraPrices)
            .map(item => ({
                ...has(item, 'id') && { id: item.id },
                offerTextBlock: item.offerTextBlock,
                name: item.name,
                buyPrice: item.buyPrice,
                sellPrice: item.sellPrice,
                type: item.type,
                marginTax: item.marginTax
            })),
        includedPrices: parsePrices(state.prices.includedPrices)
            .map(item => ({
                ...has(item, 'id') && { id: item.id },
                priceType: item.priceType,
                name: item.name,
                buyPrice: item.buyPrice,
                minPersons: item.minPersons,
                maxPersons: item.maxPersons
            })),

        rates: state.savedExchangeRates
    };


};
export const getSortHelper = function (days) {
    return [].concat(...days.map(day => day.sortHelper))
};
export const placeholderNights = function (placeholder) {
    if (placeholder) {
        const { startAt, endAt } = placeholder;
        return nights(fullYear(startAt), fullYear(endAt));
    }

    return 0;
};
export const getRequestAPI = function (request) {
    return `/api/${getRequestType(request)}_requests/${request.id}`;
};
export const applyItemPricesSummary = function (items, rooms, exchangeRates, supplementPrices = true) {

    // const persons = rooms.single + rooms.double * 2;
    const persons = rooms.double ? rooms.double : 1; // * 2;
    const doubleMin = rooms.doubleMin ? rooms.doubleMin : 0;
    const doubleMax = rooms.doubleMax ? rooms.doubleMax : 999999;
    const singleMin = rooms.singleMin ? rooms.singleMin : 0;
    const singleMax = rooms.singleMax ? rooms.singleMax : 999999;


    return items.map(price => ({
        ...price,
        _summary: {
            ...price._summary,
            single: price._summary.single + sumPersons(
                price.itemPrices.filter(({ quantityType }) => quantityType === 'per_single_room' || quantityType === 'per_single_room_night'),
                { min: singleMin, max: singleMax },
                exchangeRates
            ) + sumPersons(
                price.itemPrices.filter(({ quantityType }) => !supplementPrices && ['per_group', 'custom'].includes(quantityType)),
                { min: doubleMin, max: doubleMax },
                exchangeRates, persons
            ) + sumPersons(
                price.itemPrices.filter(({ quantityType }) => !supplementPrices && (quantityType === 'per_person' || quantityType === 'per_person_night')),
                { min: doubleMin, max: doubleMax },
                exchangeRates
            ),
            group: price._summary.group + sumPersons(
                price.itemPrices.filter(({ quantityType }) => ['per_group', 'custom'].includes(quantityType)),
                { min: doubleMin, max: doubleMax },
                exchangeRates
            ),
            person: price._summary.person + sumPersons(
                price.itemPrices.filter(({ quantityType }) => quantityType === 'per_person' || quantityType === 'per_person_night'),
                { min: doubleMin, max: doubleMax },
                exchangeRates
            ) + sumPersons(
                price.itemPrices.filter(({ quantityType }) => ['per_group', 'custom'].includes(quantityType)),
                { min: doubleMin, max: doubleMax },
                exchangeRates, persons
            )
        },
    }))
};

export const addBusBuyPrice = function (prices, persons, exchangeRates, supplementPrices = true) {
    persons = persons ? persons : 1;
    return prices
        .map(price => {

            const busBuyPrice = Number(price.busBuyPrice.amount);
            const exchangeFactor = exchangeRates && price.busBuyPrice && exchangeRates[price.busBuyPrice.currency] ? Number(exchangeRates[price.busBuyPrice.currency]) : 0;

            return {
                ...price,
                _summary: {
                    ...price._summary,
                    group: price._summary.group + busBuyPrice * exchangeFactor,
                    person: price._summary.person + (busBuyPrice ? (busBuyPrice * exchangeFactor / persons) : 0),
                    ...!supplementPrices && {
                        single: price._summary.single + (busBuyPrice ? (busBuyPrice * exchangeFactor / persons) : 0)
                    },
                },

            };
        });
};

export const appendPersonPrice = function (prices, persons, supplementPrices = true) {
    //No exchange rate necessary since _summaries already in correct currency
    persons = persons ? persons : 1;
    return prices.map(price => {

        return {
            ...price,
            _summary: {
                ...price._summary,
                ...price._summary.group > 0 && {
                    person: price._summary.group  / persons
                },
                ...!supplementPrices && price._summary.group > 0 && {
                    single: price._summary.group  / persons
                },
            }
        }});
};
export const filterPersons = function (items, persons) {
    if (typeof persons === 'object' && persons !== null) {
        const { min, max } = persons;
        return items.filter(({ minPersons, maxPersons }) => {
            minPersons = minPersons ? minPersons : 0;
            maxPersons = [-1, null, 99999999].includes(maxPersons) ? 99999999 : maxPersons;
            return (
                minPersons <= max &&
                maxPersons >= min
            )
        });
    } else {
        return items.filter(({ minPersons, maxPersons }) =>
            (persons >= minPersons || minPersons === null) &&
            (persons <= maxPersons || [-1, null, 99999999].includes(maxPersons))
        );
    }
};

export const withoutTreatPackages = function (arr) {
    return [...arr].filter(price => {
        const { calculationRequest } = price.placeholder;
        return calculationRequest === null || calculationRequest.package === null
    });
};

export const getPackageItemPrices = function (pricePackage) {
    return pricePackage
        ? pricePackage.itemPrices
            .filter(itemPrice => itemPrice.inCalculation)
        : [];
};


export const sortHelper = function (arr, helper) {
    return [...arr].sort((a, b) => {
        if (a.placeholder && b.placeholder) {
            const aDate = fullYear(a.placeholder.startAt);
            const bDate = fullYear(b.placeholder.startAt);
            if (aDate === bDate) {
                return helper.indexOf(`${a._type}-${a.placeholder.id}`) < helper.indexOf(`${b._type}-${b.placeholder.id}`) ? -1 : 1;
            } else {
                return compareDates(aDate, bDate);
            }
        }

        return 0;
    });
};


export const addItemPrices = function (prices) {
    return prices.map(price => {
        const nights = placeholderNights(price.placeholder);

        const itemPrices = getItemPrices(price.placeholder).map(price => ({
            ...price,
            nights: price.quantityType === 'per_single_room_night' || price.quantityType === 'per_person_night' ? nights : price.quantityType === 'custom' ? price.quantity : 1
        }));

        return {
            ...price,
            itemPrices
        };
    });
};
