import Days from '@components/Days';
import { Multiselect, InputMonth, InputPrice, DestinationSelect, PlaceSelect, AreaSelect, AsyncSelect } from '@components/form';
import Buttons from './Buttons';

import { daysToNumbers, numbersToDays } from '@utilities/functions';

export default {
    props: {
        value: {},
        options: {
            type: Object,
        },
    },

    data() {
        return {
            form: !!this.value ? JSON.parse(JSON.stringify(this.value)) : {},
            isLoading: false
        }
    },

    computed: {
        days: {
            get: function () {
                return daysToNumbers(this.form);
            },

            set: function (v) {
                this.form = Object.assign({}, this.form, numbersToDays(v));
            }
        },

        areaParams: function () {
            return {
                ...this.form.destination && { 'destination.id': this.form.destination.id }
            }
        },

        isDestination: function () {
            return !!this.form.destination;
        },
    },

    watch: {
        form: {
            deep: true,
            handler: function (form) {
                this.$emit('input', form)
            }
        }
    },

    components: {
        Days,
        Multiselect,
        DestinationSelect,
        PlaceSelect,
        AreaSelect,
        AsyncSelect,
        InputMonth,
        InputPrice,
        Buttons
    }
}