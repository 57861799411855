var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c("div", [
        _vm.isEdit
          ? _c(
              "i",
              {
                staticClass: "material-icons",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("edit")
                  },
                },
              },
              [_vm._v("edit")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isDelete
          ? _c(
              "i",
              {
                staticClass: "material-icons",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("delete")
                  },
                },
              },
              [_vm._v("delete")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isCancel
          ? _c(
              "i",
              {
                staticClass: "material-icons",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("cancel")
                  },
                },
              },
              [_vm._v("close")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isSubmit
          ? _c(
              "i",
              {
                staticClass: "material-icons",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("submit")
                  },
                },
              },
              [_vm._v("check")]
            )
          : _vm._e(),
      ])
    : _c("i", { staticClass: "material-icons icon-spin" }, [_vm._v("refresh")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }