<template>
    <tr>
        <th><label for="replace" class="label">Überall Ersetzten:</label></th>
        <td>
            <toggle-switch v-model="replace">
                <option :value="false">Nein</option>
                <option :value="true">Ja</option>
            </toggle-switch>
        </td>
    </tr>
</template>

<script>

import { ToggleSwitch } from '@components/form';


export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        replace: {
            get: function () { return this.value},
            set: function (value) { this.$emit('input', value) }
        }
    },

    components: {
        ToggleSwitch,
    },

}
</script>
