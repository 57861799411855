<script>
import Prices from '../Prices';
import Individually from './Individually';


export default {
    extends: Prices,


    components: {
        Individually
    }
}
</script>
