<template>
    <div class="tags" v-if="value">
        <span v-for="tag in value"
              class="tag is-white"
        >{{tagValue(tag)}}</span>
    </div>
</template>

<script>
import { getValueWithKey } from '@utilities/functions';
import Cell from './cell';

export default {
    mixins: [ Cell ],

    methods: {
        tagValue: function (tag) {
            return getValueWithKey({key: tag, optionList: this.options.defaultList})
        }
    }
}
</script>
