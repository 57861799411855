<template>
    <div class="drop-down" v-click-outside="() => { visible = false }">
        <div v-on:click.prevent.stop="toggle">
            <slot />
        </div>
        <transition name="fade">
            <div class="drop-down__list" v-if="visible">
                <slot name="list" />
            </div>
        </transition>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
    data: function () {
        return {
            visible: false,
        }
    },

    methods: {
        toggle: function () {
            this.visible = !this.visible;
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    }
}
</script>
