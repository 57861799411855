<template>
    <modal-form
        editTitle="Passende Leistungsträger auswählen"
        createTitle="Passende Leistungsträger auswählen"
        v-bind:isLoading="isLoading"
    >
        <table>
            <tr>
                <td colspan="2">
                    <h2>Passende Leistungsträger <i class="material-icons" v-on:click="addProvider">add_circle</i></h2>
                </td>
            </tr>
            <template v-for="provider in form.providers">
                <tr>
                    <th><label class="label">Klassifizierung:</label></th>
                    <td>
                        <div class="columns">
                            <div class="column">
                                <multiselect
                                    v-if="classifications"
                                    v-model="provider.classification"
                                    track-by="key"
                                    label="value"
                                    v-bind:options="classifications"
                                    class="is-shorter"
                                />
                            </div>
                            <div class="column is-narrow" style="flex-direction: row;">
                                <button type="button" @click="deleteProvider(provider)" class="btn-delete" title="Löschen"><i class="material-icons">delete</i></button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>
                        <label class="label">Leistungsträger:</label>
                    </th>
                    <td>
                        <div class="columns">
                            <div class="column">
                                <async-select
                                    :api     = "api"
                                    v-model = "provider.provider"
                                    placeholder="Leistungsträger auswählen"
                                    :params="params"
                                />
                            </div>
                            <div class="column is-narrow" style="flex-direction: row;">
                                <button type="button" @click="isLinked = !isLinked" class="btn-delete" title="Löschen"><i :class="[isLinked ? 'fa fa-link fa-lg' : 'fa fa-unlink fa-lg']"></i></button>
                            </div>
                        </div>


                    </td>
                </tr>
                <tr>
                    <th><label class="label">Agentur:</label></th>
                    <td>
                        <async-select
                            api     = "agencies"
                            v-model = "provider.agency"
                            placeholder="direkt"
                        />
                    </td>
                </tr>
<!--                <tr>
                    <th class="is-top"><label for="description" class="label">Anmerkungen:</label></th>
                    <td>
                        <error-wrapper v-bind:message="errors.description">
                            <textarea type="text" id="description" rows="4" class="textarea" v-model="provider.remarks"></textarea>
                        </error-wrapper>
                    </td>
                </tr>-->
                <tr>
                    <td colspan="2"><h2></h2></td>
                </tr>
            </template>



        </table>
    </modal-form>
</template>



<script>

import TabForm from '@components/CRUD/Item/Tabs/form';
import {Multiselect, AsyncSelect} from '@components/form';
import axios from 'axios'
import {getAndSetOptionStorage} from "@utilities/functions";
import {notifySuccess} from "@components/Notification";
import {addOtherService, getOtherServices} from "@api";

export default {
    mixins: [TabForm],

    form: {
        providers: [{
            agency: null,
            provider: null,
            classification: {
                key: 1,
                value: '1. Wahl'
            }
        }],
    },


    data: function () {
        return {
            classifications: [],
            isLinked: true,
        }
    },

    async mounted () {
        this.classifications = await getAndSetOptionStorage('classifications', 'concept_providers');
    },

    computed: {
        myStore: function () {
            return this.$store.state;
        },

        lastClassification: function () {
            return this.form.providers.length > 0 ? this.form.providers[this.form.providers.length - 1].classification.key : 0;
        },

        api: function () {
            if(this.form.placeholder){
                let type = this.form.placeholder.placeholderType;
                if(type === 'hotel'){
                    return 'hotels';
                } else if(type === 'ferry'){
                    return 'ferries';
                } else if(type === 'airline') {
                    return 'airlines';
                } else if (type === 'train') {
                    return 'trains'
                } else {
                    return 'providers';
                }
            }
            return 'providers';
        },



        params: function () {
            if(this.form.placeholder && this.isLinked){
                let type = this.form.placeholder.placeholderType;
                if(type === 'hotel'){
                    return {
                        ...this.form.placeholder.area && {'place.area.id': this.form.placeholder.area.id},
                        ...this.form.placeholder.place && {'place.id': this.form.placeholder.place.id},
                    };
                } else if(type === 'ferry'){
                    return {
                        ...this.form.placeholder.startPort && {'ferryRoutes.startPort.id': this.form.placeholder.startPort.id},
                        ...this.form.placeholder.endPort && {'ferryRoutes.endPort.id': this.form.placeholder.endPort.id},
                    };
                } else if(type === 'airline') {
                    return {
                        ...this.form.placeholder.startPort && {'airlineRoutes.startAirport.id': this.form.placeholder.endAirport.id},
                        ...this.form.placeholder.endPort && {'airlineRoutes.endAirport.id': this.form.placeholder.startAirport.id},
                    };
                } else if(type === 'train'){
                    return {
                        ...this.form.placeholder.startTrainStation && {'trainRoutes.startTrainStation.id': this.form.placeholder.startTrainStation.id},
                        ...this.form.placeholder.endTrainStation && {'trainRoutes.endTrainStation.id': this.form.placeholder.endTrainStation.id},
                        ...this.form.placeholder.trainType && {'trainRoutes.trainType': this.form.placeholder.trainType},
                        ...this.form.placeholder.type && {'trainRoutes.type': this.form.placeholder.type}

                    };
                } else {
                    return {
                        ...this.form.placeholder.otherServiceType && {'otherServices.otherServiceType.id': this.form.placeholder.otherServiceType.id}
                    };
                }
            }
            return {};
        }
    },


    methods: {


        addProvider: function () {
            this.form.providers.push({
                key: new Date(),
                agency: null,
                provider: null,
                classification: this.classifications.find(item => item.key === this.lastClassification + 1)
            })
        },

        deleteProvider: function (provider) {
            let id = provider.id ? provider.id : provider.key;
            let index = this.form.providers.findIndex(x => x.id === id || x.key === id);
            this.form.providers.splice(index, 1);
        },

        openCallback() {
            if(this.form.providers.length === 0){
                this.addProvider();
            } else {
                this.form.providers = this.form.providers.map(provider => ({
                    ...provider,
                    ...provider.classification && typeof provider.classification !== 'object' && {classification: this.classifications.find(item => item.key.toString() === provider.classification)}
                })).sort((a,b) => a.classification.key - b.classification.key)
            }
        },

        updatePlaceholder: function (placeholders) {
            return placeholders.map(placeholder => ({
                ...placeholder,
                ...placeholder.id === this.form.placeholder.id && {
                    ...placeholder,
                    providers: this.form.providers.map(provider => ({
                        ...provider,
                        classification: provider.classification.key.toString(),
                    })),
                }
            }))
        },

        updateStore: function () {
            let data = this.$store.state.data;

            if(this.form.dayId){

                data.days = data.days.map(day => ({
                    ...day,
                    ...day.id === this.form.dayId && {
                        [this.form.placeholder.placeholderType + 'Placeholders']: this.updatePlaceholder(day[this.form.placeholder.placeholderType + 'Placeholders'])
                    }
                }))
            } else {
                data[this.form.placeholder.placeholderType + 'Placeholders'] = this.updatePlaceholder(data[this.form.placeholder.placeholderType + 'Placeholders']);
            }

            this.$store.commit('updateData', data);
        },




        handleSubmit: function () {

            let api = this.api === 'providers' ? 'others' : this.api;
            if(this.form.placeholder.placeholderType === 'other'){
                //try to find otherService and add if not existant
                this.form.providers.forEach(provider => {
                    getOtherServices('', {
                        params: {
                            'otherServiceType.id': this.form.placeholder.otherServiceType.id,
                            ...provider.agency && {'agency.id': provider.agency.id},
                            ...provider.provider && {'provider.id': provider.provider.id},
                            ...!provider.agency && {'agency[exists]': false},
                            ...!provider.provider && {'provider[exists]': false},
                        }
                    }).then(response => {
                        let otherServiceId = response.data.length > 0 ? response.data[0].id : null;
                        if(!otherServiceId){
                            addOtherService({
                                ...provider.provider && {provider: 'api/providers/' + provider.provider.id},
                                ...provider.agency && {agency: 'api/agencies/' + provider.agency.id},
                                otherServiceType: '/api/other_service_types/' + this.form.placeholder.otherServiceType.id
                            })
                        }
                    });
                });
            }
            axios.put('/api/concept_placeholder_' + api + '/' + this.form.id,
                {
                    providers: this.form.providers.map(provider => ({
                        classification: provider.classification.key.toString(),
                        ...provider.provider && {provider: '/api/providers/' + provider.provider.id},
                        ...provider.agency && {agency: '/api/agencies/' + provider.agency.id},
                    })),
                },
            ).then(response => {

                this.updateStore();

                this.visible = false;
                notifySuccess('Leistungsträger hinzugefügt!')
                this.$emit('success', {
                    form: this.form,
                    data: response.data
                })
            })
        },
    },


    components: {
        Multiselect,
        AsyncSelect,
    }
}
</script>
