var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "configurator" }, [
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column is-one-third" },
        [
          _c("search", {
            on: { input: _vm.inputSearchConcept },
            model: {
              value: _vm.searchConcept,
              callback: function ($$v) {
                _vm.searchConcept = $$v
              },
              expression: "searchConcept",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.searchResults.length > 0 || _vm.isLoading
      ? _c(
          "main",
          [
            _vm.searchResults.length > 0
              ? _c("div", { staticClass: "search-day-concept" }, [
                  _c("div", { staticClass: "column is-one-third" }, [
                    _c("div", { staticClass: "panel" }, [
                      _c("div", { staticClass: "panel-heading" }, [
                        _vm._v("Suchergebnisse - Bestehende Gesamtkonzepte:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "panel-block is-spaceless" },
                        [
                          _c("concepts", {
                            staticClass: "day-concepts",
                            attrs: {
                              concepts: _vm.searchResults,
                              value: _vm.conceptSelected,
                              options: _vm.options,
                            },
                            on: { select: _vm.selectConcept },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column is-one-third" }, [
                    _c("div", { staticClass: "panel" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "panel-block is-spaceless" },
                        [
                          _c("concept-days", {
                            staticClass: "day-concepts",
                            attrs: {
                              concept: _vm.conceptSelected,
                              options: _vm.options,
                            },
                            on: { select: _vm.selectDay },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column is-one-third" }, [
                    _c("div", { staticClass: "panel" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "panel-block is-spaceless" },
                        [
                          _vm.daySelected
                            ? _c("day", {
                                staticClass: "day-concepts",
                                on: { input: _vm.updateDay },
                                model: {
                                  value: _vm.daySelected,
                                  callback: function ($$v) {
                                    _vm.daySelected = $$v
                                  },
                                  expression: "daySelected",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isLoading ? _c("loading") : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-heading" }, [
      _c("span", [_vm._v("Vorschau:")]),
      _vm._v(" "),
      _c("span", { staticClass: "is-light" }, [
        _vm._v(
          "auf Tag klicken um Nächte und Länge von Zusatzleistungen festzulegen"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-heading" }, [
      _c("span", [_vm._v("Vorschau Des Tages:")]),
      _vm._v(" "),
      _c("span", { staticClass: "is-light" }, [
        _vm._v("Nächte und Länge von Zusatzleistungen festlegen"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }