var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-dropzone",
    {
      ref: "dropzone",
      attrs: {
        id: "dropzone",
        options: _vm.dropzoneOptions,
        useCustomSlot: "",
      },
      on: {
        "vdropzone-sending": _vm.sendingEvent,
        "vdropzone-success": _vm.fileSuccess,
        "vdropzone-complete": _vm.fileCompleted,
        "vdropzone-files-added": _vm.filesAdded,
      },
    },
    [
      _c(
        "div",
        { staticClass: "columns has-margin-top" },
        [
          _c("gallery", {
            attrs: {
              "folder-images": _vm.folderImages,
              "enable-google-maps": false,
              "enable-zoom": _vm.component.props.enableZoom,
              "column-classes": _vm.component.props.columnClasses,
              "column-style": _vm.component.props.columnStyle,
              "multi-images": _vm.component.props.multiImages,
              "has-label": false,
              "is-cover-image": true,
              "dropzone-id": _vm.component.props.dropzoneID,
            },
            on: {
              openForm: _vm.openForm,
              deleteImage: _vm.deleteImage,
              zoom: _vm.zoom,
            },
          }),
          _vm._v(" "),
          _c("vue-gallery", {
            attrs: { images: _vm.gallery, index: _vm.zoomIndex },
            on: {
              close: function ($event) {
                _vm.zoomIndex = null
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "dz-area" }, [
            _c("span", [_vm._v("Zum Hochladen Bild hier hineinziehen")]),
          ]),
          _vm._v(" "),
          _c("image-form", {
            ref: "form",
            attrs: { "show-type": !this.component.props.filterParams.type },
            on: { submit: _vm.handleImageSubmit },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }