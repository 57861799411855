<template>
    <main>
        <slot />

        <div>
            <div>Buspreis</div>
            <input-price
                v-model="busPrice"
                :provider-prices="value.providerPrices.bus_price"
                :small-input="true"
                :price-currency="value.requestCurrency"
            />
        </div>

        <div>
            <div>Steuern - Bus</div>
            <input-price
                v-model="busTax"
                :provider-prices="value.providerPrices.bus_tax"
                :small-input="true"
                :price-currency="value.requestCurrency"
            />
        </div>

        <div>
            <div>Steuern - pro Person</div>
            <input-price
                v-model="ticketTax"
                :provider-prices="value.providerPrices.ticket_tax"
                :small-input="true"
                :price-currency="value.requestCurrency"
            />
        </div>
    </main>
</template>

<script>

import InputPrice from '@calculations/components/InputPrice'

export default {
    components: { InputPrice },

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    computed: {
        busPrice: {
            get: function () { return this.value.busPrice.amount },
            set: function (value) { this.$emit('input', {...this.value,
                busPrice: {
                    amount: value,
                    currency: this.$parent.requestCurrency
                }
            }) }
        },

        busTax: {
            get: function () { return this.value.busTax.amount },
            set: function (value) { this.$emit('input', {...this.value,
                busTax: {
                    amount: value,
                    currency: this.$parent.requestCurrency
                }}) }
        },

        ticketTax: {
            get: function () { return this.value.ticketTax.amount },
            set: function (value) { this.$emit('input', {...this.value, ticketTax: {
                    amount: value,
                    currency: this.$parent.requestCurrency
                }}) }
        }
    }
}
</script>

