<template>
    <vue-dropzone
        ref="dropzone"
        id="dropzone"

        v-bind:options="dropzoneOptions"
        useCustomSlot
        v-on:vdropzone-sending="sendingEvent"
        v-on:vdropzone-success="fileSuccess"
        v-on:vdropzone-complete="fileCompleted"
        v-on:vdropzone-files-added="filesAdded"
    >
        <div class="columns has-margin-top">
            <gallery
                :folder-images="folderImages"
                :enable-google-maps="false"
                :enable-zoom="component.props.enableZoom"
                :column-classes="component.props.columnClasses"
                :column-style="component.props.columnStyle"
                v-on:openForm="openForm"
                v-on:deleteImage="deleteImage"
                v-on:zoom="zoom"
                :multi-images="component.props.multiImages"
                :has-label="false"
                :is-cover-image="true"
                :dropzone-id="component.props.dropzoneID"
            />

            <vue-gallery v-bind:images="gallery" v-bind:index="zoomIndex" v-on:close="zoomIndex = null" />

            <div class="dz-area">
                <span>Zum Hochladen Bild hier hineinziehen</span>
            </div>

            <image-form ref="form" v-on:submit="handleImageSubmit" :show-type="!this.component.props.filterParams.type"/>
        </div>




    </vue-dropzone>
</template>

<script>
import vue2Dropzone  from 'vue2-dropzone';
import VueGallery from 'vue-gallery';
import has from 'lodash/has';

import { store } from '@components/CRUD/Item/store';

import Loading from '@components/Loading';
import ColumnItem from '@components/CRUD/Item/ColumnItem';
import ImageForm from '@components/CRUD/Item/Tabs/Images/Form';


import { authHeader, prepareForBackend } from '@utilities/functions';
import { getImages, optionsImages, deleteImage, editImage, addGoogleImage } from '@api';
import {omit} from "lodash";
import Gallery from "@components/CRUD/Item/Tabs/Images/Gallery";

export default {
    store,

    props: {
        component: {
            type: Object,
            required: true
        },
    },

    data: function () {
        let clickable = this.component.props.dropzoneID ? '.bp-panel.is-add.' + this.component.props.dropzoneID : '.bp-panel.is-add.dropzone-id-0';

        return {
            zoomIndex: null,
            images: [],
            isLoading: false,

            dropzoneOptions: {
                url: '/api/images/upload',
                maxFilesize: 20,
                headers: authHeader(),
                previewTemplate: this.template(),
                createImageThumbnails: false,
                clickable: clickable,
                paramName: "user_file_upload"
            },

            queueSize: 0,
            editFile: {
                id: []
            },

            selectedFolder: null
        }
    },

    mounted: function () {
        this.fetchImages();
        this.fetchOptions();

        let lastTarget = null;

        window.addEventListener("dragenter", (e) => {
            lastTarget = e.target; // cache the last target here
            document.getElementById("dropzone").classList.add("dz-drag-hover")
        });

        window.addEventListener("dragleave", (e) => {
            if(e.target === lastTarget || e.target === document) {
                document.getElementById("dropzone").classList.remove("dz-drag-hover")
            }
        });
    },

    computed: {
        id: function () { return this.$store.state.id },
        api: function () { return this.$store.state.api },
        options: function () { return this.$store.state.options.images },
        gallery: function () { return this.images.map(image => image.publicPath )},
        folderImages: function() {
            return this.images;
        },

        paramKey: function () {
            return {
                'airlines': 'provider',
                'others': 'provider',
                'ferries': 'provider',
                'hotels': 'provider',
                'destinations': 'destination',
                'order_concepts': 'orderConcept',
            }[this.api]
        },

        params: function () {
            return {
                [`${this.paramKey}.id`]: this.id,
                ...this.component.props.filterParams
            }
        },
    },

    methods: {
        editImage: function (data) {
            //todo: image disapears if edited
            this.openForm({
                ...data,
            })
        },

        zoom: function(index) {
            this.zoomIndex = index;
        },

        fetchImages: function () {
            this.isLoading = true;

            getImages(this.params)
                .then(response => {
                    this.images = response.data;
                    this.isLoading = false;
                })
        },

        fetchOptions: function () {
            optionsImages()
                .then(response => {
                    this.$store.commit('updateOptions', {
                        images: response.data
                    })
                })
        },

        label: function (image) {
            const type = !!this.options
                && this.options.categories.find(category => category.key === image.type);
            return !!type ? type.value : ''
        },

        deleteImage: function ({id}) {
            if (confirm('Really want to delete this image?')) {
                deleteImage(id)
                    .then(() => {
                        this.images = this.images
                            .filter(image => image.id !== id)
                    })
            }
        },

        openForm: function (data) {
            this.$refs.form.open({...data, ...this.component.props.filterParams.type && {type: this.component.props.filterParams.type}});
        },

        handleImageSubmit: function (data) {
            if (has(data, 'id')) {
                this.handleImageEdit(data)
            }
        },



        handleImageEdit: function (data) {
            if (Array.isArray(data.id)) {
                Promise.all(data.id.map(id => editImage({id, data: {
                    ...omit(data, 'id'),
                    type: data.type
                }})))
                .then(responses => {
                    const newImages = responses
                        .map(response => response.data);

                    this.images = [
                        ...this.images.filter(image => {
                            return !newImages.some(newImage => newImage.id === image.id)
                        }),
                        ...newImages
                    ];

                    this.$refs.form.close();
                })
            } else {
                editImage({id: data.id, data: {
                    ...prepareForBackend(data),
                }})
                    .then(response => {
                        this.images = this.images.map(image => {
                            if (image.id === data.id) {
                                return response.data
                            }

                            return image;
                        });

                        this.$refs.form.close();
                    })
            }
        },

        // Dropzone
        template: function () {
            return `<div class="dz-preview dz-file-preview">
                        <div class="dz-details">
                            <span data-dz-name></span>
                            <div class="dz-error-message">
                                <span data-dz-errormessage></span>
                            </div>
                        </div>
                        <div class="dz-progress">
                            <span class="dz-upload" data-dz-uploadprogress></span>
                        </div>

                        <div class="dz-remove">
                            <i class="material-icons" data-dz-remove>delete</i>
                        </div>
                    </div>`;
        },

        sendingEvent: function (file, xhr, formData) {
            formData.append('title', file.name.split('.').slice(0, -1).join('.'));
            formData.append(this.paramKey, `/api/${this.api}/${this.id}`);
            formData.append('type', this.component.props.filterParams.type)
        },

        fileSuccess: function (file, response) {
            this.images = [...this.images, response];
            this.$refs.dropzone.removeFile(file);
            this.editFile = {
                ...response,
                id: [...this.editFile.id, response.id]
            }
        },

        fileCompleted: function (file) {
            this.queueSize--;

            if (this.queueSize === 0) {
                this.openForm(this.editFile);
            }
        },

        filesAdded: function (files) {
            this.editFile = { id: [] };
            this.queueSize = files.length;
        },
    },

    components: {
        Gallery,
        Loading,
        ColumnItem,
        vueDropzone: vue2Dropzone,
        VueGallery,
        ImageForm,
    }
}
</script>
