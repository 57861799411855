<template>
    <div>
        <main-object
            v-bind:headline="headline"
            v-bind:levels="levels"
            v-bind:priceType="priceType"
            v-bind:providerType="providerType"
            v-bind:show-filters="showFilters"
            v-bind:filter-start-end-keys="filterStartEndKeys"

            v-on:openForm="openPriceForm"
            v-on:openOtherServiceForm="openOtherServiceForm"
            v-on:deleteOtherService="openDeleteOtherService"

            ref="mainObject"
        />
        <item-form ref="form" v-on:submit="handleSubmit" v-bind:provider-type="providerType"/>
        <other-form ref="other" v-on:submit="handleSubmit" v-bind:provider-type="providerType"/>
        <delete-other-service
            v-if="deleteOtherService"
            v-bind:item="deleteOtherService"
            :filter-params="item ? {'provider.id': item.id, '_groups': ['provider_read', 'agency_read']} : {}"
            v-on:close="deleteOtherService = null"
            v-on:deleted="$refs.mainObject.getPrices()"
        />

    </div>



</template>

<script>

import Mixin from '../tab';
import PriceMixin from './mixins'
import ItemForm from './Forms/Other';
import OtherForm from './Forms/OtherService';
import DeleteOtherService from "./Forms/DeleteOtherService";
import MainObject from "@components/CRUD/Item/Tabs/ProviderPrices/Main";
import {getOtherService} from "@utilities/api";



export default {
    mixins: [ Mixin, PriceMixin ],

    components: {MainObject, ItemForm, OtherForm, DeleteOtherService},

    data: function () {
        return {
            headline: 'Zusatzleistungen',
            levels: ['level', 'otherServiceType', 'priceGroup', 'price'],
            priceType: 'other',
            providerType: 'provider',
            showFilters: {
                'otherServiceType.category': "",
                'timePeriods.startAt[before]': '',
                'timePeriods.endAt[after]': '',
                'agency.id': "",
                'agency[exists]': "",
            },
            filterStartEndKeys: ['timePeriods.endAt[after]', 'timePeriods.startAt[before]'],
            deleteOtherService: null,
        }
    },

    methods: {
        openDeleteOtherService: function (item) {
            this.deleteOtherService = JSON.parse(JSON.stringify(item));
        },

        openOtherServiceForm: function(otherService = {}) {
            if(otherService.id){
                this.isLoading = true
                getOtherService(otherService.id, '?_groups[]=agency_read&_groups[]=provider_read&_groups[]=other_service_type_read').then(response => {
                    otherService = response.data
                    this.$refs.other.setType(response.data.agency ? 'agency' : 'direct');
                    this.$refs.other.open(otherService);
                });
            } else {
                this.$refs.other.open(otherService);
            }
        },
    }


}
</script>
