var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "is-form is-medium",
      attrs: { visible: "" },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("p", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Zusatzleistung löschen (ID: " + _vm._s(_vm.item.id) + " )"),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content" },
        [
          _c("h2", { staticClass: "title" }, [
            _vm._v("MIT ZUSATZLEISTUNG VERBUNDENE ELEMENTE"),
          ]),
          _vm._v(" "),
          _c("crud-table", {
            attrs: {
              api: _vm.api,
              url: _vm.table.url,
              storeKey: "delete",
              filter: _vm.table.filter,
              "load-options": false,
              deleteRow: false,
              perPage: 5,
              columns: _vm.table.columns,
            },
            scopedSlots: _vm._u([
              {
                key: "filters",
                fn: function ({}) {
                  return undefined
                },
              },
              {
                key: "head",
                fn: function ({}) {
                  return [
                    _c("div", { staticClass: "row is-head" }, [
                      _c(
                        "div",
                        { staticClass: "cell" },
                        [
                          _c(
                            "toggle-switch",
                            {
                              model: {
                                value: _vm.api,
                                callback: function ($$v) {
                                  _vm.api = $$v
                                },
                                expression: "api",
                              },
                            },
                            [
                              _c("option", { attrs: { value: "orders" } }, [
                                _vm._v("Reisen"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "dates",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      _vm._s(_vm._f("fullYear")(row.startAt)) +
                        " - " +
                        _vm._s(_vm._f("fullYear")(row.endAt))
                    ),
                  ]
                },
              },
              {
                key: "id",
                fn: function ({ row }) {
                  return [_vm._v("#" + _vm._s(row.id))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "title" }, [
            _vm._v("ZUSATZLEISTUNG ERSETZEN"),
          ]),
          _vm._v(" "),
          _c(
            "toggle-switch",
            {
              attrs: { "btn-class": "is-300" },
              model: {
                value: _vm.replace,
                callback: function ($$v) {
                  _vm.replace = $$v
                },
                expression: "replace",
              },
            },
            [
              _c("option", { domProps: { value: true } }, [
                _vm._v("Zusatzleistung ersetzen (empfohlen)"),
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: false } }, [
                _vm._v("Zusatzleistung & Verknüpfungen löschen"),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.replace
            ? [
                _c(
                  "div",
                  { staticClass: "level" },
                  [
                    _c("async-select", {
                      attrs: {
                        api: "other_services",
                        placeholder: "Zusatzleistung suchen",
                        "custom-label": _vm.otherServiceLabel,
                        params: _vm.filterParams,
                      },
                      model: {
                        value: _vm.replacement,
                        callback: function ($$v) {
                          _vm.replacement = $$v
                        },
                        expression: "replacement",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("h2", { staticClass: "title" }, [
                  _vm._v(
                    "INHALTE DER ZU LÖSCHENDEN ZUSATZLEISTUNG AUF NEUE ZUSATZLEISTUNG ÜBETRAGEN"
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.sortedOptions, function (option) {
                  return _c("div", { staticClass: "level mb-xs" }, [
                    _c(
                      "div",
                      { staticClass: "level-left" },
                      [
                        _c(
                          "toggle-switch",
                          {
                            attrs: { "btn-class": "is-230" },
                            model: {
                              value: option[0].isEnabled,
                              callback: function ($$v) {
                                _vm.$set(option[0], "isEnabled", $$v)
                              },
                              expression: "option[0].isEnabled",
                            },
                          },
                          [
                            _c("option", { domProps: { value: true } }, [
                              _vm._v(_vm._s(option[0].label) + " übetragen"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: false } }, [
                              _vm._v("nicht übertragen"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                }),
              ]
            : _c("div", { staticClass: "alert is-danger" }, [
                _c("p", [_c("strong", [_vm._v("Achtung:")])]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n                Wenn die Zusatzleistung gelöscht wird, werden Anfragen zu dieser Zusatzlesistung auch in den Reisen gelöscht!\n            "
                  ),
                ]),
              ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "level", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Abbrechen")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button is-danger",
              class: { "is-loading": _vm.isLoading },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleDelete.apply(null, arguments)
                },
              },
            },
            [_vm._v("Löschen")]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }