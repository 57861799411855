var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column is-one-third" }, [
        _c("div", { staticClass: "bp-box is-price" }, [
          _c("header", [
            _c(
              "div",
              [
                _c("other-service-icons", {
                  attrs: { type: _vm.request.otherServiceType.category },
                }),
                _vm._v(" Zusatztleistung\n                    "),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("main", [
            _c(
              "div",
              { staticClass: "is-standard calculation" },
              [
                _c(
                  "div",
                  [
                    _vm.request.contingent.priceType === "per_person"
                      ? [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("person"),
                          ]),
                          _vm._v(" Teilnehmer\n                            "),
                        ]
                      : [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("people"),
                          ]),
                          _vm._v(" Gruppenpreis\n                            "),
                        ],
                  ],
                  2
                ),
                _vm._v(" "),
                _c("input-price", {
                  attrs: {
                    "provider-prices": _vm.request.contingent.providerPrices
                      .buy_price
                      ? _vm.request.contingent.providerPrices.buy_price
                      : [],
                    "small-input": true,
                    "price-currency": _vm.request.requestCurrency,
                  },
                  model: {
                    value: _vm.standardPrice,
                    callback: function ($$v) {
                      _vm.standardPrice = $$v
                    },
                    expression: "standardPrice",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "select" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.request.contingent.priceType,
                          expression: "request.contingent.priceType",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.request.contingent,
                            "priceType",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "per_person" } }, [
                        _vm._v("pro Person"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "per_group" } }, [
                        _vm._v("pro Gruppe"),
                      ]),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column is-one-third" },
        [
          _c("free-places", {
            attrs: { request: _vm.request },
            model: {
              value: _vm.request.freePlaces,
              callback: function ($$v) {
                _vm.$set(_vm.request, "freePlaces", $$v)
              },
              expression: "request.freePlaces",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c("item-prices", {
            attrs: { provider: _vm.provider },
            model: {
              value: _vm.request.itemPrices,
              callback: function ($$v) {
                _vm.$set(_vm.request, "itemPrices", $$v)
              },
              expression: "request.itemPrices",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }