<template>
    <div class="row is-filters">
        <i class="fa fa-lg fa-filter"></i>
        
        <template v-if="hotel || ferry">
            <multiselect
                track-by="key"
                label="label"
                v-bind:options="options.types"
                v-model="filters.type"
                placeholder="Art filtern"
            />
        </template>
        
        <multiselect
            v-bind:options="options.agencies"
            track-by="id"
            label="name"
            v-model="filters.agency"
            placeholder="Agentur filtern"
        />

        <div class="flatpickr-combined">
            <input-date v-model="filters.startAt" />
            <span>-</span>
            <input-date v-model="filters.endAt" />
        </div>
    </div>
</template>

<script>
import { TableFilters } from '@components/CRUD/DataTable';
import { InputDate } from '@components/form'

export default {
    mixins: [ TableFilters ],

    computed: {
        hotel: function () {
            return this.$parent.hotel
        },
        
        ferry: function () {
            return this.$parent.ferry;
        }
    },

    components: {
        InputDate
    }
}
</script>
