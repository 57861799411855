<template>
    <div>
        <div class="columns">
            <div class="column is-one-third">
                <div class="bp-box is-price">
                    <header>
                        <div><i class="material-icons">airplanemode_active</i> <b>Airline</b></div>
                    </header>

                    <main>
                        <!-- Standard -->
                        <div class="is-standard calculation">
                            <div>
                                <i class="material-icons">airplanemode_active</i>
                                Tickets
                            </div>
                            <div>
                                <input-price
                                    v-model="standardPrice"
                                    :small-input="true"
                                    :price-currency="request.requestCurrency"
                                />
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>


        <div class="columns">
            <div class="column is-one-third">
                <free-places v-model="request.freePlaces" v-bind:request="request"></free-places>
            </div>
            <div class="column">
                <item-prices v-model="request.itemPrices" v-bind:provider="provider"></item-prices>
            </div>
        </div>
        <div></div>
    </div>
</template>



<script>
import mixins from '../mixinsIndividually';


export default {
    mixins: [mixins],
    computed: {
        provider:  function () { return this.request.airline },

        standardPrice: {
            get: function () {
                return this.request.contingent.price.amount
            },

            set: function (value) {
                this.request.contingent.price = {
                    amount: value,
                    currency: this.request.requestCurrency
                }
            }
        }
    }
}
</script>
