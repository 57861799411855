<template>
    <popover trigger="hover" v-if="isProvider">
        <span v-on:mouseover="loadBookingStand">{{ bookings }}</span>
        <template slot="popover">
            <div class="has-text-weight-semibold">Buchungsstandentwicklung:</div>
            <span v-if="bookingStand.loading">Bitte warten...</span>
            <template v-else>
                <ul v-if="bookingStand.items.length > 0">
                    <li
                        v-for="stand in bookingStand.items"
                        v-bind:key="stand.id"
                    >{{stand.date | fullYear}} - {{stand.numberOfPersons}} Pax</li>
                </ul>
                <template v-else>Es wurde noch nichts eingetragen</template>
            </template>
        </template>
    </popover>
    <div v-else>{{bookings}}</div>
</template>

<script>
import has from 'lodash/has';
import get from 'lodash/get';
import { getBookingStands } from '@api';
import { fullYear } from '@utilities/functions';
import Popover from '@components/Popover';
import Cell from './cell';

export default {
    mixins: [ Cell ],

    props: {
        value: {
            type: [Object, Number],
        },
    },

    data: function () {
        return {
            bookingStand: {
                items: [],
                loading: false,
            }
        }
    },

    computed: {
        isHotel: function () {
            return !!get(this, 'value.info.doubleRooms', false)
        },

        isFerry: function () {
            return !!get(this, 'value.info.doubleCabins', false)
        },

        isProvider: function () {
            return this.isHotel || this.isFerry
        },

        bookings: function () {
            if (this.isHotel) {
                const { info } = this.value;
                const { doubleRooms, otherRooms, singleRooms } = info;
                const personsReserved = has(info, 'personsReserved') ? info.personsReserved : this.row.info.personsReserved;

                return `${personsReserved} Pax (`+
                    `${doubleRooms.totalReserved} DZ,
                    ${singleRooms.totalReserved} EZ`+
                    `${otherRooms.totalReserved > 0 ? `, ${otherRooms.totalReserved} MBZ` : ''})`;

            } else if (this.isFerry) {
                const { info } = this.value;
                const { doubleCabins, otherCabins, singleCabins } = info;
                const personsReserved = has(info, 'personsReserved') ? info.personsReserved : this.row.info.personsReserved;

                return `${personsReserved} Pax (`+
                    `${doubleCabins.totalReserved} DK,
                    ${singleCabins.totalReserved} EK` +
                    `${otherCabins.totalReserved > 0 ? `, ${otherCabins.totalReserved} MBK` : ''})`
            } else {
                return `${this.value} Pax`;
            }
        }
    },

    methods: {
        loadBookingStand: function () {
            this.bookingStand.loading = true;
            const orderID = has(this.row, 'order') ? this.row.order.id : this.row.id;

            getBookingStands(`?order=${orderID}`)
                .then(response => {
                    this.bookingStand = {
                        items: response.data,
                        loading: false,
                    }
                })
        }
    },

    components: {
        Popover
    },

    filters: {
        fullYear
    }
}
</script>
