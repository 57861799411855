import moment from 'moment';
import { currentUser } from '@utilities/functions';

export default {
    data: function () {
        return {
            emptyForm: {
                serviceDayTimes: [],
            }
        }
    },


}
