<template>
    <div>
        <div class="bp-box is-info">
            <header><i class="material-icons">info</i> Informationen zur Anfrage</header>

            <main>
                <div class="is-one-row" v-if="!request.ferry || editMode">
                    <button
                        v-bind:class="['btn-single-toggle',  onlyFromOrder ? '' : 'is-active']"
                        v-on:click="onlyFromOrder = !onlyFromOrder"
                        v-bind:title="onlyFromOrder ? 'Suche ist auf obigen Route eingeschränkt' : 'Suche ist nicht eingeschränkt'"
                        style="border: 1px solid #dddddd; margin-right: 5px; min-width: 22px; min-height: 22px;"
                    ><i class="material-icons" style="margin: 0">flag</i></button>

                    <div>
                        <ferry-select
                            style="width: 400px"
                            v-model                = "request.ferry"
                            v-bind:placeholderInfo = "onlyFromOrder ? localPlaceholderInfo : {startPort: null, endPort: null, type: null}"
                            v-bind:nullable        = "false"
                            v-bind:autoSelect      = "false"
                        ></ferry-select>
                    </div>

                    <agency-select
                        v-if="editMode"
                        :value          = "request.agency"
                        v-bind:provider = "null"
                        text            = "Agentur"
                        prefill
                        v-on:update     = "agency => request.agency = agency"
                    ></agency-select>
                </div>
                <div class="is-one-row">
                    <div >
                        <label><i class="material-icons">import_contacts</i> Status:</label>
                        <div class="select">
                            <select v-model="request.requestStatus.status">
                                <template v-for="status in requestStatuses">
                                    <option v-bind:value="status.key">{{ status.value }}</option>
                                </template>
                            </select>
                        </div>
                    </div>

                    <div >
                        <i class="material-icons">notifications_none</i> Termine:
                        <multiselect
                            class="is-shorter"
                            v-if="request.todos.length > 1"
                            v-model="request.optionTodo"
                            :options="request.todos"
                            label="name"
                            track-by="id"
                        />
                        <datepicker
                            v-bind:inputClass="['is-date']"
                            v-bind:config="{
                            dateFormat: 'd.m.Y H:i:S',
                            wrap: true,
                            parseDate:  function (dateStr) { return moment(dateStr, 'DD.MM.YYYY hh:mm:ss').toDate(); },
                            formatDate: function (dateObj) { return moment(dateObj).format('DD.MM.YYYY hh:mm:ss'); },
                            allowInput: true,
                            altInput: true,
                        }"
                            v-model="deadline"
                            placeholder="Deadline"
                        />
                    </div>

                    <div >
                        <label><i class="material-icons">assignment</i> Schiff:</label>
                        <input type="text" class="input" v-model="request.shipName" />
                    </div>

                    <div >
                        <label><i class="material-icons">format_list_numbered</i> Reservierungs-Nr:</label>
                        <input type="text" class="input" v-model="request.optionNr" />
                    </div>
                    <div>
                        <label><i class="material-icons">contact_phone</i> Ansprechpartner:</label>
                        <multiselect
                            class="is-shorter"
                            style="min-width: 180px"
                            v-model="request.requestContact"
                            v-bind:options="this.request.agency ? this.agencyContactOptions : this.providerContactOptions"
                            track-by="id"
                            :custom-label="contact => `${contact.fullName}`"
                            :to-be-copied="false"
                        >
                        </multiselect>
                    </div>
                </div>
                <div class="is-last-row" v-if="showAssignedContactAddress">

                    <div>
                        <label><i class="material-icons">list</i> Für Kunde sichtbar:</label>
                        <button
                            title="Adresse anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('address')}"
                            @click="setVisibleContactDetails('address')"
                        ><i class="material-icons">home</i></button>
                    </div>

                    <div v-if="request.visibleContactDetails.includes('address')">
                        <i class="material-icons">home</i> Adresse
                        <multiselect
                            class="is-shorter"
                            style="min-width: 150px"
                            v-model="request.assignedAddress"
                            v-bind:options="providerAddressOptions"
                            track-by="id"
                            :custom-label="address => (address.title ? address.title + ': ' + address.streetAddress : address.streetAddress).substring(0,50)"
                            :to-be-copied="false"
                            placeholder="Keine Adresse"

                        >
                        </multiselect>
                    </div>
                    <div v-if="request.visibleContactDetails.includes('address')">
                        <i class="material-icons">list</i> Kontaktdetails sichtbar:
                        <button
                            title="Festnetz anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('phoneFixed')}"
                            @click="setVisibleContactDetails('phoneFixed')"
                            v-if="request.assignedContact && request.assignedContact.phoneFixed"
                        ><i class="material-icons">phone</i></button>
                        <button
                            title="Mobile anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('phoneMobile')}"
                            @click="setVisibleContactDetails('phoneMobile')"
                            v-if="request.assignedContact && request.assignedContact.phoneMobile"
                        ><i class="material-icons">smartphone</i></button>
                    </div>


                </div>



            </main>
        </div>


        <div class="columns">
            <template v-if="request.type === 'night'">
                <div class="column">
                    <simple-ferry-contingent
                        v-if="request.contingent"
                        v-model="request.contingent.doubleCabins"
                        type="ferry"
                        v-bind:nrOfPersons="2"
                        v-bind:types="ferryTypes"
                        v-bind:isInPackage="request.package ? true : false"
                    ></simple-ferry-contingent>
                </div>

                <div class="column">
                    <simple-ferry-contingent
                        v-if="request.contingent"
                        v-model="request.contingent.singleCabins"
                        type="ferry"
                        v-bind:nrOfPersons="1"
                        v-bind:types="ferryTypes"
                        v-bind:isInPackage="request.package ? true : false"
                    ></simple-ferry-contingent>
                </div>

                <div class="column">
                    <ferry-contingent
                        v-if="request.contingent"
                        v-model="request.contingent.otherCabins"
                        type="ferry"
                        v-bind:types="ferryTypes"
                        v-bind:isInPackage="request.package ? true : false"
                    ></ferry-contingent>
                </div>
            </template>

            <template v-else>
                <div class="column">
                    <div class="bp-box is-tickets">
                        <header>
                            <div><i class="material-icons">credit_card</i> Tickets Tagfähre</div>
                            <div>
                                <i class="material-icons">check_circle</i>
                                <i class="material-icons">help</i>
                            </div>
                            <div><button v-if="!request.package" title="Extraticket/-kabine für Reisebegleitung" v-on:click="addExtra"><i class="material-icons">person_add</i></button></div>
                        </header>

                        <main>
                            <div>
                                <div><i class="material-icons">accessibility</i> Pax</div>
                                <div>
                                    <input
                                        class="input"
                                        type="text"
                                        v-model.number="request.contingent.ticketsReserved"
                                        v-on:focus="$event.target.select()"
                                    />
                                    <input
                                        class="input"
                                        type="text"
                                        v-model.number="request.contingent.ticketsAsked"
                                        v-on:focus="$event.target.select()"
                                    />
                                </div>
                                <div><button
                                    class="button"
                                    title="Copy value"
                                    v-on:click="copyDayFerryContingent"
                                >
                                    <i class="material-icons">check_circle</i>
                                    <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                                    <i class="material-icons">help</i>
                                </button></div>
                            </div>

                            <div class="extra-people" v-if="hasExtra && !request.package">
                                <div>
                                    <i class="material-icons">person_add</i>
                                    <input
                                        class="input"
                                        type="text"
                                        v-model="request.contingent.ticketsExtraDescription"
                                    />
                                    <button class="btn-delete" title="Löschen" v-on:click="deleteExtra" tabindex="-1"><i class="material-icons">delete</i></button>
                                </div>
                                <div>
                                    <div class="control"><input
                                        class="input"
                                        type="text"
                                        v-model.number="request.contingent.ticketsExtraReserved"
                                        v-on:focus="$event.target.select()"
                                    /></div>
                                    <div class="control"><input
                                        class="input"
                                        type="text"
                                        v-model.number="request.contingent.ticketsExtraAsked"
                                        v-on:focus="$event.target.select()"
                                    /></div>
                                </div>
                                <div><button
                                    class="button"
                                    title="Copy value"
                                    v-on:click="request.contingent.ticketsExtraReserved = request.contingent.ticketsExtraAsked"
                                >
                                    <i class="material-icons">check_circle</i>
                                    <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                                    <i class="material-icons">help</i>
                                </button></div>
                            </div>
                        </main>
                    </div>
                </div>
            </template>
        </div>


        <div class="columns">
            <div class="column is-one-third">
                <tip-tap v-model="request.notes" />
            </div>
            <div class="column">
                <item-prices
                    v-if="request.package"
                    :is-package="true"
                    v-bind:isWithoutPrice="false"
                    v-model="request.package.itemPrices"
                    v-bind:provider="provider"
                ></item-prices>
                <item-prices
                    v-else
                    v-bind:isWithoutPrice="false"
                    v-model="request.itemPrices"
                    v-bind:provider="provider"
                ></item-prices>
            </div>
        </div>


        <p class="explanation">
            <i class="material-icons">check_circle</i> = bestätigtes Kontingent
            <i class="material-icons">help</i> = angefragtes Kontingent
            <i class="material-icons">person_add</i> = Extrazimmer für Busfahrer, Reiseleiter, etc.
        </p>
    </div>
</template>



<script>
import { fullYear } from '@utilities/functions';
import AsyncSelect  from '@components/form/AsyncSelect';
import FerrySelect           from '@orders/placeholders/components/forms/FerrySelect';
import FerryContingent       from '../components/contingent/Ferry';
import SimpleFerryContingent from '../components/contingent/SimpleFerry';
import mixins                from './mixins';


export default {
    mixins: [mixins],


    props: {
        placeholderInfo: { type: Object, required: true }
    },


    data: function () {
        return {
            hasExtra: false
        }
    },


    computed: {
        localPlaceholderInfo: function () {
            return {
                startPort: this.request.startPort,
                endPort:   this.request.endPort,
                type:      this.placeholderInfo.type
            };
        },


        ferryTypes: function () { return this.$store.state.optionsCabinTypes; },
        provider:   function () { return this.request.ferry }
    },


    methods: {
        addExtra: function () {
            this.request.contingent.ticketsExtraDescription = '';
            this.request.contingent.ticketsExtraReserved    = 0;
            this.request.contingent.ticketsExtraAsked       = 0;
            this.hasExtra = true;
        },


        deleteExtra: function () {
            this.request.contingent.ticketsExtraDescription = null;
            this.request.contingent.ticketsExtraReserved    = null;
            this.request.contingent.ticketsExtraAsked       = null;
            this.hasExtra = false;
        },


        /*arrangeCabins: function (array) {
            // moving 'outside' first (for the case we have more than 2 items)
            let localArray = [array.filter(x => x.type.type === 'outside')[0], ...array.filter(x => x.type.type !== 'outside')];

            // moving 'inside' first
            return [localArray.filter(x => x.type.type === 'inside')[0], ...localArray.filter(x => x.type.type !== 'inside')]
        },*/


        copyDayFerryContingent: function () {
            this.request.contingent.ticketsReserved = this.request.contingent.ticketsAsked;
            this.request.contingent.ticketsAsked    = 0;
        }
    },


    created: function () {
        this.hasExtra = !!(this.request.contingent.ticketsExtraAsked ||
            this.request.contingent.ticketsExtraReserved);

        //this.request.contingent.singleCabins = this.arrangeCabins(this.request.contingent.singleCabins);
        //this.request.contingent.doubleCabins = this.arrangeCabins(this.request.contingent.doubleCabins);
    },
    mounted: function() {
        this.fetchContactOptions();
    },

    watch: {

        // Fixing empty strins to 0 (zero)
        'request.contingent.ticketsAsked': function () {
            if (this.request.contingent.ticketsAsked === '') {
                this.request.contingent.ticketsAsked = 0;
            }
        },


        // Fixing empty strins to 0 (zero)
        'request.contingent.ticketsReserved': function () {
            if (this.request.contingent.ticketsReserved === '') {
                this.request.contingent.ticketsReserved = 0;
            }
        },


        // Fixing empty strins to 0 (zero)
        'request.contingent.ticketsExtraAsked': function () {
            if (this.request.contingent.ticketsExtraAsked === '') {
                this.request.contingent.ticketsExtraAsked = 0;
            }
        },


        // Fixing empty strins to 0 (zero)
        'request.contingent.ticketsExtraReserved': function () {
            if (this.request.contingent.ticketsExtraReserved === '') {
                this.request.contingent.ticketsExtraReserved = 0;
            }
        }
    },


    components: {
        AsyncSelect,
        FerryContingent,
        SimpleFerryContingent,
        FerrySelect
    }
}
</script>
