var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Anbieter mit Leistung verknüpfen",
        createTitle: "Anbieter mit Leistung verknüpfen",
      },
    },
    [
      _c(
        "table",
        [
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [
                _vm._v("Verküpfung mit:"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "buttons has-addons" }, [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    class: { "is-primary": !_vm.form.link },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changeLink(false)
                      },
                    },
                  },
                  [_vm._v("einz. Leistungsträgern")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button",
                    class: { "is-primary": _vm.form.link },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changeLink(true)
                      },
                    },
                  },
                  [_vm._v("allen Leistungsträgern einer Kategorie")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", { staticClass: "is-top" }, [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "providerType" } },
                [
                  _vm.form.link
                    ? [_vm._v("Leistungsträger - Kategorie:")]
                    : [_vm._v("Leistungsträger:")],
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("div", { staticClass: "buttons has-addons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      class: {
                        "is-primary": _vm.form.providerType === "hotel",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changeType("hotel")
                        },
                      },
                    },
                    [_vm._v("Hotels")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      class: {
                        "is-primary": _vm.form.providerType === "ferry",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changeType("ferry")
                        },
                      },
                    },
                    [_vm._v("Fähren")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      class: {
                        "is-primary": _vm.form.providerType === "airline",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changeType("airline")
                        },
                      },
                    },
                    [_vm._v("Airlines")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      class: {
                        "is-primary": _vm.form.providerType === "other",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changeType("other")
                        },
                      },
                    },
                    [_vm._v("Sonstige")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      class: {
                        "is-primary": _vm.form.providerType === "agency",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changeType("agency")
                        },
                      },
                    },
                    [_vm._v("Agentur")]
                  ),
                ]),
                _vm._v(" "),
                !_vm.form.link
                  ? [
                      _c("async-select", {
                        attrs: {
                          api: _vm.apiType,
                          placeholder: "Select provider",
                          crudLinkTitle:
                            "Fügen Sie eine neue Leistungsträger hinzu",
                        },
                        model: {
                          value: _vm.form.provider,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "provider", $$v)
                          },
                          expression: "form.provider",
                        },
                      }),
                    ]
                  : _vm.form.link && _vm.form.providerType === "other"
                  ? [
                      _c("multiselect", {
                        attrs: {
                          "track-by": "key",
                          label: "value",
                          options: _vm.options.otherTypes,
                        },
                        model: {
                          value: _vm.form.otherType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "otherType", $$v)
                          },
                          expression: "form.otherType",
                        },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _vm.form.link
            ? [
                _vm.form.providerType !== "airline" &&
                _vm.form.providerType !== "ferry"
                  ? _c("tr", [
                      _c("th", [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Leistung gültig für:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("location-select", {
                            attrs: { hasDestination: "" },
                            model: {
                              value: _vm.location,
                              callback: function ($$v) {
                                _vm.location = $$v
                              },
                              expression: "location",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                [
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [
                      _c("h2", [_vm._v("Anbieter auswwählen")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm.allSelected
                        ? _c(
                            "button",
                            {
                              staticClass: "button",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.changeSelection.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("Auswahl entfernen")]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "button",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.changeSelection.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("Alles auswählen")]
                          ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c(
                      "td",
                      { attrs: { colspan: "2" } },
                      [
                        _c("list-select", {
                          attrs: { api: _vm.apiType, params: _vm.listParams },
                          on: { updateItems: _vm.updateItems },
                          model: {
                            value: _vm.form.providers,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "providers", $$v)
                            },
                            expression: "form.providers",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c(
                      "td",
                      { attrs: { colspan: "2" } },
                      [
                        _c("pagination", {
                          attrs: { simple: "", "show-per-page": "" },
                          model: {
                            value: _vm.pagination,
                            callback: function ($$v) {
                              _vm.pagination = $$v
                            },
                            expression: "pagination",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }