<template>
    <columns property="organisationBankAccounts" v-on:add="openForm" :load-options="false">
        <column-item
            v-bind:update="{updatedBy: item.updatedBy, updatedAt: item.updatedAt}"
            :api="'/api/organisation_bank_accounts/' + item.id"
            slot-scope="{item}"
            :label="item.accountPlanItem ? item.accountPlanItem.accountingNumber : ''"
            v-on:edit="openForm(item)"
            v-on:delete="deleteItem('organisationBankAccounts', item)"
        >

            <ul>
                <li><strong>{{ item.name }}</strong></li>
                <li v-if="item.type !== 'cash_account'"><i class="material-icons">person</i>{{ item.accountHolder }}</li>
                <li v-if="item.type !== 'cash_account'"><i class="material-icons">credit_card</i>{{ item.iban }}</li>
                <li v-if="item.type !== 'cash_account' && item.bank"><i class="material-icons">account_balance</i>{{ item.bank.name }} ({{item.bank.bic }})</li>

            </ul>



        </column-item>
            <account-form ref="form" slot="form"/>
    </columns>
</template>

<script>
import Mixin             from '../tab';
import AccountForm       from './Form';
export default {
    mixins: [ Mixin ],
    data: function () {
        return  {
            newEmailContact: null,
            isNewEmailVisible: false,
        }
    },
    methods: {
        openForm: function (data = {}) {
            this.$refs.form.open({
                ...data
            });
        }
    },
    components: {AccountForm}
}
</script>
