<template>
    <div>
        <div class="columns">
            <div class="column">
                <div class="bp-box is-price">
                    <header>
                        <div><i class="material-icons no-spacing">local_hotel</i><i class="material-icons">local_hotel</i> <b>Doppelzimmer</b></div>
                        <div><i class="material-icons">local_dining</i> {{ getValueWithKey({ key: request.board, optionList: boardList }) }}</div>
                    </header>

                    <main>
                        <!-- Standard -->
                        <div class="is-standard calculation">
                            <div>
                                {{ getAccommodationLabel(standardRoom.type.type, standardRoom.type.persons, roomTypes)}}
                            </div>
                            <input-price
                                v-model="standardPrice"
                                :provider-prices="standardRoom.providerPrices"
                                :small-input="true"
                                :price-currency="request.requestCurrency"
                            />
                        </div>


                        <!-- The rest -->
                        <div
                            v-for="accomodation in doubleRooms"
                            v-bind:key="accomodation.id"
                            class="calculation"
                        >
                            <div>
                                {{ getAccommodationLabel(accomodation.type.type, accomodation.type.persons, roomTypes)}}
                            </div>
                            <input-price
                                :small-input="true"
                                v-bind:value="getDifference(accomodation)"
                                :provider-prices="accomodation.providerPrices"
                                v-on:input="(v) => { updatePrices(v, accomodation, 'doubleRooms') }"
                                :price-currency="request.requestCurrency"
                            />
                        </div>
                    </main>
                </div>
            </div>


            <div class="column">
                <div class="bp-box is-price calculation">
                    <header>
                        <div><i class="material-icons">local_hotel</i> <b>Einzelzimmer</b></div>
                    </header>

                    <main>
                        <div v-for="accomodation in singleRooms" v-bind:key="accomodation.id">
                            <div>
                                {{ getAccommodationLabel(accomodation.type.type, accomodation.type.persons, roomTypes)}}
                            </div>
                            <input-price
                                :small-input="true"
                                :provider-prices="accomodation.providerPrices"
                                v-bind:value="getDifference(accomodation)"
                                v-on:input="(v) => { updatePrices(v, accomodation, 'singleRooms') }"
                                :price-currency="request.requestCurrency"
                            />
                        </div>
                    </main>
                </div>
            </div>


            <div class="column">
                <div class="bp-box is-price calculation">
                    <header>
                        <div><i class="material-icons no-spacing">local_hotel</i><i class="material-icons no-spacing">local_hotel</i><i class="material-icons">local_hotel</i> <b>Mehrbettzimmer</b></div>
                    </header>

                    <main>
                        <div v-for="accomodation in otherRooms" v-bind:key="accomodation.id">
                            <div>
                                {{ getAccommodationLabel(accomodation.type.type, accomodation.type.persons, roomTypes)}}
                            </div>
                            <input-price
                                :small-input="true"
                                :provider-prices="accomodation.providerPrices"
                                v-bind:value="getDifference(accomodation)"
                                v-on:input="(v) => { updatePrices(v, accomodation, 'otherRooms') }"
                                :price-currency="request.requestCurrency"
                            />
                        </div>
                    </main>
                </div>
            </div>
        </div>


        <div class="columns">
            <div class="column is-one-third">
                <free-places v-model="request.freePlaces" v-bind:request="request"></free-places>
            </div>
            <div class="column">
                <item-prices v-model="request.itemPrices" v-bind:provider="provider"></item-prices>
            </div>
        </div>
        <div></div>
    </div>
</template>



<script>
import toNumber from 'lodash/toNumber';
import isNumber from 'lodash/isNumber';
import mixins from '../mixinsIndividually';



export default {
    mixins: [mixins],

    computed: {
        provider:  function () { return this.request.hotel },
        types:     function () { return this.$store.state.optionsRoomTypes; },
        boardList: function () { return this.$store.state.options.boardList; },

        doubleRooms: function () { return this.request.contingent.doubleRooms.filter(room => room.type.type !== 'standard') },
        singleRooms: function () { return this.request.contingent.singleRooms },
        otherRooms: function () { return this.request.contingent.otherRooms },

        roomTypes: function() {return this.$store.state.options.roomTypes.crud_prices ? this.$store.state.options.roomTypes.crud_prices : [] },

        standardRoom: function () {
            return this.request.contingent.doubleRooms
                .find(room => room.type.type === 'standard')
        },

        standardPrice: {
            get: function () {
                const price = this.standardRoom && this.standardRoom.price && this.standardRoom.price.amount;
                return !!price ? price : '0';
            },

            set: function (price) {
                const newPrice = !isNumber(price) ? toNumber(price) : price;

                this.request.contingent.doubleRooms = this.request.contingent.doubleRooms
                    .map(room => {
                            if(room.type.type === 'standard'){
                                return Object.assign({}, room, {
                                    price: {
                                        currency: this.request.requestCurrency,
                                        amount: newPrice.toString()
                                    }
                                })
                            }
                            return room;
                    });

            }
        },
    },

    methods: {
        updatePrices: function (newDifference, accomodation, type) {
            const newPrice = toNumber(newDifference);

            this.request.contingent[type] = this.request.contingent[type]
                .map(room => Object.assign({}, room, {
                    price: {
                        amount: room.id === accomodation.id ? newPrice.toString() : room.price.amount,
                        currency: this.request.requestCurrency
                    }
                }))
        },

        savePrice: function ({type, persons, price}) {
            const newPrice = !isNumber(price) ? toNumber(price) : price;

            if (type === 'standard' && persons === 2) {
                this.standardPrice = newPrice
            } else {
                const roomsType = persons === 2 ? 'doubleRooms' : (persons === 1 ? 'singleRooms' : 'otherRooms');
                this.request.contingent[roomsType] = this.request.contingent[roomsType]
                    .map(room => {
                        if (room.type.type === type && room.type.persons === persons) {
                            return Object.assign({}, room, {
                                price: {
                                    amount: newPrice.toString(),
                                    currency: this.request.requestCurrency
                                }
                            })
                        }

                        return room;
                    })
            }
        },

        savePrices: function (prices) {
            prices.forEach(price => {
                this.savePrice(price);
            })
        }
    },
}

</script>
