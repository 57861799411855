<template>
    <div>
        <modal-form
            editTitle="Aufgabe bearbeiten"
            createTitle="Neuen Aufgabe hinzufügen"
        >
            <table>
                <tr>
                    <th>
                        <label class="label">Tage vor Leistungsbeginn:</label>
                    </th>
                    <td>
                        <input type="text" class="input is-small" v-model.number="form.daysBeforeStartAt">
                    </td>
                </tr>
                <tr>
                    <th>
                        <label  class="label">Aufgabe:</label>
                    </th>
                    <td>
                        <multiselect
                            v-model="form.name"
                            v-bind:options="todoOptions"
                            v-bind:taggable="true"
                            v-on:tag="addName"
                            v-on:search-change="searchName"
                            v-on:close="closeName"
                        >
                            <span slot="caret"></span>
                        </multiselect>
                    </td>
                </tr>
                <tr>
                    <th>
                        <label  class="label">Art:</label>
                    </th>
                    <td>
                        <toggle-switch v-model="form.type">
                            <option value="external">Strenge Deadline</option>
                            <option value="internal">Weiche Deadline</option>
                        </toggle-switch>
                    </td>
                </tr>
               <tr>
                    <th class="is-top">
                        <label class="label">Gültig für:</label>
                    </th>
                    <td>
                        <div class="control">
                            <div class="checkboxes columns">
                                <div class="column is-one-third" v-for="status in options.requestStatuses" >
                                    <label class="checkbox">
                                        <input type="checkbox" v-model="form.validForRequestStatuses" v-bind:value="status.key" v-bind:key="status.key">
                                        {{ status.value }}
                                    </label>
                                </div>
                                <div class="column is-one-third">
                                    <label class="checkbox">
                                        <input type="checkbox" v-on:change="toggleCheckboxes" value="all" v-model="allStatuses">
                                        alle
                                    </label>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>

            </table>
        </modal-form>

    </div>
</template>

<script>
import { ToggleSwitch } from '@components/form';
import TabForm from '../form'
import InputDate                             from '@components/form/InputDate';
export default {
    mixins: [ TabForm ],
    form: {
        name: '',
        daysBeforeStartAt: null,
        type: 'external',
        validForRequestStatuses: [],
    },
    property: 'todoTemplates',

    data: function () {
        return {
            allStatuses: false,
            todoOptions: ['Storno', 'Zahlung', 'Namensliste'],
            nameSearch: '',
        }
    },

    methods: {
        toggleCheckboxes: function (event) {
            if (event.target.checked) {
                this.form.validForRequestStatuses = this.options.requestStatuses.map(item => item.key)
            } else {
                this.form.validForRequestStatuses = [];
            }
        },
        addName (newName) {
            if (newName !== "") {
                this.form.name = newName;
                if(!this.todoOptions.includes(newName)) {
                    this.todoOptions = [...this.todoOptions, newName];
                }
            }
        },

        searchName: function (name) {
            this.nameSearch = name;
        },

        closeName: function () {
            this.addName(this.nameSearch)
        },

    },
    components: {
        ToggleSwitch,
        InputDate
    },

}
</script>
