<template>
    <loading v-if="isLoading" />
    <div class="trip-details" v-else>
        <table-list
            type="provider_info"
            v-on:delete="item => deleteItem('providerTripDetails', item)"
        />
        <box-list
            type="box_map"
            v-on:delete="item => deleteItem('providerTripDetails', item)"
        />
        <box-list
            type="box"
            v-on:delete="item => deleteItem('providerTripDetails', item)"
        />
    </div>
</template>

<script>
import { List as BoxList } from './Box';
import { List as TableList } from './Table';
import mixin from '../tab';
import { getProviderTripDetails } from '@api'
import Loading from '@components/Loading'

export default {
    mixins: [ mixin ],

    components: {
        BoxList,
        TableList,
        Loading
    },

    data() {
        return {
            isLoading: false,
        }
    },

    activated() {
        this.isLoading = true;
        getProviderTripDetails({
            'provider.id': this.item.id,
            '_groups': ['image_read', 'image_google_map_read', 'info_read']
        }).then(response => {
            this.$store.commit('updateData', {
                providerTripDetails: response.data
            })
        }).then(() => {
            this.isLoading = false;
        })
    }
}
</script>

