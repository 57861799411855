<template>
    <span v-if="value">{{ price }} &euro;</span>
</template>

<script>
import { priceView } from '@utilities/functions'
import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        price: function () {
            return !!this.value && priceView(this.value)
        }
    }
}
</script>

