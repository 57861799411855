var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c("loading")
    : _c(
        "div",
        { staticClass: "trip-details" },
        [
          _c("table-list", {
            attrs: { type: "provider_info" },
            on: {
              delete: (item) => _vm.deleteItem("providerTripDetails", item),
            },
          }),
          _vm._v(" "),
          _c("box-list", {
            attrs: { type: "box_map" },
            on: {
              delete: (item) => _vm.deleteItem("providerTripDetails", item),
            },
          }),
          _vm._v(" "),
          _c("box-list", {
            attrs: { type: "box" },
            on: {
              delete: (item) => _vm.deleteItem("providerTripDetails", item),
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }