<template>
    <crud-table
        ref="table"

        api="order_concepts"
        url="/concepts/orders"
        :storeKey="`concepts/days//${item.id}/orderConcepts`"

        :filter="{'days.id': item.id}"

        label="Stammdaten"
        :name="{singular: 'reisekonzept', plural: 'reisekonzepten'}"

        :deleteRow="false"

        :columns="[
            { key: 'name', label: 'Name', searchable: true,  class: 'is-one-fifth' },
            { key: 'category', filter: 'category'},
            { key: 'destinations', filter: 'destinations.id' },
            { key: 'tags', sortable: false, filter: 'tags'},
            { key: 'days.length', sortable: false },
            { key: 'dataCheckedAt', filter: 'checkedAt' }
        ]"
    >
        <h2 slot="header">Reise-Gesamtkonzepte</h2>
    </crud-table>
</template>

<script>
import { CrudTable } from '@components/CRUD';
import tab from './tab';

export default {
    mixins: [tab],

    components: {
        CrudTable
    },

    methods: {
        addNewOrder: function () {
            window.location.href = '/orders/add';
        },
    }
}
</script>
