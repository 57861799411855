var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "row",
    [
      _c("template", { slot: "type" }, [
        _c("i", { staticClass: "material-icons" }, [
          _vm._v("subdirectory_arrow_right"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "date" },
        [
          _vm.hasPersons
            ? [
                _vm._v(
                  "\n            von " +
                    _vm._s(_vm.range.from || 0) +
                    " bis " +
                    _vm._s(_vm.range.to >= 0 ? _vm.range.to : "∞") +
                    "\n            "
                ),
                _vm.isSingle ? [_vm._v("EZ")] : [_vm._v("Pax")],
              ]
            : [_vm._v("-")],
        ],
        2
      ),
      _vm._v(" "),
      _vm.isSingleNight || _vm.isPerPersonNight
        ? _c("template", { slot: "nights" }, [
            _vm._v(_vm._s(this.price.nights) + " N"),
          ])
        : _c("template", { slot: "nights" }, [_vm._v("-")]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "name" },
        [
          _vm.isCustom ? [_vm._v("Zuschlag:")] : _vm._e(),
          _vm._v(" "),
          _vm.isSingle ? [_vm._v("Zuschlag EZ:")] : _vm._e(),
          _vm._v(" "),
          _vm.isSingleNight ? [_vm._v("Zuschlag EZ/Nacht:")] : _vm._e(),
          _vm._v(" "),
          _vm.isPerGroup ? [_vm._v("Zuschlag Gruppe: ")] : _vm._e(),
          _vm._v(" "),
          _vm.isPerPerson ? [_vm._v("Zuschlag: ")] : _vm._e(),
          _vm._v(" "),
          _vm.isPerPersonNight ? [_vm._v("Zuschlag/Nacht: ")] : _vm._e(),
          _vm._v("\n        " + _vm._s(_vm.price.name) + "\n    "),
        ],
        2
      ),
      _vm._v(" "),
      _c("template", { slot: "provider" }, [_vm._v("-")]),
      _vm._v(" "),
      _c("template", { slot: "currency" }, [
        _c("span", { staticClass: "is-currency" }, [
          _vm._v(_vm._s(_vm.currency)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "sellPrice" },
        [
          _vm.isPerGroup || _vm.isCustom
            ? _c("input-price", {
                attrs: {
                  value: _vm.groupPrice,
                  linked: "",
                  "value-in-order-currency": _vm.groupPriceInOrderCurrency,
                },
                on: {
                  unlink: function ($event) {
                    return _vm.$emit("unlink")
                  },
                },
              })
            : _c("span", { staticClass: "empty" }, [_vm._v("-")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "profit" },
        [
          _vm.isPerGroup ||
          _vm.isPerPerson ||
          _vm.isPerPersonNight ||
          _vm.isCustom
            ? _c("input-price", {
                attrs: {
                  value: _vm.personPrice,
                  linked: "",
                  highlight: _vm.personsInRange,
                  "value-in-order-currency": _vm.personPriceInOrderCurrency,
                },
                on: {
                  unlink: function ($event) {
                    return _vm.$emit("unlink")
                  },
                },
              })
            : _c("span", { staticClass: "empty" }, [_vm._v("-")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "margin" },
        [
          !_vm.supplementPrices || _vm.isSingle || _vm.isSingleNight
            ? _c("input-price", {
                attrs: {
                  value: _vm.singlePrice,
                  linked: "",
                  highlight: _vm.singleInRange,
                  "value-in-order-currency": _vm.singlePriceInOrderCurrency,
                },
                on: {
                  unlink: function ($event) {
                    return _vm.$emit("unlink")
                  },
                },
              })
            : _c("span", { staticClass: "empty" }, [_vm._v("-")]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }