<template>
    <div>
        <div
            v-for="concept in concepts"
            v-bind:key="concept.id"
            class="is-variation"
            v-bind:class="{ 'is-selected' : value === concept }"
            v-on:click.prevent="selectConcept(concept)"
        >
            <div v-if="concept.category" class="tag">
                <span class="is-truncated">{{categoryName(concept)}}</span>
            </div>
            <div v-else class="tag is-placeholder"></div>

            <span class="is-truncated" v-tooltip="{content: concept.name, delay: {show: 1000}}">{{ concept.name }}</span>
            
            <div class="icons" v-if="icons(concept).length > 0">
                <i class="material-icons" v-for="icon in icons(concept)" v-bind:key="icon">{{icon}}</i>
            </div>
        </div>
    </div>
</template>

<script>
import { tagIcons } from '@utilities/variables';

export default {
    props: {
        concepts: {
            type: Array,
            required: true
        },

        options: {
            type: Object,
            required: true
        },

        value: {}
    },

    methods: {
        selectConcept: function (concept) {
            this.$emit('select', concept)
        },

        categoryName: function (concept) {
            const keyValue = concept.category;
            const optionValue = this.options.order.categories && this.options.order.categories
                .find(i => i.key === keyValue);

            return optionValue && optionValue.value;
        },

        icons: function (concept) {
            return tagIcons.filter(tag => {
                return concept.tags.indexOf(tag.label) !== -1;
            }).map(tag => tag.label);
        },
    }
}
</script>
