<template>
    <div :class="{'is-additional': isAdditional}">
        <row v-on:dblclick.native="openModal" v-if="!hide">
            <template slot="type">
                <i class="material-icons">{{icon}}</i>
            </template>
            <template slot="name">{{name}}</template>
            <template slot="date">{{date}}</template>
            <template slot="nights">
                <template v-if="days && isOther">{{days}} T</template>
                <template v-else-if="nights">{{nights}} N</template>
                <template v-else>-</template>
            </template>

            <template slot="marginTax">
                <margin-tax v-model="marginTaxEnabled" :tax="marginTax" :margin="grossMargin" />
            </template>

            <template slot="buyPriceCalculation" v-if="showExchangeRates">
                <span class="empty" v-if="hideInputs">-</span>
                <input-price
                    :linked="linked"
                    v-model="buyPriceInCalculationCurrency"
                    v-else/>
            </template>
            <template slot="buyPrice">
                <span class="empty" v-if="hideInputs">-</span>
                <input-price
                    :is-blue="hasTwoCurrencies"
                    :linked="linked"
                    v-model="buyPrice"
                    :currency="currency"
                    :provider-prices="getProviderPrices(buyPriceType)"
                    v-else
                />
            </template>
            <template slot="sellPriceCalculation" v-if="showExchangeRates">
                <span class="empty" v-if="hideInputs">-</span>
                <input-price
                    v-model="sellPriceInCalculationCurrency"
                    v-else
                />
            </template>
            <template slot="sellPrice">
                <span class="empty" v-if="hideInputs">-</span>
                <input-price
                    v-model="sellPrice"
                    :provider-prices="getProviderPrices(sellPriceType)"
                    :is-blue="hasTwoCurrencies"
                    v-else
                />
            </template>
            <template slot="profitCalculation" v-if="showExchangeRates">
                <span class="empty" v-if="hideInputs">-</span>
                <input-price
                    v-model="profitInCalculationCurrency"
                    v-else/>
            </template>
            <template slot="profit">
                <span class="empty" v-if="hideInputs">-</span>
                <input-price
                    v-model="profit"
                    :is-blue="hasTwoCurrencies"
                    v-else
                />
            </template>
            <template slot="margin">
                <span class="empty" v-if="hideInputs">-</span>
                <input-price v-model="margin" v-else/>
            </template>
            <template slot="actions">
                <template v-if="isOther">
                    <i class="material-icons" v-on:click.prevent.stop="togglePriceType">
                        <template v-if="isPerPerson">people</template>
                        <template v-else>person</template>
                    </i>
                </template>
                <i class="material-icons" v-on:click.prevent.stop="toggleStatus">remove_shopping_cart</i>
                <i class="material-icons" v-on:click.prevent.stop="saveInCRUD" v-if="!isAirline">save</i>
            </template>
        </row>
        <div class="is-light" v-if="hideInputs">
            <template v-if="isHotel">
                <night-provider name="Doppelzimmer" v-bind:price="price" property="doubleRoom" />
                <night-provider name="Einzelzimmer" v-bind:price="price" property="singleRoomPremium" />
            </template>
            <template v-else-if="isFerry">
                <night-provider name="Doppelkabine" v-bind:price="price" property="insideDoubleCabin" />
                <night-provider name="Einzelkabine" v-bind:price="price" property="insideSingleCabinPremium" />
                <night-provider name="Doppelkabine Außen" v-bind:price="price" property="outsideDoubleCabin" />
                <night-provider name="Einzelkabine Außen" v-bind:price="price" property="outsideSingleCabinPremium" />
            </template>
            <template v-else-if="isTrain && placeholder.type === 'night'">
                <night-provider name="Doppelabteil" v-bind:price="price" property="secondDoubleCabin" />
                <night-provider name="Einzelabteil" v-bind:price="price" property="secondSingleCabinPremium" />
                <night-provider name="Doppelabteil 1. Klasse" v-bind:price="price" property="firstDoubleCabin" />
                <night-provider name="Einzelabteil 1. Klasse" v-bind:price="price" property="firstSingleCabinPremium" />
            </template>
            <template v-else-if="isTrain && placeholder.type === 'day'">
                <night-provider name="2. Klasse" v-bind:price="price" property="secondSingleCabinPremium" />
                <night-provider name="1. Klasse" v-bind:price="price" property="firstSingleCabinPremium" />
            </template>
        </div>
        <div class="is-light" v-if="isAirline">
            <airline-package v-bind:price="price" v-bind:included="isAirlineIncluded" />
        </div>
    </div>
</template>

<script>
import { serviceIcons } from '@utilities/variables';
import { placeholderPriceMixin } from '@calculations/functions';

import price from './price';

import NightProvider from './NightProvider';
import AirlinePackage from './AirlinePackage';
import Template from "@orders/subpages/TripDetails/General/Template";

export default {
    mixins: [ price, placeholderPriceMixin ],

    components: {
        Template,
        NightProvider,
        AirlinePackage,
    },

    computed: {
        hideInputs: function () {
            return (this.isFerry && this.placeholder.type === 'night') || this.isHotel || this.isAirlineIncluded || this.isTrain
        },

        icon: function () {
            if (this.price._type === 'other') {
                return serviceIcons[this.placeholder.otherServiceType.category]
            } else if (this.isHotel) {
                return 'local_hotel'
            } else if (this.isFerry) {
                return 'directions_boat'
            } else if (this.isTrain) {
                return 'directions_train'
            } else if (this.price._type === 'airline') {
                return 'airplanemode_active'
            }
        },

        isAdditional: function () {
            return this.price.placeholder.clientOffer.status === 'additional';
        },

        isHotel: function () {
            return this.price._type === 'hotel'
        },

        isFerry: function () {
            return this.price._type === 'ferry'
        },

        isTrain: function () {
            return this.price._type === 'train'
        },

        isAirline: function () {
            return this.price._type === 'airline'
        },

        isAirlineIncluded() {
            if (this.isAirline) {
                const flights = this.$store.getters.notIncludedPrices.airlinePrices
                    .filter(flight => flight.placeholder.id !== this.placeholder.id)
                    .filter(flight => flight.airlinePrice)
                    .find(flight => flight.airlinePrice === this.price.id);

                return !!flights
            }

            return false;
        },

        // Loaded prices
        buyPriceType: function() {  return 'buyPrice'; },
        sellPriceType: function() {  return 'sellPrice'; },
    },

    methods: {
        openModal: function() {
            this.$store.commit('updateModalPlaceholder', this.price)
        },

        saveInCRUD: function() {
            this.$store.commit('updateModalCRUD', this.price)
        }
    }
}
</script>
