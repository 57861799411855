var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "is-confirmation",
      class: { "is-local": _vm.isLocal },
      attrs: { visible: _vm.isVisible },
    },
    [
      _c("div", { attrs: { slot: "content" }, slot: "content" }, [
        _vm._v("\n        " + _vm._s(_vm.text) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "level", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  _vm.confirm = false
                },
              },
            },
            [_vm._v("Nein")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button is-primary",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.confirm = true
                },
              },
            },
            [_vm._v("Ja")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }