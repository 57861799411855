<template>
    <span>
        {{ row.startTrainStation && row.startTrainStation.name }}
        <template v-if="row.startTrainStation && row.endTrainStation">-</template>
        {{ row.endTrainStation && row.endTrainStation.name }}
    </span>
</template>

<script>
import Cell from './cell';

export default {
    mixins: [ Cell ],
}
</script>
