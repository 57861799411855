var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-request" },
    [
      _c("div", { staticClass: "overall-buttons" }, [
        _c(
          "button",
          {
            staticClass: "button is-large",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [
            _c("i", {
              staticClass: "fa fa-long-arrow-left",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Zurück zur Übersicht"),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("accordion", { attrs: { isCollapsible: false } }, [
        _c("div", { attrs: { slot: "header" }, slot: "header" }, [
          _c(
            "div",
            [
              _vm.newRequest.train
                ? _c("request-score", {
                    attrs: { rating: _vm.newRequest.train.buspaketRating },
                  })
                : _c("i", {
                    staticClass: "fa fa-plus-circle",
                    attrs: { "aria-hidden": "true" },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm._v(
                "\n                LEISTUNGSTRÄGER HINZUFÜGEN\n\n                "
              ),
              _c(
                "provider-info",
                {
                  staticClass: "header-extra-info",
                  attrs: { provider: _vm.newRequest.train },
                },
                [
                  _vm.newRequest.type === "day"
                    ? [
                        _c("div", [
                          _c(
                            "i",
                            { staticClass: "material-icons spacing-left" },
                            [_vm._v("perm_identity")]
                          ),
                          _vm._v(
                            " Kundenkontingent:\n                            " +
                              _vm._s(
                                _vm.$store.state.hotelContingent.info
                                  .personsAsked
                              ) +
                              " Personen\n                        "
                          ),
                        ]),
                      ]
                    : [
                        _c("div", [
                          _c(
                            "i",
                            { staticClass: "material-icons spacing-left" },
                            [_vm._v("perm_identity")]
                          ),
                          _vm._v(
                            " Kundenkontingent:\n                            "
                          ),
                          _c(
                            "i",
                            { staticClass: "material-icons no-spacing" },
                            [_vm._v("local_hotel")]
                          ),
                          _vm._v(" "),
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("local_hotel"),
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$store.state.hotelContingent.info
                                  .doubleRooms.totalAsked
                              ) +
                              " Doppelzimmer\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("local_hotel"),
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$store.state.hotelContingent.info
                                  .singleRooms.totalAsked
                              ) +
                              " Einzelzimmer\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "i",
                            { staticClass: "material-icons no-spacing" },
                            [_vm._v("local_hotel")]
                          ),
                          _vm._v(" "),
                          _c(
                            "i",
                            { staticClass: "material-icons no-spacing" },
                            [_vm._v("local_hotel")]
                          ),
                          _vm._v(" "),
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("local_hotel"),
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$store.state.hotelContingent.info.otherRooms
                                  .totalAsked
                              ) +
                              " Mehrbettzimmer\n                        "
                          ),
                        ]),
                      ],
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "label",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isMainRequest,
                      expression: "isMainRequest",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.isMainRequest)
                      ? _vm._i(_vm.isMainRequest, null) > -1
                      : _vm.isMainRequest,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.isMainRequest,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isMainRequest = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isMainRequest = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isMainRequest = $$c
                      }
                    },
                  },
                }),
                _vm._v(
                  "\n                    als Leistung festlegen\n                "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { slot: "content" }, slot: "content" }, [
          _c("div", { staticClass: "bp-box is-info" }, [
            _c(
              "main",
              [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("business"),
                ]),
                _vm._v(" Zuggesellschaft:\n\n                    "),
                _c(
                  "div",
                  [
                    _c("train-select", {
                      attrs: { placeholderInfo: _vm.localPlaceholderInfo },
                      model: {
                        value: _vm.newRequest.train,
                        callback: function ($$v) {
                          _vm.$set(_vm.newRequest, "train", $$v)
                        },
                        expression: "newRequest.train",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("agency-select", {
                  attrs: {
                    provider: _vm.newRequest.train,
                    text: "Agentur",
                    prefill: "",
                  },
                  on: { update: (agency) => (_vm.newRequest.agency = agency) },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "is-second-row" }, [
                  _c("div", [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("format_list_numbered"),
                    ]),
                    _vm._v(" Reservierungs-Nr:\n                            "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newRequest.optionNr,
                          expression: "newRequest.optionNr",
                        },
                      ],
                      staticClass: "input",
                      attrs: { type: "text" },
                      domProps: { value: _vm.newRequest.optionNr },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.newRequest,
                            "optionNr",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns" }, [
            _vm.newRequest.type === "night"
              ? _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _vm.newRequest.contingent
                      ? _c("simple-train-contingent", {
                          attrs: {
                            type: "train",
                            day: false,
                            category: _vm.placeholderInfo.category,
                            nrOfPersons: 2,
                            types: _vm.trainTypes,
                            isOnlyAsked: true,
                          },
                          model: {
                            value: _vm.newRequest.contingent.doubleCabins,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.newRequest.contingent,
                                "doubleCabins",
                                $$v
                              )
                            },
                            expression: "newRequest.contingent.doubleCabins",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column" },
              [
                _vm.newRequest.contingent
                  ? _c("simple-train-contingent", {
                      attrs: {
                        type: "train",
                        category: _vm.placeholderInfo.category,
                        day: _vm.newRequest.type !== "night",
                        nrOfPersons: 1,
                        types: _vm.trainTypes,
                        isOnlyAsked: true,
                      },
                      model: {
                        value: _vm.newRequest.contingent.singleCabins,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.newRequest.contingent,
                            "singleCabins",
                            $$v
                          )
                        },
                        expression: "newRequest.contingent.singleCabins",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.newRequest.type === "night"
              ? _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _vm.newRequest.contingent
                      ? _c("train-contingent", {
                          attrs: {
                            type: "train",
                            types: _vm.trainTypes,
                            isOnlyAsked: true,
                          },
                          model: {
                            value: _vm.newRequest.contingent.otherCabins,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.newRequest.contingent,
                                "otherCabins",
                                $$v
                              )
                            },
                            expression: "newRequest.contingent.otherCabins",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column is-offset-6" },
              [
                _c("item-prices", {
                  attrs: { isWithoutPrice: true, provider: _vm.provider },
                  model: {
                    value: _vm.newRequest.itemPrices,
                    callback: function ($$v) {
                      _vm.$set(_vm.newRequest, "itemPrices", $$v)
                    },
                    expression: "newRequest.itemPrices",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-large",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c("i", { staticClass: "material-icons" }, [_vm._v("close")]),
          _vm._v(" Abbrechen"),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-primary is-large",
          class: { "is-loading": _vm.isLoading },
          on: { click: _vm.saveNewRequest },
        },
        [
          _c("i", { staticClass: "material-icons" }, [_vm._v("check")]),
          _vm._v(" speichern und zur Email"),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-primary is-large",
          class: { "is-loading": _vm.isLoading },
          on: {
            click: function ($event) {
              return _vm.saveNewRequest("close")
            },
          },
        },
        [
          _c("i", { staticClass: "material-icons" }, [
            _vm._v("keyboard_arrow_right"),
          ]),
          _vm._v(" speichern und schließen"),
        ]
      ),
      _vm._v(" "),
      _c("confirmation", {
        attrs: {
          isVisible: _vm.isVisibleConfirmation,
          "is-local": "",
          text: "Soll die Beschreibung der Anfrage in den Platzhalter kopiert werden?",
        },
        on: { input: (value) => _vm.$emit("confirm", value) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }