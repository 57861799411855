var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "has-icon" },
    [
      _c("i", { staticClass: "fa fa-book" }),
      _vm._v(" "),
      _vm.text ? [_vm._v(_vm._s(_vm.text) + ": ")] : _vm._e(),
      _vm._v(" "),
      _c(
        "multiselect",
        {
          class: [_vm.text ? "has-margin-left" : ""],
          attrs: {
            options: _vm.agencies,
            loading: _vm.isLoading,
            placeholder: "Agentur suchen",
            "track-by": "id",
            label: "name",
            "allow-empty": true,
          },
          on: { "search-change": _vm.agencySearch },
          model: {
            value: _vm.agencySelected,
            callback: function ($$v) {
              _vm.agencySelected = $$v
            },
            expression: "agencySelected",
          },
        },
        [
          _vm.isEmptyResults
            ? _c("span", { attrs: { slot: "caret" }, slot: "caret" }, [
                _c(
                  "a",
                  {
                    staticClass: "multiselect-crud-link",
                    attrs: {
                      href: "/agencies?add",
                      title: "Neue Agentur hinzufügen",
                      target: "_blank",
                    },
                  },
                  [_c("i", { staticClass: "material-icons" }, [_vm._v("add")])]
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }