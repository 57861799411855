<template>
    <modal-form
        editTitle="Zugverbindung  bearbeiten"
        createTitle="Neue Zugverbindung hinzufügen"
        v-bind:isLoading="isLoading"
    >
        <table>
            <tr v-if="providerType === 'provider'">
                <th><label class="label">Buchung:</label></th>
                <td>
                    <toggle-switch v-model="type">
                        <option value="direct">direkt</option>
                        <option value="agency">über Agentur</option>
                    </toggle-switch>
                </td>
            </tr>
            <tr v-if="providerType === 'agency'">
                <th><label class="label">Buchung:</label></th>
                <td>
                    <toggle-switch v-model="type">
                        <option value="direct">No-Name</option>
                        <option value="train">bestimmter Zug</option>
                    </toggle-switch>
                </td>
            </tr>

            <tr v-if="type === 'agency'">
                <th><label class="label">Agentur:</label></th>
                <td>
                    <async-select
                        api="agencies"
                        v-model="agency"
                    />
                </td>
            </tr>

           <tr v-if="type === 'train'">
                <th><label class="label">Zuggesellschaft:</label></th>
                <td>
                    <async-select
                        api="trains"
                        v-model="train"
                    />
                </td>
            </tr>
            <tr>
                <th><label for="startTrainStation" class="label">Abfahrtsbahnhof:</label></th>
                <td>
                    <div class="control">
                        <async-select
                            api     = "train_stations"
                            v-model = "form.startTrainStation"
                            v-bind:custom-label = "item => item && item.name ? item.name + ' (' + item.place.name + ')' : ''"
                            crudLinkTitle       = "Fügen Sie einen neuen Bahnhof hinzu"
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="endTrainStation" class="label">Ankunftsbahnhof:</label></th>
                <td>
                    <div class="control">
                        <async-select
                            api     = "train_stations"
                            v-model = "form.endTrainStation"
                            v-bind:custom-label = "item => item && item.name ? item.name + ' (' + item.place.name + ')' : ''"
                            crudLinkTitle       = "Fügen Sie einen neuen Bahnhof hinzu"
                        />
                    </div>
                </td>
            </tr>
            <service-day-time-field label="Zugzeiten" :fields="['startTimeOfDay', 'endTimeOfDay', 'checkInTimeOfDay']" v-model="form.serviceDayTimes" />

            <tr>
                <th><label for="type" class="label">Art:</label></th>
                <td>
                    <toggle-switch v-model="form.type">
                        <option value="day">Tagzug</option>
                        <option value="night">Nachtzug</option>
                    </toggle-switch>
                </td>
            </tr>
            <tr>
                <th><label class="label">Zugart (oder leer):</label></th>
                <td>
                    <multiselect
                        v-if="(options.trainLabels.trainTypes)"
                        v-model="trainType"
                        track-by="key"
                        label="value"
                        v-bind:options="objectToKeyValueArray(options.trainLabels.trainTypes)"
                    />
                </td>
            </tr>
            <tr v-if="form.type === 'day'">
                <th><label class="label">Wagen (oder leer):</label></th>
                <td>
                    <multiselect
                        v-if="(options.trainLabels.wagonTypes)"
                        v-model="wagonType"
                        track-by="key"
                        label="value"
                        v-bind:options="objectToKeyValueArray(options.trainLabels.wagonTypes)"
                    />
                </td>
            </tr>
            <tr v-if="hasOwnRouteDescription">
                <th class="is-top"><label for="description" class="label">Routenbeschreibung:</label></th>
                <td>
                    <error-wrapper v-bind:message="errors.routeDescription">
                        <textarea id="description" rows="4" class="textarea" v-model="form.routeDescription"></textarea>
                    </error-wrapper>
                </td>
            </tr>
            <tr v-if="!form.id">
                <th><label class="label">Option:</label></th>
                <td>
                    <div class="control">
                        <label class="checkbox">
                            <input type="checkbox" v-bind:value="true" v-model="form.return">
                            <span>Rückfahrt autmoatisch hinzufügen</span>
                        </label>
                    </div>
                </td>
            </tr>
        </table>
    </modal-form>
</template>



<script>
import TabForm from '../../form'
import { Multiselect, InputDate, DateTime as InputTime, AsyncSelect, ToggleSwitch } from '@components/form';
import { addTrainRoute, editTrainRoute, getTrainRoute } from '@api';
import { prepareForBackend, objectToKeyValueArray } from '@utilities/functions';
import { serviceDayTimeField, serviceDayTimeMixin } from '@utilities/mixins';


export default {
    mixins: [ TabForm, serviceDayTimeMixin ],

    props: {
        providerType: {
            type: String,
            required: true
        }
    },

    data: function () {
        return {
            type: 'direct',
            params: {
                _groups: ['service_day_time', 'provider_train_route:trainStation'],
            }
        }
    },

    form: {
        startTrainStation: null,
        endTrainStation: null,
        agency: null,
        train: null,
        return: '',
        type: 'day',
        trainType: 'standard',
        wagonType: 'standard',
        serviceDayTimes: [],
        routeDescription: '',
    },

    property: 'trainRoutes',

    components: {
        Multiselect,
        InputTime,
        InputDate,
        AsyncSelect,
        ToggleSwitch,
        serviceDayTimeField
    },

    computed: {
        hasOwnRouteDescription: function () {
            return this.options.clientConfig && this.options.clientConfig.crud.train.hasOwnRouteDescription;
        },

        trainType: {
            get: function () {
                return objectToKeyValueArray(this.options.trainLabels.trainTypes).find(label => label.key === this.form.trainType);
            },
            set: function (value) {
                this.form.trainType = value.key;
            }
        },

        wagonType: {
            get: function () {
                return objectToKeyValueArray(this.options.trainLabels.wagonTypes).find(label => label.key === this.form.wagonType);
            },
            set: function (value) {
                this.form.wagonType = value.key;

            }
        },

        prepareForBackend: function () {
            return prepareForBackend({
                ...this.form
            })
        },

        trainRoutes: function () {
            return this.$store.state.data.trainRoutes
        },

        agency: {
            get: function () { return this.form.agency && !!this.form.agency.id ? this.form.agency : null },
            set: function (agency) { this.form.agency = agency }
        },

        train: {
            get: function () { return this.form.train && !!this.form.train.id ? this.form.train : null },
            set: function (provider) { this.form.train = provider }
        },
    },

    methods: {
        objectToKeyValueArray,

        setType: function(value) {
            this.type = value;
        },

        handleSubmit: function () {
            this.isLoading = true;

            if(this.providerType === 'agency'){
                this.form.agency = `/api/agencies/${this.$store.state.id}`;
            } else if(this.form.agency){
                this.form.agency = this.form.agency.id ? `/api/agencies/${this.form.agency.id}` : this.form.agency;
            }
            if(this.providerType === 'provider'){
                this.form.train = `/api/trains/${this.$store.state.id}`;
            } else if(this.form.train){
                this.form.train = this.form.train.id ? `/api/trains/${this.form.train.id}` : this.form.train;
            }

            if (this.editMode) {
                editTrainRoute({id: this.form.id, data: this.prepareForBackend}, this.params)
                    .then(response => {
                        this.visible = false;
                        this.notifySuccess('Die Zugverbindung wurde erfolgreich bearbeitet!')
                        this.$emit('submit')

                    }, error => {
                        this.notifyError("Item isn't edited! Error happened")
                    }).then(() => {
                        this.isLoading = false;
                    })
            } else {
                Promise.all([
                    addTrainRoute(this.prepareForBackend, this.params),
                    ...this.form.return ? [ addTrainRoute({
                        ...this.prepareForBackend,
                        startTrainStation: this.prepareForBackend.endTrainStation,
                        endTrainStation: this.prepareForBackend.startTrainStation
                    }, this.params) ] : []
                ]).then(([trainRoute, returnTrainRoute]) => {
                    this.visible = false;
                        this.notifySuccess('Die Zugverbindung wurde erfolgreich hinzugefügt')
                        this.$emit('submit')
                }, error => {
                    this.notifyError("Train route isn't added! Error happened")
                }).then(() => {
                    this.isLoading = false;
                })
            }

        },
    }
}
</script>
