<template>
  <nav class="menu">
        <p class="menu-label level">
            <span>Ordner</span>
            <i class="material-icons is-pointer" v-on:click.prevent="startAddMode">add_circle</i>
        </p>
        <ul class="menu-list">
            <li><a v-on:click.prevent.stop="selectFolder(null)" :class="{'is-active': !value}">Ungeordnet</a></li>
            <li v-for="folder in folders" :key="folder.id">
                <a
                    v-if="editMode !== folder.id"
                    v-on:click.prevent.stop="selectFolder(folder)"
                    class="level level-left"
                    :class="{'is-active': value && value.id === folder.id}"
                >
                    <span class="level-item">{{folder.name}}</span>

                    <span class="level hover-anchor">
                        <i class="material-icons" v-on:click.prevent.stop="startEditMode(folder)">edit</i>
                        <i class="material-icons" v-on:click.prevent.stop="deleteFolder(folder)">delete</i>
                    </span>
                </a>

                <input v-else ref="input" type="text" class="input" placeholder="Folder name" v-model="form.name" @keydown.esc="editMode = false" @keydown.enter="editFolder" />
            </li>
            <li v-if="addMode">
                <input ref="input" type="text" class="input" placeholder="Folder name" v-model="form.name" @keydown.esc="addMode = false" @keydown.enter="addFolder">
            </li>
        </ul>
    </nav>
</template>

<script>
import { store } from '@components/CRUD/Item/store';
import { notifySuccess, notifyError } from '@components/Notification';

export default {
    store,

    props: {
        value: {
            validator: prop => typeof prop === 'object' || prop === null,
            required: true
        } // Selected folder
    },

    computed: {
        folders: function() {
            return this.$store.state.data.imageFolders
        }
    },

    data: function() {
        return {
            addMode: false,
            editMode: false,

            form: {
                name: ''
            }
        }
    },

    methods: {
        startAddMode: function() {
            this.editMode = false;
            this.addMode = true
            this.form.name = '';

            this.$nextTick(() => {
                this.$refs.input.focus()
            })
        },

        startEditMode: function(folder) {
            this.addMode = false;
            this.editMode = folder.id;
            this.form.name = folder.name;

            this.$nextTick(() => {
                this.$refs.input[0].focus()
            })
        },

        addFolder: function () {
            this.$store.dispatch('updateProperty', { key: 'imageFolders', data: {
                name: this.form.name
            }})
            this.addMode = false;
        },

        editFolder: function () {
            this.$store.dispatch('updateProperty', { key: 'imageFolders', data: {
                id: this.editMode,
                name: this.form.name
            }})
            this.editMode = false;
        },

        deleteFolder: function (folder) {
            if (confirm('You really want to delete this folder?')) {
                this.$store.dispatch('deleteProperty', { key: 'imageFolders', id: folder.id })
                    .then(() => notifySuccess('Folder deleted successfully'),
                        error => notifyError('Error while deleting item')
                    );
            }
        },

        selectFolder: function(folder) {
            this.$emit('input', folder)
        }
    }
}
</script>
