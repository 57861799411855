var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "has-margin-top" }, [
    _c(
      "div",
      {
        staticClass: "bp-panel is-form has-no-title",
        staticStyle: { "min-height": "auto" },
      },
      [
        _c("div", { staticClass: "panel-content is-table-form" }, [
          _c(
            "table",
            [
              _c("tr", [
                _c("th", [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c(
                        "toggle-switch",
                        {
                          model: {
                            value: _vm.preview,
                            callback: function ($$v) {
                              _vm.preview = $$v
                            },
                            expression: "preview",
                          },
                        },
                        [
                          _c("option", { domProps: { value: false } }, [
                            _vm._v("Eingabe"),
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: true } }, [
                            _vm._v("Ausgabe"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("td"),
              ]),
              _vm._v(" "),
              !_vm.preview
                ? [
                    _c("tr", [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("td", [
                        _c("div", { staticClass: "control" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.periods,
                                expression: "periods",
                                modifiers: { number: true },
                              },
                            ],
                            staticClass: "input",
                            attrs: { type: "text" },
                            domProps: { value: _vm.periods },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.periods = _vm._n($event.target.value)
                                },
                                _vm.setPeriods,
                              ],
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.periods, function (period) {
                      return [
                        _c("tr", [
                          _c("td", { attrs: { colspan: "3" } }, [
                            _c("h2", [_vm._v(_vm._s(period) + ". Preisblock")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("th", [
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                staticClass: "input is-large",
                                attrs: {
                                  type: "text",
                                  placeholder:
                                    "06.09. - 17.09.2021 (Zubuchertermin)",
                                },
                                domProps: {
                                  value: _vm.getTableElement(period - 1, 0, 0),
                                },
                                on: {
                                  input: (input) =>
                                    _vm.setTableElement(
                                      period - 1,
                                      0,
                                      0,
                                      input
                                    ),
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                staticClass: "input",
                                attrs: {
                                  type: "text",
                                  placeholder: "Düsseldorf",
                                },
                                domProps: {
                                  value: _vm.getTableElement(period - 1, 0, 1),
                                },
                                on: {
                                  input: (input) =>
                                    _vm.setTableElement(
                                      period - 1,
                                      0,
                                      1,
                                      input
                                    ),
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                staticClass: "input",
                                attrs: { type: "text", placeholder: "München" },
                                domProps: {
                                  value: _vm.getTableElement(period - 1, 0, 2),
                                },
                                on: {
                                  input: (input) =>
                                    _vm.setTableElement(
                                      period - 1,
                                      0,
                                      2,
                                      input
                                    ),
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _vm._m(1, true),
                          _vm._v(" "),
                          _c("td", [
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                staticClass: "input",
                                attrs: {
                                  type: "text",
                                  placeholder: "2.176,- €*",
                                },
                                domProps: {
                                  value: _vm.getTableElement(period - 1, 1, 1),
                                },
                                on: {
                                  input: (input) =>
                                    _vm.setTableElement(
                                      period - 1,
                                      1,
                                      1,
                                      input
                                    ),
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                staticClass: "input",
                                attrs: {
                                  type: "text",
                                  placeholder: "2.176,- €*",
                                },
                                domProps: {
                                  value: _vm.getTableElement(period - 1, 1, 2),
                                },
                                on: {
                                  input: (input) =>
                                    _vm.setTableElement(
                                      period - 1,
                                      1,
                                      2,
                                      input
                                    ),
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _vm._m(2, true),
                          _vm._v(" "),
                          _c("td", [
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                staticClass: "input",
                                attrs: { type: "text", placeholder: "434,– €" },
                                domProps: {
                                  value: _vm.getTableElement(period - 1, 2, 1),
                                },
                                on: {
                                  input: (input) =>
                                    _vm.setTableElement(
                                      period - 1,
                                      2,
                                      1,
                                      input
                                    ),
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                staticClass: "input",
                                attrs: { type: "text", placeholder: "434,– €" },
                                domProps: {
                                  value: _vm.getTableElement(period - 1, 2, 2),
                                },
                                on: {
                                  input: (input) =>
                                    _vm.setTableElement(
                                      period - 1,
                                      2,
                                      2,
                                      input
                                    ),
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]
                    }),
                  ]
                : [
                    _c("tr", [
                      _vm._m(3),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "control" },
                          [
                            _c("resizable", [
                              _c("textarea", {
                                staticClass: "textarea",
                                attrs: { rows: "20", readonly: true },
                                domProps: { value: _vm.item.servicesHtml },
                              }),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _vm._m(4),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "control" },
                          [
                            _c("resizable", [
                              _c("textarea", {
                                staticClass: "textarea",
                                attrs: { rows: "20", readonly: true },
                                domProps: { value: _vm.pricePreview },
                              }),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
            ],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _c("label", { staticClass: "label" }, [
        _vm._v("Zeiträume (Preisblöcke):"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _c("label", { staticClass: "label" }, [
        _vm._v("Preise pro Person im DZ:"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _c("label", { staticClass: "label" }, [_vm._v("Einzelzimmer-Zuschlag:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _c("label", { staticClass: "label" }, [_vm._v("HTML für Leistungen:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _c("label", { staticClass: "label" }, [_vm._v("HTML für Preise:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }