var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "redesign-table" },
    [
      _c("table-header", {
        attrs: { item: _vm.item },
        on: {
          "change:section": _vm.toggleSection,
          delete: function ($event) {
            return _vm.$emit("delete")
          },
          edit: function ($event) {
            return _vm.$emit("edit")
          },
          copy: (v) => _vm.$emit("copy", v),
          reset: function ($event) {
            return _vm.$emit("reset")
          },
        },
      }),
      _vm._v(" "),
      _c("selling-prices", {
        attrs: { calculationOrder: _vm.item.calculationOrder },
        on: {
          editContingentPrice: (price) =>
            _vm.$emit("editContingentPrice", _vm.item, price),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }