<template>
    <multiselect
        v-model             = "ferrySelected"
        v-bind:options      = "items"
        v-bind:loading      = "isLoadingItems"
        v-bind:allow-empty  = "false"
        v-bind:custom-label = "customLabel"

        placeholder  = "Fähre"
        track-by     = "id"
        group-values = "items"
        group-label  = "type"
        select-label = ""
    >
        <template slot="noResult">Keine passenden Fähren</template>
        <span slot="caret">
            <a
                class  = "multiselect-crud-link"
                href   = "/ferries?add"
                title  = "Fügen Sie eine neue Fähre hinzu"
                target = "_blank"
            ><i class="material-icons">add</i></a>
        </span>
    </multiselect>
</template>



<script>
import has                    from 'lodash/has';
import { Multiselect }        from '@components/form';
import { getFilteredFerries } from '@api';
import { time }               from '@utilities/functions';
import { notifyError }        from '@components/Notification';
import mixins from "@orders/placeholders/components/forms/mixins";
import axios from "axios";


const emptyItem = { id: -1, name: 'Kein Fähre' };


export default {
    mixins: [mixins],
    props: {
        value: {},
        placeholderInfo: { type: Object, required: true },
        nullable:        { type: Boolean, default: true },
        // When new list select the first
        autoSelect:      { type: Boolean, default: true }
    },


    computed: {
        ferrySelected: {
            get: function () {
                return this.value ? JSON.parse(JSON.stringify(this.value)) : emptyItem;
            },
            set: function (value) {
                this.$emit('input', value.id !== -1 ? value : null);
            }
        }
    },


    data: function () {
        return {
            // List of Ferries
            items: [],
            isLoadingItems: false
        }
    },


    methods: {
        customLabel: function (item) {
            if (item.ferryRoutes && this.placeholderInfo.startPort && this.placeholderInfo.endPort){
                const route = item.ferryRoutes.find(ferryRoute => ferryRoute.startPort && ferryRoute.endPort &&
                                                                  ferryRoute.startPort.id === this.placeholderInfo.startPort.id &&
                                                                  ferryRoute.endPort.id   === this.placeholderInfo.endPort.id &&
                                                                  ferryRoute.type         === this.placeholderInfo.type);

                if (route && (route.exampleStartAt || route.exampleEndAt)) {
                    return item.name + ': ' + [time(route.exampleStartAt), time(route.exampleEndAt)].join(' - ');
                }
            }

            return item.name;
        },


        getList: function () {
            if (!this.isLoadingItems) {
                this.isLoadingItems = true;


                this.callCancelRequest();
                getFilteredFerries({
                        startPort: this.placeholderInfo.startPort,
                        endPort:   this.placeholderInfo.endPort,
                        routeType:   this.placeholderInfo.type,
                        config: {cancelToken: this.cancelSource.token}
                    }).then(response => {
                        this.items[1].items = response.data;

                        if (!has(this.ferrySelected, 'id')) {
                            if (this.nullable) {
                                this.ferrySelected = this.items[0].items[0];

                            } else if (this.items[1].items.length > 0) {
                                if (this.autoSelect) {
                                    this.ferrySelected = this.items[1].items[0];

                                } else {
                                    this.ferrySelected = emptyItem;
                                }
                            }
                        }

                    }, error => {
                        if(!axios.isCancel(error)) {
                            notifyError('Die Fähren konnten nicht geladen werden! Server error!');
                        }
                    })
                    .then(() => { this.isLoadingItems = false; });
            }
        }
    },


    created: function () {
        this.getList();

        if (this.nullable) {
            this.items = [
                {
                    type: 'Kein Fähre',
                    items: [emptyItem]
                },
                {
                    type:  'Fähren',
                    items: []
                }
            ];
        } else {
            this.items = [
                {},
                {
                    type:  'Fähren',
                    items: []
                }
            ];
        }
    },


    watch: {
        'placeholderInfo.startPort': function () { this.getList(); },
        'placeholderInfo.endPort':   function () { this.getList(); },
        'placeholderInfo.type':      function () { this.getList(); }
    },


    components: {
        Multiselect
    }
}
</script>
