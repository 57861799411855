<template>

    <div class="has-margin-top">
        <div class="bp-panel is-form has-no-title" style="min-height: auto">
            <div class="panel-content is-table-form">
                <table>
                    <tr>
                        <th>
                            <div class="control">
                                <toggle-switch v-model="preview">
                                    <option :value="false">Eingabe</option>
                                    <option :value="true">Ausgabe</option>
                                </toggle-switch>
                            </div>
                        </th>
                        <td>
                        </td>
                    </tr>
                    <template v-if="!preview">
                        <tr>
                            <th>
                                <label class="label">Zeiträume (Preisblöcke):</label>
                            </th>
                            <td>
                                <div class="control">
                                    <input type="text" class="input" v-model.number="periods" @input="setPeriods">
                                </div>
                            </td>
                        </tr>
                        <template v-for="period in periods">
                            <tr>
                                <td colspan="3">
                                    <h2>{{period}}. Preisblock</h2>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div class="control">
                                        <input
                                            type="text"
                                            class="input is-large"
                                            :value="getTableElement(period-1, 0,0)"
                                            @input="input => setTableElement(period-1, 0,0, input)"
                                            placeholder="06.09. - 17.09.2021 (Zubuchertermin)"
                                        >
                                    </div>
                                </th>
                                <td>
                                    <div class="control">
                                        <input
                                            type="text"
                                            class="input"
                                            :value="getTableElement(period-1, 0,1)"
                                            @input="input => setTableElement(period-1, 0,1, input)"
                                            placeholder="Düsseldorf"
                                        >
                                    </div>
                                </td>
                                <td>
                                    <div class="control">
                                        <input
                                            type="text"
                                            class="input"
                                            :value="getTableElement(period-1, 0,2)"
                                            @input="input => setTableElement(period-1, 0,2, input)"
                                            placeholder="München"
                                        >
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label class="label">Preise pro Person im DZ:</label>
                                </th>
                                <td>
                                    <div class="control">
                                        <input
                                            type="text"
                                            class="input"
                                            :value="getTableElement(period-1, 1,1)"
                                            @input="input => setTableElement(period-1, 1,1, input)"
                                            placeholder="2.176,- €*"
                                        >
                                    </div>
                                </td>
                                <td>
                                    <div class="control">
                                        <input
                                            type="text"
                                            class="input"
                                            :value="getTableElement(period-1, 1,2)"
                                            @input="input => setTableElement(period-1, 1,2, input)"
                                            placeholder="2.176,- €*"
                                        >
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label class="label">Einzelzimmer-Zuschlag:</label>
                                </th>
                                <td>
                                    <div class="control">
                                        <input
                                            type="text"
                                            class="input"
                                            :value="getTableElement(period-1, 2,1)"
                                            @input="input => setTableElement(period-1, 2,1, input)"
                                            placeholder="434,– €"
                                        >
                                    </div>
                                </td>
                                <td>
                                    <div class="control">
                                        <input
                                            type="text"
                                            class="input"
                                            :value="getTableElement(period-1, 2,2)"
                                            @input="input => setTableElement(period-1, 2,2, input)"
                                            placeholder="434,– €"
                                        >
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                    <template v-else>
                        <tr>
                            <th>
                                <label class="label">HTML für Leistungen:</label>
                            </th>
                            <td>
                                <div class="control">
                                    <resizable>
                                        <textarea
                                            rows="20"
                                            class="textarea"
                                            :value="item.servicesHtml"
                                            :readonly="true"
                                        ></textarea>
                                    </resizable>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label class="label">HTML für Preise:</label>
                            </th>
                            <td>
                                <div class="control">
                                    <resizable>
                                        <textarea
                                            rows="20"
                                            class="textarea"
                                            :value="pricePreview"
                                            :readonly="true"
                                        ></textarea>
                                    </resizable>
                                </div>
                            </td>
                        </tr>
                    </template>
                </table>
            </div>
        </div>
    </div>


</template>

<script>

import Mixin from '../../tab';
import {Multiselect, ToggleSwitch, Resizable} from "@components/form";


export default {
    mixins: [ Mixin ],

    props: {
        component: {
            type: Object,
            required: true
        }
    },

    data: function () {
        return {
            periods: 1,
            preview: false,
        }
    },

    methods: {

        saveItem: function() {
            this.$emit('saveItem', this.component.props.property, this.getProperty)
        },

        setPeriods: function() {
            let property = this.getProperty;
            if(!property || property.length === 0){
                this.item[this.component.props.property] = [];
            }
            for(let t=this.getProperty.length; t<this.periods; t++){
                this.item[this.component.props.property][t] = [];
                this.item[this.component.props.property][t][0] = [];
                this.item[this.component.props.property][t][1] = [];
                this.item[this.component.props.property][t][2] = [];
                this.item[this.component.props.property][t][0][0] = '';
                this.item[this.component.props.property][t][1][0] = 'Preis pro Person im DZ';
                this.item[this.component.props.property][t][2][0] = 'Einzelzimmer-Zuschlag';
                this.item[this.component.props.property][t][0][1] = '';
                this.item[this.component.props.property][t][1][1] = '';
                this.item[this.component.props.property][t][2][1] = '';
                this.item[this.component.props.property][t][0][2] = '';
                this.item[this.component.props.property][t][1][2] = '';
                this.item[this.component.props.property][t][2][2] = '';
            }
            let diff = this.getProperty.length - this.periods;
            if(diff > 0){
                this.item[this.component.props.property].splice(this.getProperty.length-diff, diff);
                this.saveItem();
            }

        },

        setTableElement(block, row, col, inputEvent) {
            this.getProperty[block][row][col] = inputEvent.target.value;
            this.saveItem();
        },

        getTableElement(block, row, col) {
            return this.getProperty && this.getProperty[block] && this.getProperty[block][row] && this.getProperty[block][row][col] ? this.getProperty[block][row][col] : '';
        }
    },

    computed: {
        getProperty: function () {
            return this.item[this.component.props.property];
        },

        pricePreview: function () {
            let text = '<div>\n';
            let row = [];
            let col = [];
            let priceBlock = [];
            for (let i = 0; i < this.getProperty.length; i++) {
                text += '   <div class="w-layout-grid grid-price">\n';
                priceBlock = this.getProperty[i];
                for (let r = 0; r < priceBlock.length; r++) {
                    row = priceBlock[r];
                    for (let c = 0; c < row.length; c++) {
                        col = row[c];
                        if (r === 0) {
                            text += '      <div class="text-block-date">' + col + '</div>\n';
                        } else {
                            text += '      <div class="text-block-price">' + col + '</div>\n';
                        }
                    }
                }
                text += '   </div>\n';
            }
            text += '</div>';
            return text;
        }
    },

    mounted: function () {
        let property = this.item[this.component.props.property];
        this.periods = property ? property.length : 1;
        this.setPeriods();
    },

    components: {
        Resizable,
        ToggleSwitch,
        Multiselect,
    },
}
</script>
