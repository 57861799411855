<template>
    <popover trigger="hover">
        <template v-if="total > 0">{{currency}} {{ total | priceView }}</template>
        <template slot="popover">
            <ul v-if="charges.length > 0">
                <li
                    v-for="charge in charges"
                    v-bind:key="charge.id"
                    class="level mb-xs"
                >
                    <span>{{ charge.name }}</span>
                    <span class="px-xs"></span>
                    <span>{{charge.price.currency}} {{ charge.price.amount | priceView }}</span>
                </li>
            </ul>
        </template>
    </popover>
</template>

<script>
import Popover from '@components/Popover'
import { priceView } from '@utilities/functions'
import Cell from './cell';

export default {
    mixins: [ Cell ],

    components: {
        Popover,
    },

    computed: {
        charges: function() {
            return this.value || []
        },

        total: function() {
            return this.charges.reduce((sum, charge) => {
                return sum += Number(charge.price.amount)
            }, 0)
        },

        currency: function() {
            return this.charges.length > 0 ? this.charges[0].price.currency : 'EUR'
        }
    },

    filters: {
        priceView
    }
}
</script>

