<template>
    <a
        v-bind:href="url(value)"
        target="_blank"
    >{{value}}</a>
</template>

<script>
import { url } from '@utilities/functions'
import Cell from './cell';

export default {
    mixins: [ Cell ],
    
    props: {
        value: {
            type: String,
        }
    },

    methods: {
        url
    }
}
</script>

