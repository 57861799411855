var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Zuschlag/Eintritt bearbeiten",
        createTitle: "Neuen Zuschlag/Eintritt hinzufügen",
      },
    },
    [
      _c("table", [
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "name" } }, [
              _vm._v("Zuschlag:"),
            ]),
          ]),
          _vm._v(" "),
          _c("td", [
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.name,
                    expression: "form.name",
                  },
                ],
                staticClass: "input",
                attrs: { type: "text" },
                domProps: { value: _vm.form.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "name", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "priceType" } }, [
              _vm._v("Preisart:"),
            ]),
          ]),
          _vm._v(" "),
          _c("td", [
            _c(
              "div",
              { staticClass: "control" },
              [
                _c("multiselect", {
                  attrs: {
                    options: _vm.quantityTypes,
                    "trakc-by": "key",
                    label: "value",
                  },
                  model: {
                    value: _vm.quantityType,
                    callback: function ($$v) {
                      _vm.quantityType = $$v
                    },
                    expression: "quantityType",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "price" } }, [
              _vm._v("Preis:"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("input-price", {
                staticClass: "is-medium",
                attrs: { id: "price", prefix: _vm.currency },
                model: {
                  value: _vm.amount,
                  callback: function ($$v) {
                    _vm.amount = $$v
                  },
                  expression: "amount",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "currency" } }, [
              _vm._v("Währung:"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("multiselect", {
                staticClass: "is-small",
                attrs: {
                  options: _vm.options.potentialCurrencies
                    ? _vm.options.potentialCurrencies
                    : ["EUR"],
                  placeholder: "EUR",
                  "allow-empty": false,
                },
                model: {
                  value: _vm.currency,
                  callback: function ($$v) {
                    _vm.currency = $$v
                  },
                  expression: "currency",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "" } }, [
              _vm._v("Gültigkeit:"),
            ]),
          ]),
          _vm._v(" "),
          _c("td", [
            _c("div", { staticClass: "level" }, [
              _c("div", { staticClass: "level-left" }, [
                _c("div", { staticClass: "level-item" }, [
                  _c(
                    "div",
                    { staticClass: "control is-expanded" },
                    [
                      _c("multiselect", {
                        staticClass: "is-full-width",
                        attrs: {
                          options: _vm.limitTypes,
                          "track-by": "key",
                          label: "value",
                        },
                        model: {
                          value: _vm.limitType,
                          callback: function ($$v) {
                            _vm.limitType = $$v
                          },
                          expression: "limitType",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "level-item" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.form.minPersons,
                        expression: "form.minPersons",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "input is-small",
                    attrs: { type: "text", placeholder: "Min" },
                    domProps: { value: _vm.form.minPersons },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.form,
                          "minPersons",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "level-item" }, [
                  _c("span", [_vm._v("-")]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "level-item" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.form.maxPersons,
                        expression: "form.maxPersons",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "input is-small",
                    attrs: { type: "text", placeholder: "Max" },
                    domProps: { value: _vm.form.maxPersons },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.form,
                          "maxPersons",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "level-item" },
                  [
                    _vm.form.quantityType === "per_single_room"
                      ? [_c("span", [_vm._v("EZ")])]
                      : [_c("span", [_vm._v("Pax")])],
                  ],
                  2
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "startAt" } }, [
              _vm._v("Gültig von:"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "td",
            [
              _vm.visible
                ? _c("input-date", {
                    staticClass: "is-small",
                    model: {
                      value: _vm.form.startAt,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "startAt", $$v)
                      },
                      expression: "form.startAt",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "endAt" } }, [
              _vm._v("Gültig bis:"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "td",
            [
              _vm.visible
                ? _c("input-date", {
                    staticClass: "is-small",
                    model: {
                      value: _vm.form.endAt,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "endAt", $$v)
                      },
                      expression: "form.endAt",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }