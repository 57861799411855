<template>
    <div v-bind:class="{'level  is-align-items-baseline': !simple || showPerPage, 'is-inline-flex': simple && !showPerPage}">
        <p class="pagination-info" v-if="!simple">Auf der Seite werden {{range.from}}-{{range.to}} von {{pagination.items}} {{ namePlural }} gezeigt</p>


        <nav class="pagination is-centered" role="navigation" aria-label="navigation" v-if="shouldShowPagination">
            <a
                class="pagination-previous"
                v-bind:disabled="pagination.current === 1"
                v-on:click="pageClicked( pagination.current - 1 )"
            >
                <template v-if="!simple">Zurück</template>
                <i v-else class="material-icons">keyboard_arrow_left</i>
            </a>

            <ul class="pagination-list">
                <li v-if="hasFirst" >
                    <a
                        class="pagination-link"
                        :class="{ 'is-current': isActive(1) }"
                        v-on:click="pageClicked(1)"
                    >1</a>
                </li>
                <li v-if="hasFirstEllipsis"><span class="pagination-ellipsis">&hellip;</span></li>
                <li v-for="page in pages" :key="page">
                    <a
                        class="pagination-link"
                        :class="{ 'is-current': isActive(page), disabled: page === '...' }"
                        v-on:click="pageClicked(page)"
                    >{{ page }}</a>
                </li>
                <li v-if="hasLastEllipsis"><span class="pagination-ellipsis">&hellip;</span></li>
                <li v-if="hasLast">
                    <a
                        class="pagination-link"
                        :class="{ 'is-current': isActive(this.pagination.pages) }"
                        v-on:click="pageClicked(pagination.pages)"
                    >{{pagination.pages}}</a>
                </li>
            </ul>

            <a
                class="pagination-next"
                v-bind:disabled="pagination.current === pagination.pages"
                v-on:click="pageClicked( pagination.current + 1 )"
            >
                <template v-if="!simple">Weiter</template>
                <i v-else class="material-icons">keyboard_arrow_right</i>
            </a>
        </nav>


        <div class="custom-page" v-if="!simple || showPerPage">
            <p>Elemente pro Seite:</p>
            <input
                class        = "input is-shorter"
                type         = "text"
                v-bind:value = "pagination.perPage"
                v-on:input   = "perPageChange"
            >
        </div>
    </div>
</template>



<script>
import debounce from 'lodash/debounce'


export default {
    props: {
        value:  { type: Object, default: () => ({}) },
        simple: { type: Boolean, default: false },
        showPerPage: { type: Boolean, default: false },
        name: { type: Object, default: () => ({
            singular: 'item',
            plural: 'items'
        })}
    },

    computed: {
        pagination: function () {
            return {
                ...this.value,
                pages: Math.ceil(this.value.items / this.value.perPage)
            }
        },

        maxPageButtons: function () {
            return this.simple ? 7 : 10;
        },

        namePlural: function () {
            return (this.$parent && this.$parent.name && this.$parent.name.plural) ? this.$parent.name.plural : this.name.plural;
        },

        range: function () {
            const perPage = this.pagination.items < this.pagination.perPage ? this.pagination.items : this.pagination.perPage;
            const from = ((this.pagination.current - 1) * perPage + 1);
            const to = (this.pagination.current * perPage);

            return {
                from,
                to,
            }
        },

        pages() {
            return this.pagination.pages === undefined
                ? []
                : this.pageLinks();
        },

        hasFirst() {
            return this.pagination.current >= 4 || this.pagination.pages < this.maxPageButtons
        },

        hasLast() {
            if (this.pagination.pages > 1) {
                return this.pagination.current <= this.pagination.pages - 3 || this.pagination.pages < this.maxPageButtons
            }

            return false;
        },

        hasFirstEllipsis() {
            return this.pagination.current >= 4 && this.pagination.pages >= this.maxPageButtons
        },

        hasLastEllipsis() {
            return this.pagination.current <= this.pagination.pages - 3 && this.pagination.pages >= this.maxPageButtons
        },

        shouldShowPagination() {
            if (this.pagination.pages === undefined) {
                return false;
            }

            if (this.pagination.count === 0) {
                return false;
            }

            return true;

            return this.pagination.pages > 1;
        },

    },

    methods: {
        isActive(page) {
            const current = this.pagination.current || 1;

            return current === page;
        },

        pageClicked(page) {
            if (page === '...' ||
                page === this.pagination.current ||
                page > this.pagination.pages ||
                page < 1) {
                return;
            }

            this.$emit('input', {...this.value, current: page})
        },

        perPageChange: debounce(function (e) {
            const perPage = Number(e.target.value);
            this.$emit('input', {...this.value, perPage})
        }, 400),

        pageLinks() {
            const pages = [];

            let left = 2;
            let right = this.pagination.pages - 1;

            if (this.pagination.pages >= this.maxPageButtons) {
                left = Math.max(1, this.pagination.current - 2);
                right = Math.min(this.pagination.current + 2, this.pagination.pages);
            }

            for (let i = left; i <= right; i++) {
                pages.push(i);
            }

            return pages;
        },
    },
};
</script>
