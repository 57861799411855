<script>
import { store } from './store';
import { fullYear} from "@utilities/functions";
import UpdatePopover from "@components/UpdatePopover";

export default {
    store,

    filters: {
        fullYear
    },
    methods: {
        optionValue: function (key) {
            const keyValue = this.item[key];
            const optionValue = this.options[key] && this.options[key]
                .find(i => i.key === keyValue);

            return optionValue && optionValue.value;
        }
    },

    computed: {
        item:    function () { return this.$store.state.data },
        options: function () { return this.$store.state.options },
        itemApi: function () { return this.$store.state.api && this.item ? '/api/' + this.$store.state.api + '/' + this.item.id : '' },
    },

    components: {
        UpdatePopover
    }
}
</script>
