var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tiny-mce", {
    model: {
      value: _vm.catalogText,
      callback: function ($$v) {
        _vm.catalogText = $$v
      },
      expression: "catalogText",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }