<template>
    <div class="tags">
        <span class="is-truncated">
            <span
                class="tag is-white"
                v-for="tag in value"
            >{{ tag }}</span>
        </span>
    </div>
</template>

<script>
import Cell from './cell';

export default {
    mixins: [ Cell ],
}
</script>
