<template>
    <div>
        <div class="row is-headline is-darker"
             v-on:click.prevent.stop="toggleCollapse"
             v-if="!showNextRow"
        >
            <div class="level-left">
                <i
                    class="collapse-icon material-icons level-item"
                    v-bind:class="{'is-rotated' : !isOpen}"
                >keyboard_arrow_up</i>
                <span class="level-item">{{level.level}}</span>
            </div>
        </div>

        <template v-if="showSubRow">
            <board-row
                v-if="currentLevel === 'board'"
                v-for="board in level.boards"
                v-bind:board="board"
                v-bind:options="options"
                v-bind:key="board.key"
                v-bind:show-next-row="level.boards.length < 3"

                v-on:editPriceGroup="priceGroup => $emit('editPriceGroup', priceGroup)"
                v-on:deletePriceGroup="priceGroup => $emit('deletePriceGroup', priceGroup)"
                v-on:updateBoard="updateBoard"
            />
            <route-row
                v-if="currentLevel === 'route'"
                v-for="route in level.routes"
                v-bind:route="route"
                v-bind:options="options"
                v-bind:key="route.id"
                v-bind:show-next-row="level.routes.length < 3"

                v-on:editRoute="$emit('editRoute', route)"
                v-on:deleteRoute="$emit('deleteRoute', route)"

                v-on:addPriceGroup="$emit('addPriceGroup', route)"
                v-on:editPriceGroup="priceGroup => $emit('editPriceGroup', priceGroup)"
                v-on:deletePriceGroup="priceGroup => $emit('deletePriceGroup', priceGroup)"
                v-on:updateRoute="updateRoute"
            />
            <train-route-row
                v-if="currentLevel === 'trainRoute'"
                v-for="route in level.routes"
                v-bind:route="route"
                v-bind:options="options"
                v-bind:key="route.id"
                v-bind:show-next-row="level.routes.length < 3"

                v-on:editRoute="$emit('editTrainRoute', route)"
                v-on:deleteTrainRoute="$emit('deleteTrainRoute', route)"

                v-on:addPriceGroup="$emit('addPriceGroup', route)"
                v-on:editPriceGroup="priceGroup => $emit('editPriceGroup', priceGroup)"
                v-on:deletePriceGroup="priceGroup => $emit('deletePriceGroup', priceGroup)"
                v-on:updateRoute="updateTrainRoute"
            />
            <other-service-type-row
                v-if="currentLevel === 'otherServiceType'"
                v-for="otherService in level.otherServices"
                v-bind:otherService="otherService"
                v-bind:options="options"
                v-bind:key="otherService.id"
                v-bind:show-next-row="level.otherServices.length < 3"

                v-on:editOtherService="$emit('editOtherService', otherService)"
                v-on:deleteOtherService="$emit('deleteOtherService', otherService)"

                v-on:addPriceGroup="$emit('addPriceGroup', otherService)"
                v-on:editPriceGroup="priceGroup => $emit('editPriceGroup', priceGroup)"
                v-on:deletePriceGroup="priceGroup => $emit('deletePriceGroup', priceGroup)"
                v-on:updateOtherService="updateOtherService"
            />
        </template>


        <!--<agency-row
            v-for="agency in board.agencies"
            v-bind:key="!!agency ? agency.id : 'direkt'"
            v-bind:agency="agency"
            v-bind:options="options"

            v-on:add="$emit('addForAgency', agency)"
            v-on:delete="$emit('deleteAgency', agency)"

            v-on:editPriceGroup="priceGroup => $emit('edit', priceGroup)"
            v-on:deletePriceGroup="priceGroup => $emit('delete', priceGroup)"
        />-->
    </div>
</template>

<script>
import BoardRow from './Board';
import RouteRow from './Route';
import TrainRouteRow from './TrainRoute';
import OtherServiceTypeRow from './OtherServiceType'

import Mixins          from './mixins';

export default {
    mixins: [Mixins],

    props: {
        level: {
            type: Object,
            required: true
        },

        options: {
            type: Object,
        },

        levels: {
            type: Object,
            required: true
        }
    },

    computed: {
        showSubRow: function() {
            return this.isOpen || this.showNextRow
        },

        currentLevel: function() {
            return this.levels.levels[this.levels.key]
        }
    },

    methods: {
        updateBoard: function(board) {

            const level = this.level;
            const index = level.boards.findIndex(oldBoard => oldBoard.key === board.key)
            level.boards.splice(index, 1, {
                ...board,
            })

            this.$emit('updateLevel', level)
        },

        updateRoute: function(route) {

            const level = this.level;
            const index = level.routes.findIndex(oldRoute => oldRoute.route.id === route.route.id)
            level.routes.splice(index, 1, {
                ...route,
            })


            this.$emit('updateLevel', level)
        },
        updateTrainRoute: function(route) {

            const level = this.level;
            const index = level.routes.findIndex(oldRoute => oldRoute.route.id === route.route.id)
            level.routes.splice(index, 1, {
                ...route,
            })


            this.$emit('updateLevel', level)
        },

        updateOtherService: function (otherService) {

            const level = this.level;
            const index = level.otherServices.findIndex(old => old.otherService.id === otherService.otherService.id)
            level.otherServices.splice(index, 1, {
                ...otherService,
            })

            this.$emit('updateLevel', level)
        }
    },

    components: {
        BoardRow,
        RouteRow,
        TrainRouteRow,
        OtherServiceTypeRow
    }
}
</script>
