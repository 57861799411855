var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: () => {
            _vm.visible = false
          },
          expression: "() => { visible = false }",
        },
      ],
      staticClass: "drop-down",
    },
    [
      _c(
        "div",
        {
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.toggle.apply(null, arguments)
            },
          },
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.visible
          ? _c("div", { staticClass: "drop-down__list" }, [_vm._t("list")], 2)
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }