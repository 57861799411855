var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "columns",
    {
      attrs: { property: "bankAccounts", loadOptions: false },
      on: { add: _vm.openForm },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function ({ item }) {
            return _c(
              "column-item",
              {
                attrs: {
                  update: {
                    updatedBy: item.updatedBy,
                    updatedAt: item.updatedAt,
                  },
                  api: "/api/bank_accounts/" + item.id,
                  label: _vm.label(item),
                },
                on: {
                  edit: function ($event) {
                    return _vm.openForm(item)
                  },
                  delete: function ($event) {
                    return _vm.deleteItem("bankAccounts", item)
                  },
                },
              },
              [
                _c("ul", [
                  _c("li", [_c("strong", [_vm._v(_vm._s(item.iban))])]),
                  _vm._v(" "),
                  _c("li", [_c("em", [_vm._v(_vm._s(item.bic))])]),
                  _vm._v(" "),
                  item.bank
                    ? _c("li", [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("account_balance"),
                        ]),
                        _vm._v(_vm._s(item.bank) + "\n            "),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !!item.accountHolder
                    ? _c("li", { staticClass: "mb" }, [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("person"),
                        ]),
                        _vm._v(_vm._s(item.accountHolder) + "\n            "),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  item.notes !== ""
                    ? _c("li", [
                        _c("hr", { staticClass: "has-margin-top" }),
                        _vm._v(" "),
                        _c("p", {
                          domProps: { innerHTML: _vm._s(item.notes) },
                        }),
                      ])
                    : _vm._e(),
                ]),
              ]
            )
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("bank-account-form", {
        ref: "form",
        attrs: { slot: "form" },
        slot: "form",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }