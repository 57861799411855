var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popover",
    { attrs: { trigger: "hover" } },
    [
      _vm._l(_vm.valueArray, function (order) {
        return [
          _c(
            "a",
            {
              staticClass: "tag is-white px-1",
              attrs: { href: `/orders/${order.id}`, target: "_blank" },
            },
            [_vm._v("\n            " + _vm._s(order.id) + "\n        ")]
          ),
        ]
      }),
      _vm._v(" "),
      _c("template", { slot: "popover" }, [
        _c(
          "div",
          { staticClass: "todo__popover" },
          [
            _vm.valueArray.length > 1
              ? [
                  _c(
                    "ul",
                    { staticClass: "icon-list" },
                    _vm._l(_vm.valueArray, function (order, index) {
                      return _c("li", [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("folder"),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.getOrderString(order)))]),
                      ])
                    }),
                    0
                  ),
                ]
              : [
                  _vm._l(_vm.valueArray, function (order) {
                    return [
                      _c("ul", { staticClass: "icon-list" }, [
                        order.client && order.client.shortName
                          ? _c("li", [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("person"),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(order.client.shortName)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("li", [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("date_range"),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("fullYear")(order.startAt)) +
                                " - " +
                                _vm._s(_vm._f("fullYear")(order.endAt))
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        order.trip && order.trip.destinations
                          ? _c("li", [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("flag"),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.tripDestination(order))),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        order.trip && order.trip.name
                          ? _c("li", [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("sync"),
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(order.trip.name))]),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  }),
                ],
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }