var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "columns",
    {
      attrs: { property: "infos", filter: { type: "menu" } },
      on: { add: _vm.openForm },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function ({ item }) {
            return _c(
              "column-item",
              {
                attrs: {
                  update: {
                    updatedBy: item.updatedBy,
                    updatedAt: item.updatedAt,
                  },
                  api: "/api/infos/" + item.id,
                },
                on: {
                  edit: function ($event) {
                    return _vm.openForm(item)
                  },
                  delete: function ($event) {
                    return _vm.deleteItem("infos", item)
                  },
                },
              },
              [
                _c("ul", [
                  _c("li", { staticClass: "content" }, [
                    _c("strong", [_vm._v(_vm._s(item.title))]),
                  ]),
                  _vm._v(" "),
                  _c("li", {
                    staticClass: "nl2br content",
                    domProps: { innerHTML: _vm._s(item.content) },
                  }),
                ]),
              ]
            )
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("menu-form", { ref: "form", attrs: { slot: "form" }, slot: "form" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }