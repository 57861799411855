<template>
    <span v-if="value">{{cabinType}}</span>
</template>

<script>
import find from 'lodash/find';
import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        cabinType: function () {
            const { persons, type } = this.value;
            const cabinType = find(this.options.cabinTypes, {
                persons,
                type
            });

            if (cabinType) {
                return cabinType.name;
            }
        }
    }
}
</script>

