var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.value, function (tag) {
      return _c("span", { staticClass: "tag is-white px-1 mr-1" }, [
        _vm._v("\n        " + _vm._s(tag) + "\n    "),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }