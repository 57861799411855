var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bp-row-form" },
    [
      _c("div", { staticClass: "is-flex-grow" }, [
        _c("div", { staticClass: "bp-row-form__title" }, [_vm._v("Freitext")]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "button",
              {
                staticClass: "btn-single-toggle has-margin-right",
                class: [_vm.onlyFromOrder ? "" : "is-active"],
                attrs: {
                  title: _vm.onlyFromOrder
                    ? "Nur in Destinationen suchen"
                    : "Überall suchen",
                },
                on: {
                  click: function ($event) {
                    _vm.onlyFromOrder = !_vm.onlyFromOrder
                  },
                },
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("flag")])]
            ),
            _vm._v(" "),
            _c(
              "multiselect",
              {
                ref: "free",
                attrs: {
                  options: _vm.predefinedFreeTextItems,
                  loading: _vm.isLoading,
                  "allow-empty": true,
                  "internal-search": false,
                  placeholder: "Freitextvorlage suchen",
                  label: "name",
                  "track-by": "id",
                },
                on: {
                  select: _vm.predefinedSelected,
                  "search-change": _vm.getPredefinedFreeTextList,
                },
                model: {
                  value: _vm.template,
                  callback: function ($$v) {
                    _vm.template = $$v
                  },
                  expression: "template",
                },
              },
              [
                _vm.isEmptyResults
                  ? _c("span", { attrs: { slot: "caret" }, slot: "caret" }, [
                      _c(
                        "a",
                        {
                          staticClass: "multiselect-crud-link",
                          attrs: {
                            href: "/free_texts?add",
                            title: "füge eine neue Vorlage hinzu",
                            target: "_blank",
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("add"),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("i", { staticClass: "material-icons" }, [
              _vm._v("view_headline"),
            ]),
            _vm._v(" "),
            _c("tip-tap", {
              staticClass: "is-flex-grow",
              attrs: {
                menuItems: [
                  "fullscreen",
                  "bold",
                  "italic",
                  "underline",
                  "color",
                  "undo",
                ],
                isNotAlone: "",
              },
              model: {
                value: _vm.copyPlaceholder.text,
                callback: function ($$v) {
                  _vm.$set(_vm.copyPlaceholder, "text", $$v)
                },
                expression: "copyPlaceholder.text",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "is-160 has-time" }, [
        _c("div", { staticClass: "bp-row-form__title" }, [
          _vm._v("Zeitangaben"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("i", { staticClass: "material-icons" }, [_vm._v("access_time")]),
            _vm._v(" "),
            !_vm.isConcept
              ? _c("date-time", {
                  staticClass: "has-margin-right",
                  model: {
                    value: _vm.copyPlaceholder.startAt,
                    callback: function ($$v) {
                      _vm.$set(_vm.copyPlaceholder, "startAt", $$v)
                    },
                    expression: "copyPlaceholder.startAt",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isConcept
              ? _c("date-time", {
                  staticClass: "has-margin-right",
                  attrs: { timeOnly: "" },
                  model: {
                    value: _vm.copyPlaceholder.startTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.copyPlaceholder, "startTime", $$v)
                    },
                    expression: "copyPlaceholder.startTime",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }