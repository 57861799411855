var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row is-header is-space-between" }, [
    _c(
      "div",
      { staticClass: "level-left" },
      [
        _c("span", { staticClass: "level-item" }, [_vm._v("Zeitraum:")]),
        _vm._v(" "),
        _vm.section === "sellingPrices"
          ? _c("strong", [_vm._v(_vm._s(_vm.label(_vm.item)))])
          : _c("multiselect", {
              staticClass: "is-very-large level-item",
              attrs: {
                "custom-label": _vm.label,
                options: _vm.sellingPrices,
                "allow-empty": false,
              },
              model: {
                value: _vm.sellingPrice,
                callback: function ($$v) {
                  _vm.sellingPrice = $$v
                },
                expression: "sellingPrice",
              },
            }),
        _vm._v(" "),
        _vm.section === "calculation" && _vm.options && _vm.options.hotelTypes
          ? _c(
              "div",
              { staticClass: "level-item level-left" },
              [
                _c("button", { staticClass: "button level-item" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/orders/" + _vm.sellingPrice.calculationOrder.id,
                        target: "_blank",
                      },
                    },
                    [_vm._v("Reisekonzept")]
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "level-item" }, [
                  _vm._v("Hotelkategorie:"),
                ]),
                _vm._v(" "),
                _vm.options.hotelTypes
                  ? _c("multiselect", {
                      staticClass: "is-tiny level-item",
                      attrs: {
                        "track-by": "name",
                        label: "name",
                        options: _vm.options.hotelTypes,
                        placeholder: _vm.value.hotelTypePlaceholder,
                      },
                      model: {
                        value: _vm.hotelType,
                        callback: function ($$v) {
                          _vm.hotelType = $$v
                        },
                        expression: "hotelType",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button level-item",
                    attrs: { disabled: !_vm.hotelType },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.changeHotelType.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("ändern")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "level-left" },
      [
        _c(
          "div",
          { staticClass: "level-item" },
          [
            _c(
              "toggle-switch",
              {
                attrs: { value: _vm.section },
                on: { input: _vm.changeSection },
              },
              [
                _c("option", { attrs: { value: "sellingPrices" } }, [
                  _vm._v("Verkaufspreise"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "calculation" } }, [
                  _vm._v("Kalkulation"),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "popover",
          {
            ref: "user",
            staticClass: "userPopover",
            attrs: { delay: { show: 0, hide: 0 } },
            on: {
              show: function ($event) {
                _vm.copyYear = null
              },
            },
          },
          [
            _c("i", { staticClass: "material-icons is-pointer" }, [
              _vm._v("content_copy"),
            ]),
            _vm._v(" "),
            _c(
              "template",
              { slot: "popover" },
              [
                _c("div", [
                  _vm._v(
                    "\n                    In folgendes Jahr kopieren:\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("multiselect", {
                  attrs: { options: _vm.availableYears, "allow-empty": false },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("copy", _vm.copyYear)
                    },
                  },
                  model: {
                    value: _vm.copyYear,
                    callback: function ($$v) {
                      _vm.copyYear = $$v
                    },
                    expression: "copyYear",
                  },
                }),
              ],
              1
            ),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "i",
          {
            staticClass: "material-icons is-pointer",
            on: {
              click: function ($event) {
                return _vm.$emit("reset")
              },
            },
          },
          [_vm._v("refresh")]
        ),
        _vm._v(" "),
        _c(
          "i",
          {
            staticClass: "material-icons is-pointer",
            on: {
              click: function ($event) {
                return _vm.$emit("edit")
              },
            },
          },
          [_vm._v("edit")]
        ),
        _vm._v(" "),
        _c(
          "i",
          {
            staticClass: "material-icons is-pointer",
            on: {
              click: function ($event) {
                return _vm.$emit("delete")
              },
            },
          },
          [_vm._v("delete")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }