<template>
    <div class="configurator search-day-concept is-gapless">
        <div class="column is-half">
            <div class="panel">
                <div class="panel-heading">
                    <span>Vorschau:</span>
                    <span class="is-light">auf Tag klicken um Nächte und Länge von Zusatzleistungen festzulegen</span>
                </div>
                <div class="panel-block is-spaceless">
                    <concept-days
                        class="day-concepts"
                        v-bind:concept="value"
                        v-on:select="day => selectedDay = day"
                        v-bind:options="{day:{categories:[]}}"
                    />
                </div>
            </div>
        </div>
        <div class="column is-half">
            <div class="panel">
                <div class="panel-heading">
                    <span>Vorschau Des Tages:</span>
                    <span class="is-light">Nächte und Länge von Zusatzleistungen festlegen</span>
                </div>
                <div class="panel-block is-spaceless">
                    <day
                        class="day-concepts"
                        v-if="selectedDay"
                        v-model="selectedDay"
                        v-on:input="updateDay"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ConceptDays, Day } from '@components/configurator/OrderConcept'
export default {
    props:{
        value: {
            type: [Boolean, Object]
        }
    },

    components: {
        ConceptDays,
        Day
    },

    data: function () {
        return {
            selectedDay: false
        }
    },

    methods: {
        updateDay: function (newDay) {
            const newConcept = {
                ...this.value,
                days: [
                    ...this.value.days
                ].map(day => {
                    if (day.id === newDay.id) {
                        return {
                            ...day,
                            ...newDay
                        }
                    } else {
                        return day;
                    }
                })
            }

            this.$emit('input', newConcept);
        },
    }
}
</script>

