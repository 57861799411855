<template>
    <div class="bp-box is-extra is-flex mb-0">
        <header>
            <i class="material-icons">star</i> Zuschläge und sonstige Leistungen (Preis pro Einheit)
            <button v-on:click="addItemPrices"><i aria-hidden="true" class="fa fa-plus-circle"></i></button>
        </header>

        <main class="flex-grow">
            <div v-for="(extra, index) in localItemPrices" v-bind:key="extra.id">
                <i class="material-icons" v-if="!isCalculationRequest">insert_drive_file</i>
                <button
                    v-else
                    class="btn-single-toggle has-border is-large"
                    v-on:click.prevent="extra.inCalculation = !extra.inCalculation"
                    :class="{'is-active': extra.inCalculation}"
                >
                    <i class="fa fa-calculator"></i>
                </button>

                <div>
                    <multiselect
                        v-bind:options="providerPrices"
                        track-by="id"
                        label="name"
                        v-bind:value="localItemPrices[index] ? localItemPrices[index] : null"
                        v-on:input="value => { priceSelected(value, index) }"

                        v-bind:taggable="true"
                        v-on:tag="value => addPriceSelected(value, index)"
                        v-on:search-change="searchPriceSelect"
                        v-on:close="closePriceSelect(index)"
                        v-on:open="getItemPrices"
                    >
                        <span slot="caret" />
                    </multiselect>
                </div>



                <div >
                    <div class="select">
                        <select
                            v-model="extra.limitType"
                        >
                            <option value="validIfLimit">für alle</option>
                            <option value="validForLimit">für Limit</option>
                        </select>
                    </div>
                    <input type="text" class="input is-tiny" v-model.number="extra.minPersons" placeholder="Min" />
                    <span>-</span>
                    <input type="text" class="input is-tiny" v-model.number="extra.maxPersons" placeholder="Max" />
                </div>


                <div>
                    <i class="material-icons">format_list_numbered</i>
                    <div class="select">
                        <select
                            v-model="extra.quantityType"
                            v-on:change="extra.quantity = (extra.quantityType !== 'custom' ? null : extra.quantity)"
                        >
                            <option v-if=!isPackage value="per_person_night">pro Person und Nacht</option>
                            <option value="per_person">pro Person</option>
                            <option value="per_group">pro Gruppe</option>
                            <option v-if=!isPackage value="per_single_room_night">pro EZ und Nacht</option>
                            <option value="per_single_room">pro EZ</option>
                            <option value="custom">Anzahl eingeben</option>
                        </select>
                    </div>
                    <input
                        class="input"
                        v-bind:disabled="extra.quantityType !== 'custom'"
                        v-model.number="extra.quantity"
                        v-on:focus="$event.target.select()"
                    />



                    <input-price v-if="!isWithoutPrice" v-model="extra.price.amount" :prefix="extra.price.currency"></input-price>

                    <div v-if="!isWithoutPrice" class="buttons has-addons">
                        <button
                            v-bind:class="['button', extra.billingType === 'info' ? 'is-primary' : '']"
                            v-on:click="extra.billingType = 'info'"
                            title="Kunde zahlt selbst vor Ort (nur Info)"
                        ><i class="fa fa-info" aria-hidden="true"></i></button>

                        <button
                            v-bind:class="['button', extra.billingType === 'included' ? 'is-primary' : '']"
                            v-on:click="extra.billingType = 'included'"
                            title="von uns bezhalt und im Paketpreis inkludiert"
                        ><i class="material-icons">shopping_cart</i></button>

                        <button
                            v-bind:class="['button', extra.billingType === 'additional' ? 'is-primary' : '']"
                            v-on:click="extra.billingType = 'additional'"
                            title="gesondert berechnet"
                        ><i class="material-icons">playlist_add</i></button>
                    </div>

                    <button class="btn-delete" title="Löschen" v-on:click="deleteItemPrices(index)"><i class="material-icons">delete</i></button>
                </div>
            </div>

            <div v-if="!localItemPrices || localItemPrices.length === 0">Es wurden keine Zuschläge eingetragen</div>
        </main>
    </div>
</template>



<script>
import has from 'lodash/has';
import { InputPrice } from '@components/form';
import { Multiselect } from '@components/form';
import { getProviderItemPrices } from '@api';
import { store } from '@orders/store';
import {defaultItemPrice} from "@clientSpecific/utilities/defaultValues";


export default {
    store,


    props: {
        value:     { type: Array,   required: true  },
        isWithoutPrice: { type: Boolean, default:  false },
        provider: { type: Object },
        isPackage: {type: Boolean, default: false}
    },


    mounted: function () {
        if (!!this.provider && !this.hasProviderPrices) {
            this.$store.commit('addProviderItemPrices', {
                [this.providerID]: []
            });

            this.getItemPrices();
        }
    },


    data: function () {
        return {
            localItemPrices: JSON.parse(JSON.stringify(this.value)),
            localProviderPrices: [],
            priceSearch: '',
            defaultItemPrice: defaultItemPrice
        }
    },


    computed: {

        providerID: function () {
            return this.provider && this.provider.id;
        },

        providerItemPrices: function () {
            return this.$store.state.providerItemPrices;
        },

        hasProviderPrices: function () {
            return has(this.providerItemPrices, this.providerID);
        },

        providerPrices: function () {
            if (!!this.provider && this.hasProviderPrices) {
                return [
                    ...this.providerItemPrices[this.providerID],
                    ...this.localProviderPrices
                ]
            }

            return [];
        },

        isCalculationRequest: function () {
            return this.$parent.isCalculationRequest || false;
        },

    },


    methods: {
        getItemPrices: function () {
            getProviderItemPrices({
                provider: this.providerID,
                ...!!this.$parent.request.requestCurrency && {
                    'price.currency': this.$parent.request.requestCurrency
                }
            })
                .then(response => {
                    this.$store.commit('addProviderItemPrices', {
                        [this.providerID]: response.data
                    })
                });
        },

        addItemPrices: function () {
            this.localItemPrices.push({
                ...defaultItemPrice,
                price: {
                    amount: '0.00',
                    currency: this.$parent.request.requestCurrency
                },
            });
        },


        deleteItemPrices: function (index) {
            this.localItemPrices.splice(index, 1);
        },

        priceSelected: function (value, index) {
            this.localItemPrices = this.localItemPrices
                .map((price, i) => {
                    if (i === index) {
                        return Object.assign({}, price, {
                            name: value.name,
                            quantityType: value.quantityType,
                            limitType: value.limitType,
                            quantity: null,
                            price: {
                                amount: value.price.amount,
                                currency: this.$parent.request.requestCurrency
                            }
                        })
                    }

                    return price;
                })
        },

        addPriceSelected: function (value, index) {
            if (value !== '') {

                if (!this.localProviderPrices.find(price => price.name === value)) {
                    this.localProviderPrices = [...this.localProviderPrices, {
                        id: +new Date(),
                        name: value
                    }]
                }

                this.localItemPrices = this.localItemPrices
                    .map((price, i) => {
                        if (i === index) {
                            return Object.assign({}, price, {
                                name: value,
                            })
                        }

                        return price;
                })
            }
        },

        searchPriceSelect: function (value) {
            this.priceSearch = value;
        },

        closePriceSelect: function (index) {
            this.addPriceSelected(this.priceSearch, index)
        },
    },


    watch: {
        localItemPrices: {
            handler: function () {
                this.$emit('input', this.localItemPrices);
            },
            deep: true
        }
    },


    components: {
        InputPrice,
        Multiselect
    }
}
</script>
