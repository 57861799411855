<template>
    <modal
        class="is-confirmation"
        v-bind:class="{ 'is-local': isLocal }"
        v-bind:visible="isVisible"
    >
        <div slot="content">
            {{ text }}
        </div>


        <div slot="footer" class="level">
            <button
                class="button"
                v-on:click="confirm = false"
            >Nein</button>

            <button
                class="button is-primary"
                v-on:click.prevent="confirm = true"
            >Ja</button>
        </div>
    </modal>
</template>



<script>
import Modal from '@components/Modal';


export default {
    props: {
        isVisible: { type: Boolean },
        value:   { default: null },
        isLocal: { type: Boolean, default: false },
        text:    { type: String,  default: 'Bist du sicher?' }
    },


    data: function () {
        return {
            confirm: this.value ? JSON.parse(JSON.stringify(this.value)) : null,
        }
    },


    watch: {
        confirm: function () {
            if (this.confirm !== null) {
                this.$emit('input', this.confirm);
            }
        },

        isVisible: function () { this.confirm = null; }
    },


    components: {
        Modal
    }
}
</script>
