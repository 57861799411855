<template>
    <div
        class="bp-panel"
        v-bind:class="{'is-hover': isHover}"

        v-on:mouseenter="isHover = true"
        v-on:mouseleave="isHover = false"
        v-on:click="isHover = false"
    >
        <div class="panel-content">
            <h3>{{template.title}}</h3>
            <div v-html="content"></div>
        </div>

        <div class="panel-actions">
            <button v-on:click.prevent.stop="$emit('add')">
                <i class="material-icons">add</i>
                HINZUFÜGEN
            </button>
        </div>
    </div>
</template>

<script>
import { store } from '../store';

export default {
    store,

    props: {
        template: {
            type: Object,
            required: true
        }
    },

    computed: {
        order: function() {
            return this.$store.state.tripDetails.order
        },

        content: function() {
            return this.order.formal ? this.template.renderedContent : this.template.renderedInformalContent
        }
    },

    data: function () {
        return {
            isHover: false,
        }
    }
}
</script>

