var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Zuschlag bearbeiten",
        createTitle: "Neuen Zuschlag hinzufügen",
      },
    },
    [
      _c("table", [
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "name" } }, [
              _vm._v("Eintritt:"),
            ]),
          ]),
          _vm._v(" "),
          _c("td", [
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.name,
                    expression: "form.name",
                  },
                ],
                staticClass: "input",
                attrs: { type: "text" },
                domProps: { value: _vm.form.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "name", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "priceType" } }, [
              _vm._v("Preisart:"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.quantityTypes,
                  "trakc-by": "key",
                  label: "value",
                },
                model: {
                  value: _vm.quantityType,
                  callback: function ($$v) {
                    _vm.quantityType = $$v
                  },
                  expression: "quantityType",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "price" } }, [
              _vm._v("Preis:"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("input-price", {
                staticClass: "is-medium",
                attrs: { id: "price", prefix: _vm.currency },
                model: {
                  value: _vm.amount,
                  callback: function ($$v) {
                    _vm.amount = $$v
                  },
                  expression: "amount",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "currency" } }, [
              _vm._v("Währung:"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("multiselect", {
                staticClass: "is-small",
                attrs: {
                  options: _vm.options.potentialCurrencies
                    ? _vm.options.potentialCurrencies
                    : ["EUR"],
                  placeholder: "EUR",
                  "allow-empty": false,
                },
                model: {
                  value: _vm.currency,
                  callback: function ($$v) {
                    _vm.currency = $$v
                  },
                  expression: "currency",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "" } }, [
              _vm._v("Limit:"),
            ]),
          ]),
          _vm._v(" "),
          _c("td", [
            _c("div", { staticClass: "level-left" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.form.minPersons,
                    expression: "form.minPersons",
                    modifiers: { number: true },
                  },
                ],
                staticClass: "input is-small level-item",
                attrs: { type: "text", placeholder: "Min" },
                domProps: { value: _vm.form.minPersons },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.form,
                      "minPersons",
                      _vm._n($event.target.value)
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "level-item" }, [_vm._v("-")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.form.maxPersons,
                    expression: "form.maxPersons",
                    modifiers: { number: true },
                  },
                ],
                staticClass: "input is-small level-item",
                attrs: { type: "text", placeholder: "Max" },
                domProps: { value: _vm.form.maxPersons },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.form,
                      "maxPersons",
                      _vm._n($event.target.value)
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.form.quantityType === "per_single_room"
                    ? [_vm._v("EZ")]
                    : [_vm._v("Pax")],
                ],
                2
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "startAt" } }, [
              _vm._v("Stand:"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "td",
            [
              _vm.visible
                ? _c("input-date", {
                    staticClass: "is-small",
                    model: {
                      value: _vm.form.startAt,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "startAt", $$v)
                      },
                      expression: "form.startAt",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c(
              "label",
              { staticClass: "label", attrs: { for: "primaryPrice" } },
              [_vm._v("Primäreintritt:")]
            ),
          ]),
          _vm._v(" "),
          _c("td", [
            _c("div", { staticClass: "control" }, [
              _c("label", { staticClass: "radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.primaryPrice,
                      expression: "form.primaryPrice",
                    },
                  ],
                  attrs: { type: "radio" },
                  domProps: {
                    value: true,
                    checked: _vm._q(_vm.form.primaryPrice, true),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.form, "primaryPrice", true)
                    },
                  },
                }),
                _vm._v("ja\n                   "),
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.primaryPrice,
                      expression: "form.primaryPrice",
                    },
                  ],
                  attrs: { type: "radio" },
                  domProps: {
                    value: false,
                    checked: _vm._q(_vm.form.primaryPrice, false),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.form, "primaryPrice", false)
                    },
                  },
                }),
                _vm._v("nein\n                   "),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c(
              "label",
              { staticClass: "label", attrs: { for: "billingType" } },
              [_vm._v("Einfügen als:")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("multiselect", {
                staticClass: "is-medium",
                attrs: {
                  options: _vm.billingTypes,
                  "track-by": "key",
                  label: "value",
                },
                model: {
                  value: _vm.billingType,
                  callback: function ($$v) {
                    _vm.billingType = $$v
                  },
                  expression: "billingType",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th"),
          _vm._v(" "),
          _c("td", [
            _c("div", { staticClass: "control" }, [
              _c("label", { staticClass: "checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.automaticallyAddToOrder,
                      expression: "form.automaticallyAddToOrder",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    value: true,
                    checked: Array.isArray(_vm.form.automaticallyAddToOrder)
                      ? _vm._i(_vm.form.automaticallyAddToOrder, true) > -1
                      : _vm.form.automaticallyAddToOrder,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.form.automaticallyAddToOrder,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = true,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.form,
                              "automaticallyAddToOrder",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "automaticallyAddToOrder",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "automaticallyAddToOrder", $$c)
                      }
                    },
                  },
                }),
                _vm._v(
                  "Zuschlag automatisch in jeder Reise hinzufügen\n                    "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }