var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popover",
    { attrs: { trigger: "hover" } },
    [
      _vm.total > 0
        ? [
            _vm._v(
              _vm._s(_vm.currency) +
                " " +
                _vm._s(_vm._f("priceView")(_vm.total))
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("template", { slot: "popover" }, [
        _vm.charges.length > 0
          ? _c(
              "ul",
              _vm._l(_vm.charges, function (charge) {
                return _c(
                  "li",
                  { key: charge.id, staticClass: "level mb-xs" },
                  [
                    _c("span", [_vm._v(_vm._s(charge.name))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "px-xs" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(charge.price.currency) +
                          " " +
                          _vm._s(_vm._f("priceView")(charge.price.amount))
                      ),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }