var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c("loading")
    : _vm.isEmpty
    ? _c("div", { staticClass: "is-empty-cost" }, [
        _vm._v("\n    Berechnung ohne Preise nicht möglich\n"),
      ])
    : _c("div", [
        _c(
          "div",
          { staticClass: "redesign-table is-costs" },
          [
            _c("div", { staticClass: "row is-head" }, [
              _c("div", { staticClass: "cell" }, [_vm._v("Leistung")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cell" },
                [!_vm.packageID ? [_vm._v("Übernachtungen")] : _vm._e()],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }, [_vm._v("Anzahl")]),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }, [_vm._v("Einzelpreis")]),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }, [_vm._v("Gesamtpreis")]),
            ]),
            _vm._v(" "),
            _vm.packageID
              ? _c("package", {
                  attrs: {
                    calculations: _vm.calculations,
                    roomTypes: _vm.roomTypes,
                    cabinTypes: _vm.cabinTypes,
                    trainCabinTypes: _vm.trainCabinTypes,
                    isLoading: _vm.isLoading,
                  },
                })
              : _c(
                  "div",
                  { staticClass: "is-zebra" },
                  [
                    _vm._l(_vm.sortedRooms, function (room) {
                      return _c("div", { key: room.id, staticClass: "row" }, [
                        _c("div", { staticClass: "cell" }, [
                          _c(
                            "span",
                            {
                              style: _vm.isStandardRoom(room)
                                ? "font-weight: bold"
                                : "",
                            },
                            [_vm._v(_vm._s(_vm.roomLabel(room)))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "cell" }, [
                          _vm._v(_vm._s(_vm.calculations.durationInDays)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "cell" }, [
                          _vm._v(_vm._s(_vm.roomPersons(room))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "cell" }, [
                          _vm._v(
                            _vm._s(_vm._f("priceView")(_vm.roomPrice(room)))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "cell" }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm._f("priceView")(_vm.roomTotal(room)))
                            ),
                          ]),
                        ]),
                      ])
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.sortedRooms, function (room) {
                      return room.extraReservedAmount
                        ? _c(
                            "div",
                            { key: "extra-" + room.id, staticClass: "row" },
                            [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v(
                                  "Extra - " +
                                    _vm._s(
                                      _vm.getAccommodationLabel(
                                        room.type.type,
                                        room.type.persons,
                                        _vm.roomTypes
                                      )
                                    ) +
                                    " - " +
                                    _vm._s(room.extraDescription)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _vm._v(_vm._s(_vm.calculations.durationInDays)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _vm._v(
                                  _vm._s(
                                    room.extraReservedAmount * room.type.persons
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("priceView")(
                                      room.absolutePrice.amount
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("priceView")(
                                        room.totalPriceExtraReserved.amount
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.sortedRooms, function (room) {
                      return room.personsFreePlaces
                        ? _c(
                            "div",
                            { key: "free-" + room.id, staticClass: "row" },
                            [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v(_vm._s(_vm.freePlaceLabel(room))),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _vm._v(_vm._s(_vm.calculations.durationInDays)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _vm._v(_vm._s(room.personsFreePlaces)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("priceView")(
                                      -1 * room.absolutePrice.amount
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("priceView")(
                                        -1 * room.totalPriceFreePlaces.amount
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.calculations.itemPrices, function (item) {
                      return item.billingType !== "info" && item.limitIsValid
                        ? _c(
                            "div",
                            { key: "item-" + item.id, staticClass: "row" },
                            [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("Zuschlag: " + _vm._s(item.name)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _vm._v(
                                  _vm._s(
                                    item.quantityType === "per_person_night" ||
                                      item.quantityType ===
                                        "per_single_room_night"
                                      ? _vm.calculations.durationInDays
                                      : ""
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _vm._v(
                                  _vm._s(item.calculatedQuantityWithExtra)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _vm._v(
                                  _vm._s(_vm._f("priceView")(item.price.amount))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("priceView")(
                                        item.totalPrice.amount
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm.isLoading ? _c("loading") : _vm._e(),
                  ],
                  2
                ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "for-costs-total" }, [
          _c("div", [
            _c("b", [
              _vm._v(
                "TOTAL (" + _vm._s(_vm.calculations.totalPrice.currency) + ")"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("b", [
              _vm._v(
                _vm._s(_vm._f("priceView")(_vm.calculations.totalPrice.amount))
              ),
            ]),
          ]),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }