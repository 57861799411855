var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-request" },
    [
      _c("div", { staticClass: "overall-buttons" }, [
        _c(
          "button",
          {
            staticClass: "button is-large",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [
            _c("i", {
              staticClass: "fa fa-long-arrow-left",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Zurück zur Übersicht"),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("accordion", { attrs: { isCollapsible: false } }, [
        _c("div", { attrs: { slot: "header" }, slot: "header" }, [
          _c(
            "div",
            [
              _vm.newRequest.otherService &&
              _vm.newRequest.otherService.provider
                ? _c("request-score", {
                    attrs: {
                      rating:
                        _vm.newRequest.otherService.provider.buspaketRating,
                    },
                  })
                : _c("i", {
                    staticClass: "fa fa-plus-circle",
                    attrs: { "aria-hidden": "true" },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm._v(
                "\n                LEISTUNGSTRÄGER HINZUFÜGEN\n                "
              ),
              _c(
                "provider-info",
                {
                  staticClass: "header-extra-info",
                  attrs: {
                    provider: _vm.newRequest.otherService
                      ? _vm.newRequest.otherService.provider
                      : null,
                  },
                },
                [
                  _c("div", [
                    _c("i", { staticClass: "material-icons spacing-left" }, [
                      _vm._v("perm_identity"),
                    ]),
                    _vm._v(
                      " Kundenkontingent:\n                        " +
                        _vm._s(_vm.peopleContingent) +
                        " Teilnehmer\n                    "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "label",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isMainRequest,
                      expression: "isMainRequest",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.isMainRequest)
                      ? _vm._i(_vm.isMainRequest, null) > -1
                      : _vm.isMainRequest,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.isMainRequest,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isMainRequest = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isMainRequest = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isMainRequest = $$c
                      }
                    },
                  },
                }),
                _vm._v(
                  "\n                    als Leistung festlegen\n                "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { slot: "content" }, slot: "content" }, [
          _c("div", { staticClass: "bp-box is-info" }, [
            _c(
              "main",
              [
                _c(
                  "button",
                  {
                    class: [
                      "btn-single-toggle",
                      _vm.onlyFromOrder ? "" : "is-active",
                    ],
                    staticStyle: {
                      border: "1px solid #dddddd",
                      "margin-right": "5px",
                      "min-width": "22px",
                      "min-height": "22px",
                    },
                    attrs: {
                      title: _vm.onlyFromOrder
                        ? "Suche ist auf obigen Ort eingeschränkt"
                        : "Suche ist nicht eingeschränkt",
                    },
                    on: {
                      click: function ($event) {
                        _vm.onlyFromOrder = !_vm.onlyFromOrder
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        staticStyle: { margin: "0" },
                      },
                      [_vm._v("flag")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("other-service-select", {
                      staticClass: "has-margin-left",
                      staticStyle: { width: "400px" },
                      attrs: {
                        otherServiceTypeID:
                          _vm.placeholderInfo.otherServiceType.id,
                        "placeholder-info": _vm.onlyFromOrder
                          ? _vm.locationInfo
                          : { area: null, place: null, destination: null },
                      },
                      model: {
                        value: _vm.newRequest.otherService,
                        callback: function ($$v) {
                          _vm.$set(_vm.newRequest, "otherService", $$v)
                        },
                        expression: "newRequest.otherService",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("agency-select", {
                  attrs: { provider: null, text: "Agentur", prefill: "" },
                  on: { update: (agency) => (_vm.newRequest.agency = agency) },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column" }, [
              _c("div", { staticClass: "bp-box is-tickets" }, [
                _c("header", [
                  _c(
                    "div",
                    [
                      _c("other-service-icons", {
                        attrs: {
                          type: _vm.newRequest.otherServiceType.category,
                        },
                      }),
                      _vm._v(" Zusatzleistung\n                            "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("help"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "button",
                      {
                        attrs: { title: "Extrabuchung für Reisebegleitung" },
                        on: { click: _vm.addExtra },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("person_add"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("main", [
                  _c("div", [
                    _c("div", [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("person"),
                      ]),
                      _vm._v(" Teilnehmer\n                                "),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.newRequest.contingent.askedAmount,
                            expression: "newRequest.contingent.askedAmount",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "input",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.newRequest.contingent.askedAmount,
                        },
                        on: {
                          focus: function ($event) {
                            return $event.target.select()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.newRequest.contingent,
                              "askedAmount",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div"),
                  ]),
                  _vm._v(" "),
                  _vm.hasExtra
                    ? _c("div", { staticClass: "extra-people" }, [
                        _c("div", [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("person_add"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.newRequest.contingent.extraDescription,
                                expression:
                                  "newRequest.contingent.extraDescription",
                              },
                            ],
                            staticClass: "input",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.newRequest.contingent.extraDescription,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.newRequest.contingent,
                                  "extraDescription",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn-delete",
                              attrs: { title: "Löschen", tabindex: "-1" },
                              on: { click: _vm.deleteExtra },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("delete"),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", { staticClass: "control" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value:
                                    _vm.newRequest.contingent.extraAskedAmount,
                                  expression:
                                    "newRequest.contingent.extraAskedAmount",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "input",
                              attrs: { type: "text" },
                              domProps: {
                                value:
                                  _vm.newRequest.contingent.extraAskedAmount,
                              },
                              on: {
                                focus: function ($event) {
                                  return $event.target.select()
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.newRequest.contingent,
                                    "extraAskedAmount",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div"),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column is-offset-6" },
              [
                _c("item-prices", {
                  attrs: { isWithoutPrice: true, provider: _vm.provider },
                  model: {
                    value: _vm.newRequest.itemPrices,
                    callback: function ($$v) {
                      _vm.$set(_vm.newRequest, "itemPrices", $$v)
                    },
                    expression: "newRequest.itemPrices",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-large",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c("i", { staticClass: "material-icons" }, [_vm._v("close")]),
          _vm._v(" Abbrechen"),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-primary is-large",
          class: { "is-loading": _vm.isLoading },
          on: { click: _vm.saveNewRequest },
        },
        [
          _c("i", { staticClass: "material-icons" }, [_vm._v("check")]),
          _vm._v(" speichern und zur Email"),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-primary is-large",
          class: { "is-loading": _vm.isLoading },
          on: {
            click: function ($event) {
              return _vm.saveNewRequest("close")
            },
          },
        },
        [
          _c("i", { staticClass: "material-icons" }, [
            _vm._v("keyboard_arrow_right"),
          ]),
          _vm._v(" speichern und schließen"),
        ]
      ),
      _vm._v(" "),
      _c("confirmation", {
        attrs: {
          isVisible: _vm.isVisibleConfirmation,
          "is-local": "",
          text: "Soll die Leistungs- und Routenbeschreibung an die spezifische Anfrage angepasst werden?",
        },
        on: { input: (value) => _vm.$emit("confirm", value) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }