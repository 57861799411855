var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isRouteView
    ? _c(
        "div",
        {
          staticClass: "placeholder-view has-requests",
          class: {
            "has-no-route-description":
              !_vm.placeholder.routeDescription && _vm.isRouteView,
          },
        },
        [
          _c(
            "div",
            { attrs: { title: _vm.placeholder.routeDescription } },
            [
              _c(
                "i",
                {
                  staticClass: "material-icons",
                  attrs: { title: "Routenstichpunkt" },
                },
                [_vm._v("filter_hdr")]
              ),
              _vm._v(" "),
              _vm.placeholder.routeDescription
                ? [
                    _vm.placeholder.timeOfDay
                      ? [
                          _vm._v(
                            _vm._s(
                              _vm.getValueWithKey({
                                key: _vm.placeholder.timeOfDay,
                                optionList: _vm.timeOfDay,
                              })
                            ) + " "
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(
                      "\n\n            " +
                        _vm._s(_vm.placeholder.routeDescription) +
                        "\n            "
                    ),
                  ]
                : [_vm._v("ohne Routenbeschreibung")],
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.top",
                      value: { content: _vm.placeholder.additionalDescription },
                      expression:
                        "{ content: placeholder.additionalDescription }",
                      modifiers: { top: true },
                    },
                  ],
                  class: ["route-difficulty", _vm.getDifficulty()],
                },
                [
                  _c("div", { staticClass: "dot" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "dot" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "dot" }),
                ]
              ),
            ],
            2
          ),
          _vm._v(" "),
          !_vm.isConcept ? _c("div", { staticClass: "is-60" }) : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "is-100 is-hidden-on-small" },
            [
              _c("location-charges", {
                attrs: {
                  row: _vm.placeholder,
                  value: _vm.placeholder.locationCharges,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isConcept
            ? _c(
                "div",
                { staticClass: "contingent-section" },
                [
                  _vm.placeholder.predefinedRouteDetail &&
                  _vm.placeholder.predefinedRouteDetail.pricePerPerson &&
                  _vm.placeholder.predefinedRouteDetail.pricePerPerson.amount &&
                  _vm.placeholder.predefinedRouteDetail.pricePerPerson
                    .amount !== "0" &&
                  _vm.placeholder.predefinedRouteDetail.pricePerPerson
                    .amount !== "0.00"
                    ? [
                        _c("span", { staticClass: "currency" }, [
                          _vm._v(
                            _vm._s(
                              _vm.placeholder.predefinedRouteDetail
                                .pricePerPerson.currency
                            )
                          ),
                        ]),
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("priceView")(
                                _vm.placeholder.predefinedRouteDetail
                                  .pricePerPerson.amount
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.placeholder.pricePerPerson &&
                  _vm.placeholder.pricePerPerson.amount &&
                  _vm.placeholder.pricePerPerson.amount !== "0" &&
                  _vm.placeholder.pricePerPerson.amount !== "0.00"
                    ? [
                        _c("span", { staticClass: "currency" }, [
                          _vm._v(
                            _vm._s(_vm.placeholder.pricePerPerson.currency)
                          ),
                        ]),
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("priceView")(
                                _vm.placeholder.pricePerPerson.amount
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isConcept
            ? _c(
                "div",
                [
                  _c("location-charges", {
                    attrs: {
                      row: _vm.placeholder,
                      value: _vm.placeholder.locationCharges,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "action-buttons" }, [_vm._t("default")], 2),
          _vm._v(" "),
          _vm.placeholder.sortGroup
            ? _c("div", {
                staticClass: "group-icon",
                class: "is-" + _vm.placeholder.sortGroup,
              })
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }