var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5" },
    [
      _vm.row.complexity
        ? [
            _c("popover", { attrs: { trigger: "hover" } }, [
              _c(
                "div",
                {
                  staticClass: "circles",
                  class: _vm.getRentabilityIndexColorClass(
                    _vm.row.complexity.rentabilityIndex
                  ),
                  attrs: {
                    "data-p": 100,
                    title:
                      "Erwartete Marge pro Person: " +
                      _vm.row.complexity.rentabilityIndex,
                  },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.row.complexity.rentabilityIndex)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { attrs: { slot: "popover" }, slot: "popover" }, [
                _c("div", { staticClass: "level-left" }, [
                  _c("span", { staticClass: "level-item" }, [_vm._v("Marge:")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "circles circles-popover level-item",
                      class: _vm.getRentabilityColorClass(
                        _vm.row.complexity.rentability
                      ),
                      attrs: {
                        "data-p": 100,
                        title:
                          "Marge pro Person: " + _vm.row.complexity.rentability,
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.row.complexity.rentability)),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "level-left" }, [
                  _c("span", { staticClass: "level-item" }, [
                    _vm._v("Buchungswahrscheinlichkeit:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "circles circles-popover level-item",
                      class: _vm.getColorClass(
                        _vm.row.complexity.bookingProbability
                      ),
                      attrs: {
                        "data-p": 100,
                        title:
                          "Buchungswahrscheinlichkeit: " +
                          _vm.row.complexity.bookingProbability +
                          " %",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.row.complexity.bookingProbability)),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "level-left" }, [
                  _c("span", { staticClass: "level-item" }, [
                    _vm._v("Durchführungswahrscheinlichkeit:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "circles circles-popover level-item",
                      class: _vm.getColorClass(
                        _vm.row.complexity.executionProbability
                      ),
                      attrs: {
                        "data-p": 100,
                        title:
                          "Durchführungswahrscheinlichkeit: " +
                          _vm.row.complexity.executionProbability +
                          " %",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.row.complexity.executionProbability)),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        : [_vm._m(0)],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "circles",
        attrs: { "data-p": "0", title: "Durchschnittspreis pro Person: ?" },
      },
      [_c("span", [_vm._v("?")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }