<template>
    <modal-form
        editTitle="Kasse oder Konto bearbeiten"
        createTitle="Kasse oder Konto anlegen"
    >
        <table>
            <tr>
                <th><label class="label">Art:</label></th>
                <td>
                    <toggle-switch v-model="form.type">
                        <option value="bank_account">Bankonto</option>
                        <option value="equity_holder_account">Aktionärskonto</option>
                        <option value="cash_account">Kasse</option>
                    </toggle-switch>
                </td>
            </tr>
            <tr>
                <th><label for="name" class="label">Bezeichnung:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="name" class="input" v-model="form.name">
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="shortName" class="label">Kurzname:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="shortName" class="input" v-model="form.shortName">
                    </div>
                </td>
            </tr>
            <template v-if="form.type === 'cash_account'">
                <tr>
                    <th><label class="label">Unterkasse:</label></th>
                    <td>
                        <toggle-switch v-model="subFund">
                            <option :value="true">Ja</option>
                            <option :value="false">Nein</option>
                        </toggle-switch>
                    </td>
                </tr>
                <tr v-if="subFund">
                    <th><label class="label">Hauptkasse:</label></th>
                    <td>
                        <error-wrapper v-bind:message="errors.mainOrganisationBankAccount">
                            <async-select
                                api="organisation_bank_accounts"
                                id="organisationBankAccounts"
                                v-model="form.mainOrganisationBankAccount"
                            />
                        </error-wrapper>
                    </td>
                </tr>
            </template>
            <template v-else>
               <tr>
                    <th><label for="accountHolder" class="label">Kontoinhaber:</label></th>
                    <td>
                        <div class="control">
                            <input type="text" id="accountHolder" class="input" v-model="form.accountHolder">
                        </div>
                    </td>
               </tr>

               <tr>
                    <th><label for="iban" class="label">IBAN:</label></th>
                    <td>
                        <div class="control">
                            <input type="text" id="iban" class="input" v-model="form.iban">
                        </div>
                    </td>
               </tr>

               <tr>
                    <th>
                        <label class="label">Bank:</label>
                    </th>
                    <td>
                        <error-wrapper v-bind:message="errors.bank">
                            <async-select
                                api="banks"
                                id="bank"
                                v-model="form.bank"
                            />
                        </error-wrapper>
                    </td>
                </tr>
            </template>
            <tr v-if="!form.id && form.balance">
                <th><label for="currency" class="label">Währung:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="currency" class="input" v-model="form.balance.currency">
                    </div>
                </td>
            </tr>
            <tr v-if="!form.id && form.balance">
                <th><label class="label">Kontostand:</label></th>
                <td>
                    <div class="control">
                        <input-price v-model="form.balance.amount" placeholder="0,00" />
                    </div>
                </td>
            </tr>
            <tr>
                <th><label class="label">Mehrere Währungen:</label></th>
                <td>
                    <toggle-switch v-model="form.showMultipleCurrencies">
                        <option :value="true">ja</option>
                        <option :value="false">nein</option>
                    </toggle-switch>
                </td>
            </tr>
           <tr>
                <th>
                    <label class="label">Buchhaltungskonto:</label>
                </th>
                <td>
                   <error-wrapper v-bind:message="errors.accountPlanItem">
                       <async-select
                           api     = "account_plan_items"
                           v-model="form.accountPlanItem"
                           v-bind:custom-label = "itemNumber => itemNumber.accountingNumber + ' (' + itemNumber.name + ')'"
                           placeholder         = "nach Nummer oder Konto suchen"
                       />
                    </error-wrapper>

                </td>
           </tr>
           <template v-if="form.type === 'bank_account'">
               <tr>
                   <td colspan="2">
                       <h2>Schecks <i class="material-icons" v-on:click="addRow">add_circle</i></h2>
                   </td>
               </tr>
                <tr v-for="row in form.checkbooks">
                    <th>
                        <label class="label">Name / Laufende Nr:</label>
                    </th>
                    <td>
                        <div class="columns">
                            <div class="column">
                                <input class="input" v-model="row.name" placeholder="Bezeichnung">
                            </div>
                            <div class="column">
                                <input class="input" v-model="row.currentNumber" placeholder="Laufende Nr">
                            </div>
                            <div class="column is-narrow" style="flex-direction: row;">
                                <button type="button" @click="removeRow(row)" class="btn-delete" title="Löschen"><i class="material-icons">delete</i></button>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
        </table>
    </modal-form>
</template>

<script>
import TabForm from '../form'
import {AsyncSelect, ToggleSwitch} from '@components/form';
import InputPrice from "@calculations/components/InputPrice";

export default {
    mixins: [TabForm],

    property: 'organisationBankAccounts',

    form: {
        type: 'bank_account',
        name: '',
        accountHolder: '',
        iban: '',
        organisation: null,
        balance: {
            currency: 'EUR',
            amount: 0,
        },
        accountPlanItem: null,
        bank: null,
        checkbooks: [],
        mainOrganisationBankAccount: null

    },
    data: function () {
        return {
            subFund: false,
        }
    },
    components: {
        ToggleSwitch,
        AsyncSelect,
        InputPrice,
    },

    computed: {
        api: function() {
            return this.$store.state.api;
        },

        apiURL: function() {
            const id = this.$store.state.id;
            return `/api/${this.api}/${id}`
        },
    },
    methods: {
        openCallback() {
            this.form.organisation = this.apiURL;
        },

        removeRow: function(row){
            this.form.checkbooks.splice(row, 1);
        },
        addRow: function () {
            this.form.checkbooks.push({
                name: '',
                currentNumber: ''
            });
        }
    },
}
</script>
