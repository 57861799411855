<template>
    <loading v-if="isLoading"></loading>

    <div class="is-empty-cost" v-else-if="isEmpty">
        Berechnung ohne Preise nicht möglich
    </div>

    <div v-else>
        <div class="redesign-table is-costs">
            <div class="row is-head">
                <div class="cell">Leistung</div>
                <div class="cell"></div>
                <div class="cell">Anzahl</div>
                <div class="cell">Einzelpreis</div>
                <div class="cell">Gesamtpreis</div>
            </div>


            <package
                v-if="packageID"
                v-bind:calculations="calculations"
                v-bind:roomTypes="roomTypes"
                v-bind:cabinTypes="cabinTypes"
                v-bind:trainCabinTypes="trainCabinTypes"
                v-bind:isLoading="isLoading"
            ></package>

            <div class="is-zebra" v-else-if="calculations.type === 'night'">

                <div
                    class="row"
                    v-for="room in sortedRooms"
                    v-bind:key="room.id"
                >
                    <div class="cell"><span :style="isStandardRoom(room) ? 'font-weight: bold' : ''">{{ roomLabel(room) }}</span></div>
                    <div class="cell"></div>
                    <div class="cell">{{ roomPersons(room) }}</div>
                    <div class="cell">{{ roomPrice(room) | priceView }}</div>
                    <div class="cell"><b>{{ roomTotal(room) | priceView }}</b></div>
                </div>

                <div
                    class="row"
                    v-for="room in sortedRooms"
                    v-bind:key="'extra-' + room.id"
                    v-if="room.extraReservedAmount"
                >
                    <div class="cell">Extra - {{ getAccommodationLabel(room.type.type, room.type.persons, cabinTypes) }} - {{ room.extraDescription }}</div>
                    <div class="cell"></div>
                    <div class="cell">{{ room.extraReservedAmount * room.type.persons }}</div>
                    <div class="cell">{{ room.absolutePrice.amount | priceView }}</div>
                    <div class="cell"><b>{{ room.totalPriceExtraReserved.amount | priceView }}</b></div>
                </div>

                <div
                    class="row"
                    v-for="room in sortedRooms"
                    v-bind:key="'free-' + room.id"
                    v-if="room.personsFreePlaces"
                >
                    <div class="cell">{{freePlaceLabel(room)}}</div>
                    <div class="cell"></div>
                    <div class="cell">{{ room.personsFreePlaces }}</div>
                    <div class="cell">{{ -1 * room.absolutePrice.amount | priceView }}</div>
                    <div class="cell"><b>{{ -1 * room.totalPriceFreePlaces.amount | priceView }}</b></div>
                </div>


                <div class="row"
                    v-for="item in calculations.itemPrices"
                    v-bind:key="'item-' + item.id"
                    v-if="item.billingType !== 'info' && item.limitIsValid"
                >
                    <div class="cell">Zuschlag - {{ item.name }}</div>
                    <div class="cell">{{ item.quantityType === 'per_person_night' || item.quantityType === 'per_single_room_night' ? calculations.durationInDays : ''}}</div>
                    <div class="cell">{{ item.calculatedQuantity}}</div>
                    <div class="cell">{{ item.price.amount | priceView }}</div>
                    <div class="cell"><b>{{ item.totalPrice.amount | priceView }}</b></div>
                </div>

                <bus-prices v-bind:calculations="calculations" v-if="calculations.contingent.busPrice" />
            </div>


            <!-- ================ Day Ferry ================ -->
            <div class="is-zebra" v-else>
                <div class="row">
                    <div class="cell"><span style='font-weight: bold'>{{ calculations.info.title }} - Ticketpreis pro Person</span></div>
                    <div class="cell"></div>
                    <div class="cell">{{ calculations.personsReserved }}</div>
                    <div class="cell">{{ calculations.contingent.ticketPrice.amount | priceView }}</div>
                    <div class="cell"><b>{{ calculations.contingent.totalPriceReservedTickets.amount | priceView }}</b></div>
                </div>

                <div class="row"
                    v-if="calculations.personsFreePlaces"
                >
                    <div class="cell">Freiplatz</div>
                    <div class="cell"></div>
                    <div class="cell">{{ calculations.personsFreePlaces }}</div>
                    <div class="cell">{{ -1 * calculations.contingent.ticketPrice.amount | priceView }}</div>
                    <div class="cell"><b>{{ -1 * calculations.totalPriceFreePlaces.amount | priceView }}</b></div>
                </div>

                <div class="row"
                    v-if="calculations.personsExtraReserved"
                >
                    <div class="cell">Extra-Ticket - {{ calculations.contingent.ticketsExtraDescription }}</div>
                    <div class="cell"></div>
                    <div class="cell">{{ calculations.personsExtraReserved }}</div>
                    <div class="cell">{{ calculations.contingent.ticketPrice.amount | priceView }}</div>
                    <div class="cell"><b>{{ calculations.contingent.totalPriceExtraReservedTickets.amount | priceView }}</b></div>
                </div>

                <div class="row"
                    v-for="item in calculations.itemPrices"
                    v-bind:key="'item-' + item.id"
                    v-if="item.billingType !== 'info' && item.limitIsValid"
                >
                    <div class="cell">Zuschlag: {{ item.name }}</div>
                    <div class="cell">{{ item.quantityType === 'per_person_night' || item.quantityType === 'per_single_room_night' ? calculations.durationInDays : ''}}</div>
                    <div class="cell">{{ item.quantityType === 'per_person' ? calculations.info.personsReserved : item.quantity }}</div>
                    <div class="cell">{{ item.price.amount | priceView }}</div>
                    <div class="cell"><b>{{ item.totalPrice.amount | priceView }}</b></div>
                </div>

                <bus-prices v-bind:calculations="calculations" v-if="calculations.contingent.busPrice" />
            </div>

            <loading v-if="isLoading"></loading>
        </div>

        <div class="for-costs-total">
            <div>TOTAL ({{calculations.totalPrice.currency}})</div>
            <div><b>{{ calculations.totalPrice.amount | priceView }}</b></div>
        </div>
    </div>
</template>



<script>
import mixins from './mixins';
import BusPrices from './BusPrices'


export default {
    mixins: [mixins],

    components: { BusPrices },

    data: function () {
        return {
            apiEndpoint: 'ferry_requests/'
        }
    },

    computed: {
        sortedRooms: function () {
            let roomTypes = this.cabinTypes;
            return this.calculations.contingent.all.sort(function (a, b){
                if(!roomTypes){
                    return 0;
                }
                const indexA = roomTypes.findIndex(item => item.persons === a.type.persons && item.type === a.type.type);
                const indexB = roomTypes.findIndex(item => item.persons === b.type.persons && item.type === b.type.type);
                return indexA - indexB;
            });
        },

        standardRoom: function () {
            return this.sortedRooms.find(room => this.isStandardRoom(room));
        }
    },

    methods: {
        isStandardRoom: function (room) {
            return room.type.type === 'inside' && room.type.persons === 2;
        },

        isNegative: function(room) {
            return this.standardRoom.absolutePrice.amount > room.absolutePrice.amount;
        },

        roomLabel: function (room) {
            return this.isStandardRoom(room) ?
                this.calculations.info.title + ' - Preis pro Person - ' + this.getAccommodationLabel('inside', 2, this.cabinTypes)
                : this.getAccommodationLabel(room.type.type, room.type.persons, this.cabinTypes)
        },

        freePlaceLabel: function (room) {
            return this.isStandardRoom(room) ? 'Freiplatz in der halben Doppelkabine' : 'Freiplatz in der Einzelkabine';
        },

        roomPersons: function (room) {
            if(this.supplementPrices && this.isStandardRoom(room)){
                return this.calculations.personsReserved
            }
            return this.supplementPrices ? room.personsForCosts : room.reservedAmount * room.type.persons;
        },

        roomPrice: function (room) {
            return !this.supplementPrices || this.isStandardRoom(room) ? room.absolutePrice.amount : room.premiumPrice.amount;
        },

        roomTotal: function (room) {
            if(this.isStandardRoom(room)) {
                return room.totalPriceReservedStandard.amount ;
            }
            return !this.supplementPrices ? room.totalPriceReserved.amount : room.totalPriceReservedPremium.amount
        },

        roomExtraTotal: function (room) {
            return room.totalPriceExtraReservedPremium.amount
        }
    },
}
</script>
