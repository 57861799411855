var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rating" },
    [
      _c("div", { staticClass: "rating__circle" }, [
        _c(
          "div",
          {
            staticClass: "rating__value",
            style: { "line-height": `${_vm.size}px` },
          },
          [_vm._v(_vm._s(_vm.rating > 0 ? _vm.rating : "-"))]
        ),
        _vm._v(" "),
        _c(
          "svg",
          {
            attrs: {
              width: _vm.size,
              height: _vm.size,
              viewBox: `0 0 ${_vm.size} ${_vm.size}`,
            },
          },
          [
            _c("circle", {
              staticClass: "circle__meter",
              attrs: {
                cx: _vm.halfSize,
                cy: _vm.halfSize,
                r: _vm.radius,
                "stroke-width": _vm.weight,
              },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "circle__value",
              style: {
                strokeDashoffset: _vm.progress,
                strokeDasharray: _vm.circumference,
              },
              attrs: {
                cx: _vm.halfSize,
                cy: _vm.halfSize,
                r: _vm.radius,
                "stroke-width": _vm.weight,
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }