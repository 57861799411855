var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("loading")
        : _c(
            "div",
            [
              _c("div", { staticClass: "level" }, [
                _c("div"),
                _vm._v(" "),
                _c("div", { staticClass: "buttons has-addons" }, [
                  _c(
                    "button",
                    {
                      class: [
                        "btn-single-toggle",
                        _vm.isDraggableDisabled ? "" : "is-active",
                      ],
                      attrs: {
                        title: _vm.isDraggableDisabled
                          ? "Ziehen aktivieren"
                          : "Ziehen blockieren",
                      },
                      on: {
                        click: function ($event) {
                          _vm.isDraggableDisabled = !_vm.isDraggableDisabled
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("lock"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      class: [
                        "btn-single-toggle",
                        _vm.isRouteView ? "is-active" : "",
                      ],
                      staticStyle: { "margin-right": "1px" },
                      on: {
                        click: function ($event) {
                          _vm.isRouteView = !_vm.isRouteView
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("place"),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "is-relative" },
                [
                  _c(
                    "draggable",
                    {
                      attrs: {
                        list: _vm.sortedDays,
                        options: {
                          disabled:
                            _vm.draggableDisabled || _vm.isDraggableDisabled,
                        },
                      },
                      on: { change: _vm.changeOrder },
                    },
                    [
                      _vm._l(_vm.sortedDays, function (day, index) {
                        return _c(
                          "accordion",
                          {
                            key: day.id,
                            staticClass: "day has-hidden-arrow is-concept",
                            class: { "is-empty": !_vm.hasDayPlaceholders(day) },
                            attrs: {
                              isOpen: _vm.hasDayPlaceholders(day),
                              isCollapsibe: _vm.hasDayPlaceholders(day),
                            },
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("header", [
                                _c("span", [
                                  _vm._v(
                                    "Tag " +
                                      _vm._s(index + 1) +
                                      " - " +
                                      _vm._s(day.name)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "day-action-buttons" },
                                  [
                                    _c(
                                      "simple-menu",
                                      {
                                        attrs: {
                                          material: "",
                                          icon: "more_vert",
                                          title: "Menu",
                                        },
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.editDay(day.id)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              { staticClass: "material-icons" },
                                              [_vm._v("mode_edit")]
                                            ),
                                            _vm._v(" Bearbeiten"),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.deleteDay(day.id)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              { staticClass: "material-icons" },
                                              [_vm._v("delete")]
                                            ),
                                            _vm._v(" Löschen"),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "template",
                              { slot: "content" },
                              [
                                _vm.hasDayPlaceholders(day)
                                  ? _vm._l(
                                      _vm.dayPlaceholders(day),
                                      function (placeholder) {
                                        return _c(
                                          placeholder.placeholderType + "-view",
                                          {
                                            key:
                                              placeholder.placeholderType +
                                              "-" +
                                              placeholder.id,
                                            tag: "component",
                                            attrs: {
                                              placeholder: placeholder,
                                              isRouteView: _vm.isRouteView,
                                              options: _vm.loadedOptions,
                                              isConcept: "",
                                            },
                                          },
                                          [
                                            placeholder.placeholderType !==
                                            "route"
                                              ? _c(
                                                  "simple-menu",
                                                  {
                                                    attrs: {
                                                      material: "",
                                                      icon: "more_vert",
                                                      title: "Menu",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.openConceptProviderForm(
                                                              {
                                                                providers:
                                                                  placeholder.providers,
                                                                dayId: day.id,
                                                                placeholder:
                                                                  placeholder,
                                                                id: placeholder.id,
                                                              }
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "material-icons",
                                                          },
                                                          [_vm._v("add")]
                                                        ),
                                                        _vm._v(
                                                          " Leistungsträger auswählen"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  : _c("div", { staticClass: "placeholder" }, [
                                      _c("span", { staticClass: "text-sm" }, [
                                        _vm._v(
                                          "Das Tageskonzept enthält keine weiteren Elemente"
                                        ),
                                      ]),
                                    ]),
                              ],
                              2
                            ),
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.draggableDisabled
                    ? _c(
                        "div",
                        { staticClass: "loading-container" },
                        [_vm.draggableDisabled ? _c("loading") : _vm._e()],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.showConfigurator
                ? _c(
                    "div",
                    { staticClass: "buttons is-fullwidth has-addons" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "button is-dashed",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showConfigurator = true
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("library_books"),
                          ]),
                          _vm._v(
                            " Weiteres bestehendes Tageskonzept hinzufügen\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button is-dashed",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addDayConcept.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("add_circle"),
                          ]),
                          _vm._v(
                            "\n                Neues Tageskonzept erstellen\n            "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("concept-provider-form", {
                ref: "conceptProviderForm",
                attrs: { options: [] },
              }),
              _vm._v(" "),
              _vm.showConfigurator
                ? _c("day-concept", {
                    staticClass: "is-dashed",
                    attrs: {
                      selected: _vm.selectedDayIds,
                      dayCount: _vm.item.days.length,
                      options: _vm.loadedOptions,
                      destinations: _vm.item.destinations,
                    },
                    on: {
                      cancel: function ($event) {
                        _vm.showConfigurator = false
                      },
                      submit: _vm.storeDay,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }