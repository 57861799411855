<template>
    <span v-if="value">{{board}}</span>
</template>

<script>
import Cell from './cell';
import { getValueWithKey } from '@utilities/functions';

export default {
    mixins: [ Cell ],

    computed: {
        board: function () {
            return getValueWithKey({key: this.value, optionList: this.options.board})
        },
    }
}
</script>

