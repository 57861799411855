var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "columns",
    {
      attrs: { property: "organisationBankAccounts", "load-options": false },
      on: { add: _vm.openForm },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function ({ item }) {
            return _c(
              "column-item",
              {
                attrs: {
                  update: {
                    updatedBy: item.updatedBy,
                    updatedAt: item.updatedAt,
                  },
                  api: "/api/organisation_bank_accounts/" + item.id,
                  label: item.accountPlanItem
                    ? item.accountPlanItem.accountingNumber
                    : "",
                },
                on: {
                  edit: function ($event) {
                    return _vm.openForm(item)
                  },
                  delete: function ($event) {
                    return _vm.deleteItem("organisationBankAccounts", item)
                  },
                },
              },
              [
                _c("ul", [
                  _c("li", [_c("strong", [_vm._v(_vm._s(item.name))])]),
                  _vm._v(" "),
                  item.type !== "cash_account"
                    ? _c("li", [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("person"),
                        ]),
                        _vm._v(_vm._s(item.accountHolder)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  item.type !== "cash_account"
                    ? _c("li", [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("credit_card"),
                        ]),
                        _vm._v(_vm._s(item.iban)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  item.type !== "cash_account" && item.bank
                    ? _c("li", [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("account_balance"),
                        ]),
                        _vm._v(
                          _vm._s(item.bank.name) +
                            " (" +
                            _vm._s(item.bank.bic) +
                            ")"
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            )
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("account-form", {
        ref: "form",
        attrs: { slot: "form" },
        slot: "form",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }