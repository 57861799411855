<template>
    <div>
        <loading v-if="isLoading"/>

        <div class="columns " v-for="row in rows" v-else>
            <div v-for="col in row.cols" class="column" :class="col.columnClass" :style="col.columnStyle">
                <div>
                    <h2 class="is-headline">{{col.label}}</h2>
                </div>
                <div>
                    <component
                        :is="col.component.type"
                        :component="col.component"
                        @saveItem="saveItem"
                    />

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import debounce        from 'lodash/debounce';
import Mixin from '../tab';
import HomepagePublish from '../Webflow/Components/HomepagePublish'
import HomepageImage from '../Webflow/Components/HomepageImage';
import HomepageInput from "../Webflow/Components/HomepageInput";
import HomepagePriceTable from "../Webflow/Components/HomepagePriceTable";
import HomepageEditor from "../Webflow/Components/HomepageEditor";
import HomepageStatus from "../Webflow/Components/HomepageStatus";
import HomepageReady from "../Webflow/Components/HomepageReady";
import {notifyError, notifySuccess} from "@components/Notification";
import Loading from "@components/Loading";

export default {
    mixins: [ Mixin ],

    data: function() {
        return {
            isLoading: false,
        };
    },

    computed: {
        rows: function() {
            if(this.api === 'destinations'){
                return [
                    {
                        cols: [
                            {
                                label: 'Titelbild',
                                columnClass: '',
                                columnStyle:'is-one-third',
                                component: {
                                    type: 'homepageImage',
                                    props: {
                                        filterParams: {type: 'trip_detail_title'},
                                        columnStyle: {'padding-top': 0, 'height': '438px', 'max-width': '450px'},
                                        columnClasses: '',
                                        multiImages: false,
                                        enableZoom: false
                                    }
                                }
                            },
                            {
                                label: 'Routenkarte',
                                columnClass: '',
                                columnStyle: 'is-one-third',
                                component: {
                                    type: 'homepageImage',
                                    props: {
                                        dropzoneID: 'dropzone-3',
                                        filterParams: {type: 'trip_detail_map'},
                                        columnStyle: {'padding-top': 0, 'height': '438px', 'max-width': '450px'},
                                        columnClasses: '',
                                        multiImages: false,
                                        enableZoom: false
                                    }
                                }
                            },
                            {
                                label: 'Einleitungstext',
                                columnClass: '',
                                columnStyle: 'is-one-third',
                                component: {
                                    type: 'homepageEditor',
                                    props: {
                                        property: 'tripDetailsIntroText',
                                        //columnStyle: {'padding-top': 0, 'height': '350px', 'max-width': '500px'},
                                        defaultSize: 394,
                                    }
                                }
                            },
                        ]
                    },
                ]
            }
        }

    },


    methods: {
        saveItem: debounce(function(key, data, update = false, publish = false) {
            if(publish){
                this.isLoading = true;
            }
            this.$store.dispatch('storeProperty', {
                key: key,
                data: data,
            }).then(data => {
                notifySuccess('Die Daten wurden gespeichert!')
            }, error => {
                notifyError("Die Daten konnten nicht gespeichert werden! Error happened")
            }).then(() => {this.isLoading = false;})
        }, 1000),
    },

    components: {
        Loading,
        HomepageInput,
        HomepageImage,
        HomepageEditor,
        HomepagePriceTable,
        HomepageStatus,
        HomepageReady,
        HomepagePublish,
    },
}
</script>
