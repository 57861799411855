<template>
    <row v-if="!hide">
        <template slot="type">
            <i class="material-icons">{{icon}}</i>
        </template>
        <template slot="name">
            <input type="text" class="input" v-model="price.name" />
        </template>
        <template slot="marginTax">
            <margin-tax v-model="marginTaxEnabled" :tax="marginTax" :margin="grossMargin" />
        </template>
        <template slot="buyPriceCalculation" v-if="showExchangeRates">
            <input-price
                v-model="buyPriceInCalculationCurrency"
            />
        </template>
        <template slot="buyPrice">
            <input-price
                v-model="price.buyPrice.amount"
                :is-blue="hasTwoCurrencies"
            />
        </template>
        <template slot="sellPriceCalculation" v-if="showExchangeRates">
            <input-price
                v-model="sellPriceInCalculationCurrency"
            />
        </template>
        <template slot="sellPrice">
            <input-price
                v-model="price.sellPrice.amount"
                :is-blue="hasTwoCurrencies"
            />
        </template>
        <template slot="profitCalculation" v-if="showExchangeRates">
            <input-price
                v-model="profitInCalculationCurrency"
            />
        </template>
        <template slot="profit">
            <input-price
                v-model="profit"
                :is-blue="hasTwoCurrencies"
            />
        </template>
        <template slot="margin">
            <input-price v-model="margin" />
        </template>
        <template slot="actions">
            <i class="material-icons" v-on:click.prevent.stop="deleteExtra">delete</i>
        </template>
    </row>
</template>

<script>
import price from './price' ;
export default {
    mixins: [ price ],

    computed: {
        offerTextBlock: function () {
            return this.price.offerTextBlock;
        },

        icon: function () {
            return {

                'hotelSuplements': 'local_hotel',
                'ferrySuplements': 'directions_boat',
                'ferryOther': 'directions_boat',
                'foodOnBoard': 'restaurant_menu',
                'optionalFoodOnBoard': 'restaurant_menu',
                'tourGuides': 'record_voice_over',
                'optionalTourGuides': 'record_voice_over',

                'groupServices': 'people',
                'optionalGroupServices': 'people',
                'services': 'person',
                'optionalServices': 'person',
                'mainPriceDates': 'folder'
            }[this.offerTextBlock]
        }
    },

    methods: {
        deleteExtra: function () {
            this.$store.commit('removeExtra', this.price)
        },

        updatePrice: function (obj = {}) {
            this.$store.commit('updatePrice', {
                extraPrices: {
                    id: this.price.id,
                    ...obj
                }
            })
        }
    }
}
</script>
