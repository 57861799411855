<template>
    <modal visible v-on:closeModal="$emit('close')" class="is-form is-medium">
        <p slot="header">Zusatzleistung löschen (ID: {{item.id}} )</p>

        <template slot="content">

            <h2 class="title">MIT ZUSATZLEISTUNG VERBUNDENE ELEMENTE</h2>
            <crud-table
                :api="api"
                :url="table.url"

                storeKey="delete"

                :filter="table.filter"
                :load-options="false"

                :deleteRow="false"
                :perPage="5"

                :columns="table.columns"
            >
                <template slot="filters" slot-scope="{}" />
                <template slot="head" slot-scope="{}">
                    <div class="row is-head">
                        <div class="cell">
                            <toggle-switch v-model="api">
                                <option value="orders">Reisen</option>
                            </toggle-switch>
                        </div>
                    </div>
                </template>
                <template slot="dates" slot-scope="{row}">{{ row.startAt | fullYear }} - {{ row.endAt | fullYear}}</template>
                <template slot="id" slot-scope="{row}">#{{ row.id }}</template>
            </crud-table>

            <h2 class="title">ZUSATZLEISTUNG ERSETZEN</h2>
            <toggle-switch v-model="replace" btn-class="is-300">
                <option :value="true">Zusatzleistung ersetzen (empfohlen)</option>
                <option :value="false">Zusatzleistung & Verknüpfungen löschen</option>
            </toggle-switch>

            <template v-if="replace">
                <div class="level">
                    <async-select
                        api="other_services"
                        placeholder="Zusatzleistung suchen"
                        v-model="replacement"
                        :custom-label = "otherServiceLabel"
                        :params = "filterParams"
                    />
                </div>

                <h2 class="title">INHALTE DER ZU LÖSCHENDEN ZUSATZLEISTUNG AUF NEUE ZUSATZLEISTUNG ÜBETRAGEN</h2>
                <div v-for="option in sortedOptions" class="level mb-xs">
                    <div class="level-left">
                        <toggle-switch v-model="option[0].isEnabled" btn-class="is-230">
                            <option :value="true">{{option[0].label}} übetragen</option>
                            <option :value="false">nicht übertragen</option>
                        </toggle-switch>
                    </div>
                </div>
            </template>


            <div class="alert is-danger" v-else>
                <p><strong>Achtung:</strong></p>
                <p>
                    Wenn die Zusatzleistung gelöscht wird, werden Anfragen zu dieser Zusatzlesistung auch in den Reisen gelöscht!
                </p>
            </div>
        </template>

        <div slot="footer" class="level">
            <button
                class="button"
                v-on:click.prevent="$emit('close')"
            >Abbrechen</button>

            <button
                v-bind:class="{ 'is-loading': isLoading }"
                class="button is-danger"
                v-on:click.prevent="handleDelete"
            >Löschen</button>
        </div>
    </modal>
</template>

<script>
import { CrudTable } from '@components/CRUD';
import Modal from '@components/Modal';
import { ToggleSwitch, AsyncSelect } from '@components/form';
import { notifySuccess, notifyError } from '@components/Notification';
import { replaceOtherService } from '@api';
import { fullYear } from '@utilities/functions';

export default {
    props: {
        item: {
            required: true
        },
        filterParams: {
            default: {}
        }
    },

    components: {
        Modal,
        CrudTable,
        ToggleSwitch,
        AsyncSelect
    },

    data: function() {
        return {
            api: 'orders',

            replace: true,
            replacement: null,
            isLoading: false,

            options: [
                { key: 'prices', isEnabled: true, label: 'Preise'},
            ],
        }
    },

    computed: {
        sortedOptions: function () {
            let x = 0;
            let array = [];
            let current = [];
            this.options.forEach(option => {
                current.push(option);
                x++;
                if(x % 2 === 0) {
                    array.push(current);
                    current = [];
                }
            })
            if(x % 2 === 1) {
                current.push({key: ''});
                array.push(current);
            }
            return array;
        },

        table: function() {
            return {
                url: {
                    orders: '/orders',
                }[this.api],
                filter: {
                    orders: { 'days.otherPlaceholders.requests.otherService.id': this.item.id},
                }[this.api],
                columns: {
                    orders: ['id', 'client.companyName', 'trip.destinations', 'dates'],
                }[this.api]
            }
        }
    },

    methods: {
        otherServiceLabel: function(item) {
            let label = item.id;
            label += item.serviceDescription ? ' - ' + item.serviceDescription : '';
            label += item.subtitle ? ' - ' + item.subtitle : '';
            label += item.otherServiceType.place ? ' (' + item.otherServiceType.place.name + ')' : '';
            label += item.otherServiceType.area ? ' (' + item.otherServiceType.area.name + ')' : '';
            label += item.otherServiceType.destination ? ' (' + item.otherServiceType.destination.name + ')' : '';
            label += item.provider ? ' | ' + item.provider.name : '';
            label += item.agency ? ' | ' + item.agency.name : '';
            return label;
        },

        handleDelete: function() {
            if (this.replace && !this.replacement) {
                notifyError('Bitte Ersatz-Zusatzleistung wählen')
                return;
            }

            this.isLoading = true;
            replaceOtherService(this.item.id, {
                ...this.replace && {
                    replacementId: this.replacement.id,
                    ...Object.assign(...this.options.filter(option => option.key).map(option => {
                        return {
                            [option.key]: option.isEnabled
                        }
                    }))
                }
            })
                .then(response => {
                    notifySuccess('Die Zusatzleistung wurde erfolgreich gelöscht!')
                    this.isLoading = false;
                    this.$emit('deleted', this.item)
                    this.$emit('close')
                }, error => {
                    this.isLoading = false;
                    notifyError('Problem! Die Zusatzleistung konnte nicht gelöscht werden!')
                })
        },
    },

    filters: {
        fullYear
    }
}
</script>
