<template>
    <div>{{ location }} </div>
</template>

<script>
import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        location() {
            const service = this.row;

            if (!!service.place) {
                return service.place.name;
            } else if (!!service.destination){
                return service.destination.name;
            } else if (!!service.area){
                return service.area.name;
            }

            if(!!service.otherServiceType.place){
                return service.otherServiceType.place.name;
            } else if (!!service.otherServiceType.destination){
                return service.otherServiceType.destination.name;
            } else if (!!service.otherServiceType.area){
                return service.otherServiceType.area.name;
            }


            return '-';
        },
    }
}
</script>
