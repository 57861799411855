var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row is-headline is-small" }, [
        _c("div", { staticClass: "is-flex" }, [
          _c(
            "div",
            {
              staticClass: "level level-left mb-0",
              on: { click: _vm.toggleCollapse },
            },
            [
              _vm.hasPrices
                ? _c(
                    "i",
                    {
                      staticClass: "collapse-icon material-icons level-item",
                      class: { "is-rotated": !_vm.isOpen },
                    },
                    [_vm._v("keyboard_arrow_up")]
                  )
                : _c("i", { staticClass: "material-icons level-item" }, [
                    _vm._v("close"),
                  ]),
              _vm._v(" "),
              _c("span", { staticClass: "level-item" }, [
                _vm._v(_vm._s(_vm.name)),
              ]),
              _vm._v(" "),
              _vm.otherService.otherService.description
                ? _c(
                    "popover",
                    { attrs: { trigger: "hover" } },
                    [
                      _c("i", { staticClass: "material-icons m-r" }, [
                        _vm._v("info"),
                      ]),
                      _vm._v(" "),
                      _c("template", { slot: "popover" }, [
                        _c("div", { staticStyle: { "text-align": "left" } }, [
                          _c("h3", [_vm._v("Beschreibung:")]),
                          _vm._v(" "),
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.otherService.otherService.description
                              ),
                            },
                          }),
                        ]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "level-item",
                  attrs: {
                    href: `/other_service_types/${_vm.id}`,
                    target: "_blank",
                  },
                },
                [_c("i", { staticClass: "material-icons" }, [_vm._v("  link")])]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "btn-edit",
              on: {
                click: function ($event) {
                  return _vm.$emit("addPriceGroup")
                },
              },
            },
            [_c("i", { staticClass: "fa fa-calendar-plus-o fa-lg" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-edit",
              on: {
                click: function ($event) {
                  return _vm.$emit("editOtherService")
                },
              },
            },
            [_c("i", { staticClass: "material-icons" }, [_vm._v("edit")])]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-delete",
              on: {
                click: function ($event) {
                  return _vm.$emit("deleteOtherService")
                },
              },
            },
            [_c("i", { staticClass: "material-icons" }, [_vm._v("delete")])]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.isOpen
        ? _vm._l(_vm.groups, function (priceGroup) {
            return _c("price-group-row", {
              key: priceGroup.id,
              attrs: {
                priceGroup: priceGroup,
                options: _vm.options,
                "show-next-row": _vm.otherService.groups.length < 2,
                "price-type": "other",
              },
              on: {
                updatePriceGroup: _vm.updatePriceGroup,
                editPriceGroup: function ($event) {
                  return _vm.$emit("editPriceGroup", priceGroup)
                },
                deletePriceGroup: function ($event) {
                  return _vm.$emit("deletePriceGroup", priceGroup)
                },
              },
            })
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }