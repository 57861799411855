var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("div", [_vm._v("Buspreis")]),
          _vm._v(" "),
          _c("input-price", {
            attrs: {
              "provider-prices": _vm.value.providerPrices.bus_price,
              "small-input": true,
              "price-currency": _vm.value.requestCurrency,
            },
            model: {
              value: _vm.busPrice,
              callback: function ($$v) {
                _vm.busPrice = $$v
              },
              expression: "busPrice",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("div", [_vm._v("Steuern - Bus")]),
          _vm._v(" "),
          _c("input-price", {
            attrs: {
              "provider-prices": _vm.value.providerPrices.bus_tax,
              "small-input": true,
              "price-currency": _vm.value.requestCurrency,
            },
            model: {
              value: _vm.busTax,
              callback: function ($$v) {
                _vm.busTax = $$v
              },
              expression: "busTax",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("div", [_vm._v("Steuern - pro Person")]),
          _vm._v(" "),
          _c("input-price", {
            attrs: {
              "provider-prices": _vm.value.providerPrices.ticket_tax,
              "small-input": true,
              "price-currency": _vm.value.requestCurrency,
            },
            model: {
              value: _vm.ticketTax,
              callback: function ($$v) {
                _vm.ticketTax = $$v
              },
              expression: "ticketTax",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }