

export const infoSections = {
    trip_dashboard: 'Reisemaske',
    offer:          'Routenplanung in Dokumenten',
};

export const categoryIcons = {
    'activity': {icon: 'place'},
    'round': {icon: 'autorenew'},
    'culture': {icon: 'account_balance'},
    'active': {icon: 'directions_run'},
    'home': {text: 'WH'},
    'classics': {text: 'WC'},
    'experience': {text: 'WE'},
    'individual': {text: 'I'},
};

export const emailHosts = [
    'buspaket.de',
    'we-tours.de'
];

export const preSelections = {
    'order_concept': {
        'sellingPriceForm': {
            'periodType': 'dates'
        }
    }
}


