<template>
    <div class="has-margin-top">
        <div class="bp-panel has-no-title" style="min-height: auto">
            <div class="panel-content">
                <div v-for="(object, index) in objects">
                    <div class="field">
                        <div class="control">
                            <label class="checkbox" style="white-space: nowrap;overflow: hidden;width: 100%;">
                                <input type="checkbox" v-if="isHotel" :checked="getHotelChecked(object)" @input="setHotelChecked(object)">
                                <a class="is-link" target="_blank" :href="getLink(object)">{{getLabel(object, index+1)}}</a>
                            </label>
                        </div>
                        <p class="is-small-text"
                           :class="{
                            'is-turqoise-text': !!object.readyForStaging,
                            'is-text-red': !object.readyForStaging
                            }">{{getSmallText(object)}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {compareDates, dateISOtoView} from "@utilities/functions";
import Mixin from '../../tab';


export default {
    mixins: [ Mixin ],



    props: {
        component: {
            type: Object,
            required: true
        },
    },

    data: function() {
        return {
            form: {
                websites: []
            }
        }
    },

    methods: {
        getLabel: function (object, index) {
            if(this.isDay){
                return index + '. Tag: ' + object.name;
            }
            if(this.isHotel){
                return object.name
            }
            if(this.isDestination){
                return object.name
            }
        },

        getSmallText: function (object) {
            if(this.isDay){
                return object.readyForStaging ? 'kann veröffentlicht werden' : 'muss noch geprüft werden'
            }
            if(this.isDestination){
                return object.readyForStaging ? 'kann veröffentlicht werden' : 'muss noch geprüft werden'
            }
            if(this.isHotel){
                if(!object.selected) {
                    return 'nicht für Homepage ausgewählt';
                }
                return object.readyForStaging ? 'kann veröffentlicht werden' : 'muss noch geprüft werden'
            }
        },

        getLink: function (object) {
            if(this.isDay){
                return '/concepts/days/' + object.id;
            }
            if(this.isHotel){
                return '/hotels/' + object.id;
            }
            if(this.isDestination){
                return '/destinations/' + object.id;
            }
        },

        getHotelChecked: function (object) {
              return object.selected;
        },

        setHotelChecked: function (object) {


            let id = String(object.id)
            const foundIndex = this.item.publishedHotelIds.indexOf(id);

            if(foundIndex !== -1){
                if(this.item.publishedHotelIds)
                this.item.publishedHotelIds.splice(foundIndex, 1);
                this.$emit('saveItem', 'publishedHotelIds', this.item.publishedHotelIds, true);
                this.$store.commit('updateData', {
                    conceptProviderHotels: this.item.conceptProviderHotels.map(hotel => ({
                        ...hotel,
                        ...hotel.id === object.id && {selected: false}
                    }))
                })
            } else {
                this.item.publishedHotelIds.push(id);

                this.$emit('saveItem', 'publishedHotelIds', this.item.publishedHotelIds, true);
                this.$store.commit('updateData', {
                    conceptProviderHotels: this.item.conceptProviderHotels.map(hotel => ({
                        ...hotel,
                        ...hotel.id === object.id && {selected: true}
                    }))
                })
            }
        }
    },

    computed: {

        isDay: function () {
            return this.component.props.object === 'day';
        },

        isHotel: function () {
            return this.component.props.object === 'hotel';
        },

        isDestination: function () {
            return this.component.props.object === 'destination';
        },

        objects: function () {
            if(this.isDay){
                return this.item.orderedDays;
            }
            if(this.isHotel){
                return this.item.conceptProviderHotels;
            }
            if(this.isDestination){
                return this.item.destinations;
            }
        }
    },
}
</script>
