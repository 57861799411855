var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popover",
    { staticClass: "is-large", attrs: { trigger: "hover", container: false } },
    [
      _c("span", {
        staticClass: "circle",
        class: {
          "is-orange": _vm.isDifferent,
          "is-small-input": _vm.smallInput,
          "is-green": !_vm.isDifferent,
        },
        on: {
          click: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.handleCircleClick.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _vm.mainPrices.length > 1
        ? _c("div", {
            staticClass: "circle-line",
            class: {
              "is-orange": _vm.isDifferent,
              "is-green": !_vm.isDifferent,
              "is-small-input": _vm.smallInput,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("template", { slot: "popover" }, [
        _c(
          "ul",
          { staticClass: "has-text-left" },
          [
            _vm.mainPrices.length > 0
              ? [
                  _c("li", { staticClass: "level mb-0" }, [
                    _c("span", [
                      _vm._v(
                        "Vorgeschlagene Preise (umgerechnet in " +
                          _vm._s(_vm.priceCurrency) +
                          "): "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(
                        "Ø " + _vm._s(_vm._f("priceView")(_vm.mainAverage))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [_c("hr", { staticClass: "my-sm" })]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.mainPrices, function (price, index) {
              return _c("li", { key: "main" + index }, [
                _vm._v(
                  "\n                " +
                    _vm._s(price.date) +
                    " | " +
                    _vm._s(price.description)
                ),
                _c("br"),
                _vm._v(" "),
                _c("strong", [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("priceView")(price.price.amount)) +
                        " " +
                        _vm._s(price.price.currency)
                    ),
                  ]),
                ]),
              ])
            }),
            _vm._v(" "),
            _vm.mainPrices.length > 0 && _vm.loadedPrices.length > 0
              ? _c("li", [_c("br")])
              : _vm._e(),
            _vm._v(" "),
            _vm.loadedPrices.length > 0
              ? [
                  _c("li", { staticClass: "level mb-0" }, [
                    _c("span", [_vm._v("Weitere Preise: ")]),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(
                        "Ø " + _vm._s(_vm._f("priceView")(_vm.loadedAverage))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [_c("hr", { staticClass: "my-sm" })]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.loadedPrices, function (price, index) {
              return _c("li", { key: "loaded-" + index }, [
                _vm._v(
                  "\n                " +
                    _vm._s(price.date) +
                    " | " +
                    _vm._s(price.description)
                ),
                _c("br"),
                _vm._v(" "),
                _c("strong", [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("priceView")(price.price.amount)) +
                        " " +
                        _vm._s(price.price.currency)
                    ),
                  ]),
                ]),
              ])
            }),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }