<template>
    <div class="panel is-alt is-small is-day">
        <div class="panel-heading">
            <div class="level-left">
                {{heading}}
            </div>
            <div class="level-right" v-if="isConcept">
                <multiselect
                    v-if="options.checkedStatusList"
                    v-model="checkStatus"
                    track-by="key"
                    label="value"
                    v-bind:options="options.checkedStatusList"
                />
            </div>
        </div>
        <div class="panel-block">
            <div class="panel-content">
                <div class="has-margin-top mb" v-html="catalogText" v-if="!day.catalogTextChanged && !isConcept"></div>
                <tip-tap
                    v-if="day.catalogTextChanged || isConcept"
                    v-model="catalogText"
                    class="is-fullwidth is-smaller resizeable"
                    resizeable
                    isNotAlone
                    v-on:input="storeText"
                    :menuItems="['fullscreen', 'bold', 'italic', 'underline', 'bullet_list', 'ordered_list', 'color', 'undo']"
                />
                <div class="level-left buttons" v-if="!showSearch && !isConcept">
                    <button
                        class="button is-large"
                        v-on:click.stop.prevent="initDaySearch"
                    >Vorlage suchen</button>
                    <button
                        v-if="!day.catalogTextChanged"
                        class="button is-large"
                        v-on:click.stop.prevent="day.catalogTextChanged = true"
                    >Manuell bearbeiten</button>
                </div>

                <div v-else-if="!isConcept">
                    <div>
                        <div class="search-day-concept is-gapless">
                            <div class="column is-one-third">
                                <div class="level-left">
                                    <i class="material-icons level-item">search</i>
                                    <input
                                        type="text"
                                        class="input"
                                        placeholder="Suche ein Konzept"
                                        v-model="searchTerm"
                                        multiple
                                    />
                                </div>
                            </div>

                            <div class="column is-one-third">
                                <multiselect
                                    v-bind:options="this.options.checkedStatusList"
                                    v-model="filters.statuses"
                                    track-by="key"
                                    label="value"
                                    :multiple="true"
                                >
                                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                                        <span class="multiselect__single" v-if="values.length && values.length > 1 && !isOpen">{{ values.length }} Stati ausgewählt</span>
                                    </template>
                                </multiselect>
                            </div>

                            <div class="column is-one-third">
                                <div class="level-right">
                                    <div class="buttons has-addons">
                                        <button class="button is-large" v-on:click.prevent.stop="showSearch = false">Abbrechen</button>
                                        <button
                                            class="button is-large is-primary"
                                            v-on:click.prevent.stop="replaceCatalogText"
                                            :disabled="!conceptSelected"
                                        >Vorlage übernehmen</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <search-day-concept
                        class="is-gapless"
                        v-bind:searchTerm    = "searchTerm"
                        v-on:input           = "value => conceptSelected = value"
                        :destinations        = "[]"
                        :statuses            = "filters.statuses"
                    >
                        <template slot="variation" slot-scope="{concept}">
                            <p class="nl2br" v-html="concept.catalogText" v-if="concept"></p>
                        </template>
                    </search-day-concept>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchDayConcept from '@orders/concepts/components/SearchDayConcept';
import { editDay,editDayConcept } from '@api';
import { Multiselect }   from '@components/form';
import { TipTap }        from '@components/form';
import {notifyError, notifySuccess} from "@components/Notification";
import debounce from 'lodash/debounce';



export default {
    props: {
        day: {
            type: Object,
        },
        tripName: {
            type: String
        },
        options: {
            type: Object
        },
        isConcept: {
            type: Boolean,
            default: false
        },
        shortVersion: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            showSearch: false,
            searchTerm: '',
            conceptSelected: null,
            filters: {
                statuses: [
                    {
                        key: 'text_checked',
                        value: 'Text kontrolliert'
                    }
                ],
            }
        }
    },

    computed: {
        checkStatus: {
            get: function () { return this.options.checkedStatusList.find(status => status.key === this.day.checkStatus)},
            set: function (status) {
                this.day.checkStatus = !!status ? status.key : null
                this.saveCatalogText({
                    checkStatus: this.day.checkStatus
                });
            }
        },

        heading: function () {
            if (!this.isConcept) {
                return `Tag ${this.day.info.dayNumber} - ${this.formattedDate} - ${this.day.name}`;
            } else {
                let index = this.options.orderOfDays.indexOf(this.day.id.toString()) + 1;
                return `Tag ${index} - ${this.day.name}`;
            }
        },
        formattedDate: function () {
            return this.isConcept ? '' : this.day.date.substring(0, 10)
        },
        catalogText : {
            get : function() {
                if (this.isConcept && this.shortVersion) {
                    return this.day.shortText;
                }
                    return this.day.catalogText;
            },
            set : function (value) {
                if (this.isConcept && this.shortVersion) {
                    this.day.shortText=value;
                }
                else {
                    this.day.catalogText=value;
                }
            }
        }
    },

    methods: {
        saveCatalogText: function (data) {
            if(!this.isConcept) {
                editDay({id: this.day.id, data: data}).then(() => notifySuccess('Der Kataloxtext wurde gespeichert!'), error => notifyError('Der Katalogtext konnte nicht gespeichert werden!'))
            }
            if(this.isConcept) {
                editDayConcept({id: this.day.id, data: data}).then(() => notifySuccess('Der Kataloxtext wurde gespeichert!'), error => notifyError('Der Katalogtext konnte nicht gespeichert werden!'))
            }
        },

        initDaySearch: function () {
            this.showSearch = true;
            this.searchTerm = this.day.name
        },

        replaceCatalogText: function () {
            if (this.conceptSelected) {
                this.saveCatalogText(
                    {
                            dayConcept: `/api/day_concepts/${this.conceptSelected.id}`,
                            catalogText: '',
                            catalogTextChanged: false,
                    }
                );
                this.catalogText = this.conceptSelected.catalogText
                this.day.catalogTextChanged = false;
                this.showSearch = false;
            }
        },

        storeText: debounce(function (data) {
            this.saveCatalogText(
                {
                    ...this.isConcept && this.shortVersion && {shortText: data},
                    ...(!this.isConcept || !this.shortVersion) && {catalogText: data},
                    catalogTextChanged: true
                });
        }, 1000),
    },

    components: {
        SearchDayConcept,
        Multiselect,
        TipTap
    }
}
</script>

