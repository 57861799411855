<template>
    <user-image v-bind:user="value" multiple-in-table  />
</template>

<script>
import UserImage from '@components/UserImage';
import Cell from './cell';

export default {
    mixins: [ Cell ],

    components: {
        UserImage
    }
}
</script>
