var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column is-one-third" }, [
        _c("div", { staticClass: "bp-box is-price" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("main", [
            _c("div", { staticClass: "is-standard calculation" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("input-price", {
                    attrs: {
                      "small-input": true,
                      "price-currency": _vm.request.requestCurrency,
                    },
                    model: {
                      value: _vm.standardPrice,
                      callback: function ($$v) {
                        _vm.standardPrice = $$v
                      },
                      expression: "standardPrice",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column is-one-third" },
        [
          _c("free-places", {
            attrs: { request: _vm.request },
            model: {
              value: _vm.request.freePlaces,
              callback: function ($$v) {
                _vm.$set(_vm.request, "freePlaces", $$v)
              },
              expression: "request.freePlaces",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c("item-prices", {
            attrs: { provider: _vm.provider },
            model: {
              value: _vm.request.itemPrices,
              callback: function ($$v) {
                _vm.$set(_vm.request, "itemPrices", $$v)
              },
              expression: "request.itemPrices",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons" }, [
          _vm._v("airplanemode_active"),
        ]),
        _vm._v(" "),
        _c("b", [_vm._v("Airline")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "material-icons" }, [
        _vm._v("airplanemode_active"),
      ]),
      _vm._v("\n                            Tickets\n                        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }