<template>
    <div class="level-left">
        <i class="material-icons level-item">search</i>
        <input
            type="text"
            class="input"
            placeholder="Konzept suchen"
            v-model="searchConcept"
        />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String
        }
    },

    computed: {
        searchConcept: {
            get: function () {
                return this.value
            },

            set: function (value) {
                this.$emit('input', value)
            }
        }
    }
}
</script>
