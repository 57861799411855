var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c(
      "td",
      { staticClass: "is-medium" },
      [
        _c(
          "multiselect",
          {
            attrs: { options: _vm.types, "track-by": "value", label: "label" },
            model: {
              value: _vm.type,
              callback: function ($$v) {
                _vm.type = $$v
              },
              expression: "type",
            },
          },
          [_c("span", { attrs: { slot: "clear" }, slot: "clear" })]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("td", { staticClass: "is-small" }, [
      _vm.form.type === "days"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.deadlineDate,
                expression: "form.deadlineDate",
              },
            ],
            staticClass: "input",
            attrs: { type: "text" },
            domProps: { value: _vm.form.deadlineDate },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "deadlineDate", $event.target.value)
              },
            },
          })
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("td", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.conditionText,
            expression: "form.conditionText",
          },
        ],
        staticClass: "input",
        attrs: { type: "text" },
        domProps: { value: _vm.form.conditionText },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "conditionText", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "is-narrow" }, [
      _c(
        "i",
        {
          staticClass: "material-icons",
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.$emit("delete")
            },
          },
        },
        [_vm._v("delete")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }