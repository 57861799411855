<template>
    <div>
        <div class="row is-headline is-small" v-on:click.prevent.stop="toggleCollapse" >
            <div class="level-left">
                <i
                    class="collapse-icon material-icons level-item"
                    v-bind:class="{'is-rotated' : !isOpen}"
                >keyboard_arrow_up</i>
                <span class="level-item">{{boardName}}</span>
            </div>
        </div>

        <template v-if="isOpen">
            <price-group-row
                v-for="priceGroup in groups"
                v-bind:key="priceGroup.id"
                v-bind:priceGroup="priceGroup"
                v-bind:options="options"
                v-bind:show-next-row="board.groups.length < 2"
                price-type="hotel"

                v-on:updatePriceGroup="updatePriceGroup"

                v-on:editPriceGroup="priceGroup => $emit('editPriceGroup', priceGroup)"
                v-on:deletePriceGroup="priceGroup => $emit('deletePriceGroup', priceGroup)"

            >
        </price-group-row>
        </template>

    </div>
</template>

<script>
import PriceGroupRow from './PriceGroup';
import Mixins from "./mixins";
import Template from "@orders/subpages/TripDetails/General/Template";
import { compareDates, fullYear } from '@utilities/functions';

export default {
    mixins: [Mixins],

    props: {
        board: {
            type: Object,
            required: true
        },

        options: {
            type: Object,
        }
    },

    computed: {
        groups: function () {
            return this.board.groups;
        },

        boardOptions: function () { return this.options && this.options.boardExtended ? this.options.boardExtended : [] },

        boardName: function () {
            const board = this.boardOptions.find(board => board.key === this.board.key)
            return !!board ? board.value : this.board.key;
        }
    },

    methods: {
        updatePriceGroup: function(group) {



            const board = this.board;
            const index = board.groups.findIndex(oldGroup => oldGroup.id === group.id)
            board.groups.splice(index, 1, {
                ...group,
            })

            this.$emit('updateBoard', board)
        }
    },

    components: {
        Template,
        PriceGroupRow
    }
}
</script>
