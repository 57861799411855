var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bp-panel",
      class: { "is-hover": _vm.isHover && !_vm.isForm, "is-form": _vm.isForm },
      on: {
        mouseenter: function ($event) {
          _vm.isHover = true
        },
        mouseleave: function ($event) {
          _vm.isHover = false
        },
        click: function ($event) {
          _vm.isHover = false
        },
      },
    },
    [
      _c("span", { staticClass: "panel-label" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "panel-content" },
        [
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.templates,
                  label: "title",
                  "track-by": "id",
                  placeholder: "Vorlage asuwählen",
                },
                model: {
                  value: _vm.form.info,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "info", $$v)
                  },
                  expression: "form.info",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.value.info
            ? [
                _c("hr"),
                _vm._v(" "),
                _c("h2", [_vm._v(_vm._s(_vm.value.title))]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "columns is-multiline" },
                  _vm._l(_vm.value.images, function (image) {
                    return _c(
                      "div",
                      { key: image.id, staticClass: "column is-one-quarter" },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: image.publicPath,
                              title: image.title,
                              target: "_blank",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: image.publicPath,
                                title: image.title,
                              },
                            }),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "nl2br",
                  domProps: { innerHTML: _vm._s(_vm.value.text) },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "panel-actions" }, [
        _vm.value.info
          ? _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("edit")
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons" }, [_vm._v("edit")]),
                _vm._v("\n            Hinweis bearbeiten\n        "),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$emit("delete")
              },
            },
          },
          [
            _c("i", { staticClass: "material-icons" }, [_vm._v("delete")]),
            _vm._v(" "),
            !_vm.isForm ? [_vm._v("Delete")] : [_vm._v("Discard")],
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }