var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.hasNoIcon === false
        ? _c(
            "i",
            { staticClass: "material-icons", attrs: { title: "Board" } },
            [_vm._v("local_dining")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasText ? [_vm._v("Verpflegung:")] : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "select" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.localBoard,
                expression: "localBoard",
              },
            ],
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.localBoard = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          _vm._l(_vm.boardList, function (board) {
            return _c("option", { domProps: { value: board.key } }, [
              _vm._v(_vm._s(board.value)),
            ])
          }),
          0
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }