<template>
    <row >
        <template slot="type">
            <i class="material-icons">local_hotel</i>
            <i class="material-icons" v-if="isDouble">local_hotel</i>
        </template>

        <template slot="name">
            <div class="level-left">
                <span class="level-item">
                    <template v-if="isSingle">Einzelzimmerzuschlag inkl. Einzelkabinenzuschlag bis</template>
                    <template v-else>Paketpreis im Doppelzimmer ab</template>
                    {{price.numberOfPersons}} Pax
                </span>
            </div>
        </template>

        <template slot="marginTax">
            <margin-tax v-model="marginTaxEnabled" :tax="marginTax" :margin="grossMargin" :margin-on="true" />
        </template>

        <template slot="buyPriceCalculation" v-if="hasTwoCurrencies">
            <input-price readOnly :value="buyPriceInCalculationCurrency"/>
        </template>

        <template slot="buyPrice">
            <input-price
                readOnly :value="buyPrice"
                :is-blue="hasTwoCurrencies"
            />
        </template>

        <template slot="sellPriceCalculation" v-if="hasTwoCurrencies">
            <input-price v-model="sellPriceInCalculationCurrency"/>
        </template>

        <template slot="sellPrice">
            <input-price
                v-model="sellPrice"
                :is-blue="hasTwoCurrencies"
            />
        </template>

        <template slot="profitCalculation" v-if="hasTwoCurrencies">
            <input-price v-model="profitInCalculationCurrency" />
        </template>

        <template slot="profit">
            <input-price
                v-model="profit"
                :is-blue="hasTwoCurrencies"
            />
        </template>

        <template slot="margin">
            <input-price v-model="margin" />
        </template>
    </row>
</template>

<script>
import { Row, InputPrice } from '@orders/subpages/Calculations/components';
import { profit, margin, applyProfit, applyMargin } from '@utilities/functions';
import { MarginTax } from '@calculations/components';


export default {
    props: {
        price: {
            type: Object,
            required: true
        },
        hasTwoCurrencies : {
            type: Boolean,
            required: false,
            default: false,
        },
        calculationToOrderExchangeRate: {
            required: false,
            default: 1
        }
    },

    components: {
        Row,
        InputPrice,
        MarginTax,
    },

    computed: {
        marginTaxEnabled: {
            get: function () {
                return this.price.marginTax
            },
            set: function (value) {
                this.toggleMarginTax(value)
            }
        },

        grossMargin: function () {
            return this.buyPrice ? (this.sellPrice - this.buyPrice) / this.buyPrice : 0;
        },

        multiplier: function () {
            return this.price.marginTax ? 1.19 : 1;
        },

        marginTax: function () {
            return this.price.marginTax ? Number(this.profit) * 0.19 : null;
        },

        isSingle: function () { return this.price.orderHotelRoom.type.persons === 1;},
        isDouble: function () { return !this.isSingle },

        buyPrice: {
            get: function () { return this.price.buyPrice.amount },
            set: function (value) { this.updatePrice('buyPrice', value) }
        },

        buyPriceInCalculationCurrency: {
            get: function () {
                return this.calculationToOrderExchangeRate ?
                    this.buyPrice / this.calculationToOrderExchangeRate :
                    this.buyPrice;
            },

            set: function (value) {
                this.buyPrice = value * this.calculationToOrderExchangeRate;
            }

        },

        sellPriceInCalculationCurrency:  {
            get: function () {
                return this.calculationToOrderExchangeRate ?
                    this.sellPrice / this.calculationToOrderExchangeRate :
                    this.sellPrice;
            },

            set: function (value) {
                this.sellPrice = value * this.calculationToOrderExchangeRate;
            }
        },

        profitInCalculationCurrency: {
            get: function () {
                return this.calculationToOrderExchangeRate ?
                    this.profit / this.calculationToOrderExchangeRate :
                    this.profit;
            },

            set: function (value) {
                this.profit = value * this.calculationToOrderExchangeRate;
            }
        },


        sellPrice: {
            get: function () { return this.price.sellPrice.amount },
            set: function (value) { this.updatePrice('sellPrice', value) }
        },

        profit: {
            get: function () {
                return this.multiplier ? profit(this.sellPrice, this.buyPrice) / this.multiplier : 0;
            },
            set: function (value) {
                this.sellPrice = Number(this.buyPrice) + (Number(value) * this.multiplier);
                // this.sellPrice = applyProfit(this.buyPrice, value)
            }
        },



        margin: {
            get: function () {
                return this.buyPrice ? Number(this.profit) / Number(this.buyPrice) * 100 : 0;
                // return margin(this.sellPrice, this.buyPrice)
            },
            set: function (value) {
                this.sellPrice = Number(this.buyPrice) * (1 + Number(value) / 100 * this.multiplier);
                // this.sellPrice = applyMargin(this.buyPrice, value)
            }
        },
},

    methods: {
        toggleMarginTax: function (newValue) {
            const sellPrice = newValue
                ? Number(this.sellPrice.amount) + this.marginTax
                : Number(this.sellPrice.amount) - this.marginTax;

            this.updatePrice({
                marginTax: newValue,
                sellPrice: {
                    amount: sellPrice,
                    currency: this.orderCurrency
                }
            });
            return !! newValue;
        },

        updatePrice: function (key, value) {
            this.$emit('update:price', {
                ...this.price,
                [key]: {...this.price[key], amount: value}
            })
        }
    }
}
</script>

