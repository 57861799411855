<template>
    <days v-bind:value="days"/>
</template>

<script>
import Days from '@components/Days';
import { daysToNumbers } from '@utilities/functions';
import Cell from './cell';

export default {
    mixins: [ Cell ],
    
    computed: {
        days() {
            return daysToNumbers(this.row);
        }
    },

    components: {
        Days
    }
}
</script>
