<template>
    <freeplaces-table 
        v-bind:hotel="isHotel" 
        v-bind:ferry="isFerry"
        v-bind:freePlaces="item.freePlaces || []"
    />
</template>

<script>

import Mixin from '../tab';
import FreeplacesTable from './Table';

export default {
    mixins: [ Mixin ],

    computed: {
        isHotel: function () { return this.$store.state.api === 'hotels'},
        isFerry: function () { return this.$store.state.api === 'ferries'}
    },

    components: { FreeplacesTable }
}
</script>
