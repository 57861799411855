import { store }   from '@root/pages/orders/store';
import { notifySuccess, notifyError } from '@components/Notification';
import { addRequest, editPlaceholder } from '@api';
import { url } from '@utilities/functions';
import { DateTime, Multiselect }   from '@components/form';
import Accordion      from '@components/Accordion';
import Confirmation   from '@components/Confirmation';
import AgencySelect   from '../components/AgencySelect';
import ItemPrices     from '../ItemPrices';
import ProviderInfo   from './ProviderInfo';
import RequestScore   from '../requests/RequestScore';

export default {
    store: store,


    props: {
        value:           { type: Object, required: true },
        placeholderID:   { type: Number, required: true },
        placeholderInfo: { type: Object, required: true },
    },


    data: function () {
        return {
            newRequest: JSON.parse(JSON.stringify(this.value)),

            // TODO - this is gone
            newRequestHasMainItem: true,
            onlyFromOrder: true,

            isMainRequest: false,
            isVisibleConfirmation: false,
            isLoading: false
        };
    },


    methods: {
        addRequest: function (newRequest, close) {
            addRequest({ apiEndpoint: this.apiEndpoint + '?_groups[]=modal_write&_groups[]=modal:providerPrices', data: newRequest })
                .then(response => {
                    if (this.isMainRequest) {
                        editPlaceholder({
                            type: this.apiEndpoint.replace('requests', 'placeholders'),
                            id: this.placeholderID,
                            data: {
                                selectedRequest: '/api/requests/' + response.data.id,
                                ...this.isMainRequest === 'has-description' && {
                                    serviceDescription: response.data.serviceDescription,
                                    routeDescription:   response.data.routeDescription
                                }
                            },
                            params: '?_groups[]=modal_read&_groups[]=modal:providerPrices'
                        }).then(() => {}, error => { notifyError('Konnte nicht als Leistung festgelegt werden'); });
                    }

                    this.$emit('saved', response.data.isCalculationRequest ? response.data : null);
                    this.$emit('input', response.data);
                    notifySuccess('Die Anfrage wurde hinzugefügt!');

                    if (close === 'close') {
                        // Save and Close
                        this.$emit('closeModal');

                    } else {
                        // Save and Email
                        this.$emit('close', true);

                        // Opening the request once created
                        this.$store.commit('updateModalToRequest', {
                            id: response.data.id,
                            type: this.apiEndpoint.replace('_requests', ''),
                            isNew: true,
                        });
                    }

                }, error => { notifyError('Die Anfrage konnte nicht hinzugefügt werden! Server Error!'); })
                .then(() => { this.isLoading = false; });
        },


        url
    },


    watch: {
        isMainRequest: function () {
            if (this.isMainRequest && !this.isVisibleConfirmation) {
                this.isVisibleConfirmation = true;
                this.$on('confirm', (confirm) => {
                    this.isMainRequest = confirm ? 'has-description' : true;
                    this.$nextTick(() => { this.isVisibleConfirmation = false; });
                });
            }
        }
    },


    components: {
        Accordion,
        AgencySelect,
        Confirmation,
        DateTime,
        ItemPrices,
        Multiselect,
        ProviderInfo,
        RequestScore
    }
};
