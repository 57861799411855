import moment from 'moment';
import {getAddresses, getContacts} from '@api';
import { Multiselect, ToggleSwitch, InputDate as Datepicker, DateTime, TipTap } from '@components/form';
import { store }    from '@root/pages/orders/store';
import ItemPrices   from '../ItemPrices';
import AgencySelect from '../components/AgencySelect';
import {dateISOtoView} from "@utilities/functions";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import {settings} from "@clientSpecific/utilities/settings";


export default {
    store: store,


    props: {
        value: { type: Object, required: true },
        editMode: { type: Boolean, default: false },
        calculationRequest: {},
    },


    computed: {

        showAssignedContactAddress: function() {
            return settings.order.modal.assignedContactAddress;
        },

        hasDifferentContact: {
            get: function() {
                return !this.request.assignedAddress || this.request.hasDifferentContact;
            },
            set: function(value) {
                this.request.hasDifferentContact = value;
            }

        },

        today: function () {
            let date = new Date();
            date.setHours(0, 0, 0, 0);
            return date;
        },

        isCalculationRequest: function () {
            return this.calculationRequest && this.calculationRequest.id === this.request.id
        },

        deadline: {
            get: function () {
                return this.request.optionTodo ? this.request.optionTodo.dueDate : null;
            },
            set: function (value) {
                if(!this.request.optionTodo) {
                    this.request.optionTodo = {}
                }
                this.request.optionTodo.status = value ? 'open' : 'done';
                this.request.optionTodo.dueDate = value;
                this.request.optionTodo.doneDate = !value ? dateISOtoView((new Date()).toISOString()) + '00:00:00' : null;
            }
        },


        requestStatuses: function () { return this.$store.state.options.requestStatuses; },
        providerContactOptions: function () {
            return get(this.$store.state.options, [this.request.id, 'providerContactOptions'], []);
        },
        agencyContactOptions: function () {
            return get(this.$store.state.options, [this.request.id, 'agencyContactOptions'], []);
        },
        providerAddressOptions: function () {
            return get(this.$store.state.options, [this.request.id, 'providerAddressOptions'], []);
        },
    },


    data: function () {
        const request = JSON.parse(JSON.stringify(this.value));
        // sets a default object for the assigned object if null
        request.assignedContact = request.assignedContact || null;
        request.assignedAddress = request.assignedAddress || null;
        request.requestContact = request.requestContact || null;
        request.visibleContactDetails = request.visibleContactDetails || [];
        request.todos = request.todos || [];
        return {
            onlyFromOrder: true,
            request,
        };
    },


    methods: {
        moment,

        todoLabel: function(todo) {
            return todo.name.split(':')[0];
        },

        fetchContactOptions: async function () {
            let params = {};

            if (this.request.agency) {
                params['agency.id'] = this.request.agency.id;
                const paramsQueryAgency = new URLSearchParams(params).toString()
                const { data: agencyContactOptions } = await getContacts(`?${paramsQueryAgency}`);
                this.$store.commit('updateRequestOptions', {
                    requestId: this.request.id,
                    key: 'agencyContactOptions',
                    newOptions:  agencyContactOptions
                });
            } if (this.request.provider) {
                params = {};
                params['provider.id'] = this.request.provider.id;
                const paramsQueryProvider = new URLSearchParams(params).toString()
                const { data: providerContactOptions } = await getContacts(`?${paramsQueryProvider}`);
                const { data: providerAddressOptions } = await getAddresses(`?${paramsQueryProvider}`);
                this.$store.commit('updateRequestOptions', {
                    requestId: this.request.id,
                    key: 'providerContactOptions',
                    newOptions:  providerContactOptions
                });
                this.$store.commit('updateRequestOptions', {
                    requestId: this.request.id,
                    key: 'providerAddressOptions',
                    newOptions:  providerAddressOptions
                });
            }

        },
        setVisibleContactDetails: function (key) {
            if(this.request.visibleContactDetails.includes(key)) {
                let index = this.request.visibleContactDetails.findIndex(item => item === key);
                this.request.visibleContactDetails.splice(index,1);
            } else {
                this.request.visibleContactDetails.push(key);
            }
        }
    },


    watch: {
        value: {
            handler: function (newValue, oldValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    this.request = JSON.parse(JSON.stringify(this.value));
                }
            },
            deep: true
        },


        request: {
            handler: function () {
                this.$emit('input', this.request);
            },
            deep: true
        }
    },


    components: {
        Datepicker,
        DateTime,
        ItemPrices,
        Multiselect,
        AgencySelect,
        ToggleSwitch,
        TipTap,
        moment
    }
};
