import moment from 'moment';
import filter from 'lodash/filter';

import Days from '@components/Days';
import { daysToNumbers, priceView, monthYear  } from '@utilities/functions';

import mixin from './mixin';
import Buttons from './Buttons';

export default {
    mixins: [ mixin ],

    data: function () {
        return {
            perPage: 10,
        }
    },

    computed: {
        preFilteredRows: function () {
            const filters = {
                // Hotels
                ...!!this.filters.hotelType     && { 'hotelType'    : {
                    category: this.filters.hotelType.category,
                    stars: this.filters.hotelType.stars
                }},
                ...!!this.filters.board         && { 'board'        : this.filters.board.key },
                ...!!this.filters.roomType      && { 'roomType'     :  {
                    persons: this.filters.roomType.persons,
                    type: this.filters.roomType.type
                }},

                // Others
                ...!!this.filters.otherServiceType && { 'otherServiceType' : { id: this.filters.otherServiceType.id }},

                // Hotels & others
                ...!!this.filters.destination   && { 'destination'  : { id: this.filters.destination.id }},
                ...!!this.filters.area          && { 'area'         : { id: this.filters.area.id }},

                // Feries
                ...!!this.filters.startPort     && { 'startPort'    : { id: this.filters.startPort.id }},
                ...!!this.filters.endPort       && { 'endPort'      : { id: this.filters.endPort.id }},
                ...!!this.filters.type          && { 'type'         : this.filters.type.key },
                ...!!this.filters.cabinType     && { 'cabinType'    : {
                        persons: this.filters.cabinType.persons,
                        type: this.filters.cabinType.type
                    }},

                // All
                ...!!this.filters.monday        && { 'monday'       : this.filters.monday },
                ...!!this.filters.tuesday       && { 'tuesday'      : this.filters.tuesday },
                ...!!this.filters.wednesday     && { 'wednesday'    : this.filters.wednesday },
                ...!!this.filters.thursday      && { 'thursday'     : this.filters.thursday },
                ...!!this.filters.friday        && { 'friday'       : this.filters.friday },
                ...!!this.filters.saturday      && { 'saturday'     : this.filters.saturday },
                ...!!this.filters.sunday        && { 'sunday'       : this.filters.sunday },
            };

            return filter(this.orderedRows, filters)
                .filter(row => {
                    if (!!this.filters.startAtMonth) {
                        return filterMonth(row.startAt) <= filterMonth(this.filters.startAtMonth)
                    }

                    return row;
                })
                .filter(row => {
                    if (!!this.filters.endAtMonth) {
                        return filterMonth(row.endAt) >= filterMonth(this.filters.endAtMonth)
                    }

                    return row;
                })
                .filter(row => {
                    if (!!this.filters.startAt) {
                        return filterDate(row.startAt) <= filterDate(this.filters.startAt)
                    }

                    return row;
                })
                .filter(row => {
                    if (!!this.filters.endAt) {
                        return filterDate(row.endAt) >= filterDate(this.filters.endAt)
                    }

                    return row;
                })
        },

        filteredRows: function () {
            return this.preFilteredRows;
        },

        preparedForBackend: function () {
            return {
                // Hotel
                ...!!this.form.hotelType        && { hotelType    : {
                    category: this.form.hotelType.category,
                    stars: this.form.hotelType.stars
                }},
                ...!!this.form.board            && { board          : this.form.board },
                ...!!this.form.roomType         && { roomType       : {
                    persons: this.form.roomType.persons,
                    type: this.form.roomType.type
                }},

                // Others
                ...!!this.form.otherServiceType && { otherServiceType : `/api/other_service_types/${this.form.otherServiceType.id}`},

                // Hotel & others
                area        : this.form.area ? `/api/areas/${this.form.area.id}` : null,
                destination : this.form.destination ? `/api/destinations/${this.form.destination.id}` : null,

                // Ferries
                ...!!this.form.startPort        && { startPort      : `/api/ports/${this.form.startPort.id}`},
                ...!!this.form.endPort          && { endPort        : `/api/ports/${this.form.endPort.id}`},
                ...!!this.form.type             && { type           : this.form.type },
                ...!!this.form.cabinType        && { cabinType      : {
                    persons: this.form.cabinType.persons,
                    type: this.form.cabinType.type
                }},



                // All
                monday      : this.form.monday,
                tuesday     : this.form.tuesday,
                wednesday   : this.form.wednesday,
                thursday    : this.form.thursday,
                friday      : this.form.friday,
                saturday    : this.form.saturday,
                sunday      : this.form.sunday,

                price       : this.form.price,

                ...!!this.form.startAt  && { startAt    : `${this.form.startAt} 00:00:00`},
                ...!!this.form.endAt    && { endAt      : `${lastMonthDay(this.form.endAt)} 00:00:00`},
            }
        },
    },

    methods: {
        daysToNumbers
    },

    filters: {
        priceView,
        monthYear
    },

    components: {
        Days,
        Buttons
    }
}

const filterMonth = function (date) {
    return Number(moment(date, 'DD.MM.YYYY').format('YYYYMM'));
}

const filterDate = function (date) {
    return Number(moment(date, 'DD.MM.YYYY').format('YYYYMMDD'));
}

const lastMonthDay = function (date) {
    return moment(date, 'DD.MM.YYYY').endOf('month').format('DD.MM.YYYY');
}
