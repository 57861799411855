<template>
    <stars :count="value" />
</template>

<script>
import Stars from '@components/Stars';
import Cell from './cell';

export default {
    mixins: [ Cell ],
    
    components: { Stars },

    props: {
        value: {
            type: [String, Number]
        }
    }
}
</script>