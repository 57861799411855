<template>
    <div v-if="groupedPrices.length > 0">
        <div class="row is-headline" v-if="groupName">{{groupName}}</div>
        <component
            v-for="(price, index) in sortedPrices"
            v-bind:key="`${price.placeholder.info.type}-${price.placeholder.id}`"
            v-bind:price="price"
            v-bind:is="`${price.placeholder.info.type}-price`"
            ref="price"
        >
            <component
                v-bind:is="`${price.placeholder.info.type}-subrows`"
                v-bind:price="price"
            />
            <item-prices-subrows v-bind:price="price" v-on:unlink="handleUnlink(index)" />
        </component>
    </div>
</template>

<script>
import moment from 'moment'
import { sortHelper } from '@calculations/functions'
import store from '@calculations/store'
import Prices from './Prices'

export default {
    store,

    props: {
        sortGroup: {
            type: Object,
            default: function () {
                return {
                    key: null,
                    value: null
                }
            }
        }
    },

    computed: {
        groupName: function () {
            const hasGroupedPrices = Object.values(this.$store.getters.groupedPrices)
                .filter(prices => prices.length > 0)
                .length > 0;

            return !!this.sortGroup.value ? this.sortGroup.value : (hasGroupedPrices ? 'keine Gruppenzuordnung' : false)
        },

        groupedPrices: function () {
            if (this.sortGroup.key === null) {
                return this.$store.getters.unGroupedPrices;
            }

            return !!this.$store.getters.groupedPrices[this.sortGroup.key] 
                ? this.$store.getters.groupedPrices[this.sortGroup.key] 
                : []
        },

        sortedPrices: function () {
            return sortHelper(this.groupedPrices, this.$store.state.sortHelper)
        }
    },

    methods: {
        handleUnlink: function (index) {
            if (!!this.$refs.price && this.$refs.price[index]) {
                this.$refs.price[index].handleUnlink()
            }
        }
    },

    components: {
        ...Prices
    }
}
</script>
