var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "columns",
    {
      attrs: { property: "clientDates", loadOptions: false },
      on: { add: _vm.openForm },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function ({ item }) {
            return _c(
              "column-item",
              {
                attrs: {
                  api: "/api/client_dates/" + item.id,
                  update: {
                    updatedBy: item.updatedBy,
                    updatedAt: item.updatedAt,
                  },
                  label: _vm.label(item),
                },
                on: {
                  edit: function ($event) {
                    return _vm.openForm(item)
                  },
                  delete: function ($event) {
                    return _vm.deleteItem("clientDates", item)
                  },
                },
              },
              [
                _c("ul", [
                  item.name
                    ? _c("li", [_c("strong", [_vm._v(_vm._s(item.name))])])
                    : _vm._e(),
                  _vm._v(" "),
                  item.description
                    ? _c("li", {
                        domProps: { innerHTML: _vm._s(item.description) },
                      })
                    : _vm._e(),
                ]),
              ]
            )
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("client-date-form", {
        ref: "form",
        attrs: { slot: "form" },
        slot: "form",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }