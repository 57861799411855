var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "has-margin-top" }, [
    _c(
      "div",
      {
        staticClass: "bp-panel has-no-title",
        staticStyle: { "min-height": "auto" },
      },
      [
        _c(
          "div",
          { staticClass: "panel-content" },
          _vm._l(_vm.objects, function (object, index) {
            return _c("div", [
              _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "control" }, [
                  _c(
                    "label",
                    {
                      staticClass: "checkbox",
                      staticStyle: {
                        "white-space": "nowrap",
                        overflow: "hidden",
                        width: "100%",
                      },
                    },
                    [
                      _vm.isHotel
                        ? _c("input", {
                            attrs: { type: "checkbox" },
                            domProps: { checked: _vm.getHotelChecked(object) },
                            on: {
                              input: function ($event) {
                                return _vm.setHotelChecked(object)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "is-link",
                          attrs: {
                            target: "_blank",
                            href: _vm.getLink(object),
                          },
                        },
                        [_vm._v(_vm._s(_vm.getLabel(object, index + 1)))]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "is-small-text",
                    class: {
                      "is-turqoise-text": !!object.readyForStaging,
                      "is-text-red": !object.readyForStaging,
                    },
                  },
                  [_vm._v(_vm._s(_vm.getSmallText(object)))]
                ),
              ]),
            ])
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }