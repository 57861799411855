<template>
    <div class="redesign-table">
        <div class="table-wrapper">
            <table-filters
                v-model="filters"
                v-bind:options="filterOptions"
            />

            <div class="row is-head">
                <div class="cell" v-if="hotel || ferry" v-orderBy="'type'">Freiplatz pro Person</div>
                <div class="cell" v-orderBy.date="'startAt'">Zeitraum</div>
                <div class="cell" v-orderBy="'agency.name'">Agentur</div>
                <div class="cell" v-orderBy.number="'numberOfPersons'">ab Pax</div>
                <div class="cell is-actions">
                    <button
                        class="btn-edit"
                    ><i class="material-icons is-pointer" v-on:click.prevent="openForm">add_circle</i></button>
                </div>
            </div>

            <loading v-if="loading" />
            <div class="is-zebra" v-else>
                <template v-for="item in paginatedRows">
                    <div class="row" v-bind:key="item.id" v-if="!isEditing(item)">
                        <div class="cell" v-if="hotel || ferry">{{type(item)}}</div>
                        <div class="cell">{{item.startAt | fullYear}} - {{item.endAt | fullYear}}</div>
                        <div class="cell">{{!!item.agency ? item.agency.name : 'direkt'}}</div>
                        <div class="cell">{{item.numberOfPersons}}</div>
                        <div class="cell is-actions">
                            <update-popover
                                v-bind:update="{updatedAt: item.updatedAt, updatedBy: item.updatedBy}"
                                :api="'/api/provider_free_places/' + item.id"
                            >
                                <button
                                class="btn-edit"
                            ><i class="material-icons">update</i></button>
                            </update-popover>
                            <button
                                class="btn-edit"
                                v-on:click="openForm(item)"
                            ><i class="material-icons">edit</i></button>
                            <button
                                class="btn-delete"
                                v-on:click="startDelete(item)"
                            ><i class="material-icons">delete</i></button>
                        </div>
                    </div>

                    <table-form
                        v-else
                        v-bind:key="`form-${item.id}`"
                        v-model="form"
                        v-bind:options="options"
                        v-on:submit="submitForm"
                        v-on:cancel="cancelForm"
                        v-bind:loading="isSubmitting(item)"
                    />
                </template>

                <table-form
                    v-if="!isEditMode && showForm"
                    v-model="form"
                    v-bind:options="options"
                    v-on:submit="submitForm"
                    v-on:cancel="cancelForm"
                    v-bind:loading="isSubmitting(form)"
                />
            </div>
        </div>

        <div class="redesign-table__footer" v-if="!loading">
            <a
                v-on:click.prevent="openForm"
                class="button is-primary"
            >Neuer Freiplatz</a>
            <pagination v-model="pagination" />
        </div>
    </div>
</template>

<script>
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';

import Table from '@components/CRUD/DataTable';
import TableFilters from './Filters';
import TableForm from './Form';

import { prepareForBackend, fullYear } from '@utilities/functions';
import {
    addFreePlace, editFreePlace, deleteFreePlace
} from '@api';
import UpdatePopover from "@components/UpdatePopover";
import omit from "lodash/omit";

const emptyPrice = {
    agency: null,
    type: 'double',
    numberOfPersons: 0
};

export default {
    mixins: [ Table ],
    components: {UpdatePopover, TableFilters, TableForm },

    props: {
        hotel: {
            type: Boolean,
            default: false,
        },

        ferry: {
            type: Boolean,
            default: false
        },

        freePlaces: {
            type: Array,
            required: true
        }
    },

    data: function () {
        return  {
            emptyForm: Object.assign({}, emptyPrice),
            filters: {
                type: '',
                agency: null,
                startAt: null,
                endAt: null
            },

            rows: this.freePlaces,

            options: {
                types: [
                    {
                        key: 'double',
                        label: 'halbes Doppel'
                    },
                    {
                        key: 'single',
                        label: 'Einzel'
                    }
                ]
            }
        }
    },

    computed: {
        filterOptions: function () {
            return {
                agencies: this.agencies,
                types: this.options.types
            }
        },

        agencies: function () {
            const agencies = this.rows
                .filter(row => row.agency)
                .map(row => row.agency);

            return uniqBy(agencies, 'id');
        },

        filteredRows: function () {
            return filter(this.preFilteredRows, {
                ...!!this.filters.agency  && { 'agency' : { id: this.filters.agency.id }},
            })
        },

        preparedForBackend: function () {
            return {
                ...prepareForBackend(omit(this.form, ['updatedAt', 'updatedBy'])),
                 provider: `/api${window.location.pathname}`,
            }
        }
    },

    methods: {
        deleteRow: deleteFreePlace,
        editRow: function ({id, data}) {
            return editFreePlace({
                id,
                data,
            })
        },
        addRow: function (data) {
            return addFreePlace(data)
        },

        type: function (item) {
            if (!!item.type ) {
                const type = this.options.types
                    .find(t => t.key == item.type);
                if (type) {
                    return type.label;
                }
            }
            return ''
        },

    },

    filters: {
        fullYear
    }
}
</script>
