import omit      from 'lodash/omit';
import debounce  from 'lodash/debounce';
import has       from 'lodash/has';
import axios     from 'axios';

import store     from '@orders/store';
import Emails    from '@components/emails/modals/newEmail/EmailForm';
import Loading   from '@components/Loading';
import Accordion from '@components/Accordion';
import Multiselect from '@components/form/Multiselect';
import { notifyError } from '@components/Notification';
import { deadlineUrgency, fullYear, getValueWithKey, prepareForBackend, currentUser, apiCallWithCancelFactory } from '@utilities/functions';
import { editPackage, getRequestUpdates } from '@api';

import RequestSettings from '@orders/placeholders/modal/components/RequestSettings';
import ProviderInfo from '../newRequests/ProviderInfo';
import RequestScore from './RequestScore';

export default {
    store: store,


    props: {
        value: { type: Object, required: true },
        placeholderInfo: { type: Object, required: true },
        calculationRequest: {},
    },


    computed: {
        modalToRequest: function () {
            return this.$store.state.modal.toRequest;
        },


        packageID: function () {
            if (this.request.package) {
                if (this.request.package.id) {
                    return this.request.package.id;

                } else {
                    return Number(this.request.package.replace('/api/packages/', ''));
                }

            } else {
                return false;
            }
        },


        isLoading:       function () { return this.$store.state.isLoading.modalRequest; },
        order:           function () { return this.$store.state.order; },
        orderID:         function () { return this.$store.state.orderID; },
        requestStatuses: function () { return this.$store.state.options.requestStatuses; },


        todoData: function () {
            const user = currentUser();

            return {
                user: user ? `/api/users/${user.id}` : null,
                status: 'open',
            };
        }
    },


    data: function () {
        return {
            request: JSON.parse(JSON.stringify(Object.assign({}, this.value, { mostUrgentTodo: this.value.mostUrgentTodo ? this.value.mostUrgentTodo : {} }))),
            sectionSelection: 'overview',
            oldRequest: JSON.parse(JSON.stringify(Object.assign({}, this.value, { mostUrgentTodo: this.value.mostUrgentTodo ? this.value.mostUrgentTodo : {} }))),
            editMode: false,
        };
    },


    methods: {
        saveRequestChanges: debounce(function (newTab = null) {
            this.$store.commit('updateRequestSaving', true);
            this.$emit('saved');

            if (this.sectionSelection === 'price' && this.request.package) {
                this.editPackage(newTab);
            } else {
                this.editRequest(newTab);
            }
        }, 900),


        saveCalculationRequest: function (calculationRequest) {
            this.$store.commit('updateRequestSaving', true);
            if (this.request.placeholder) {
                axios.put(this.request.placeholder, {
                    calculationRequest: calculationRequest ? `/api/${this.apiEndpoint}/${calculationRequest.id}` : null
                }, {
                    params: { _groups: ['modal_read', 'modal:providerPrices'] }
                }).then(response => {
                    this.$store.commit('updateRequestSaving', 'ok');
                    setTimeout(() => { this.$store.commit('updateRequestSaving', false); }, 2000);
                });
            }
        },

        setCalculationRequest: function (request) {
            this.$emit('setCalculationRequest', request);
            this.saveCalculationRequest(request);
        },

        reloadPrices: function (params) {
            this.$store.commit('updateIsLoadingModalRequest', true);
            getRequestUpdates({ id: this.request.id, data: {}, params: params + '&_groups[]=modal_write&_groups[]=modal:providerPrices&type=' + this.apiEndpoint }).then(response => {
                this.request    = response.data;
            }, error => {
                notifyError('Daten konnten nicht geladen werden!');
            }).then(() =>
                this.$store.commit('updateIsLoadingModalRequest', false)
            );
        },


        changeTab: function (newTab) {
            if (this.isLoading) {
                notifyError('Please Wait untill all data has been Loaded!');

            } else {
                this.sectionSelection = newTab;
            }
        },


        getRequestBuild: function (request) { /* polymorphism */ },


        editRequest: function (newTab = null) {
            let copyRequest = JSON.parse(JSON.stringify(this.request));
            copyRequest = omit(copyRequest, ['todos']);

            if (copyRequest.assignedContact && copyRequest.assignedContact.id) {
                copyRequest.assignedContact = '/api/contacts/' + copyRequest.assignedContact.id;
            } else {
                copyRequest.assignedContact = null;
            }

            if (copyRequest.package) { // store itemPrices
                editPackage({
                    id:   copyRequest.package.id,
                    data: {
                        itemPrices: copyRequest.package.itemPrices
                    },
                    params: '?_groups[]=modal_read&_groups[]=order_request_read'
                });
            }

            copyRequest.package = copyRequest.package && copyRequest.package.id ?
                '/api/packages/' + copyRequest.package.id : copyRequest.package;

            // Actions specific to each type of request
            copyRequest = this.getRequestBuild(copyRequest);


            this.storeRequest(copyRequest, newTab);

            /*this.storeTodo(mostUrgentTodo)
                .then(response => {
                    if (response) {
                        const { todo, isNew } = response;
                        if (isNew) {
                            this.$store.commit('addTodo', todo);

                        } else {
                            this.$store.commit('updateTodo', todo);
                        }

                        this.oldRequest = Object.assign({}, this.request, {
                            mostUrgentTodo: todo.status !== 'done' ? todo : {}
                        });
                        this.request = Object.assign({}, this.request, {
                            mostUrgentTodo: todo.status !== 'done' ? todo : {}
                        });
                    }
                });*/

        },


        storeRequest: function (copyRequest, newTab) {
            this.apiCallWithCancel({
                method: 'put',
                url:    '/api/' + this.apiEndpoint + '/' + copyRequest.id + '?_groups[]=modal_write&_groups[]=modal:providerPrices',
                data: {
                    ...prepareForBackend(copyRequest),
                    ...(this.request.requestStatus.status !== copyRequest.requestStatus.status && copyRequest.requestStatus.status === 'canceled' && {
                        optionTodo: {
                            ...copyRequest.optionTodo,
                            dueDate: null,
                        }
                    }
                    )
                }
            })
                .then(response => {
                    if (response.data.optionTodo) {
                        response.data.optionTodo.justSaved = true;
                        this.$store.commit('updateTodo', response.data.optionTodo);
                    }



                    // Request
                    //this.oldRequest = Object.assign({}, this.request, response.data);
                    //this.request    = Object.assign({}, this.request, response.data);

                    if (newTab) {
                        this.sectionSelection = newTab;
                    }
                    this.$store.commit('updateRequestSaving', 'ok');
                    setTimeout(() => { this.$store.commit('updateRequestSaving', false); }, 100);

                }, error => {
                    this.$store.commit('updateRequestSaving', false);
                });
        },


        /*storeTodo: function (todo) {
            const isNew = todo === undefined || todo === null || !has(todo, 'id');
            const todoData = this.todoData;
            const requestDeadline = this.request.requestDeadline.deadline;
            const isSame = this.todoData && this.todoData.dueDate && this.todoData.dueDate === fullYear(todo.dueDate);

            if ((isNew && !requestDeadline) || isSame) {
                return new Promise((resolve) => resolve());
            }

            return new Promise((resolve, reject) => {
                (isNew ?
                    addTodo(todoData, '?_groups[]=order_list_read') :
                    editTodo({
                        id: todo.id,
                        data: Object.assign({},
                            todoData, {
                                status:  requestDeadline === null ? 'done' : todoData.status,
                                dueDate: requestDeadline === null ? fullYear(todo.dueDate) : todoData.dueDate
                            }
                        )
                    })
                ).then(response => {
                    resolve({ todo: response.data, isNew });

                }, error => { reject(error); });
            });
        },*/


        editPackage: function (newTab = null) {

            let copyPackage = JSON.parse(JSON.stringify(this.request.package));
            if (copyPackage.requests) {
                copyPackage.requests = copyPackage.requests.map((request) => (request.id ? '/api/requests/' + request.id : request));
            }


            if (copyPackage.id !== -1) {
                editPackage({
                    id:   copyPackage.id,
                    data: copyPackage,
                    params: '?_groups[]=modal_read&_groups[]=order_request_read'
                })
                    .then(response => {
                        /*this.oldRequest.package = response.data;
                        this.request.package    = response.data;*/

                        if (newTab) {
                            this.sectionSelection = newTab;
                        }
                        this.$store.commit('updateRequestSaving', 'ok');
                        setTimeout(() => { this.$store.commit('updateRequestSaving', false); }, 2000);

                    }, error => {
                        notifyError('Die Anfrage konnte nicht aktualisiert werden! Server Error!');
                        this.$store.commit('updateRequestSaving', false);
                    });
            }
        },


        apiCallWithCancel: apiCallWithCancelFactory(),


        deadlineUrgency,
        getValueWithKey
    },


    created: function () {
        if (this.request.placeholder.indexOf(this.modalToRequest.type) !== -1 &&
                this.modalToRequest.id === this.request.id &&
                this.modalToRequest.isNew) {
            this.sectionSelection = 'email';
        }
    },


    watch: {
        value: {
            handler: function (newValue, oldValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    this.request = JSON.parse(JSON.stringify(Object.assign({}, this.value, { mostUrgentTodo: this.value.mostUrgentTodo ? this.value.mostUrgentTodo : {} })));
                }
            },
            deep: true
        },


        request: {
            handler: function () {
                let value = null;
                if (this.request.requestType === 'hotel') {
                    value = _.pick(this.request, 'id', 'optionTodo', 'hotel', 'agency', 'requestStatus', 'optionNr', 'contingent', 'notes', 'package', 'itemPrices', 'freePlaces', 'requestCurrency', 'assignedContact', 'assignedAddress', 'requestContact', 'visibleContactDetails');
                } else if (this.request.requestType === 'ferry') {
                    value = _.pick(this.request, 'id', 'optionTodo', 'ferry', 'agency', 'requestStatus', 'shipName', 'optionNr', 'contingent', 'notes', 'package', 'itemPrices', 'freePlaces', 'requestCurrency', 'assignedContact', 'assignedAddress', 'requestContact', 'visibleContactDetails');
                } else if (this.request.requestType === 'other') {
                    value = _.pick(this.request, 'id', 'optionTodo', 'otherService', 'agency', 'requestStatus', 'assignedContact', 'optionNr', 'contingent', 'notes', 'package', 'itemPrices', 'freePlaces', 'requestCurrency', 'assignedContact', 'assignedAddress', 'requestContact', 'visibleContactDetails', 'hasDifferentContact');
                } else if (this.request.requestType === 'airline') {
                    value = _.pick(this.request, 'id', 'optionTodo', 'airline', 'agency', 'requestStatus', 'optionNr', 'contingent', 'notes', 'package', 'itemPrices', 'freePlaces', 'requestCurrency', 'flightNumber', 'foodOnBoard', 'ticketNr', 'assignedContact', 'assignedAddress', 'requestContact', 'visibleContactDetails');
                }  else if (this.request.requestType === 'train') {
                    value = _.pick(this.request, 'id', 'optionTodo', 'train', 'agency', 'requestStatus', 'optionNr', 'contingent', 'notes', 'package', 'itemPrices', 'freePlaces', 'requestCurrency');
                }

                if (JSON.stringify(this.oldRequest) !== JSON.stringify(value)) {
                    this.$emit('input', this.request);
                    this.saveRequestChanges();
                    this.oldRequest = JSON.parse(JSON.stringify(value));
                }

            },
            deep: true
        }
    },


    filters: {
        fullYear
    },


    components: {
        Accordion,
        Emails,
        Loading,
        ProviderInfo,
        RequestScore,
        Multiselect,
        RequestSettings
    }
};
