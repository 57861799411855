<template>
    <div class="row is-darken" v-if="groupedPrices.length > 0">
        <div class="cell"><strong>- davon {{sortGroup.value}}</strong></div>
        <div class="cell is-price-cell is-small">
           <span class="is-currency">{{ orderCurrency }}</span>
        </div>
        <div class="cell is-price-cell">
            <input-price readOnly :value="summary.group" />
        </div>
        <div class="cell is-price-cell">
            <input-price readOnly :value="summary.person" />
        </div>
        <div class="cell is-price-cell is-margin-cell">
            <input-price readOnly :value="summary.single" />
        </div>
        <div class="cell is-actions is-small-cell"></div>
    </div>
</template>

<script>
import { InputPrice } from '@calculations/components';
import Group from './Group';

export default {
    mixins: [ Group ],

    components: {
        InputPrice
    },

    computed: {
        groupedPrices: function () {
            return !!this.$store.getters.groupedPrices[this.sortGroup.key]
                ? this.$store.getters.groupedPrices[this.sortGroup.key]
                : []
        },

        orderCurrency: function () {
            return this.$store.state.order.orderCurrency;
        },

        summary: function () {
            return {
                group: this.groupedPrices.reduce((sum, {_summary}) => {
                    return sum + Number(_summary.group)
                }, 0),
                person: this.groupedPrices.reduce((sum, {_summary}) => {
                    return sum + Number(_summary.person)
                }, 0),
                single: this.groupedPrices.reduce((sum, {_summary}) => {
                    return sum + Number(_summary.single)
                }, 0),
            }
        },
    }
}
</script>
