var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "columns",
    {
      attrs: { property: "addresses" },
      on: { add: _vm.openForm },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function ({ item }) {
            return _c(
              "column-item",
              {
                attrs: {
                  update: {
                    updatedBy: item.updatedBy,
                    updatedAt: item.updatedAt,
                  },
                  api: "/api/addresses/" + item.id,
                  label: _vm.label(item),
                },
                on: {
                  edit: function ($event) {
                    return _vm.openForm(item)
                  },
                  delete: function ($event) {
                    return _vm.deleteItem("addresses", item)
                  },
                },
              },
              [
                _c("ul", [
                  item.title
                    ? _c("li", [_c("strong", [_vm._v(_vm._s(item.title))])])
                    : _vm._e(),
                  _vm._v(" "),
                  item.streetAddress
                    ? _c("li", [_vm._v(_vm._s(item.streetAddress))])
                    : _vm._e(),
                  _vm._v(" "),
                  item.zip
                    ? _c("li", [
                        _vm._v(_vm._s(item.zip) + " " + _vm._s(item.city)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  item.country
                    ? _c("li", [_vm._v(_vm._s(_vm.country(item)))])
                    : _vm._e(),
                  _vm._v(" "),
                  item.port
                    ? _c("li", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `/ports/${item.port.id}`,
                              target: "_blank",
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("directions_boat"),
                            ]),
                            _vm._v(
                              "\n                " +
                                _vm._s(item.port.place.name) +
                                "\n                "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  item.airport
                    ? _c("li", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `/airport/${item.airport.id}`,
                              target: "_blank",
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("airplanemode_active"),
                            ]),
                            _vm._v(
                              "\n                    " +
                                _vm._s(item.airport.name) +
                                "\n                "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      slot: "actions",
                      href: _vm.googleMapsLink(item),
                      target: "_blank",
                    },
                    slot: "actions",
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("location_on"),
                    ]),
                    _vm._v("Maps\n        "),
                  ]
                ),
              ]
            )
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("address-form", {
        ref: "form",
        attrs: { slot: "form" },
        slot: "form",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }