var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.concept
    ? _c(
        "div",
        _vm._l(_vm.sortedDays, function (day, index) {
          return _c(
            "div",
            {
              key: day.id,
              on: {
                click: function ($event) {
                  return _vm.$emit("select", day)
                },
              },
            },
            [
              day.category
                ? _c("span", { staticClass: "tag" }, [
                    _c("span", { staticClass: "is-truncated" }, [
                      _vm._v(_vm._s(_vm.dayCategoryName(day))),
                    ]),
                  ])
                : _c("span", { staticClass: "tag is-placeholder" }),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: { content: day.name, delay: { show: 1000 } },
                      expression: "{content: day.name, delay: {show: 1000}}",
                    },
                  ],
                  staticClass: "is-truncated",
                },
                [_vm._v(_vm._s(`Tag ${index + 1}`) + " - " + _vm._s(day.name))]
              ),
            ]
          )
        }),
        0
      )
    : _c("div", [_c("div", [_vm._v("Wähle ein Gesamtkonzept aus")])])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }