<template>
    <a v-bind:href="googleMapsLink" target="_blank">
        <template v-if="value.title"><strong>{{ value.title }}</strong>,</template>
        <template v-if="value.streetAddress">{{ value.streetAddress }},</template>
        <template v-if="value.zip">{{ value.zip }} {{ value.city }},</template>
        <template v-if="value.country">{{ country }}</template>
    </a>
</template>

<script>
import { countries } from '@utilities/variables';
import { googleMapsLink } from '@utilities/functions';
import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        country: function () {
            const label = this.value.country;
            const country = countries && countries.find(c => c.label === label);
            return country && country.name;
        },

        googleMapsLink: function() {
            return googleMapsLink(this.value)
        }
    }
}
</script>

