<template>
    <div>
        <div class="redesign-table">
            <div class="table-wrapper">
                <div class="row is-head">
                    <div class="cell">
                        <i class="material-icons">assignment</i>
                            Zuschlag
                    </div>
                    <div class="cell">
                        Preisart
                    </div>
                    <div class="cell">
                        <i class="material-icons">date_range</i>
                        von - bis
                    </div>
                    <div class="cell">
                        <i class="material-icons">people</i>
                        Gültigkeit
                    </div>
                    <div class="cell">
                        <i class="fa fa-tag fa-lg"></i>
                        Preis
                    </div>
                    <div class="cell is-actions">
                        <button
                            class="btn-edit"
                        ><i class="material-icons is-pointer" v-on:click.prevent="openForm">add_circle</i></button>
                    </div>
                </div>

                <template v-if="hasExtras">
                    <div
                        class="row"
                        v-for="extra in extras"
                        v-bind:key="extra.id"
                    >
                        <div class="cell">
                            {{extra.name}}
                        </div>
                        <div class="cell">
                            <div class="level-left">
                                <i class="material-icons level-item">{{quantityIcon(extra)}}</i>
                                <span>{{quantityType(extra)}}</span>
                            </div>
                        </div>
                        <div class="cell">
                            <template v-if="extra.startAt">
                                {{extra.startAt | fullYear}}
                            </template>
                            <template v-if="extra.startAt && extra.endAt">-</template>
                            <template v-if="extra.endAt">
                                {{extra.endAt | fullYear}}
                            </template>
                        </div>
                        <div class="cell">
                            <template v-if="extra.minPersons !== null">{{extra.limitType === 'validIfLimit' ? 'ab' : 'für'}} {{extra.minPersons || 0}}</template>
                            <template v-if="extra.maxPersons !== null">bis {{extra.maxPersons || 0}}</template>
                            <template v-if="extra.minPersons || extra.maxPersons">
                                <template v-if="extra.quantityType === 'per_single_room'">EZ</template>
                                <template v-else>Pax</template>
                            </template>
                        </div>
                        <div class="cell">
                            {{extra.price.formatted}}
                        </div>
                        <div class="cell is-actions">
                            <update-popover
                                v-bind:update="{updatedAt: extra.updatedAt, updatedBy: extra.updatedBy}"
                                :api="'/api/provider_item_prices/' + extra.id"
                            >
                                <button
                                    class="btn-edit"
                                ><i class="material-icons">update</i></button>
                            </update-popover>
                            <button
                                class="btn-edit"
                                v-on:click="openForm(extra)"
                            ><i class="material-icons">edit</i></button>
                            <button
                                class="btn-delete"
                                v-on:click="deleteItem('itemPrices', extra)"
                            ><i class="material-icons">delete</i></button>
                        </div>
                    </div>
                </template>

                <div class="row" v-else>
                    <div class="cell has-text-centered text-small">
                        Keine Zuschläge vorhanden - klicke auf <i class="is-pointer material-icons is-vertical-middle" v-on:click="openForm">add_circle</i> um einen hinzuzufügen
                    </div>
                </div>
            </div>
        </div>

        <extra-form ref="form" />
    </div>
</template>

<script>
import Mixin from '../tab';
import Days from '@components/Days';
import ExtraForm from './Form';
import { priceView, fullYear, getValueWithKey } from '@utilities/functions';
import UpdatePopover from "@components/UpdatePopover";


export default {
    mixins: [Mixin],

    computed: {
        extras: function () {
            return this.item.itemPrices
                .filter(price => price.type !== 'entrance')
        },

        hasExtras: function () {
            return this.item.itemPrices && this.extras.length > 0
        },
    },

    methods: {
        quantityIcon: function (extra) {
            return ['per_person', 'per_single_room'].includes(extra.quantityType) ? 'person' : 'people'
        },

        quantityType: function (extra) {
            return getValueWithKey({key: extra.quantityType, optionList: this.options.quantityType})
        },

        openForm(data = {}) {
            this.$refs.form.open(data);
        },

        billingType: function (item) {
            return getValueWithKey({key: item.billingType, optionList: this.options.billingType})
        }
    },

    components: {
        UpdatePopover,
        Days,
        ExtraForm,
    },

    filters: {
        priceView,
        fullYear
    }
}
</script>
