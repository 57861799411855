var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Hotelpreis bearbeiten",
        createTitle: "Neuen Hotelpreis hinzufügen",
      },
    },
    [
      _c(
        "table",
        [
          _vm.initialValue
            ? [
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label" }, [_vm._v("Preis:")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "toggle-switch",
                        {
                          model: {
                            value: _vm.priceType,
                            callback: function ($$v) {
                              _vm.priceType = $$v
                            },
                            expression: "priceType",
                          },
                        },
                        [
                          _c("option", { attrs: { value: "calculation" } }, [
                            _vm._v("Kalkulation"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "provider" } }, [
                            _vm._v("Anbieter"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "agency" } }, [
                            _vm._v("Agentur"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.priceType === "agency"
                  ? _c("tr", [
                      _c("th", [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Agentur:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("async-select", {
                            attrs: { api: "agencies" },
                            model: {
                              value: _vm.agency,
                              callback: function ($$v) {
                                _vm.agency = $$v
                              },
                              expression: "agency",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.priceType === "provider" || _vm.priceType === "agency"
                  ? _c("tr", [
                      _c("th", [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Hotel:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("async-select", {
                            attrs: { api: "hotels" },
                            model: {
                              value: _vm.provider,
                              callback: function ($$v) {
                                _vm.provider = $$v
                              },
                              expression: "provider",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [_vm._v("Bezeichnung:")]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "toggle-switch",
                  {
                    model: {
                      value: _vm.ownName,
                      callback: function ($$v) {
                        _vm.ownName = $$v
                      },
                      expression: "ownName",
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v("Individuell"),
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v("Automatisch (nach Datum)"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.ownName
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Eigene Bezeichnung:"),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "control" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.name,
                          expression: "form.name",
                        },
                      ],
                      staticClass: "input",
                      attrs: { type: "text", id: "name" },
                      domProps: { value: _vm.form.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "name", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("th", { staticClass: "is-top" }, [
              _c("label", { staticClass: "label" }, [
                _vm._v("\n                    Zeitraum:\n                    "),
                _vm.canAddPeriod
                  ? _c(
                      "i",
                      {
                        staticClass: "material-icons is-pointer",
                        on: { click: _vm.addPeriod },
                      },
                      [_vm._v("add_circle")]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              _vm._l(_vm.timePeriods, function (period) {
                return _c(
                  "div",
                  { key: period.key, staticClass: "level-left" },
                  [
                    _c(
                      "div",
                      { staticClass: "flatpickr-combined level-item" },
                      [
                        _c("input-date", {
                          model: {
                            value: period.startAt,
                            callback: function ($$v) {
                              _vm.$set(period, "startAt", $$v)
                            },
                            expression: "period.startAt",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("-")]),
                        _vm._v(" "),
                        _c("input-date", {
                          model: {
                            value: period.endAt,
                            callback: function ($$v) {
                              _vm.$set(period, "endAt", $$v)
                            },
                            expression: "period.endAt",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "level-item" },
                      [
                        _c("days", {
                          attrs: { picker: "" },
                          model: {
                            value: period.days,
                            callback: function ($$v) {
                              _vm.$set(period, "days", $$v)
                            },
                            expression: "period.days",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.timePeriods.length > 1
                      ? _c(
                          "i",
                          {
                            staticClass: "material-icons is-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.deletePeriod(period)
                              },
                            },
                          },
                          [_vm._v("delete")]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "board" } }, [
                _vm._v("Verpflegung:"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("multiselect", {
                  attrs: {
                    options: _vm.boardsExtended,
                    label: "value",
                    "track-by": "key",
                  },
                  model: {
                    value: _vm.board,
                    callback: function ($$v) {
                      _vm.board = $$v
                    },
                    expression: "board",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.providerType === "provider"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Art:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      [
                        _c("option", { attrs: { value: "direct" } }, [
                          _vm._v("Direktpreis"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "agency" } }, [
                          _vm._v("Preis von Agentur"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.providerType === "agency"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Art:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      [
                        _c("option", { attrs: { value: "direct" } }, [
                          _vm._v("No-Name Preis"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "hotel" } }, [
                          _vm._v("Preis für ein bestimmtes Hotel"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "agency"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Agentur:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("async-select", {
                      attrs: { api: "agencies" },
                      model: {
                        value: _vm.agency,
                        callback: function ($$v) {
                          _vm.agency = $$v
                        },
                        expression: "agency",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "hotel"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Hotel:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("async-select", {
                      attrs: { api: "hotels" },
                      model: {
                        value: _vm.provider,
                        callback: function ($$v) {
                          _vm.provider = $$v
                        },
                        expression: "provider",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          (_vm.type === "direct" && _vm.providerType === "agency") ||
          _vm.providerType === "calculation"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Hotelkategorie:"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.options.hotelTypes
                      ? _c("multiselect", {
                          attrs: {
                            "track-by": "name",
                            label: "name",
                            options: _vm.options.hotelTypes,
                          },
                          model: {
                            value: _vm.hotelType,
                            callback: function ($$v) {
                              _vm.hotelType = $$v
                            },
                            expression: "hotelType",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          (_vm.type === "direct" && _vm.providerType === "agency") ||
          _vm.providerType === "calculation"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Region:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("area-select", {
                      model: {
                        value: _vm.form.area,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "area", $$v)
                        },
                        expression: "form.area",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [
              _c("h2", [
                _vm._v("Preise "),
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    on: { click: _vm.addPrice },
                  },
                  [_vm._v("add_circle")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [_vm._v("Überprüft:")]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("import-checked-field", {
                  model: {
                    value: _vm.importChecked,
                    callback: function ($$v) {
                      _vm.importChecked = $$v
                    },
                    expression: "importChecked",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [_vm._v("Währung:")]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("multiselect", {
                  staticStyle: { width: "70px" },
                  attrs: {
                    options: _vm.currencies,
                    "allow-empty": false,
                    placeholder: "",
                  },
                  model: {
                    value: _vm.currency,
                    callback: function ($$v) {
                      _vm.currency = $$v
                    },
                    expression: "currency",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [
                _vm._v(
                  _vm._s(_vm.priceRoomType(_vm.standardRoomTypeDouble).name) +
                    ":"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("input-price", {
                  staticClass: "is-medium",
                  attrs: { placeholder: "0,00", prefix: _vm.currency },
                  model: {
                    value: _vm.doubleStandardPrice,
                    callback: function ($$v) {
                      _vm.doubleStandardPrice = $$v
                    },
                    expression: "doubleStandardPrice",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [
                _vm._v(
                  _vm._s(_vm.priceRoomType(_vm.standardRoomTypeSingle).name) +
                    ":"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("input-price", {
                  staticClass: "is-medium",
                  attrs: { placeholder: "0,00", prefix: _vm.currency },
                  model: {
                    value: _vm.singleStandardPrice,
                    callback: function ($$v) {
                      _vm.singleStandardPrice = $$v
                    },
                    expression: "singleStandardPrice",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.otherPrices, function (otherPrice) {
            return _c("tr", { key: otherPrice.key }, [
              _c(
                "th",
                [
                  _c("multiselect", {
                    attrs: {
                      options: _vm.priceRoomTypes(otherPrice),
                      value: _vm.priceRoomType(otherPrice),
                      "track-by": "name",
                      label: "name",
                      "allow-empty": false,
                    },
                    on: {
                      input: (roomType) =>
                        _vm.changeRoomType({ roomType, price: otherPrice }),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("td", [
                _c("div", { staticClass: "level-left" }, [
                  _c(
                    "div",
                    { staticClass: "level-item" },
                    [
                      _c("input-price", {
                        staticClass: "is-medium",
                        attrs: {
                          value: otherPrice.price.amount,
                          placeholder: "0,00",
                          prefix: _vm.currency,
                        },
                        on: {
                          input: (price) =>
                            _vm.updatePrice({
                              roomType: otherPrice.roomType,
                              price,
                            }),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "i",
                    {
                      staticClass: "material-icons",
                      on: {
                        click: function ($event) {
                          return _vm.deletePrice(otherPrice)
                        },
                      },
                    },
                    [_vm._v("delete")]
                  ),
                ]),
              ]),
            ])
          }),
          _vm._v(" "),
          _vm.addNewPrice
            ? _c("tr", [
                _c(
                  "th",
                  [
                    _c("multiselect", {
                      attrs: {
                        options: _vm.roomTypes,
                        "track-by": "name",
                        label: "name",
                      },
                      model: {
                        value: _vm.roomType,
                        callback: function ($$v) {
                          _vm.roomType = $$v
                        },
                        expression: "roomType",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "level-left" }, [
                    _c(
                      "div",
                      { staticClass: "level-item" },
                      [
                        _c("input-price", {
                          staticClass: "is-medium",
                          attrs: { placeholder: "0,00", prefix: _vm.currency },
                          model: {
                            value: _vm.newPrice.price.amount,
                            callback: function ($$v) {
                              _vm.$set(_vm.newPrice.price, "amount", $$v)
                            },
                            expression: "newPrice.price.amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        on: {
                          click: function ($event) {
                            _vm.addNewPrice = false
                          },
                        },
                      },
                      [_vm._v("delete")]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.existingPrices.length > 0
            ? _c("tr", [
                _c("td", { staticClass: "alert is-danger" }),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "alert is-danger" }, [
                    _c("p", [_c("strong", [_vm._v("Preise überschreiben?")])]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                        Im angegebenen Preise existieren bereits folgende Preise, die beim erneuten Klick auf Speichern überschrieben werden:\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      _vm._l(_vm.existingPrices, function (price) {
                        return _c("li", [
                          _vm._v(
                            _vm._s(
                              price.timePeriods
                                .map(
                                  (item) => item.startAt + " - " + item.endAt
                                )
                                .join(" | ")
                            ) +
                              " - EK im DZ: " +
                              _vm._s(
                                price.providerPrices
                                  .filter(
                                    (item) =>
                                      item.roomType.roomIndex === "2_standard"
                                  )
                                  .map((item) => item.price.formatted)
                                  .join(" | ")
                              )
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }