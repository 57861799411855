var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "row",
        [
          _c("template", { slot: "type" }, [
            _c("i", { staticClass: "material-icons" }, [_vm._v("folder")]),
          ]),
          _vm._v(" "),
          _c("template", { slot: "date" }, [_vm._v("-")]),
          _vm._v(" "),
          _c("template", { slot: "nights" }, [_vm._v("-")]),
          _vm._v(" "),
          _c("template", { slot: "name" }, [
            _vm._v(
              "Paket # " +
                _vm._s(_vm.treatPackage.id) +
                " von " +
                _vm._s(_vm.name)
            ),
          ]),
          _vm._v(" "),
          _c("template", { slot: "provider" }, [_vm._v(_vm._s(_vm.name))]),
          _vm._v(" "),
          _c("template", { slot: "currency" }, [
            _c("span", { staticClass: "is-currency" }, [
              _vm._v(_vm._s(_vm.currency)),
            ]),
          ]),
          _vm._v(" "),
          _c("template", { slot: "sellPrice" }, [
            _c("span", { staticClass: "empty" }, [_vm._v("-")]),
          ]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "profit" },
            [
              _vm.personPrice
                ? _c("input-price", {
                    attrs: {
                      value: _vm.personPrice,
                      highlight: !!_vm.highlight.person,
                      "value-in-order-currency": _vm.personPriceInOrderCurrency,
                      linked: "",
                    },
                  })
                : _c("span", { staticClass: "empty" }, [_vm._v("-")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "margin" },
            [
              _vm.singlePrice
                ? _c("input-price", {
                    attrs: {
                      value: _vm.singlePrice,
                      highlight: !!_vm.highlight.single,
                      "value-in-order-currency": _vm.singlePriceInOrderCurrency,
                      linked: "",
                    },
                  })
                : _c("span", { staticClass: "empty" }, [_vm._v("-")]),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "is-light" },
        [
          _vm._l(_vm.treatPackage.requests, function (request) {
            return _c(
              "row",
              { key: `request-${request.id}` },
              [
                _c("template", { slot: "type" }, [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("subdirectory_arrow_right"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "date" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("fullYear")(request.placeholder.startAt)
                        ) +
                        "\n                "
                    ),
                    request.endAt &&
                    !_vm.equalDates(
                      request.placeholder.startAt,
                      request.placeholder.endAt
                    )
                      ? [
                          _vm._v(
                            "\n                    - " +
                              _vm._s(
                                _vm._f("fullYear")(request.placeholder.endAt)
                              ) +
                              "\n                "
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "nights" },
                  [
                    _vm.requestNights(request)
                      ? [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.requestNights(request)) +
                              " N\n                "
                          ),
                        ]
                      : [_vm._v("-")],
                  ],
                  2
                ),
                _vm._v(" "),
                _c("template", { slot: "name" }, [
                  _vm._v(_vm._s(request.serviceDescription)),
                ]),
                _vm._v(" "),
                _c("template", { slot: "provider" }, [_vm._v("-")]),
                _vm._v(" "),
                _c("template", { slot: "currency" }, [
                  _c("span", { staticClass: "is-currency" }, [
                    _vm._v(_vm._s(_vm.currency)),
                  ]),
                ]),
                _vm._v(" "),
                _c("template", { slot: "sellPrice" }, [
                  _c("span", { staticClass: "empty" }, [_vm._v("-")]),
                ]),
                _vm._v(" "),
                _c("template", { slot: "profit" }, [
                  _c("span", { staticClass: "empty" }, [_vm._v("-")]),
                ]),
                _vm._v(" "),
                _c("template", { slot: "margin" }, [
                  _c("span", { staticClass: "empty" }, [_vm._v("-")]),
                ]),
              ],
              2
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.itemPrices, function (itemPrice) {
            return _c("item-price", {
              key: `itemPrice-${itemPrice.id}`,
              attrs: { price: itemPrice },
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }