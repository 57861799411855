<template>
    <tr>
        <th><label for="_importChecked" class="label">Überprüft:</label></th>
        <td>
            <label class="radio">
                <input type="radio" v-model="importChecked" v-bind:value="true"> ja
            </label>

            <label class="radio">
                <input type="radio" v-model="importChecked" v-bind:value="false"> nein
            </label>
        </td>
    </tr>
</template>

<script>
export default {
    props: ['value'],

    computed: {
        importChecked: {
            get: function () { return this.value; },
            set: function (value) { this.$emit('input', value) }
        }
    }
}
</script>
