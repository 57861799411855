var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isChecked
    ? _c("i", { staticClass: "material-icons" }, [_vm._v("check")])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }