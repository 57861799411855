<template>
    <div class="bp-row-form">
        <div class="is-flex-grow">
            <div class="bp-row-form__title">Freitext</div>

            <div>
                <button
                    class        = "btn-single-toggle has-margin-right"
                    v-bind:class = "[onlyFromOrder ? '' : 'is-active']"
                    v-on:click   = "onlyFromOrder = !onlyFromOrder"
                    v-bind:title = "onlyFromOrder ? 'Nur in Destinationen suchen' : 'Überall suchen'"
                ><i class="material-icons">flag</i></button>


                <multiselect
                    ref                    = "free"
                    v-model                = "template"
                    v-bind:options         = "predefinedFreeTextItems"
                    v-bind:loading         = "isLoading"
                    v-bind:allow-empty     = "true"
                    v-bind:internal-search = "false"

                    placeholder     = "Freitextvorlage suchen"
                    label           = "name"
                    track-by        = "id"

                    v-on:select        = "predefinedSelected"
                    v-on:search-change = "getPredefinedFreeTextList"
                >
                    <span slot="caret" v-if="isEmptyResults">
                        <a
                            class  = "multiselect-crud-link"
                            href   = "/free_texts?add"
                            title  = "füge eine neue Vorlage hinzu"
                            target = "_blank"
                        ><i class="material-icons">add</i></a>
                    </span>
                </multiselect>

            </div>
            <div>
                <i class="material-icons">view_headline</i>
                <tip-tap class="is-flex-grow" :menuItems="['fullscreen', 'bold', 'italic', 'underline', 'color', 'undo']" v-model="copyPlaceholder.text" isNotAlone/>
            </div>
        </div>
        <div class="is-160 has-time">
            <div class="bp-row-form__title">Zeitangaben</div>

            <div>
                <i class="material-icons">access_time</i>
                <date-time class="has-margin-right" v-model="copyPlaceholder.startAt" v-if="!isConcept"></date-time>
                <date-time class="has-margin-right" v-model="copyPlaceholder.startTime" v-if="isConcept" timeOnly></date-time>
            </div>
        </div>



        <!-- action buttons -->
        <slot></slot>
    </div>
</template>



<script>
import get          from 'lodash/get';
import debounce     from 'lodash/debounce';
import { InputPrice, TimeOfDaySelect, Multiselect, AsyncSelect, ErrorWrapper, DateTime } from '@components/form';
import { getPredefinedFreeTexts } from '@api';
import store     from '@orders/store';
import { TipTap }        from '@components/form';
import mixins from "@orders/placeholders/components/forms/mixins";



export default {
    mixins: [mixins],
    store,

    props: {
        value:               { type: Object,  required: true },
        isConcept:           { type: Boolean, default: false },
        limitedDestinations: { type: Array,   required: true },
        isTaggable:          { default:  true }
    },


    data: function () {
        return {
            copyPlaceholder:  JSON.parse(JSON.stringify(this.value)),
            template:       {},
            predefinedFreeTextItems: [],
            searchQuery:      '',
            onlyFromOrder:    true,
            isLoading:        false,
            isEmptyResults:   false,
        }
    },


    methods: {


        predefinedSelected: function (option) {
            this.template                        = option;
            this.copyPlaceholder.predefinedFreeTextItem = option;
            this.copyPlaceholder.text      = option.text;
        },


        getPredefinedFreeTextList: debounce(function (query) {
            if (query) {
                this.searchQuery = query;

                let apiQuery = '?name=' + query;

                if (this.onlyFromOrder) {
                    apiQuery += this.limitedDestinations.reduce((currentString, destination) => {
                            return currentString + '&destination.id[]=' + destination.id;
                        }, '');
                }

                this.isLoading = true;
                this.callCancelRequest();
                getPredefinedFreeTexts(apiQuery, {cancelToken: this.cancelSource.token}).then(response => {
                    this.predefinedFreeTextItems = response.data;
                    this.isEmptyResults = response.data.length === 0;

                    // If it is a new Route, set it to the first (if any)
                    if (this.copyPlaceholder.isBrandNew && this.predefinedFreeTextItems.length > 0) {
                        this.predefinedSelected(this.predefinedFreeTextItems[0]);
                    }

                    this.isLoading = false;
                });
            }
        }, 900),


        update() {
            this.copyPlaceholder = JSON.parse(JSON.stringify(this.value));
        }
    },


    created: function () {
        if (this.copyPlaceholder.text) {
            if(this.copyPlaceholder.predefinedFreeTextItem){
                this.template = { text: this.copyPlaceholder.text, id: this.copyPlaceholder.predefinedFreeTextItem.id };
            } else {
                this.template = { text: this.copyPlaceholder.text };

            }
        }
    },

    mounted: function () {
        if (this.value && !this.value.predefinedFreeTextItem) {
            this.$refs.free.$el.querySelector('input').focus();
        }
    },


    watch: {
        value: function (value) {
            if (JSON.stringify(value) !== JSON.stringify(this.copyPlaceholder)) {
                this.copyPlaceholder = JSON.parse(JSON.stringify(value));
            }
        },

        copyPlaceholder: {
            handler: function (value) {
                this.$emit('input', value);
            },
            deep: true
        },


        onlyFromOrder: function () { this.getPredefinedFreeTextList(this.searchQuery); },
    },


    components: {
        InputPrice,
        Multiselect,
        AsyncSelect,
        ErrorWrapper,
        TimeOfDaySelect,
        TipTap,
        DateTime
    }
}
</script>
