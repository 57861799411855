var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "has-margin-top" }, [
    _c(
      "div",
      {
        staticClass: "bp-panel has-no-title",
        staticStyle: { "min-height": "auto" },
      },
      [
        _c("div", { staticClass: "panel-content" }, [
          _c("div", { staticClass: "field" }, [
            _c(
              "div",
              { staticClass: "control" },
              [
                _c(
                  "toggle-switch",
                  {
                    attrs: {
                      "div-class": "is-full-width",
                      "btn-class": "is-half",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$emit(
                          "saveItem",
                          "readyForStaging",
                          _vm.readyForStaging,
                          true
                        )
                      },
                    },
                    model: {
                      value: _vm.readyForStaging,
                      callback: function ($$v) {
                        _vm.readyForStaging = $$v
                      },
                      expression: "readyForStaging",
                    },
                  },
                  [
                    _c("option", { domProps: { value: false } }, [
                      _vm._v("soll noch nicht veröffentlicht werden"),
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: true } }, [
                      _vm._v("kann veröffentlicht werden"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }