var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cell-content is-full is-flex is-align-items-center" },
    [
      _vm.row.link && _vm.row.buttonLabel
        ? _c(
            "a",
            {
              staticClass: "tag is-white p-1 mr-1 has-text-grey-dark",
              staticStyle: { "text-decoration": "none" },
              attrs: { href: _vm.row.link, target: "_blank" },
            },
            [_vm._v("\n        " + _vm._s(_vm.row.buttonLabel) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.value))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }