var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hide
    ? _c(
        "row",
        {
          nativeOn: {
            dblclick: function ($event) {
              return _vm.openModal.apply(null, arguments)
            },
          },
        },
        [
          _c("template", { slot: "type" }, [
            _c("i", { staticClass: "material-icons" }, [
              _vm._v("subdirectory_arrow_right"),
            ]),
          ]),
          _vm._v(" "),
          _c("template", { slot: "date" }, [_vm._v("-")]),
          _vm._v(" "),
          _c("template", { slot: "nights" }, [_vm._v("-")]),
          _vm._v(" "),
          _c("template", { slot: "name" }, [_vm._v(_vm._s(_vm.name))]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "marginTax" },
            [
              _c("margin-tax", {
                attrs: { tax: _vm.marginTax, margin: _vm.grossMargin },
                model: {
                  value: _vm.marginTaxEnabled,
                  callback: function ($$v) {
                    _vm.marginTaxEnabled = $$v
                  },
                  expression: "marginTaxEnabled",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showExchangeRates
            ? _c(
                "template",
                { slot: "buyPriceCalculation" },
                [
                  _c("input-price", {
                    attrs: { linked: _vm.linked },
                    model: {
                      value: _vm.buyPriceInCalculationCurrency,
                      callback: function ($$v) {
                        _vm.buyPriceInCalculationCurrency = $$v
                      },
                      expression: "buyPriceInCalculationCurrency",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "template",
            { slot: "buyPrice" },
            [
              _c("input-price", {
                attrs: {
                  linked: _vm.linked,
                  "provider-prices": _vm.getProviderPrices(_vm.buyPriceType),
                  currency: _vm.currency,
                  "is-blue": _vm.hasTwoCurrencies,
                },
                model: {
                  value: _vm.buyPrice,
                  callback: function ($$v) {
                    _vm.buyPrice = $$v
                  },
                  expression: "buyPrice",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showExchangeRates
            ? _c(
                "template",
                { slot: "sellPriceCalculation" },
                [
                  _c("input-price", {
                    model: {
                      value: _vm.sellPriceInCalculationCurrency,
                      callback: function ($$v) {
                        _vm.sellPriceInCalculationCurrency = $$v
                      },
                      expression: "sellPriceInCalculationCurrency",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "template",
            { slot: "sellPrice" },
            [
              _c("input-price", {
                attrs: { "is-blue": _vm.hasTwoCurrencies },
                model: {
                  value: _vm.sellPrice,
                  callback: function ($$v) {
                    _vm.sellPrice = $$v
                  },
                  expression: "sellPrice",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showExchangeRates
            ? _c(
                "template",
                { slot: "profitCalculation" },
                [
                  _c("input-price", {
                    model: {
                      value: _vm.profitInCalculationCurrency,
                      callback: function ($$v) {
                        _vm.profitInCalculationCurrency = $$v
                      },
                      expression: "profitInCalculationCurrency",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "template",
            { slot: "profit" },
            [
              _c("input-price", {
                attrs: { "is-blue": _vm.hasTwoCurrencies },
                model: {
                  value: _vm.profit,
                  callback: function ($$v) {
                    _vm.profit = $$v
                  },
                  expression: "profit",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "margin" },
            [
              _c("input-price", {
                model: {
                  value: _vm.margin,
                  callback: function ($$v) {
                    _vm.margin = $$v
                  },
                  expression: "margin",
                },
              }),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }