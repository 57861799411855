var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bp-row-form" }, [
    _c("div", [
      _c("div", { staticClass: "bp-row-form__title" }, [_vm._v("Standort")]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "button",
            {
              class: ["btn-single-toggle", _vm.onlyFromDay ? "" : "is-active"],
              attrs: {
                title: _vm.onlyFromDay ? "Only from trip destinations" : "All",
              },
              on: {
                click: function ($event) {
                  _vm.onlyFromDay = !_vm.onlyFromDay
                },
              },
            },
            [_c("i", { staticClass: "material-icons" }, [_vm._v("flag")])]
          ),
          _vm._v(" "),
          _c("location-select", {
            ref: "location",
            attrs: {
              value: {
                area: _vm.copyPlaceholder.area,
                place: _vm.copyPlaceholder.place,
              },
              limitedDestinations: _vm.destinationsForLocation,
            },
            on: {
              input: (value) => {
                _vm.copyPlaceholder.area = value.area
                _vm.copyPlaceholder.place = value.place
              },
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("div", { staticClass: "bp-row-form__title" }, [_vm._v("Details")]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("i", { staticClass: "material-icons" }, [_vm._v("star")]),
            _vm._v(" "),
            _c("type", {
              model: {
                value: _vm.copyPlaceholder.hotelType,
                callback: function ($$v) {
                  _vm.$set(_vm.copyPlaceholder, "hotelType", $$v)
                },
                expression: "copyPlaceholder.hotelType",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("board", {
          model: {
            value: _vm.copyPlaceholder.board,
            callback: function ($$v) {
              _vm.$set(_vm.copyPlaceholder, "board", $$v)
            },
            expression: "copyPlaceholder.board",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "is-160 has-time" }, [
      _c("div", { staticClass: "bp-row-form__title" }, [_vm._v("Zeitangaben")]),
      _vm._v(" "),
      _c("div", [
        _c("i", { staticClass: "material-icons" }, [_vm._v("brightness_3")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.copyPlaceholder.nights,
              expression: "copyPlaceholder.nights",
              modifiers: { number: true },
            },
          ],
          staticClass: "input has-margin-right is-30",
          attrs: { type: "text" },
          domProps: { value: _vm.copyPlaceholder.nights },
          on: {
            focus: function ($event) {
              return $event.target.select()
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.copyPlaceholder,
                "nights",
                _vm._n($event.target.value)
              )
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _vm._v("\n                Nächte\n            "),
      ]),
    ]),
    _vm._v(" "),
    _c("div", [
      _c("div", { staticClass: "bp-row-form__title" }, [_vm._v("Abrechnung")]),
      _vm._v(" "),
      _c("div", [
        _c(
          "i",
          {
            staticClass: "material-icons",
            attrs: { title: "Set as Additional" },
          },
          [_vm._v("shopping_cart")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "select" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.copyPlaceholder.clientOffer.status,
                  expression: "copyPlaceholder.clientOffer.status",
                },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.copyPlaceholder.clientOffer,
                    "status",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "included" } }, [
                _vm._v("inkludiert"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "optional" } }, [
                _vm._v("optional"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "additional" } }, [
                _vm._v("gesondert"),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "button is-close",
        attrs: { title: "Verwerfen" },
        on: {
          click: function ($event) {
            return _vm.$emit("cancel")
          },
        },
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "button is-save is-primary",
        class: { "is-loading": _vm.isLoading },
        attrs: { title: "Speichern" },
        on: { click: _vm.save },
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("check")])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }