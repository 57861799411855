var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("year-tab-filter", {
        attrs: { "filter-start-end-keys": _vm.filterStartEndKeys },
        model: {
          value: _vm.filters,
          callback: function ($$v) {
            _vm.filters = $$v
          },
          expression: "filters",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "redesign-table" },
        [
          _c("div", { staticClass: "row is-header is-space-between" }, [
            _c("h2", [_vm._v(_vm._s(_vm.headline))]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-edit",
                on: {
                  click: function ($event) {
                    return _vm.$emit("addPriceGroup")
                  },
                },
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons is-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.handleFormByType({})
                      },
                    },
                  },
                  [_vm._v("add_circle")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("table-filters", {
            staticClass: "row is-filters",
            attrs: { options: _vm.options },
            on: {
              input: function ($event) {
                return _vm.getPrices()
              },
            },
            model: {
              value: _vm.filters,
              callback: function ($$v) {
                _vm.filters = $$v
              },
              expression: "filters",
            },
          }),
          _vm._v(" "),
          _vm.isLoading
            ? _c("loading")
            : _vm._l(_vm.groupedRows, function (level) {
                return _vm.levels[0] === "level"
                  ? _c("level-row", {
                      key: level.key,
                      attrs: {
                        level: level,
                        levels: _vm.allLevels,
                        options: _vm.options,
                        "show-next-row": _vm.showNextRow,
                      },
                      on: {
                        editRoute: (route) =>
                          _vm.$emit("openRouteForm", route.route),
                        deleteRoute: (route) => _vm.deleteRoute(route.route),
                        editTrainRoute: (trainRoute) =>
                          _vm.$emit("openTrainRouteForm", trainRoute.route),
                        deleteTrainRoute: (trainRoute) =>
                          _vm.deleteTrainRoute(trainRoute.route),
                        editOtherService: (otherService) =>
                          _vm.$emit(
                            "openOtherServiceForm",
                            otherService.otherService
                          ),
                        deleteOtherService: (otherService) =>
                          _vm.$emit(
                            "deleteOtherService",
                            otherService.otherService
                          ),
                        addPriceGroup: _vm.addPriceGroup,
                        editPriceGroup: _vm.editPriceGroup,
                        deletePriceGroup: _vm.deletePriceGroup,
                        updateLevel: _vm.updateRow,
                      },
                    })
                  : _vm._e()
              }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("div", { staticClass: "redesign-table__footer" })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }