<template>
    <div class="tags" v-if="value">
        <span 
            class="tag"
            v-bind:class="`is-${value}`"
        >{{ difficulty }}</span>
    </div>
</template>

<script>
import { getValueWithKey } from '@utilities/functions'
import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        difficulty: function () {
            return getValueWithKey({key: this.value, optionList: this.options.difficulties})
        }
    }
}
</script>
