var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "columns",
    {
      attrs: { property: "airlineRoutes", loadOptions: false },
      on: { add: _vm.openForm },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function ({ item }) {
            return _c(
              "column-item",
              {
                attrs: {
                  update: {
                    updatedBy: item.updatedBy,
                    updatedAt: item.updatedAt,
                  },
                  api: "/api/airline_routes/" + item.id,
                },
                on: {
                  edit: function ($event) {
                    return _vm.openForm(item)
                  },
                  delete: function ($event) {
                    return _vm.deleteItem("airlineRoutes", item)
                  },
                },
              },
              [
                _c("dl", [
                  _c("dt", [_vm._v("Flughafen Abflug")]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.airportName(item.startAirport)) +
                        "\n                "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `/airports/${item.startAirport.id}`,
                          target: "_blank",
                        },
                      },
                      [_c("i", { staticClass: "fa fa-external-link-square" })]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("dt", [_vm._v("Flughafen Ankunft")]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.airportName(item.endAirport)) +
                        "\n                "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `/airports/${item.endAirport.id}`,
                          target: "_blank",
                        },
                      },
                      [_c("i", { staticClass: "fa fa-external-link-square" })]
                    ),
                  ]),
                ]),
              ]
            )
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("flight-form", { ref: "form", attrs: { slot: "form" }, slot: "form" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }