<template>
    <div class="row">
        <div class="cell is-icon-cell has-text-centered" v-if="hasType">
            <slot name="type"></slot>
        </div>
        <div class="cell is-multiple-date-cell" v-if="hasDate">
            <slot name="date"></slot>
        </div>
        <div class="cell is-small-cell has-text-centered" v-if="hasNights">
            <slot name="nights"></slot>
        </div>
        <div class="cell is-name-cell" v-if="hasName" :class="{'has-provider': hasProvider}">
            <slot name="name"></slot>
        </div>
        <div class="cell is-provider-cell" v-if="hasProvider">
            <slot name="provider"></slot>
        </div>
        <div class="cell is-price-cell is-small is-editable" v-if="hasCurrency">
            <slot name="currency"></slot>
        </div>
        <div class="cell is-price-cell" v-if="hasMarginTax">
            <slot name="marginTax"></slot>
        </div>
        <div class="cell is-price-cell is-half" v-if="hasBuyPriceCalculation">
            <slot name="buyPriceCalculation"></slot>
        </div>
        <div class="cell is-price-cell" :class="{'is-half': hasBuyPriceCalculation}" v-if="hasBuyPrice">
            <slot name="buyPrice"></slot>
        </div>
        <div class="cell is-price-cell is-half" v-if="hasSellPriceCalculation">
            <slot name="sellPriceCalculation"></slot>
        </div>
        <div class="cell is-price-cell" :class="{'is-half': hasSellPriceCalculation}">
            <slot name="sellPrice"></slot>
        </div>
        <div class="cell is-price-cell is-half" v-if="hasProfitCalculation">
            <slot name="profitCalculation"></slot>
        </div>
        <div class="cell is-price-cell" :class="{'is-half': hasProfitCalculation}">
            <slot name="profit"></slot>
        </div>
        <div class="cell is-price-cell is-margin-cell">
            <slot name="margin"></slot>
        </div>
        <div class="cell is-actions is-small-cell" v-if="actions">
            <slot name="actions"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        actions: {
            type: Boolean,
            default: true,
        }
    },

    computed: {
        hasType: function () { return !!this.$slots.type },
        hasDate: function () { return !!this.$slots.date },
        hasNights: function () { return !!this.$slots.nights },
        hasCurrency: function () { return !!this.$slots.currency },
        hasName: function () { return !!this.$slots.name },
        hasProvider: function () { return !!this.$slots.provider },
        hasBuyPrice: function () { return !!this.$slots.buyPrice },
        hasBuyPriceCalculation: function () {return this.$slots.buyPriceCalculation },
        hasSellPriceCalculation: function () {return !!this.$slots.sellPriceCalculation },
        hasProfitCalculation: function () {return !!this.$slots.profitCalculation},
        hasMarginTax: function () { return !!this.$slots.marginTax }
    }
}
</script>

