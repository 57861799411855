import isEmpty           from 'lodash/isEmpty';
import pickBy            from 'lodash/pickBy';
import pick              from 'lodash/pick';
import reduce            from 'lodash/reduce';
import isBoolean         from 'lodash/isBoolean';

import ModalForm         from '@components/ModalForm';
import { ErrorWrapper }  from '@components/form';
import {notifyError, notifySuccess} from '@components/Notification';

import { prepareForBackend } from '@utilities/functions';

export default {

    props: {
        options: {
            type: Object,
            required: false,
        }
    },

    data: function () {
        return {
            visible: false,
            isLoading: false,
            emptyForm: {},
            form: {},
            errors: {},
            replaceItemSwitch: false
        }
    },


    created: function () {
        this.form = Object.assign({}, this.emptyForm);
    },


    mounted: function () {
        this.mountForm();
    },


    computed: {
        editMode: function () {
            return !!this.form.id;
        },

        formData: function () {
            return prepareForBackend(this.form)
        }
    },


    methods: {
        mountForm: function () {
            const self = this;

            this.$children[0].$on('create', function () {
                self.handleFormCreate();
            });

            this.$children[0].$on('update', function () {
                self.handleFormUpdate();
            });

            this.$children[0].$on('closeModal', function () {
                self.visible = false;
            });
        },

        openModal: function (data){
            const formKeys = [...Object.keys(this.emptyForm), 'id']

            this.form = JSON.parse(JSON.stringify({
                ...this.emptyForm,
                ...!isEmpty(data) && pickBy(data, (value, key) => {
                    return formKeys.includes(key) && (isBoolean(value) || value)
                })
            }))


            this.afterDataLoad();
            this.afterDataLoadAddition();
            this.visible = true;
            this.errors = {};
        },

        closeModal: function () {
            this.visible = false;
        },

        afterDataLoad: function () {},
        afterDataLoadAddition: function () {},



        update: function () { return new Promise(resolve => { resolve({data: {}}) }) }, // Override in component
        create: function () { return new Promise(resolve => { resolve({data: {}}) }) }, // Override in component
        replace: function () { return new Promise(resolve => { resolve({data: {}}) }) }, // Override in component

        handleFormUpdate: function () {
            const keys = Object.keys(this.form);

            this.$emit('submit', this.form);
            this.$emit('update', this.form);
            this.isLoading = true;

            this.update({id: this.form.id, data: this.formData})
                .then(response => {

                    if(this.replaceItemSwitch){
                        this.replace(this.form.id).then(response => {
                            notifySuccess('Das Element wurde überall ersetzt!')
                        }, error => {
                            notifyError('Server Error! Das Element konnte nicht überall ersetzt werden!')
                        })
                    }

                    this.isLoading = false;
                    this.visible = false;

                    if (Object.keys(response.data).length > 0) {
                        const data = response.data;
                        if(data.translations && this.defaultTranslation){

                            for (let translation in data.translations) {

                                if(translation === this.defaultTranslation){
                                    for (const prop in data.translations[translation]) {
                                        if (Object.prototype.hasOwnProperty.call(data, prop)) {
                                            data[prop] = data.translations[translation][prop];
                                        }
                                    }
                                }
                            }
                        }
                        this.$emit('updated', pick(data, keys))
                        notifySuccess('Aktualisierung erfolgreich!');
                    }
                }, this.onError)
        },

        handleFormCreate: function () {
            this.$emit('submit', this.form);
            this.$emit('create', this.form);
            this.isLoading = true;

            this.create(this.formData)
                .then(response => {
                    this.isLoading = false
                    this.visible = false;

                    if (Object.keys(response.data).length > 0) {
                        this.$emit('created', response.data)
                        notifySuccess('Aktualisierung erfolgreich!');
                    }
                }, this.onError)
        },

        onError: function (error) {
            const violations = error.response.data.violations;

            const errors = reduce(violations, function (obj, param) {
                obj[param.propertyPath] = param.message
                return obj;
            }, {});

            this.errors = Object.assign({}, errors);
            this.$emit('error', this.errors);
            this.isLoading = false;
        }
    },


    components: {
        ModalForm, ErrorWrapper
    }
}
