<template>
    <div
        class="bp-deadline"
        v-bind:class="[deadlineUrgency(date), editable ? 'is-editable' : '']"
    >

        <input-date
            v-if="editable"
            v-model="date"
            ref="input"
            placeholder="-"
        />

        <span v-else class="deadline__date">
            <template v-if="value">{{ value | fullYear }}</template>
            <template v-else>-</template>
        </span>
    </div>
</template>



<script>
import { deadlineUrgency , fullYear } from '@utilities/functions';
import { InputDate } from '@components/form'


export default {
    props: {
        value:       { required: true },
        editable:    { type: Boolean, default: false }
    },


    computed: {
        date: {
            get: function () { return !!this.value ? this.value.substring(0, 10) : this.value; },
            set: function (newValue) {
                if (this.date !== newValue) {
                    this.$emit('input', newValue ? newValue + ' 00:00:00' : null);
                }
            }
        }
    },


    methods: {
        deadlineUrgency
    },


    filters: {
        fullYear
    },


    components: {
        InputDate
    }
}
</script>
