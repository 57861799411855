export default {
    props: {
        row: {
            type: Object,
            required: true,
        },
        
        value: {
            required: true,
        },

        options: {
            type: Object,
        },
    },
}