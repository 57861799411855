<template>
    <span :data-bubble="value" v-if="value">{{ rating }}</span>
</template>

<script>
import has from 'lodash/has';
import Cell from './cell';

export default {
    mixins: [ Cell ],
    
    props: {
        value: {
            required: true
        },
    },

    computed: {
        rating: function () {
            const rating = (has(this.options, 'buspaketRatings') ? this.options.buspaketRatings : [])
                .find(rating => rating.key === this.value);
            
            return !!rating ? rating.value : '';
        },
    }
}
</script>
