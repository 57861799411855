<template>
    <div class="tab-addition">
        <div class="tab-addition__header">
            <header v-if="tabs.length > 0">
                <button
                    v-for="key in tabs"
                    v-bind:key="key"
                    v-bind:class="{'is-active': key === activeKey}"
                    v-on:click.prevent.stop="activeKey = key"
                >
                    {{tabName(key)}}
                </button>
            </header>
        </div>

        <div class="main">
            <keep-alive>
                <component v-bind:is="activeKey" />
            </keep-alive>
            <div class="has-text-centered" v-if="!tabs.length">Keine weiteren Informationen</div>
        </div>
    </div>
</template>

<script>
import { store } from './store';
import { getStorageTab, setStorageTab } from '@utilities/functions';
import Tabs from './Tabs'

export default {
    store,

    props: {
        tabs: {
            type: Array,
            required: true
        }
    },

    data: function () {
        return {
            activeKey: ""
        }
    },

    computed: {
        data: function () { return this.$store.state.data; },
    },

    methods: {
        tabName(key) {
            return {
                'addresses': 'Adressdaten',
                'accounts': 'Konten und Kassen',
                'contacts': 'Kontaktdaten',
                'images': 'Bilder',
                'tripDetailDocuments': 'Reiseuterlagen',
                'otherServices': 'Zusatzleistungen',
                'otherPresetPrices': 'Kalkulationspreise',
                'hotelPrices': 'Preise Hotelübernachtung',
                'itemPrices': 'Zuschläge',
                'entrances': 'Eintritte',
                'infos': 'Hinweise',
                'todos': 'Aufgaben',
                'menus': 'Menüs',
                'bank-accounts': 'Bankverbindungen',
                'freeplaces': 'Freiplätze',
                'documents': 'Dokumente',
                'service-noname-prices': 'Zusatzleistungen',
                'hotel-noname-prices': 'Hotelleistungen',
                'ferry-noname-prices': 'Fährverbindungen',
                'train-noname-prices': 'Zugverbindungen',
                'airlineRoutes': 'Flugverbindungen',
                'ferryRoutes': 'Fährverbindungen',
                'trainRoutes': 'Zugverbindungen',
                'services': 'Mit Leistung verknüpfte Leistungsträger',
                'directions': 'Routenbeschreibung',
                'day-directions': 'Routenbeschreibung',
                'sellingPrices': 'Verkaufspreise',
                'calculationOrders': 'Verkaufspreise',
                'orders': 'Reiseübersicht',
                'orderConcepts': 'Verknüpfte Gesamtkonzepte',
                'conditions': 'Konditionen',
                'client-dates': 'Termine',
                'sales': 'Vertrieb',
                'conceptCatalogText': 'Ausschreibungstext',
                'trip-details': 'Reiseunterlagen',
                'linkedOrders': 'Verknüpfungen mit Reisen',
                'linkedDayConcepts': 'Verknüpfungen mit Tageskonzepten',
                'catalogText': 'Katalogtext',
                'users': 'Mitarbeiter',
                'accountPlan': 'Kontenplan',
                'webflow': 'Homepage',
                'websiteExport': 'Homepage',
            }[key];
        },
    },

    mounted: function () {
        this.activeKey = getStorageTab(window.location.pathname);

        if (this.activeKey === "" && this.tabs.length > 0) {
            this.activeKey = this.tabs[0]
        }
    },

    watch: {
        activeKey: function (key) {
            setStorageTab(window.location.pathname, key)
        },

        tabs: function(tabs) {
            if (!tabs.includes(this.activeKey)) {
                this.activeKey = tabs[0]
            }
        }
    },

    components: Tabs
}
</script>
