<template>
    <tr>
        <th class="is-top">
            <label class="label">
                {{label}}:
                <i class="material-icons is-pointer" v-on:click="addTime">add_circle</i>
            </label>
        </th>

        <td>
            <!-- dayTimes -->
            <span v-if="dayTimes && dayTimes.length === 0">Alle Startzeiten</span>
            <div class="level-left" v-for="dayTime in dayTimes" v-bind:key="keyID(dayTime)">
                <div class="flatpickr-combined level-item">
                    <date-time placeholder-text="Check-In" v-if="fields.includes('checkInTimeOfDay')" v-model="dayTime.checkInTimeOfDay" timeOnly/>
                    <span v-if="fields.includes('checkInTimeOfDay')"> - </span>
                    <date-time placeholder-text="Startzeit" v-if="fields.includes('startTimeOfDay')" v-model="dayTime.startTimeOfDay" timeOnly/>
                    <span v-if="fields.includes('endTimeOfDay')"> &ndash; </span>
                    <date-time placeholder-text="Endzeit" v-if="fields.includes('endTimeOfDay')" v-model="dayTime.endTimeOfDay" timeOnly/>
                </div>
                <i
                    class="material-icons is-pointer"
                    v-on:click="deleteTime(dayTime)"
                >delete</i>
            </div>
        </td>
    </tr>
</template>

<script>

import { DateTime, } from '@components/form';

export default {
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        fields: {
            type: Array,
            default: () => (['startTimeOfDay', 'endTimeOfDay'])
        },
        predefinedDayTimes : {
            type: Array,
            default: () => ([])
        },
        label : {
            type: String,
            default: 'Tageszeiten'
        },
    },

    methods: {
        keyID: function (dayTime) {
            return dayTime.key ? dayTime.key : dayTime.id
        },
        addTime: function () {
            if(this.dayTimes.length === 0 && this.predefinedDayTimes.length > 0){
                this.predefinedDayTimes.forEach(dayTime => {
                    this.dayTimes.push(dayTime)
                })
            } else {
                this.dayTimes.push({
                    startTimeOfDay: '',
                    endTimeOfDay: '',
                    checkInTimeOfDay: '',
                    key: + new Date
                })
            }
        },
        deleteTime: function (time) {
            this.dayTimes = this.dayTimes
                .filter(dayTime => this.keyID(dayTime) !== this.keyID(time))
        },
    },


    computed: {
        dayTimes: {
            get: function () { return this.value},
            set: function (values) { this.$emit('input', values) }
        }
    },

    components: {
        DateTime,
    },

}
</script>
