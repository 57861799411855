var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "redesign-table" }, [
        _c(
          "div",
          { staticClass: "table-wrapper" },
          [
            _vm.label
              ? _c("div", { staticClass: "table-label" }, [
                  _vm._v(_vm._s(_vm.label)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hasHeader
              ? _c(
                  "div",
                  { staticClass: "row is-header" },
                  [_vm._t("header")],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.hasSlotFilters
              ? _c("filters", {
                  staticClass: "row is-filters",
                  attrs: { options: _vm.options },
                  on: { input: _vm.refresh },
                  model: {
                    value: _vm.filters,
                    callback: function ($$v) {
                      _vm.filters = $$v
                    },
                    expression: "filters",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._t("filters", null, { options: _vm.options }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.isLoading
        ? _c("loading")
        : _vm._t("default", null, null, {
            rows: _vm.rows,
            options: _vm.options,
          }),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "level" },
            [
              _c("div", [
                _vm.isAddable
                  ? _c(
                      "a",
                      {
                        staticClass: "button is-primary",
                        on: { click: _vm.handleAdd },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("capitalize")(_vm.name.singular)) +
                            " hinzufügen"
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("pagination", {
                on: { input: _vm.refresh },
                model: {
                  value: _vm.pagination,
                  callback: function ($$v) {
                    _vm.pagination = $$v
                  },
                  expression: "pagination",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("form", null, { options: _vm.options }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }