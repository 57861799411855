<template>

    <div class="has-margin-top">
        <tip-tap
            v-model="item[component.props.property]"
            @input="saveForm(component.props)"
            :default-size="component.props.defaultSize"
        />
    </div>


</template>

<script>

import Mixin from '../../tab';
import { TipTap } from '@components/form';



export default {
    mixins: [ Mixin ],

    props: {
        component: {
            type: Object,
            required: true
        }
    },

    methods: {
        saveForm: function(field) {
            this.$emit('saveItem', field.property, this.item[field.property])
        }
    },

    computed: {},

    components: {
        TipTap,
    },
}
</script>
