<template>
    <modal-form
        editTitle="Zuschlag bearbeiten"
        createTitle="Neuen Zuschlag hinzufügen"
    >
        <table>
            <tr>
                <th>
                    <label for="name" class="label">Eintritt:</label>
                </th>
                <td>
                    <div class="control">
                        <input type="text" class="input" v-model="form.name" />
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="priceType" class="label">Preisart:</label></th>
                <td>
                    <multiselect
                        v-model="quantityType"
                        v-bind:options="quantityTypes"
                        trakc-by="key"
                        label="value"
                    />
                </td>
            </tr>
            <tr>
                <th><label for="price" class="label">Preis:</label></th>
                <td>
                    <input-price id="price" v-model="amount" class="is-medium" :prefix="currency" />
                </td>
            </tr>

            <tr>
                <th><label for="currency" class="label">Währung:</label></th>
                <td>
                    <multiselect
                        v-model="currency"
                        :options="options.potentialCurrencies ? options.potentialCurrencies : ['EUR']"
                        placeholder="EUR"
                        class="is-small"
                        :allow-empty="false"
                    />
                </td>
            </tr>

            <tr>
                <th>
                    <label for="" class="label">Limit:</label>
                </th>
                <td>
                    <div class="level-left">
                        <input type="text" class="input is-small level-item" v-model.number="form.minPersons" placeholder="Min" />
                        <span class="level-item">-</span>
                        <input type="text" class="input is-small level-item" v-model.number="form.maxPersons" placeholder="Max" />
                        <div>
                            <template v-if="form.quantityType === 'per_single_room'">EZ</template>
                            <template v-else>Pax</template>
                        </div>
                    </div>
                </td>
            </tr>

            <tr>
                <th>
                    <label for="startAt" class="label">Stand:</label>
                </th>
                <td>
                    <input-date v-model="form.startAt" class="is-small" v-if="visible"/>
                </td>
            </tr>

            <tr>
                <th>
                    <label for="primaryPrice" class="label">Primäreintritt:</label>
                </th>
                <td>
                    <div class="control">
                       <label class="radio">
                            <input type="radio" :value="true" v-model="form.primaryPrice">ja
                       </label>
                       <label class="radio">
                            <input type="radio" :value="false" v-model="form.primaryPrice">nein
                       </label>
                    </div>
                </td>
            </tr>

            <tr>
                <th><label for="billingType" class="label">Einfügen als:</label></th>
                <td>
                    <multiselect
                        v-bind:options="billingTypes"
                        v-model="billingType"
                        track-by="key"
                        label="value"
                        class="is-medium"
                    />
                </td>
            </tr>

            <tr>
                <th></th>
                <td>
                    <div class="control">
                        <label class="checkbox">
                            <input type="checkbox" v-bind:value="true" v-model="form.automaticallyAddToOrder">Zuschlag automatisch in jeder Reise hinzufügen
                        </label>
                    </div>
                </td>
            </tr>
        </table>
    </modal-form>
</template>

<script>
import TabForm from '../form'
import { InputPrice, InputDate, ToggleSwitch } from '@components/form';

export default {
    mixins: [ TabForm ],

    form: {
        name: '',
        quantityType: 'per_person',
        type: 'entrance',
        price: {
            amount: '0',
            currency: 'EUR'
        },
        startAt: null,
        endAt: null,
        primaryPrice: false,
        automaticallyAddToOrder: false,
        minPersons: null,
        maxPersons: null,
        billingType: null
    },

    property: 'itemPrices',

    components: {
        InputPrice, InputDate, ToggleSwitch
    },

    computed: {
        quantityTypes: function () {
            return this.options && this.options.quantityType ? this.options.quantityType : []
        },

        currency: {
            get: function () {
                return this.form && this.form.price && this.form.price.currency ? this.form.price.currency : 'EUR'
            },

            set: function (value) {
                this.form.price.currency = value
            }
        },

        amount: {
            get: function () {
                return this.form && this.form.price && this.form.price.amount ? this.form.price.amount : 'EUR'
            },

            set: function (value) {
                this.form.price.amount = value
            }
        },

        quantityType: {
            get: function () {
                return this.quantityTypes.find(type => type.key === this.form.quantityType)
            },

            set: function (value) {
                this.form.quantityType = !!value ? value.key : null
            }
        },

        billingTypes: function () {
            return this.options.billingType ? this.options.billingType : []
        },

        billingType: {
            get: function () {
                return this.billingTypes.find(billingType => billingType.key === this.form.billingType)
            },
            set: function (value) {
                this.form.billingType = !!value ? value.key : null
            }
        }
    }
}
</script>
