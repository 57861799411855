<template>
    <crud-table
        ref="table"
        api="account_plan_items"
        label="Buchungskonten"
        :load-options="false"
        slot-scope="{item}"
        :filter="{'organisation.id': item.id}"
        v-on:add="openForm"
        v-on:edit="openForm"
        :passed-options="options"
        :name="{singular: 'Buchungskonto', plural: 'Buchungskonten'}"
        :columns="[
                    'name',
                    { key: 'accountingNumber', label: 'Buchungskonto' },
                    { key: 'isDefaultFor', label: 'Voreingestellt für' },
                    { key: 'translations', label: 'Übersetzungen', sortable: false},

                ]"
    >
        <h2 slot="header">KONTENPLAN</h2>
        <account-plan-form
            ref="form" slot="form"
            v-on:success="$refs.table.refresh()"
        />

    </crud-table>

</template>

<script>

import AccountPlanForm from './Form';
import Mixin from '../tab';
import {CrudList} from "@components/CRUD";

export default {
    mixins: [ Mixin,CrudList],
    components: { AccountPlanForm },
    options: true,
    methods: {
        openForm: function (data = {}) {
            this.$refs.form.open({
                ...data
            });
        },
    },



}
</script>
