<script>
import mixin from './mixin'
export default {
    mixins: [ mixin ],

    icon: 'local_hotel',

    computed: {
        hasPersonPrice: function () { return true },
        personPrice: {
            get: function () {
                return this.price.doubleRoomBuyPrice.amount
            },
            set: function (value) {
                this.$store.commit('updatePrice', {
                    hotelPrices: {
                        id: this.price.id,
                        doubleRoomBuyPrice: {
                            amount: value,
                            currency: this.placeholder.placeholderCurrency
                        }
                    }
                })
            }
        },
        personPriceType: function () {
            return 'doubleRoomBuyPrice';
        },
        singlePriceType: function () {
            return 'singleRoomPremiumBuyPrice';
        },

        hasSinglePrice: function () { return true },
        singlePrice: {
            get: function () {
                return this.price.singleRoomPremiumBuyPrice.amount
            },
            set: function (value) {
                this.$store.commit('updatePrice', {
                    hotelPrices: {
                        id: this.price.id,
                        singleRoomPremiumBuyPrice: {
                            amount: value,
                            currency: this.placeholder.placeholderCurrency
                        }
                    }
                })
            }
        },
    }
}
</script>
