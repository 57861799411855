var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c("th", { staticClass: "is-top" }, [
      _c("label", { staticClass: "label" }, [
        _vm._v("\n            " + _vm._s(_vm.label) + ":\n            "),
        _c(
          "i",
          {
            staticClass: "material-icons is-pointer",
            on: { click: _vm.addTime },
          },
          [_vm._v("add_circle")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "td",
      [
        _vm.dayTimes && _vm.dayTimes.length === 0
          ? _c("span", [_vm._v("Alle Startzeiten")])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.dayTimes, function (dayTime) {
          return _c(
            "div",
            { key: _vm.keyID(dayTime), staticClass: "level-left" },
            [
              _c(
                "div",
                { staticClass: "flatpickr-combined level-item" },
                [
                  _vm.fields.includes("checkInTimeOfDay")
                    ? _c("date-time", {
                        attrs: { "placeholder-text": "Check-In", timeOnly: "" },
                        model: {
                          value: dayTime.checkInTimeOfDay,
                          callback: function ($$v) {
                            _vm.$set(dayTime, "checkInTimeOfDay", $$v)
                          },
                          expression: "dayTime.checkInTimeOfDay",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.fields.includes("checkInTimeOfDay")
                    ? _c("span", [_vm._v(" - ")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.fields.includes("startTimeOfDay")
                    ? _c("date-time", {
                        attrs: {
                          "placeholder-text": "Startzeit",
                          timeOnly: "",
                        },
                        model: {
                          value: dayTime.startTimeOfDay,
                          callback: function ($$v) {
                            _vm.$set(dayTime, "startTimeOfDay", $$v)
                          },
                          expression: "dayTime.startTimeOfDay",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.fields.includes("endTimeOfDay")
                    ? _c("span", [_vm._v(" – ")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.fields.includes("endTimeOfDay")
                    ? _c("date-time", {
                        attrs: { "placeholder-text": "Endzeit", timeOnly: "" },
                        model: {
                          value: dayTime.endTimeOfDay,
                          callback: function ($$v) {
                            _vm.$set(dayTime, "endTimeOfDay", $$v)
                          },
                          expression: "dayTime.endTimeOfDay",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "i",
                {
                  staticClass: "material-icons is-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.deleteTime(dayTime)
                    },
                  },
                },
                [_vm._v("delete")]
              ),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }