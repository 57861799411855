var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "bp-box is-info" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("main", [
          _c("label", { staticClass: "has-input-radio" }, [
            _c("input", {
              attrs: {
                type: "radio",
                name: "item_prices_ferry" + _vm.request.id,
              },
              domProps: { checked: _vm.request.package === null },
              on: {
                change: function ($event) {
                  return _vm.setAsIndividual(_vm.request.id)
                },
              },
            }),
            _vm._v(" Einzelabrechnung"),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "has-input-radio" }, [
            _c("input", {
              attrs: {
                type: "radio",
                name: "item_prices_ferry" + _vm.request.id,
              },
              domProps: { checked: _vm.request.package },
              on: {
                change: function ($event) {
                  _vm.request.package = { id: -1 }
                },
              },
            }),
            _vm._v(" im Paket"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.request.package === null
        ? _c("individually", {
            ref: "individually",
            attrs: {
              calculationRequest: _vm.calculationRequest,
              placeholder: _vm.placeholder,
            },
            model: {
              value: _vm.request,
              callback: function ($$v) {
                _vm.request = $$v
              },
              expression: "request",
            },
          })
        : _c("package", {
            attrs: { placeholder: _vm.placeholder },
            model: {
              value: _vm.request,
              callback: function ($$v) {
                _vm.request = $$v
              },
              expression: "request",
            },
          }),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "explanation" },
        [
          _vm.isCalculationRequest
            ? [
                _c("i", { staticClass: "fa fa-calculator has-text-active" }),
                _vm._v(" = für Kalkulation verwenden\n        "),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("i", {
            staticClass: "fa fa-info",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" = Kunde zahlt selbst vor Ort (nur Info)\n        "),
          _c("i", { staticClass: "material-icons" }, [_vm._v("shopping_cart")]),
          _vm._v(" = von uns bezahlt und im Paketpreis inkludiert\n        "),
          _c("i", { staticClass: "material-icons" }, [_vm._v("playlist_add")]),
          _vm._v(" = gesondert berechnet\n    "),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("view_quilt")]),
      _vm._v(" Abrechnungsart der Leistung"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }