var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "bp-box is-add-request" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "main",
        [
          _vm.isLoading === false
            ? [
                _vm.requests.length === 0 ||
                (_vm.requests.length === 1 &&
                  _vm.requests[0].id === _vm.request.id)
                  ? _c("div", { staticClass: "is-empty" }, [
                      _vm._v("Es gibt keine Anfragen"),
                    ])
                  : _c(
                      "table",
                      _vm._l(_vm.requests, function (request) {
                        return !_vm.isAlreadyInPackage(request.id)
                          ? _c("tr", { key: request.id }, [
                              _c("td", [
                                _c(
                                  "label",
                                  {
                                    staticClass: "checkbox",
                                    attrs: {
                                      title:
                                        "Wird bereits im Paket abgerechnet",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      attrs: {
                                        type: "checkbox",
                                        disabled: _vm.isAlreadyInPackage(
                                          request.id
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.checked(request.id, $event)
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    request.requestType === "hotel"
                                      ? _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("home")]
                                        )
                                      : request.requestType === "ferry"
                                      ? _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("directions_boat")]
                                        )
                                      : request.requestType === "airline"
                                      ? _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("airplanemode_active")]
                                        )
                                      : request.requestType === "train"
                                      ? _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("directions_train")]
                                        )
                                      : request.requestType === "other"
                                      ? _c("other-service-icons", {
                                          attrs: {
                                            type: request.otherServiceType
                                              .category,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(request.info.title) +
                                        "\n                            "
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  request.info.provider.name
                                    ? [
                                        _c(
                                          "i",
                                          {
                                            staticClass: "material-icons",
                                            attrs: { title: "Provider name" },
                                          },
                                          [_vm._v("business")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(request.info.provider.name) +
                                            "\n                            "
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  request.requestType === "hotel"
                                    ? [
                                        _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("local_dining")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getValueWithKey({
                                                key: request.board,
                                                optionList: _vm.boardList,
                                              })
                                            )
                                        ),
                                      ]
                                    : request.ferry && request.type === "night"
                                    ? [
                                        _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("brightness_4")]
                                        ),
                                        _vm._v(" Nachtfähre"),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "i",
                                  {
                                    staticClass: "material-icons",
                                    attrs: { title: "Status" },
                                  },
                                  [_vm._v("map")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getValueWithKey({
                                        key: request.requestStatus.status,
                                        optionList: _vm.requestStatuses,
                                      })
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                            ])
                          : _vm._e()
                      }),
                      0
                    ),
              ]
            : _c("loading"),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "button is-large",
        on: {
          click: function ($event) {
            return _vm.$emit("cancel")
          },
        },
      },
      [
        _c("i", { staticClass: "material-icons" }, [_vm._v("close")]),
        _vm._v(" Abbrechen"),
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "button is-primary is-large",
        class: { "is-loading": _vm.isSaving },
        on: { click: _vm.save },
      },
      [
        _c("i", { staticClass: "material-icons" }, [_vm._v("check")]),
        _vm._v(" Hinzufügen"),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("i", { staticClass: "material-icons" }, [
        _vm._v("format_list_numbered"),
      ]),
      _vm._v(" Leistung"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }