var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Zugverbindung  bearbeiten",
        createTitle: "Neue Zugverbindung hinzufügen",
        isLoading: _vm.isLoading,
      },
    },
    [
      _c(
        "table",
        [
          _vm.providerType === "provider"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Buchung:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      [
                        _c("option", { attrs: { value: "direct" } }, [
                          _vm._v("direkt"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "agency" } }, [
                          _vm._v("über Agentur"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.providerType === "agency"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Buchung:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      [
                        _c("option", { attrs: { value: "direct" } }, [
                          _vm._v("No-Name"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "train" } }, [
                          _vm._v("bestimmter Zug"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "agency"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Agentur:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("async-select", {
                      attrs: { api: "agencies" },
                      model: {
                        value: _vm.agency,
                        callback: function ($$v) {
                          _vm.agency = $$v
                        },
                        expression: "agency",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "train"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Zuggesellschaft:"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("async-select", {
                      attrs: { api: "trains" },
                      model: {
                        value: _vm.train,
                        callback: function ($$v) {
                          _vm.train = $$v
                        },
                        expression: "train",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "startTrainStation" } },
                [_vm._v("Abfahrtsbahnhof:")]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("async-select", {
                    attrs: {
                      api: "train_stations",
                      "custom-label": (item) =>
                        item && item.name
                          ? item.name + " (" + item.place.name + ")"
                          : "",
                      crudLinkTitle: "Fügen Sie einen neuen Bahnhof hinzu",
                    },
                    model: {
                      value: _vm.form.startTrainStation,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "startTrainStation", $$v)
                      },
                      expression: "form.startTrainStation",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "endTrainStation" } },
                [_vm._v("Ankunftsbahnhof:")]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("async-select", {
                    attrs: {
                      api: "train_stations",
                      "custom-label": (item) =>
                        item && item.name
                          ? item.name + " (" + item.place.name + ")"
                          : "",
                      crudLinkTitle: "Fügen Sie einen neuen Bahnhof hinzu",
                    },
                    model: {
                      value: _vm.form.endTrainStation,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "endTrainStation", $$v)
                      },
                      expression: "form.endTrainStation",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("service-day-time-field", {
            attrs: {
              label: "Zugzeiten",
              fields: ["startTimeOfDay", "endTimeOfDay", "checkInTimeOfDay"],
            },
            model: {
              value: _vm.form.serviceDayTimes,
              callback: function ($$v) {
                _vm.$set(_vm.form, "serviceDayTimes", $$v)
              },
              expression: "form.serviceDayTimes",
            },
          }),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "type" } }, [
                _vm._v("Art:"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "toggle-switch",
                  {
                    model: {
                      value: _vm.form.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "type", $$v)
                      },
                      expression: "form.type",
                    },
                  },
                  [
                    _c("option", { attrs: { value: "day" } }, [
                      _vm._v("Tagzug"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "night" } }, [
                      _vm._v("Nachtzug"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [
                _vm._v("Zugart (oder leer):"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _vm.options.trainLabels.trainTypes
                  ? _c("multiselect", {
                      attrs: {
                        "track-by": "key",
                        label: "value",
                        options: _vm.objectToKeyValueArray(
                          _vm.options.trainLabels.trainTypes
                        ),
                      },
                      model: {
                        value: _vm.trainType,
                        callback: function ($$v) {
                          _vm.trainType = $$v
                        },
                        expression: "trainType",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.form.type === "day"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Wagen (oder leer):"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.options.trainLabels.wagonTypes
                      ? _c("multiselect", {
                          attrs: {
                            "track-by": "key",
                            label: "value",
                            options: _vm.objectToKeyValueArray(
                              _vm.options.trainLabels.wagonTypes
                            ),
                          },
                          model: {
                            value: _vm.wagonType,
                            callback: function ($$v) {
                              _vm.wagonType = $$v
                            },
                            expression: "wagonType",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasOwnRouteDescription
            ? _c("tr", [
                _c("th", { staticClass: "is-top" }, [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "description" } },
                    [_vm._v("Routenbeschreibung:")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "error-wrapper",
                      { attrs: { message: _vm.errors.routeDescription } },
                      [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.routeDescription,
                              expression: "form.routeDescription",
                            },
                          ],
                          staticClass: "textarea",
                          attrs: { id: "description", rows: "4" },
                          domProps: { value: _vm.form.routeDescription },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "routeDescription",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.form.id
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Option:")]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "control" }, [
                    _c("label", { staticClass: "checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.return,
                            expression: "form.return",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          value: true,
                          checked: Array.isArray(_vm.form.return)
                            ? _vm._i(_vm.form.return, true) > -1
                            : _vm.form.return,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.form.return,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = true,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.form,
                                    "return",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.form,
                                    "return",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.form, "return", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Rückfahrt autmoatisch hinzufügen")]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }