<template>
    <user-image v-if="value" v-bind:user="value" />
</template>

<script>
import UserImage from '@components/UserImage';
import Cell from './cell';

export default {
    mixins: [ Cell ],

    props: {
        value: {
            type: [Object, String]
        }
    },

    components: {
        UserImage
    }
}
</script>
