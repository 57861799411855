var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.checkStatus
    ? _c("div", { staticClass: "tags" }, [
        _c("div", { staticClass: "tag is-white" }, [
          _vm._v(_vm._s(_vm.checkStatus)),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }