<template>
    <modal-form
        editTitle="Fährverbindung  bearbeiten"
        createTitle="Neue Fährverbindung hinzufügen"
    >
        <table>
            <tr>
                <th><label for="startAirport" class="label">Flughafen Abflug:</label></th>
                <td>
                    <div class="control">
                        <async-select
                            api     = "airports"
                            v-model = "form.startAirport"
                            crudLinkTitle = "Fügen Sie einen neuen Flughafen hinzu"
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="endAirport" class="label">Flughafen Ankunft:</label></th>
                <td>
                    <div class="control">
                         <async-select
                            api     = "airports"
                            v-model = "form.endAirport"
                            crudLinkTitle = "Fügen Sie einen neuen Flughafen hinzu"
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="!form.id">
                <th><label class="label">Option:</label></th>
                <td>
                    <div class="control">
                        <label class="checkbox">
                            <input type="checkbox" v-bind:value="true" v-model="form.return">Rückflug autmoatisch hinzufügen
                        </label>
                    </div>
                </td>
            </tr>
        </table>
    </modal-form>
</template>



<script>
import TabForm from '../form';


export default {
    mixins: [ TabForm ],


    form: {
        startAirport: null,
        endAirport: null,
        option: '',
        return: ''
    },


    property: 'airlineRoutes'
}
</script>
