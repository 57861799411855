var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", { staticClass: "crud-prices" }, [
      _c("div", { staticClass: "panel" }, [
        _c("div", { staticClass: "panel-heading" }, [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button is-icon",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.$emit("reset")
                },
              },
            },
            [_vm._v("→ Alle")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "panel-block" }, [
          _c(
            "div",
            { staticClass: "panel-content" },
            [_vm.isLoading ? _c("loading") : _vm._t("default")],
            2
          ),
        ]),
        _vm._v(" "),
        _c("footer", [
          _c("div", { staticClass: "level" }, [
            _c(
              "button",
              {
                staticClass: "button",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("Abbrechen")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button is-primary",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("save")
                  },
                },
              },
              [_vm._v("Preise übernehmen")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }