<template>
    <row>
        <template slot="type">
            <i class="material-icons">subdirectory_arrow_right</i>
        </template>
        <template slot="date">
            <template v-if="!included">Im Preis inkludieren:</template>
            <template v-else>bereits inkludiert</template>
        </template>
        <template slot="nights">-</template>
        <template slot="name">
                <multiselect
                    v-bind:options="options"
                    v-model="airlinePrice"
                    label="label"
                    track-by="id"
                    class="is-medium"
                    placeholder="Nichts"
                />

        </template>

        <span slot="buyPrice" class="empty">-</span>
        <span slot="sellPrice" class="empty">-</span>
        <span slot="profit" class="empty">-</span>
        <span slot="margin" class="empty">-</span>
    </row>
</template>

<script>
import price from './price';
import { Multiselect } from '@components/form';
import Template from "@orders/subpages/TripDetails/General/Template";

 export default {
    mixins: [ price ],

    props: {
        included: {
            type: Boolean,
            default: false,
        }
    },

    components: {Template, Multiselect },

    computed: {
        placeholder: function () { return this.price.placeholder },

        airlinePrices: function () { return this.$store.getters.notIncludedPrices.airlinePrices },

        airlinePrice: {
            get: function () {
                if (this.price.airlinePrice) {
                    return this.options.find(option => option.id === this.price.airlinePrice )
                } else {
                    return null;
                }
            },
            set: function (value) {
                this.updatePrice({ airlinePrice: !!value ? value.id : null })
            }
        },

        otherFlights: function () {
            return this.airlinePrices
                .filter(flight => flight.placeholder.id !== this.placeholder.id)
                .filter(flight => flight.id)
        },

        options: function () {
            return this.otherFlights
                .map(flight => ({
                    id: flight.id,
                    label: flight.placeholder.routeDescription
                }))
        }
    },
}
</script>
