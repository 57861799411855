<template>
    <modal-form
        editTitle="Zugpreis bearbeiten"
        createTitle="Neuen Zugpreis hinzufügen"
    >

        <table>
            <template v-if="initialValue">
                <tr>
                    <th><label class="label">Preis:</label></th>
                    <td>
                        <toggle-switch v-model="priceType">
                            <option value="calculation">Kalkulation</option>
                            <option value="provider">Anbieter</option>
                            <option value="agency">Agentur</option>
                        </toggle-switch>
                    </td>
                </tr>
                <tr v-if="priceType === 'agency'">
                    <th><label class="label">Agentur:</label></th>
                    <td>
                        <async-select
                            api="agencies"
                            v-model="agency"
                        />
                    </td>
                </tr>
                <tr v-if="priceType === 'provider' || priceType === 'agency'">
                    <th><label class="label">Zuggesellschaft:</label></th>
                    <td>
                        <async-select
                            api="trains"
                            v-model="train"
                        />
                    </td>
                </tr>
            </template>
            <tr>
                <th><label class="label">Bezeichnung:</label></th>
                <td>
                    <toggle-switch v-model="ownName">
                        <option :value="true">Individuell</option>
                        <option :value="false">Automatisch (nach Datum)</option>
                    </toggle-switch>
                </td>
            </tr>
            <tr v-if="ownName">
                <th><label class="label">Eigene Bezeichnung:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="name" class="input" v-model="form.name">
                    </div>
                </td>
            </tr>
            <tr>
                <th class="is-top">
                    <label class="label">
                        Zeitraum:
                        <i class="material-icons is-pointer" v-on:click="addPeriod" v-if="canAddPeriod">add_circle</i>
                    </label>
                </th>

                <td>
                    <!-- Time periods -->
                    <div class="level-left" v-for="period in timePeriods" v-bind:key="period.key">
                        <div class="flatpickr-combined level-item">
                            <input-date v-model="period.startAt" class="is-small"  />
                            <span>-</span>
                            <input-date v-model="period.endAt" class="is-small"  />
                        </div>
                        <div class="level-item">
                            <days v-model="period.days" picker/>
                        </div>
                        <i
                            v-if="timePeriods.length > 1"
                            class="material-icons is-pointer"
                            v-on:click="deletePeriod(period)"
                        >delete</i>
                    </div>
                </td>
            </tr>

            <service-day-time-field label="Gültig für" :fields="['startTimeOfDay', 'endTimeOfDay', 'checkInTimeOfDay']" v-model="form.serviceDayTimes" :predefined-day-times="predefinedDayTimes" />


            <!-- Preise -->
            <tr>
                <td colspan="2">
                    <h2>Preise</h2>
                </td>
            </tr>

            <tr>
                <th><label class="label">Überprüft:</label></th>
                <td>
                    <import-checked-field v-model="importChecked" />
                </td>
            </tr>

            <tr>
                <th><label class="label">Währung:</label></th>
                <td>
                    <multiselect
                        v-model="currency"
                        style="width: 70px"
                        :options="currencies"
                        :allow-empty="false"
                        placeholder=""
                    />
                </td>
            </tr>

            <template v-if="form.route && form.route.type === 'night'">
                <tr>
                    <th><label class="label">{{ getAccommodationLabel('second', 2, allCabinTypes)}}:</label></th>
                    <td><input-price v-model="doubleSecond" class="is-medium" placeholder="0,00" :prefix="currency"/></td>
                </tr>
                <tr>
                    <th><label class="label">{{ getAccommodationLabel('second', 1, allCabinTypes)}}:</label></th>
                    <td><input-price v-model="singleSecond" class="is-medium" placeholder="0,00" :prefix="currency"/></td>
                </tr>
            </template>
            <template v-if="form.route && form.route.type !== 'night'">
                <tr>
                    <th><label class="label">{{ getAccommodationLabel('second', 1, allCabinTypes)}}:</label></th>
                    <td><input-price v-model="singleSecond" class="is-medium" placeholder="0,00" :prefix="currency"/></td>
                </tr>
                <tr v-if="firstClass">
                    <th><label class="label">{{ getAccommodationLabel('first', 1, allCabinTypes)}}:</label></th>
                    <td><input-price v-model="singleFirst" class="is-medium" placeholder="0,00" :prefix="currency"/></td>
                </tr>
            </template>

            <template v-if="threeBedCabins">
                <tr>
                    <th><label class="label">{{ getAccommodationLabel('second', 3, allCabinTypes)}}:</label></th>
                    <td>
                        <div class="level-left">
                            <div class="level-item">
                                <input-price v-model="tripleSecond" class="is-medium" placeholder="0,00" :prefix="currency"/>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>

            <template v-if="fourBedCabins">
                <tr>
                    <th><label class="label">{{ getAccommodationLabel('second', 4, allCabinTypes)}}:</label></th>
                    <td>
                        <div class="level-left">
                            <div class="level-item">
                                <input-price v-model="quadrupleSecond" class="is-medium" placeholder="0,00" :prefix="currency"/>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>

            <template v-if="firstClass && form.route && form.route.type === 'night'">
                <tr>
                    <th><label class="label">{{ getAccommodationLabel('first', 2, allCabinTypes)}}:</label></th>
                    <td>
                        <div class="level-left">
                            <div class="level-item">
                                <input-price v-model="doubleFirst" class="is-medium" placeholder="0,00" :prefix="currency"/>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th><label class="label">{{ getAccommodationLabel('first', 1, allCabinTypes)}}:</label></th>
                    <td>
                        <div class="level-left">
                            <div class="level-item">
                                <input-price v-model="singleFirst" class="is-medium" placeholder="0,00" :prefix="currency"/>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr v-if="threeBedCabins">
                    <th><label class="label">{{ getAccommodationLabel('first', 3, allCabinTypes)}}:</label></th>
                    <td>
                        <div class="level-left">
                            <div class="level-item">
                                <input-price v-model="tripleFirst" class="is-medium" placeholder="0,00" :prefix="currency"/>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr v-if="fourBedCabins">
                    <th><label class="label">{{ getAccommodationLabel('first', 4, allCabinTypes)}}:</label></th>
                    <td>
                        <div class="level-left">
                            <div class="level-item">
                                <input-price v-model="quadrupleFirst" class="is-medium" placeholder="0,00" :prefix="currency"/>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>

            <tr v-if="!threeBedCabins && form.route && form.route.type === 'night'">
                <th>
                </th>
                <td>
                    <button class="button" @click.prevent="threeBedCabins = true">
                        <i class="material-icons">add_circle</i> 3-Bett Zugabteil
                    </button>
                </td>
            </tr>

            <tr v-if="!fourBedCabins && form.route && form.route.type === 'night'">
                <th>
                </th>
                <td>
                    <button class="button" @click.prevent="fourBedCabins = true">
                        <i class="material-icons">add_circle</i> 4-Bett Zugabteil
                    </button>
                </td>
            </tr>
            <tr v-if="!firstClass">
                <th>
                </th>
                <td>
                    <button class="button" @click.prevent="firstClass = true">
                        <i class="material-icons">add_circle</i> 1. Klasse
                    </button>
                </td>
            </tr>

            <tr v-if="existingPrices.length > 0">
                <td class="alert is-danger"></td>
                <td>
                    <div class="alert is-danger" >
                        <p>
                            <strong>Preise überschreiben?</strong>
                        </p>
                        <p>
                            Im angegebenen Preise existieren bereits folgende Preise, die beim erneuten Klick auf Speichern überschrieben werden:
                        </p>
                        <ul>
                            <li v-for="price in existingPrices">{{priceTitle(price)}}</li>
                        </ul>
                    </div>
                </td>
            </tr>

        </table>
    </modal-form>
</template>

<script>
import has from 'lodash/has';
import Days from '@components/Days';
import { InputDate, DateTime, InputPrice, ToggleSwitch, AsyncSelect } from '@components/form';
import { numbersToDays, getAccommodationLabel } from '@utilities/functions';
import TabForm from '../../form'
import { importCheckedMixin, importCheckedField, serviceDayTimeField, serviceDayTimeMixin  } from '@utilities/mixins';
import {
    getTrainRoutes,
    addTrainRoute,
    getTrainPriceGroups, providerPrices, getFerryPlaceholder, getTrainPlaceholder,
} from '@api'
import moment from "moment";
import Template from "@orders/subpages/TripDetails/General/Template";
import {notifySuccess} from "@components/Notification";


export default {
    mixins: [ TabForm, importCheckedMixin, serviceDayTimeMixin ],

    props: {
        providerType: {
            type: String,
            required: true
        }
    },

    components: {
        Template,
        Days,
        InputDate,
        InputPrice,
        DateTime,
        ToggleSwitch,
        importCheckedField,
        serviceDayTimeField,
        AsyncSelect
    },

    form: {
        route: null,
        name: '',
        serviceDayTimes: [],
        providerPrices: [],
        timePeriods: [],
    },

    data: function () {
        return {
            existingPrices: [],
            ownName: false,
            threeBedCabins: false,
            fourBedCabins: false,
            firstClass: false,
            currency: this.$store.state.data.mainCurrency ? this.$store.state.data.mainCurrency : 'EUR',
            priceType: 'calculation',
        }
    },

    computed: {
        predefinedDayTimes: function () {
            return this.form.route && this.form.route.serviceDayTimes ? this.form.route.serviceDayTimes.map(detail => {
                detail.key = detail.id;
                delete detail.id;
                return detail;
            }) : [];
        },

        currencies: function () {
            return this.options.potentialCurrencies ? this.options.potentialCurrencies : [];
        },

        timePeriods: function () {
            this.afterDataLoad();
            this.setCurrency();
            return this.form.timePeriods ? this.form.timePeriods : []
        },

        dataCheckedAtCheck: function() {
            if(this.form.route){
                this.afterDataLoad();
                return this.form.dataCheckedAt ? moment(this.form.dataCheckedAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') : null;
            }
            return null
        },

        canAddPeriod: function () {
            return this.timePeriods
                .filter(({startAt, endAt}) => !startAt || !endAt)
                .length === 0
        },

        agency: {
            get: function () { return this.form.route.agency && !!this.form.route.agency.id ? this.form.route.agency : null },
            set: function (agency) { this.form.route.agency = agency }
        },

        train: {
            get: function () { return this.form.route.train && !!this.form.route.train.id ? this.form.route.train : null },
            set: function (provider) { this.form.route.train = provider }
        },


        // Prices
        prices: function () { return has(this.form, 'providerPrices') ? this.form.providerPrices : [] },

        doubleS: function () {return findCabinTypePrice(this.prices, {type: 'second', persons: 2}) },
        doubleSecond: {
            get: function () { return this.doubleS ? this.doubleS.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'second', persons: 2}, price}) }
        },

        doubleF: function () {return findCabinTypePrice(this.prices, {type: 'first', persons: 2}) },
        doubleFirst: {
            get: function () { return this.doubleF ? this.doubleF.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'first', persons: 2}, price}) }
        },

        singleS: function () { return findCabinTypePrice(this.prices, {type: 'second', persons: 1}) },
        singleSecond: {
            get: function () { return this.singleS ? this.singleS.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'second', persons: 1}, price}) }
        },

        singleF: function () { return findCabinTypePrice(this.prices, {type: 'first', persons: 1}) },
        singleFirst: {
            get: function () { return this.singleF ? this.singleF.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'first', persons: 1}, price}) }
        },

        tripleS: function () {return findCabinTypePrice(this.prices, {type: 'second', persons: 3}) },
        tripleSecond: {
            get: function () { return this.tripleS ? this.tripleS.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'second', persons: 3}, price}) }
        },

        tripleF: function () {return findCabinTypePrice(this.prices, {type: 'first', persons: 3}) },
        tripleFirst: {
            get: function () { return this.tripleF ? this.tripleF.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'first', persons: 3}, price}) }
        },

        quadrupleS: function () {return findCabinTypePrice(this.prices, {type: 'second', persons: 4}) },
        quadrupleSecond: {
            get: function () { return this.quadrupleS ? this.quadrupleS.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'second', persons: 4}, price}) }
        },

        quadrupleF: function () {return findCabinTypePrice(this.prices, {type: 'first', persons: 4}) },
        quadrupleFirst: {
            get: function () { return this.quadrupleF ? this.quadrupleF.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'first', persons: 4}, price}) }
        },


        allCabinTypes: function () {
            if(this.form.route && this.form.route.type === 'night') {
                return (this.options)
                    ? this.options.trainCabinTypes
                    : []
            }
            return (this.options)
                ? this.options.dayTrainCabinTypes
                : []

        },

    },

    methods: {
        getAccommodationLabel,

        priceTitle: function (price) {
            if(this.form.route && this.form.route.type === 'night'){
                return price.timePeriods.map(item => item.startAt + ' - ' + item.endAt).join(' | ') + ' - EK im Zweibettabteil: ' + price.providerPrices.filter(item => item.cabinType && item.cabinType.cabinIndex === '2_second').map(item => item.price.formatted).join(' | ');
            }
            return price.timePeriods.map(item => item.startAt + ' - ' + item.endAt).join(' | ') + ' - Ticketpreis: ' + price.providerPrices.filter(item => item.cabinType && item.cabinType.cabinIndex === '1_second').map(item => item.price.formatted).join(' | ');
        },

        handleInitialValue: function(initialValue) {

            this.setSettings()
            this.open();

            if(this.initialValue.sellingPrice){
                this.form.name = this.initialValue.sellingPrice.subTitle;
                if(!this.form.name){
                    this.ownName = false;
                }
                this.form.timePeriods = this.initialValue.sellingPrice.seasonTimes.map(season => {
                    return {
                        days: [0,1,2,3,4,5,6],
                        key: season.id,
                        startAt: season.startAt,
                        endAt: season.endAt
                    }
                })
            } else {
                this.form.timePeriods = [{
                    days: [0,1,2,3,4,5,6],
                    startAt: null,
                    endAt: null,
                    key: 1
                }]
            }

            if(this.initialValue.price){

                getTrainPlaceholder(initialValue.price.placeholder.id, '?_groups[]=modal_read&_groups[]=order_placeholder_displayed_request_read').then(response => {
                    const {startTrainStation, endTrainStation, type, trainType, displayedRequest} = response.data;
                    let route = {
                        startTrainStation: startTrainStation,
                        endTrainStation: endTrainStation,
                        type: type,
                        trainType: trainType,
                        agency: displayedRequest ? displayedRequest.agency : null,
                        train: displayedRequest ? displayedRequest.train : null,
                    }

                    this.singleSecond = initialValue.price.secondSingleCabinPremiumBuyPrice.amount;
                    this.singleFirst = initialValue.price.firstSingleCabinPremiumBuyPrice.amount;


                    if(route.type === 'night'){
                        this.doubleSecond = initialValue.price.secondDoubleCabinBuyPrice.amount;
                        this.doubleFirst = initialValue.price.firstDoubleCabinBuyPrice.amount;
                    }
                    this.setCurrency();

                    this.form.route = route
                    if(route.agency){
                        this.priceType = 'agency';
                    } else if(route.train){
                        this.priceType = 'provider';
                    }
                })
            }


        },


        setCurrency: function() {
            if(this.double && this.double.price.currency){
                this.currency = this.double.price.currency;
            }
        },

        setSettings: function() {
            this.firstClass = this.singleF || this.doubleF || this.tripleF || this.quadrupleF
            this.threeBedCabins = this.tripleF || this.tripleS;
            this.fourBedCabins = this.quadrupleF || this.quadrupleS;
        },

        addPeriod: function () {
            this.form.timePeriods.push({
                startAt: null,
                endAt: null,
                days: [0,1,2,3,4,5,6],
                key: + new Date
            })
        },

        deletePeriod: function (period) {
            this.form.timePeriods = this.form.timePeriods
                .filter(({key}) => period.key !== key)
        },

        updatePrice: function ({price, cabinType, priceType}) {
            if (cabinType) {
                const priceIndex = this.form.providerPrices
                    .findIndex(price => cabinType.type === price.cabinType.type && cabinType.persons === price.cabinType.persons)
                if (priceIndex === -1) {
                    this.form.providerPrices.push({cabinType, price: {amount: price}, key: +new Date})
                } else {
                    this.form.providerPrices.splice(priceIndex, 1, {
                        ...this.form.providerPrices[priceIndex],
                        price: {amount: price},
                    })
                }
            } else {
                const priceIndex = this.form.providerPrices.findIndex(price => priceType === price.priceType)
                if (priceIndex === -1) {
                    this.form.providerPrices.push({
                        price: {amount: price},
                        priceType: priceType,
                        key: +new Date,
                        cabinType: null
                    })
                } else {
                    this.form.providerPrices.splice(priceIndex, 1, {
                        ...this.form.providerPrices[priceIndex],
                        price: {amount: price},
                        cabinType: null
                    })
                }
            }
        },

        getIDFromString: function(apiString) {
            if(typeof apiString === 'object'){
                return apiString.id;
            }
            let stringArray = apiString.split('/');
            return stringArray[stringArray.length - 1];
        },

        checkOverlappingPrices: function () {
            let params = {};
            if(this.existingPrices.length > 0){
                this.addPriceGroup();
            } else {
                let periods = [];
                this.form.timePeriods.forEach(period => {
                    periods.push(period, {...period, startAt: null})
                });
                Promise.all(periods.map(period => {
                    params = {
                        'route.id': this.getIDFromString(this.form.route),
                        'timePeriods.startAt[before]': period.endAt,
                        'timePeriods.endAt[after]': period.startAt,
                        'timePeriods.monday': period.monday.toString(),
                        'timePeriods.tuesday': period.tuesday.toString(),
                        'timePeriods.wednesday': period.wednesday.toString(),
                        'timePeriods.thursday': period.thursday.toString(),
                        'timePeriods.friday': period.friday.toString(),
                        'timePeriods.saturday ': period.saturday.toString() ,
                        'timePeriods.sunday': period.sunday.toString(),
                        ...!period.startAt && {'timePeriods.endAt[exists]': 'false'},
                        '_groups': ['provider_price_group:provider_price', 'provider_price', 'provider_price_group:price_time_period', 'price_time_period', 'cabin_type']
                    };
                    return getTrainPriceGroups(params)
                })).then(responses => {
                    this.existingPrices = [].concat(...(responses.map(response => response.data)));
                    if(this.existingPrices.length === 0){
                        this.addPriceGroup();
                    }
                })
            }
        },


        addPriceGroup: function () {
            providerPrices.trainPriceGroups.post({
                ...this.form,
                ...{route: this.form.route.id ? `/api/train_routes/${this.form.route.id}` : this.form.route},
            })
                .then(response => {
                    notifySuccess('Die Preise wurden erfolgreich hinzugefügt!')
                    this.deletePriceGroups(this.existingPrices);
                    this.$emit('submit')
                    this.isLoading = false;
                    this.visible = false;

                }, error => {
                    this.notifyError("Die Preise konnte nicht hinzugefügt werden!")
                    this.isLoading = false;
                })
        },

        deletePriceGroups: function (groups) {
            groups.map(group => providerPrices.trainPriceGroups.delete(group.id).then(response => {}, error => {
                this.notifyError("Die bestehenden Preise konnten nicht überschrieben werden. Doppelte Preise bitte manuell ändern!")
            }))
        },

        editPriceGroup: function () {
            providerPrices.trainPriceGroups.put(
                {
                    ...this.form,
                    ...{route: this.form.route.id ? `/api/train_routes/${this.form.route.id}` : this.form.route},
                })
                .then(response => {
                    notifySuccess('Die Preise wurden erfolgreich aktualisiert!')
                    this.isLoading = false;
                    this.$emit('submit')
                    this.visible = false;

                }, error => {
                    this.notifyError("Die Preise konnte nicht aktualisiert werden!")
                    this.isLoading = false;
                })
        },

        handleSubmit: function () {

            this.isLoading = true;
            this.form.timePeriods = this.form.timePeriods.map(period => { return {...period, ...numbersToDays(period.days)}})
            this.form.dataCheckedAt = this.dataCheckedAtCheck;
            this.form.name = this.ownName ? this.form.name : '';

                this.form.providerPrices = this.form.providerPrices.map(price => {
                return Object.assign({}, price, {
                    price: {
                        amount: price.price.amount,
                        currency: this.currency
                    }
                })
            });
            if(this.form.id){
                this.editPriceGroup();
            } else if(this.initialValue && this.form.route.startTrainStation) {

                if(this.priceType === 'calculation'){
                    this.form.route.train = null;
                    this.form.route.agency = null;
                }
                if(this.priceType === 'provider'){
                    this.form.route.agency = null;
                }

                //try to find route
                getTrainRoutes({
                    'startTrainStation.id': this.form.route.startTrainStation.id,
                    'endTrainStation.id': this.form.route.endTrainStation.id,
                    type: this.form.route.type,
                    ...this.form.route.agency && {'agency.id': this.form.route.agency.id},
                    ...this.form.route.train && {'train.id': this.form.route.train.id},
                    ...!this.form.route.agency && {'agency[exists]': false},
                    ...!this.form.route.train && {'train[exists]': false},
                }).then(response => {
                   let routeId = response.data.length > 0 ? response.data[0].id : null;
                    if(routeId){
                        this.form.route.id = routeId;
                        this.checkOverlappingPrices();
                    } else {
                        this.form.route.startTrainStation = '/api/train_stations/' + this.form.route.startTrainStation.id;
                        this.form.route.endTrainStation = '/api/train_stations/' + this.form.route.endTrainStation.id;
                        this.form.route.agency = this.form.route.agency ? '/api/agencies/' + this.form.route.agency.id : null;
                        this.form.route.train = this.form.route.train ? '/api/trains/' + this.form.route.train.id : null;
                        addTrainRoute(this.form.route).then(response => {
                            this.form.route = `/api/train_routes/${response.data.id}`;
                            this.addPriceGroup();
                        })
                    }
                });
            } else {
                this.addPriceGroup();
            }
        }
    },
}

const findCabinTypePrice = function (prices, {type, persons}) {
    return prices.find(price => {
        if (!!price && has(price, 'cabinType') && price.cabinType) {
            const { cabinType } = price;
            return cabinType.persons === persons && cabinType.type === type
        } else {
            return false
        }
    })
}

const findPriceTypePrice = function (prices, priceType) {
    return prices.find(price => price.priceType === priceType)
}

</script>
