<template>
    <div>
        <div class="bp-box is-info">
            <header><i class="material-icons">info</i> Informationen zur Anfrage</header>

            <main>
                <div class="is-one-row" v-if="editMode || !request.airline">
                    <i class="material-icons">business</i> Airline:
                    <airline-select
                        class="has-margin-left"
                        v-model                 = "request.airline"
                        v-bind:placeholderInfo  = "{
                                startAirport:  request.startAirport,
                                endAirport:    request.endAirport
                            }"
                        v-on:updateStartAirport = "value => request.startAirport = value"
                        v-on:updateEndAirport   = "value => request.endAirport = value"
                        :show-airport-selects="false"
                    ></airline-select>

                    <agency-select
                        class="has-margin-left"
                        v-bind:value    = "request.agency"
                        v-bind:provider = "null"
                        prefill
                        text            = "Agentur"
                        v-on:update     = "agency => request.agency = agency"
                    ></agency-select>
                </div>

                <div class="is-one-row">
                    <div class="is-300">
                        <i class="material-icons">import_contacts</i> Status:
                        <div class="select has-margin-right">
                            <select v-model="request.requestStatus.status">
                                <template v-for="status in requestStatuses">
                                    <option v-bind:value="status.key">{{ status.value }}</option>
                                </template>
                            </select>
                        </div>
                    </div>


                    <div class="is-300">
                        <i class="material-icons">notifications_none</i> Termine:
                        <multiselect
                            class="is-shorter"
                            v-if="request.todos.length > 1"
                            v-model="request.optionTodo"
                            :options="request.todos"
                            label="name"
                            track-by="id"
                        />
                        <datepicker
                            class="has-margin-right"
                            v-bind:inputClass="['is-date']"
                            v-bind:config="{
                            dateFormat: 'd.m.Y H:i:S',
                            wrap: true,
                            parseDate:  function (dateStr) { return moment(dateStr, 'DD.MM.YYYY hh:mm:ss').toDate(); },
                            formatDate: function (dateObj) { return moment(dateObj).format('DD.MM.YYYY hh:mm:ss'); },
                            allowInput: true,
                            altInput: true,
                        }"
                            v-model="deadline"
                            placeholder="Deadline"
                        />
                    </div>

                    <div >
                        <i class="material-icons">image_aspect_ratio</i> Flugnummer
                        <input class="input has-margin-right" type="text" v-model="request.flightNumber" />
                    </div>

                    <div>
                        <i class="material-icons">image_aspect_ratio</i> PNR
                        <input class="input has-margin-right" type="text" v-model="request.ticketNr" />
                    </div>

                    <div>
                        <i class="material-icons">local_dining</i> Verpflegung
                        <input class="input has-margin-right" type="text" v-model="request.foodOnBoard" />
                    </div>

                    <div>
                        <i class="material-icons">contact_phone</i> Ansprechpartner:
                        <multiselect
                            class="is-shorter"
                            style="min-width: 180px"
                            v-model="request.requestContact"
                            v-bind:options="this.request.agency ? this.agencyContactOptions : this.providerContactOptions"
                            track-by="id"
                            :custom-label="contact => `${contact.fullName}`"
                            :to-be-copied="false"
                        >
                        </multiselect>
                    </div>
                </div>
                <div class="is-last-row" v-if="showAssignedContactAddress">

                    <div>
                        <i class="material-icons">list</i> Für Kunde sichtbar:
                        <button
                            title="Adresse anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('address')}"
                            @click="setVisibleContactDetails('address')"
                        ><i class="material-icons">home</i></button>
                    </div>

                    <div v-if="request.visibleContactDetails.includes('address')">
                        <i class="material-icons">home</i> Adresse
                        <multiselect
                            class="is-shorter"
                            style="min-width: 150px"
                            v-model="request.assignedAddress"
                            v-bind:options="providerAddressOptions"
                            track-by="id"
                            :custom-label="address => (address.title ? address.title + ': ' + address.streetAddress : address.streetAddress).substring(0,50)"
                            :to-be-copied="false"
                            placeholder="Keine Adresse"

                        >
                        </multiselect>
                    </div>
                    <div v-if="request.visibleContactDetails.includes('address')">
                        <i class="material-icons">list</i> Kontaktdetails sichtbar:
                        <button
                            title="Festnetz anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('phoneFixed')}"
                            @click="setVisibleContactDetails('phoneFixed')"
                        ><i class="material-icons">phone</i></button>
                        <button
                            title="Mobile anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('phoneMobile')}"
                            @click="setVisibleContactDetails('phoneMobile')"
                        ><i class="material-icons">smartphone</i></button>
                    </div>



                </div>
            </main>
        </div>


        <div class="columns">
            <div class="column">
                <div class="bp-box is-tickets">
                    <header>
                        <div><i class="material-icons" title="Tickets">airplanemode_active</i> Airline</div>
                        <div>
                            <i class="material-icons">check_circle</i>
                            <i class="material-icons">help</i>
                        </div>
                        <div><button v-if="!request.package" title="Extraticket für Reisebegleitung" v-on:click="addExtra"><i class="material-icons">person_add</i></button></div>
                    </header>

                    <main>
                        <div>
                            <div><i class="material-icons" title="Tickets">airplanemode_active</i> Tickets</div>
                            <div>
                                <input
                                    class="input"
                                    type="text"
                                    v-model.number="request.contingent.reservedAmount"
                                    v-on:focus="$event.target.select()"
                                />
                                <input
                                    class="input"
                                    type="text"
                                    v-model.number="request.contingent.askedAmount"
                                    v-on:focus="$event.target.select()"
                                />
                            </div>
                            <div><button
                                class="button"
                                title="Copy value"
                                v-on:click="request.contingent.reservedAmount = request.contingent.askedAmount"
                            >
                                <i class="material-icons">check_circle</i>
                                <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                                <i class="material-icons">help</i>
                            </button></div>
                        </div>

                        <div class="extra-people" v-if="hasExtra && !request.package">
                            <div>
                                <i class="material-icons">person_add</i>
                                <input
                                    class="input"
                                    type="text"
                                    v-model="request.contingent.extraDescription"
                                />
                                <button class="btn-delete" title="Löschen" v-on:click="deleteExtra" tabindex="-1"><i class="material-icons">delete</i></button>
                            </div>
                            <div>
                                <div class="control"><input
                                    class="input"
                                    type="text"
                                    v-model.number="request.contingent.extraReservedAmount"
                                    v-on:focus="$event.target.select()"
                                /></div>
                                <div class="control"><input
                                    class="input"
                                    type="text"
                                    v-model.number="request.contingent.extraAskedAmount"
                                    v-on:focus="$event.target.select()"
                                /></div>
                            </div>
                            <div><button
                                class="button"
                                title="Copy value"
                                v-on:click="request.contingent.extraReservedAmount = request.contingent.extraAskedAmount"
                            >
                                <i class="material-icons">check_circle</i>
                                <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                                <i class="material-icons">help</i>
                            </button></div>
                        </div>
                    </main>
                </div>
            </div>
        </div>


        <div class="columns">
            <div class="column is-one-third">
                <tip-tap v-model="request.notes" />
            </div>
            <div class="column">
                <item-prices
                    v-if="request.package && request.package.itemPrices"
                    v-bind:isWithoutPrice="false"
                    :is-package="true"
                    v-model="request.package.itemPrices"
                    v-bind:provider="provider"
                ></item-prices>
                <item-prices
                    v-else
                    v-bind:isWithoutPrice="false"
                    v-model="request.itemPrices"
                    v-bind:provider="provider"
                ></item-prices>
            </div>
        </div>


        <p class="explanation">
            <i class="material-icons">check_circle</i> = bestätigtes Kontingent
            <i class="material-icons">help</i> = angefragtes Kontingent
            <i class="material-icons">person_add</i> = Extrazimmer für Busfahrer, Reiseleiter, etc.
        </p>
    </div>
</template>



<script>
import mixins        from './mixins.js';
import AirlineSelect from '../components/AirlineSelect';


export default {
    mixins: [mixins],


    data: function () {
        return {
            hasExtra: false
        };
    },


    computed: {
        provider: function () { return this.request.airline }
    },


    methods: {
        addExtra: function () {
            this.request.contingent.extraDescription    = '';
            this.request.contingent.extraReservedAmount = 0;
            this.request.contingent.extraAskedAmount    = 0;
            this.hasExtra = true
        },


        deleteExtra: function () {
            this.request.contingent.extraDescription    = null;
            this.request.contingent.extraReservedAmount = null;
            this.request.contingent.extraAskedAmount    = null;
            this.hasExtra = false;
        }
    },


    created: function () {
        this.hasExtra = !!(this.request.contingent.extraAskedAmount ||
            this.request.contingent.extraReservedAmount);
    },
    mounted: function() {
        this.fetchContactOptions();
    },


    watch: {
        // Fixing empty strins to 0 (zero)
        'request.contingent.askedAmount': function () {
            if (this.request.contingent.askedAmount === '') {
                this.request.contingent.askedAmount = 0;
            }
        },


        // Fixing empty strins to 0 (zero)
        'request.contingent.reservedAmount': function () {
            if (this.request.contingent.reservedAmount === '') {
                this.request.contingent.reservedAmount = 0;
            }
        },


        // Fixing empty strins to 0 (zero)
        'request.contingent.extraAskedAmount': function () {
            if (this.request.contingent.extraAskedAmount === '') {
                this.request.contingent.extraAskedAmount = 0;
            }
        },


        // Fixing empty strins to 0 (zero)
        'request.contingent.extraReservedAmount': function () {
            if (this.request.contingent.extraReservedAmount === '') {
                this.request.contingent.extraReservedAmount = 0;
            }
        }
    },


    components: {
        AirlineSelect
    }
}
</script>
