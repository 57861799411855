<template>
    <div class="bp-box is-freeplaces is-flex">
        <header>
            <div>
                <i class="material-icons">no_sim</i> Freiplätze
                <button v-on:click="addFreePlace"><i aria-hidden="true" class="fa fa-plus-circle"></i></button>
            </div>
        </header>

        <main class="flex-grow">
            <div v-for="(freePlace, index) in localFreePlaces" v-bind:key="freePlace.id">
                <div>
                    <i class="material-icons">format_list_numbered</i>
                    {{ index + 1 }}. Freiplatz ab
                    <input
                        class="input"
                        type="text"
                        v-model.number="freePlace.numberOfPersons"
                        v-on:focus="$event.target.select()"
                    />
                </div>
                <div>Pax</div>
                <div>
                    <i class="material-icons">room_service</i>
                    Typ:
                    <div class="select">
                        <select v-model="freePlace.type">
                            <option v-if="hasHotel || hasFerry" value="double">Doppel</option>
                            <option v-if="hasHotel || hasFerry" value="single">Einzel</option>
                            <option v-if="!hasHotel && !hasFerry" value="other">Zusatzleistung</option>
                        </select>
                    </div>
                    <button class="btn-delete" title="Löschen" v-on:click="deleteFreePlace(index)"><i class="material-icons">delete</i></button>
                </div>
            </div>

            <div v-if="localFreePlaces.length === 0">Es wurden keine Freipläze vermerkt</div>
        </main>
    </div>
</template>



<script>
import axios from 'axios';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';

import { InputPrice } from '@components/form';
import { getFreePlaces } from '@api';

export default {
    props: {
        value:   { type: Array, required: true },
        request: { type: Object, required: true }
    },

    computed: {
        hasHotel: function () {
            if (this.request.package && this.request.package.id) {
                let requests = this.request.package.requests;
                for (let i = 0, len = requests.length; i < len; i++) {
                    if (requests[i].requestType === 'hotel') {
                        return true;
                    }
                }
                return false;

            } else {
                return this.request.requestType === 'hotel';
            }
        },


        hasFerry: function () {
            if (this.request.package && this.request.package.id) {
                let requests = this.request.package.requests;
                for (let i = 0, len = requests.length; i < len; i++) {
                    if (requests[i].requestType === 'ferry' && requests[i].type === 'night') {
                        return true;
                    }
                }
                return false;

            } else if (this.request.requestType === 'ferry' && this.request.type === 'night') {
                return true;

            } else {
                return false;
            }
        },


        hasOther: function () {
            if (this.request.package && this.request.package.id) {
                let requests = this.request.package.requests;
                for (let i = 0, len = requests.length; i < len; i++) {
                    if (requests[i].requestType === 'airline' ||
                        requests[i].requestType === 'other' ||
                        (requests[i].requestType === 'ferry' && requests[i].type === 'day') ||
                        (requests[i].requestType === 'train' && requests[i].type === 'day')
                    ) {
                        return true;
                    }
                }
                return false;

            } else if (this.request.requestType === 'airline' ||
                this.request.requestType === 'other' ||
                (this.request.requestType === 'ferry' && this.request.type === 'day') ||
                (requests[i].requestType === 'train' && requests[i].type === 'day')
            ) {
                return true;

            } else {
                return false;
            }
        },

        providerID: function () {
            if (this.hasFerry) {
                return this.request.ferry.id
            } else if (this.hasHotel) {
                return this.request.hotel.id;
            } else {
                return this.request.otherService.provider.id;
            }
        }
    },


    data: function () {
        return {
            localFreePlaces: this.sortItems(this.value),
            feID: 0,
        }
    },


    methods: {
        addFreePlace: function () {
            this.localFreePlaces.push({
                id: 'bp-' + this.feID++,
                freePlaces: 1,
                numberOfPersons: 1,
                type: this.hasHotel || this.hasFerry ? 'double' : 'other'
            });
        },


        deleteFreePlace: function (index) {
            this.localFreePlaces.splice(index, 1);
        },

        sortItems: function (freePlaces) {
            let copyFreePlaces = JSON.parse(JSON.stringify(freePlaces));
            return copyFreePlaces.sort((a, b) => a.numberOfPersons - b.numberOfPersons);
        }
    },


    watch: {
        localFreePlaces: {
            handler: function () {
                let copyFreePlaces = this.sortItems(this.localFreePlaces);

                // Need to remove custom IDs ('bp-number') used for vue listing
                copyFreePlaces = copyFreePlaces.map(item => {
                    if (item.id.toString()[0] === 'b') {
                        return _.omit(item, ['id']);

                    } else {
                        return item;
                    }
                });

                this.$emit('input', copyFreePlaces);
            },
            deep: true
        }
    },


    components: {
        InputPrice
    }
}
</script>
