var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bp-panel is-form-contingent" }, [
    _c("div", { staticClass: "panel-label" }, [_vm._v("Fähre - MBZ")]),
    _vm._v(" "),
    _c("header", [
      _vm._m(0),
      _vm._v(" "),
      _c("div", [
        !_vm.isOnlyAsked
          ? _c("i", { staticClass: "material-icons" }, [_vm._v("check_circle")])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("div", [
        _c(
          "button",
          {
            staticClass: "has-margin-top",
            attrs: { title: "Einen neuen Kabinentyp hinzufügen" },
            on: { click: _vm.addAccomodationType },
          },
          [
            _c("i", {
              staticClass: "fa fa-plus-circle",
              attrs: { "aria-hidden": "true" },
            }),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "main",
      [
        _vm._l(_vm.accomodations, function (accomodation, index) {
          return _c("div", { key: accomodation.id, staticClass: "row" }, [
            _vm.editIndex !== index
              ? _c("div", [
                  _c("span", [
                    _vm._v(
                      _vm._s(accomodation.type.persons) +
                        "-Bettkabine - " +
                        _vm._s(
                          _vm.getValueWithKey({
                            key: accomodation.type.type,
                            optionList: _vm.types,
                          })
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn-delete",
                        attrs: { title: "Löschen" },
                        on: {
                          click: function ($event) {
                            return _vm.removeAccomodation(accomodation)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("delete"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn-edit",
                        attrs: { title: "Editieren" },
                        on: {
                          click: function ($event) {
                            _vm.editIndex = index
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-pencil" })]
                    ),
                  ]),
                ])
              : _c(
                  "div",
                  { staticClass: "multiselect-is-small" },
                  [
                    _c("div", { staticClass: "select" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: accomodation.type.persons,
                              expression: "accomodation.type.persons",
                              modifiers: { number: true },
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return _vm._n(val)
                                })
                              _vm.$set(
                                accomodation.type,
                                "persons",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(Object.keys(_vm.typesForNumber), function (nr) {
                          return _c("option", { domProps: { value: nr } }, [
                            _vm._v(_vm._s(nr) + "x"),
                          ])
                        }),
                        0
                      ),
                    ]),
                    _vm._v(" "),
                    _c("multiselect", {
                      attrs: {
                        options: _vm.typesForNumber[accomodation.type.persons],
                        taggable: true,
                        placeholder: "Kabinentyp",
                        label: "value",
                        "track-by": "key",
                        "tag-placeholder": "Add this as new tag",
                        "select-label": "",
                        "deselect-label": "Löschen",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.accomodationTypeSelected(accomodation)
                        },
                        tag: (newTag) => {
                          _vm.addTag(newTag, accomodation)
                        },
                        close: _vm.closeMultiselect,
                      },
                      model: {
                        value: accomodation.type.type,
                        callback: function ($$v) {
                          _vm.$set(accomodation.type, "type", $$v)
                        },
                        expression: "accomodation.type.type",
                      },
                    }),
                  ],
                  1
                ),
            _vm._v(" "),
            _c("div", [
              !_vm.isOnlyAsked
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: accomodation.reservedAmount,
                        expression: "accomodation.reservedAmount",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "input is-small",
                    attrs: { type: "text" },
                    domProps: { value: accomodation.reservedAmount },
                    on: {
                      focus: function ($event) {
                        return $event.target.select()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          accomodation,
                          "reservedAmount",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: accomodation.askedAmount,
                    expression: "accomodation.askedAmount",
                    modifiers: { number: true },
                  },
                ],
                staticClass: "input is-small",
                attrs: { type: "text" },
                domProps: { value: accomodation.askedAmount },
                on: {
                  focus: function ($event) {
                    return $event.target.select()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      accomodation,
                      "askedAmount",
                      _vm._n($event.target.value)
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", [
              !_vm.isOnlyAsked
                ? _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { title: "Copy value" },
                      on: {
                        click: function ($event) {
                          return _vm.setValues(accomodation)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("check_circle"),
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fa fa-long-arrow-left",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("help"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ])
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "row",
            class: [_vm.accomodations.length > 0 ? "has-border-top" : ""],
          },
          [
            _vm._m(2),
            _vm._v(" "),
            _c("div", [
              !_vm.isOnlyAsked
                ? _c("span", [_vm._v(_vm._s(_vm.totalReserved))])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [_c("span", [_vm._v(_vm._s(_vm.totalAsk))])]),
            _vm._v(" "),
            _c("div"),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _vm._v("≥ 3 x "),
      _c("i", { staticClass: "material-icons" }, [_vm._v("directions_boat")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("help")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("b", [_vm._v("Mehrbettkabine - Gesamt")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }