var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "panel is-alt is-small is-day" }, [
    _c("div", { staticClass: "panel-heading" }, [
      _c("div", { staticClass: "level-left" }, [
        _vm._v("\n            " + _vm._s(_vm.heading) + "\n        "),
      ]),
      _vm._v(" "),
      _vm.isConcept
        ? _c(
            "div",
            { staticClass: "level-right" },
            [
              _vm.options.checkedStatusList
                ? _c("multiselect", {
                    attrs: {
                      "track-by": "key",
                      label: "value",
                      options: _vm.options.checkedStatusList,
                    },
                    model: {
                      value: _vm.checkStatus,
                      callback: function ($$v) {
                        _vm.checkStatus = $$v
                      },
                      expression: "checkStatus",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "panel-block" }, [
      _c(
        "div",
        { staticClass: "panel-content" },
        [
          !_vm.day.catalogTextChanged && !_vm.isConcept
            ? _c("div", {
                staticClass: "has-margin-top mb",
                domProps: { innerHTML: _vm._s(_vm.catalogText) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.day.catalogTextChanged || _vm.isConcept
            ? _c("tip-tap", {
                staticClass: "is-fullwidth is-smaller resizeable",
                attrs: {
                  resizeable: "",
                  isNotAlone: "",
                  menuItems: [
                    "fullscreen",
                    "bold",
                    "italic",
                    "underline",
                    "bullet_list",
                    "ordered_list",
                    "color",
                    "undo",
                  ],
                },
                on: { input: _vm.storeText },
                model: {
                  value: _vm.catalogText,
                  callback: function ($$v) {
                    _vm.catalogText = $$v
                  },
                  expression: "catalogText",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.showSearch && !_vm.isConcept
            ? _c("div", { staticClass: "level-left buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-large",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.initDaySearch.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Vorlage suchen")]
                ),
                _vm._v(" "),
                !_vm.day.catalogTextChanged
                  ? _c(
                      "button",
                      {
                        staticClass: "button is-large",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            _vm.day.catalogTextChanged = true
                          },
                        },
                      },
                      [_vm._v("Manuell bearbeiten")]
                    )
                  : _vm._e(),
              ])
            : !_vm.isConcept
            ? _c(
                "div",
                [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "search-day-concept is-gapless" },
                      [
                        _c("div", { staticClass: "column is-one-third" }, [
                          _c("div", { staticClass: "level-left" }, [
                            _c(
                              "i",
                              { staticClass: "material-icons level-item" },
                              [_vm._v("search")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.searchTerm,
                                  expression: "searchTerm",
                                },
                              ],
                              staticClass: "input",
                              attrs: {
                                type: "text",
                                placeholder: "Suche ein Konzept",
                                multiple: "",
                              },
                              domProps: { value: _vm.searchTerm },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.searchTerm = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "column is-one-third" },
                          [
                            _c("multiselect", {
                              attrs: {
                                options: this.options.checkedStatusList,
                                "track-by": "key",
                                label: "value",
                                multiple: true,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "selection",
                                  fn: function ({ values, search, isOpen }) {
                                    return [
                                      values.length &&
                                      values.length > 1 &&
                                      !isOpen
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "multiselect__single",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(values.length) +
                                                  " Stati ausgewählt"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.filters.statuses,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "statuses", $$v)
                                },
                                expression: "filters.statuses",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "column is-one-third" }, [
                          _c("div", { staticClass: "level-right" }, [
                            _c("div", { staticClass: "buttons has-addons" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "button is-large",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showSearch = false
                                    },
                                  },
                                },
                                [_vm._v("Abbrechen")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "button is-large is-primary",
                                  attrs: { disabled: !_vm.conceptSelected },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.replaceCatalogText.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Vorlage übernehmen")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("search-day-concept", {
                    staticClass: "is-gapless",
                    attrs: {
                      searchTerm: _vm.searchTerm,
                      destinations: [],
                      statuses: _vm.filters.statuses,
                    },
                    on: { input: (value) => (_vm.conceptSelected = value) },
                    scopedSlots: _vm._u([
                      {
                        key: "variation",
                        fn: function ({ concept }) {
                          return [
                            concept
                              ? _c("p", {
                                  staticClass: "nl2br",
                                  domProps: {
                                    innerHTML: _vm._s(concept.catalogText),
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }