<template>
    <div class="is-truncated is-250">
        <template v-for="provider in sortedProviders">

            <template v-if="provider.classification === '1'">
                <a v-if="provider.agency" v-bind:href="'/agencies/' + provider.agency.id" v-bind:title="provider.agency.name" target="_blank">{{ provider.agency.name }}</a>
                <template v-if="provider.agency && provider.provider"> - </template>
                <a v-if="provider.provider" v-bind:href="providerUrl(provider.provider)" v-bind:title="provider.provider.name" target="_blank">{{ provider.provider.name }}</a>
            </template>

            <button
                v-else
                class        = "concept-provider-btn"
                :class="'is-' + provider.classification"
                :title        = "getLabel(provider)"
                @click="openUrl(provider)"
            >{{ provider.classification }}</button>


        </template>


    </div>
</template>



<script>
export default {
    props: {
        providers:      { required: true },
    },

    computed: {
        sortedProviders: function () {
            return this.providers.sort((a,b) => a.classification - b.classification);
        }
    },

    methods: {
        openUrl: function (provider) {
            window.open(this.providerUrl(provider.provider), '_blank');
        },

        getLabel: function (item) {
            let label = '';
            if(item.agency){
                label += item.agency.name;
                if(item.provider){
                    label += ' - ';
                }
            }
            if(item.provider){
                label += item.provider.name;
            }
            return label;
        },

        providerUrl: function(provider) {
            if(provider.providerType === 'hotel'){
                return '/hotels/' + provider.id;
            }
            else if(provider.providerType === 'ferry'){
                return '/ferries/' + provider.id;
            }
            else if(provider.providerType === 'other'){
                return '/others/' + provider.id;
            }
            if(provider.providerType === 'airline'){
                return '/airlines/' + provider.id;
            }
            return '';
        }
    }
}
</script>
