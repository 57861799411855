var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is-zebra" },
    [
      _vm._l(_vm.sortedPrices, function (room) {
        return _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "cell" }, [
            _c(
              "span",
              { style: _vm.isStandardRoom(room) ? "font-weight: bold" : "" },
              [_vm._v(_vm._s(_vm.roomLabel(room)))]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cell" }),
          _vm._v(" "),
          _c("div", { staticClass: "cell" }, [
            _vm._v(_vm._s(_vm.roomPersons(room))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cell" }, [
            _vm._v(_vm._s(_vm._f("priceView")(_vm.roomPrice(room)))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cell" }, [
            _c("b", [_vm._v(_vm._s(_vm._f("priceView")(_vm.roomTotal(room))))]),
          ]),
        ])
      }),
      _vm._v(" "),
      _vm._l(_vm.sortedPrices, function (room) {
        return room.personsFreePlaces
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "cell" }, [
                _vm._v(_vm._s(_vm.freePlaceLabel(room))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }, [
                _vm._v(_vm._s(room.personsFreePlaces)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }, [
                _vm._v(
                  _vm._s(_vm._f("priceView")(-1 * room.absolutePrice.amount))
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }, [
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm._f("priceView")(-1 * room.totalPriceFreePlaces.amount)
                    )
                  ),
                ]),
              ]),
            ])
          : _vm._e()
      }),
      _vm._v(" "),
      _vm._l(_vm.calculations.itemPrices, function (item) {
        return item.billingType !== "info" && item.limitIsValid
          ? _c("div", { key: "item-" + item.id, staticClass: "row" }, [
              _c("div", { staticClass: "cell" }, [
                _vm._v(" + Zuschlag: " + _vm._s(item.name)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }, [
                _vm._v(_vm._s(item.calculatedQuantity)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }, [
                _vm._v(_vm._s(_vm._f("priceView")(item.price.amount))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }, [
                _c("b", [
                  _vm._v(_vm._s(_vm._f("priceView")(item.totalPrice.amount))),
                ]),
              ]),
            ])
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }