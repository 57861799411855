<template>
    <multiselect
        v-model                = "otherServiceSelected"
        v-bind:options         = "items"
        v-bind:loading         = "isLoadingItems"
        v-bind:allow-empty     = "false"
        v-bind:disabled        = "!otherServiceTypeID"
        v-bind:custom-label    = "otherLabel"
        v-bind:internal-search = "false"

        placeholder    = "Zusatzleistung auswählen"
        track-by       = "id"
        group-values   = "items"
        group-label    = "type"
        select-label   = ""
        deselect-label = "Löschen"

        v-on:search-change = "otherServiceSearch"
    >
        <template slot="noResult">Keine passenden Leistungsträger</template>
        <span slot="caret">
            <a
                class  = "multiselect-crud-link"
                href   = "/others?add"
                title  = "Fügen Sie eine neue Leistungsträger hinzu"
                target = "_blank"
            ><i class="material-icons">add</i></a>
        </span>
    </multiselect>
</template>


<script>
import axios           from 'axios';
import debounce        from 'lodash/debounce';
import Multiselect     from '@components/form/Multiselect';
import { notifyError } from '@components/Notification';
import mixins from "@orders/placeholders/components/forms/mixins";


const emptyObject = { id: -1, provider: { name: 'No-Name' } };


export default {
    mixins: [mixins],
    props: {
        value: {},
        otherServiceTypeID: { required: true },
        placeholderInfo: {  default: () => ({}) }
    },


    data: function () {
        return {
            items: [
                {
                    type: 'No-Name',
                    items: [emptyObject]
                },
                {
                    type: 'Leistungsträger',
                    items: []
                }
            ],

            queryString:          '',
            otherServiceSelected: null,
            isLoadingItems:       false
        }
    },


    methods: {
        otherLabel: function (other) {
            let subtitle = other.subtitle ? ' (' + other.subtitle + ')' : '';

            if (other.provider) {
                subtitle += other.provider.place ? ' (' + other.provider.place.destination.name + ')' : '';
                return other.provider.name + subtitle ;

            } else {
                return 'No-Name' + subtitle;
            }
        },


        otherServiceSearch: debounce(function (query, isTriggerAction = false) {
            if (this.otherServiceTypeID || isTriggerAction) {
                this.queryString = query;

                this.isLoadingItems = true;
                this.callCancelRequest();
                Promise.all([
                        axios.get('/api/other_services?provider[exists]=true&provider.name=' + query + '&otherServiceType.id=' + this.otherServiceTypeID
                            + '&_groups[]=provider_read&_groups[]=place_read&_groups[]=area_read&_groups[]=destination_read', {cancelToken: this.cancelSource.token}),
                        axios.get('/api/other_services?provider[exists]=false&otherServiceType.id=' + this.otherServiceTypeID + '&_groups[]=provider_read', {cancelToken: this.cancelSource.token})
                    ])
                    .then(([withProvider, noProvider]) => {
                        this.items[1].items = [
                                ...withProvider.data.filter(item => {
                                    if(!item.provider.place){
                                        return true;
                                    }
                                    if (this.placeholderInfo.area && this.placeholderInfo.area.id) {
                                        return item.provider.place.area.id === this.placeholderInfo.area.id
                                    } else if (this.placeholderInfo.place && this.placeholderInfo.place.id) {
                                        return item.provider.place.id === this.placeholderInfo.place.id
                                    } else if (this.placeholderInfo.destination && this.placeholderInfo.destination.id) {
                                        return item.provider.place.destination.id === this.placeholderInfo.destination.id
                                    }
                                    return true;
                                }),
                                ...noProvider.data.filter(item => item.subtitle)
                            ];

                    }, error => { if(!axios.isCancel(error)) {
                        notifyError('Die Anbieter konnten nicht geladen werden! Server error!');
                    } })
                    .then(() => { this.isLoadingItems = false; });
            }
        }, 900)
    },


    created: function () {
        this.otherServiceSearch(this.queryString, true);
    },


    watch: {
        otherServiceTypeID: function (newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                this.otherServiceSearch(this.queryString, true);
            }
        },
        placeholderInfo: function (newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                this.otherServiceSearch(this.queryString, true);
            }
        },


        value: {
            handler: function (newValue, oldValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    if (this.value) {
                        this.otherServiceSelected = JSON.parse(JSON.stringify(this.value));

                    } else {
                        this.otherServiceSelected = emptyObject;
                    }
                }
            },
            deep: true,
            immediate: true
        },


        otherServiceSelected: {
            handler: function (value) {
                this.$emit('input', value.id !== -1 ? value : null);
            },
            deep: true
        }
    },


    components: {
        Multiselect
    }
}
</script>
