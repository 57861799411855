<template>
    <div :class="showAirportSelects ? 'has-3-multiselects' : ''">
        <airport-select
            v-if="showAirportSelects"
            class               = "has-margin-right"
            v-model             = "startAirport"
            api                 = "airports"
            v-bind:custom-label = "airport => airport.name + ' - ' + airport.place.name"
            crudLinkTitle       = "Fügen Sie einen neuen Flughafen hinzu"
            v-bind:allow-empty  = "false"
            v-bind:clearable    = "false"
            placeholder         = "Flughafen finden"
        ></airport-select>
        <airport-select
            v-if="showAirportSelects"
            class               = "has-margin-right"
            v-model             = "endAirport"
            api                 = "airports"
            v-bind:custom-label = "airport => airport.name + ' - ' + airport.place.name"
            crudLinkTitle       = "Fügen Sie einen neuen Flughafen hinzu"
            v-bind:allow-empty  = "false"
            v-bind:clearable    = "false"
            placeholder         = "Flughafen finden"
        ></airport-select>


        <multiselect
            class="has-margin-right"
            v-model="airlineSelected"
            v-bind:options="items"
            v-bind:loading="isLoadingItems"
            v-bind:allow-empty="false"
            placeholder="tippen, um Fluggesellschaft zu finden"
            label="name"
            track-by="id"
            group-values="items"
            group-label="type"
            select-label=""
            deselect-label="Löschen"
            :style="multiSelectStyle"
        >
            <template slot="noResult">Keine passenden Fluggesellschaft</template>
            <span slot="caret" v-if="items.length === 0"></span>
        </multiselect>
    </div>
</template>


<script>
import { Multiselect } from '@components/form';
import { getAirlines } from '@api';
import { notifyError } from '@components/Notification';
import AirportSelect   from '@components/form/AsyncSelect';
import mixins from "@orders/placeholders/components/forms/mixins";
import axios from "axios";


export default {
    mixins: [mixins],
    props: {
        value: { required: true },
        placeholderInfo: { type: Object, required: true },
        multiSelectStyle: {},
        showAirportSelects: {type: Boolean, default: true}
    },


    computed: {
        airlineSelected: {
            get: function () {
                return this.value ? JSON.parse(JSON.stringify(this.value)) : { id: -1, name: 'No-Name Fluggesellschaft' };
            },
            set: function (value) {
                this.$emit('input', value.id !== -1 ? value : null);
            }
        }
    },


    data: function () {
        return {
            items: [
                {
                    type: 'No-Name Fluggesellschaft',
                    items: [{ id: -1, name: 'No-Name Fluggesellschaft' }]
                },
                {
                    type:  'Fluggesellschaft',
                    items: []
                }
            ],
            startAirport:  null,
            endAirport:    null,

            isLoadingItems:   false
        }
    },


    methods: {
        getList: function () {
            this.isLoadingItems = true;
            this.callCancelRequest();
            getAirlines('?airlineRoutes.startAirport.id=' + this.startAirport.id + '&airlineRoutes.endAirport.id=' + this.endAirport.id, {cancelToken: this.cancelSource.token}).then(response => {
                    this.items[1].items = response.data;

                }, error => {
                    if(!axios.isCancel(error)) {
                        notifyError('Die Airlines konnten nicht geladen werden! Server error!');
                    }
                })
                .then(() => { this.isLoadingItems = false; });
        }
    },


    created: function () {
        if (this.placeholderInfo) {
            this.startAirport = this.placeholderInfo.startAirport;
            this.endAirport   = this.placeholderInfo.endAirport;

        } else {
            this.getList();
        }
    },


    watch: {
        startAirport: function () {
            this.getList();
            this.$emit('updateStartAirport', this.startAirport);
            this.airlineSelected = { id: -1, name: 'No-Name Fluggesellschaft' };
        },

        endAirport: function () {
            this.getList();
            this.$emit('updateEndAirport', this.endAirport);
            this.airlineSelected = { id: -1, name: 'No-Name Fluggesellschaft' };
        }
    },


    components: {
        AirportSelect,
        Multiselect
    }
}
</script>
