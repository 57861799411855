import { getPackageContingentCalculation, getRequestContingentCalculation } from '@api';
import {getAccommodationLabel, getValueWithKey, priceView} from '@utilities/functions';
import { notifyError } from '@components/Notification';
import Loading   from '@components/Loading';
import { store } from '@root/pages/orders/store';
import Package   from './Package';


export default {
    store: store,


    props: {
        requestID:  { type: Number,  required: true },
        packageID:  { required: true }
    },


    computed: {
        roomTypes: function() {
            return this.$store.state.options.roomTypes.crud_prices ? this.$store.state.options.roomTypes.crud_prices : {};
        },
        cabinTypes: function () {
            return this.$store.state.options.cabinTypes.crud_prices ? this.$store.state.options.cabinTypes.crud_prices : {};
        },
        trainCabinTypes: function () {
            return this.$store.state.options.trainCabinTypes.crud_prices ? this.$store.state.options.trainCabinTypes.crud_prices : {};
        },
        dayTrainCabinTypes: function () {
            return this.$store.state.options.dayTrainCabinTypes.crud_prices ? this.$store.state.options.dayTrainCabinTypes.crud_prices : {};
        },
        supplementPrices: function () { return this.$store.state.options.clientConfig.calculation.supplementPrices; },
    },


    data: function () {
        return {
            calculations: null,
            isLoading: false,
            isEmpty: false
        };
    },


    methods: {
        getValueWithKey,
        getAccommodationLabel,
    },


    created: function () {
        this.isLoading = true;

        if (this.packageID) {
            getPackageContingentCalculation(this.packageID).then(response => {
                this.calculations = response.data;
                this.isLoading = false;

            }, error => {
                notifyError('Couldn\'t get the Calculations! Server Error!');
                this.isEmpty   = true;
                this.isLoading = false;
            });

        } else {
            getRequestContingentCalculation(this.apiEndpoint + this.requestID).then(response => {
                this.calculations = response.data;
                this.isLoading = false;

            }, error => {
                notifyError('Couldn\'t get the Calculations! Server Error!');
                this.isEmpty   = true;
                this.isLoading = false;
            });
        }
    },


    filters: {
        priceView
    },


    components: {
        Loading,
        Package
    }
};
