<template>
    <div class="redesign-table">
        <div class="row is-header">
            <h2>KALKULATION DER INBEGRIFFENEN LEISTUNGEN</h2>
            <simple-menu>
                <button v-on:click.prevent.stop="addIncludedPrice('per_person')">
                    <i class="material-icons">add</i> Kalkulatorische Leistung pro Person im DZ
                </button>
                <button v-on:click.prevent.stop="addIncludedPrice('per_single_room')">
                    <i class="material-icons">add</i> Kalkulatorische Leistung pro Person im EZ
                </button>
                <button v-on:click.prevent.stop="addIncludedPrice('per_group')">
                    <i class="material-icons">add</i> Kalkulatorische Leistung pro Gruppe
                </button>
            </simple-menu>
        </div>
        <row class="is-head">
            <template slot="type">Art</template>
            <template slot="date">Datum</template>
            <template slot="nights"><div></div></template>
            <template slot="name">Leistungsbeschreibung</template>
            <template slot="provider">Anbieter</template>
            <template slot="currency"><div></div></template>
            <template slot="sellPrice">pro Gruppe</template>
            <template slot="profit">pro Person im DZ</template>
            <template slot="margin">{{ this.supplementPrices ? 'EZ-Zuschlag' : 'pro Person im EZ'}}</template>
        </row>

        <group
            v-for="sortGroup in sortGroups"
            v-bind:key="sortGroup.key"
            v-bind:sortGroup="sortGroup"
        />

        <group />

        <treat-package
            v-for="treatPackage in treatPackages"
            v-bind:key="`treatPackage-${treatPackage.id}`"
            v-bind:treatPackage="treatPackage"
        />

        <included-price
            v-for="price in includedPrices"
            v-bind:key="`included-${price.key}`"
            v-bind:price="price"
            v-on:delete="deleteIncluded(price)"
        />

        <price-summary />
        <group-summary
            v-for="sortGroup in sortGroups"
            v-bind:key="`summary-${sortGroup.key}`"
            v-bind:sortGroup="sortGroup"
        />
    </div>
</template>

<script>
import SimpleMenu            from '@components/SimpleMenu'

import store                 from '@calculations/store'
import { Row, TreatPackage } from '@calculations/components'

import Group                 from './Group'
import PriceSummary          from './Summary'
import GroupSummary          from './GroupSummary'
import { IncludedPrice }     from './Prices';

export default {
    store,

    computed: {
        supplementPrices: function () {
            return this.$store.state.options.clientConfig.calculation.supplementPrices;
        },

        sortGroups: function () {
            return this.$store.state.options.sortGroups
        },

        includedPrices: function () {
            const { includedPrices } = this.$store.getters.includedPrices;
            return includedPrices;
        },

        treatPackages: function () {
            return this.$store.getters.treatPackages
                .filter(({requests}) => requests[0].placeholder.clientOffer.status === 'included')
        }
    },

    components: {
        Group,
        Row,
        TreatPackage,
        PriceSummary,
        GroupSummary,
        IncludedPrice,
        SimpleMenu
    },

    methods: {
        addIncludedPrice: function (type) {
            this.$store.commit('addIncluded', type);
        },

        deleteIncluded: function (included) {
            this.$store.commit('removeIncluded', included)
        }
    }
}
</script>
