var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-price field has-addons",
      class: {
        "is-read-only": _vm.isReadOnly,
        "is-highlight": _vm.highlight,
        "is-darken": _vm.isDarken,
      },
      on: {
        click: _vm.focus,
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
          )
            return null
          return _vm.focus.apply(null, arguments)
        },
      },
    },
    [
      _vm.hasLoadedPrices && !_vm.showExchangeRates
        ? _c("loaded-price", {
            attrs: {
              providerPrices: !!_vm.providerPrices ? _vm.providerPrices : [],
              value: _vm.localPrice,
              "small-input": _vm.smallInput,
              "price-currency": _vm.priceCurrency
                ? _vm.priceCurrency
                : _vm.orderCurrency,
            },
            on: { input: _vm.updateFromLoadedPrices },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.invisible
        ? _c("div", [_c("span", { staticClass: "circle is-invisible" })])
        : _vm._e(),
      _vm._v(" "),
      !_vm.showExchangeRates && _vm.linked
        ? _c(
            "div",
            {
              staticClass: "link",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("unlink")
                },
              },
            },
            [
              _c("i", { staticClass: "material-icons is-pointer" }, [
                _vm._v("link"),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.alwaysShowOrderCurrency || _vm.showExchangeRates) &&
      _vm.valueInOrderCurrency
        ? _c(
            "div",
            { staticClass: "inOrderCurrency" },
            [
              _vm._v(
                _vm._s(_vm._f("priceView")(_vm.valueInOrderCurrency)) + " "
              ),
              _vm.valueInOrderCurrency < 1000
                ? [_vm._v(" " + _vm._s(_vm.orderCurrency))]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.defaultExchangeRate
        ? _c("div", { staticClass: "inOrderCurrency" }, [
            _vm._v(
              _vm._s(_vm._f("priceView")(_vm.defaultExchangeRate, this.digits))
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "control is-expanded" }, [
        _c("input", {
          ref: "input",
          staticClass: "input",
          class: { "is-blue": _vm.isBlue },
          style: _vm.inputStyle,
          attrs: {
            type: "text",
            disabled: _vm.disabled,
            readOnly: _vm.isReadOnly,
            placeholder: _vm.placeholder,
          },
          domProps: { value: _vm.inputValue },
          on: { blur: _vm.updatePrice, focus: _vm.focus },
        }),
      ]),
      _vm._v(" "),
      _vm.suffix
        ? _c(
            "div",
            { staticClass: "control" },
            [
              _c("div", { staticClass: "button is-static" }, [
                _vm._v(_vm._s(_vm.suffix)),
              ]),
              _vm._v(" "),
              _vm._t("prefix"),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }