<template>
    <div>
        <transition-group name="column" tag="div" class="columns is-multiline">
            <div class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-widescreen" v-for="item in items" v-bind:key="item.id">
                <slot v-bind:item="item"/>
            </div>

            <div class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-widescreen" key="add">
                <div class="bp-panel is-add" v-on:click="$emit('add')">
                    <i class="material-icons">add</i>
                </div>
            </div>
        </transition-group>

        <slot name="form" v-bind:property="property" />
    </div>
</template>

<script>
import axios from 'axios';
import filter from 'lodash/filter';
import has from 'lodash/has';

import { store } from './store';

export default {
    store,

    props: {
        property: {
            type: String,
            required: true
        },

        filter: {
            type: Object,
        },

        loadOptions: {
            type: Boolean,
            default: true
        },
    },

    created: function () {
        this.fetchOptions()
    },

    methods: {
        fetchOptions: function () {
            const api = this.property;

            // Load api options
            if (api !== '' && !has(this.options, api) && this.loadOptions) {
                axios.options(`/api/${api}`)
                    .then(response => {
                        this.$store.commit('updateOptions', {
                            [api]: response.data
                        })
                    })
            }
        },
    },

    computed: {
        items: function () {
            const items = this.$store.state.data[this.property]
            return filter(items, this.filter);

        },

        options: function () {
            return this.$store.state.options;
        },
    },
}
</script>
