<template>
    <row v-if="!hide">
        <template slot="type">
            <i class="material-icons">local_hotel</i>
            <i class="material-icons" v-if="isDouble">local_hotel</i>
        </template>

        <template slot="name">
            <div class="level-left">
                <template v-if="isSingle">
                    <span class="level-item">
                        <template v-if="hasNightFerries && supplementPrices">{{ getAccommodationLabel('standard', 1, roomTypes)}} inkl. Einzelkabinenzuschlag</template>
                        <template v-else>{{ getAccommodationLabel('standard', 1, roomTypes)}}</template>
                        <template v-if="singleRoomLimit">
                            bis
                        </template>
                    </span>
                    <template v-if="singleRoomLimit">
                        <div class="level-left">
                            <input
                                type="text"
                                v-model.number="numberOfPersons"
                                class="input is-tiny level-item"
                            />
                            <span> EZ</span>

                        </div>
                    </template>
                </template>
                <template v-else>
                    <span class="level-item">
                        {{ getAccommodationLabel('standard', 2, roomTypes)}} ab
                    </span>
                    <div class="level-left">
                        <input
                            type="text"
                            v-model.number="numberOfPersons"
                            class="input is-tiny level-item"
                        />
                        <span> Pax</span>
                    </div>
                </template>
            </div>
        </template>

        <template slot="marginTax">
            <margin-tax v-model="marginTaxEnabled" :tax="marginTax" :margin="grossMargin"/>
        </template>

        <template slot="buyPriceCalculation" v-if="hasTwoCurrencies">
            <input-price readOnly :value="buyPriceInCalculationCurrency"/>
        </template>

        <template slot="buyPrice">
            <input-price
                readOnly :value="buyPrice"
                :is-blue="hasTwoCurrencies"
            />
        </template>

        <template slot="sellPriceCalculation" v-if="hasTwoCurrencies">
            <input-price v-model="sellPriceInCalculationCurrency"/>
        </template>

        <template slot="sellPrice">
            <input-price
                v-model="sellPrice"
                :is-blue="hasTwoCurrencies"
            />
        </template>

        <template slot="profitCalculation" v-if="hasTwoCurrencies">
            <input-price v-model="profitInCalculationCurrency" />
        </template>

        <template slot="profit">
            <input-price
                v-model="profit"
                :is-blue="hasTwoCurrencies"
            />
        </template>

        <template slot="margin">
            <input-price v-model="margin" />
        </template>

        <template slot="actions">
            <i class="material-icons" v-on:click.prevent.stop="deletePackage">delete</i>
            <i class="material-icons" v-on:click="toggleHighlight" :class="{'is-active': isHighlighted}">location_searching</i>
        </template>
    </row>
</template>

<script>
import has from 'lodash/has';
import flow from 'lodash/flow';
import debounce from 'lodash/debounce'
import { applyItemPricesSummary, appendPersonPrice, addBusBuyPrice, filterPersons } from '@calculations/functions';
import price from './price' ;
import Template from "@orders/subpages/TripDetails/General/Template";

export default {
    components: {Template},
    mixins: [ price ],

    computed: {

        isSingle: function () { return this.price.orderHotelRoom.type.persons === 1;},
        isDouble: function () { return !this.isSingle },

        hasNightFerries: function () {
            return this.$store.getters.nightFerryPrices.length > 0;
        },

        persons: function () {
            return this.$store.getters.persons;
        },

        includedPrices: function () {
            const exchangeRates = this.$store.state.exchangeRates;

            const includedPrices = this.$store.getters.includedPrices;
            const treatPackages = this.$store.getters.treatPackages
                .filter(({requests}) => requests[0].placeholder.clientOffer.status === 'included');

            let numberOfPersons = this.isSingle ? this.persons : this.price.numberOfPersons;

            const summaryPrices = [
                ...includedPrices.hotelPrices,
                ...includedPrices.airlinePrices,
                ...flow(
                    items => addBusBuyPrice(items, numberOfPersons, exchangeRates, this.supplementPrices)
                )(includedPrices.ferryPrices),
                ...includedPrices.trainPrices,
                ...flow(
                    items => appendPersonPrice(items, numberOfPersons, this.supplementPrices)
                )(includedPrices.otherPrices),
                ...flow(
                    items => filterPersons(items, this.price.numberOfPersons),
                    items => appendPersonPrice(items, numberOfPersons, this.supplementPrices)
                )(includedPrices.includedPrices),
                ...treatPackages
            ];

            if (this.isSingle) {
                let mainPrice = this.$store.state.prices.contingentPrices.find(price => price.numberOfPersons === numberOfPersons);
                return applyItemPricesSummary(summaryPrices,
                    {
                                single: (this.price.numberOfPersons + 1),
                                singleMin: this.price.numberOfPersonsMin,
                                singleMax: this.price.numberOfPersonsMax,
                                double: numberOfPersons,
                                doubleMin: mainPrice.numberOfPersonsMin,
                                doubleMax: mainPrice.numberOfPersonsMax,
                           },
                    exchangeRates, this.supplementPrices
                );
            } else {
                return applyItemPricesSummary(summaryPrices, {
                    single: 0,
                    double: this.price.numberOfPersons,
                    doubleMin: this.price.numberOfPersonsMin,
                    doubleMax: this.price.numberOfPersonsMax,
                },
                    exchangeRates, this.supplementPrices);
            }
        },

        numberOfPersons: {
            get: function() { return this.price.numberOfPersons },
            set: function(value) { this.updatePricePersons(value) }
        },

        buyPriceInCalculationCurrency: function () {
            if (this.isSingle) {
                return this.includedPrices.reduce((sum, {_summary}) => {
                    return sum + Number(_summary.single)
                }, 0)
            } else {
                return this.includedPrices.reduce((sum, {_summary}) => {
                    return sum + Number(_summary.person)
                }, 0)
            }
        },

        buyPrice: function () {
            return this.buyPriceInCalculationCurrency * this.calculationToOrderExchangeRate;
        },

        isHighlighted: function () {
            if (this.isSingle) {
                return this.$store.state.highlight.single === this.price.numberOfPersons
            } else {
                return this.$store.state.highlight.person === this.price.numberOfPersons
            }
        },




    },

    methods: {
        deletePackage: function () {
            this.$store.commit('removePackage', this.price)
        },

        updatePrice: function (obj = {}) {
            this.$store.commit('updatePrice', {
                contingentPrices: {
                    key: this.price.key,
                    orderHotelRoom: this.price.orderHotelRoom,
                    numberOfPersons: this.price.numberOfPersons,
                    ...obj
                }
            })
        },

        updatePricePersons: debounce( function(value) {
            this.updatePrice({numberOfPersons: value})
        }, 1500),

        toggleHighlight: function () {
            if (!this.isHighlighted) {
                this.$store.commit('updateHighlight', {
                    ...this.isSingle && {
                        single: this.price.numberOfPersons,
                        ...has(this.price, 'numberOfPersonsMin') && {
                            singleMin: this.price.numberOfPersonsMin,
                            singleMax: this.price.numberOfPersonsMax,
                        }
                    },
                    ...this.isDouble && { person: this.price.numberOfPersons }
                })
            } else {
                this.$store.commit('clearHighlight');
            }
        }
    },

    watch: {
        buyPrice: {
            immediate: true,
            handler: function (newBuyPrice) {
                this.updatePrice({buyPrice: {amount: newBuyPrice, currency: this.orderCurrency}})
            }
        }
    }
}
</script>

