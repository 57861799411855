<template>
    <span>
        {{ row.startPort && row.startPort.place.name }}
        <template v-if="row.startPort && row.endPort">-</template>
        {{ row.endPort && row.endPort.place.name }}
    </span>
</template>

<script>
import Cell from './cell';

export default {
    mixins: [ Cell ],
}
</script>
