<template>
    <modal-form
        editTitle="Besuchsbericht bearbeiten"
        createTitle="Neuen Besuchsbericht hinzufügen"
    >
        <table>
            <tr>
                <th>
                    <label for="date" class="label">Datum:</label>
                </th>
                <td>
                    <div class="control">
                        <input-date
                            v-model="form.date"
                            class="flatpickr--range">
                        </input-date>
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="contact" class="label">Kontaktperson:</label></th>
                <td>
                    <multiselect
                        v-model="form.contacts"
                        multiple
                        track-by="id"
                        v-bind:custom-label="contactLabel"
                        v-bind:options="contacts"
                    >
                        <span slot="caret"></span>
                    </multiselect>
                </td>
            </tr>
            <tr>
                <th><label for="report" class="label">Bericht:</label></th>
                <td>
                    <tip-tap v-model="form.report"/>
                </td>
            </tr>

        </table>
    </modal-form>
</template>

<script>
import TabForm from '../../form'
import { TipTap } from '@components/form';
import {currentUser} from "@utilities/functions";
import { DateRange } from '@components/form';
import Date from "@components/CRUD/Table/Cells/Date";
import Datepicker from 'vue-bulma-datepicker';
import { InputDate } from '@components/form';
import {getAxios} from "@api";

export default {
    mixins: [ TabForm ],

    form: {
        report: '',
        contacts: [],
        user: currentUser(),
        date: null,
    },

    props: {
        client: {
            type: Object,
            required: false,
        }
    },

    property: 'clientVisits',
    components: {
        Date,
        TipTap,
        InputDate
    },

    data: function () {
        return {
            passedContacts: [],
        }
    },

    computed: {
        contacts: function() {
            if(this.passedContacts.length > 0) {
                return this.passedContacts;
            } else {
                return this.$store.state.data.contacts ? this.$store.state.data.contacts : [];
            }
        },
    },
    methods: {
        contactLabel: function (contact) {
            return `${contact.fullName}`;
        },

        openCallback: function() {
              if(this.client) {
                  this.$store.state.api = 'clients';
                  this.$store.state.id = this.client.id;
                  getAxios('contacts', {'client.id': this.client.id}).then(response => {
                      this.passedContacts = response.data;
                  });
              }
        },
    }

}
</script>
