var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "has-margin-top" }, [
    _c(
      "div",
      {
        staticClass: "bp-panel is-form has-no-title",
        staticStyle: { "min-height": "auto" },
      },
      [
        _c("div", { staticClass: "panel-content is-table-form" }, [
          _c(
            "table",
            [
              _vm._l(_vm.component.props.rows, function (row) {
                return [
                  _c(
                    "tr",
                    [
                      _vm._l(row.fields, function (field) {
                        return [
                          field.type === "label"
                            ? _c("th", [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v(_vm._s(field.label) + ":"),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          field.type === "multiselect"
                            ? _c("td", [
                                _c(
                                  "div",
                                  { staticClass: "control" },
                                  [
                                    _c("multiselect", {
                                      attrs: {
                                        options: field.options,
                                        multiple: field.multiple,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.saveItem(field)
                                        },
                                      },
                                      model: {
                                        value: _vm.item[field.property],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            field.property,
                                            $$v
                                          )
                                        },
                                        expression: "item[field.property]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }