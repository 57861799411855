<template>
    <tr>
        <td class="is-medium">
            <multiselect
                v-bind:options="types"
                v-model="type"
                track-by="value"
                label="label"
            >
                <span slot="clear" />
            </multiselect>
        </td>
        <td class="is-small">
            <input
                v-if="form.type === 'days'"
                type="text"
                v-model="form.deadlineDate"
                class="input"
            >
        </td>
        <td>
            <input
                type="text"
                v-model="form.conditionText"
                class="input"
            >
        </td>
        <td class="is-narrow">
            <i class="material-icons" v-on:click.prevent.stop="$emit('delete')">delete</i>
        </td>
    </tr>
</template>

<script>
import debounce from 'lodash/debounce'
import { Multiselect } from '@components/form'

export default {
    props: {
        condition: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data: function () {
        return {

            form: {
                type: 'text',

                conditionText: '',
                deadlineDate: '',
                ...this.condition
            },
            types: [
                { label: 'Freitext', value: 'text' },
                { label: 'bis X Tage vor Reisebeginn', value: 'days'}
            ]
        }
    },

    mounted: function () {
        if (!['', null].includes(this.condition.deadlineDate)) {
            this.form.type = 'days'
        }
    },

    computed: {
        type: {
            get: function () { return this.types.find(type => type.value === this.form.type) },
            set: function (type) {
                if (type === 'text') {
                    this.form.deadlineDate = ''
                }

                this.form.type = !!type ? type.value : null;
            }
        }
    },

    methods: {
        updateCondition: debounce( function (obj) {
            this.$emit('save', {...this.form, ...obj})
        }, 1000)
    },

    watch: {
        'form.deadlineDate': function (newValue) {
            this.updateCondition({deadlineDate: newValue})
        },

        'form.conditionText': function (newValue) {
            this.updateCondition({conditionText: newValue})
        }
    },

    components: {
        Multiselect
    }
}
</script>
