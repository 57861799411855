<template>
    <div>
        <div class="redesign-table">
            <div class="table-wrapper">
                <div class="table-label" v-if="label">{{ label }}</div>
                <div class="row is-header" v-if="hasHeader">
                    <slot name="header" />
                </div>

                <filters
                    class="row is-filters"
                    v-model="filters"
                    v-on:input="refresh"
                    v-bind:options="options"
                    v-if="!hasSlotFilters"
                />
                <slot name="filters" v-bind:options="options" />
            </div>
        </div>

        <loading v-if="isLoading" />

        <slot v-bind="{rows, options}" v-else />


        <!-- Footer -->
        <div class="level" v-if="!isLoading">
            <div>
                <a
                    v-if="isAddable"
                    v-on:click="handleAdd"
                    class="button is-primary"
                >{{ name.singular | capitalize }} hinzufügen</a>
            </div>
            <pagination v-model="pagination" v-on:input="refresh" />
        </div>


        <!-- Form slot -->
        <slot name="form" v-bind:options="options" />
    </div>
</template>

<script>
import Table from './Table/Index';

export default {
    mixins: [ Table ]
}
</script>
