<template>
    <span>{{ date }}</span>
</template>

<script>
import { fullYear } from '@utilities/functions';
import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        date: function () {
            return !!this.value ? fullYear(this.value) : ''
        }
    }
}
</script>

