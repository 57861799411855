var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "is-additional": _vm.isAdditional } },
    [
      !_vm.hide
        ? _c(
            "row",
            {
              nativeOn: {
                dblclick: function ($event) {
                  return _vm.openModal.apply(null, arguments)
                },
              },
            },
            [
              _c("template", { slot: "type" }, [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v(_vm._s(_vm.icon)),
                ]),
              ]),
              _vm._v(" "),
              _c("template", { slot: "name" }, [_vm._v(_vm._s(_vm.name))]),
              _vm._v(" "),
              _c("template", { slot: "date" }, [_vm._v(_vm._s(_vm.date))]),
              _vm._v(" "),
              _c(
                "template",
                { slot: "nights" },
                [
                  _vm.days && _vm.isOther
                    ? [_vm._v(_vm._s(_vm.days) + " T")]
                    : _vm.nights
                    ? [_vm._v(_vm._s(_vm.nights) + " N")]
                    : [_vm._v("-")],
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "marginTax" },
                [
                  _c("margin-tax", {
                    attrs: { tax: _vm.marginTax, margin: _vm.grossMargin },
                    model: {
                      value: _vm.marginTaxEnabled,
                      callback: function ($$v) {
                        _vm.marginTaxEnabled = $$v
                      },
                      expression: "marginTaxEnabled",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showExchangeRates
                ? _c(
                    "template",
                    { slot: "buyPriceCalculation" },
                    [
                      _vm.hideInputs
                        ? _c("span", { staticClass: "empty" }, [_vm._v("-")])
                        : _c("input-price", {
                            attrs: { linked: _vm.linked },
                            model: {
                              value: _vm.buyPriceInCalculationCurrency,
                              callback: function ($$v) {
                                _vm.buyPriceInCalculationCurrency = $$v
                              },
                              expression: "buyPriceInCalculationCurrency",
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "template",
                { slot: "buyPrice" },
                [
                  _vm.hideInputs
                    ? _c("span", { staticClass: "empty" }, [_vm._v("-")])
                    : _c("input-price", {
                        attrs: {
                          "is-blue": _vm.hasTwoCurrencies,
                          linked: _vm.linked,
                          currency: _vm.currency,
                          "provider-prices": _vm.getProviderPrices(
                            _vm.buyPriceType
                          ),
                        },
                        model: {
                          value: _vm.buyPrice,
                          callback: function ($$v) {
                            _vm.buyPrice = $$v
                          },
                          expression: "buyPrice",
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showExchangeRates
                ? _c(
                    "template",
                    { slot: "sellPriceCalculation" },
                    [
                      _vm.hideInputs
                        ? _c("span", { staticClass: "empty" }, [_vm._v("-")])
                        : _c("input-price", {
                            model: {
                              value: _vm.sellPriceInCalculationCurrency,
                              callback: function ($$v) {
                                _vm.sellPriceInCalculationCurrency = $$v
                              },
                              expression: "sellPriceInCalculationCurrency",
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "template",
                { slot: "sellPrice" },
                [
                  _vm.hideInputs
                    ? _c("span", { staticClass: "empty" }, [_vm._v("-")])
                    : _c("input-price", {
                        attrs: {
                          "provider-prices": _vm.getProviderPrices(
                            _vm.sellPriceType
                          ),
                          "is-blue": _vm.hasTwoCurrencies,
                        },
                        model: {
                          value: _vm.sellPrice,
                          callback: function ($$v) {
                            _vm.sellPrice = $$v
                          },
                          expression: "sellPrice",
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showExchangeRates
                ? _c(
                    "template",
                    { slot: "profitCalculation" },
                    [
                      _vm.hideInputs
                        ? _c("span", { staticClass: "empty" }, [_vm._v("-")])
                        : _c("input-price", {
                            model: {
                              value: _vm.profitInCalculationCurrency,
                              callback: function ($$v) {
                                _vm.profitInCalculationCurrency = $$v
                              },
                              expression: "profitInCalculationCurrency",
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "template",
                { slot: "profit" },
                [
                  _vm.hideInputs
                    ? _c("span", { staticClass: "empty" }, [_vm._v("-")])
                    : _c("input-price", {
                        attrs: { "is-blue": _vm.hasTwoCurrencies },
                        model: {
                          value: _vm.profit,
                          callback: function ($$v) {
                            _vm.profit = $$v
                          },
                          expression: "profit",
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "margin" },
                [
                  _vm.hideInputs
                    ? _c("span", { staticClass: "empty" }, [_vm._v("-")])
                    : _c("input-price", {
                        model: {
                          value: _vm.margin,
                          callback: function ($$v) {
                            _vm.margin = $$v
                          },
                          expression: "margin",
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "actions" },
                [
                  _vm.isOther
                    ? [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.togglePriceType.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm.isPerPerson
                              ? [_vm._v("people")]
                              : [_vm._v("person")],
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "i",
                    {
                      staticClass: "material-icons",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.toggleStatus.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("remove_shopping_cart")]
                  ),
                  _vm._v(" "),
                  !_vm.isAirline
                    ? _c(
                        "i",
                        {
                          staticClass: "material-icons",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.saveInCRUD.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("save")]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hideInputs
        ? _c(
            "div",
            { staticClass: "is-light" },
            [
              _vm.isHotel
                ? [
                    _c("night-provider", {
                      attrs: {
                        name: "Doppelzimmer",
                        price: _vm.price,
                        property: "doubleRoom",
                      },
                    }),
                    _vm._v(" "),
                    _c("night-provider", {
                      attrs: {
                        name: "Einzelzimmer",
                        price: _vm.price,
                        property: "singleRoomPremium",
                      },
                    }),
                  ]
                : _vm.isFerry
                ? [
                    _c("night-provider", {
                      attrs: {
                        name: "Doppelkabine",
                        price: _vm.price,
                        property: "insideDoubleCabin",
                      },
                    }),
                    _vm._v(" "),
                    _c("night-provider", {
                      attrs: {
                        name: "Einzelkabine",
                        price: _vm.price,
                        property: "insideSingleCabinPremium",
                      },
                    }),
                    _vm._v(" "),
                    _c("night-provider", {
                      attrs: {
                        name: "Doppelkabine Außen",
                        price: _vm.price,
                        property: "outsideDoubleCabin",
                      },
                    }),
                    _vm._v(" "),
                    _c("night-provider", {
                      attrs: {
                        name: "Einzelkabine Außen",
                        price: _vm.price,
                        property: "outsideSingleCabinPremium",
                      },
                    }),
                  ]
                : _vm.isTrain && _vm.placeholder.type === "night"
                ? [
                    _c("night-provider", {
                      attrs: {
                        name: "Doppelabteil",
                        price: _vm.price,
                        property: "secondDoubleCabin",
                      },
                    }),
                    _vm._v(" "),
                    _c("night-provider", {
                      attrs: {
                        name: "Einzelabteil",
                        price: _vm.price,
                        property: "secondSingleCabinPremium",
                      },
                    }),
                    _vm._v(" "),
                    _c("night-provider", {
                      attrs: {
                        name: "Doppelabteil 1. Klasse",
                        price: _vm.price,
                        property: "firstDoubleCabin",
                      },
                    }),
                    _vm._v(" "),
                    _c("night-provider", {
                      attrs: {
                        name: "Einzelabteil 1. Klasse",
                        price: _vm.price,
                        property: "firstSingleCabinPremium",
                      },
                    }),
                  ]
                : _vm.isTrain && _vm.placeholder.type === "day"
                ? [
                    _c("night-provider", {
                      attrs: {
                        name: "2. Klasse",
                        price: _vm.price,
                        property: "secondSingleCabinPremium",
                      },
                    }),
                    _vm._v(" "),
                    _c("night-provider", {
                      attrs: {
                        name: "1. Klasse",
                        price: _vm.price,
                        property: "firstSingleCabinPremium",
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAirline
        ? _c(
            "div",
            { staticClass: "is-light" },
            [
              _c("airline-package", {
                attrs: { price: _vm.price, included: _vm.isAirlineIncluded },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }