import toString from 'lodash/toString';
import mapValues from 'lodash/mapValues';
import has from 'lodash/has';
import flow from 'lodash/flow';

import { fullYear, nights, compareDates, getPlaceholderType, getRequestType } from '@utilities/functions';
import { edit } from '@api';
import { getAccommodationLabel } from '@utilities/functions';

import Row from './components/Row';
import InputPrice from './components/InputPrice';
import store from './store';
import {settings} from "@clientSpecific/utilities/settings";

export const getSortHelper = function (days) {
    return [].concat(...days.map(day => day.sortHelper))
};

const parsePrice = function (price) {
    if (price === null || typeof price === 'string' && price.trim() === '') {
        return null;
    } else {
        return toString(price);
    }
};

const parsePrices = function (prices) {
    return prices.map(item => {
        return mapValues(item, (value, key) => {
            if (key.includes('Price') && key !== 'airlinePrice') {
                if(!value || !value.amount) {
                    return {
                        amount: "0"
                    }
                }
                return {
                    amount: parsePrice(value.amount),
                    currency: item.currencyChange && item.placeholder ? item.placeholder.placeholderCurrency : value.currency
                }
            } else {
                return value;
            }
        });
    });
};

export const filterPersons = function (items, persons) {
    if (typeof persons === 'object' && persons !== null) {
        const { min, max } = persons;
        return items.filter(({ minPersons, maxPersons }) => {
            minPersons = minPersons ? minPersons : 0;
            maxPersons = [-1, null, 99999999].includes(maxPersons) ? 99999999 : maxPersons;
            return (
                minPersons <= max &&
                maxPersons >= min
            )
        });
    } else {
        return items.filter(({ minPersons, maxPersons }) =>
            (persons >= minPersons || minPersons === null) &&
            (persons <= maxPersons || [-1, null, 99999999].includes(maxPersons))
        );
    }
};

export const filterPersons2 = function (items, persons) {
    if (typeof persons === 'object' && persons !== null) {
        const { min, max } = persons;
        return items.filter(({ minPersons, maxPersons }) => {
            minPersons = minPersons ? minPersons : 0;
            maxPersons = [-1, null, 99999999].includes(maxPersons) ? 99999999 : maxPersons;
            return (
                (((minPersons >= min && minPersons <= max) || minPersons === null)) &&
                (((maxPersons >= min && maxPersons <= max) || [-1, null, 99999999].includes(maxPersons)))
            )
        });
    } else {
        return items.filter(({ minPersons, maxPersons }) =>
            (persons >= minPersons || minPersons === null) &&
            (persons <= maxPersons || [-1, null, 99999999].includes(maxPersons))
        );
    }
};

const sumPersons = function (prices, persons, exchangeRates, divideBy = 1) {




    const divideByItem = divideBy ? divideBy : 1;

    return flow(
        arr => arr.map(itemPrice => ({
            ...itemPrice,
            minPersons: itemPrice.minPersons || 0,
            maxPersons: itemPrice.maxPersons || 99999999,
            exchangeFactor: exchangeRates && exchangeRates[itemPrice.price.currency] ? exchangeRates[itemPrice.price.currency] : 0
        })),
        arr => filterPersons(arr, persons),

        arr => arr.reduce((sum, itemPrice) => {

            itemPrice.price.amount = divideBy ? itemPrice.price.amount : 0;
            itemPrice.nights = itemPrice.nights ? itemPrice.nights : 1;
            return sum + (Number(itemPrice.price.amount) / divideByItem * itemPrice.nights * Number(itemPrice.exchangeFactor))
        }, 0)
    )(prices);
};

export const placeholderNights = function (placeholder) {
    if (placeholder) {
        const { startAt, endAt } = placeholder;
        return nights(fullYear(startAt), fullYear(endAt));
    }

    return 0;
};

export const prepareForBackend = function (state) {
    return {
        order: `/api/orders/${state.order.id}`,

        hotelPrices: parsePrices(state.prices.hotelPrices)
            .map(item => ({
                id: item.id,
                doubleRoomBuyPrice: item.doubleRoomBuyPrice,
                doubleRoomSellPrice: item.doubleRoomSellPrice,
                extrasDoubleRoom: item.extrasDoubleRoom,
                extrasSingleRoom: item.extrasSingleRoom,
                freePlacesDoubleRoom: item.freePlacesDoubleRoom,
                freePlacesSingleRoom: item.freePlacesSingleRoom,
                singleRoomPremiumBuyPrice: item.singleRoomPremiumBuyPrice,
                singleRoomPremiumSellPrice: item.singleRoomPremiumSellPrice,
                marginTax: item.marginTax
            })),
        ferryPrices: parsePrices(state.prices.ferryPrices)
            .map(item => ({
                id: item.id,
                busBuyPrice: item.busBuyPrice,
                busSellPrice: item.busSellPrice,
                dayFreePlaces: item.dayFreePlaces,
                dayTicketBuyPrice: item.dayTicketBuyPrice,
                dayTicketSellPrice: item.dayTicketSellPrice,
                extrasDoubleCabin: item.extrasDoubleCabin,
                extrasSingleCabin: item.extrasSingleCabin,
                freePlacesDoubleCabin: item.freePlacesDoubleCabin,
                freePlacesSingleCabin: item.freePlacesSingleCabin,
                insideDoubleCabinBuyPrice: item.insideDoubleCabinBuyPrice,
                insideDoubleCabinSellPrice: item.insideDoubleCabinSellPrice,
                insideSingleCabinPremiumBuyPrice: item.insideSingleCabinPremiumBuyPrice,
                insideSingleCabinPremiumSellPrice: item.insideSingleCabinPremiumSellPrice,
                outsideDoubleCabinBuyPrice: item.outsideDoubleCabinBuyPrice,
                outsideDoubleCabinSellPrice: item.outsideDoubleCabinSellPrice,
                outsideSingleCabinPremiumBuyPrice: item.outsideSingleCabinPremiumBuyPrice,
                outsideSingleCabinPremiumSellPrice: item.outsideSingleCabinPremiumSellPrice,
                marginTax: item.marginTax
            })),
        trainPrices: parsePrices(state.prices.trainPrices)
            .map(item => ({
                id: item.id,
                secondDoubleCabinBuyPrice: item.secondDoubleCabinBuyPrice,
                secondDoubleCabinSellPrice: item.secondDoubleCabinSellPrice,
                secondSingleCabinPremiumBuyPrice: item.secondSingleCabinPremiumBuyPrice,
                secondSingleCabinPremiumSellPrice: item.secondSingleCabinPremiumSellPrice,
                firstDoubleCabinBuyPrice: item.firstDoubleCabinBuyPrice,
                firstDoubleCabinSellPrice: item.firstDoubleCabinSellPrice,
                firstSingleCabinPremiumBuyPrice: item.firstSingleCabinPremiumBuyPrice,
                firstSingleCabinPremiumSellPrice: item.firstSingleCabinPremiumSellPrice,
                marginTax: item.marginTax
            })),
        otherPrices: parsePrices(state.prices.otherPrices)
            .map(item => ({
                id: item.id,
                sellPrice: item.sellPrice,
                buyPrice: item.buyPrice,
                freePlaces: item.freePlaces,
                marginTax: item.marginTax
            })),
        airlinePrices: parsePrices(state.prices.airlinePrices)
            .map(item => ({
                id: item.id,
                ...!!item.airlinePrice && { airlinePrice : `/api/calculation_price_airlines/${item.airlinePrice}`},
                sellPrice: item.sellPrice,
                buyPrice: item.buyPrice,
                marginTax: item.marginTax
            })),

        contingentPrices: parsePrices(state.prices.contingentPrices)
            .map(item => ({
                ...has(item, 'id') && { id: item.id },
                numberOfPersons: item.numberOfPersons,
                buyPrice: item.buyPrice,
                sellPrice: item.sellPrice,
                marginTax: item.marginTax,
                ...!!item.orderHotelRoom && { orderHotelRoom: `/api/order_contingent_rooms/${item.orderHotelRoom.id}` },
                ...!!item.orderFerryCabin && { orderFerryCabin: `/api/order_contingent_cabins/${item.orderFerryCabin.id}` }
            })),
        extraPrices: parsePrices(state.prices.extraPrices)
            .map(item => ({
                ...has(item, 'id') && { id: item.id },
                offerTextBlock: item.offerTextBlock,
                name: item.name,
                buyPrice: item.buyPrice,
                sellPrice: item.sellPrice,
                type: item.type,
                marginTax: item.marginTax
            })),
        includedPrices: parsePrices(state.prices.includedPrices)
            .map(item => ({
                ...has(item, 'id') && { id: item.id },
                priceType: item.priceType,
                name: item.name,
                buyPrice: item.buyPrice,
                minPersons: item.minPersons,
                maxPersons: item.maxPersons
            })),

        rates: state.savedExchangeRates
    };


};

export const priceMixin = {
    store,

    props: {
        price: {
            type: Object,
            required: true
        },
    },

    computed: {

        supplementPrices: function () { return this.$store.state.options.clientConfig.calculation.supplementPrices; },

        singleRoomLimit: function () { return settings.calculation.singleRoomLimit },

        calculationSaved: function () {
            return this.$store.state.calculationSaved;
        },
        marginTaxOn: function() {
            return this.$store.state.marginTaxOn;
        },
        roomTypes: function() {return this.$store.state.options.roomTypes.crud_prices ? this.$store.state.options.roomTypes.crud_prices : [] },
    },
    methods: {
        getAccommodationLabel
    },

    components: {
        Row,
        InputPrice
    }
};

export const placeholderPriceMixin = {
    methods: {
        togglePriceType: function () {
            const priceType = this.isPerPerson ? 'per_group' : 'per_person';
            this.placeholder.clientOffer.priceType = priceType;

            edit(this.api, {
                clientOffer: this.placeholder.clientOffer
            }, {
                '_groups[]': 'order_placeholder_read'
            });
        },

        toggleStatus: function () {
            const status = this.isIncluded ? 'optional' : 'included';
            const currency = this.currency;

            this.placeholder.clientOffer.status = status;

            if(status === 'optional'){
                if(currency !== this.$store.state.order.orderCurrency && !this.placeholder.calculationRequest){
                    let factor = this.$store.state.calculationToOrderExchangeRate[this.$store.state.order.calculationCurrency] ? this.exchangeRate * this.$store.state.calculationToOrderExchangeRate[this.$store.state.order.calculationCurrency] : 1;
                    if(this.price[this.personPriceType]){
                        this.price[this.personPriceType].amount = this.price[this.personPriceType].amount * factor;
                        this.price[this.personPriceType].currency = this.$store.state.order.orderCurrency;
                    }
                    if(this.price[this.singlePriceType]){
                        this.price[this.singlePriceType].amount = this.price[this.singlePriceType].amount * factor;
                        this.price[this.singlePriceType].currency = this.$store.state.order.orderCurrency;
                    }
                    if(this.price[this.groupPriceType]){
                        this.price[this.groupPriceType].amount = this.price[this.groupPriceType].amount * factor;
                        this.price[this.groupPriceType].currency = this.$store.state.order.orderCurrency;
                    }
                }
            }

            edit(this.api, {
                clientOffer: this.placeholder.clientOffer,
                placeholderCurrency: this.$store.state.order.orderCurrency
            }, {
                '_groups[]': 'order_placeholder_read'
            }).then(response => {
                let placeholder = {
                    ...this.price.placeholder,
                    placeholderCurrency: response.data.placeholderCurrency
                }

                this.$store.commit('updatePrice', {[this.price._type + 'Prices']: {...this.price, placeholder: placeholder, currencyChange: true}})
            });
        }
    },

    computed: {
        api: function () { return `${getPlaceholderType(this.placeholder)}_placeholders/${this.placeholder.id}`; },
        placeholder: function () { return this.price.placeholder; },
        name: function () { return this.placeholder.serviceDescription; },
        icon: function () { return this.$options.icon || 'help'; },
        currency: function() { return this.placeholder.placeholderCurrency},
        isPerPerson: function () { return this.placeholder.clientOffer.priceType === 'per_person'; },
        isIncluded: function () { return this.placeholder.clientOffer.status === 'included'; },
        isOther: function () { return this.price._type === 'other'; },
        isAirline: function () { return this.price._type === 'airline'; },

        date: function () {
            if (this.placeholder) {
                let { startAt, endAt } = this.placeholder;
                startAt = fullYear(startAt);
                endAt = fullYear(endAt);

                if (startAt !== endAt) {
                    return `${startAt.substring(0,6)} - ${endAt}`;
                } else {
                    return startAt;
                }
            }

            return false;
        },

        nights: function () {
            return placeholderNights(this.placeholder);
        },

        days: function () {
            return this.nights + 1;
        },
    }
};

export const applyItemPricesSummary = function (items, rooms, exchangeRates, supplementPrices = true) {

    // const persons = rooms.single + rooms.double * 2;
    const persons = rooms.double ? rooms.double : 1; // * 2;
    const doubleMin = rooms.doubleMin ? rooms.doubleMin : 0;
    const doubleMax = rooms.doubleMax ? rooms.doubleMax : 999999;
    const singleMin = rooms.singleMin ? rooms.singleMin : 0;
    const singleMax = rooms.singleMax ? rooms.singleMax : 999999;


    return items.map(price => ({
        ...price,
        _summary: {
            ...price._summary,
            single: price._summary.single + sumPersons(
                price.itemPrices.filter(({ quantityType }) => quantityType === 'per_single_room' || quantityType === 'per_single_room_night'),
                { min: singleMin, max: singleMax },
                exchangeRates
            ) + sumPersons(
                price.itemPrices.filter(({ quantityType }) => !supplementPrices && ['per_group', 'custom'].includes(quantityType)),
                { min: doubleMin, max: doubleMax },
                exchangeRates, persons
            ) + sumPersons(
                price.itemPrices.filter(({ quantityType }) => !supplementPrices && (quantityType === 'per_person' || quantityType === 'per_person_night')),
                { min: doubleMin, max: doubleMax },
                exchangeRates
            ),
            group: price._summary.group + sumPersons(
                price.itemPrices.filter(({ quantityType }) => ['per_group', 'custom'].includes(quantityType)),
                { min: doubleMin, max: doubleMax },
                exchangeRates
            ),
            person: price._summary.person + sumPersons(
                price.itemPrices.filter(({ quantityType }) => quantityType === 'per_person' || quantityType === 'per_person_night'),
                { min: doubleMin, max: doubleMax },
                exchangeRates
            ) + sumPersons(
                price.itemPrices.filter(({ quantityType }) => ['per_group', 'custom'].includes(quantityType)),
                { min: doubleMin, max: doubleMax },
                exchangeRates, persons
            )
        },
    }))
};

export const addBusBuyPrice = function (prices, persons, exchangeRates, supplementPrices = true) {
    persons = persons ? persons : 1;
    return prices
        .map(price => {

            const busBuyPrice = Number(price.busBuyPrice.amount);
            const exchangeFactor = exchangeRates && price.busBuyPrice && exchangeRates[price.busBuyPrice.currency] ? Number(exchangeRates[price.busBuyPrice.currency]) : 0;

            return {
                ...price,
                _summary: {
                    ...price._summary,
                    group: price._summary.group + busBuyPrice * exchangeFactor,
                    person: price._summary.person + (busBuyPrice ? (busBuyPrice * exchangeFactor / persons) : 0),
                    ...!supplementPrices && {
                        single: price._summary.single + (busBuyPrice ? (busBuyPrice * exchangeFactor / persons) : 0)
                    },
                },

            };
        });
};

export const appendPersonPrice = function (prices, persons, supplementPrices = true) {
    //No exchange rate necessary since _summaries already in correct currency
    persons = persons ? persons : 1;
    return prices.map(price => {

        return {
        ...price,
        _summary: {
            ...price._summary,
            ...price._summary.group > 0 && {
                person: price._summary.group  / persons
            },
            ...!supplementPrices && price._summary.group > 0 && {
                single: price._summary.group  / persons
            },
        }
    }});
};

export const getItemPrices = function (placeholder) {
    return placeholder && placeholder.calculationRequest
        ? placeholder.calculationRequest.itemPrices
            .filter(itemPrice => itemPrice.inCalculation)
        : [];
};

export const getPackageItemPrices = function (pricePackage) {
    return pricePackage
        ? pricePackage.itemPrices
            .filter(itemPrice => itemPrice.inCalculation)
        : [];
};

export const getRequestAPI = function (request) {
    return `/api/${getRequestType(request)}_requests/${request.id}`;
};

export const sortHelper = function (arr, helper) {
    return [...arr].sort((a, b) => {
        if (a.placeholder && b.placeholder) {
            const aDate = fullYear(a.placeholder.startAt);
            const bDate = fullYear(b.placeholder.startAt);
            if (aDate === bDate) {
                return helper.indexOf(`${a._type}-${a.placeholder.id}`) < helper.indexOf(`${b._type}-${b.placeholder.id}`) ? -1 : 1;
            } else {
                return compareDates(aDate, bDate);
            }
        }

        return 0;
    });
};

export const withoutTreatPackages = function (arr) {
    return [...arr].filter(price => {
        const { calculationRequest } = price.placeholder;
        return calculationRequest === null || calculationRequest.package === null
    });
};

export const addItemPrices = function (prices) {
    return prices.map(price => {
        const nights = placeholderNights(price.placeholder);

        const itemPrices = getItemPrices(price.placeholder).map(price => ({
            ...price,
            nights: price.quantityType === 'per_single_room_night' || price.quantityType === 'per_person_night' ? nights : price.quantityType === 'custom' ? price.quantity : 1
        }));

        return {
            ...price,
            itemPrices
        };
    });
};
