var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hide
    ? _c(
        "row",
        [
          _c(
            "template",
            { slot: "type" },
            [
              _vm.persons > 2
                ? [
                    _vm._v("\n            " + _vm._s(_vm.persons) + " x "),
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("directions_train"),
                    ]),
                  ]
                : _vm._l(_vm.persons, function (n) {
                    return _c("i", { key: n, staticClass: "material-icons" }, [
                      _vm._v("directions_train"),
                    ])
                  }),
            ],
            2
          ),
          _vm._v(" "),
          _c("template", { slot: "name" }, [_vm._v(_vm._s(_vm.name))]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "marginTax" },
            [
              _c("margin-tax", {
                attrs: { tax: _vm.marginTax, margin: _vm.grossMargin },
                model: {
                  value: _vm.marginTaxEnabled,
                  callback: function ($$v) {
                    _vm.marginTaxEnabled = $$v
                  },
                  expression: "marginTaxEnabled",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showExchangeRates
            ? _c(
                "template",
                { slot: "buyPriceCalculation" },
                [
                  _c("input-price", {
                    model: {
                      value: _vm.buyPriceInCalculationCurrency,
                      callback: function ($$v) {
                        _vm.buyPriceInCalculationCurrency = $$v
                      },
                      expression: "buyPriceInCalculationCurrency",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "template",
            { slot: "buyPrice" },
            [
              _c("input-price", {
                attrs: { "is-blue": _vm.hasTwoCurrencies },
                model: {
                  value: _vm.buyPrice,
                  callback: function ($$v) {
                    _vm.buyPrice = $$v
                  },
                  expression: "buyPrice",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showExchangeRates
            ? _c(
                "template",
                { slot: "sellPriceCalculation" },
                [
                  _c("input-price", {
                    model: {
                      value: _vm.sellPriceInCalculationCurrency,
                      callback: function ($$v) {
                        _vm.sellPriceInCalculationCurrency = $$v
                      },
                      expression: "sellPriceInCalculationCurrency",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "template",
            { slot: "sellPrice" },
            [
              _c("input-price", {
                attrs: { "is-blue": _vm.hasTwoCurrencies },
                model: {
                  value: _vm.sellPrice,
                  callback: function ($$v) {
                    _vm.sellPrice = $$v
                  },
                  expression: "sellPrice",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showExchangeRates
            ? _c(
                "template",
                { slot: "profitCalculation" },
                [
                  _c("input-price", {
                    model: {
                      value: _vm.profitInCalculationCurrency,
                      callback: function ($$v) {
                        _vm.profitInCalculationCurrency = $$v
                      },
                      expression: "profitInCalculationCurrency",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "template",
            { slot: "profit" },
            [
              _c("input-price", {
                attrs: { "is-blue": _vm.hasTwoCurrencies },
                model: {
                  value: _vm.profit,
                  callback: function ($$v) {
                    _vm.profit = $$v
                  },
                  expression: "profit",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "margin" },
            [
              _c("input-price", {
                model: {
                  value: _vm.margin,
                  callback: function ($$v) {
                    _vm.margin = $$v
                  },
                  expression: "margin",
                },
              }),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }