import { CrudTable } from '@components/CRUD';
import CrudGrid from './Grid';

export default {
    components: { CrudTable, CrudGrid },

    data: function () {
        return {
            deleteItemObject: null
        };
    },

    methods: {
        openItemForm: function (data = {}) {
            if (!!this.$refs.itemForm) {
                this.$refs.itemForm.openModal(data);
            }
        },

        openDeleteForm: function (item) {
            this.deleteItemObject = JSON.parse(JSON.stringify(item));
        }
    },


    mounted: function () {
        let urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('add')) {
            this.openItemForm();
        }

        if (this.$options.page && this.$options.page !== '') {
            this.$layout.setPageName(this.$options.page);
        }
    }
};

