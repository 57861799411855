<template>
    <div class="tab-addition is-small">
        <div class="tab-addition__header">
            <header>
                <button
                    v-if="showOptionAll"
                    v-bind:class="{'is-active': section === 'all'}"
                    v-on:click="section = 'all'"
                    >Alle
                </button>
                <button v-for="year in availableYears"
                    v-bind:class="{'is-active': section === year}"
                    v-on:click="section = year"
                >{{year}}
                </button>
            </header>
        </div>
    </div>
</template>



<script>

import moment from "moment";


export default {



    props: {
        value: {
            type: Object,
            required: true,
        },

        filterStartEndKeys: {
            type: Array,
            required: true
        },

        years: {
            type: Array,
        },

        sectionDefault: {
            type: Number,
            required: false,
        },

        showOptionAll: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    data: function () {
        return {
            section: this.sectionDefault ? this.sectionDefault : 'all'
        }
    },

    watch: {
        'section': function (value) {
            if(value === 'all'){
                this.value[this.filterStartEndKeys[0]] = null;
                this.value[this.filterStartEndKeys[1]] = null;
            } else {
                this.value[this.filterStartEndKeys[0]] = moment('01.01.' + value, 'DD.MM.YYYY').format('DD.MM.YYYY');
                this.value[this.filterStartEndKeys[1]] = moment('31.12.' + value, 'DD.MM.YYYY').format('DD.MM.YYYY');
            }

            this.$emit('input', {...this.value})

        },
    },

    computed: {
        availableYears: function() {
            if(this.years){
                return this.years;
            }

            const endYear = parseInt(moment().format('YYYY')) + 2;
            const years = [];
                for(let i = 2019; i <= endYear; i++) {
                    years.push(i);
            }
            return years;
        },
    },


    methods: {

    }
}
</script>
