import moment from 'moment';
import { currentUser, getTranslationOptions } from '@utilities/functions';
import pick from "lodash/pick";
import {notifySuccess} from "@components/Notification";

export default {
    data: function () {
        return {
            emptyForm: {
                translations: {},
            },
            translationOptions: getTranslationOptions(),
            locale: 'de_DE',
        }
    },

    methods: {
        afterDataLoad: function () {
            //Needs to be added to function if function already exists in ItemForm.vue
            this.locale = this.defaultTranslation;


            if(!this.form.translations || Object.keys(this.form.translations).length === 0){
                this.form.translations = Object.assign({}, {   [this.defaultTranslation] : {}     });
                //this.form.translations.push({   [this.defaultTranslation] : {}     })
            }

            //this.translations = this.form.translations ? this.form.translations : [{"de_DE" : {}}];
        },
        update: function () { return new Promise(resolve => { resolve({data: {}}) }) }, // Override in component

        changeTranslation: function(translationArray)
        {
            let newLocale = translationArray[0];
            let translations = translationArray[1];
            this.isLoading = true;

            if(this.form.id){
                this.update({id: this.form.id, data: this.formData})
                    .then(response => {
                        let responseData = response.translations ? response : response.data;
                        this.isLoading = false;

                        if(responseData.translations) {
                            this.form.translations = responseData.translations;
                        }

                        if(!this.form.translations[newLocale]){
                            this.form.translations[newLocale] = {};
                        }

                        this.locale = newLocale;
                        //change form to new values
                        for (const prop in translations) {
                            if (Object.prototype.hasOwnProperty.call(translations, prop)) {
                                this.form[prop] = translations[prop];
                            }
                        }
                        notifySuccess('Übersetzung erfolgreich gespeichert!');
                    }, this.onError)
            } else {
                this.create( this.formData).then(response => {
                    let responseData = response.translations ? response : response.data;

                    this.isLoading = false;

                    this.$set(this.form, 'id', responseData.id)

                    if(responseData.translations) {
                        this.form.translations = responseData.translations;
                    }
                    this.form.translations[newLocale] = {};

                    this.locale = newLocale;
                    //change form to new values
                    for (const prop in translations) {
                        if (Object.prototype.hasOwnProperty.call(translations, prop)) {
                            this.form[prop] = translations[prop];
                        }
                    }
                    notifySuccess('Übersetzung erfolgreich gespeichert!');
                }, this.onError)
            }

        },
    },

    computed: {
        defaultTranslation: function() {
            const translation = this.translationOptions.find(item => item.isDefault);
            if(translation){
                return translation.code;
            }
            return 'de_DE';
        }
    },

    watch: {
        locale: function (locale) {
            /*if (locale) {

               this.form.dataCheckedAt = moment().format('DD.MM.YYYY HH:mm:ss');

                const userID = currentUser('id');
                if (!!userID) {
                    this.form.dataCheckedBy = `/api/users/${userID}`;
                }

            } else {
                this.form.dataCheckedAt = null;
                this.form.dataCheckedBy = null;
            }*/
        }
    }
}
