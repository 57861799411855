var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-addition is-small" }, [
    _c("div", { staticClass: "tab-addition__header" }, [
      _c(
        "header",
        [
          _vm.showOptionAll
            ? _c(
                "button",
                {
                  class: { "is-active": _vm.section === "all" },
                  on: {
                    click: function ($event) {
                      _vm.section = "all"
                    },
                  },
                },
                [_vm._v("Alle\n            ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.availableYears, function (year) {
            return _c(
              "button",
              {
                class: { "is-active": _vm.section === year },
                on: {
                  click: function ($event) {
                    _vm.section = year
                  },
                },
              },
              [_vm._v(_vm._s(year) + "\n            ")]
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }