import Days from '@components/Days';
import { InputMonth, Multiselect } from '@components/form';

import { daysToNumbers, numbersToDays } from '@utilities/functions';

export default {
    props: {
        value: {},
        options: {
            type: Object,
            required: true
        }
    },

    computed: {
        filters: {
            get: function () {
                return this.value;
            },

            set: function (values) {
                this.$emit('input', Object.assign({}, this.value, values));
            }
        },

        days: {
            get: function () {
                return daysToNumbers(this.filters);
            },

            set: function (v) {
                this.filters = Object.assign({}, this.filters, numbersToDays(v));
            }
        },
    },

    methods: {
        clearStartAt: function () {
            if (this.$refs.startAt) {
                this.$refs.startAt.clearDate();
            }
        },
    },

    components: {
        Days,
        Multiselect,
        InputMonth,
    }
}
