var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "bp-box is-info" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("main", [
        !_vm.request.ferry || _vm.editMode
          ? _c(
              "div",
              { staticClass: "is-one-row" },
              [
                _c(
                  "button",
                  {
                    class: [
                      "btn-single-toggle",
                      _vm.onlyFromOrder ? "" : "is-active",
                    ],
                    staticStyle: {
                      border: "1px solid #dddddd",
                      "margin-right": "5px",
                      "min-width": "22px",
                      "min-height": "22px",
                    },
                    attrs: {
                      title: _vm.onlyFromOrder
                        ? "Suche ist auf obigen Route eingeschränkt"
                        : "Suche ist nicht eingeschränkt",
                    },
                    on: {
                      click: function ($event) {
                        _vm.onlyFromOrder = !_vm.onlyFromOrder
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        staticStyle: { margin: "0" },
                      },
                      [_vm._v("flag")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("ferry-select", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        placeholderInfo: _vm.onlyFromOrder
                          ? _vm.localPlaceholderInfo
                          : { startPort: null, endPort: null, type: null },
                        nullable: false,
                        autoSelect: false,
                      },
                      model: {
                        value: _vm.request.ferry,
                        callback: function ($$v) {
                          _vm.$set(_vm.request, "ferry", $$v)
                        },
                        expression: "request.ferry",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.editMode
                  ? _c("agency-select", {
                      attrs: {
                        value: _vm.request.agency,
                        provider: null,
                        text: "Agentur",
                        prefill: "",
                      },
                      on: { update: (agency) => (_vm.request.agency = agency) },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "is-one-row" }, [
          _c("div", [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "select" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.request.requestStatus.status,
                      expression: "request.requestStatus.status",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.request.requestStatus,
                        "status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _vm._l(_vm.requestStatuses, function (status) {
                    return [
                      _c("option", { domProps: { value: status.key } }, [
                        _vm._v(_vm._s(status.value)),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("i", { staticClass: "material-icons" }, [
                _vm._v("notifications_none"),
              ]),
              _vm._v(" Termine:\n                    "),
              _vm.request.todos.length > 1
                ? _c("multiselect", {
                    staticClass: "is-shorter",
                    attrs: {
                      options: _vm.request.todos,
                      label: "name",
                      "track-by": "id",
                    },
                    model: {
                      value: _vm.request.optionTodo,
                      callback: function ($$v) {
                        _vm.$set(_vm.request, "optionTodo", $$v)
                      },
                      expression: "request.optionTodo",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("datepicker", {
                attrs: {
                  inputClass: ["is-date"],
                  config: {
                    dateFormat: "d.m.Y H:i:S",
                    wrap: true,
                    parseDate: function (dateStr) {
                      return _vm.moment(dateStr, "DD.MM.YYYY hh:mm:ss").toDate()
                    },
                    formatDate: function (dateObj) {
                      return _vm.moment(dateObj).format("DD.MM.YYYY hh:mm:ss")
                    },
                    allowInput: true,
                    altInput: true,
                  },
                  placeholder: "Deadline",
                },
                model: {
                  value: _vm.deadline,
                  callback: function ($$v) {
                    _vm.deadline = $$v
                  },
                  expression: "deadline",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _vm._m(2),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.request.shipName,
                  expression: "request.shipName",
                },
              ],
              staticClass: "input",
              attrs: { type: "text" },
              domProps: { value: _vm.request.shipName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.request, "shipName", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._m(3),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.request.optionNr,
                  expression: "request.optionNr",
                },
              ],
              staticClass: "input",
              attrs: { type: "text" },
              domProps: { value: _vm.request.optionNr },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.request, "optionNr", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm._m(4),
              _vm._v(" "),
              _c("multiselect", {
                staticClass: "is-shorter",
                staticStyle: { "min-width": "180px" },
                attrs: {
                  options: this.request.agency
                    ? this.agencyContactOptions
                    : this.providerContactOptions,
                  "track-by": "id",
                  "custom-label": (contact) => `${contact.fullName}`,
                  "to-be-copied": false,
                },
                model: {
                  value: _vm.request.requestContact,
                  callback: function ($$v) {
                    _vm.$set(_vm.request, "requestContact", $$v)
                  },
                  expression: "request.requestContact",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.showAssignedContactAddress
          ? _c("div", { staticClass: "is-last-row" }, [
              _c("div", [
                _vm._m(5),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button is-icon-button",
                    class: {
                      "is-active":
                        _vm.request.visibleContactDetails.includes("address"),
                    },
                    attrs: { title: "Adresse anzeigen" },
                    on: {
                      click: function ($event) {
                        return _vm.setVisibleContactDetails("address")
                      },
                    },
                  },
                  [_c("i", { staticClass: "material-icons" }, [_vm._v("home")])]
                ),
              ]),
              _vm._v(" "),
              _vm.request.visibleContactDetails.includes("address")
                ? _c(
                    "div",
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("home"),
                      ]),
                      _vm._v(" Adresse\n                    "),
                      _c("multiselect", {
                        staticClass: "is-shorter",
                        staticStyle: { "min-width": "150px" },
                        attrs: {
                          options: _vm.providerAddressOptions,
                          "track-by": "id",
                          "custom-label": (address) =>
                            (address.title
                              ? address.title + ": " + address.streetAddress
                              : address.streetAddress
                            ).substring(0, 50),
                          "to-be-copied": false,
                          placeholder: "Keine Adresse",
                        },
                        model: {
                          value: _vm.request.assignedAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.request, "assignedAddress", $$v)
                          },
                          expression: "request.assignedAddress",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.request.visibleContactDetails.includes("address")
                ? _c("div", [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("list"),
                    ]),
                    _vm._v(" Kontaktdetails sichtbar:\n                    "),
                    _vm.request.assignedContact &&
                    _vm.request.assignedContact.phoneFixed
                      ? _c(
                          "button",
                          {
                            staticClass: "button is-icon-button",
                            class: {
                              "is-active":
                                _vm.request.visibleContactDetails.includes(
                                  "phoneFixed"
                                ),
                            },
                            attrs: { title: "Festnetz anzeigen" },
                            on: {
                              click: function ($event) {
                                return _vm.setVisibleContactDetails(
                                  "phoneFixed"
                                )
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("phone"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.request.assignedContact &&
                    _vm.request.assignedContact.phoneMobile
                      ? _c(
                          "button",
                          {
                            staticClass: "button is-icon-button",
                            class: {
                              "is-active":
                                _vm.request.visibleContactDetails.includes(
                                  "phoneMobile"
                                ),
                            },
                            attrs: { title: "Mobile anzeigen" },
                            on: {
                              click: function ($event) {
                                return _vm.setVisibleContactDetails(
                                  "phoneMobile"
                                )
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("smartphone"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "columns" },
      [
        _vm.request.type === "night"
          ? [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _vm.request.contingent
                    ? _c("simple-ferry-contingent", {
                        attrs: {
                          type: "ferry",
                          nrOfPersons: 2,
                          types: _vm.ferryTypes,
                          isInPackage: _vm.request.package ? true : false,
                        },
                        model: {
                          value: _vm.request.contingent.doubleCabins,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.request.contingent,
                              "doubleCabins",
                              $$v
                            )
                          },
                          expression: "request.contingent.doubleCabins",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column" },
                [
                  _vm.request.contingent
                    ? _c("simple-ferry-contingent", {
                        attrs: {
                          type: "ferry",
                          nrOfPersons: 1,
                          types: _vm.ferryTypes,
                          isInPackage: _vm.request.package ? true : false,
                        },
                        model: {
                          value: _vm.request.contingent.singleCabins,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.request.contingent,
                              "singleCabins",
                              $$v
                            )
                          },
                          expression: "request.contingent.singleCabins",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column" },
                [
                  _vm.request.contingent
                    ? _c("ferry-contingent", {
                        attrs: {
                          type: "ferry",
                          types: _vm.ferryTypes,
                          isInPackage: _vm.request.package ? true : false,
                        },
                        model: {
                          value: _vm.request.contingent.otherCabins,
                          callback: function ($$v) {
                            _vm.$set(_vm.request.contingent, "otherCabins", $$v)
                          },
                          expression: "request.contingent.otherCabins",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          : [
              _c("div", { staticClass: "column" }, [
                _c("div", { staticClass: "bp-box is-tickets" }, [
                  _c("header", [
                    _vm._m(6),
                    _vm._v(" "),
                    _vm._m(7),
                    _vm._v(" "),
                    _c("div", [
                      !_vm.request.package
                        ? _c(
                            "button",
                            {
                              attrs: {
                                title:
                                  "Extraticket/-kabine für Reisebegleitung",
                              },
                              on: { click: _vm.addExtra },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("person_add"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("main", [
                    _c("div", [
                      _vm._m(8),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.request.contingent.ticketsReserved,
                              expression: "request.contingent.ticketsReserved",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "input",
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.request.contingent.ticketsReserved,
                          },
                          on: {
                            focus: function ($event) {
                              return $event.target.select()
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.request.contingent,
                                "ticketsReserved",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.request.contingent.ticketsAsked,
                              expression: "request.contingent.ticketsAsked",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "input",
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.request.contingent.ticketsAsked,
                          },
                          on: {
                            focus: function ($event) {
                              return $event.target.select()
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.request.contingent,
                                "ticketsAsked",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            attrs: { title: "Copy value" },
                            on: { click: _vm.copyDayFerryContingent },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("check_circle"),
                            ]),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "fa fa-long-arrow-left",
                              attrs: { "aria-hidden": "true" },
                            }),
                            _vm._v(" "),
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("help"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.hasExtra && !_vm.request.package
                      ? _c("div", { staticClass: "extra-people" }, [
                          _c("div", [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("person_add"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.request.contingent
                                      .ticketsExtraDescription,
                                  expression:
                                    "request.contingent.ticketsExtraDescription",
                                },
                              ],
                              staticClass: "input",
                              attrs: { type: "text" },
                              domProps: {
                                value:
                                  _vm.request.contingent
                                    .ticketsExtraDescription,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.request.contingent,
                                    "ticketsExtraDescription",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn-delete",
                                attrs: { title: "Löschen", tabindex: "-1" },
                                on: { click: _vm.deleteExtra },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("delete"),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value:
                                      _vm.request.contingent
                                        .ticketsExtraReserved,
                                    expression:
                                      "request.contingent.ticketsExtraReserved",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass: "input",
                                attrs: { type: "text" },
                                domProps: {
                                  value:
                                    _vm.request.contingent.ticketsExtraReserved,
                                },
                                on: {
                                  focus: function ($event) {
                                    return $event.target.select()
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.request.contingent,
                                      "ticketsExtraReserved",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value:
                                      _vm.request.contingent.ticketsExtraAsked,
                                    expression:
                                      "request.contingent.ticketsExtraAsked",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass: "input",
                                attrs: { type: "text" },
                                domProps: {
                                  value:
                                    _vm.request.contingent.ticketsExtraAsked,
                                },
                                on: {
                                  focus: function ($event) {
                                    return $event.target.select()
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.request.contingent,
                                      "ticketsExtraAsked",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "button",
                                attrs: { title: "Copy value" },
                                on: {
                                  click: function ($event) {
                                    _vm.request.contingent.ticketsExtraReserved =
                                      _vm.request.contingent.ticketsExtraAsked
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("check_circle"),
                                ]),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "fa fa-long-arrow-left",
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(" "),
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("help"),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ],
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column is-one-third" },
        [
          _c("tip-tap", {
            model: {
              value: _vm.request.notes,
              callback: function ($$v) {
                _vm.$set(_vm.request, "notes", $$v)
              },
              expression: "request.notes",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _vm.request.package
            ? _c("item-prices", {
                attrs: {
                  "is-package": true,
                  isWithoutPrice: false,
                  provider: _vm.provider,
                },
                model: {
                  value: _vm.request.package.itemPrices,
                  callback: function ($$v) {
                    _vm.$set(_vm.request.package, "itemPrices", $$v)
                  },
                  expression: "request.package.itemPrices",
                },
              })
            : _c("item-prices", {
                attrs: { isWithoutPrice: false, provider: _vm.provider },
                model: {
                  value: _vm.request.itemPrices,
                  callback: function ($$v) {
                    _vm.$set(_vm.request, "itemPrices", $$v)
                  },
                  expression: "request.itemPrices",
                },
              }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm._m(9),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("info")]),
      _vm._v(" Informationen zur Anfrage"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("import_contacts")]),
      _vm._v(" Status:"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("assignment")]),
      _vm._v(" Schiff:"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("i", { staticClass: "material-icons" }, [
        _vm._v("format_list_numbered"),
      ]),
      _vm._v(" Reservierungs-Nr:"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("contact_phone")]),
      _vm._v(" Ansprechpartner:"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("list")]),
      _vm._v(" Für Kunde sichtbar:"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("credit_card")]),
      _vm._v(" Tickets Tagfähre"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("check_circle")]),
      _vm._v(" "),
      _c("i", { staticClass: "material-icons" }, [_vm._v("help")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("accessibility")]),
      _vm._v(" Pax"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "explanation" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("check_circle")]),
      _vm._v(" = bestätigtes Kontingent\n        "),
      _c("i", { staticClass: "material-icons" }, [_vm._v("help")]),
      _vm._v(" = angefragtes Kontingent\n        "),
      _c("i", { staticClass: "material-icons" }, [_vm._v("person_add")]),
      _vm._v(" = Extrazimmer für Busfahrer, Reiseleiter, etc.\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }