export const settings = {
    modules: {
        websiteExport: false,
        webflow: true,
    },
    order: {
        contacts:  {
            hideBus: true,
        },
        modal: {
            assignedContactAddress: true,
        }
    },
    offer: {
        tabs: {
            offer: true,
            freePlaces : true,
            cancelation: true,
            payments: true,
            conditions: true,
            other: false,
        },
        freePlaces: {
            showRoomType: true
        },
        bullets: {
            show: true
        },
        generationTypes: {},
    },
    userDashboard: {
        resetFilterToToday: true
    },
    emails: {
        organisationHosts : ['@buspaket.de', '@we-tours.com', '@we-tours.de']
    },
    calculation: {
        singleRoomLimit: true,
    },
    providerInvoice: {
        hasInvoiceDetails: true,
        startSection: 'InvoiceList'
    },
    clientInvoice: {
        disableCompleted: true,
        fields: {
            tax: [],
            dueDate: true,
            contact: true,
            introText: true,
            endText: true,
            signature: true
        },
        columns: [
            { key: 'favicon', label: 'User', class: 'is-user-cell', sortable: 'order.user.username' },
            { key: 'invoiceNumber', filter: 'invoiceNumber', label: 'R-NR' },
            { key: 'order', filter: 'order.id', label: 'Reisenummer' },
            { key: 'order.client.shortName', label: 'Kunde' },
            { key: 'order.startAt', label: 'Reisebeginn' },
            { key: 'order.endAt', label: 'Reiseende' },
            { key: 'invoiceDate', filter: 'invoiceDate', label: 'Rechnungsdatum' },
            { key: 'dueDate', filter: 'dueDate', label: 'Zahlungsziel', class: 'is-120px-cell is-editable' },
            { key: 'amount.formatted', filter: 'amount.formatted', label: 'Rechnungsbetrag' },
            { key: 'paidAmount.formatted', filter: 'paidAmount.formatted', label: 'Bezahlt' },
            { key: 'openAmount.formatted', label: 'Saldo' },
            { key: 'status', filter: 'status', label: 'Status', hideContent: true, class: 'is-medium-cell is-editable' },
        ],
        orderBy: {
            property: 'id',
            direction: 'DESC'
        },
        mainCurrency: null,
        exportCurrentInvoice: [],
    },
    serviceInvoice: {
        disableCompleted: true,
        form: {
            issuer: true,
            bankAccount: true,
            type: 'final_payment',
            invoiceAmount: true,
            invoiceNumber: true,
            invoiceDate: true,
            dueDate: true,
            status: false,
            notes: true,
            document: true,
            organisationBankAccount: false,
            checkNumber: false,
        }
    },
    voucher: {
        translations: true,
        translationsInOne: false,
    },
    roomList: {
        create: {
            fields: {
                notes: true
            }
        },
        templateA: {
            fields: {
                extraType: true,
                twin: true,
                gender: false,
                firstName1: true,
                lastName: true,
                firstName2: false,
                nationality: false,
                dateOfBirth: false,
                placeOfBirth: false,
                documentType: false,
                documentNumber: false,
                assistance: false,
                outsideCabin: false,
                notes: true
            }
        },
        templateB: {
            fields: {
                extraType: true,
                twin: false,
                gender: true,
                firstName1: true,
                lastName: true,
                firstName2: false,
                nationality: true,
                dateOfBirth: true,
                placeOfBirth: true,
                documentType: true,
                documentNumber: true,
                assistance: true,
                outsideCabin: true,
                notes: true
            }
        }
    },
    liquidityPlan: {
        isUsed: false,
        customExports: [
            {
                active: true,
                type: 'monthly',
                label: 'V & V erstellen',
                url: '/api/finances/custom-statement/generate-excel',
                downloadName: 'Vermietung und Verpachtung Kirchheim'
            }
        ],
    },
};

