<template>
    <div class="cell-content is-full is-flex is-align-items-center">
        <a class="tag is-white p-1 mr-1 has-text-grey-dark" style="text-decoration: none;" v-if="row.link && row.buttonLabel" v-bind:href="row.link" target="_blank">
            {{ row.buttonLabel }}
        </a>
            <span>{{ value }}</span>
    </div>
</template>

<script>
import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {

        activity() {
            return this.row.activity
        },
    },
}
</script>
