<template>
    <crud-table
        ref="table"

        api="users"
        :storeKey="`clients/${item.id}/organisations`"

        :filter="{'organisations.id': item.id}"
        :loadOptions="false"
        label="Stammdaten"
        :name="{singular: 'user', plural: 'users'}"
        :delete-row="false"

        :columns="[
                    'username',
                    'firstName',
                    'lastName',
                    'email',
                    'lastLogin'
                ]"
    >
        <h2 slot="header">REISEÜBERSICHT</h2>
    </crud-table>
</template>

<script>
import { CrudTable } from '@components/CRUD';
import tab from '../tab';

export default {
    mixins: [tab],

    components: {
        CrudTable
    },
}
</script>
