import { store }           from '@orders/store';
import { getValueWithKey , getAccommodationLabel} from '@utilities/functions';
//import { InputPrice }      from '@components/form';
import InputPrice from '@calculations/components/InputPrice';

import ItemPrices          from '../ItemPrices';
import FreePlaces          from './FreePlaces';


export default {
    store: store,


    props: {
        value: { type: Object, required: true },
        calculationRequest: {}
    },


    computed: {
        isCalculationRequest: function () {
            return this.calculationRequest && this.calculationRequest.id === this.request.id
        },
    },


    data: function () {
        return {
            request: JSON.parse(JSON.stringify(this.value))
        };
    },


    methods: {
        getAccommodationLabel,

        getDifference: function (accomodation) {
            //this.$nextTick(() => {
                let difference = parseFloat(accomodation.price.amount);
                // this.$set(accomodation, 'difference', Number(difference));

                return difference;
            //});
        },


        setPrice: function (accomodation, newDifference) {
            this.$set(accomodation, 'difference', Number(newDifference));
            accomodation.price = (this.standardPrice + parseFloat(newDifference)).toFixed(2);
        },


        updatePrice: function (accomodations, isDouble = false) {
            for (let i = 0, len = accomodations.length; i < len; i++) {
                if (!isDouble || i !== this.standardIndex) {
                    let difference = accomodations[i].difference ? accomodations[i].difference : 0;

                    accomodations[i].price = (Number(this.standardPrice) + difference).toFixed(2);
                }
            }
        },

        openMasterPrices: function () {
            this.$refs.masterPrices.open();
        },

        openCalculationPrices: function () {
            this.$refs.calculationPrices.open();
        },

        getValueWithKey
    },


    watch: {
        request: {
            handler: function () {
                this.$emit('input', this.request);
            },
            deep: true
        }
    },


    components: {
        FreePlaces,
        InputPrice,
        ItemPrices
    }
};
