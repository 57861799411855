<template>
    <i  
        v-if="isChecked"
        class="material-icons"
    >check</i>
</template>

<script>

import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        isChecked: function () {
            return !!this.value
        }
    }
}
</script>

