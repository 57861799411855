<template>
    <div>


        <div class="bp-box is-info">
            <header><i class="material-icons">view_quilt</i> Abrechnungsart der Leistung</header>

            <main>
                <label class="has-input-radio"><input type="radio" v-bind:name="'item_prices_ferry' + request.id" v-bind:checked="request.package === null" v-on:change="setAsIndividual(request.id)" /> Einzelabrechnung</label>
                <label class="has-input-radio"><input type="radio" v-bind:name="'item_prices_ferry' + request.id" v-bind:checked="request.package" v-on:change="request.package = { id: -1 }" /> im Paket</label>
            </main>
        </div>


        <individually
            v-if="request.package === null"
            :calculationRequest="calculationRequest"
            :placeholder="placeholder"
            v-model="request"
            ref="individually"
        ></individually>

        <package
            v-else
            v-model="request"
            :placeholder="placeholder"
        ></package>


        <p class="explanation">
            <template v-if="isCalculationRequest">
                <i class="fa fa-calculator has-text-active"></i> = für Kalkulation verwenden
            </template>
            <i class="fa fa-info" aria-hidden="true"></i> = Kunde zahlt selbst vor Ort (nur Info)
            <i class="material-icons">shopping_cart</i> = von uns bezahlt und im Paketpreis inkludiert
            <i class="material-icons">playlist_add</i> = gesondert berechnet
        </p>
    </div>
</template>



<script>
    // Individually will be overridden in each individual case
import Individually from './hotels/Individually';
import Package      from './Package';


export default {
    props: {
        value: { type: Object, required: true },
        calculationRequest: { },
        placeholder: {}
    },

    computed: {
        isCalculationRequest: {
            get: function () { return this.calculationRequest && this.calculationRequest.id === this.request.id },
        }
    },


    data: function () {
        return {
            request: JSON.parse(JSON.stringify(this.value)),
        }
    },

    methods: {
        setAsIndividual: function (requestID) {
            this.request.package = null;
        },
    },


    watch: {
        request: {
            handler: function () {
                this.$emit('input', this.request);
            },
            deep: true
        }
    },


    components: {
        Individually,
        Package
    }
}
</script>
