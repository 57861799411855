<template>
    <div>
        <div class="row is-headline is-small">
            <div class="level level-left mb-0" v-on:click.prevent.stop="toggleCollapse">
                <i
                    v-if="hasPrices"
                    class="collapse-icon material-icons level-item"
                    v-bind:class="{'is-rotated' : !isOpen}"
                >keyboard_arrow_up</i>
                <i
                    v-else
                    class="material-icons level-item"
                >close</i>
                <span class="level-item">{{route.route.name}}</span>
            </div>
            <div>
                <i class="fa fa-calendar-plus-o fa-lg" v-on:click="$emit('addPriceGroup')"></i>
                <i class="material-icons is-pointer" v-on:click="$emit('editRoute')">edit</i>
                <i class="material-icons" v-on:click="$emit('deleteTrainRoute')">delete</i>
            </div>
        </div>

        <template v-if="isOpen">
            <price-group-row
                v-for="priceGroup in groups"
                v-bind:key="priceGroup.id"
                v-bind:priceGroup="priceGroup"
                v-bind:options="options"
                v-bind:show-next-row="route.groups.length < 2"
                price-type="train"

                v-on:updatePriceGroup="updatePriceGroup"

                v-on:editPriceGroup="$emit('editPriceGroup', priceGroup)"
                v-on:deletePriceGroup="$emit('deletePriceGroup', priceGroup)"
            >

        </price-group-row>
        </template>

    </div>
</template>

<script>

import PriceGroupRow from './PriceGroup';

import Mixins from "./mixins";
import Template from "@orders/subpages/TripDetails/General/Template";
import {deleteTrainRoute} from "@utilities/api";
import {compareDates, fullYear} from "@utilities/functions";

export default {
    mixins: [Mixins],

    props: {
        route: {
            type: Object,
            required: true
        },

        options: {
            type: Object,
        }
    },

    methods: {
        addPrice: function() {

        },



        updatePriceGroup: function(group) {

            const route = this.route;
            const index = route.groups.findIndex(oldGroup => oldGroup.id === group.id)
            route.groups.splice(index, 1, {
                ...group,
            })

            this.$emit('updateRoute', route)
        }
    },

    computed: {
        hasPrices: function() {
            return this.route.groups.length > 0
        },

        groups: function () {
            return this.route.groups;
        },
    },

    components: {
        Template,
        PriceGroupRow
    },

    filters: {
        time: function(string) {
            return string.substring(0, 5);
        }
    }
}
</script>
