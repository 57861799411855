var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "crud-table",
    {
      ref: "table",
      attrs: {
        api: "day_concepts",
        url: "/concepts/days",
        storeKey: `otherServiceTypes/${_vm.item.id}/dayConcepts`,
        filter: { "otherPlaceholders.otherServiceType.id": _vm.item.id },
        label: "Stammdaten",
        name: { singular: "tageskonzept", plural: "tageskonzepten" },
        columns: [
          {
            key: "name",
            label: "Name",
            searchable: true,
            class: "is-one-quarter",
          },
          {
            key: "included",
            sortable: false,
            label: "inkl",
            class: "is-one-fifth",
          },
          {
            key: "optional",
            sortable: false,
            label: "Optional",
            class: "is-one-fifth",
          },
          { key: "subtitle", class: "is-one-tenth" },
          { key: "category", filter: "category" },
          { key: "destinations", filter: "destinations.id" },
          {
            key: "checkedCatalogText",
            filter: "checkedCatalogText",
            sortable: false,
            class: "is-one-twentieth",
          },
          {
            key: "dataCheckedAt",
            filter: "checkedAt",
            class: "is-one-twentieth",
          },
        ],
      },
      scopedSlots: _vm._u([
        {
          key: "included",
          fn: function ({ row }) {
            return [_vm._v("\n        " + _vm._s(_vm.included(row)) + "\n    ")]
          },
        },
        {
          key: "optional",
          fn: function ({ row }) {
            return [_vm._v("\n        " + _vm._s(_vm.optional(row)) + "\n    ")]
          },
        },
      ]),
    },
    [
      _c("h2", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Verknüpfungen mit Tageskonzepten"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }