var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.showAirportSelects ? "has-3-multiselects" : "" },
    [
      _vm.showAirportSelects
        ? _c("airport-select", {
            staticClass: "has-margin-right",
            attrs: {
              api: "airports",
              "custom-label": (airport) =>
                airport.name + " - " + airport.place.name,
              crudLinkTitle: "Fügen Sie einen neuen Flughafen hinzu",
              "allow-empty": false,
              clearable: false,
              placeholder: "Flughafen finden",
            },
            model: {
              value: _vm.startAirport,
              callback: function ($$v) {
                _vm.startAirport = $$v
              },
              expression: "startAirport",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAirportSelects
        ? _c("airport-select", {
            staticClass: "has-margin-right",
            attrs: {
              api: "airports",
              "custom-label": (airport) =>
                airport.name + " - " + airport.place.name,
              crudLinkTitle: "Fügen Sie einen neuen Flughafen hinzu",
              "allow-empty": false,
              clearable: false,
              placeholder: "Flughafen finden",
            },
            model: {
              value: _vm.endAirport,
              callback: function ($$v) {
                _vm.endAirport = $$v
              },
              expression: "endAirport",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "multiselect",
        {
          staticClass: "has-margin-right",
          style: _vm.multiSelectStyle,
          attrs: {
            options: _vm.items,
            loading: _vm.isLoadingItems,
            "allow-empty": false,
            placeholder: "tippen, um Fluggesellschaft zu finden",
            label: "name",
            "track-by": "id",
            "group-values": "items",
            "group-label": "type",
            "select-label": "",
            "deselect-label": "Löschen",
          },
          model: {
            value: _vm.airlineSelected,
            callback: function ($$v) {
              _vm.airlineSelected = $$v
            },
            expression: "airlineSelected",
          },
        },
        [
          _c("template", { slot: "noResult" }, [
            _vm._v("Keine passenden Fluggesellschaft"),
          ]),
          _vm._v(" "),
          _vm.items.length === 0
            ? _c("span", { attrs: { slot: "caret" }, slot: "caret" })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }