<script>
import mixin from './mixin'
export default {
    mixins: [ mixin ],

    icon: 'airplanemode_active',

    computed: {
        hasPersonPrice: function () { return true },
        personPrice: {
            get: function () {
                return this.price.buyPrice.amount
            },
            set: function (value) {
                this.$store.commit('updatePrice', {
                    airlinePrices: {
                        id: this.price.id,
                        buyPrice: {
                            amount: value,
                            currency: this.placeholder.placeholderCurrency
                        }
                    }
                })
            }
        },
        hasSinglePrice: function () { return !this.supplementPrices },

        isNight: function () { return false },

        singlePrice: function () {
            return !this.supplementPrices ? this.personPrice : 0;
        }
    }
}
</script>
