var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("freeplaces-table", {
    attrs: {
      hotel: _vm.isHotel,
      ferry: _vm.isFerry,
      freePlaces: _vm.item.freePlaces || [],
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }