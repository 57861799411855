import { getValueWithKey, dayMonth, fullYear } from '@utilities/functions';
import Deadline  from '@components/Deadline';
import { store } from '@orders/store';
import TimeOfDay from './TimeOfDay';
import AgencyAndProvider from './modules/AgencyAndProvider';
import ConceptProvider from "@orders/placeholders/components/views/modules/ConceptProvider";



export default {
    store: store,


    props: {
        placeholder: { type: Object,  required: true },
        isRouteView: { type: Boolean, required: true },
        isConcept:   { type: Boolean, default: false },
        showDuration:  { type: Boolean, default: true }
    },


    computed: {
        totalPersonsAsked:    function () { return this.$store.state.hotelContingent.info.personsAsked; },
        hasContingentWarning: function () { return this.placeholder.info.personsReserved < this.totalPersonsAsked; },
        statusesList:         function () { return this.$store.state.options.requestStatuses; }
    },


    methods: {
        getValueWithKey,
        fullYear
    },


    filters: {
        dayMonth,
        fullYear
    },


    components: {
        AgencyAndProvider,
        Deadline,
        TimeOfDay,
        ConceptProvider
    }
};
