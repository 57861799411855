<template>
    <div>
        <main-object
            v-bind:headline="headline"
            v-bind:levels="levels"
            v-bind:priceType="priceType"
            v-bind:providerType="providerType"
            v-bind:show-filters="showFilters"
            v-bind:filter-start-end-keys="filterStartEndKeys"
            v-on:openForm="openPriceForm"
            ref="mainObject"
        />
        <item-form ref="form" v-on:submit="handleSubmit" v-bind:provider-type="providerType"/>
    </div>



</template>

<script>

import Mixin from '../tab';
import PriceMixin from './mixins'
import ItemForm from './Forms/Hotel';
import MainObject from "@components/CRUD/Item/Tabs/ProviderPrices/Main";
import moment from "moment";



export default {
    mixins: [ Mixin, PriceMixin ],

    components: {MainObject, ItemForm},

    data: function () {
        return {
            headline: 'Preise für die Hotelübernachtung',
            levels: ['level', 'board', 'priceGroup', 'Price'],
            priceType: 'hotel',
            providerType: 'agency',
            showFilters: {
                'timePeriods.startAt[before]': '',
                'timePeriods.endAt[after]': '',
                board: "",
                area: ""
            },
            filterStartEndKeys: ['timePeriods.endAt[after]', 'timePeriods.startAt[before]'],
        }
    },


}
</script>
