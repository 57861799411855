<template>
    <div>
        <div class="columns"  v-if="placeholder.type === 'night'">
            <div class="column">
                <div class="bp-box is-price">
                    <header>
                        <div><i class="material-icons no-spacing">directions_train</i><i class="material-icons">directions_train</i> <b>Doppelabteil</b></div>
                    </header>

                    <main>
                        <!-- Standard -->
                        <div class="is-standard calculation">
                            <div>
                                {{ getAccommodationLabel(standardDoubleCabin.type.type, standardDoubleCabin.type.persons, trainCabinTypes)}}
                            </div>
                            <input-price
                                v-model="standardDoublePrice"
                                :provider-prices="standardDoubleCabin.providerPrices"
                                :small-input="true"
                                :price-currency="request.requestCurrency"
                            />
                        </div>


                        <!-- The rest -->
                        <div
                            v-for="accomodation in doubleCabins"
                            v-bind:key="accomodation.id"
                            class="calculation"
                        >

                            <div>
                                {{ getAccommodationLabel(accomodation.type.type, accomodation.type.persons, trainCabinTypes)}}
                            </div>
                            <input-price
                                :provider-prices="accomodation.providerPrices"
                                :small-input="true"
                                v-bind:value="getDifference(accomodation)"
                                v-on:input="(v) => { updatePrices(v, accomodation, 'doubleCabins') }"
                                :price-currency="request.requestCurrency"
                            />
                        </div>
                    </main>
                </div>
            </div>


            <div class="column">
                <div class="bp-box is-price calculation">
                    <header>
                        <div><i class="material-icons">directions_boat</i> <b>Einzelabteil</b></div>
                    </header>

                    <main>
                        <div v-for="accomodation in singleCabins" v-bind:key="accomodation.id">
                            <div>
                                {{ getAccommodationLabel(accomodation.type.type, accomodation.type.persons, trainCabinTypes)}}
                            </div>
                            <input-price
                                :provider-prices="accomodation.providerPrices"
                                :small-input="true"
                                v-bind:value="getDifference(accomodation)"
                                v-on:input="(v) => { updatePrices(v, accomodation, 'singleCabins') }"
                                :price-currency="request.requestCurrency"
                            />
                        </div>
                    </main>
                </div>
            </div>


            <div class="column">
                <div class="bp-box is-price calculation">
                    <header>
                        <div><i class="material-icons">help</i> <b>Sonstige Preise</b></div>
                    </header>
                        <main>
                            <div v-for="accomodation in otherCabins" v-bind:key="accomodation.id">
                                <div>
                                    {{ getAccommodationLabel(accomodation.type.type, accomodation.type.persons, trainCabinTypes)}}
                                </div>
                                <input-price
                                    :provider-prices="accomodation.providerPrices"
                                    :small-input="true"
                                    v-bind:value="getDifference(accomodation)"
                                    v-on:input="(v) => { updatePrices(v, accomodation, 'otherCabins') }"
                                    :price-currency="request.requestCurrency"
                                />
                            </div>
                        </main>
                </div>
            </div>
        </div>

        <div class="columns" v-else>
            <div class="column">
                <div class="bp-box is-price calculation">
                    <header><div><i class="material-icons">credit_card</i> Zugtickets</div></header>

                    <main>
                        <div class="is-standard calculation">
                            <div>
                                {{ getAccommodationLabel(this.standardCategory, 1, dayTrainCabinTypes)}}
                            </div>
                            <input-price
                                v-model="standardSinglePrice"
                                :provider-prices="standardSingleCabin.providerPrices"
                                :small-input="true"
                                :price-currency="request.requestCurrency"
                            />
                        </div>
                        <div class="calculation" v-if="placeholder.category !== this.standardCategory">
                            <div>
                                {{ getAccommodationLabel('first', 1, dayTrainCabinTypes)}}
                            </div>
                            <input-price
                                v-model="firstSinglePrice"
                                :provider-prices="firstSingleCabin.providerPrices"
                                :small-input="true"
                                :price-currency="request.requestCurrency"
                            />
                        </div>
                    </main>
                </div>
            </div>
        </div>


        <div class="columns">
            <div class="column is-one-third">
                <free-places v-model="request.freePlaces" v-bind:request="request"></free-places>
            </div>
            <div class="column">
                <item-prices v-model="request.itemPrices" v-bind:provider="provider"></item-prices>
            </div>
        </div>
        <div></div>
    </div>
</template>



<script>
import toNumber from 'lodash/toNumber';
import isNumber from 'lodash/isNumber';

import mixins from '../mixinsIndividually';


export default {
    mixins: [mixins],

    props: {
        placeholder: {}
    },


    computed: {
        provider:  function () { return this.request.train },
        types:     function () { return this.$store.state.optionsTrainCabinTypes; },
        trainCabinTypes: function() {return this.$store.state.options.trainCabinTypes.crud_prices ? this.$store.state.options.trainCabinTypes.crud_prices : [] },
        dayTrainCabinTypes: function() {return this.$store.state.options.dayTrainCabinTypes.crud_prices ? this.$store.state.options.dayTrainCabinTypes.crud_prices : [] },



        doubleCabins: function () {
            let cabins = this.request.contingent.doubleCabins;
            if(this.placeholder.category !== 'first_and_second') {
                return this.request.contingent.doubleCabins.filter(cabin => cabin.type === this.placeholder.category);
            }
            return this.request.contingent.doubleCabins.filter(cabin => cabin.type.type !== 'second');
        },
        singleCabins: function () {
            let singleCabins = this.request.contingent.singleCabins;
            if(this.placeholder.category !== 'first_and_second'){
                singleCabins = singleCabins.filter(cabin => cabin.type.type === this.placeholder.category)
            }

            if(singleCabins.length > 1) {
                // moving 'first' first (for the case we have more than 2 items)
                singleCabins = [
                    singleCabins.filter(x => x.type.type === 'first')[0],
                    ...singleCabins.filter(x => x.type.type !== 'first')
                ];

                // moving 'second' first
                singleCabins = [
                    singleCabins.filter(x => x.type.type === 'second')[0],
                    ...singleCabins.filter(x => x.type.type !== 'second')
                ];
            }


            return singleCabins;
        },
        otherCabins:  function () {
            let cabins = this.request.contingent.otherCabins;
            return this.request.contingent.otherCabins
        },

        standardCategory: function () {
            return this.placeholder.category === 'first' ? 'first' : 'second';
        },

        standardDoubleCabin: function () { return this.request.contingent.doubleCabins.find(cabin => cabin.type.type === this.standardCategory)},
        standardSingleCabin: function () { return this.request.contingent.singleCabins.find(cabin => cabin.type.type === this.standardCategory)},
        firstSingleCabin: function () { return this.request.contingent.singleCabins.find(cabin => cabin.type.type === 'first')},


        standardDoublePrice: {
            get: function () {
                const price = this.standardDoubleCabin && this.standardDoubleCabin.price && this.standardDoubleCabin.price.amount;
                return !!price ? price : '0';
            },

            set: function (price) {
                const newPrice = !isNumber(price) ? toNumber(price) : price;

                this.request.contingent.doubleCabins = this.request.contingent.doubleCabins
                    .map(cabin => {
                        if(cabin.type.type !== this.standardCategory){
                            return cabin;
                        }
                        return Object.assign({}, cabin, {
                            price: {
                                amount: newPrice.toString(),
                                currency: this.request.requestCurrency
                            }
                        })
                    });
            }
        },

        standardSinglePrice: {
            get: function () {
                const price = this.standardSingleCabin && this.standardSingleCabin.price && this.standardSingleCabin.price.amount;
                return !!price ? price : '0';
            },

            set: function (price) {
                const newPrice = !isNumber(price) ? toNumber(price) : price;

                this.request.contingent.singleCabins = this.request.contingent.singleCabins
                    .map(cabin => {
                        if(cabin.type.type !== this.standardCategory){
                            return cabin;
                        }
                        return Object.assign({}, cabin, {
                            price: {
                                amount: newPrice.toString(),
                                currency: this.request.requestCurrency
                            }
                        })
                    });
            }
        },

        firstSinglePrice: {
            get: function () {
                const price = this.firstSingleCabin && this.firstSingleCabin.price && this.firstSingleCabin.price.amount;
                return !!price ? price : '0';
            },

            set: function (price) {
                const newPrice = !isNumber(price) ? toNumber(price) : price;

                this.request.contingent.singleCabins = this.request.contingent.singleCabins
                    .map(cabin => {
                        if(cabin.type.type !== 'first'){
                            return cabin;
                        }
                        return Object.assign({}, cabin, {
                            price: {
                                amount: newPrice.toString(),
                                currency: this.request.requestCurrency
                            }
                        })
                    });
            }
        },
    },


    methods: {
        updatePrices: function (newDifference, accomodation, type) {
            const newPrice = toNumber(newDifference);

            this.request.contingent[type] = this.request.contingent[type]
                .map(cabin => Object.assign({}, cabin, {
                    price: {
                        amount: cabin.id === accomodation.id ? newPrice.toString() : cabin.price.amount,
                        currency: this.request.requestCurrency
                    }

                }))
        },


        savePrice: function ({type, persons, price}) {
            const newPrice = !isNumber(price) ? toNumber(price) : price;

            if (type === 'second' && persons === 2) {
                this.standardPrice = newPrice
            } else {
                const cabinType = persons === 2 ? 'doubleCabins' : (persons === 1 ? 'singleCabins' : 'otherCabins');
                this.request.contingent[cabinType] = this.request.contingent[cabinType]
                    .map(cabin => {
                        if (cabin.type.type === type && cabin.type.persons === persons) {
                            return Object.assign({}, cabin, {
                                price: {
                                    amount: newPrice.toString(),
                                    currency: this.request.requestCurrency
                                }
                            })
                        }

                        return cabin;
                    })
            }
        },


        savePrices: function (prices) {
            prices.forEach(price => {
                this.savePrice(price);
            })
        }
    },
}
</script>
