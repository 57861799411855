<script>
import mixin from './mixin'
import { serviceIcons } from '@utilities/variables'
export default {
    mixins: [ mixin ],

    computed: {
        icon: function () {
            return serviceIcons[this.placeholder.otherServiceType.category];
        },

        isPerGroup: function () { return !this.isPerPerson },

        isNight: function () { return false },

        hasGroupPrice: function () { return this.isPerGroup },
        hasPersonPrice: function () { return this.isPerPerson},
        hasSinglePrice: function () { return !this.supplementPrices },

        personPrice: {
            get: function () {
                return this.isPerPerson || !this.doublePersons
                    ? this.price.buyPrice.amount
                    : this.price.buyPrice.amount / this.doublePersons;
            },
            set: function (value) {
                this.$store.commit('updatePrice', {
                    otherPrices: {
                        id: this.price.id,
                        buyPrice: {
                            amount: value,
                            currency: this.placeholder.placeholderCurrency
                        }
                    }
                })
            }
        },
        personLoadedPrices: function () {
            return this.isPerPerson ? this.loadedPrices.buyPrice : null
        },
        personMainPrices: function () {
            return this.isPerPerson ? this.mainPrices.buyPrice : null
        },

        groupPrice: {
            get: function () {
                return this.isPerGroup
                    ? this.price.buyPrice.amount
                    : 0
            },
            set: function (value) {
                this.$store.commit('updatePrice', {
                    otherPrices: {
                        id: this.price.id,
                        buyPrice: {
                            amount: value,
                            currency: this.placeholder.placeholderCurrency
                        }
                    }
                })
            }
        },

        personPriceType: function () {
            return this.isPerGroup ? 'none' : 'buyPrice';
        },

        groupPriceType: function () {
            return this.isPerGroup ? 'buyPrice': 'none';
        },

        singlePrice: function () {
            return !this.supplementPrices ? this.personPrice : 0;
        }


    }
}
</script>
