<template>
    <row>
        <template slot="type">
            <i class="material-icons">subdirectory_arrow_right</i>
        </template>
        <template slot="date">
            <template v-if="hasPersons">
                von {{ range.from || 0 }} bis {{ range.to >= 0 ? range.to : '∞' }}
                <template v-if="isSingle">EZ</template>
                <template v-else>Pax</template>
            </template>
            <template v-else>-</template>
        </template>
        <template slot="nights" v-if="isSingleNight || isPerPersonNight">{{this.price.nights}} N</template>
        <template slot="nights" v-else>-</template>

        <template slot="name">
            <template v-if="isCustom">Zuschlag:</template>
            <template v-if="isSingle">Zuschlag EZ:</template>
            <template v-if="isSingleNight">Zuschlag EZ/Nacht:</template>
            <template v-if="isPerGroup">Zuschlag Gruppe: </template>
            <template v-if="isPerPerson">Zuschlag: </template>
            <template v-if="isPerPersonNight">Zuschlag/Nacht: </template>
            {{price.name}}
        </template>
        <template slot="provider">-</template>
        <template slot="currency"><span class="is-currency">{{currency}}</span></template>

        <template slot="sellPrice">
            <input-price
                v-if="isPerGroup || isCustom"
                :value="groupPrice"
                linked
                v-on:unlink="$emit('unlink')"
                :value-in-order-currency="groupPriceInOrderCurrency"
            />
            <span v-else class="empty">-</span>
        </template>
        <template slot="profit">
            <input-price
                v-if="isPerGroup || isPerPerson || isPerPersonNight || isCustom"
                :value="personPrice"
                linked v-on:unlink="$emit('unlink')"
                :highlight="personsInRange"
                :value-in-order-currency="personPriceInOrderCurrency"
            />
            <span v-else class="empty">-</span>
        </template>
        <template slot="margin">
            <input-price
                v-if="!supplementPrices || isSingle || isSingleNight" :value="singlePrice"
                linked v-on:unlink="$emit('unlink')"
                :highlight="singleInRange"
                :value-in-order-currency="singlePriceInOrderCurrency"
            />
            <span v-else class="empty">-</span>
        </template>
    </row>
</template>

<script>
import has from 'lodash/has';

import store from '@calculations/store';

import Row from './Row';
import InputPrice from './InputPrice'
import Template from "@orders/subpages/TripDetails/General/Template";

export default {
    store,

    props: {
        price: {
            type: Object,
            required: true
        }
    },

    components: {
        Template,
        Row,
        InputPrice
    },

    computed: {
        persons: function () { return this.$store.getters.persons },
        doublePersons: function () { return this.$store.getters.rooms.double },
        highlight: function () { return this.$store.state.highlight },
        supplementPrices: function () { return this.$store.state.options.clientConfig.calculation.supplementPrices; },
        range: function () {
            return {
                from: [0, null].includes(this.price.minPersons) ? 0 : this.price.minPersons,
                to: [0, null].includes(this.price.maxPersons) ? -1 : this.price.maxPersons
            }
        },

        hasPersons: function () { return has(this.price, 'minPersons') },
        personsInRange: function () {
            const { person } = this.highlight;
            return !!person &&
                (
                    !this.hasPersons || (
                        this.hasPersons &&
                        person >= this.range.from &&
                        (person <= this.range.to || this.range.to === -1)
                    )
                );
        },
        singleInRange: function () {
            const { single, singleMin, singleMax } = this.highlight;
            const { from, to } = this.range;

            return !!single &&
                (
                    !this.hasPersons || (
                        this.hasPersons &&
                        ((from >= singleMin && from <= singleMax) &&
                        ((to >= singleMin && to <= singleMax) || this.range.to === -1))
                    )
                );
        },

        isSingle: function () { return this.price.quantityType === 'per_single_room' },
        isSingleNight: function () { return this.price.quantityType === 'per_single_room_night' },
        isPerGroup: function () { return this.price.quantityType === 'per_group' },
        isPerPerson: function () { return this.price.quantityType === 'per_person' },
        isPerPersonNight: function () { return this.price.quantityType === 'per_person_night' },
        isCustom: function() { return this.price.quantityType === 'custom'},

        groupPrice: function () {
            if(this.isPerGroup){
                return this.price.price.amount;
            }
            if(this.isCustom){
                return this.price.price.amount * this.price.quantity;
            }
            return 0;
        },
        personPrice: function () {
            if(this.isPerPerson || this.isPerPersonNight) {
                return this.price.price.amount;
            }
            if(this.isPerGroup && this.doublePersons){
                return this.price.price.amount / this.doublePersons;
            }
            if(this.isCustom && this.doublePersons) {
                return this.price.price.amount / this.doublePersons * this.price.quantity;
            }
            return 0;
        },
        singlePrice: function () {
            if(!this.supplementPrices){
                return this.isSingle || this.isSingleNight ? this.price.price.amount : this.personPrice;
            }
            return this.isSingle || this.isSingleNight ? this.price.price.amount : 0
        },

        currency: function() {
            return this.price.price.currency ? this.price.price.currency : null;
        },

        exchangeRate: function () {
            return this.$store.state.exchangeRates && this.$store.state.exchangeRates[this.currency] ? this.$store.state.exchangeRates[this.currency] : 0;
        },

        personPriceInOrderCurrency: function () { return this.personPrice * this.exchangeRate },
        groupPriceInOrderCurrency: function () { return this.groupPrice * this.exchangeRate },
        singlePriceInOrderCurrency: function () { return this.singlePrice * this.exchangeRate},


    }
}
</script>

