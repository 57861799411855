var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "panel",
      class: [_vm.classNames, !_vm.isOpen ? "is-collapsed" : ""],
    },
    [
      _c(
        "div",
        {
          staticClass: "panel-heading",
          style: _vm.headingStyle,
          on: {
            click: function ($event) {
              return _vm.toggleCollapse(!_vm.isOpen)
            },
          },
        },
        [
          _vm.label
            ? _c("span", [_vm._v(_vm._s(_vm.label))])
            : _c("div", { staticClass: "panel-label" }, [_vm._t("label")], 2),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "panel-buttons" },
            [
              _vm._t("buttons"),
              _vm._v(" "),
              _vm.hasSave
                ? _c(
                    "i",
                    {
                      staticClass: "is-pointer material-icons",
                      attrs: { title: _vm.saveTitle },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.$emit("save")
                        },
                      },
                    },
                    [_vm._v("speichern")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasForm
                ? _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.$emit("add")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("add_circle"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isCollapsible
                ? _c(
                    "i",
                    {
                      staticClass: "collapse-icon material-icons",
                      class: { "is-rotated": !_vm.isOpen },
                    },
                    [_vm._v("keyboard_arrow_up")]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "panel-block" }, [
        _c(
          "div",
          { ref: "collapse", staticClass: "panel-content" },
          [_vm._t("default")],
          2
        ),
      ]),
      _vm._v(" "),
      _vm._t("modal"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }