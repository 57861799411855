var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "is-white" }, [
    _c("div", { staticClass: "row" }, [
      _vm.priceType === "hotel"
        ? _c("div", { staticClass: "cell is-double" }, [
            _vm._v(_vm._s(_vm.roomType)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.priceType === "ferry" && !_vm.isDay
        ? _c("div", { staticClass: "cell is-double" }, [
            _vm._v(_vm._s(_vm.cabinType)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.priceType === "ferry" && _vm.isDay
        ? _c("div", { staticClass: "cell is-double" }, [
            _vm._v(_vm._s(_vm.pricePriceType)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.priceType === "train" && !_vm.isDay
        ? _c("div", { staticClass: "cell is-double" }, [
            _vm._v(_vm._s(_vm.trainCabinType)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.priceType === "train" && _vm.isDay
        ? _c("div", { staticClass: "cell is-double" }, [
            _vm._v(_vm._s(_vm.dayTrainCabinType)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.priceType === "other" && _vm.priceOtherPriceType
        ? _c("div", { staticClass: "cell is-double" }, [
            _vm._v(_vm._s(_vm.priceOtherPriceType)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "cell" }, [
        _vm._v(
          _vm._s(_vm._f("priceView")(_vm.price.price.amount)) +
            " " +
            _vm._s(_vm.price.price.currency)
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }