<template>
    <row>
        <template slot="type">
            <span class="icon is-pointer" v-on:click.prevent.stop="togglePriceType">
                <template v-if="isSingle">EZ</template>
                <template v-if="isPerPerson">DZ</template>
                <template v-if="isPerGroup">
                    <i class="material-icons">people</i>
                </template>
            </span>
        </template>
        <template slot="date">
            <div class="level-left">
                <span class="level-item">von</span>
                <multiselect
                    v-model="minPersons"
                    v-bind:options="personsOptions.from"
                    class="is-tiny level-item"
                    placeholder="0"
                />
                <span class="level-item">bis</span>
                <multiselect
                    v-model="maxPersons"
                    v-bind:options="personsOptions.to"
                    label="label"
                    track-by="label"
                    class="is-tiny level-item"
                    placeholder="0"
                />
                <span>
                    <template v-if="isSingle">EZ</template>
                    <template v-else>Pax</template>
                </span>
            </div>
        </template>
        <template slot="nights">-</template>
        <template slot="name">
            <input type="text" class="input" v-model="name">
        </template>
        <template slot="provider">-</template>
        <template slot="currency">
            <multiselect
                v-if="editCurrency"
                v-model="price.buyPrice.currency"
                v-bind:options="potentialCurrencies"
                v-on:input="editCurrency = false"
                placeholder="EUR"
            >
            </multiselect>
            <template v-else>
                <div class="is-currency level-left">
                    <span class="level-item">{{price.buyPrice.currency}}</span>
                    <i class="material-icons level-item is-actions" v-if="!editCurrency" v-on:click="editCurrency = true">edit</i>
                </div>
            </template>
        </template>

        <template slot="sellPrice">
            <input-price v-if="isPerGroup"
                         v-model="buyPrice"
                         :value-in-order-currency="groupPriceInOrderCurrency"
            />
            <span class="empty" v-else>-</span>
        </template>
        <template slot="profit">
            <input-price v-if="isPerPerson"
                         v-model="buyPrice"
                         :value-in-order-currency="personPriceInOrderCurrency"
                         :highlight="personsInRange" />
            <input-price
                v-else-if="isPerGroup && persons"
                :value="buyPrice / persons"
                :value-in-order-currency="personPriceInOrderCurrency"
                :highlight="personsInRange" readOnly/>
            <span class="empty" v-else>-</span>
        </template>
        <template slot="margin">
            <input-price v-if="isSingle"
                         :value-in-order-currency="singlePriceInOrderCurrency"
                         v-model="buyPrice"
                         :highlight="singleInrange" />
            <input-price
                v-else-if="!supplementPrices && isPerGroup && persons"
                :value="buyPrice / persons"
                :value-in-order-currency="singlePriceInOrderCurrency"
                :highlight="personsInRange" readOnly/>
            <input-price
                v-else-if="!supplementPrices && isPerPerson"
                :value="buyPrice"
                :value-in-order-currency="singlePriceInOrderCurrency"
                :highlight="personsInRange" readOnly/>
            <span class="empty" v-else>-</span>
        </template>

        <template slot="actions">
            <i class="material-icons" v-on:click="$emit('delete')">delete</i>
        </template>
    </row>
</template>

<script>
import uniq from 'lodash/uniq';
import { Multiselect } from '@components/form'
import { priceMixin } from '@calculations/functions';
import InputPrice from '@calculations/components/InputPrice'

export default {
    mixins: [ priceMixin ],

    data: function () {
        return {
            editCurrency: false,
        }
    },

    computed: {
        priceType: function () { return this.price.priceType },
        isSingle: function () { return this.priceType === 'per_single_room' },
        isPerPerson: function () { return this.priceType === 'per_person' },
        isPerGroup: function () { return !this.isPerPerson && !this.isSingle },
        rooms: function () { return this.$store.getters.rooms },
        persons: function () { return this.$store.getters.persons },

        buyPrice: {
            get: function () { return this.price.buyPrice.amount },
            set: function (value) { this.update(
                {
                        buyPrice: {
                            amount: value,
                            currency: this.price.buyPrice.currency
                        }
                    })
            }
        },

        name: {
            get: function () { return this.price.name },
            set: function (value) { this.update({name: value}) }
        },

        exchangeRate: function () {
            return this.$store.state.exchangeRates && this.$store.state.exchangeRates[this.price.buyPrice.currency] ? this.$store.state.exchangeRates[this.price.buyPrice.currency] : 0;
        },

        groupPriceInOrderCurrency: function () { return this.isPerGroup ? this.buyPrice * this.exchangeRate : 0 },
        personPriceInOrderCurrency: function () { return this.isPerGroup && this.persons ? this.buyPrice / this.persons * this.exchangeRate : this.buyPrice * this.exchangeRate  },
        singlePriceInOrderCurrency: function () { return this.isSingle ? this.buyPrice * this.exchangeRate : 0},

        singlePersonsOptions: function () {
            const numberOfPersons = this.$store.getters.packagePrices
                .filter(packagePrice => !!packagePrice.numberOfPersons)
                .filter(packagePrice => packagePrice.orderHotelRoom.type.persons === 1)
                .map(packagePrice => packagePrice.numberOfPersons)

            const options = uniq([
                this.rooms.single,
                ...numberOfPersons
            ]).sort((a,b) => a < b ? -1 : 1)

            return {
                from: [
                    0,
                    ...options
                        .filter(n => n > 0)
                        .map(n => n + 1)
                ],
                to: [
                    ...options.map(n => ({
                        label: n, value: n
                    })),
                    {
                        label: '∞',
                        value: -1
                    }
                ]
            }
        },

        potentialCurrencies: function () { return this.$store.state.options && this.$store.state.options.potentialCurrencies ? this.$store.state.options.potentialCurrencies : []},

        doublePersonsOptions: function () {
            const numberOfPersons = this.$store.getters.packagePrices
                .filter(packagePrice => !!packagePrice.numberOfPersons)
                .filter(packagePrice => packagePrice.orderHotelRoom.type.persons === 2)
                .map(packagePrice => packagePrice.numberOfPersons)

            const options = uniq([
                ...numberOfPersons,
                this.persons,
            ]).sort((a,b) => a < b ? -1 : 1)

            return {
                from: [0, ...options],
                to: [
                    ...options.map(n => ({
                        label: n-1,
                        value: n-1
                    })),
                    {
                        label: '∞',
                        value: -1
                    }
                ]
            }
        },

        personsOptions: function () {
            if (this.isSingle) {
                return this.singlePersonsOptions;
            } else {
                return this.doublePersonsOptions;
            }
        },

        minPersons: {
            get: function () { return this.price.minPersons },
            set: function (minPersons) { this.update({minPersons}) }
        },

        maxPersons: {
            get: function () { return this.personsOptions.to.find(option => option.value === this.price.maxPersons )},
            set: function (option) { this.update({ maxPersons: !!option ? option.value : null }) }
        },

        highlight: function () { return this.$store.state.highlight },
        personsInRange: function () {
            const { person } = this.highlight;
            return !!person &&
                (
                    person >= this.price.minPersons &&
                    (person <= this.price.maxPersons || this.price.maxPersons === -1)
                );
        },
        singleInrange: function () {
            const { single } = this.highlight;
            return !!single &&
                (
                    single >= this.price.minPersons &&
                    ((single - 1) <= this.price.maxPersons || this.price.maxPersons === -1)
                );
        }
    },

    methods: {
        togglePriceType: function () {
            if (this.isPerPerson) {
                this.price.priceType = 'per_group'
            } else if (this.isPerGroup) {
                this.price.priceType = 'per_single_room'
            } else {
                this.price.priceType = 'per_person'
            }
        },

        update: function (obj) {
            this.$store.commit('updatePrice', { includedPrices: {
                key: this.price.key,
                ...obj
            }})
        }
    },

    components: {
        Multiselect,
        InputPrice
    }
}
</script>
