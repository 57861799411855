var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("loading")
        : _vm._l(_vm.rows, function (row) {
            return _c(
              "div",
              { staticClass: "columns" },
              _vm._l(row.cols, function (col) {
                return _c(
                  "div",
                  {
                    staticClass: "column",
                    class: col.columnClass,
                    style: col.columnStyle,
                  },
                  [
                    _c("div", [
                      _c("h2", { staticClass: "is-headline" }, [
                        _vm._v(_vm._s(col.label)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(col.component.type, {
                          tag: "component",
                          attrs: { component: col.component },
                          on: { saveItem: _vm.saveItem },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }