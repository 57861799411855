var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "is-half is-flex is-justify-content-space-between" },
        [
          _c("h1", [_vm._v("Kundenbesuche")]),
          _vm._v(" "),
          _c(
            "i",
            {
              staticClass: "material-icons is-pointer",
              on: { click: _vm.openForm },
            },
            [_vm._v("add_circle")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "client-visit is-half" },
        _vm._l(_vm.item.clientVisits, function (clientVisit) {
          return _c(
            "div",
            [
              _c(
                "accordion",
                {
                  staticClass: "client-accordion",
                  attrs: { "is-open": false },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "is-flex",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "is-half is-flex is-align-items-center",
                        },
                        [
                          _c("h4", [_vm._v(_vm._s(clientVisit.date))]),
                          _vm._v(" "),
                          _c(
                            "i",
                            {
                              staticClass: "material-icons is-pointer mr-1",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteItem(
                                    "clientVisits",
                                    clientVisit
                                  )
                                },
                              },
                            },
                            [_vm._v("delete")]
                          ),
                          _vm._v(" "),
                          _c(
                            "i",
                            {
                              staticClass: "material-icons is-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.openForm(clientVisit)
                                },
                              },
                            },
                            [_vm._v("edit")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      clientVisit.user
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "is-half is-flex is-align-items-center",
                            },
                            [
                              _c("h4", [
                                _vm._v(_vm._s(_vm.contactString(clientVisit))),
                              ]),
                              _vm._v(" "),
                              _c("user-image", {
                                staticClass: "user-image mt-0",
                                attrs: { user: clientVisit.user, small: "" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _c("div", {
                      staticClass: "nl2br py-3 mx-3",
                      domProps: { innerHTML: _vm._s(clientVisit.report) },
                    }),
                  ]),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("client-visit-form", {
        ref: "form",
        attrs: { slot: "form" },
        slot: "form",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }