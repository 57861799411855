<template>
    <div class="tags" v-if="checkStatus">
        <div class="tag is-white">{{ checkStatus }}</div>
    </div>
</template>

<script>
import { getValueWithKey } from '@utilities/functions';

import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        checkStatus: function () {
            return getValueWithKey({key: this.value, optionList: this.options.checkedStatusList})
        }
    }
}
</script>
