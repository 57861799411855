<template>
    <span v-if="value"># {{value}}</span>
</template>

<script>
import Cell from './cell';

export default {
    mixins: [ Cell ],
    
    props: {
        value: {
            type: [String, Number]
        }
    }
}
</script>
