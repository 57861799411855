<template>
    <transition name="fade">
        <div class="crud-prices">
            <div class="panel">
                <div class="panel-heading">
                    <span>{{title}}</span>
                    <button
                        class="button is-icon"
                        v-on:click.prevent.stop="$emit('reset')"
                    >&rarr; Alle</button>
                </div>
                <div class="panel-block">
                    <div class="panel-content">
                        <loading v-if="isLoading" />
                        <slot v-else />
                    </div>
                </div>

                <footer>
                    <div class="level">
                        <button class="button" v-on:click.prevent="$emit('close')">Abbrechen</button>
                        <button class="button is-primary" v-on:click.prevent="$emit('save')">Preise übernehmen</button>
                    </div>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
import Loading from '@components/Loading';
export default {
    components: { Loading },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },

        title: {
            type: String,
            default: "Einkaufspreis aus Stammdaten übernehmen?"
        }
    }
}
</script>


