<template>
    <div class="new-request">
        <div class="overall-buttons">
            <button class="button is-large" v-on:click="$emit('close')"><i aria-hidden="true" class="fa fa-long-arrow-left"></i> Zurück zur Übersicht</button>
        </div>


        <accordion v-bind:isCollapsible="false">
            <div slot="header">
                <div>
                    <request-score v-if="newRequest.otherService && newRequest.otherService.provider" v-bind:rating="newRequest.otherService.provider.buspaketRating" />
                    <i aria-hidden="true" class="fa fa-plus-circle" v-else></i>
                </div>

                <div>
                    LEISTUNGSTRÄGER HINZUFÜGEN
                    <provider-info class="header-extra-info" v-bind:provider="newRequest.otherService ? newRequest.otherService.provider : null">
                        <div>
                            <i class="material-icons spacing-left">perm_identity</i> Kundenkontingent:
                            {{ peopleContingent }} Teilnehmer
                        </div>
                    </provider-info>
                </div>

                <div>
                    <label v-on:click.stop>
                        <input type="checkbox" v-model="isMainRequest" />
                        als Leistung festlegen
                    </label>
                </div>
            </div>


            <div slot="content">
                <div class="bp-box is-info">
                    <main>
                        <button
                            v-bind:class="['btn-single-toggle',  onlyFromOrder ? '' : 'is-active']"
                            v-on:click="onlyFromOrder = !onlyFromOrder"
                            v-bind:title="onlyFromOrder ? 'Suche ist auf obigen Ort eingeschränkt' : 'Suche ist nicht eingeschränkt'"
                            style="border: 1px solid #dddddd; margin-right: 5px; min-width: 22px; min-height: 22px;"
                        ><i class="material-icons" style="margin: 0">flag</i></button>

                        <div>
                            <other-service-select
                                style="width: 400px"
                                class="has-margin-left"
                                v-model="newRequest.otherService"
                                v-bind:otherServiceTypeID="placeholderInfo.otherServiceType.id"
                                v-bind:placeholder-info="onlyFromOrder ? locationInfo : {area: null, place: null, destination: null}"
                            ></other-service-select>
                        </div>

                        <agency-select
                            v-bind:provider = "null"
                            text            = "Agentur"
                            v-on:update     = "agency => newRequest.agency = agency"
                            prefill
                        ></agency-select>

                    </main>
                </div>


                <div class="columns">
                    <div class="column">
                        <div class="bp-box is-tickets">
                            <header>
                                <div>
                                    <other-service-icons v-bind:type="newRequest.otherServiceType.category"></other-service-icons> Zusatzleistung
                                </div>
                                <div><i class="material-icons">help</i></div>
                                <div><button title="Extrabuchung für Reisebegleitung" v-on:click="addExtra"><i class="material-icons">person_add</i></button></div>
                            </header>

                            <main>
                                <div>
                                    <div>
                                        <i class="material-icons">person</i> Teilnehmer
                                    </div>
                                    <div>
                                        <input
                                            class="input"
                                            type="text"
                                            v-model.number="newRequest.contingent.askedAmount"
                                            v-on:focus="$event.target.select()"
                                        />
                                    </div>
                                    <div></div>
                                </div>

                                <div class="extra-people" v-if="hasExtra">
                                    <div>
                                        <i class="material-icons">person_add</i>
                                        <input
                                            class="input"
                                            type="text"
                                            v-model="newRequest.contingent.extraDescription"
                                        />
                                        <button class="btn-delete" title="Löschen" v-on:click="deleteExtra" tabindex="-1"><i class="material-icons">delete</i></button>
                                    </div>
                                    <div>
                                        <div class="control"><input
                                            class="input"
                                            type="text"
                                            v-model.number="newRequest.contingent.extraAskedAmount"
                                            v-on:focus="$event.target.select()"
                                        /></div>
                                    </div>
                                    <div></div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>


                <div class="columns">
                    <div class="column is-offset-6">
                        <item-prices
                            v-bind:isWithoutPrice="true"
                            v-model="newRequest.itemPrices"
                            v-bind:provider="provider"
                        ></item-prices>
                    </div>
                </div>
            </div>
        </accordion>


        <button class="button is-large" v-on:click="$emit('close')"><i class="material-icons">close</i> Abbrechen</button>

        <button
            class="button is-primary is-large"
            v-bind:class="{ 'is-loading': isLoading }"
            v-on:click="saveNewRequest"
        ><i class="material-icons">check</i> speichern und zur Email</button>

        <button
            class="button is-primary is-large"
            v-bind:class="{ 'is-loading': isLoading }"
            v-on:click="saveNewRequest('close')"
        ><i class="material-icons">keyboard_arrow_right</i> speichern und schließen</button>


        <confirmation
            v-bind:isVisible="isVisibleConfirmation"
            is-local
            text="Soll die Leistungs- und Routenbeschreibung an die spezifische Anfrage angepasst werden?"
            v-on:input="value => $emit('confirm', value)"
        ></confirmation>
    </div>
</template>



<script>
import get                   from 'lodash/get';
import mixins                from './mixins';
import OtherServiceIcons     from '@components/OtherServiceIcons';
import { notifyError }       from '@components/Notification';
import { prepareForBackend } from '@utilities/functions';
import OtherServiceSelect    from '@orders/placeholders/components/forms/OtherServiceSelect';
import LocationSelect    from '@components/form/LocationSelect';


export default {
    mixins: [mixins],


    computed: {
        peopleContingent: function () { return this.$store.state.hotelContingent.info.personsAsked; },
        provider:         function () { return this.newRequest.otherService ? this.newRequest.otherService : null },
        providerContacts: function () { return get(this.newRequest, 'otherService.provider.contacts', []); },
        locationInfo: function () {
            return {
                area:        this.placeholderInfo.area,
                place:       this.placeholderInfo.place,
                destination: this.placeholderInfo.destination,
            };
        }
    },


    data: function () {
        return {
            apiEndpoint: 'other_requests',
            hasExtra: false,

        };
    },


    methods: {
        saveNewRequest: function (close = false) {
            if (this.newRequest.otherService === null && this.newRequest.agency === null) {
                notifyError('Sie müssen einen Anbieter oder eine Agentur auswählen!');
                return false;
            }


            this.newRequest.contingent.priceType = this.newRequest.otherServiceType.priceType ? this.newRequest.otherServiceType.priceType : 'per_person';

            let copyNewRequest = JSON.parse(JSON.stringify(this.newRequest));

            copyNewRequest.otherService = copyNewRequest.otherService && copyNewRequest.otherService.id ? '/api/other_services/' + copyNewRequest.otherService.id : null;

            // preparing for backend use
            copyNewRequest = prepareForBackend(copyNewRequest);

            copyNewRequest.otherServiceType = '/api/other_service_types/' + copyNewRequest.otherServiceType.id;

            if (copyNewRequest.assignedContact && copyNewRequest.assignedContact.id) {
                copyNewRequest.assignedContact = '/api/contacts/' + copyNewRequest.assignedContact.id;
            }

            this.isLoading = true;

            // API call
            this.addRequest(copyNewRequest, close);
        },


        addExtra: function () {
            this.newRequest.contingent.extraDescription    = '';
            this.newRequest.contingent.extraReservedAmount = 0;
            this.newRequest.contingent.extraAskedAmount    = 0;
            this.hasExtra = true;
        },


        deleteExtra: function () {
            this.newRequest.contingent.extraDescription    = null;
            this.newRequest.contingent.extraReservedAmount = 0;
            this.newRequest.contingent.extraAskedAmount    = 0;
            this.hasExtra = false;
        }
    },


    created: function () {
        this.newRequest.contingent.askedAmount = this.peopleContingent;
    },


    watch: {
        'newRequest.otherService': function () {
            if (this.newRequest.otherService) {
                this.newRequest.agency = null;

                const contacts = get(this.newRequest, 'otherService.provider.contacts', [])
                const primaryContact = contacts.find(contact => contact.type === 'primary');

                if (primaryContact) {
                    this.newRequest.assignedContact = JSON.parse(JSON.stringify(primaryContact))
                }
            }
        }
    },


    components: {
        OtherServiceIcons,
        OtherServiceSelect,
        LocationSelect
    }
}
</script>
