<template>
    <div class="redesign-table">
        <div class="row is-header">
            <h2>Verkaufspreise</h2>
                <simple-menu>
                    <button v-if="singleRoomLimit" v-on:click="addPackage({persons: 1})">
                        <i class="material-icons">add</i> {{ getAccommodationLabel('standard', 1, roomTypes)}}
                    </button>
                    <button v-on:click="addPackage({persons:2})">
                        <i class="material-icons">add</i> Paketpreis
                    </button>
                    <div class="has-sub-menu">
                        <button><i class="material-icons">chevron_left</i> Extra</button>
                        <div class="sub-menu">
                            <button v-on:click="addExtra({offerTextBlock: 'hotelSuplements'})">
                                <i class="material-icons">add</i> Mehrbettzimmer und sonstige Zimmer
                            </button>
                            <button v-on:click="addExtra({offerTextBlock: 'ferrySuplements'})">
                                <i class="material-icons">add</i> Außenkabinen
                            </button>
                            <button v-on:click="addExtra({offerTextBlock: 'trainSuplements'})">
                                <i class="material-icons">add</i> Zugfahrten 1. Klasse
                            </button>
                            <button v-on:click="addExtra({offerTextBlock: 'ferryOther'})">
                                <i class="material-icons">add</i> Sonstige Leistungen auf der Fähre
                            </button>
                            <button v-on:click="addExtra({offerTextBlock: 'foodOnBoard'})">
                                <i class="material-icons">add</i> zusätzliche Leistungen an Bord der Fähre
                            </button>
                            <button v-on:click="addExtra({offerTextBlock: 'tourGuides', type: 'per_group'})">
                                <i class="material-icons">add</i> zusätzliche Reiseleiter-Leistungen
                            </button>
                            <button v-on:click="addExtra({offerTextBlock: 'groupServices', type: 'per_group'})">
                                <i class="material-icons">add</i> zusätzliche Gruppen-Leistungen
                            </button>
                            <button v-on:click="addExtra({offerTextBlock: 'services'})">
                                <i class="material-icons">add</i> zusätzliche Leistungen pro Person
                            </button>
                            <button v-on:click="addExtra({offerTextBlock: 'mainPriceDates'})">
                                <i class="material-icons">add</i> Paketpreise
                            </button>
                            <button v-on:click="addExtra({offerTextBlock: 'tax'})">
                                <i class="material-icons">add</i> Kurtaxe
                            </button>
                        </div>
                    </div>
                </simple-menu>
        </div>

        <row class="is-head" v-if="!hide">
            <template slot="type">Art</template>
            <template slot="date"><div></div></template>
            <template slot="nights"><div></div></template>
            <template slot="name"><div></div></template>
            <template slot="marginTax">Margensteuer ({{orderCurrency}})</template>
            <template slot="buyPriceCalculation" v-if="hasTwoCurrencies">EK ({{calculationCurrency}})</template>
            <template slot="buyPrice">{{hasTwoCurrencies ? 'EK (' + orderCurrency + ')' : 'Einkaufspreis'}}</template>
            <template slot="sellPriceCalculation" v-if="hasTwoCurrencies">VK ({{calculationCurrency}})</template>
            <template slot="sellPrice">{{hasTwoCurrencies ? 'VK (' + orderCurrency + ')' : 'Verkaufspreis'}}</template>
            <template slot="profitCalculation" v-if="hasTwoCurrencies">GS ({{calculationCurrency}})</template>
            <template slot="profit">{{hasTwoCurrencies ? 'GS (' + orderCurrency + ')' : 'Gewinnspanne'}}</template>
            <template slot="margin">Marge (%)</template>
            <template slot="actions">
                <button
                    class="btn-single-toggle level-item"
                    v-bind:class="{'is-active': marginsEnabled}"
                    v-on:click   = "marginsEnabled = !marginsEnabled"
                    v-bind:title = "marginsEnabled ? 'Margensteuer deaktivieren' : 'Margensteuer aktivieren'"
                >
                    <i class="fa fa-percent"></i>
                </button>
            </template>
        </row>

        <template v-if="packagePrices.double.length > 0 || packagePrices.single.length > 0 || mainPriceDates.length > 0">
            <div class="row is-headline">Paketpreise pro Person</div>

            <package-price
                v-for="price in packagePrices.double"
                v-bind:key="`package-${price.key}`"
                v-bind:price="price"
            />

            <package-price
                v-for="price in packagePrices.single"
                v-bind:key="`package-${price.key}`"
                v-bind:price="price"
            />

            <extra-price
                v-for="price in mainPriceDates"
                v-bind:key="`extra-${price.key}`"
                v-bind:price="price"
            />
        </template>

        <template v-if="treatPackages.length > 0">
            <div class="row is-headline">Pakete</div>
            <treat-package
                v-for="treatPackage in treatPackages"
                v-bind:key="`treatPackage-${treatPackage.id}`"
                v-bind:treatPackage="treatPackage"
            />
        </template>

        <template v-if="hotelContingentPrices.length > 0 || extraHotelSuplementPrices.length > 0">
            <div class="row is-headline">Mehrbettzimmer und sonstige Zimmer</div>
            <hotel-contingent-price
                v-for="price in hotelContingentPrices"
                v-bind:key="`contingent-${price.key}`"
                v-bind:price="price"
            />
            <extra-price
                v-for="price in extraHotelSuplementPrices"
                v-bind:key="`extra-${price.key}`"
                v-bind:price="price"
            />
        </template>

        <template v-if="ferryContingentPrices.length > 0 || extraFerrySuplementPrices.length > 0">
            <div class="row is-headline">Außenkabinen (insgesamt für alle Fähren)</div>
            <ferry-contingent-price
                v-for="price in ferryContingentPrices"
                v-bind:key="`contingent-${price.key}`"
                v-bind:price="price"
            />
            <extra-price
                v-for="price in extraFerrySuplementPrices"
                v-bind:key="`extra-${price.key}`"
                v-bind:price="price"
            />
        </template>

        <template v-if="trainContingentPrices.length > 0 || extraTrainSuplementPrices.length > 0">
            <div class="row is-headline">Zugfahrten 1. Klasse (insgesamt für alle Züge)</div>
            <train-contingent-price
                v-for="price in trainContingentPrices"
                v-bind:key="`contingent-${price.key}`"
                v-bind:price="price"
            />
            <extra-price
                v-for="price in extraTrainSuplementPrices"
                v-bind:key="`extra-${price.key}`"
                v-bind:price="price"
            />
        </template>

        <template v-if="extraFerryPrices.length > 0">
            <div class="row is-headline">Sonstige Leistungen auf der Fähre</div>
            <extra-price
                v-for="price in extraFerryPrices"
                v-bind:key="`extra-${price.key}`"
                v-bind:price="price"
            />
        </template>

        <template v-if="airlinePrices.length > 0">
            <div class="row is-headline">Flüge pro Person</div>
            <provider-price
                v-for="price in airlinePrices"
                v-bind:key="`airline-${price.key}`"
                v-bind:price="price"
            />
        </template>

        <template v-if="hotelPrices.length > 0">
            <div class="row is-headline">zusätzliche Hotelleistungen pro Person</div>
            <provider-price
                v-for="price in hotelPrices"
                v-bind:key="`hotel-${price.key}`"
                v-bind:price="price"
            />
        </template>

        <template v-if="ferryPrices.night.length > 0">
            <div class="row is-headline">zusätzliche Nachtfähren pro Person (inkl. Fährpassagen für alle Fahrzeuge)</div>
            <ferry-price
                v-for="price in ferryPrices.night"
                v-bind:key="`ferry-${price.key}`"
                v-bind:price="price"
            />
        </template>

        <template v-if="ferryPrices.day.length > 0">
            <div class="row is-headline">zusätzliche Tagfähren pro Person (inkl. Fährpassage für alle Fahrzeuge)</div>
            <ferry-price
                v-for="price in ferryPrices.day"
                v-bind:key="`ferry-${price.key}`"
                v-bind:price="price"
            />
        </template>

        <template v-if="trainPrices.length > 0">
            <div class="row is-headline">zusätzliche Zugfahrten pro Person</div>
            <provider-price
                v-for="price in trainPrices"
                v-bind:key="`train-${price.key}`"
                v-bind:price="price"
            />
        </template>

        <template v-if="extraFoodPrices.length > 0 || otherFoodPrices.length > 0">
            <div class="row is-headline">zusätzliche Leistungen an Bord der Fähre</div>
            <provider-price
                v-for="price in otherFoodPrices"
                v-bind:key="`other-${price.key}`"
                v-bind:price="price"
            />
            <extra-price
                v-for="price in extraFoodPrices"
                v-bind:key="`extra-${price.key}`"
                v-bind:price="price"
            />
        </template>

        <template v-if="extraTourPrices.length > 0 || otherTourPrices.length > 0">
            <div class="row is-headline">zusätzliche Reiseleiter-Leistungen</div>
            <provider-price
                v-for="price in otherTourPrices"
                v-bind:key="`other-${price.key}`"
                v-bind:price="price"
            />
            <extra-price
                v-for="price in extraTourPrices"
                v-bind:key="`extra-${price.key}`"
                v-bind:price="price"
            />
        </template>

        <template v-if="extraGroupPrices.length > 0 || otherGroupPrices.length > 0">
            <div class="row is-headline">zusätzliche Gruppen-Leistungen</div>
            <provider-price
                v-for="price in otherGroupPrices"
                v-bind:key="`other-${price.key}`"
                v-bind:price="price"
            />
            <extra-price
                v-for="price in extraGroupPrices"
                v-bind:key="`extra-${price.key}`"
                v-bind:price="price"
            />
        </template>

        <template v-if="extraPersonPrices.length > 0 || otherPersonPrices.length > 0">
            <div class="row is-headline">zusätzliche Leistungen pro Person</div>
            <provider-price
                v-for="price in otherPersonPrices"
                v-bind:key="`other-${price.key}`"
                v-bind:price="price"
            />
            <extra-price
                v-for="price in extraPersonPrices"
                v-bind:key="`extra-${price.key}`"
                v-bind:price="price"
            />
        </template>

        <template v-if="taxPrices.length > 0">
            <div class="row is-headline">Kurtaxe</div>
            <extra-price
                v-for="price in taxPrices"
                v-bind:key="`extra-${price.key}`"
                v-bind:price="price"
            />
        </template>
    </div>
</template>

<script>
import SimpleMenu from '@components/SimpleMenu'

import store      from '@calculations/store';
import { Row, TreatPackage } from '@calculations/components'

import Prices     from './Prices'
import { getAccommodationLabel } from '@utilities/functions';
import {settings} from "@clientSpecific/utilities/settings";


export default {
    store,

    components: {
        ...Prices,
        Row,
        TreatPackage,
        SimpleMenu
    },

    data: function() {
        return {
            marginTax: false,
            hide: false
        }
    },

    computed: {
        singleRoomLimit: function () { return settings.calculation.singleRoomLimit },

        hasTwoCurrencies: function () {
            this.hide = true;
            this.$nextTick(() => {
                this.hide = false;
            });
            return this.orderCurrency !== this.calculationCurrency;
        },

        roomTypes: function() {return this.$store.state.options.roomTypes.crud_prices ? this.$store.state.options.roomTypes.crud_prices : [] },

        marginsEnabled: {
            get() { return this.marginTax },
            set(v) {
                //v = true
                this.marginTax = v;
                this.$store.commit('toggleMargins', v)
            }
        },
        prices: function () { return this.$store.state.prices },
        notIncludedPrices: function () { return this.$store.getters.notIncludedPrices },
        persons: function () { return this.$store.getters.persons },

        packagePrices: function () {
            const doublePrices = [...this.$store.getters.packagePrices]
                .filter(price => price.orderHotelRoom.type.persons === 2);

            const singlePrices = [...this.$store.getters.packagePrices]
                .filter(price => price.orderHotelRoom.type.persons === 1);

            return {
                double: doublePrices,
                single: singlePrices
            }
        },

        orderCurrency: function () {
            return this.$store.state.order.orderCurrency;
        },

        calculationCurrency: function () {
            return this.$store.state.order.calculationCurrency;
        },

        treatPackages: function () {
            return this.$store.getters.treatPackages
                .filter(({requests}) => requests[0].placeholder.clientOffer.status !== 'included')
        },

        hotelContingentPrices: function () {
            const hotelContingentPrices = this.prices.contingentPrices
                .filter(price => price.orderHotelRoom)
                .filter(({orderHotelRoom}) => orderHotelRoom.type.persons > 2 || orderHotelRoom.type.type !== 'standard')
                .map(item => ({
                    ...item,
                    _sort: item.orderHotelRoom.type.persons === 2 ? 0 : ( item.orderHotelRoom.type.persons === 1 ? 1 : 2)
                }));

            return [...hotelContingentPrices]
                .sort((a, b) => a._sort - b._sort)
        },

        extraHotelSuplementPrices: function () {
            return this.prices.extraPrices
                .filter(({offerTextBlock}) => offerTextBlock === 'hotelSuplements')
        },

        ferryContingentPrices: function () {
            const ferryContingentPrices = this.prices.contingentPrices
                .filter(price => price.orderFerryCabin)
                .filter(({orderFerryCabin}) => orderFerryCabin.type.persons > 2 || orderFerryCabin.type.type !== 'inside')
                .map(item => ({
                    ...item,
                    _sort: item.orderFerryCabin.type.persons === 2 ? 0 : ( item.orderFerryCabin.type.persons === 1 ? 1 : 2)
                }));

            return [...ferryContingentPrices]
                .sort((a, b) => a._sort - b._sort)
        },

        trainContingentPrices: function () {
            const trainContingentPrices = this.prices.contingentPrices
                .filter(price => price.trainCabinType && price.trainCabinType.type)
                .map(item => ({
                    ...item,
                    _sort: item.trainCabinType.persons === 2 ? 0 : ( item.trainCabinType.persons === 1 ? 1 : 2)
                }));

            return [...trainContingentPrices]
                .sort((a, b) => a._sort - b._sort)
        },

        extraFerrySuplementPrices: function () {
            return this.prices.extraPrices
                .filter(({offerTextBlock}) => offerTextBlock === 'ferrySuplements')
        },

        extraTrainSuplementPrices: function () {
            return this.prices.extraPrices
                .filter(({offerTextBlock}) => offerTextBlock === 'trainSuplements')
        },

        hotelPrices: function () {
            return this.notIncludedPrices.hotelPrices
        },

        ferryPrices: function () {
            return {
                day: this.notIncludedPrices.ferryPrices
                    .filter(({placeholder}) => placeholder.type === 'day'),
                night: this.notIncludedPrices.ferryPrices
                    .filter(({placeholder}) => placeholder.type === 'night'),
            }
        },

        trainPrices: function () {
            return this.notIncludedPrices.trainPrices;
        },

        airlinePrices: function () {
            return this.notIncludedPrices.airlinePrices
        },

        extraFerryPrices: function () {
            return this.prices.extraPrices
                .filter(({offerTextBlock}) => offerTextBlock === 'ferryOther')
        },

        extraFoodPrices: function () {
            return this.prices.extraPrices
                .filter(({offerTextBlock}) => ['foodOnBoard', 'optionalFoodOnBoard'].includes(offerTextBlock))
        },

        otherFoodPrices: function () {
            return this.notIncludedPrices.otherPrices
                .filter(({placeholder}) => placeholder.otherServiceType.category === 'food_on_board')
        },

        extraTourPrices: function () {
            return this.prices.extraPrices
                .filter(({offerTextBlock}) => ['tourGuides', 'optionalTourGuides'].includes(offerTextBlock))
        },

        otherTourPrices: function () {
            return this.notIncludedPrices.otherPrices
                .filter(({placeholder}) => placeholder.otherServiceType.category === 'tour_guide')
        },

        extraGroupPrices: function () {
            return this.prices.extraPrices
                .filter(({offerTextBlock}) => ['groupServices', 'optionalGroupServices'].includes(offerTextBlock))
        },

        otherGroupPrices: function () {
            return this.notIncludedPrices.otherPrices
                .filter(({placeholder}) => !['tour_guide', 'food_on_board'].includes(placeholder.otherServiceType.category))
                .filter(({placeholder}) => placeholder.clientOffer.priceType === 'per_group')
        },

        extraPersonPrices: function () {
            return this.prices.extraPrices
                .filter(({offerTextBlock}) => ['services', 'optionalServices'].includes(offerTextBlock))
        },

        otherPersonPrices: function () {
            return this.notIncludedPrices.otherPrices
                .filter(({placeholder}) => !['tour_guide', 'food_on_board'].includes(placeholder.otherServiceType.category))
                .filter(({placeholder}) => placeholder.clientOffer.priceType === 'per_person')
        },

        mainPriceDates: function () {
            return this.prices.extraPrices
                .filter(({offerTextBlock}) => ['mainPriceDates'].includes(offerTextBlock))
        },

        taxPrices: function () {
            return this.prices.extraPrices
                .filter(({offerTextBlock}) => ['tax'].includes(offerTextBlock))
        }
    },

    methods: {
        getAccommodationLabel,

        addExtra: function (obj) {
            this.$store.commit('addExtra', obj);
        },

        addPackage: function (type) {
            this.$store.commit('addPackage', type)
        }
    }
}
</script>
