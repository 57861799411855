var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popover",
    { attrs: { trigger: "hover" } },
    [
      _vm.value
        ? _c("deadline", { attrs: { value: _vm.value } })
        : _c("span", { staticClass: "bp-deadline" }, [_vm._v("-")]),
      _vm._v(" "),
      _vm.todo
        ? _c("template", { slot: "popover" }, [
            _c("div", { staticClass: "level level-left" }, [
              _c(
                "div",
                { staticClass: "level-item" },
                [
                  _vm.todo.user
                    ? _c("user-image", {
                        attrs: { user: _vm.todo.user, small: "" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                { class: { "is-line-through": _vm.todo.status === "done" } },
                [_c("strong", [_vm._v(_vm._s(_vm.todo.name))])]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }