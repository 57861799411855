var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.showNextRow
        ? _c(
            "div",
            {
              staticClass: "row is-headline is-darker",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.toggleCollapse.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "level-left" }, [
                _c(
                  "i",
                  {
                    staticClass: "collapse-icon material-icons level-item",
                    class: { "is-rotated": !_vm.isOpen },
                  },
                  [_vm._v("keyboard_arrow_up")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "level-item" }, [
                  _vm._v(_vm._s(_vm.level.level)),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSubRow
        ? [
            _vm._l(_vm.level.boards, function (board) {
              return _vm.currentLevel === "board"
                ? _c("board-row", {
                    key: board.key,
                    attrs: {
                      board: board,
                      options: _vm.options,
                      "show-next-row": _vm.level.boards.length < 3,
                    },
                    on: {
                      editPriceGroup: (priceGroup) =>
                        _vm.$emit("editPriceGroup", priceGroup),
                      deletePriceGroup: (priceGroup) =>
                        _vm.$emit("deletePriceGroup", priceGroup),
                      updateBoard: _vm.updateBoard,
                    },
                  })
                : _vm._e()
            }),
            _vm._v(" "),
            _vm._l(_vm.level.routes, function (route) {
              return _vm.currentLevel === "route"
                ? _c("route-row", {
                    key: route.id,
                    attrs: {
                      route: route,
                      options: _vm.options,
                      "show-next-row": _vm.level.routes.length < 3,
                    },
                    on: {
                      editRoute: function ($event) {
                        return _vm.$emit("editRoute", route)
                      },
                      deleteRoute: function ($event) {
                        return _vm.$emit("deleteRoute", route)
                      },
                      addPriceGroup: function ($event) {
                        return _vm.$emit("addPriceGroup", route)
                      },
                      editPriceGroup: (priceGroup) =>
                        _vm.$emit("editPriceGroup", priceGroup),
                      deletePriceGroup: (priceGroup) =>
                        _vm.$emit("deletePriceGroup", priceGroup),
                      updateRoute: _vm.updateRoute,
                    },
                  })
                : _vm._e()
            }),
            _vm._v(" "),
            _vm._l(_vm.level.routes, function (route) {
              return _vm.currentLevel === "trainRoute"
                ? _c("train-route-row", {
                    key: route.id,
                    attrs: {
                      route: route,
                      options: _vm.options,
                      "show-next-row": _vm.level.routes.length < 3,
                    },
                    on: {
                      editRoute: function ($event) {
                        return _vm.$emit("editTrainRoute", route)
                      },
                      deleteTrainRoute: function ($event) {
                        return _vm.$emit("deleteTrainRoute", route)
                      },
                      addPriceGroup: function ($event) {
                        return _vm.$emit("addPriceGroup", route)
                      },
                      editPriceGroup: (priceGroup) =>
                        _vm.$emit("editPriceGroup", priceGroup),
                      deletePriceGroup: (priceGroup) =>
                        _vm.$emit("deletePriceGroup", priceGroup),
                      updateRoute: _vm.updateTrainRoute,
                    },
                  })
                : _vm._e()
            }),
            _vm._v(" "),
            _vm._l(_vm.level.otherServices, function (otherService) {
              return _vm.currentLevel === "otherServiceType"
                ? _c("other-service-type-row", {
                    key: otherService.id,
                    attrs: {
                      otherService: otherService,
                      options: _vm.options,
                      "show-next-row": _vm.level.otherServices.length < 3,
                    },
                    on: {
                      editOtherService: function ($event) {
                        return _vm.$emit("editOtherService", otherService)
                      },
                      deleteOtherService: function ($event) {
                        return _vm.$emit("deleteOtherService", otherService)
                      },
                      addPriceGroup: function ($event) {
                        return _vm.$emit("addPriceGroup", otherService)
                      },
                      editPriceGroup: (priceGroup) =>
                        _vm.$emit("editPriceGroup", priceGroup),
                      deletePriceGroup: (priceGroup) =>
                        _vm.$emit("deletePriceGroup", priceGroup),
                      updateOtherService: _vm.updateOtherService,
                    },
                  })
                : _vm._e()
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }