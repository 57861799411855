var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "row",
    [
      _c("template", { slot: "type" }, [
        _c(
          "span",
          {
            staticClass: "icon is-pointer",
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.togglePriceType.apply(null, arguments)
              },
            },
          },
          [
            _vm.isSingle ? [_vm._v("EZ")] : _vm._e(),
            _vm._v(" "),
            _vm.isPerPerson ? [_vm._v("DZ")] : _vm._e(),
            _vm._v(" "),
            _vm.isPerGroup
              ? [_c("i", { staticClass: "material-icons" }, [_vm._v("people")])]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "date" }, [
        _c(
          "div",
          { staticClass: "level-left" },
          [
            _c("span", { staticClass: "level-item" }, [_vm._v("von")]),
            _vm._v(" "),
            _c("multiselect", {
              staticClass: "is-tiny level-item",
              attrs: { options: _vm.personsOptions.from, placeholder: "0" },
              model: {
                value: _vm.minPersons,
                callback: function ($$v) {
                  _vm.minPersons = $$v
                },
                expression: "minPersons",
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "level-item" }, [_vm._v("bis")]),
            _vm._v(" "),
            _c("multiselect", {
              staticClass: "is-tiny level-item",
              attrs: {
                options: _vm.personsOptions.to,
                label: "label",
                "track-by": "label",
                placeholder: "0",
              },
              model: {
                value: _vm.maxPersons,
                callback: function ($$v) {
                  _vm.maxPersons = $$v
                },
                expression: "maxPersons",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm.isSingle ? [_vm._v("EZ")] : [_vm._v("Pax")]], 2),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "nights" }, [_vm._v("-")]),
      _vm._v(" "),
      _c("template", { slot: "name" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name",
            },
          ],
          staticClass: "input",
          attrs: { type: "text" },
          domProps: { value: _vm.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.name = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("template", { slot: "provider" }, [_vm._v("-")]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "currency" },
        [
          _vm.editCurrency
            ? _c("multiselect", {
                attrs: { options: _vm.potentialCurrencies, placeholder: "EUR" },
                on: {
                  input: function ($event) {
                    _vm.editCurrency = false
                  },
                },
                model: {
                  value: _vm.price.buyPrice.currency,
                  callback: function ($$v) {
                    _vm.$set(_vm.price.buyPrice, "currency", $$v)
                  },
                  expression: "price.buyPrice.currency",
                },
              })
            : [
                _c("div", { staticClass: "is-currency level-left" }, [
                  _c("span", { staticClass: "level-item" }, [
                    _vm._v(_vm._s(_vm.price.buyPrice.currency)),
                  ]),
                  _vm._v(" "),
                  !_vm.editCurrency
                    ? _c(
                        "i",
                        {
                          staticClass: "material-icons level-item is-actions",
                          on: {
                            click: function ($event) {
                              _vm.editCurrency = true
                            },
                          },
                        },
                        [_vm._v("edit")]
                      )
                    : _vm._e(),
                ]),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "sellPrice" },
        [
          _vm.isPerGroup
            ? _c("input-price", {
                attrs: {
                  "value-in-order-currency": _vm.groupPriceInOrderCurrency,
                },
                model: {
                  value: _vm.buyPrice,
                  callback: function ($$v) {
                    _vm.buyPrice = $$v
                  },
                  expression: "buyPrice",
                },
              })
            : _c("span", { staticClass: "empty" }, [_vm._v("-")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "profit" },
        [
          _vm.isPerPerson
            ? _c("input-price", {
                attrs: {
                  "value-in-order-currency": _vm.personPriceInOrderCurrency,
                  highlight: _vm.personsInRange,
                },
                model: {
                  value: _vm.buyPrice,
                  callback: function ($$v) {
                    _vm.buyPrice = $$v
                  },
                  expression: "buyPrice",
                },
              })
            : _vm.isPerGroup && _vm.persons
            ? _c("input-price", {
                attrs: {
                  value: _vm.buyPrice / _vm.persons,
                  "value-in-order-currency": _vm.personPriceInOrderCurrency,
                  highlight: _vm.personsInRange,
                  readOnly: "",
                },
              })
            : _c("span", { staticClass: "empty" }, [_vm._v("-")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "margin" },
        [
          _vm.isSingle
            ? _c("input-price", {
                attrs: {
                  "value-in-order-currency": _vm.singlePriceInOrderCurrency,
                  highlight: _vm.singleInrange,
                },
                model: {
                  value: _vm.buyPrice,
                  callback: function ($$v) {
                    _vm.buyPrice = $$v
                  },
                  expression: "buyPrice",
                },
              })
            : !_vm.supplementPrices && _vm.isPerGroup && _vm.persons
            ? _c("input-price", {
                attrs: {
                  value: _vm.buyPrice / _vm.persons,
                  "value-in-order-currency": _vm.singlePriceInOrderCurrency,
                  highlight: _vm.personsInRange,
                  readOnly: "",
                },
              })
            : !_vm.supplementPrices && _vm.isPerPerson
            ? _c("input-price", {
                attrs: {
                  value: _vm.buyPrice,
                  "value-in-order-currency": _vm.singlePriceInOrderCurrency,
                  highlight: _vm.personsInRange,
                  readOnly: "",
                },
              })
            : _c("span", { staticClass: "empty" }, [_vm._v("-")]),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "actions" }, [
        _c(
          "i",
          {
            staticClass: "material-icons",
            on: {
              click: function ($event) {
                return _vm.$emit("delete")
              },
            },
          },
          [_vm._v("delete")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }