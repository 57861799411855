<template>
    <div>
        <i v-if="hasNoIcon === false" class="material-icons" title="Board">local_dining</i>
        <template v-if="hasText">Verpflegung:</template>

        <div class="select">
            <select v-model="localBoard">
                <option v-for="board in boardList" v-bind:value="board.key">{{ board.value }}</option>
            </select>
        </div>
    </div>
</template>


<script>
import { getBoardOptions } from '@utilities/functions';


export default {
    props: {
        value:      { required: true },
        isExtended: { type: Boolean, default: false },
        hasNoIcon:  { type: Boolean, default: false },
        hasText:    { type: Boolean, default: false }
    },


    computed: {
        localBoard: {
            get: function () { return this.value; },
            set: function (board) { this.$emit('input', board); }
        }
    },


    data: function () {
        return {
            boardList: []
        }
    },


    created: function () {
        getBoardOptions().then(options => {
            this.boardList = this.isExtended ? options.boardExtended : options.board;
        });
    }
}
</script>
