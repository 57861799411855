var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is-truncated is-250" },
    [
      _vm._l(_vm.sortedProviders, function (provider) {
        return [
          provider.classification === "1"
            ? [
                provider.agency
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: "/agencies/" + provider.agency.id,
                          title: provider.agency.name,
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(provider.agency.name))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                provider.agency && provider.provider
                  ? [_vm._v(" - ")]
                  : _vm._e(),
                _vm._v(" "),
                provider.provider
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.providerUrl(provider.provider),
                          title: provider.provider.name,
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(provider.provider.name))]
                    )
                  : _vm._e(),
              ]
            : _c(
                "button",
                {
                  staticClass: "concept-provider-btn",
                  class: "is-" + provider.classification,
                  attrs: { title: _vm.getLabel(provider) },
                  on: {
                    click: function ($event) {
                      return _vm.openUrl(provider)
                    },
                  },
                },
                [_vm._v(_vm._s(provider.classification))]
              ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }