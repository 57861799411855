import orderBy from 'lodash/orderBy';
import get     from 'lodash/get';
import isEqual from 'lodash/isEqual';
import has     from 'lodash/has';

import { dateViewtoISO, prepareForBackend } from '@utilities/functions';
import { notifySuccess, notifyError } from '@components/Notification';
import { Pagination } from '@components/CRUD';
import Loading from '@components/Loading';

export default {
    data: function () {
        return {
            rows: [],

            orderBy: '',
            orderDirection: 'asc',
            orderType: String,

            loading: false,
            showForm: false,

            deleting: false,
            submitting: false,

            emptyForm: {},
            form: {},
            filters: {},
            options: {},

            currentPage: 1,
            perPage: 20,
        }
    },

    methods: {
        editRow: function (params) { console.log('replace this method to use', params); },
        addRow: function (params) { console.log('replace this method to use', params); },
        deleteRow: function (params) { console.log('replace this method to use', params); },
        onSuccess: function (data) { },
        onError: function (error) { },

        orderClass: function (column) {
            if (column === this.orderBy) {
                return `is-order-${this.orderDirection}`;
            }

            return '';
        },

        toggleOrder: function (column, type) {
            if (column === this.orderBy) {
                this.orderDirection = this.orderDirection === 'asc' ? 'desc' : 'asc';
            } else {
                this.orderBy = column;
                this.orderType = type;
                this.orderDirection = 'asc';
            }
        },

        // Form
        submitForm: function () {
            if (!this.isValid) {
                notifyError('Please fill fields!');
            } else {
                this.submitting = has(this.form, 'id') ? this.form.id : null;

                this.submitRow()
                    .then(response => {
                        if (this.isEditMode) {
                            const row = response.data
                            const foundIndex = this.rows
                                .findIndex(item => item.id === row.id)
                            this.rows.splice(foundIndex, 1, row);
                        } else {
                            this.rows.push(response.data);
                        }

                        this.showForm = false;
                        this.submitting = false;

                        notifySuccess('Die Daten wurden erfolgreich gespeichert');

                        this.onSuccess(response.data);
                    }, error => {
                        notifyError('Die Daten konnten nicht gespeichert werden');
                        this.submitting = false;
                        this.onError(error);
                    })
            }
        },

        submitRow: function () {
            const self = this;

            return new Promise(function (resolve, reject) {
                (self.isEditMode ? self.editRow({
                    id: self.form.id,
                    data: self.preparedForBackend
                }) : self.addRow(self.preparedForBackend))
                    .then(response => {
                        resolve(response);
                    }, error => {
                        reject(error);
                    })
            });
        },

        openForm: function (row = {}) {
            if (!this.showForm) {
                this.form = Object.assign({}, this.emptyForm, row);
                this.showForm = true;
            }
        },

        cancelForm: function () {
            this.form = {};
            this.showForm = false;
        },

        startDelete: function ({id}, prompt = 'Do you really want to delete this row?') {
            if (confirm(prompt)) {
                this.deleting = id;
                this.deleteRow(id)
                    .then(response => {
                        this.rows = this.rows.filter(row => row.id !== id);
                        this.deleting = false;
                        notifySuccess('Row successfully deleted');
                    }, error => {
                        notifyError('Error while deleting row')
                    });
            }
        },

        isEditing: function (row) {
            return this.showForm && this.isEditMode && this.form.id === row.id;
        },

        isSubmitting: function (row) {
            return this.submitting === (has(row, 'id') ? row.id : null);
        },

        isDeleting: function (row) {
            return this.deleting === row.id;
        },
    },

    computed: {
        orderedRows: function () {
            if (this.orderBy !== '') {
                return orderBy(this.rows, (item) => {
                    if (Array.isArray(this.orderBy)) {
                        return this.orderBy.map(o => this.orderType(get(item, o)));
                    } else {
                        return this.orderType(get(item, this.orderBy));
                    }
                }, this.orderDirection);
            }

            return this.rows;
        },

        filteredRows: function () {
            return this.orderedRows;
        },

        paginatedRows: function () {
            if (this.perPage > 0) {
                return this.filteredRows.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
            }

            return this.filteredRows;
        },

        pagination: {
            get: function () {
                return {
                    items: this.filteredRows.length,
                    perPage: this.perPage,
                    current: this.currentPage,
                }
            },

            set: function (pagination) {
                const { perPage, current } = pagination;
                this.currentPage = current;
                this.perPage = perPage;
            }
        },

        isEmpty: function () {
            return this.filteredRows.length === 0 && !this.loading;
        },

        isEditMode: function () {
            return this.form && has(this.form, 'id');
        },

        isValid: function () {
            return true;
        },

        preparedForBackend: function () {
            return prepareForBackend(this.form);
        }
    },

    directives: {
        orderBy: {
            bind: function (el, binding, vnode) {
                el.classList.add('is-sortable');
                el.addEventListener('click', (row) => {
                    let orderType = binding.modifiers.number ? Number : String;
                    orderType = binding.modifiers.date ? dateViewtoISO : orderType;

                    vnode.context.toggleOrder(binding.value, orderType);
                })
            },

            componentUpdated: function (el, binding, vnode) {
                const orderDirection = vnode.context.orderDirection;
                const orderBy = vnode.context.orderBy;
                let currentOrder = binding.value === orderBy;

                if (Array.isArray(binding.value)) {
                    currentOrder = isEqual(binding.value, [...orderBy]);
                }

                el.classList.toggle(`is-order-asc`, orderDirection === 'asc' && currentOrder);
                el.classList.toggle('is-order-desc', orderDirection === 'desc' && currentOrder);
            }
        }
    },

    components: {
        Pagination,
        Loading
    }
}
