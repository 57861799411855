var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "flex", "align-items": "center" } },
    [
      _vm.value.date
        ? [
            _c(
              "div",
              {
                staticClass: "user",
                staticStyle: { width: "18px", height: "18px" },
                attrs: { favicon: "true" },
              },
              [
                _c("div", {
                  staticClass: "user__avatar",
                  style:
                    "background-image: url('/clientSpecific/img_user/" +
                    _vm.value.user +
                    ".png');",
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "i",
              {
                staticClass: "material-icons",
                staticStyle: { "font-size": "12px", "margin-left": "5px" },
                style: _vm.value.style,
              },
              [_vm._v(_vm._s(_vm.value.icon))]
            ),
            _vm._v(" "),
            _c("span", { staticStyle: { "margin-left": "5px" } }, [
              _vm._v(_vm._s(_vm.value.date)),
            ]),
          ]
        : _c("span", [_vm._v("-")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }