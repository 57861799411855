var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { classes: "placeholder", visible: _vm.isModalVisible },
      on: { closeModal: _vm.closeModal },
    },
    [
      _vm.modalLoading
        ? [
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _c("loading", {
                  attrs: { textInFront: "Daten werden aktualisiert" },
                }),
              ],
              1
            ),
          ]
        : [
            _c("template", { slot: "header" }, [
              _c("div", { staticClass: "is-saving" }, [
                _vm.isSaving === "ok"
                  ? _c("i", { staticClass: "material-icons" }, [
                      _vm._v("check"),
                    ])
                  : _vm.isSaving
                  ? _c("i", { staticClass: "material-icons icon-spin" }, [
                      _vm._v("refresh"),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.localPlaceholder.serviceDescription)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bp-row-form extra-info" }, [
                _c("div", [
                  _c(
                    "div",
                    [
                      _c(
                        "i",
                        {
                          staticClass: "material-icons",
                          attrs: { title: "Zug" },
                        },
                        [_vm._v("directions_train")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "buttons has-addons toggle-switch has-margin-right",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "button is-large",
                              class: {
                                "is-primary":
                                  _vm.localPlaceholder.type === "night",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.localPlaceholder.type = "night"
                                },
                              },
                            },
                            [_vm._v("Nacht")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "button is-large",
                              class: {
                                "is-primary":
                                  _vm.localPlaceholder.type === "day",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.localPlaceholder.type = "day"
                                },
                              },
                            },
                            [_vm._v("Tag")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("async-select", {
                        ref: "startTrainStation",
                        staticClass: "has-margin-right",
                        attrs: {
                          api: "train_stations",
                          "custom-label": (item) => item.name,
                          crudLinkTitle: "Fügen Sie einen neuen Bahnhof hinzu",
                          clearable: false,
                          "allow-empty": false,
                          placeholder: "Abfahrtsbahnhof",
                        },
                        model: {
                          value: _vm.localPlaceholder.startTrainStation,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.localPlaceholder,
                              "startTrainStation",
                              $$v
                            )
                          },
                          expression: "localPlaceholder.startTrainStation",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn-single-toggle has-margin-right",
                            class: [
                              _vm.endTrainStationRestriction ? "" : "is-active",
                            ],
                            attrs: {
                              title: "Nur bestehende Zugverbindungen suchen",
                            },
                            on: {
                              click: function ($event) {
                                _vm.endTrainStationRestriction =
                                  !_vm.endTrainStationRestriction
                              },
                            },
                          },
                          [
                            _c(
                              "i",
                              {
                                staticClass: "material-icons",
                                staticStyle: { "vertical-align": "-2px" },
                              },
                              [_vm._v("timeline")]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("async-select", {
                        attrs: {
                          api: "train_stations",
                          "custom-label": (item) => item.name,
                          crudLinkTitle: "Fügen Sie einen neuen Bahnhof hinzu",
                          clearable: false,
                          "allow-empty": false,
                          params:
                            _vm.endTrainStationRestriction &&
                            _vm.localPlaceholder.startTrainStation
                              ? {
                                  _groups: [
                                    "trainStation:service_day_time",
                                    "provider_train_route",
                                    "provider_read",
                                    "location_train_station:provider_train_route",
                                  ],
                                  "endRoutes.startTrainStation.id":
                                    _vm.localPlaceholder.startTrainStation.id,
                                }
                              : { _groups: ["trainStation:service_day_time"] },
                          placeholder: "Ankunftsbahnhof",
                        },
                        model: {
                          value: _vm.localPlaceholder.endTrainStation,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.localPlaceholder,
                              "endTrainStation",
                              $$v
                            )
                          },
                          expression: "localPlaceholder.endTrainStation",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "is-160 has-time" }, [
                  _c(
                    "div",
                    [
                      _vm.serviceDayTimes.length > 0
                        ? _c(
                            "button",
                            {
                              staticClass: "btn-single-toggle has-margin-right",
                              class: [_vm.onlyTime ? "" : "is-active"],
                              attrs: { title: "Uhrzeit" },
                              on: {
                                click: function ($event) {
                                  _vm.onlyTime = !_vm.onlyTime
                                },
                              },
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "material-icons",
                                  staticStyle: { "vertical-align": "-1px" },
                                },
                                [_vm._v("access_time")]
                              ),
                            ]
                          )
                        : _c("i", { staticClass: "material-icons" }, [
                            _vm._v("access_time"),
                          ]),
                      _vm._v(" "),
                      _vm.onlyTime
                        ? [
                            _c("date-time", {
                              staticClass: "has-margin-right",
                              model: {
                                value: _vm.localPlaceholder.startAt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localPlaceholder, "startAt", $$v)
                                },
                                expression: "localPlaceholder.startAt",
                              },
                            }),
                            _vm._v(" -\n                            "),
                            _c("date-time", {
                              staticClass: "has-margin-left",
                              model: {
                                value: _vm.localPlaceholder.endAt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localPlaceholder, "endAt", $$v)
                                },
                                expression: "localPlaceholder.endAt",
                              },
                            }),
                          ]
                        : [
                            _c(
                              "div",
                              { staticClass: "select" },
                              [
                                _c("multiselect", {
                                  attrs: {
                                    options: _vm.serviceDayTimes,
                                    "track-by": "id",
                                    "custom-label": _vm.serviceDayTimeLabel,
                                  },
                                  on: { select: _vm.selectServiceTime },
                                  model: {
                                    value: _vm.serviceDayTimeSelected,
                                    callback: function ($$v) {
                                      _vm.serviceDayTimeSelected = $$v
                                    },
                                    expression: "serviceDayTimeSelected",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.onlyTime
                    ? _c(
                        "div",
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              attrs: { title: "Einkunft am Bahnhof" },
                            },
                            [_vm._v("av_timer")]
                          ),
                          _vm._v(" "),
                          _c("date-time", {
                            model: {
                              value: _vm.localPlaceholder.checkInAt,
                              callback: function ($$v) {
                                _vm.$set(_vm.localPlaceholder, "checkInAt", $$v)
                              },
                              expression: "localPlaceholder.checkInAt",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    [
                      _c(
                        "i",
                        {
                          staticClass: "material-icons",
                          attrs: { title: "Klasse" },
                        },
                        [_vm._v("receipt")]
                      ),
                      _vm._v(" "),
                      _vm.options.trainLabels.categorySelect
                        ? _c("multiselect", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              "track-by": "key",
                              label: "value",
                              placeholder: "Klasse",
                              options: _vm.objectToKeyValueArray(
                                _vm.options.trainLabels.categorySelect
                              ),
                            },
                            model: {
                              value: _vm.category,
                              callback: function ($$v) {
                                _vm.category = $$v
                              },
                              expression: "category",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "i",
                        {
                          staticClass: "material-icons",
                          attrs: { title: "Klasse" },
                        },
                        [_vm._v("receipt")]
                      ),
                      _vm._v(" "),
                      _vm.trainTypes
                        ? _c("multiselect", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              "track-by": "key",
                              label: "value",
                              placeholder: "Besonderer Zug",
                              options: _vm.objectToKeyValueArray(
                                _vm.trainTypes
                              ),
                            },
                            model: {
                              value: _vm.trainType,
                              callback: function ($$v) {
                                _vm.trainType = $$v
                              },
                              expression: "trainType",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.localPlaceholder.trainType !== "standard" &&
                  _vm.localPlaceholder.type === "day"
                    ? _c(
                        "div",
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              attrs: { title: "Klasse" },
                            },
                            [_vm._v("receipt")]
                          ),
                          _vm._v(" "),
                          _vm.wagonTypes
                            ? _c("multiselect", {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  "track-by": "key",
                                  label: "value",
                                  placeholder: "Wagen",
                                  options: _vm.objectToKeyValueArray(
                                    _vm.wagonTypes
                                  ),
                                },
                                model: {
                                  value: _vm.wagonType,
                                  callback: function ($$v) {
                                    _vm.wagonType = $$v
                                  },
                                  expression: "wagonType",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("div", [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        attrs: { title: "Set as Additional" },
                      },
                      [_vm._v("shopping_cart")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "select" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.localPlaceholder.clientOffer.status,
                              expression: "localPlaceholder.clientOffer.status",
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.localPlaceholder.clientOffer,
                                "status",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "included" } }, [
                            _vm._v("inkludiert"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "optional" } }, [
                            _vm._v("optional"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "additional" } }, [
                            _vm._v("gesondert"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm.hasOwnRouteDescription
                  ? _c("div", [
                      _c("div", [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.localPlaceholder.routeDescription,
                              expression: "localPlaceholder.routeDescription",
                            },
                          ],
                          staticClass: "textarea",
                          staticStyle: { resize: "none" },
                          attrs: { id: "description", rows: "4" },
                          domProps: {
                            value: _vm.localPlaceholder.routeDescription,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.localPlaceholder,
                                "routeDescription",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button is-large is-success is-add-new",
                  on: { click: _vm.addNewRequest },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-plus-circle",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" Leistungsträger hinzufügen"),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _vm._l(
                  _vm.localPlaceholder.requests,
                  function (request, index) {
                    return request.id
                      ? _c("request", {
                          key: request.id,
                          attrs: {
                            placeholderInfo: _vm.placeholderInfo,
                            calculationRequest: _vm.calculationRequest,
                          },
                          on: {
                            closeModal: function ($event) {
                              return _vm.$emit(
                                "closeModal",
                                _vm.localPlaceholder
                              )
                            },
                            setCalculationRequest: _vm.setCalculationRequest,
                            saved: _vm.handleRequestSaved,
                          },
                          model: {
                            value: _vm.localPlaceholder.requests[index],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.localPlaceholder.requests,
                                index,
                                $$v
                              )
                            },
                            expression: "localPlaceholder.requests[index]",
                          },
                        })
                      : _vm._e()
                  }
                ),
                _vm._v(" "),
                _vm.isNewRequestVisible
                  ? _c("new-request", {
                      attrs: {
                        placeholderID: _vm.localPlaceholder.id,
                        placeholderInfo: _vm.placeholderInfo,
                      },
                      on: {
                        close: _vm.closeNewRequestModal,
                        closeModal: function ($event) {
                          return _vm.$emit("closeModal", _vm.localPlaceholder)
                        },
                        saved: _vm.handleRequestSaved,
                      },
                      model: {
                        value:
                          _vm.localPlaceholder.requests[
                            _vm.localPlaceholder.requests.length - 1
                          ],
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.localPlaceholder.requests,
                            _vm.localPlaceholder.requests.length - 1,
                            $$v
                          )
                        },
                        expression:
                          "localPlaceholder.requests[localPlaceholder.requests.length - 1]",
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }