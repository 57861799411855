<script>
import Provider from './Provider'
export default {
    mixins: [ Provider ],

    computed: {
        isDay: function () { return this.placeholder.type === 'day' },

        buyPriceKey: function () {
            return this.isDay ? 'dayTicketBuyPrice' : 'buyPrice';
        },

        sellPriceKey: function () {
            return this.isDay ? 'dayTicketSellPrice' : 'sellPrice'
        },

        buyPrice: {
            get: function () { return this.price[this.buyPriceKey] ? this.price[this.buyPriceKey].amount : 0 },
            set: function (value) { this.updatePrice({[this.buyPriceKey]: {
                    [this.buyPriceKey]: {
                        amount: value,
                        currency: this.orderCurrency
                    }
                }}) }
        },

        sellPrice: {
            get: function () { return this.price[this.sellPriceKey] ? this.price[this.sellPriceKey].amount : 0 },
            set: function (value) { this.updatePrice(
                {
                    [this.sellPriceKey]: {
                            amount: value,
                            currency: this.orderCurrency
                        }
                }) }
        },

        buyPriceType: function () { return this.buyPriceKey },
        sellPriceType: function () { return this.sellPriceKey },
    }
}
</script>
