<template>
    <div>
        <crud-table
            ref="table"
            :name="{singular: 'Aufgabe', plural: 'Aufgaben'}"
            :columns="[
                { key: 'name', filter: 'order.id', label: 'Aufgabe', class: 'is-larger-cell'},
                { key: 'daysBeforeStartAt', filter: 'invoiceNumber',label: 'Deadline', class: 'is-larger-cell' },
                { key: 'validForRequestStatuses', label: 'Gültig für', sortable: false},
            ]"
            api="todo_templates"
            @loaded="saveOptions"
            @dataLoaded="store"
            :filter="{...api === 'agencies' && {'agency.id': item.id }, ...api !== 'agencies' && {'provider.id': item.id}, 'order[exists]': false}"
            v-on:add="openForm"
            v-on:edit="editForm"
            v-on:delete="removeItem"
            order="daysBeforeStartAt"
        >
            <h2 slot="header">Automatische Aufgaben für Reisen</h2>
            <todo-form
                ref="form"
                slot="form"
                v-on:success="$refs.table.refresh()"
            />
            <template v-slot:validForRequestStatuses="slotProps">
                <span class="tag is-white px-1 mr-1"  v-for="tag in slotProps.row.validForRequestStatuses">
                    {{ getTag(tag) }}
                </span>
            </template>
            <template v-slot:daysBeforeStartAt="slotProps">
                {{ slotProps.row.daysBeforeStartAt }} Tage vor Leistungsbeginn
            </template>

        </crud-table>


    </div>


</template>

<script>
import Mixin from '../tab';
import TodoForm from './Form';
import {CrudTable} from "@components/CRUD";


export default {
    mixins: [ Mixin ],
    components: { TodoForm, CrudTable },

    methods: {
        removeItem: function(value) {
            this.deleteItem('todo', value);
        },
        editForm: function(value) {
            this.openForm(value);
        },
        saveOptions: function(options) {
            this.$store.commit('updateOptions', options.options)
        },
        store: function(data) {
            console.log(data)
            this.$store.commit('updateData', {
                todoTemplates: data,
            });
        },
        getTag: function (tag) {
            return this.options.requestStatuses && this.options.requestStatuses.find(item => item.key === tag) ? this.options.requestStatuses.find(item => item.key === tag).value : tag;
        }

    },
    computed: {
        api: function () {
            return this.$store.state.api; },
    },
}
</script>
