var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: () => {
            _vm.visible = false
          },
          expression: "() => { visible = false }",
        },
      ],
      staticClass: "simple-menu simple-menu--export",
      class: [_vm.visible ? "is-open" : ""],
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("i", {
        staticClass: "fa fa-file-excel-o",
        on: {
          click: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
            _vm.visible = !_vm.visible
          },
        },
      }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "scale" } }, [
        _vm.visible
          ? _c(
              "div",
              { staticClass: "simple-menu-content" },
              [
                _c("span", [_vm._v("Sprache:")]),
                _vm._v(" "),
                _c("multiselect", {
                  staticClass: "is-smaller",
                  attrs: {
                    options: _vm.exportLanguages,
                    placeholder: "Sprache auswählen",
                    label: "label",
                    "track-by": "value",
                    "allow-empty": false,
                  },
                  on: { select: _vm.handleSelect },
                  model: {
                    value: _vm.language,
                    callback: function ($$v) {
                      _vm.language = $$v
                    },
                    expression: "language",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }