<template>
    <div style="display: flex; align-items: center;">
        <template v-if="value.date">
            <div class="user" favicon="true" style="width: 18px; height:18px"><div class="user__avatar" :style="'background-image: url(\'/clientSpecific/img_user/' + value.user + '.png\');'"></div></div>

            <i class="material-icons" style="font-size: 12px; margin-left: 5px" :style="value.style">{{value.icon}}</i>
            <span style="margin-left: 5px">{{value.date}}</span>
        </template>
        <span v-else>-</span>
    </div>

</template>

<script>
import Cell from './cell';

export default {
    mixins: [ Cell ],
}
</script>
