<template>
    <span>{{ type }}</span>
</template>

<script>
import { getValueWithKey } from '@utilities/functions';
import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        type: function () {
            return getValueWithKey({key: this.value, optionList: this.options.types})
        }
    }
}
</script>
