<template>
    <div class="new-request">
        <div class="overall-buttons">
            <button class="button is-large" v-on:click="$emit('close')"><i aria-hidden="true" class="fa fa-long-arrow-left"></i> Zurück zur Übersicht</button>
        </div>


        <accordion v-bind:isCollapsible="false">
            <div slot="header">
                <div>
                    <request-score v-if="newRequest.ferry" v-bind:rating="newRequest.ferry.buspaketRating" />
                    <i aria-hidden="true" class="fa fa-plus-circle" v-else></i>
                </div>

                <div>
                    LEISTUNGSTRÄGER HINZUFÜGEN

                    <provider-info class="header-extra-info" v-bind:provider="newRequest.ferry">
                        <template v-if="newRequest.type === 'day'">
                            <div>
                                <i class="material-icons spacing-left">perm_identity</i> Kundenkontingent:
                                {{ $store.state.hotelContingent.info.personsAsked }} Tickets
                            </div>
                        </template>
                        <template v-else>
                            <div>
                                <i class="material-icons spacing-left">perm_identity</i> Kundenkontingent:
                                <i class="material-icons no-spacing">directions_boat</i>
                                <i class="material-icons">directions_boat</i>
                                {{ ferryContingent.info.doubleCabins.totalAsked }} Doppelkabinen
                            </div>
                            <div>
                                <i class="material-icons">directions_boat</i>
                                {{ ferryContingent.info.singleCabins.totalAsked }} Einzelkabinen
                            </div>
                            <div>
                                <i class="material-icons no-spacing">directions_boat</i>
                                <i class="material-icons no-spacing">directions_boat</i>
                                <i class="material-icons">directions_boat</i>
                                {{ ferryContingent.info.otherCabins.totalAsked }} Mehrbettkabinen
                            </div>
                        </template>
                    </provider-info>
                </div>

                <div>
                    <label v-on:click.stop>
                        <input type="checkbox" v-model="isMainRequest" />
                        als Leistung festlegen
                    </label>
                </div>
            </div>


            <div slot="content">
                <div class="bp-box is-info">
                    <main>
                        <button
                            v-bind:class="['btn-single-toggle',  onlyFromOrder ? '' : 'is-active']"
                            v-on:click="onlyFromOrder = !onlyFromOrder"
                            v-bind:title="onlyFromOrder ? 'Suche ist auf obigen Route eingeschränkt' : 'Suche ist nicht eingeschränkt'"
                            style="border: 1px solid #dddddd; margin-right: 5px; min-width: 22px; min-height: 22px;"
                        ><i class="material-icons" style="margin: 0">flag</i></button>



                        <div>
                            <ferry-select
                                style="width: 400px"
                                v-model                = "newRequest.ferry"
                                v-bind:placeholderInfo = "onlyFromOrder ? localPlaceholderInfo : {startPort: null, endPort: null, type: null}"
                            ></ferry-select>
                        </div>


                        <agency-select
                            v-bind:provider = "newRequest.ferry"
                            text            = "Agentur"
                            v-on:update     = "agency => newRequest.agency = agency"
                            prefill
                        ></agency-select>




                        <div class="is-second-row">

                            <div>
                                <i class="material-icons">assignment</i> Schiff:
                                <input type="text" class="input" v-model="newRequest.shipName" />
                            </div>


                            <div>
                                <i class="material-icons">format_list_numbered</i> Reservierungs-Nr:
                                <input type="text" class="input" v-model="newRequest.optionNr" />
                            </div>
                        </div>
                    </main>
                </div>


                <div class="columns">
                    <template v-if="newRequest.type === 'night'">
                        <div class="column">
                            <simple-ferry-contingent
                                v-if="newRequest.contingent"
                                v-model="newRequest.contingent.doubleCabins"
                                type="ferry"
                                v-bind:nrOfPersons="2"
                                v-bind:types="ferryTypes"
                                v-bind:isOnlyAsked="true"
                            ></simple-ferry-contingent>
                        </div>

                        <div class="column">
                            <simple-ferry-contingent
                                v-if="newRequest.contingent"
                                v-model="newRequest.contingent.singleCabins"
                                type="ferry"
                                v-bind:nrOfPersons="1"
                                v-bind:types="ferryTypes"
                                v-bind:isOnlyAsked="true"
                            ></simple-ferry-contingent>
                        </div>

                        <div class="column">
                            <ferry-contingent
                                v-if="newRequest.contingent"
                                v-model="newRequest.contingent.otherCabins"
                                type="ferry"
                                v-bind:types="ferryTypes"
                                v-bind:isOnlyAsked="true"
                            ></ferry-contingent>
                        </div>
                    </template>

                    <template v-else>
                        <div class="column">
                            <div class="bp-box is-tickets">
                                <header>
                                    <div><i class="material-icons">credit_card</i> Tickets Tagfähre</div>
                                    <div><i class="material-icons">help</i></div>
                                    <div><button title="Extraticket/-kabine für Reisebegleitung" v-on:click="addExtra"><i class="material-icons">person_add</i></button></div>
                                </header>

                                <main>
                                    <div>
                                        <div><i class="material-icons">accessibility</i>Pax</div>
                                        <div><input
                                            class="input"
                                            type="number"
                                            v-model.number="newRequest.contingent.ticketsAsked"
                                            v-on:focus="$event.target.select()"
                                        /></div>
                                        <div></div>
                                    </div>

                                    <div class="extra-people" v-if="hasExtra">
                                        <div>
                                            <i class="material-icons">person_add</i>
                                            <input
                                                class="input"
                                                type="text"
                                                v-model="newRequest.contingent.ticketsExtraDescription"
                                                v-on:focus="$event.target.select()"
                                            />
                                            <button class="btn-delete" title="Löschen" v-on:click="deleteExtra" tabindex="-1"><i class="material-icons">delete</i></button>
                                        </div>
                                        <div>
                                            <div class="control"><input
                                                class="input"
                                                type="text"
                                                v-model.number="newRequest.contingent.ticketsExtraAsked"
                                                v-on:focus="$event.target.select()"
                                            /></div>
                                        </div>
                                        <div></div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </template>
                </div>


                <div class="columns">
                    <div class="column is-offset-6">
                        <item-prices
                            v-bind:isWithoutPrice="true"
                            v-model="newRequest.itemPrices"
                            v-bind:provider="provider"
                        ></item-prices>
                    </div>
                </div>
            </div>
        </accordion>


        <button class="button is-large" v-on:click="$emit('close')"><i class="material-icons">close</i> Abbrechen</button>

        <button
            class="button is-primary is-large"
            v-bind:class="{ 'is-loading': isLoading }"
            v-on:click="saveNewRequest"
        ><i class="material-icons">check</i> speichern und zur Email</button>

        <button
            class="button is-primary is-large"
            v-bind:class="{ 'is-loading': isLoading }"
            v-on:click="saveNewRequest('close')"
        ><i class="material-icons">keyboard_arrow_right</i> speichern und schließen</button>


        <confirmation
            v-bind:isVisible="isVisibleConfirmation"
            is-local
            text="Soll die Beschreibung der Anfrage in den Platzhalter kopiert werden?"
            v-on:input="value => $emit('confirm', value)"
        ></confirmation>
    </div>
</template>



<script>
import { prepareForBackend } from '@utilities/functions';
import AsyncSelect           from '@components/form/AsyncSelect';
import { notifyError }       from '@components/Notification';
import { fullYear }          from '@utilities/functions';
import FerrySelect           from '@orders/placeholders/components/forms/FerrySelect';
import FerryContingent       from '../components/contingent/Ferry';
import SimpleFerryContingent from '../components/contingent/SimpleFerry';
import mixins                from './mixins';


export default {
    mixins: [mixins],


    computed: {
        localPlaceholderInfo: function () {
            return {
                startPort: this.newRequest.startPort,
                endPort:   this.newRequest.endPort,
                type:      this.placeholderInfo.type
            };
        },


        peopleContingent: function () { return this.$store.state.hotelContingent.info.personsAsked; },
        ferryContingent:  function () { return this.$store.state.ferryContingent; },
        ferryTypes:       function () { return this.$store.state.optionsCabinTypes; },
        routeTypes:       function () { return this.$store.state.options.ferryRoute; },
        provider:         function () { return this.newRequest.ferry ? this.newRequest.ferry : null; }
    },


    data: function () {
        return {
            apiEndpoint: 'ferry_requests',
            hasExtra: false
        };
    },


    methods: {
        saveNewRequest: function (close = false) {
            if (this.newRequest.ferry === null && this.newRequest.agency === null) {
                notifyError('Sie müssen einen Anbieter oder eine Agentur auswählen!');
                return false;
            }

            let copyNewRequest = JSON.parse(JSON.stringify(this.newRequest));

            // Transform 'kein Provider' => null OR ferry to its reference
            copyNewRequest.ferry = copyNewRequest.ferry && copyNewRequest.ferry.id ? '/api/ferries/' + copyNewRequest.ferry.id : null;

            // Preparing for backend use
            copyNewRequest = prepareForBackend(copyNewRequest);

            // Remove contingent reference and id from rooms
            for (let cabin of copyNewRequest.contingent.doubleCabins) { delete cabin.id; delete cabin.contingent; }
            for (let cabin of copyNewRequest.contingent.singleCabins) { delete cabin.id; delete cabin.contingent; }
            for (let cabin of copyNewRequest.contingent.otherCabins)  { delete cabin.id; delete cabin.contingent; }



            this.isLoading = true;

            // API call
            this.addRequest(copyNewRequest, close);
        },


        addExtra: function () {
            this.newRequest.ticketsExtraDescription = '';
            this.newRequest.ticketsExtraReserved    = 0;
            this.newRequest.ticketsExtraAsked       = 0;
            this.hasExtra = true;
        },


        deleteExtra: function () {
            this.newRequest.ticketsExtraDescription = null;
            this.newRequest.ticketsExtraReserved    = 0;
            this.newRequest.ticketsExtraAsked       = 0;
            this.hasExtra = false;
        },


        arrangeCabins: function (array) {
            // moving 'outside' first (for the case we have more than 2 items)
            let localArray = [array.filter(x => x.type.type === 'outside')[0], ...array.filter(x => x.type.type !== 'outside')];

            // moving 'inside' first
            return [localArray.filter(x => x.type.type === 'inside')[0], ...localArray.filter(x => x.type.type !== 'inside')]
        }
    },


    created: function () {
        let contingent = JSON.parse(JSON.stringify(this.ferryContingent));

        // Setting the reserved to 0 (zero)
        contingent.doubleCabins.forEach(x => x.reservedAmount = 0);
        contingent.singleCabins.forEach(x => x.reservedAmount = 0);
        contingent.otherCabins.forEach( x => x.reservedAmount = 0);

        // Arraging so the INSIDE is first and OUTSIDE is second
        contingent.doubleCabins = this.arrangeCabins(contingent.doubleCabins);
        contingent.singleCabins = this.arrangeCabins(contingent.singleCabins);

        // Copying global contingent
        this.newRequest.contingent.doubleCabins = contingent.doubleCabins;
        this.newRequest.contingent.singleCabins = contingent.singleCabins;
        this.newRequest.contingent.otherCabins  = contingent.otherCabins;

        if (this.placeholderInfo.type === 'day') {
            this.newRequest.contingent.ticketsAsked = this.peopleContingent;
        }

        this.newRequest.startPort = this.placeholderInfo.startPort;
        this.newRequest.endPort   = this.placeholderInfo.endPort;
    },


    watch: {
        'newRequest.ferry': function () {
            if (this.newRequest.ferry) {


                // If we have a ferry setting the agency to 'direkt'
                this.newRequest.agency = null;


            }
        }
    },


    components: {
        AsyncSelect,
        FerryContingent,
        SimpleFerryContingent,
        FerrySelect
    }
}
</script>
