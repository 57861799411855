var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Kundentermin bearbeiten",
        createTitle: "Neuen Kundentermin hinzufügen",
      },
    },
    [
      _c("table", [
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "name" } }, [
              _vm._v("Name:"),
            ]),
          ]),
          _vm._v(" "),
          _c("td", [
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.name,
                    expression: "form.name",
                  },
                ],
                staticClass: "input",
                attrs: { type: "text" },
                domProps: { value: _vm.form.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "name", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c(
              "label",
              { staticClass: "label", attrs: { for: "description" } },
              [_vm._v("Beschreibung:")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("tip-tap", {
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "startAt" } }, [
              _vm._v("Datum:"),
            ]),
          ]),
          _vm._v(" "),
          _c("td", [
            _c(
              "div",
              { staticClass: "flatpickr-combined" },
              [
                _c("input-date", {
                  model: {
                    value: _vm.form.startAt,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "startAt", $$v)
                    },
                    expression: "form.startAt",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("-")]),
                _vm._v(" "),
                _c("input-date", {
                  model: {
                    value: _vm.form.endAt,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "endAt", $$v)
                    },
                    expression: "form.endAt",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }