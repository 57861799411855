var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.value, function (data, translation) {
        return [
          _c("gb-flag", {
            attrs: {
              code: _vm.getFlag(translation),
              size: "small",
              iconPath: "/assets/img/flags/",
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }