var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "cell is-full is-relative",
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.toggleCollapse.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "level-left" }, [
              _c(
                "i",
                {
                  staticClass: "collapse-icon material-icons level-item",
                  class: { "is-rotated": !_vm.isOpen },
                },
                [_vm._v("keyboard_arrow_up")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "level-item" }, [
                _vm._v(_vm._s(_vm.name)),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cell is-full is-visible-action is-relative" },
          [
            _c(
              "div",
              { staticClass: "buttons" },
              [
                !_vm.priceGroup.dataCheckedAt
                  ? _c(
                      "button",
                      {
                        staticClass: "button is-smaller is-120",
                        on: { click: _vm.toggleChecked },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons has-margin-right",
                            staticStyle: { color: "#e68c87" },
                          },
                          [_vm._v("cancel")]
                        ),
                        _vm._v(" zu überprüfen\n                "),
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "button is-smaller is-120",
                        on: { click: _vm.toggleChecked },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons has-margin-right",
                            staticStyle: { color: "#60A4AB" },
                          },
                          [_vm._v("check_circle")]
                        ),
                        _vm._v(" überprüft\n                "),
                      ]
                    ),
                _vm._v(" "),
                _c("dropdown", [
                  _c(
                    "button",
                    {
                      staticClass: "button is-smaller",
                      on: { click: _vm.loadTimePeriods },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("date_range"),
                      ]),
                      _vm._v(
                        " Genaue Zeiträume anzeigen\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { attrs: { slot: "list" }, slot: "list" },
                    _vm._l(_vm.timePeriods, function (period) {
                      return _c("li", { key: period.id }, [
                        _c(
                          "div",
                          { staticClass: "level" },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm._f("fullYear")(period.startAt)) +
                                " - " +
                                _vm._s(_vm._f("fullYear")(period.endAt)) +
                                "\n                                "
                            ),
                            _c("days", {
                              attrs: { value: _vm.daysToNumbers(period) },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cell is-small-cell is-actions" },
          [
            _c(
              "update-popover",
              {
                attrs: {
                  update: {
                    updatedAt: _vm.priceGroup.updatedAt,
                    updatedBy: _vm.priceGroup.updatedBy,
                  },
                  api: "/api/price_groups/" + _vm.priceGroup.id,
                },
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("update")])]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-edit",
                on: {
                  click: function ($event) {
                    return _vm.$emit("editPriceGroup", _vm.priceGroup)
                  },
                },
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("edit")])]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-delete",
                on: {
                  click: function ($event) {
                    return _vm.$emit("deletePriceGroup", _vm.priceGroup)
                  },
                },
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("delete")])]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.isOpen
        ? [
            _vm.isLoading
              ? _c("loading")
              : _vm._l(_vm.providerPrices, function (price) {
                  return _c("price-row", {
                    key: price.id,
                    attrs: {
                      options: _vm.options,
                      price: price,
                      "price-type": _vm.priceType,
                      isDay: _vm.isDay,
                    },
                  })
                }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }