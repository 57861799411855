<template>
  <div class="details-score">
        <div class="score" v-bind:data-score="score">
            <div class="score-circle">
                <div class="full slice">
                    <div class="fill"></div>
                </div>
                <div class="slice">
                    <div class="fill"></div>
                    <div class="fill bar"></div>
                </div>
            </div>

            <div class="text">{{ rating }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        rating: {
            type: [Number, String]
        }
    },

    computed: {
        score: function() {
            return parseInt(this.rating) * 10
        }
    }
}
</script>
