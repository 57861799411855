var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { classes: "placeholder", visible: _vm.isModalVisible },
      on: { closeModal: _vm.closeModal },
    },
    [
      _vm.modalLoading
        ? [
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _c("loading", {
                  attrs: { textInFront: "Daten werden aktualisiert" },
                }),
              ],
              1
            ),
          ]
        : [
            _c("template", { slot: "header" }, [
              _c("div", { staticClass: "is-saving" }, [
                _vm.isSaving === "ok"
                  ? _c("i", { staticClass: "material-icons" }, [
                      _vm._v("check"),
                    ])
                  : _vm.isSaving
                  ? _c("i", { staticClass: "material-icons icon-spin" }, [
                      _vm._v("refresh"),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "name" },
                [
                  _vm.localPlaceholder.serviceDescription
                    ? [_vm._v(_vm._s(_vm.localPlaceholder.serviceDescription))]
                    : [_vm._v("Hotel")],
                  _vm._v(" "),
                  _vm.localPlaceholder.hotelType
                    ? _c(
                        "div",
                        {
                          class: [
                            "stars",
                            "has-" + _vm.localPlaceholder.hotelType.stars,
                          ],
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("star"),
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("star"),
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("star"),
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("star"),
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("star"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.localPlaceholder.hotelType &&
                  _vm.localPlaceholder.hotelType.category === "superior"
                    ? _c("span", { staticClass: "tag is-green" }, [
                        _vm._v("Superior"),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "bp-row-form extra-info" }, [
                _c("div", [
                  _c(
                    "div",
                    [
                      _c(
                        "button",
                        {
                          class: [
                            "btn-single-toggle",
                            _vm.onlyFromOrder ? "" : "is-active",
                          ],
                          staticStyle: {
                            border: "1px solid #dddddd",
                            "margin-right": "5px",
                            "min-width": "22px",
                            "min-height": "22px",
                          },
                          attrs: {
                            title: _vm.onlyFromOrder
                              ? "Only from trip destinations"
                              : "All",
                          },
                          on: {
                            click: function ($event) {
                              _vm.onlyFromOrder = !_vm.onlyFromOrder
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              staticStyle: { margin: "0" },
                            },
                            [_vm._v("flag")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("location-select", {
                        ref: "location",
                        attrs: {
                          value: {
                            area: _vm.localPlaceholder.area,
                            place: _vm.localPlaceholder.place,
                          },
                          limitedDestinations: _vm.destinationsForLocation,
                        },
                        on: {
                          input: (value) => {
                            _vm.localPlaceholder.area = value.area
                            _vm.localPlaceholder.place = value.place
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("star"),
                      ]),
                      _vm._v(" "),
                      _c("type", {
                        model: {
                          value: _vm.localPlaceholder.hotelType,
                          callback: function ($$v) {
                            _vm.$set(_vm.localPlaceholder, "hotelType", $$v)
                          },
                          expression: "localPlaceholder.hotelType",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("board", {
                      model: {
                        value: _vm.localPlaceholder.board,
                        callback: function ($$v) {
                          _vm.$set(_vm.localPlaceholder, "board", $$v)
                        },
                        expression: "localPlaceholder.board",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.selectedFoodDay
                  ? _c("div", { staticClass: "is-250 has-time" }, [
                      _c(
                        "div",
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("date_range"),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "is-static-date" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dayMonth")(_vm.localPlaceholder.startAt)
                              ) + " -"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("datepicker", {
                            staticClass: "has-margin-left",
                            attrs: {
                              config: {
                                dateFormat: "d.m.Y H:i:S",
                                minDate: _vm.addDays(
                                  _vm.localPlaceholder.startAt,
                                  1
                                ),
                                defaultDate: _vm.today,
                                parseDate: function (dateStr) {
                                  return _vm
                                    .moment(dateStr, "DD.MM.YYYY hh:mm:ss")
                                    .toDate()
                                },
                                formatDate: function (dateObj) {
                                  return (
                                    _vm.moment(dateObj).format("DD.MM.YYYY") +
                                    " 00:00:00"
                                  )
                                },
                              },
                            },
                            model: {
                              value: _vm.localPlaceholder.endAt,
                              callback: function ($$v) {
                                _vm.$set(_vm.localPlaceholder, "endAt", $$v)
                              },
                              expression: "localPlaceholder.endAt",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("access_time"),
                        ]),
                        _vm._v(" "),
                        _c("multiselect", {
                          staticClass: "is-small",
                          attrs: {
                            "allow-empty": true,
                            placeholder: "Datum",
                            options: _vm.listOfDays,
                          },
                          model: {
                            value: _vm.selectedFoodDay,
                            callback: function ($$v) {
                              _vm.selectedFoodDay = $$v
                            },
                            expression: "selectedFoodDay",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.localPlaceholder.times, function (time) {
                      return [
                        _vm.timeLabel(time)
                          ? _c(
                              "div",
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("subdirectory_arrow_right"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticStyle: { width: "120px" } },
                                  [_vm._v(_vm._s(_vm.timeLabel(time)))]
                                ),
                                _vm._v(" "),
                                _c("date-time", {
                                  staticStyle: { width: "42px" },
                                  model: {
                                    value: time.time,
                                    callback: function ($$v) {
                                      _vm.$set(time, "time", $$v)
                                    },
                                    expression: "time.time",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", [
                  _c("div", [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        attrs: { title: "Set as Additional" },
                      },
                      [_vm._v("shopping_cart")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "select" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.localPlaceholder.clientOffer.status,
                              expression: "localPlaceholder.clientOffer.status",
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.localPlaceholder.clientOffer,
                                "status",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "included" } }, [
                            _vm._v("inkludiert"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "optional" } }, [
                            _vm._v("optional"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "additional" } }, [
                            _vm._v("gesondert"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("business"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "has-margin-right" }, [
                        _vm._v("Hotelbeispiel:"),
                      ]),
                      _vm._v(" "),
                      _c("async-select", {
                        attrs: {
                          placeholder: "tippen, um Hotel zu finden",
                          api: "hotels",
                          "track-by": "id",
                          label: "name",
                          params: _vm.locationParams,
                        },
                        on: { input: _vm.handleOfferProviderSelect },
                        model: {
                          value: _vm.localPlaceholder.offerProvider,
                          callback: function ($$v) {
                            _vm.$set(_vm.localPlaceholder, "offerProvider", $$v)
                          },
                          expression: "localPlaceholder.offerProvider",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button is-large is-success is-add-new",
                  staticStyle: { top: "33px" },
                  on: { click: _vm.addNewRequest },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-plus-circle",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" Leistungsträger hinzufügen"),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _vm._l(
                  _vm.localPlaceholder.requests,
                  function (request, index) {
                    return !_vm.isNewRequestVisible && request.id
                      ? _c("request", {
                          key: request.id,
                          attrs: {
                            placeholderInfo: _vm.placeholderInfo,
                            calculationRequest: _vm.calculationRequest,
                          },
                          on: {
                            closeModal: function ($event) {
                              return _vm.$emit(
                                "closeModal",
                                _vm.localPlaceholder
                              )
                            },
                            setCalculationRequest: _vm.setCalculationRequest,
                            saved: _vm.handleRequestSaved,
                          },
                          model: {
                            value: _vm.localPlaceholder.requests[index],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.localPlaceholder.requests,
                                index,
                                $$v
                              )
                            },
                            expression: "localPlaceholder.requests[index]",
                          },
                        })
                      : _vm._e()
                  }
                ),
                _vm._v(" "),
                _vm.isNewRequestVisible
                  ? _c("new-request", {
                      attrs: {
                        placeholderID: _vm.localPlaceholder.id,
                        placeholderInfo: _vm.placeholderInfo,
                      },
                      on: {
                        close: _vm.closeNewRequestModal,
                        closeModal: function ($event) {
                          return _vm.$emit("closeModal", _vm.localPlaceholder)
                        },
                        saved: _vm.handleRequestSaved,
                      },
                      model: {
                        value:
                          _vm.localPlaceholder.requests[
                            _vm.localPlaceholder.requests.length - 1
                          ],
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.localPlaceholder.requests,
                            _vm.localPlaceholder.requests.length - 1,
                            $$v
                          )
                        },
                        expression:
                          "localPlaceholder.requests[localPlaceholder.requests.length - 1]",
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }