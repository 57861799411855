<template>
    <services-table v-bind:parent="item" />
</template>

<script>
import mixin from '../tab';
import ServicesTable from './Table';

export default {
    mixins: [mixin],

    components: { ServicesTable }
}
</script>

