<template>
    <a :href="`mailto:${value}`" v-if="value">{{value}}</a>
</template>

<script>
import Cell from './cell';

export default {
    mixins: [ Cell ]
}
</script>

