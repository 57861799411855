var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c(
        "div",
        { staticClass: "tags" },
        _vm._l(_vm.value, function (tag) {
          return _c("span", { staticClass: "tag is-white" }, [
            _vm._v(_vm._s(_vm.tagValue(tag))),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }