<template>
    <tr>
        <th><label for="_importChecked" class="label">Übersetzungen:</label></th>
        <td>
            <div class="columns">

            <div class="column" style="flex-direction: row; display: block">
                <template v-for="(data, translation) in translations">
                    <a v-on:click="changeLocale(translation)">
                        <gb-flag
                            :code="getFlag(translation)"
                            :class="{ 'is-selected' : translation === value }"
                            size="small"
                            iconPath="/assets/img/flags/"
                        />
                    </a>
                </template>
                <i class="material-icons" v-on:click.prevent.stop="visible = !visible" v-if="!visible">add_circle</i>
            </div>
                <div class="column is-two-thirds" v-if="visible">
                    <multiselect
                        v-bind:options="filteredTranslationOptions"
                        v-bind:="newLocale"
                        placeholder="Neue Übersetzung wählen"
                        v-on:select="handleSelect"
                        label="name"
                        track-by="id"
                        :allow-empty="true"
                    />
                </div>

            </div>
        </td>
    </tr>
</template>

<script>

import {getFlag} from "@utilities/functions";
import vClickOutside from 'v-click-outside'
import { Multiselect, AsyncSelect } from '@components/form';



export default {
    props: ['value', 'translations', 'translationOptions'],

    data() {
        return {
            visible: false,
            newLocale: null
        }
    },

    computed: {
        locale: {
            get: function () { return this.value; },
            set: function (value) { this.$emit('input', value) }
        },
        filteredTranslationOptions: function () {
            const options = this.translationOptions.filter(item => !this.translations[item.code]);
            options.push({id: 0, name: 'Abbrechen'})
            return options;
        }
    },

    methods: {
        getFlag,
        changeLocale: function(newValue) {
            this.$emit('changeTranslation', [newValue, this.translations[newValue]])
        },
        handleSelect: function(value){
            if(value.id === 0){
                this.visible = false;
            } else {
                this.$emit('changeTranslation', [value.code, []])
                this.visible = false;
            }
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    components: {
        Multiselect,
        AsyncSelect
    },
}
</script>
