<template>
    <modal-form
        editTitle="Hotelpreis bearbeiten"
        createTitle="Neuen Hotelpreis hinzufügen"
    >
        <table>
            <template v-if="initialValue">
                <tr>
                    <th><label class="label">Preis:</label></th>
                    <td>
                        <toggle-switch v-model="priceType">
                            <option value="calculation">Kalkulation</option>
                            <option value="provider">Anbieter</option>
                            <option value="agency">Agentur</option>
                        </toggle-switch>
                    </td>
                </tr>
                <tr v-if="priceType === 'agency'">
                    <th><label class="label">Agentur:</label></th>
                    <td>
                        <async-select
                            api="agencies"
                            v-model="agency"
                        />
                    </td>
                </tr>
                <tr v-if="priceType === 'provider' || priceType === 'agency'">
                    <th><label class="label">Hotel:</label></th>
                    <td>
                        <async-select
                            api="hotels"
                            v-model="provider"
                        />
                    </td>
                </tr>
            </template>
            <tr>
                <th><label class="label">Bezeichnung:</label></th>
                <td>
                    <toggle-switch v-model="ownName">
                        <option :value="true">Individuell</option>
                        <option :value="false">Automatisch (nach Datum)</option>
                    </toggle-switch>
                </td>
            </tr>
            <tr v-if="ownName">
                <th><label class="label">Eigene Bezeichnung:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="name" class="input" v-model="form.name">
                    </div>
                </td>
            </tr>
            <tr>
                <th class="is-top">
                    <label class="label">
                        Zeitraum:
                        <i class="material-icons is-pointer" v-on:click="addPeriod" v-if="canAddPeriod">add_circle</i>
                    </label>
                </th>

                <td>
                    <!-- Time periods -->
                    <div class="level-left" v-for="period in timePeriods" v-bind:key="period.key">
                        <div class="flatpickr-combined level-item">
                            <input-date v-model="period.startAt" />
                            <span>-</span>
                            <input-date v-model="period.endAt" />
                        </div>
                        <div class="level-item">
                            <days v-model="period.days" picker/>
                        </div>
                        <i
                            v-if="timePeriods.length > 1"
                            class="material-icons is-pointer"
                            v-on:click="deletePeriod(period)"
                        >delete</i>
                    </div>
                </td>
            </tr>

            <tr>
                <th><label for="board" class="label">Verpflegung:</label></th>
                <td>
                    <multiselect
                        v-bind:options="boardsExtended"
                        v-model="board"
                        label="value"
                        track-by="key"
                    />
                </td>
            </tr>

            <tr v-if="providerType === 'provider'">
                <th><label class="label">Art:</label></th>
                <td>
                    <toggle-switch v-model="type">
                        <option value="direct">Direktpreis</option>
                        <option value="agency">Preis von Agentur</option>
                    </toggle-switch>
                </td>
            </tr>

            <tr v-if="providerType === 'agency'">
                <th><label class="label">Art:</label></th>
                <td>
                    <toggle-switch v-model="type">
                        <option value="direct">No-Name Preis</option>
                        <option value="hotel">Preis für ein bestimmtes Hotel</option>
                    </toggle-switch>
                </td>
            </tr>

            <tr v-if="type === 'agency'">
                <th><label class="label">Agentur:</label></th>
                <td>
                    <async-select
                        api="agencies"
                        v-model="agency"
                    />
                </td>
            </tr>

            <tr v-if="type === 'hotel'">
                <th><label class="label">Hotel:</label></th>
                <td>
                    <async-select
                        api="hotels"
                        v-model="provider"
                    />
                </td>
            </tr>

            <tr v-if="(type === 'direct' && providerType === 'agency') || providerType === 'calculation'">
                <th><label class="label">Hotelkategorie:</label></th>
                <td>
                    <multiselect
                        v-if="options.hotelTypes"
                        v-model="hotelType"
                        track-by="name"
                        label="name"
                        v-bind:options="options.hotelTypes"
                    />
                </td>
            </tr>

            <tr v-if="(type === 'direct' && providerType === 'agency') || providerType === 'calculation'">
                <th><label class="label">Region:</label></th>
                <td>
                    <area-select v-model="form.area" />
                </td>
            </tr>

            <!-- Preise -->
            <tr>
                <td colspan="2">
                    <h2>Preise <i class="material-icons" v-on:click="addPrice">add_circle</i></h2>
                </td>
            </tr>

            <tr>
                <th><label class="label">Überprüft:</label></th>
                <td>
                    <import-checked-field v-model="importChecked" />
                </td>
            </tr>

            <tr>
                <th><label class="label">Währung:</label></th>
                <td>
                    <multiselect
                        v-model="currency"
                        style="width: 70px"
                        :options="currencies"
                        :allow-empty="false"
                        placeholder=""
                    />
                </td>
            </tr>

            <tr>
                <th><label class="label">{{priceRoomType(standardRoomTypeDouble).name }}:</label></th>
                <td><input-price v-model="doubleStandardPrice" class="is-medium" placeholder="0,00" :prefix="currency"/></td>
            </tr>
            <tr>
                <th><label class="label">{{priceRoomType(standardRoomTypeSingle).name }}:</label></th>
                <td><input-price v-model="singleStandardPrice" class="is-medium" placeholder="0,00" :prefix="currency"/></td>
            </tr>

            <tr v-for="otherPrice in otherPrices" v-bind:key="otherPrice.key">
                <th>
                    <multiselect
                        v-bind:options="priceRoomTypes(otherPrice)"
                        :value="priceRoomType(otherPrice)"
                        v-on:input="roomType => changeRoomType({roomType, price: otherPrice})"
                        track-by="name"
                        label="name"
                        :allow-empty="false"
                    />
                </th>
                <td>
                    <div class="level-left">
                        <div class="level-item">
                            <input-price :value="otherPrice.price.amount" v-on:input="price => updatePrice({roomType: otherPrice.roomType, price})" class="is-medium" placeholder="0,00" :prefix="currency"/>
                        </div>
                        <i class="material-icons" v-on:click="deletePrice(otherPrice)">delete</i>
                    </div>
                </td>
            </tr>

            <tr v-if="addNewPrice">
                <th>
                    <multiselect
                        v-bind:options="roomTypes"
                        v-model="roomType"
                        track-by="name"
                        label="name"
                    />
                </th>
                <td>
                    <div class="level-left">
                        <div class="level-item">
                            <input-price v-model="newPrice.price.amount" class="is-medium" placeholder="0,00" :prefix="currency"/>
                        </div>
                        <i class="material-icons" v-on:click="addNewPrice = false">delete</i>
                    </div>
                </td>
            </tr>
            <tr v-if="existingPrices.length > 0">
                <td class="alert is-danger"></td>
                <td>
                    <div class="alert is-danger" >
                        <p>
                            <strong>Preise überschreiben?</strong>
                        </p>
                        <p>
                            Im angegebenen Preise existieren bereits folgende Preise, die beim erneuten Klick auf Speichern überschrieben werden:
                        </p>
                        <ul>
                            <li v-for="price in existingPrices">{{ price.timePeriods.map(item => item.startAt + ' - ' + item.endAt).join(' | ') }} - EK im DZ: {{ price.providerPrices.filter(item => item.roomType.roomIndex === '2_standard').map(item => item.price.formatted).join(' | ') }}</li>
                        </ul>
                    </div>
                </td>
            </tr>
        </table>
    </modal-form>
</template>

<script>
import has from 'lodash/has';
import Days from '@components/Days';
import { InputDate, InputPrice, ToggleSwitch, AreaSelect, AsyncSelect } from '@components/form';
import {dateISOtoView, numbersToDays} from '@utilities/functions';
import TabForm from '../../form'
import { importCheckedMixin, importCheckedField } from '@utilities/mixins';
import {getHotelPlaceholder, providerPrices, getHotelPriceGroups} from '@api'
import {notifySuccess} from "@components/Notification";
import moment from "moment";



export default {
    mixins: [ TabForm, importCheckedMixin ],

    props: {
        providerType: {
            type: String,
            required: true
        }
    },

    components: {
        Days,
        InputDate,
        InputPrice,
        ToggleSwitch,
        importCheckedField,
        AreaSelect,
        AsyncSelect,
    },

    form: {
        board: '',
        name: '',
        area: null,
        hotelType: {
            stars: '0',
            category: ''
        },
        provider: null,
        updatedAt: dateISOtoView((new Date()).toISOString()),

        providerPrices: [],
        timePeriods: [],
        agency: null,
    },

    data: function () {
        return {
            existingPrices: [],
            ownName: true,
            addNewPrice: false,
            type: 'direct',
            currency: this.$store.state.data.mainCurrency ? this.$store.state.data.mainCurrency : 'EUR',
            newPrice: {
                price: {
                    amount: "0",
                },
                roomType: null
            },
            standardRoomTypeDouble: {
                roomType: {
                    persons: 2,
                    type: 'standard'
                }
            },
            standardRoomTypeSingle: {
                roomType: {
                    persons: 1,
                    type: 'standard'
                }
            },
            priceType: 'calculation',
        }
    },

    computed: {
        currencies: function () {
            return this.options.potentialCurrencies ? this.options.potentialCurrencies : [];
        },

        agency: {
            get: function () { return this.form.agency && !!this.form.agency.id ? this.form.agency : null },
            set: function (agency) { this.form.agency = agency }
        },

        provider: {
            get: function () { return this.form.provider && !!this.form.provider.id ? this.form.provider : null },
            set: function (provider) { this.form.provider = provider }
        },

        hotelTypes: function () {
            return (this.options && this.options.hotelTypes)
                ? this.options.hotelTypes
                : []
        },

        hotelType: {
            get: function () {

                if(!this.form.hotelType || !this.hotelTypes){
                    return {stars: 0, category: ''}
                }

                let hotelType = this.hotelTypes
                    .find(({stars, category}) => stars === this.form.hotelType.stars && category === this.form.hotelType.category);

                return hotelType ? hotelType : {stars: 0, category: ''}
            },

            set: function (hotelType) {
                this.form.hotelType = !!hotelType ? { stars: hotelType.stars, category: hotelType.category } : {stars: 0, category: ''}
            }
        },

        /*days: {
            get: function () { return daysToNumbers(this.form) },
            set: function (numbers) { this.form = {...this.form, ...numbersToDays(numbers)} }
        },*/

        timePeriods: function () {
            this.afterDataLoad();
            this.setCurrency();
            return this.form.timePeriods ? this.form.timePeriods : []
        },

        dataCheckedAtCheck: function() {
            if(this.board){
                this.afterDataLoad();
                return this.form.dataCheckedAt ? moment(this.form.dataCheckedAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') : null;
            }
            return null
        },

        canAddPeriod: function () {
            return this.timePeriods
                .filter(({startAt, endAt}) => !startAt || !endAt)
                .length === 0
        },

        boardsExtended: function () {
            return this.options && this.options.boardExtended
                ? this.options.boardExtended
                : []
        },

        boards: function () {
            return this.options && this.options.board
                ? this.options.board
                : []
        },

        board: {
            get: function () { return this.boardsExtended.find(board => board.key === this.form.board)},
            set: function (board) { this.form.board = !!board ? board.key : null}
        },

        allRoomTypes: function () {
             return (this.options && this.options.roomTypePrice)
                ? this.options.roomTypePrice
                : []
        },

        roomTypes: function () {
            const usedRoomTypes = this.providerPrices.map(({roomType}) => roomType)

            return this.allRoomTypes
                .filter(({type, persons}) => !(type === 'standard' && [1,2].includes(persons)))
                .filter(({type, persons}) => !usedRoomTypes.some(roomType => roomType.type === type && roomType.persons === persons ))
        },

        roomType: {
            get: function () {
                if (!!this.newPrice.roomType) {
                    const { type, persons } = this.newPrice.roomType;
                    return this.allRoomTypes
                        .find(roomType => roomType.type === type && roomType.persons === persons)
                }

                return  null;
            },

            set: function (roomType) {
                if (!!roomType) {
                    const {type, persons} = roomType
                    this.newPrice.roomType = { persons, type };
                } else {
                    this.newPrice.roomType = { persons: null, type: null};
                }

            }
        },

        // Prices
        providerPrices: function () { return has(this.form, 'providerPrices') ? this.form.providerPrices : [] },
        otherPrices: function () { return this.providerPrices.filter(({roomType}) => !(roomType.type === 'standard' && [1,2].includes(roomType.persons))) },

        doubleStandard: function () {return findRoomTypePrice(this.providerPrices, {type: 'standard', persons: 2}) },
        doubleStandardPrice: {
            get: function () { return this.doubleStandard ? this.doubleStandard.price.amount : null },
            set: function (price) { this.updatePrice({roomType: {type: 'standard', persons: 2}, price}) }
        },

        singleStandard: function () { return findRoomTypePrice(this.providerPrices, {type: 'standard', persons: 1}) },
        singleStandardPrice: {
            get: function () { return this.singleStandard ? this.singleStandard.price.amount : null },
            set: function (price) { this.updatePrice({roomType: {type: 'standard', persons: 1}, price}) }
        },
    },

    methods: {
        openCallback: function(form) {
            if(form && form.id && !form.name){
                this.ownName = false;
            }
        },

        handleInitialValue: function(initialValue) {

            this.open();

            if(this.initialValue.sellingPrice){
                this.form.name = this.initialValue.sellingPrice.subTitle;
                this.ownName = !!this.form.name;

                this.form.timePeriods = this.initialValue.sellingPrice.seasonTimes.map(season => {
                    return {
                        days: [0,1,2,3,4,5,6],
                        key: season.id,
                        startAt: season.startAt,
                        endAt: season.endAt
                    }
                })
            } else {
                this.form.timePeriods = [{
                    days: [0,1,2,3,4,5,6],
                    startAt: null,
                    endAt: null,
                    key: 1
                }]
            }

            if(this.initialValue.price){
                getHotelPlaceholder(initialValue.price.placeholder.id, '?_groups[]=modal_read&_groups[]=order_placeholder_displayed_request_read&_groups[]=place_read').then(response => {
                    const {hotelType, board, area, place, displayedRequest} = response.data;
                    this.hotelType = hotelType;
                    this.form.board = board;
                    this.form.area = area ? area : place.area;
                    this.agency = displayedRequest && displayedRequest.agency ? displayedRequest.agency : null;
                    this.provider = displayedRequest && displayedRequest.hotel ? displayedRequest.hotel : null;
                    this.doubleStandardPrice = initialValue.price.doubleRoomBuyPrice.amount;
                    this.singleStandardPrice = initialValue.price.singleRoomPremiumBuyPrice.amount;

                    this.currency = initialValue.price.doubleRoomBuyPrice.currency;

                    if(this.agency){
                        this.priceType = 'agency';
                    } else if(this.provider){
                        this.priceType = 'provider';
                    }
                })
            }
        },

        setCurrency: function() {
              if(this.doubleStandardPrice && this.doubleStandard.price.currency){
                  this.currency = this.doubleStandard.price.currency;
              }
        },

        addPeriod: function () {
            this.form.timePeriods.push({
                startAt: null,
                endAt: null,
                days: [0,1,2,3,4,5,6],
                key: + new Date
            })
        },

        deletePeriod: function (period) {
            this.form.timePeriods = this.form.timePeriods
                .filter(({key}) => period.key !== key)
        },

        addPrice: function () {
            this.addNewPrice = true;
            this.newPrice = {
                price: {
                    amount: "0"
                },
                roomType: null,
            }
        },

        deletePrice: function (price) {
            this.form.providerPrices = this.form.providerPrices
                .filter(({key}) => price.key !== key)
        },

        updatePrice: function ({price, roomType}) {
            const priceIndex = this.form.providerPrices
                .findIndex(price => roomType.type === price.roomType.type && roomType.persons === price.roomType.persons )
            if (priceIndex === -1) {
                this.form.providerPrices.push({roomType, price: {amount: price}, key: + new Date})
            } else {
                this.form.providerPrices.splice(priceIndex, 1, {
                    ...this.form.providerPrices[priceIndex],
                    price: {amount: price},
                })
            }
        },

        priceRoomTypes: function (price) {
            const usedRoomTypes = this.providerPrices
                .filter(({key}) => price.key !== key)
                .map(({roomType}) => roomType)

            return this.allRoomTypes
                .filter(({type, persons}) => !(type === 'standard' && [1,2].includes(persons)))
                .filter(({type, persons}) => !usedRoomTypes.some(roomType => roomType.type === type && roomType.persons === persons ))
        },

        priceRoomType: function ({roomType}) {
            const { type, persons } = roomType;
            return this.allRoomTypes
                .find(roomType => roomType.type === type && roomType.persons === persons)
        },

        changeRoomType: function ({roomType, price}) {
            const priceIndex = this.form.providerPrices
                .findIndex(({key}) => price.key === key )

            this.form.providerPrices.splice(priceIndex, 1, {
                ...this.form.providerPrices[priceIndex],
                roomType: {
                    type: roomType.type,
                    persons: roomType.persons
                }
            })
        },

        getIDFromString: function(apiString) {
            if(typeof apiString === 'object'){
                return apiString.id;
            }
            let stringArray = apiString.split('/');
            return stringArray[stringArray.length - 1];
        },

        checkOverlappingPrices: function (data) {
            let params = {};
            if(this.existingPrices.length > 0 || this.providerType === 'provider' || this.providerType === 'agency'){
                this.addPriceGroup(data);
            } else {
                let periods = [];
                this.form.timePeriods.forEach(period => {
                    periods.push(period, {...period, startAt: null})
                });
                Promise.all(periods.map(period => {

                    data = this.prepareForBackend(data);
                    params = {
                        board: data.board,
                        ...data.provider && {'provider.id': this.getIDFromString(data.provider)},
                        ...data.agency && {'agency.id': this.getIDFromString(data.agency)},
                        ...!data.provider && {
                            'provider[exists]': false,
                            'area.id': this.getIDFromString(data.area),
                            'hotelType.stars': data.hotelType.stars,
                        },
                        '_groups': ['provider_price_group:provider_price', 'provider_price', 'provider_price_group:price_time_period', 'price_time_period', 'room_type'],
                        ...!data.agency && {'agency[exists]': false},
                        ...!data.provider && !data.agency && {'agency[exists]': false},
                        'timePeriods.startAt[before]': period.endAt,
                        'timePeriods.endAt[after]': period.startAt,
                        'timePeriods.monday': period.monday.toString(),
                        'timePeriods.tuesday': period.tuesday.toString(),
                        'timePeriods.wednesday': period.wednesday.toString(),
                        'timePeriods.thursday': period.thursday.toString(),
                        'timePeriods.friday': period.friday.toString(),
                        'timePeriods.saturday ': period.saturday.toString() ,
                        'timePeriods.sunday': period.sunday.toString(),
                        ...!period.startAt && {'timePeriods.endAt[exists]': 'false'},
                    };
                    return getHotelPriceGroups(params)
                })).then(responses => {
                    this.existingPrices = [].concat(...(responses.map(response => response.data)));
                    if(this.existingPrices.length === 0){
                        this.addPriceGroup(data);
                    }
                })
            }
        },


        deletePriceGroups: function (groups) {
            groups.map(group => providerPrices.hotelPriceGroups.delete(group.id).then(response => {}, error => {
                this.notifyError("Die bestehenden Preise konnten nicht überschrieben werden. Doppelte Preise bitte manuell ändern!")
            }))
        },

        addPriceGroup: function (data) {

                providerPrices.hotelPriceGroups.post({...this.prepareForBackend(data)})
                    .then(response => {
                        notifySuccess('Die Preise wurden erfolgreich hinzugefügt!')
                        this.deletePriceGroups(this.existingPrices);
                        this.$emit('submit')
                        this.isLoading = false;
                        this.visible = false;

                    }, error => {
                        this.notifyError("Die Preise konnte nicht hinzugefügt werden!")
                        this.isLoading = false;
                    })

        },

        prepareForBackend: function(data) {
            data.timePeriods = data.timePeriods.map(period => { return {...period, ...numbersToDays(period.days)}})
            data.dataCheckedAt = this.dataCheckedAtCheck;
            data.name = this.ownName ? data.name : '';

            if(data.area) {
                if(data.area.id){
                    data.area = `/api/areas/${data.area.id}`
                } else if(data.area.name){
                    data.area = null;
                }
            }

            if(this.providerType === 'agency'){
                data.agency = `/api/agencies/${this.$store.state.id}`;
            } else if(data.agency){
                data.agency = data.agency.id ? `/api/agencies/${data.agency.id}` : data.agency;
            }
            if(this.providerType === 'provider'){
                data.provider = `/api/hotels/${this.$store.state.id}`;
            } else if(data.provider){
                data.provider = data.provider.id ? `/api/hotels/${data.provider.id}` : data.provider;
            }

            data.providerPrices = data.providerPrices.map(price => {
                return Object.assign({}, price, {
                    price: {
                        amount: price.price.amount,
                        currency: this.currency
                    }
                })
            });
            return data;
        },

        handleSubmit: function () {

            this.isLoading = true;

            if(this.form.id){
                providerPrices.hotelPriceGroups.put({...this.prepareForBackend(this.form)})
                    .then(response => {
                        notifySuccess('Die Preise wurden erfolgreich aktualisiert!')
                        this.isLoading = false;
                        this.$emit('submit')
                        this.visible = false;

                    }, error => {
                        this.notifyError("Die Preise konnte nicht aktualisiert werden!")
                        this.isLoading = false;
                    })
            } else {
                if(this.initialValue){
                    if(this.priceType === 'calculation'){
                        this.form.provider = null;
                        this.form.agency = null;
                    }
                    if(this.priceType === 'provider'){
                        this.form.agency = null;
                    }
                }

                this.checkOverlappingPrices(this.form);

            }
        },

    },

    watch: {
        newPrice: {
            deep: true,
            handler: function ({price, roomType}) {
                if (!!roomType && price) {
                    this.updatePrice({price, roomType})
                    this.addNewPrice = false;
                }
            }
        }
    }
}

const findRoomTypePrice = function (prices, {type, persons}) {
    return prices.find(price => {
        if (!!price && has(price, 'roomType')) {
            const { roomType } = price;
            return roomType.persons === persons && roomType.type === type
        } else {
            return false
        }
    })
}
</script>
