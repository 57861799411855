var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cell-content is-full is-flex" },
    [
      !_vm.hasProviders
        ? [_vm._v("-")]
        : _c(
            "a",
            {
              staticClass: "is-link is-truncated mr",
              attrs: {
                title: _vm.firstProvider.name,
                href: _vm.providerLink(_vm.firstProvider),
              },
            },
            [_vm._v(_vm._s(_vm.firstProvider.name))]
          ),
      _vm._v(" "),
      _vm.otherProviders.length > 0
        ? _c(
            "popover",
            { attrs: { trigger: "hover" } },
            [
              _vm.otherProviders.length < 10
                ? _c("div", { staticClass: "rounded-btn" }, [
                    _vm._v("+" + _vm._s(_vm.otherProviders.length)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.otherProviders.length > 9
                ? _c("div", { staticClass: "rounded-btn" }, [_vm._v("> 9")])
                : _vm._e(),
              _vm._v(" "),
              _vm.otherProviders.length > 0
                ? _c(
                    "template",
                    { slot: "popover" },
                    _vm._l(_vm.otherProviders, function (provider) {
                      return _c("div", { key: provider.id }, [
                        _c(
                          "a",
                          {
                            staticClass: "is-link",
                            attrs: { href: _vm.providerLink(provider) },
                          },
                          [_vm._v(_vm._s(provider.name))]
                        ),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }