var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c("loading")
    : _vm.isEmpty
    ? _c("div", { staticClass: "is-empty-cost" }, [
        _vm._v("\n    Berechnung ohne Preise nicht möglich\n"),
      ])
    : _c("div", [
        _c(
          "div",
          { staticClass: "redesign-table is-costs" },
          [
            _c("div", { staticClass: "row is-head" }, [
              _c("div", { staticClass: "cell" }, [_vm._v("Leistung")]),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }, [_vm._v("Anzahl")]),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }, [_vm._v("Einzelpreis")]),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }, [_vm._v("Gesamtpreis")]),
            ]),
            _vm._v(" "),
            _vm.packageID
              ? _c("package", {
                  attrs: {
                    calculations: _vm.calculations,
                    roomTypes: _vm.roomTypes,
                    cabinTypes: _vm.cabinTypes,
                    trainCabinTypes: _vm.trainCabinTypes,
                    isLoading: _vm.isLoading,
                  },
                })
              : _c(
                  "div",
                  { staticClass: "is-zebra" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _vm.calculations.contingent.priceType === "per_person"
                        ? _c("div", { staticClass: "cell" }, [
                            _vm._v(
                              _vm._s(_vm.calculations.info.title) +
                                " - Preis pro Person"
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.calculations.contingent.priceType === "per_group"
                        ? _c("div", { staticClass: "cell" }, [
                            _vm._v(
                              _vm._s(_vm.calculations.info.title) +
                                " - Preis pro Gruppe"
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "cell" }, [
                        _vm._v(_vm._s(_vm.calculations.durationInDays)),
                      ]),
                      _vm._v(" "),
                      _vm.calculations.contingent.priceType === "per_person"
                        ? _c("div", { staticClass: "cell" }, [
                            _vm._v(_vm._s(_vm.calculations.personsReserved)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.calculations.contingent.priceType === "per_group"
                        ? _c("div", { staticClass: "cell" }, [_vm._v("1")])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "cell" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("priceView")(
                              _vm.calculations.info.standardPrice.amount
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "cell" }, [
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm._f("priceView")(
                                _vm.calculations.totalPriceReserved.amount
                              )
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.calculations.contingent.extraReservedAmount
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "cell" }, [
                            _vm._v(
                              "Zusätzlicher Teilnehmer - " +
                                _vm._s(
                                  _vm.calculations.contingent.extraDescription
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell" }, [
                            _vm._v(
                              _vm._s(
                                _vm.calculations.contingent.extraReservedAmount
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("priceView")(
                                  _vm.calculations.contingent.price.amount
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell" }, [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("priceView")(
                                    _vm.calculations.totalPriceExtraReserved
                                      .amount
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.calculations.personsFreePlaces
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "cell" }, [
                            _vm._v("Freiplatz"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell" }, [
                            _vm._v(_vm._s(_vm.calculations.personsFreePlaces)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("priceView")(
                                  -1 * _vm.calculations.contingent.price.amount
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell" }, [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("priceView")(
                                    -1 *
                                      _vm.calculations.totalPriceFreePlaces
                                        .amount
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.calculations.itemPrices, function (item) {
                      return item.billingType !== "info" && item.limitIsValid
                        ? _c(
                            "div",
                            { key: "item-" + item.id, staticClass: "row" },
                            [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("Zuschlag - " + _vm._s(item.name)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _vm._v(
                                  _vm._s(
                                    item.quantityType === "per_person_night" ||
                                      item.quantityType ===
                                        "per_single_room_night"
                                      ? _vm.calculations.durationInDays
                                      : ""
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _vm._v(_vm._s(item.calculatedQuantity)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _vm._v(
                                  _vm._s(_vm._f("priceView")(item.price.amount))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell" }, [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("priceView")(
                                        item.totalPrice.amount
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e()
                    }),
                  ],
                  2
                ),
            _vm._v(" "),
            _vm.isLoading ? _c("loading") : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "for-costs-total" }, [
          _c("div", [
            _vm._v(
              "TOTAL (" + _vm._s(_vm.calculations.totalPrice.currency) + ")"
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("b", [
              _vm._v(
                _vm._s(_vm._f("priceView")(_vm.calculations.totalPrice.amount))
              ),
            ]),
          ]),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }