var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5" },
    [
      _vm.row.complexity
        ? [
            _c(
              "div",
              {
                staticClass: "circles",
                class: _vm.getColorClass(_vm.row.complexity.offerComplexity),
                attrs: {
                  "data-p": 100,
                  title:
                    "Angebot-Komplexität: " +
                    _vm.row.complexity.offerComplexity,
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.row.complexity.offerComplexity))])]
            ),
          ]
        : [_vm._m(0)],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "circles",
        attrs: { "data-p": "0", title: "Angebot-Komplexität: ?" },
      },
      [_c("span", [_vm._v("?")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }