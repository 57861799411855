var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-addition" }, [
    _c("div", { staticClass: "tab-addition__header" }, [
      _c("header", [
        _c(
          "button",
          {
            class: { "is-active": _vm.category === "Stornokosten" },
            on: {
              click: function ($event) {
                return _vm.changeCategory("Stornokosten")
              },
            },
          },
          [_vm._v("Stornokosten")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            class: { "is-active": _vm.category === "Zahlungen" },
            on: {
              click: function ($event) {
                return _vm.changeCategory("Zahlungen")
              },
            },
          },
          [_vm._v("Zahlungen")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            class: { "is-active": _vm.category === "Namensänderungen" },
            on: {
              click: function ($event) {
                return _vm.changeCategory("Namensänderungen")
              },
            },
          },
          [_vm._v("Namensänderungen")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "main is-gapless" }, [
      _c(
        "table",
        { staticClass: "conditions-table" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.conditions, function (condition) {
            return _c("condition", {
              key: condition.id,
              attrs: { condition: condition },
              on: {
                save: _vm.editCondition,
                delete: function ($event) {
                  return _vm.deleteCondition(condition)
                },
              },
            })
          }),
          _vm._v(" "),
          !_vm.addMode
            ? _c("tr", [
                _c("td", { attrs: { colspan: "4" } }, [
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.addMode = true
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("add"),
                      ]),
                    ]
                  ),
                ]),
              ])
            : _c("condition", {
                on: {
                  delete: function ($event) {
                    _vm.addMode = false
                  },
                  save: _vm.addCondition,
                },
              }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticClass: "is-medium" }, [_vm._v("Frist")]),
      _vm._v(" "),
      _c("th", { staticClass: "is-small b-r" }),
      _vm._v(" "),
      _c("th", { attrs: { colspan: "2" } }, [_vm._v("Freitext")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }