export default {

    props: {
        showNextRow: {
            type: Boolean,
            default: false
        }
    },

    data: function() {
        return {
            isOpen: this.showNextRow,

        }
    },

    methods : {
        toggleCollapse: function () {
            this.isOpen = !this.isOpen;
        }
    },
};
