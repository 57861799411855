var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row darken" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "cell is-multiple-date-cell" }),
    _vm._v(" "),
    _c("div", { staticClass: "cell is-small-cell" }),
    _vm._v(" "),
    _c("div", { staticClass: "cell is-name-cell" }, [
      _c("span", [
        _vm._v(
          "umgerechnet auf eine Mindestteilnehmerzahl von " +
            _vm._s(_vm.doubleRooms)
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cell is-provider-cell" }),
    _vm._v(" "),
    _c("div", { staticClass: "cell is-price-cell is-small" }, [
      _c("span", { staticClass: "is-currency" }, [
        _vm._v(_vm._s(_vm.showCurrency)),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cell is-price-cell" },
      [
        _c("input-price", {
          attrs: {
            readOnly: "",
            value: _vm.summaryInPreferenceCurrency.group,
            valueInOrderCurrency: _vm.summary.group,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cell is-price-cell" },
      [
        _c("input-price", {
          attrs: {
            readOnly: "",
            value: _vm.summaryInPreferenceCurrency.person,
            valueInOrderCurrency: _vm.summary.person,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cell is-price-cell is-margin-cell" },
      [
        _c("input-price", {
          attrs: {
            readOnly: "",
            value: _vm.summaryInPreferenceCurrency.single,
            valueInOrderCurrency: _vm.summary.single,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "cell is-actions is-small-cell" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cell is-icon-cell" }, [
      _c("strong", [_vm._v("SUMME")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }