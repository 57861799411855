<template>
    <accordion v-bind:isOpen="modalToRequest.type === 'hotel' && modalToRequest.id === request.id" :is-collapsible="false" :class-names="request.isSelectedRequest ? 'header-is-highlighted' : ''">
        <div slot="header">
            <request-score v-if="request.hotel" v-bind:rating="request.hotel.buspaketRating" />

            <div v-else>
                <div class="no-name">No Name</div>
            </div>

            <div>

                <div class="level-left">
                    <span class="level-item">
                        <a v-if="request.hotel" v-bind:href="'/hotels/' + request.hotel.id" target="_blank" title="Gehe zum Anbieter">{{ request.hotel.name }}</a>
                        <template v-else>{{request.info.title }}</template>
                    </span>
                    <div class="level-item">
                        <i
                            class="material-icons is-pointer"
                            :class="{'has-text-active': editMode}"
                            v-on:click.prevent.stop="editMode = !editMode"
                        >edit</i>
                    </div>

                    <div v-if="request.hotel" v-bind:class="['stars', 'has-' + request.hotel.hotelType.stars]">
                        <i class="material-icons">star</i>
                        <i class="material-icons">star</i>
                        <i class="material-icons">star</i>
                        <i class="material-icons">star</i>
                        <i class="material-icons">star</i>
                    </div>
                    <span class="tag is-green" v-if="request.hotel && request.hotel.hotelType.category === 'superior'">Superior</span>
                </div>

                <provider-info v-bind:provider="request.hotel" class="header-extra-info">
                    <div><i class="fa fa-book"></i>
                        <a v-if="request.agency" v-bind:href="'/agencies/' + request.agency.id" target="_blank" title="Zur Agentur gehen">{{ request.agency.name }}</a>
                        <template v-else>direkt</template>
                    </div>

                    <div>
                        <i class="material-icons">check_circle</i>
                        {{
                            request.contingent.info.doubleRooms.totalReserved +
                            request.contingent.info.singleRooms.totalReserved +
                            request.contingent.info.otherRooms.totalReserved
                        }}
                        Zimmer
                    </div>

                    <div>
                        <i class="material-icons">help</i>
                        {{
                            request.contingent.info.doubleRooms.totalAsked +
                            request.contingent.info.singleRooms.totalAsked +
                            request.contingent.info.otherRooms.totalAsked
                        }}
                        Zimmer
                    </div>

                    <div><i class="material-icons">import_contacts</i> {{ getValueWithKey({ key: request.requestStatus.status, optionList: requestStatuses }) }}</div>

                    <div v-if="request.optionTodo && request.optionTodo.dueDate">
                        <div v-bind:class="['deadline-date', deadlineUrgency(request.optionTodo.dueDate)]"><i class="material-icons">notifications_none</i> {{ request.optionTodo.dueDate | fullYear }}</div>
                    </div>
                </provider-info>
            </div>

            <div>
                <request-settings v-on:reloadPrices="reloadPrices" :request="request" :calculation-request="calculationRequest" v-on:setCalculationRequest="setCalculationRequest"/>
            </div>
        </div>

        <div slot="content">
            <div class="buttons has-addons">
                <button
                class="button is-large"
                v-bind:class="[sectionSelection === 'overview' ? 'is-primary' : 'is-gray']"
                v-on:click="changeTab('overview')"
                ><i class="material-icons">help</i> Anfragedetails</button>

                <button
                class="button is-large"
                v-bind:class="[sectionSelection === 'price' ? 'is-primary' : 'is-gray']"
                v-on:click="changeTab('price')"
                ><i class="material-icons">euro_symbol</i> Preise</button>

                <button
                class="button is-large"
                v-bind:class="[sectionSelection === 'cost' ? 'is-primary' : 'is-gray']"
                v-on:click="changeTab('cost')"
                ><i class="material-icons">view_list</i> Gesamtkosten</button>

                <button
                class="button is-large"
                v-bind:class="[sectionSelection === 'email' ? 'is-primary' : 'is-gray']"
                v-on:click="changeTab('email')"
                ><i class="material-icons">email</i> Emails/Telefonate</button>
            </div>


            <loading v-if="isLoading" textInFront="Die Änderungen werden gespeichert..."></loading>

            <template v-else>
                <request
                    v-if="sectionSelection === 'overview'"
                    v-model="request"
                    v-bind:placeholderInfo="placeholderInfo"
                    v-bind:editMode="editMode"
                    :calculation-request="calculationRequest"
                ></request>

                <price
                    v-if="sectionSelection === 'price'"
                    v-model="request"

                    v-bind:calculationRequest="calculationRequest"
                    v-on:setCalculationRequest="setCalculationRequest"
                ></price>

                <cost
                    v-if="sectionSelection === 'cost'"
                    v-bind:requestID="request.id"
                    v-bind:packageID="packageID"
                ></cost>

                <emails
                    v-if="sectionSelection === 'email'"
                    v-bind:initialContacts = "request.agency ? request.agency.contacts : request.hotel.contacts"
                    v-bind:ordersSelected  = "[order]"
                    v-bind:requestID       = "request.id"
                    v-bind:requestType     = "request.requestType"
                    v-on:close             = "$emit('closeModal')"
                ></emails>
            </template>

            <!-- <button
                v-if="sectionSelection !== 'cost' && sectionSelection !== 'email'"
                class="button is-primary is-large"
                v-bind:class="{ 'is-loading': isLoading }"
                v-on:click="saveRequestChanges(sectionSelection)"
            ><i class="material-icons">check</i> Änderungen speichern</button> -->
        </div>
    </accordion>
</template>



<script>
import mixins     from './mixins';
import Cost       from '../costs/Hotel';
import Price      from '../prices/hotels';
import Request    from '../overviews/Hotel';
import Template from "@orders/subpages/TripDetails/General/Template";


export default {
    mixins: [mixins],


    computed: {
        boardList: function () { return this.$store.state.options.boardList; }
    },


    data: function () {
        return {
            apiEndpoint: 'hotel_requests'
        }
    },


    methods: {
        getRequestBuild: function (request) {
            request.hotel = request.hotel && request.hotel.id ? '/api/hotels/' + request.hotel.id : null;

            return request;
        },
    },


    components: {
        Template,
        Cost,
        Price,
        Request,
    }
}
</script>
