<template>
    <div class="buttons has-addons toggle-switch">

        <multiselect
            v-model="requestCurrency"
            :options="potentialCurrencies"
            placeholder="EUR"
            class="is-small"
            :allow-empty="false"
        >
        </multiselect>

        <button
            class="button is-gray is-larger"
            v-on:click.prevent="$emit('reloadPrices', '?currency=' + requestCurrency)"
        >ändern</button>



        <multiselect
            v-model="reloadType"
            :options="reloadOptions"
            track-by="key"
            label="label"
            class="is-smaller-small has-margin-left"
            :allow-empty="false"
        >
        </multiselect>

        <button
            v-if="request.package === null"
            class="button is-gray is-larger"
            v-on:click.prevent="reloadPrices"
        >neu laden</button>


        <button
            v-on:click.prevent="toggleCalculationRequest"
            :class="{'is-primary': isCalculationRequest}"
            class="button is-larger has-margin-left"
        >EK-Preise für Kalkulation verwenden</button>
    </div>
</template>


<script>
import { Multiselect } from '@components/form';
import { store }    from '@root/pages/orders/store';

export default {
    store,

    props: {
        request: { type: Object, required: true },
        calculationRequest: {required: true}
    },


    computed: {

        isCalculationRequest: {
            get: function () { return this.calculationRequest && this.calculationRequest.id === this.request.id },
        },

        potentialCurrencies: function () {
            return this.$store.state.options.potentialCurrencies;
        }

    },


    data: function () {

        return {

            requestCurrency: this.request.requestCurrency,
            reloadType: {
                key: 'all',
                label: 'alles',
                params: '?prices=true&itemPrices=true&freePlaces=true',
            },
            reloadOptions: [
                {
                    key: 'all',
                    label: 'alles',
                    params: '?prices=true&itemPrices=true&freePlaces=true',
                },
                {
                    key: 'prices',
                    label: 'nur Preise',
                    params: '?prices=true',
                },
                {
                    key: 'itemPrices',
                    label: 'nur Zuschläge',
                    params: '?itemPrices=true',
                },
                {
                    key: 'freePlaces',
                    label: 'nur Freiplätze',
                    params: '?freePlaces=true',
                },
            ]
        }
    },


    methods: {
        toggleCalculationRequest: function () {
            if (!this.isCalculationRequest) {
                this.$emit('setCalculationRequest', this.request)
            } else {
                this.$emit('setCalculationRequest', null)
            }
        },
        reloadPrices: function () {
            if (confirm('Freiplätze, Preise und Zuschläge werden womöglich überschrieben. Bist du sicher?'))
            {
                this.$emit('reloadPrices', this.reloadType.params)
            }
        }
    },



    components: {
        Multiselect
    }
}
</script>
