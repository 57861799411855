import Vuex from 'vuex';
import has from 'lodash/has';
import flow from 'lodash/flow';

import { editCalculation, getCalculationPrices, getPackages, getDays, currency, editOrder } from '@api';
import { notifySuccess, notifyError } from '@components/Notification';
import { getRequestType } from '@utilities/functions';
import {
    prepareForBackend,
    getSortHelper,
    placeholderNights,
    getRequestAPI,
    applyItemPricesSummary,
    addBusBuyPrice,
    appendPersonPrice,
    filterPersons,
    addItemPrices,
    withoutTreatPackages,
    sortHelper,
    getPackageItemPrices
} from './functions2';

const getDefaultState = () => ({
    options: {
        sortGroups: []
    },

    isSaving: false,
    isLoading: true,
    shouldSave: false,

    order: {},
    calculationID: null,
    calculationSaved: false,

    prices: {
        hotelPrices: [],
        ferryPrices: [],
        otherPrices: [],
        airlinePrices: [],
        trainPrices: [],
        includedPrices: [],
        freePlaces: [],
        contingentPrices: [],
        extraPrices: [],
        itemPrices: [],
        packageItemPrices: []
    },
    packages: [],

    sortHelper: [],

    highlight: {
        persons: null,
        single: null,
    },

    modalCRUD: null,
    sellingPrice: null,

    modalPlaceholder: null,
    marginTaxOn: false,
    showExchangeRates: false,
    exchangeRates: [],
    calculationToOrderExchangeRate: 'EUR',
    defaultExchangeRates: [],
    defaultCalculationToOrderExchangeRate: null,
    savedCalcToOrderRate: null,
    savedExchangeRates: [],
    selectedExchangeRate: 'EUR',
});


export default new Vuex.Store({
    state: getDefaultState(),

    mutations: {
        resetState: function (state) {
            Object.assign(state, getDefaultState());
        },

        setOptions: function (state, options) {
            state.options = { ...state.options, ...options };
        },

        updateStatus: function (state, value) {
            state.isSaving = value;
        },

        updateLoading: function (state, value) {
            state.isLoading = value;
        },

        updateShouldSave: function (state, value) {
            state.shouldSave = value;
        },

        updateSellingPrice: function (state, value) {
            state.sellingPrice = value;
        },

        updateOrder: function (state, order) {
            state.order = JSON.parse(JSON.stringify(order));
        },

        updateCalculationID: function (state, calculation) {
            if (calculation) {
                if (typeof calculation === 'string') {
                    const calculationID = calculation.match(/^\/api\/calculations\/(\d+)/)[1];
                    state.calculationID = calculationID;
                } else {
                    state.calculationID = calculation.id;
                }
            }
        },

        updateCalculationSaved: function (state, value) {
            state.calculationSaved = value;
        },

        updateMarginTaxOn: function(state, value) {
            state.marginTaxOn = value;
        },

        updateSortHelper: function (state, sortHelper) {
            state.sortHelper = [...sortHelper];
        },

        updatePrices: function (state, data) {
            const prices = {
                hotelPrices: data.hotelPrices
                    .map(price => ({ ...price, key: price.id })),
                ferryPrices: data.ferryPrices
                    .map(price => ({ ...price, key: price.id })),
                trainPrices: data.trainPrices
                    .map(price => ({ ...price, key: price.id })),
                otherPrices: data.otherPrices
                    .map(price => ({ ...price, key: price.id })),
                airlinePrices: data.airlinePrices
                    .map(price => ({
                        ...price,
                        ...!!price.airlinePrice && { airlinePrice: price.airlinePrice.id },
                        key: price.id
                    })),
                includedPrices: data.includedPrices
                    .map(price => ({ ...price, key: price.id })),
                freePlaces: data.freePlaces
                    .map(price => ({ ...price, key: price.id })),
                contingentPrices: data.contingentPrices
                    .map(price => ({ ...price, key: price.id })),
                extraPrices: data.extraPrices
                    .map(price => ({ ...price, key: price.id })),
                itemPrices: data.itemPrices
                    .map(price => ({ ...price, key: price.id })),
                packageItemPrices: data.packageItemPrices
                    .map(price => ({ ...price, key: price.id })),
            };

            state.prices = JSON.parse(JSON.stringify(prices));
        },

        updateContingentPrices: function (state, contingentPrices) {
            state.prices = {
                ...state.prices,
                contingentPrices: contingentPrices
                    .map(price => ({ ...price, key: price.id }))
            };
        },

        updateExtraPrices: function (state, extraPrices) {
            state.prices = {
                ...state.prices,
                extraPrices: extraPrices
                    .map(price => ({ ...price, key: price.id }))
            };
        },

        updateIncludedPrices: function (state, includedPrices) {
            state.prices = {
                ...state.prices,
                includedPrices: includedPrices
                    .map(price => ({ ...price, key: price.id }))
            };
        },

        updatePrice: function (state, priceObj) {
            const key = Object.keys(priceObj)[0];
            const newPrice = priceObj[key];

            const prices = state.prices[key];
            const priceIndex = prices.findIndex(price => {
                if (has(newPrice, 'id')) {
                    return price.id === newPrice.id;
                }
                if (has(newPrice, 'key')) {
                    return price.key === newPrice.key;
                }

                return false;
            });

            if (priceIndex !== -1) {
                state.prices[key].splice(priceIndex, 1, {
                    ...prices[priceIndex],
                    ...newPrice,
                });
            } else {
                state.prices[key] = [
                    ...prices,
                    newPrice
                ];
            }
        },

        updateLoadedPrices: function (state, prices) {
            const { hotelPrices, ferryPrices, otherPrices, trainPrices } = prices;

            state.prices = {
                ...state.prices,
                hotelPrices: state.prices.hotelPrices
                    .map(price => {
                        const hotelPrice = hotelPrices.find(({ id }) => id === price.id);
                        return {
                            ...price,
                            providerPrices: hotelPrice ? hotelPrice.providerPrices : price.providerPrices
                        };
                    }),
                ferryPrices: state.prices.ferryPrices
                    .map(price => {
                        const ferryPrice = ferryPrices.find(({ id }) => id === price.id);
                        return {
                            ...price,
                            providerPrices: ferryPrice ? ferryPrice.providerPrices : price.providerPrices
                        };
                    }),
                trainPrices: state.prices.trainPrices
                    .map(price => {
                        const trainPrice = trainPrices.find(({ id }) => id === price.id);
                        return {
                            ...price,
                            providerPrices: trainPrice ? trainPrice.providerPrices : price.providerPrices
                        };
                    }),
                otherPrices: state.prices.otherPrices
                    .map(price => {
                        const otherPrice = otherPrices.find(({ id }) => id === price.id);
                        return {
                            ...price,
                            providerPrices: otherPrice ? otherPrice.providerPrices : price.providerPrices
                        };
                    })
            };
        },

        updateDefaultExchangeRates: function (state, rates) {
            state.defaultExchangeRates = rates;
        },

        updateDefaultCalculationToOrderExchangeRate: function (state, rate) {
            state.defaultCalculationToOrderExchangeRate = rate;
        },

        updateSavedExchangeRates: function (state, rates) {
            state.savedExchangeRates = rates;
        },

        updateSavedExchangeRate: function (state, array) {
            let fromCurrency = array[0];
            let toCurrency = array[1];
            let rate = array[2];
            const rateIndex         = state.savedExchangeRates.findIndex(x => x.fromCurrencyCode === fromCurrency && x.toCurrencyCode === toCurrency);
            if(rateIndex === -1){
                state.savedExchangeRates.push({
                    'fromCurrencyCode': fromCurrency,
                    'toCurrencyCode': toCurrency,
                    'fromAmount': 1,
                    'rate': parseFloat(rate)
                })
            }
            state.savedExchangeRates[rateIndex] = {...state.savedExchangeRates[rateIndex], 'rate': parseFloat(rate)}
        },

        updateExchangeRates: function (state) {

            let rates = state.savedExchangeRates.filter(rate => rate.toCurrencyCode === state.order.calculationCurrency);
            let savedRates = {}
            let defaultExchangeRates = state.defaultExchangeRates;
            state.exchangeRates = {};


            Object.keys(rates).forEach((key) => {
                let newKey = rates[key].fromCurrencyCode
                savedRates[newKey] = rates[key].rate / rates[key].fromAmount
            });

            state.exchangeRates = {...defaultExchangeRates, ...savedRates}
        },

        updateCalculationToOrderExchangeRate: function (state) {
            let rates = state.savedExchangeRates.filter(rate => rate.fromCurrencyCode === state.order.calculationCurrency && rate.toCurrencyCode === state.order.orderCurrency);
            let savedRates = {}

            Object.keys(rates).forEach((key) => {
                let newKey = rates[key].fromCurrencyCode
                savedRates[newKey] = rates[key].rate / rates[key].fromAmount
            });
            state.calculationToOrderExchangeRate = {...state.defaultCalculationToOrderExchangeRate, ...savedRates};
        },

        updateShowExchangeRates: function (state, value) {
            state.showExchangeRates = value;
        },


        updateOrderCurrency: function (state, value) {
            state.order.orderCurrency = value;
        },

        updateCalculationCurrency: function (state, value) {
            state.order.calculationCurrency = value;
        },

        //  Custom lists (addable/removable)
        addExtra: function (state, extra = {}) {
            state.prices.extraPrices = [...state.prices.extraPrices, {
                key: +new Date(),
                billingType: 'included',
                buyPrice: {
                    amount: null,
                    currency: state.order.orderCurrency
                },
                calculation: `/api/calculations/${state.calculationID}`,
                name: '',
                offerTextBlock: null,
                quantity: null,
                sellPrice: {
                    amount: null,
                    currency: state.order.orderCurrency
                },
                type: 'per_person',
                ...extra
            }];
        },

        removeExtra: function (state, item) {
            state.prices.extraPrices = state.prices.extraPrices
                .filter(extra => extra.key !== item.key);
        },

        addIncluded: function (state, priceType = 'per_person') {

            state.prices.includedPrices = [...state.prices.includedPrices, {
                key: +new Date(),
                priceType,
                calculation: `/api/calculations/${state.calculationID}`,
                maxPersons: -1,
                minPersons: 0,
                name: '',
                buyPrice: {
                    amount: null,
                    currency: state.order.calculationCurrency
                },
                freePlaces: 1
            }];
        },

        removeIncluded: function (state, item) {
            state.prices.includedPrices = state.prices.includedPrices
                .filter(included => included.key !== item.key);
        },

        addPackage: function (state, { persons }) {
            const findPrice = state.prices.contingentPrices
                .filter(price => !!price.orderHotelRoom)
                .find(({ orderHotelRoom }) => orderHotelRoom.type.persons === persons);

            const price = JSON.parse(JSON.stringify(findPrice));

            state.prices.contingentPrices = [...state.prices.contingentPrices, {
                orderHotelRoom: {
                    ...price.orderHotelRoom,
                    type: {
                        type: 'standard',
                        persons
                    }
                },
                trainCabinType: null,
                billingType: price.billingType,
                numberOfPersons: null,
                sellPrice: {
                    amount: null,
                    currency: state.order.orderCurrency
                },
                buyPrice: {
                    amount: null,
                    currency: state.order.orderCurrency
                },
                key: +new Date()
            }];
        },

        removePackage: function (state, item) {
            state.prices.contingentPrices = state.prices.contingentPrices
                .filter(p => p.key !== item.key);
        },

        updatePackages: function (state, packages) {
            state.packages = [...packages];
        },

        updateHighlight: function (state, obj) {
            state.highlight = { ...state.highlight, ...obj };
        },

        clearHighlight: function (state) {
            const { highlight } = getDefaultState();
            state.highlight = highlight;
        },

        updateModalPlaceholder: function (state, price) {
            if (price !== null) {
                const { _type, placeholder } = price;
                state.modalPlaceholder = {
                    placeholder: JSON.parse(JSON.stringify(placeholder)),
                    component: _type
                }
            } else {
                state.modalPlaceholder = null;
            }
        },

        updateModalCRUD: function (state, price) {
            if (price !== null) {
                state.modalCRUD = {
                    price: JSON.parse(JSON.stringify(price)),
                    sellingPrice: state.sellingPrice,
                    ...state.options.roomTypes && state.options.roomTypes.crud_prices && {
                        options: {
                            ...state.options,
                            roomTypePrice: state.options.roomTypes.crud_prices,
                            cabinTypes: state.options.cabinTypes.crud_prices,
                            trainCabinTypes: state.options.trainCabinTypes.crud_prices,
                            dayTrainCabinTypes: state.options.dayTrainCabinTypes.crud_prices,
                            boardExtended: state.options.boardListExtended,
                        }
                    },
                    component: price._type
                }
            } else {
                state.modalCRUD = null;
            }
        },

        toggleMargins: function (state, marginTax) {
            state.marginTaxOn = marginTax;
            state.prices = {
                ...state.prices,
                contingentPrices: state.prices.contingentPrices
                    .map(price => ({
                        ...price,
                        marginTax
                        })),

                extraPrices: state.prices.extraPrices
                    .map(price => ({
                        ...price,
                        marginTax
                    })),

                airlinePrices: state.prices.airlinePrices
                    .map(price => ({
                        ...price,
                        ...price.placeholder.clientOffer.status === 'optional' && {
                            marginTax
                        },
                        ...price.placeholder.clientOffer.status === 'additional' && {
                            marginTax
                        }
                    })),
                ferryPrices: state.prices.ferryPrices
                    .map(price => ({
                        ...price,
                        ...price.placeholder.clientOffer.status === 'optional' && {
                            marginTax
                        },
                        ...price.placeholder.clientOffer.status === 'additional' && {
                            marginTax
                        }
                    })),
                trainPrices: state.prices.trainPrices
                    .map(price => ({
                        ...price,
                        ...price.placeholder.clientOffer.status === 'optional' && {
                            marginTax
                        },
                        ...price.placeholder.clientOffer.status === 'additional' && {
                            marginTax
                        }
                    })),
                hotelPrices: state.prices.hotelPrices
                    .map(price => ({
                        ...price,
                        ...price.placeholder.clientOffer.status === 'optional' && {
                            marginTax
                        },
                        ...price.placeholder.clientOffer.status === 'additional' && {
                            marginTax
                        }
                    })),
                otherPrices: state.prices.otherPrices
                    .map(price => ({
                        ...price,
                        ...price.placeholder.clientOffer.status === 'optional' && {
                            marginTax
                        },
                        ...price.placeholder.clientOffer.status === 'additional' && {
                            marginTax
                        }
                    })),
            };
        }
    },

    actions: {
        setOrder: function ({ commit, dispatch }, order) {
            commit('updateCalculationID', order.calculation);
            commit('updateOrder', order);

            dispatch('fetchExchangeRates');
            dispatch('loadCalculation');
        },

        loadCalculation: function ({ state, commit, dispatch }) {
            dispatch('fetchPrices');
            dispatch('fetchPackages');

            getDays(`?order.id=${state.order.id}`)
                .then(response => {
                    commit('updateSortHelper', getSortHelper(response.data));
                });
        },

        fetchPrices: function ({ state, commit }) {
            getCalculationPrices(state.calculationID, {_groups: ['calculation:providerPrices', 'currency_exchange_rate']})
                .then(({ data }) => {
                    commit('updatePrices', data);
                    commit('updateCalculationSaved', data.saved);
                    commit('updateMarginTaxOn', data.marginTax);
                    commit('updateSavedExchangeRates', data.rates);
                    commit('updateExchangeRates');
                    commit('updateCalculationToOrderExchangeRate');
                    //commit('updateSavedExchangeRate', state.exchangeRates[state.selectedExchangeRate]);

                }).then(() => {
                    commit('updateLoading', false);
                });
        },

        fetchPackages: function ({ commit, state }) {
            getPackages(`?order.id=${state.order.id}&_groups[]=order_calculation_read`)
                .then(response => {
                    commit('updatePackages', response.data);
                });
        },

        fetchLoadedPrices: function ({ commit, state }, params = {}) {

            editCalculation({
                id: state.calculationID,
                data: {
                    yearsToSearchIn: params.years
                },
                saveAndGet: true,
                params: {_groups: ['calculation:providerPrices']}
                ,
            }).then(({ data }) => {
                notifySuccess('Die vorgeschlagenen Preise wurden erfolgreich aktualisiert');
                const { hotelPrices, ferryPrices, otherPrices, trainPrices } = data;
                commit('updateLoadedPrices', {
                    hotelPrices,
                    ferryPrices,
                    otherPrices,
                    trainPrices
                });
            }, error => {
                notifyError("Die vorgeschlagenen Preise konnten nicht aktualisiert werden! Server Error!");
            })


        },

        fetchExchangeRates: function ({ commit, state }) {
            currency.orderRates.get( state.order.id)
                .then(response => {
                    commit('updateDefaultExchangeRates', response.data.buyToCalculationCurrency)
                    commit('updateDefaultCalculationToOrderExchangeRate', response.data.calculationToClientCurrency)

                    commit('updateExchangeRates');
                    commit('updateCalculationToOrderExchangeRate');
                })
        },

        changeOrderCurrency: function ({ commit, dispatch, state }) {
            commit('updateStatus', true);
            let oldData = state.prices;
            dispatch('saveCalculation', true).then(({ data }) => {
                    notifySuccess('Die Verkaufswährung der Reise wurde erfolgreich geändert');
                    //commit('updatePrices', data);
                    dispatch('fetchExchangeRates');
                }, error => {
                    notifyError("Die Verkaufswährung konnte nicht geändert werden! Server Error!");
                }).then(() => commit('updateStatus', false))
        },

        changeCalculationCurrency: function ({ commit, dispatch, state }) {
            commit('updateStatus', true);

            return new Promise((resolve, reject) => {
                editOrder({
                    id: state.order.id,
                    data: {
                        calculationCurrency: state.order.calculationCurrency
                    },
                }).then(response => {
                    notifySuccess('Die Kalkulationswährung wurde erfolgreich geändert');
                    dispatch('fetchExchangeRates');
                    resolve(response.data);
                }, error => {
                    notifyError("Die Kalkulationswährung konnte nicht geändert werden! Server Error!");
                    reject(error);
                }).then(() => commit('updateStatus', false));
            });
        },


        saveCalculation: function ({ commit, state }, saveAndGet = false) {
            commit('updateStatus', true);

            return new Promise((resolve, reject) => {
                editCalculation({
                    id: state.calculationID,
                    data: {
                        ...prepareForBackend(state),
                        saved: true,
                        marginTax: state.marginTaxOn,
                    },
                    ...saveAndGet && {
                        params:{
                        _groups: ['calculation:providerPrices'],
                        currency: state.order.orderCurrency
                        }
                    },
                    saveAndGet: saveAndGet
                }).then(response => {
                    notifySuccess('Die Kalkulation wurde erfolgreich gespeichert');
                    commit('updateContingentPrices', response.data.contingentPrices);
                    commit('updateExtraPrices', response.data.extraPrices);
                    commit('updateIncludedPrices', response.data.includedPrices);
                    commit('updateCalculationSaved', response.data.saved);
                    commit('updateMarginTaxOn', response.data.marginTax);
                    commit('updateSavedExchangeRates', response.data.rates);
                    commit('updateExchangeRates');
                    commit('updateCalculationToOrderExchangeRate');

                    resolve(response.data);
                }, error => {
                    notifyError("Die Kalkulation konnte nicht gespeichert werden! Server Error!");
                    reject(error);
                }).then(() => commit('updateStatus', false));
            });
        },
    },

    getters: {
        persons: function (state, getters) {
            return getters.rooms.double;// * 2; //+ getters.rooms.single
        },

        rooms: function (state, getters) {
            const single = getters.packagePrices
                .filter(({ orderHotelRoom }) => orderHotelRoom.type.persons === 1)
                .sort((a,b) => {
                    if (a.numberOfPersons === null) return 1;
                    return a.numberOfPersons < b.numberOfPersons ? -1 : 1;
                });

            const double = getters.packagePrices
                .filter(({ orderHotelRoom }) => orderHotelRoom.type.persons === 2)
                .sort((a,b) => {
                    if (a.numberOfPersons === null) return 1;
                    return a.numberOfPersons < b.numberOfPersons ? -1 : 1;
                });

            return {
                single: single.length > 0 ? single[0].numberOfPersons : 0,
                double: double.length > 0 ? double[0].numberOfPersons : 0,
                singleMin: 0,
                singleMax: single[0].numberOfPersons,
                doubleMin: double.length > 0 ? double[0].numberOfPersons : 0,
                doubleMax: double.length > 1 ? double[1].numberOfPersons : 999999,
            };
        },

        allPrices: function (state) {
            const exchangeRates = state.exchangeRates;
            const supplementPrices = state.options.clientConfig.calculation.supplementPrices;
            return {
                hotelPrices: flow(
                    withoutTreatPackages,
                    prices => prices.map(price => {
                        const exchangeFactor = price.placeholder.placeholderCurrency && exchangeRates[price.placeholder.placeholderCurrency] ? Number(exchangeRates[price.placeholder.placeholderCurrency]) : 0;
                        return {
                        ...price,
                        exchangeFactor: exchangeFactor,
                        _type: 'hotel',
                        _summary: {
                            group: 0,
                            person: price.doubleRoomBuyPrice.amount * placeholderNights(price.placeholder) * exchangeFactor,
                            single: price.singleRoomPremiumBuyPrice.amount * placeholderNights(price.placeholder) * exchangeFactor
                        }
                    }}),
                    addItemPrices,
                    items => sortHelper(items, state.sortHelper)
                )(state.prices.hotelPrices),
                ferryPrices: flow(
                    withoutTreatPackages,
                    items => items.map(price => {
                        const exchangeFactor = price.placeholder.placeholderCurrency && exchangeRates[price.placeholder.placeholderCurrency] ? Number(exchangeRates[price.placeholder.placeholderCurrency]) : 0;
                        const isNight = price.placeholder.type === 'night';
                        const personPrice = isNight ? price.insideDoubleCabinBuyPrice : price.dayTicketBuyPrice;
                        const singlePrice = isNight ? price.insideSingleCabinPremiumBuyPrice : price.dayTicketBuyPrice;
                        const nights = isNight ? placeholderNights(price.placeholder) : 1;
                        return {
                            ...price,
                            exchangeFactor: exchangeFactor,
                            _type: 'ferry',
                            _summary: {
                                group: 0,
                                person: Number(personPrice.amount) * nights * exchangeFactor,
                                single: (!supplementPrices || isNight ? Number(singlePrice.amount) * nights * exchangeFactor : 0),
                            }
                        };
                    }),
                    addItemPrices,
                    items => sortHelper(items, state.sortHelper)
                )(state.prices.ferryPrices),
                trainPrices: flow(
                    withoutTreatPackages,
                    prices => prices.map(price => {
                        const exchangeFactor = price.placeholder.placeholderCurrency && exchangeRates[price.placeholder.placeholderCurrency] ? Number(exchangeRates[price.placeholder.placeholderCurrency]) : 0;
                        const isNight = price.placeholder.type === 'night';
                        let personPrice = isNight ? price.secondDoubleCabinBuyPrice : price.secondSingleCabinPremiumBuyPrice;
                        let singlePrice = price.secondSingleCabinPremiumBuyPrice;
                        if(price.placeholder.category === 'first') {
                            personPrice = isNight ? price.firstDoubleCabinBuyPrice : price.firstSingleCabinPremiumBuyPrice;
                            singlePrice = price.firstSingleCabinPremiumBuyPrice;
                        }
                        const nights = isNight ? placeholderNights(price.placeholder) : 1;
                        return {
                            ...price,
                            exchangeFactor: exchangeFactor,
                            _type: 'train',
                            _summary: {
                                group: 0,
                                person: Number(personPrice.amount) * nights * exchangeFactor,
                                single: (!supplementPrices || isNight ? Number(singlePrice.amount) * nights * exchangeFactor : 0),
                            }
                        }}),
                    addItemPrices,
                    items => sortHelper(items, state.sortHelper)
                )(state.prices.trainPrices),
                otherPrices: flow(
                    withoutTreatPackages,
                    items => items.map(price => {
                        const isPerPerson = price.placeholder.clientOffer.priceType === 'per_person';
                        const exchangeFactor = price.placeholder.placeholderCurrency && exchangeRates[price.placeholder.placeholderCurrency] ? Number(exchangeRates[price.placeholder.placeholderCurrency]) : 0;

                        return {
                            ...price,
                            exchangeFactor: exchangeFactor,
                            _type: 'other',
                            _summary: {
                                group: !isPerPerson ? price.buyPrice.amount * exchangeFactor : 0,
                                person: isPerPerson ? price.buyPrice.amount * exchangeFactor : 0,
                                single: !isPerPerson || supplementPrices ? 0 : price.buyPrice.amount * exchangeFactor,
                            }
                        };
                    }),
                    addItemPrices,
                    items => sortHelper(items, state.sortHelper)
                )(state.prices.otherPrices),
                airlinePrices: flow(
                    withoutTreatPackages,
                    items => items.map(price => {
                        const exchangeFactor = price.placeholder.placeholderCurrency && exchangeRates[price.placeholder.placeholderCurrency] ? Number(exchangeRates[price.placeholder.placeholderCurrency]) : 0;

                        return {
                        ...price,
                        exchangeFactor: exchangeFactor,
                        _type: 'airline',
                        _summary: {
                            group: 0,
                            person: price.buyPrice.amount * exchangeFactor,
                            single: supplementPrices ? 0 : price.buyPrice.amount * exchangeFactor,
                        }
                    }}),
                    addItemPrices,
                    items => sortHelper(items, state.sortHelper)
                )(state.prices.airlinePrices),
                includedPrices: flow(
                    items => items.map(price => {
                        let sumPrice = 0;
                        if(has(price, 'buyPrice')){
                            const exchangeFactor = price.buyPrice.currency && exchangeRates[price.buyPrice.currency] ? Number(exchangeRates[price.buyPrice.currency]) : 0;
                            sumPrice = Number(price.buyPrice.amount) * exchangeFactor;
                        }
                        return {
                            ...price,
                            _summary: {
                                group: price.priceType === 'per_group' ? sumPrice : 0,
                                person: price.priceType === 'per_person' ? sumPrice : 0,
                                single: price.priceType === 'per_single_room' || (!supplementPrices && price.priceType === 'per_person') ? sumPrice : 0
                            }
                        };
                    }),
                    addItemPrices,
                    items => sortHelper(items, state.sortHelper)
                )(state.prices.includedPrices)
            };
        },

        includedPrices: function (state, getters) {
            const { hotelPrices, ferryPrices, trainPrices, otherPrices, airlinePrices, includedPrices } = getters.allPrices;

            return {
                hotelPrices: hotelPrices
                    .filter(({ placeholder }) => placeholder.clientOffer && placeholder.clientOffer.status === 'included'),
                ferryPrices: ferryPrices
                    .filter(({ placeholder }) => placeholder.clientOffer && placeholder.clientOffer.status === 'included'),
                trainPrices: trainPrices
                    .filter(({ placeholder }) => placeholder.clientOffer && placeholder.clientOffer.status === 'included'),
                otherPrices: otherPrices
                    .filter(({ placeholder }) => placeholder.clientOffer && placeholder.clientOffer.status === 'included'),
                airlinePrices: airlinePrices
                    .filter(({ placeholder }) => placeholder.clientOffer && placeholder.clientOffer.status === 'included'),
                includedPrices
            };
        },

        notIncludedPrices: function (state, getters) {
            const { hotelPrices, ferryPrices, trainPrices, otherPrices, airlinePrices } = getters.allPrices;

            return {
                hotelPrices: hotelPrices
                    .filter(({ placeholder }) => placeholder.clientOffer && placeholder.clientOffer.status !== 'included'),
                ferryPrices: ferryPrices
                    .filter(({ placeholder }) => placeholder.clientOffer && placeholder.clientOffer.status !== 'included'),
                trainPrices: trainPrices
                    .filter(({ placeholder }) => placeholder.clientOffer && placeholder.clientOffer.status !== 'included'),
                otherPrices: otherPrices
                    .filter(({ placeholder }) => placeholder.clientOffer && placeholder.clientOffer.status !== 'included'),
                airlinePrices: airlinePrices
                    .filter(({ placeholder }) => placeholder.clientOffer && placeholder.clientOffer.status !== 'included'),
            };
        },

        summaryPrices: function (state, getters) {

            const exchangeRates = state.exchangeRates;

            const includedPrices = getters.includedPrices;
            const persons = getters.persons;

            const supplementPrices = state.options.clientConfig.calculation.supplementPrices;

            const treatPackagePrices = getters.treatPackages
                .filter(({ requests }) => requests[0].placeholder.clientOffer.status === 'included');

            return applyItemPricesSummary([
                ...includedPrices.hotelPrices,
                ...includedPrices.airlinePrices,
                ...flow(
                    items => addBusBuyPrice(items, persons, exchangeRates, supplementPrices)
                )(includedPrices.ferryPrices),
                ...includedPrices.trainPrices,
                ...flow(
                    items => appendPersonPrice(items, persons, supplementPrices)
                )(includedPrices.otherPrices),
                ...flow(
                    items => items.filter(({ priceType }) => priceType === 'per_single_room'),
                    items => filterPersons(items,  getters.rooms.single)
                )(includedPrices.includedPrices),
                ...flow(
                    items => items.filter(({ priceType }) => priceType !== 'per_single_room'),
                    items => filterPersons(items, persons),
                    items => appendPersonPrice(items, persons, supplementPrices)
                )(includedPrices.includedPrices),
                ...treatPackagePrices
            ], getters.rooms, exchangeRates, supplementPrices);
        },

        packagePrices: function (state) {
            let singlePrices = state.prices.contingentPrices
                .filter(price => price.orderHotelRoom)
                .filter(price => {
                    const { type, persons } = price.orderHotelRoom.type;
                    return type === 'standard' && persons === 1;
                });

            let doublePrices = state.prices.contingentPrices
                .filter(price => price.orderHotelRoom)
                .filter(price => {
                    const { type, persons } = price.orderHotelRoom.type;
                    return type === 'standard' && persons === 2;
                });


            singlePrices.sort((a,b) => {
                if (a.numberOfPersons === null) return 1;
                return a.numberOfPersons < b.numberOfPersons ? -1 : 1;
            });

            doublePrices.sort((a,b) => {
                if (a.numberOfPersons === null) return 1;
                return a.numberOfPersons < b.numberOfPersons ? -1 : 1;
            });

            singlePrices = singlePrices.map((price, index, arr) => ({
                ...price,
                numberOfPersonsMin: !arr[index - 1] ? 0 : arr[index - 1].numberOfPersons + 1,
                numberOfPersonsMax: price.numberOfPersons
            }));

            doublePrices = doublePrices.map((price, index, arr) => ({
                ...price,
                numberOfPersonsMin: price.numberOfPersons,
                numberOfPersonsMax: !arr[index + 1] ? 999999 : arr[index + 1].numberOfPersons - 1,
            }));

            return [...singlePrices, ...doublePrices];
        },

        nightFerryPrices: function (state, getters) {
            return getters.includedPrices.ferryPrices
                .filter(({ placeholder }) => placeholder.type === 'night');
        },

        nightTrainPrices: function (state, getters) {
            return getters.includedPrices.trainPrices
                .filter(({ placeholder }) => placeholder.type === 'night');
        },

        groupedPrices: function (state, getters) {
            const exchangeRates = state.exchangeRates;
            let groupPrices = {};
            const doublePersons = getters.rooms.double;
            const supplementPrices = state.options.clientConfig.calculation.supplementPrices;

            state.options.sortGroups
                .forEach(group => {
                    groupPrices[group.key] = flow(
                        items => items.filter(({ placeholder }) => placeholder.sortGroup === group.key),
                        items => applyItemPricesSummary(items, getters.rooms, exchangeRates)
                    )([
                        ...getters.includedPrices.hotelPrices,
                        ...getters.includedPrices.airlinePrices,
                        ...getters.includedPrices.trainPrices,
                        ...flow(
                            items => addBusBuyPrice(items, doublePersons, exchangeRates, supplementPrices)
                        )(getters.includedPrices.ferryPrices),
                        ...flow(
                            items => appendPersonPrice(items, doublePersons, supplementPrices)
                        )(getters.includedPrices.otherPrices)
                    ]);
                });

            return groupPrices;
        },

        unGroupedPrices: function (state, getters) {
            return [
                ...getters.includedPrices.hotelPrices,
                ...getters.includedPrices.ferryPrices,
                ...getters.includedPrices.otherPrices,
                ...getters.includedPrices.trainPrices,
                ...getters.includedPrices.airlinePrices,
            ].filter(({ placeholder }) => !placeholder.sortGroup);
        },

        treatPackages: function (state) {

            const exchangeRates = state.exchangeRates;
            const supplementPrices = state.options.clientConfig.calculation.supplementPrices;


            return state.packages.map(treatPackage => {
                let trainCategory = treatPackage.trainCategory === 'first' ? 'first' : 'second';

                const personPrice = treatPackage.basePrices.find(price => {
                    const { roomType, cabinType, trainCabinType } = price;
                    return (
                        (roomType.type === 'standard' && roomType.persons === 2) ||
                        (cabinType.type === 'inside' && cabinType.persons === 2) ||
                        (trainCabinType.type === trainCategory && treatPackage.hasNightTrain && trainCabinType.persons === 2) ||
                        (trainCabinType.type === trainCategory && !treatPackage.hasNightTrain && trainCabinType.persons === 1) ||
                        (roomType.type === null && roomType.persons === null && cabinType.type === null && cabinType.persons === null && trainCabinType.persons === null && trainCabinType.type === null)
                    );
                });

                const singlePrice = treatPackage.basePrices.find(price => {
                    const { roomType, cabinType, trainCabinType } = price;
                    return (
                        (roomType.type === 'standard' && roomType.persons === 1) ||
                        (cabinType.type === 'inside' && cabinType.persons === 1) ||
                        (trainCabinType.type === trainCategory && trainCabinType.persons === 1)
                    );
                });

                const exchangeFactorPerson = personPrice && exchangeRates[personPrice.price.currency] ? Number(exchangeRates[personPrice.price.currency]) : 0;
                const exchangeFactorSingle = singlePrice && exchangeRates[singlePrice.price.currency] ? Number(exchangeRates[singlePrice.price.currency]) : 0;

                const person = personPrice ? Number(personPrice.price.amount) * exchangeFactorPerson : 0;
                const single = singlePrice ? Number(singlePrice.price.amount) * exchangeFactorSingle :
                               personPrice && !supplementPrices ? person :
                               0;

                return {
                    ...treatPackage,
                    requests: flow(
                        items => items.map(request => ({
                            _type: getRequestType(request),
                            ...request,
                        })),
                        items => sortHelper(items, state.sortHelper)
                    )(treatPackage.requests),
                    _summary: {
                        person,
                        single,
                        group: 0,
                    },
                    itemPrices: getPackageItemPrices(treatPackage),
                    singlePrice: singlePrice ? Number(singlePrice.price.amount) : personPrice && !supplementPrices ? Number(personPrice.price.amount) : 0,
                    personPrice: personPrice ? Number(personPrice.price.amount) : 0
                };
            })
                .filter(({ requests }) => requests.length > 0)
                .filter(({ requests }) => getRequestAPI(requests[0]) === requests[0].placeholder.calculationRequest);
        }
    }

});
