var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bp-deadline",
      class: [_vm.deadlineUrgency(_vm.date), _vm.editable ? "is-editable" : ""],
    },
    [
      _vm.editable
        ? _c("input-date", {
            ref: "input",
            attrs: { placeholder: "-" },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          })
        : _c(
            "span",
            { staticClass: "deadline__date" },
            [
              _vm.value
                ? [_vm._v(_vm._s(_vm._f("fullYear")(_vm.value)))]
                : [_vm._v("-")],
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }