var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "status-progress" }, [
    _vm.value.progress
      ? _c("div", {
          staticClass: "pie-chart",
          class: _vm.colorClass,
          attrs: { "data-p": _vm.value.progress },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("span", { style: _vm.bookingSituationStyle }, [
      _vm._v(" " + _vm._s(_vm.status)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }