<template>
    <accordion v-bind:isOpen="modalToRequest.type === 'other' && modalToRequest.id === request.id" :is-collapsible="false" :class-names="request.isSelectedRequest ? 'header-is-highlighted' : ''">
        <div slot="header">
            <request-score v-if="request.otherService && request.otherService.provider" v-bind:rating="request.otherService.provider.buspaketRating" />

            <div v-else>
                <div class="no-name">No Name</div>
            </div>

            <div>
                <div class="level-left">
                    <span class="level-item">
                        <template v-if="request.otherService && request.otherService.provider">
                            <a
                                v-if="request.otherService.provider.providerType === 'hotel'"
                                v-bind:href="'/' + request.otherService.provider.providerType + 's/' + request.otherService.provider.id"
                                target="_blank"
                                title="Gehe zum Anbieter"
                            >{{ request.otherService.provider.name }}</a>
                            <a
                                v-else
                                v-bind:href="'/' + request.otherService.provider.providerType.replace('y', 'ie') + 's/' + request.otherService.provider.id"
                                target="_blank"
                                title="Gehe zum Anbieter"
                        >{{ request.otherService.provider.name }}</a>
                    </template>
                    <template v-else>{{request.info.title }}</template>

                    </span>
                    <i
                        class="material-icons is-pointer"
                        :class="{'has-text-active': editMode}"
                        v-on:click.prevent.stop="editMode = !editMode"
                    >edit</i>
                </div>

                <provider-info v-bind:provider="request.provider" class="header-extra-info">
                    <div><i class="fa fa-book"></i>
                        <a v-if="request.agency" v-bind:href="'/agencies/' + request.agency.id" target="_blank" title="Zur Agentur gehen">{{request.agency.name}}</a>
                        <template v-else>direkt</template>
                    </div>

                    <div>
                        <i class="material-icons">check_circle</i>
                        {{ request.contingent.reservedAmount }}
                        Pax
                    </div>

                    <div>
                        <i class="material-icons">help</i>
                        {{ request.contingent.askedAmount }}
                        Pax
                    </div>

                    <div><i class="material-icons">import_contacts</i> {{ getValueWithKey({ key: request.requestStatus.status, optionList: requestStatuses }) }}</div>

                    <div v-if="request.optionTodo && request.optionTodo.dueDate">
                        <div v-bind:class="['deadline-date', deadlineUrgency(request.optionTodo.dueDate)]"><i class="material-icons">notifications_none</i> {{ request.optionTodo.dueDate | fullYear }}</div>
                    </div>
                </provider-info>
            </div>

            <div>
                <request-settings v-on:reloadPrices="reloadPrices" :request="request" :calculation-request="calculationRequest" v-on:setCalculationRequest="setCalculationRequest"/>
            </div>
        </div>

        <div slot="content">
            <div class="buttons has-addons">
                <button
                class="button is-large"
                v-bind:class="[sectionSelection === 'overview' ? 'is-primary' : 'is-gray']"
                v-on:click="changeTab('overview')"
                ><i class="material-icons">help</i> Anfragedetails</button>

                <button
                class="button is-large"
                v-bind:class="[sectionSelection === 'price' ? 'is-primary' : 'is-gray']"
                v-on:click="changeTab('price')"
                ><i class="material-icons">euro_symbol</i> Preise</button>

                <button
                class="button is-large"
                v-bind:class="[sectionSelection === 'cost' ? 'is-primary' : 'is-gray']"
                v-on:click="changeTab('cost')"
                ><i class="material-icons">view_list</i> Gesamtkosten</button>

                <button
                class="button is-large"
                v-bind:class="[sectionSelection === 'email' ? 'is-primary' : 'is-gray']"
                v-on:click="changeTab('email')"
                ><i class="material-icons">email</i> Emails/Telefonate</button>
            </div>


            <loading v-if="isLoading" textInFront="Die Änderungen werden gespeichert..."></loading>

            <template v-else>
                <request
                    v-if="sectionSelection === 'overview'"
                    v-model="request"
                    v-bind:placeholderInfo="placeholderInfo"
                    v-bind:editMode="editMode"
                    :calculation-request="calculationRequest"
                ></request>

                <price
                    v-if="sectionSelection === 'price'"
                    v-model="request"
                    v-bind:calculationRequest="calculationRequest"
                    v-on:setCalculationRequest="setCalculationRequest"
                ></price>

                <cost
                    v-if="sectionSelection === 'cost'"
                    v-bind:requestID="request.id"
                    v-bind:packageID="packageID"
                ></cost>

                <emails
                    v-if="sectionSelection === 'email'"
                    v-bind:initialContacts = "request.agency ? request.agency.contacts : request.provider.contacts"
                    v-bind:ordersSelected  = "[order]"
                    v-bind:requestID       = "request.id"
                    v-bind:requestType     = "request.requestType"
                    v-on:close             = "$emit('closeModal')"
                ></emails>
            </template>

            <!-- <button
                v-if="sectionSelection !== 'cost' && sectionSelection !== 'email'"
                class="button is-primary is-large"
                v-bind:class="{ 'is-loading': isLoading }"
                v-on:click="saveRequestChanges(sectionSelection)"
            ><i class="material-icons">check</i> Änderungen speichern</button> -->
        </div>
    </accordion>
</template>



<script>
import mixins     from './mixins';
import Cost       from '../costs/Other';
import Price      from '../prices/others';
import Request    from '../overviews/Other';


export default {
    mixins: [mixins],


    data: function () {
        return {
            apiEndpoint: 'other_requests'
        }
    },


    methods: {
        getRequestBuild: function (request) {
            request.otherService = request.otherService && request.otherService.id ? '/api/other_services/' + request.otherService.id : null;
            request.otherServiceType = request.otherServiceType && request.otherServiceType.id ?
                    '/api/other_service_types/' + request.otherServiceType.id : request.otherServiceType;

            return request;
        },
    },


    components: {
        Cost,
        Price,
        Request
    }
}
</script>
