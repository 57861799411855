var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tags" }, [
    _c(
      "span",
      { staticClass: "is-truncated" },
      _vm._l(_vm.value, function (tag) {
        return _c("span", { staticClass: "tag is-white" }, [
          _vm._v(_vm._s(tag)),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }