var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Buchungskonto bearbeiten",
        createTitle: "Neues Buchungskonto hinzufügen",
      },
    },
    [
      _c(
        "table",
        [
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "name" } }, [
                _vm._v("Bezeichnung:"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.name,
                      expression: "form.name",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", id: "name" },
                  domProps: { value: _vm.form.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "name", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "accountingNumber" } },
                [_vm._v("Buchungskonto:")]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.accountingNumber,
                      expression: "form.accountingNumber",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", id: "accountingNumber" },
                  domProps: { value: _vm.form.accountingNumber },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.form,
                        "accountingNumber",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [
                _vm._v("Voreingestllt für:"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("multiselect", {
                  attrs: {
                    options: _vm.options.defaultList,
                    multiple: "",
                    "track-by": "key",
                    label: "value",
                  },
                  model: {
                    value: _vm.isDefaultFor,
                    callback: function ($$v) {
                      _vm.isDefaultFor = $$v
                    },
                    expression: "isDefaultFor",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("translations-field", {
            attrs: {
              translations: _vm.form.translations,
              "translation-options": _vm.translationOptions,
            },
            on: { changeTranslation: _vm.changeTranslation },
            model: {
              value: _vm.locale,
              callback: function ($$v) {
                _vm.locale = $$v
              },
              expression: "locale",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }