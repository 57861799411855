var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("documents", {
    attrs: { params: _vm.params, uploadData: _vm.uploadData },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }