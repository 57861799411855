<template>
    <div>
        <span class="tag is-white px-1 mr-1"  v-for="tag in value">
            {{ tag }}
        </span>
    </div>
</template>

<script>
import Cell from './cell';

export default {
    mixins: [ Cell ],

    props: {
        value: {
            type: [Array, Object],
        },
    },
}
</script>

