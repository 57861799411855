<template>
    <div class="bp-row-form">
        <div>
            <div class="bp-row-form__title">Standort</div>

            <div>
                <button
                    v-bind:class = "['btn-single-toggle',  onlyFromDay ? '' : 'is-active']"
                    v-on:click   = "onlyFromDay = !onlyFromDay"
                    v-bind:title = "onlyFromDay ? 'Only from trip destinations' : 'All'"
                ><i class="material-icons">flag</i></button>

                <location-select
                    ref = "location"
                    v-bind:value="{
                        area:  copyPlaceholder.area,
                        place: copyPlaceholder.place
                    }"
                    v-bind:limitedDestinations="destinationsForLocation"
                    v-on:input="value => {
                        copyPlaceholder.area  = value.area;
                        copyPlaceholder.place = value.place;
                    }"
                ></location-select>
            </div>
        </div>


        <div>
            <div class="bp-row-form__title">Details</div>

            <div>
                <i class="material-icons">star</i>
                <type v-model="copyPlaceholder.hotelType"></type>
            </div>

            <board v-model="copyPlaceholder.board"></board>
        </div>


        <div class="is-160 has-time">
            <div class="bp-row-form__title">Zeitangaben</div>

            <div>
                <i class="material-icons">brightness_3</i>
                <input
                    v-model.number="copyPlaceholder.nights"
                    class="input has-margin-right is-30"
                    type="text"
                    v-on:focus="$event.target.select()"
                />
                Nächte
            </div>
<!--            <div>
                <i title="Uhrzeit" class="material-icons">access_time</i>
                <date-time v-model="copyPlaceholder.startTime" time-only></date-time>
            </div>-->
        </div>






        <div>
            <div class="bp-row-form__title">Abrechnung</div>

            <div>
                <i title="Set as Additional" class="material-icons">shopping_cart</i>
                <div class="select">
                    <select v-model="copyPlaceholder.clientOffer.status">
                        <option value="included">inkludiert</option>
                        <option value="optional">optional</option>
                        <option value="additional">gesondert</option>
                    </select>
                </div>
            </div>
        </div>


        <button
            class="button is-close"
            v-on:click="$emit('cancel')"
            title="Verwerfen"
        ><i class="material-icons">close</i></button>

        <button
            class        = "button is-save is-primary"
            v-bind:class = "{ 'is-loading': isLoading }"
            v-on:click   = "save"
            title        = "Speichern"
        ><i class="material-icons">check</i></button>
    </div>
</template>



<script>
import debounce       from 'lodash/debounce';
import pick           from 'lodash/pick';

import LocationSelect from '@components/form/LocationSelect';
import { getValueWithKey } from '@utilities/functions';
import Board          from '@orders/placeholders/components/forms/HotelBoard';
import Type           from '@orders/placeholders/components/forms/HotelType';
import mixins         from './mixins';
import {defaultHotelPlaceholderValues} from "@clientSpecific/utilities/defaultValues";


export default {
    mixins: [mixins],


    computed: {
        destinationsForLocation: function () { return this.onlyFromDay ? this.options.dayDestinations : []; },
        boards:                  function () { return JSON.parse(localStorage.getItem('options')).hotel.board; },
        board:                   function () { return getValueWithKey({key: this.copyPlaceholder.board, optionList: this.boards}); }
    },


    data: function () {
        return {
            copyPlaceholder: {
                ...defaultHotelPlaceholderValues,
                area: null,
                place: null,
                nights: 1,
                //startTime: '00:00',
                clientOffer: {
                    status: 'included',
                    priceType: 'per_person',
                    price: '0'
                },
            },


            onlyFromDay: true
        }
    },


    methods: {
        locationLabel: function ({ preposition, name }) { return preposition ? preposition + ' ' + name : name; },


        onKeyUp: function (event) {
            if (event.keyCode === 13 &&
                this.placeholder === undefined &&
                (this.copyPlaceholder.area || this.copyPlaceholder.place)
                ) {
                this.$nextTick(() => {
                    if (this.placeholderStringify === JSON.stringify(this.copyPlaceholder)) {
                        this.save();
                    }
                });
            }
        },


        pick
    },


    mounted: function () {
        if (!this.placeholder) {
            this.$refs.location.$el.querySelector('input').focus();
        }
    },


    components: {
        Board,
        LocationSelect,
        Type
    }
}
</script>
