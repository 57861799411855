<template>
    <documents
        v-bind:params="params"
        v-bind:uploadData="uploadData"
    />
</template>

<script>
import Mixin from './tab';
import Documents from '@components/documents/List';

export default {
    mixins: [Mixin],

    computed: {
        api: function () { return this.$store.state.api},

        singular: function () {
            return {
                'hotels': 'provider',
                'ferries': 'provider',
                'others': 'provider',
                'airlines': 'provider',
                'trains' : 'provider',
                'agencies': 'agency',
                'clients': 'client',
                'destinations': 'destination',
                'places': 'place',
                'areas': 'area',
                'order_concepts' : 'orderConcept',
                'day_concepts' : 'dayConcept',
                'predefined_route_details' : 'predefinedRouteDetail',
                'organisations': 'organisation',
                'banks': 'bank',
            }[this.api];
        },

        params: function () {
            return [`links.${this.singular}.id=${this.item.id}`]
        },

        uploadData: function () {
            return {
                links: [{
                    [this.singular]: `/api/${this.api}/${this.item.id}`
                }]
            }
        }
    },

    components: { Documents }
}
</script>
