<template>
    <div class="bp-panel is-form-contingent">
        <div class="panel-label" v-if="!day">Zug - {{ nrOfPersons === 1 ? 'Einzel' : 'Doppel' }}</div>
        <div class="panel-label" v-if="day">Zugtickets</div>
        <header>
            <div><span v-if="!day">{{ nrOfPersons }} x </span><i class="material-icons">directions_train</i></div>

            <div><template v-if="!isOnlyAsked"><i class="material-icons">check_circle</i></template></div>
            <div><i class="material-icons">help</i></div>

            <div>
                <button class="has-margin-top" v-if="!isInPackage" title="Extra für Reisebegleitung" v-on:click="addExtraPeople"><i class="material-icons">person_add</i></button>
            </div>
        </header>


        <main v-bind:class="{ 'has-extra': hasExtra && !isInPackage }">
            <div class="row" v-for="(accomodation, index) in sortedAccomodations" v-bind:key="accomodation.id">
                <div>
                    <div>
                        <template v-if="nrOfPersons === 1 && !day">Einzelabteil - </template>
                        <template v-else-if="nrOfPersons === 2 && !day">Doppelabteil - </template>
                        <template v-else>Tickets - </template>
                        {{ getValueWithKey({ key: accomodation.type.type , optionList: types }) }}
                    </div>
                </div>

                <div>
                    <input
                        v-if="!isOnlyAsked"
                        class="input"
                        type="text"
                        v-model.number="accomodation.reservedAmount"
                        v-on:focus="$event.target.select()"
                    />
                </div>

                <div>
                    <input
                        class="input"
                        type="text"
                        v-model.number="accomodation.askedAmount"
                        v-on:focus="$event.target.select()"
                    />
                </div>


                <div><button
                    v-if="!isOnlyAsked"
                    class="button"
                    title="Copy value"
                    v-on:click="setValues(accomodation)"
                >
                    <i class="material-icons">check_circle</i>
                    <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    <i class="material-icons">help</i>
                </button></div>
            </div>


            <div class="row extra-people has-border-top" v-if="hasExtra && !isInPackage">
                <div>
                    <i class="material-icons">person_add</i>
                    <input
                        class="input"
                        type="text"
                        v-model="accomodations[standardIndex].extraDescription"
                    />
                    <button class="btn-delete" title="Löschen" v-on:click="deleteExtra"><i class="material-icons">delete</i></button>
                </div>

                <div>
                    <input
                        v-if="!isOnlyAsked"
                        class="input"
                        type="text"
                        v-model.number="accomodations[standardIndex].extraReservedAmount"
                        v-on:focus="$event.target.select()"
                    />
                </div>
                <div>
                    <input
                        class="input"
                        type="text"
                        v-model.number="accomodations[standardIndex].extraAskedAmount"
                        v-on:focus="$event.target.select()"
                    />
                </div>

                <div><button
                    v-if="!isOnlyAsked"
                    class="button"
                    title="Copy value"
                    v-on:click="setExtraValues"
                >
                    <i class="material-icons">check_circle</i>
                    <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    <i class="material-icons">help</i>
                </button></div>
            </div>


            <div class="row has-border-top">
                <div>
                    <b>
                        Gesamt
                    </b>
                </div>

                <div><span v-if="!isOnlyAsked">{{ totalReserved }} ({{ accomodations[standardIndex].extraReservedAmount }})</span></div>
                <div><span>{{ totalAsk }} <template v-if="accomodations[standardIndex].extraAskedAmount">({{ accomodations[standardIndex].extraAskedAmount }})</template></span></div>
                <div></div>
            </div>
        </main>
    </div>
</template>



<script>
import { notifySuccess, notifyError } from '@components/Notification';
import mixins from './mixins.js';


export default {
    mixins: [mixins],


    props: {
        nrOfPersons: { type: Number, required: true },
        day: {type: Boolean, required: true},
        category: {type: String, required: true}
    },


    data: function () {
        return {
            // For extra persons
            hasExtra:  false
        }
    },


    computed: {
        standardIndex: function () { return this.accomodations.findIndex(x => x.type.type === 'second'); },

        sortedAccomodations: function() {
            const types = ['second', 'first']
            let accommodations = [...this.accomodations]
                .sort((a, b) => types.indexOf(a.type.type) < types.indexOf(b.type.type) ? -1 : 1)
            if(!this.category || this.category === 'first_and_second') {
                return accommodations;
            }
            return accommodations.filter(item => item.type.type === this.category);
        }
    },


    methods: {
        addExtraPeople: function () {
            if (this.standardIndex !== -1) {
                this.hasExtra = true;

            } else {
                notifyError('We don\'t have any contingent');
            }
        },


        deleteExtra: function () {
            this.accomodations[this.standardIndex].extraDescription    = null;
            this.accomodations[this.standardIndex].extraReservedAmount = 0;
            this.accomodations[this.standardIndex].extraAskedAmount    = 0;
            this.hasExtra = false;
        },


        arrangeCabins: function () {
            //moving 'outside' first (for the case we have more than 2 items)
            this.accomodations = [
                    this.accomodations.filter(x => x.type.type === 'first')[0],
                   ...this.accomodations.filter(x => x.type.type !== 'first')
                ];

            // moving 'inside' first
            this.accomodations = [
                    this.accomodations.filter(x => x.type.type === 'second')[0],
                    ...this.accomodations.filter(x => x.type.type !== 'second')
                ];
        },


        setExtraValues: function () {
            this.accomodations[this.standardIndex].extraReservedAmount = this.accomodations[this.standardIndex].extraAskedAmount
            this.accomodations[this.standardIndex].extraAskedAmount    = 0;
        },
    },


    created: function () {
        this.arrangeCabins();

        // Setting the initial state
        let extraPerson = this.accomodations[this.standardIndex];
        this.hasExtra = !!(extraPerson && (extraPerson.extraAskedAmount || extraPerson.extraReservedAmount));
    }
}
</script>
