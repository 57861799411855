<template>
    <modal-form
        editTitle="Bankverbindung editieren"
        createTitle="Neue Bankverbindung"
    >
        <table>
            <tr>
                <th><label for="iban" class="label">IBAN:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="iban" class="input" v-model="form.iban">
                    </div>
                </td>
            </tr>

            <tr>
                <th><label for="bic" class="label">BIC:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="bic" class="input" v-model="form.bic">
                    </div>
                </td>
            </tr>

            <tr>
                <th><label for="bank" class="label">Bank:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="bank" class="input" v-model="form.bank">
                    </div>
                </td>
            </tr>

            <tr>
                <th><label for="accountHolder" class="label">Kontoinhaber:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="accountHolder" class="input" v-model="form.accountHolder">
                    </div>
                </td>
            </tr>

            <tr>
                <th class="is-top"><label for="notes" class="label">Zusätzliche Notizen:</label></th>
                <td>
                    <div class="control">
                        <tip-tap v-model="form.notes" />
                    </div>
                </td>
            </tr>

            <tr>
                <th><label for="primaryAccount" class="label">Primärkonto:</label></th>
                <td>
                   <div class="control">
                       <label class="radio">
                            <input type="radio" v-bind:value="true" v-model="form.primaryAccount">ja
                       </label>
                       <label class="radio">
                            <input type="radio" v-bind:value="false" v-model="form.primaryAccount">nein
                       </label>
                    </div>
                </td>
            </tr>
        </table>
    </modal-form>
</template>

<script>
import { TipTap } from '@components/form'
import TabForm from '../form';

export default {
    mixins: [TabForm],

    form: {
        iban: '',
        bic: '',
        bank: '',
        accountHolder: '',
        notes: '',
        primaryAccount: false,
    },

    property: 'bankAccounts',

    components: {
        TipTap
    }
}
</script>
