var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "span",
      { staticClass: "badges" },
      [
        _vm._l(_vm.icons, function (icon) {
          return _c("span", { key: icon, staticClass: "badge is-icon" }, [
            _c("i", { staticClass: "material-icons" }, [
              _vm._v("\n                " + _vm._s(icon) + "\n            "),
            ]),
          ])
        }),
        _vm._v(" "),
        _vm._l(_vm.badges, function (badge) {
          return _c(
            "span",
            { key: badge, staticClass: "badge", class: `is-${badge}` },
            [_vm._v(_vm._s(_vm.badgeName(badge)))]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }