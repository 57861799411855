var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isChecked
    ? _c(
        "i",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: {
                delay: { show: 1000 },
                content: _vm.fullYear(_vm.value),
              },
              expression: "{delay: {show: 1000}, content: fullYear(value)}",
            },
          ],
          staticClass: "material-icons",
        },
        [_vm._v("check")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }