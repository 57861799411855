<template>
    <row v-if="!hide">
        <template slot="type">
            <template v-if="persons > 2">
                {{persons}} x <i class="material-icons">directions_boat</i>
            </template>
            <template v-else>
                <i class="material-icons" v-for="n in persons" v-bind:key="n">directions_boat</i>
            </template>
        </template>
        <template slot="name">{{name}}</template>

        <template slot="marginTax">
            <margin-tax v-model="marginTaxEnabled" :tax="marginTax" :margin="grossMargin" />
        </template>

        <template slot="buyPriceCalculation" v-if="showExchangeRates">
            <input-price
                v-model="buyPriceInCalculationCurrency"
            />
        </template>
        <template slot="buyPrice">
            <input-price
                v-model="buyPrice"
                :is-blue="hasTwoCurrencies"
            />
        </template>

        <template slot="sellPriceCalculation" v-if="showExchangeRates">
            <input-price
                v-model="sellPriceInCalculationCurrency"
            />
        </template>
        <template slot="sellPrice">
            <input-price
                v-model="sellPrice"
                :is-blue="hasTwoCurrencies"
            />
        </template>

        <template slot="profitCalculation" v-if="showExchangeRates">
            <input-price
                v-model="profitInCalculationCurrency"
            />
        </template>
        <template slot="profit">
            <input-price
                v-model="profit"
                :is-blue="hasTwoCurrencies"
            />
        </template>

        <template slot="margin">
            <input-price v-model="margin" />
        </template>
    </row>
</template>

<script>
import price from './price' ;
import { contingentRoomTypes } from '@utilities/variables';

export default {
    mixins: [ price ],

    computed: {
        orderFerryCabin: function () { return this.price.orderFerryCabin },
        persons: function () { return this.orderFerryCabin.type.persons },

        name: function () {
            const { type, persons } = this.orderFerryCabin.type;



            return this.supplementPrices ? `Außenkabinenzuschlag in der ${persons === 2 ? 'Doppelkabine' : 'Einzelkabine'}` :
                `${persons === 2 ? 'Doppelzimmer mit Außenkabine' : 'Einzelzimmer mit Außenkabine'}`
        }
    },

    methods: {
        updatePrice: function (obj = {}) {
            this.$store.commit('updatePrice', {
                contingentPrices: {
                    id: this.price.id,
                    ...obj
                }
            })
        }
    }
}
</script>
