var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-addition" }, [
    _c("div", { staticClass: "tab-addition__header" }, [
      _vm.tabs.length > 0
        ? _c(
            "header",
            _vm._l(_vm.tabs, function (key) {
              return _c(
                "button",
                {
                  key: key,
                  class: { "is-active": key === _vm.activeKey },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      _vm.activeKey = key
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.tabName(key)) +
                      "\n            "
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("keep-alive", [_c(_vm.activeKey, { tag: "component" })], 1),
        _vm._v(" "),
        !_vm.tabs.length
          ? _c("div", { staticClass: "has-text-centered" }, [
              _vm._v("Keine weiteren Informationen"),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }