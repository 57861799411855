<template>
    <popover trigger="hover">
        <template v-for="order in valueArray">
            <a class="tag is-white px-1" v-bind:href="`/orders/${order.id}`" target="_blank">
                {{ order.id }}
            </a>
        </template>

        <template slot="popover">
            <div class="todo__popover">
                <template v-if="valueArray.length > 1">
                    <ul class="icon-list">
                        <li v-for="(order, index) in valueArray">
                            <i class="material-icons">folder</i>
                            <span>{{getOrderString(order)}}</span>
                        </li>
                    </ul>
                </template>
                <template v-else>
                    <template v-for="order in valueArray">
                        <ul class="icon-list">
                            <li v-if="order.client && order.client.shortName">
                                <i class="material-icons">person</i>
                                <span>{{ order.client.shortName }}</span>
                            </li>
                            <li>
                                <i class="material-icons">date_range</i>
                                <span>{{ order.startAt | fullYear }} - {{ order.endAt | fullYear }}</span>
                            </li>
                            <li v-if="order.trip && order.trip.destinations">
                                <i class="material-icons">flag</i>
                                <span>{{ tripDestination(order)  }}</span>
                            </li>
                            <li v-if="order.trip && order.trip.name">
                                <i class="material-icons">sync</i>
                                <span>{{ order.trip.name }}</span>
                            </li>
                        </ul>
                    </template>
                </template>
            </div>
        </template>
    </popover>
</template>

<script>
import Cell from './cell';
import {isArray} from "lodash";
import Template from "@orders/placeholders/modal/prices/takeoverPrices/Template";
import {fullYear} from "@utilities/functions";
import Popover from "@components/Popover";

export default {
    mixins: [ Cell ],

    props: {
        value: {
            type: [Array, Object],
        },
    },

    computed: {
        isArray: function () {
            return isArray(this.value);
        },

        valueArray: function () {
            return this.isArray ? this.value : [this.value];
        },
    },

    methods: {
        tripDestination: function (order) {
            return order.trip.destinations.map(({name}) => name).join(', ');
        },
        getOrderString: function (order) {
            let array = [];
            array.push(order.id);
            if(order.client && order.client.shortName) {
                array.push(order.client.shortName);
            }
            array.push(fullYear(order.startAt) + '-' + fullYear(order.endAt));
            if(order.trip && order.trip.destinations) {
                array.push(this.tripDestination(order));
                array.push(order.trip.name);
            }
            return array.join(' | ');
        }
    },

    filters: {
        fullYear
    },

    components: {
        Popover
    }
}
</script>

