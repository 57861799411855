<template>
    <div>
        <div class="bp-box is-info">
            <header><i class="material-icons">info</i> Informationen zur Anfrage</header>

            <main>
                <div class="is-one-row" v-if="!request.otherService || editMode">

                        <button
                            v-bind:class="['btn-single-toggle',  onlyFromOrder ? '' : 'is-active']"
                            v-on:click="onlyFromOrder = !onlyFromOrder"
                            v-bind:title="onlyFromOrder ? 'Suche ist auf obigen Ort eingeschränkt' : 'Suche ist nicht eingeschränkt'"
                            style="border: 1px solid #dddddd; margin-right: 5px; min-width: 22px; min-height: 22px;"
                        ><i class="material-icons" style="margin: 0">flag</i></button>

                        <div>
                            <other-service-select
                                style="width: 400px"
                                class="has-margin-left is-shorter"
                                v-model="request.otherService"
                                v-bind:otherServiceTypeID="placeholderInfo.otherServiceType.id"
                                v-bind:placeholder-info="onlyFromOrder ? this.placeholderInfo : {area: null, place: null, destination: null}"
                            ></other-service-select>
                        </div>


                        <agency-select
                            multiselect-class="is-shorter"
                            v-if="editMode"
                            v-model="request.agency"
                            v-bind:provider = "null"
                            :prefill="true"
                            text            = "Agentur"
                        ></agency-select>
                </div>
                <div class="is-one-row">

                    <div>
                        <i class="material-icons">import_contacts</i> Status:
                        <div class="select">
                            <select v-model="request.requestStatus.status">
                                <template v-for="status in requestStatuses">
                                    <option v-bind:value="status.key">{{ status.value }}</option>
                                </template>
                            </select>
                        </div>
                    </div>

                    <div>
                        <i class="material-icons">notifications_none</i> Termine:
                        <multiselect
                            class="is-shorter"
                            v-if="request.todos.length > 1"
                            v-model="request.optionTodo"
                            :options="request.todos"
                            label="name"
                            track-by="id"
                        />
                        <datepicker
                            v-bind:inputClass="['is-date']"
                            v-bind:config="{
                            dateFormat: 'd.m.Y H:i:S',
                            wrap: true,
                            parseDate:  function (dateStr) { return moment(dateStr, 'DD.MM.YYYY hh:mm:ss').toDate(); },
                            formatDate: function (dateObj) { return moment(dateObj).format('DD.MM.YYYY hh:mm:ss'); },
                            allowInput: true,
                            altInput: true,
                        }"
                            v-model="deadline"
                            placeholder="Deadline"
                        />
                    </div>

                    <div >
                        <i class="material-icons">format_list_numbered</i> Reservierungs-Nr:
                        <input type="text" class="input" v-model="request.optionNr" />
                    </div>

                    <div>
                        <i class="material-icons">contact_phone</i> Ansprechpartner:
                        <multiselect
                            class="is-shorter"
                            style="min-width: 180px"
                            v-model="request.requestContact"
                            v-bind:options="this.request.agency ? this.agencyContactOptions : this.providerContactOptions"
                            track-by="id"
                            :custom-label="contact => `${contact.fullName}`"
                            :to-be-copied="false"
                        >
                        </multiselect>
                    </div>
                </div>

                <div class="is-last-row" v-if="showAssignedContactAddress">
                    <div>
                        <i class="material-icons">list</i> Für Kunde sichtbar:
                        <button
                            title="Adresse anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('address')}"
                            @click="setVisibleContactDetails('address')"
                        ><i class="material-icons">home</i></button>
                        <button
                            title="Speziellen Kontakt anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('contact')}"
                            @click="setVisibleContactDetails('contact')"
                        ><i class="material-icons">contact_phone</i></button>
                    </div>

                    <div v-if="request.visibleContactDetails.includes('address')">
                        <i class="material-icons">home</i> Adresse
                        <multiselect
                            class="is-shorter"
                            style="min-width: 150px"
                            v-model="request.assignedAddress"
                            v-bind:options="providerAddressOptions"
                            track-by="id"
                            :custom-label="address => (address.title ? address.title + ': ' + address.streetAddress : address.streetAddress).substring(0,50)"
                            :to-be-copied="false"
                            placeholder="Keine Adresse"

                        >
                        </multiselect>
                    </div>

                    <div v-if="request.visibleContactDetails.includes('contact')">
                        <i class="material-icons">contact_phone</i>
                            Kontakt:
                        <multiselect
                            class="is-shorter"
                            style="min-width: 180px"
                            v-model="request.assignedContact"
                            v-bind:options="providerContactOptions.concat(agencyContactOptions).filter(item => item.role !== 'general_contact')"
                            track-by="id"
                            :custom-label="contact => contact.fullName ? `${contact.fullName}` : 'Allgemein'"
                            :to-be-copied="false"
                        >
                        </multiselect>
                    </div>

                    <div v-if="request.visibleContactDetails.includes('address') || request.visibleContactDetails.includes('contact')">
                        <i class="material-icons">list</i> Kontaktdetails sichtbar:
                        <button
                            title="Festnetz anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('phoneFixed')}"
                            @click="setVisibleContactDetails('phoneFixed')"
                        ><i class="material-icons">phone</i></button>
                        <button
                            title="Mobile anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('phoneMobile')}"
                            @click="setVisibleContactDetails('phoneMobile')"
                        ><i class="material-icons">smartphone</i></button>
                    </div>
                </div>







            </main>
        </div>


        <div class="columns">
            <div class="column">
                <div class="bp-box is-tickets">
                    <header>
                        <div>
                            <other-service-icons v-bind:type="request.otherServiceType.category"></other-service-icons> Zusatzleistung
                        </div>
                        <div>
                            <i class="material-icons">check_circle</i>
                            <i class="material-icons">help</i>
                        </div>
                        <div><button v-if="!request.package" title="Extrabuchung für Reisebegleitung" v-on:click="addExtra"><i class="material-icons">person_add</i></button></div>
                    </header>

                    <main>
                        <div>
                            <div>
                                <i class="material-icons">person</i> Teilnehmer
                            </div>
                            <div>
                                <input
                                    class="input"
                                    type="text"
                                    v-model.number="request.contingent.reservedAmount"
                                    v-on:focus="$event.target.select()"
                                />
                                <input
                                    class="input"
                                    type="text"
                                    v-model.number="request.contingent.askedAmount"
                                    v-on:focus="$event.target.select()"
                                />
                            </div>
                            <div><button
                                class="button"
                                title="Copy value"
                                v-on:click="copContingent"
                            >
                                <i class="material-icons">check_circle</i>
                                <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                                <i class="material-icons">help</i>
                            </button></div>
                        </div>

                        <div class="extra-people" v-if="hasExtra && !request.package">
                            <div>
                                <i class="material-icons">person_add</i>
                                <input
                                    class="input"
                                    type="text"
                                    v-model="request.contingent.extraDescription"
                                />
                                <button class="btn-delete" title="Löschen" v-on:click="deleteExtra" tabindex="-1"><i class="material-icons">delete</i></button>
                            </div>
                            <div>
                                <div class="control"><input
                                    class="input"
                                    type="text"
                                    v-model.number="request.contingent.extraReservedAmount"
                                    v-on:focus="$event.target.select()"
                                /></div>
                                <div class="control"><input
                                    class="input"
                                    type="text"
                                    v-model.number="request.contingent.extraAskedAmount"
                                    v-on:focus="$event.target.select()"
                                /></div>
                            </div>
                            <div><button
                                class="button"
                                title="Copy value"
                                v-on:click="copyExtraContingent"
                            >
                                <i class="material-icons">check_circle</i>
                                <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                                <i class="material-icons">help</i>
                            </button></div>
                        </div>
                    </main>
                </div>
            </div>
        </div>


        <div class="columns">
            <div class="column is-one-third">
                <tip-tap v-model="request.notes" />
            </div>
            <div class="column">
                <item-prices
                    :is-package="true"
                    v-if="request.package"
                    v-bind:isWithoutPrice="false"
                    v-model="request.package.itemPrices"
                    v-bind:provider="provider"
                ></item-prices>
                <item-prices
                    v-else
                    v-bind:isWithoutPrice="false"
                    v-model="request.itemPrices"
                    v-bind:provider="provider"
                ></item-prices>
            </div>
        </div>


        <p class="explanation">
            <i class="material-icons">check_circle</i> = bestätigtes Kontingent
            <i class="material-icons">help</i> = angefragtes Kontingent
            <i class="material-icons">person_add</i> = Extrazimmer für Busfahrer, Reiseleiter, etc.
        </p>
    </div>
</template>



<script>
import get                from 'lodash/get';
import OtherServiceIcons  from '@components/OtherServiceIcons';
import OtherServiceSelect from '@orders/placeholders/components/forms/OtherServiceSelect';
import mixins             from './mixins.js';


export default {
    mixins: [mixins],


    props: {
        placeholderInfo: { type: Object, required: true }
    },


    data: function () {
        return {
            hasExtra: false
        };
    },

    computed: {
        provider: function () { return this.request.otherService },
        providerContacts: function () { return get(this.request, 'otherService.provider.contacts', []); }
    },


    methods: {
        addExtra: function () {
            this.request.contingent.extraDescription    = '';
            this.request.contingent.extraReservedAmount = 0;
            this.request.contingent.extraAskedAmount    = 0;
            this.hasExtra = true;
        },


        deleteExtra: function () {
            this.request.contingent.extraDescription    = null;
            this.request.contingent.extraReservedAmount = null;
            this.request.contingent.extraAskedAmount    = null;
            this.hasExtra = false;
        },


        copContingent: function () {
            this.request.contingent.reservedAmount = this.request.contingent.askedAmount;
            this.request.contingent.askedAmount    = 0;
        },


        copyExtraContingent: function () {
            this.request.contingent.extraReservedAmount = this.request.contingent.extraAskedAmount;
            this.request.contingent.extraAskedAmount    = 0;
        }
    },


    created: function () {
        this.hasExtra = !!(this.request.contingent.extraAskedAmount ||
            this.request.contingent.extraReservedAmount);
    },
    mounted: function() {
        this.fetchContactOptions();
    },

    components: {
        OtherServiceIcons,
        OtherServiceSelect
    },


    watch: {
        // Fixing empty strins to 0 (zero)
        'request.contingent.askedAmount': function () {
            if (this.request.contingent.askedAmount === '') {
                this.request.contingent.askedAmount = 0;
            }
        },


        // Fixing empty strins to 0 (zero)
        'request.contingent.reservedAmount': function () {
            if (this.request.contingent.reservedAmount === '') {
                this.request.contingent.reservedAmount = 0;
            }
        },


        // Fixing empty strins to 0 (zero)
        'request.contingent.extraAskedAmount': function () {
            if (this.request.contingent.extraAskedAmount === '') {
                this.request.contingent.extraAskedAmount = 0;
            }
        },


        // Fixing empty strins to 0 (zero)
        'request.contingent.extraReservedAmount': function () {
            if (this.request.contingent.extraReservedAmount === '') {
                this.request.contingent.extraReservedAmount = 0;
            }
        },
        'request.otherSe': function () {
            if (this.request.contingent.extraReservedAmount === '') {
                this.request.contingent.extraReservedAmount = 0;
            }
        },
    }
}
</script>
