var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.groupedPrices.length > 0
    ? _c("div", { staticClass: "row is-darken" }, [
        _c("div", { staticClass: "cell" }, [
          _c("strong", [_vm._v("- davon " + _vm._s(_vm.sortGroup.value))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell is-price-cell is-small" }, [
          _c("span", { staticClass: "is-currency" }, [
            _vm._v(_vm._s(_vm.orderCurrency)),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cell is-price-cell" },
          [
            _c("input-price", {
              attrs: { readOnly: "", value: _vm.summary.group },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cell is-price-cell" },
          [
            _c("input-price", {
              attrs: { readOnly: "", value: _vm.summary.person },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cell is-price-cell is-margin-cell" },
          [
            _c("input-price", {
              attrs: { readOnly: "", value: _vm.summary.single },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "cell is-actions is-small-cell" }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }