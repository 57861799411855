<template>
    <div class="cell-content is-full is-flex">
        <template v-if="!hasAddress">-</template>
        <template v-else>{{ address.city }}</template>
    </div>
</template>

<script>
import Cell from './cell';

export default {
    mixins: [ Cell ],

    props: {
        value: {
            type: [Array]
        }
    },

    computed: {
        address() {
            return this.value && this.value.length > 0 ? this.value[0] : null
        },

        hasAddress() {
            return !!this.address
        },
    },
}
</script>
