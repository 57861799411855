<template>
    <div class="row is-header is-space-between">
        <div class="level-left">
            <span class="level-item">Zeitraum:</span>
            <strong v-if="section === 'sellingPrices'">{{ label(item) }}</strong>
            <multiselect
                v-else
                :custom-label="label"
                v-bind:options="sellingPrices"
                v-model="sellingPrice"
                :allow-empty="false"
                class="is-very-large level-item"
            />

            <div class="level-item level-left" v-if="section === 'calculation' && options && options.hotelTypes">
                <button class="button level-item"><a :href="'/orders/' + sellingPrice.calculationOrder.id" target="_blank">Reisekonzept</a></button>
                <span class="level-item">Hotelkategorie:</span>
                <multiselect
                    v-if="options.hotelTypes"
                    v-model="hotelType"
                    track-by="name"
                    label="name"
                    v-bind:options="options.hotelTypes"
                    :placeholder="value.hotelTypePlaceholder"
                    class="is-tiny level-item "
                />
                <button class="button level-item" v-on:click.prevent.stop="changeHotelType" :disabled="!hotelType">ändern</button>
            </div>
        </div>



        <div class="level-left">
            <div class="level-item">
                <toggle-switch :value="section" v-on:input="changeSection">
                    <option value="sellingPrices">Verkaufspreise</option>
                    <option value="calculation">Kalkulation</option>
                </toggle-switch>
            </div>
            <popover
                ref="user"
                class="userPopover"
                v-bind:delay="{show:0, hide: 0}"
                v-on:show="copyYear=null"
            >
                <i class="material-icons is-pointer">content_copy</i>
                <template slot="popover">
                    <div>
                        In folgendes Jahr kopieren:
                    </div>
                    <multiselect
                        v-model="copyYear"
                        v-bind:options="availableYears"
                        :allow-empty="false"
                        v-on:input="$emit('copy', copyYear)"
                    />
                </template>
            </popover>
            <i class="material-icons is-pointer" v-on:click="$emit('reset')">refresh</i>
            <i class="material-icons is-pointer" v-on:click="$emit('edit')">edit</i>
            <i class="material-icons is-pointer" v-on:click="$emit('delete')">delete</i>
        </div>
    </div>
</template>

<script>
import { ToggleSwitch, Multiselect } from '@components/form';
import moment from 'moment';
import Popover         from '@components/Popover';

import { fullYear} from '@utilities/functions'

export default {


    props: {
        item: {
            required: true,
            type: Object,
        },

        section: {
            type: String,
            default: 'sellingPrices'
        },

        value: { // For calculation select
            type: Object,
        },

        sellingPrices: {
            type: Array
        },

        options: {
            type: Object
        }
    },

    data: function() {
        return {
            hotelType: null,
            copyYear: null
        }
    },

    computed: {
        sellingPrice: {
            get: function () {
                return this.value
            },

            set: function (value) {
                this.$emit('input', value)
            }
        },
        availableYears: function() {
            const endYear = parseInt(moment().format('YYYY')) + 2;
            const years = [];
            for(let i = 2019; i <= endYear; i++) {
                years.push(i);
            }
            return years;
        },
    },

    methods: {
        changeSection: function (section) {
            this.$emit('change:section', section)
        },

        label: function (item) {
            let label =  item.seasonTimes.map(time => `${fullYear(time.startAt)} - ${fullYear(time.endAt)}`).join(' | ');
            label += item.subTitle ? ' - ' + item.subTitle : '';
            return label;
            //return `${fullYear(item.startAt)} - ${fullYear(item.endAt)} ${item.subTitle ? `- ${item.subTitle}` : ''}`
        },

        changeHotelType: function () {
            this.$emit('change:hotelType', this.hotelType);
        }
    },

    components: {
        ToggleSwitch,
        Multiselect,
        Popover
    },

    filters: {
        fullYear
    }
}
</script>

