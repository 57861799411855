var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Adresse bearbeiten",
        createTitle: "Neue Adresse hinzufügen",
      },
    },
    [
      _c(
        "table",
        [
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "title" } }, [
                _vm._v("Firma:"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.title,
                      expression: "form.title",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", id: "title" },
                  domProps: { value: _vm.form.title },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "title", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "streetAddress" } },
                [_vm._v("Straße:")]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.streetAddress,
                      expression: "form.streetAddress",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", id: "streetAddress" },
                  domProps: { value: _vm.form.streetAddress },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "streetAddress", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "zip" } }, [
                _vm._v("Postleitzahl:"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.zip,
                      expression: "form.zip",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", id: "zip" },
                  domProps: { value: _vm.form.zip },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "zip", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "city" } }, [
                _vm._v("Stadt:"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.city,
                      expression: "form.city",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", id: "city" },
                  domProps: { value: _vm.form.city },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "city", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "country" } }, [
                _vm._v("Land:"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("multiselect", {
                    attrs: {
                      "track-by": "label",
                      label: "name",
                      options: _vm.countries,
                    },
                    model: {
                      value: _vm.country,
                      callback: function ($$v) {
                        _vm.country = $$v
                      },
                      expression: "country",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "type" } }, [
                _vm._v("Adressart:"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "control" }, [
                _c("div", { staticClass: "select" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.type,
                          expression: "form.type",
                        },
                      ],
                      attrs: { id: "type" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.form,
                            "type",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.options.type, function (option) {
                      return _c(
                        "option",
                        { key: option.key, domProps: { value: option.key } },
                        [_vm._v(_vm._s(option.value))]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.form.type === "port"
            ? _c("tr", [
                _c("th", [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "port" } },
                    [_vm._v("Hafen:")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("async-select", {
                      attrs: {
                        api: "ports",
                        "custom-label": (item) => item.place.name,
                        crudLinkTitle: "Fügen Sie einen neuen Port hinzu",
                      },
                      model: {
                        value: _vm.form.port,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "port", $$v)
                        },
                        expression: "form.port",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.form.type === "airport"
            ? _c("tr", [
                _c("th", [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "airport" } },
                    [_vm._v("Flughafen:")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("async-select", {
                      attrs: {
                        api: "airports",
                        crudLinkTitle: "Fügen Sie einen neuen Flughafen hinzu",
                      },
                      model: {
                        value: _vm.form.airport,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "airport", $$v)
                        },
                        expression: "form.airport",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.editMode && _vm.hasContacts
            ? [
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("h2", [_vm._v("Kontaktdaten")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "email" } },
                      [_vm._v("Email:")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.contact.email,
                            expression: "contact.email",
                          },
                        ],
                        staticClass: "input",
                        attrs: { type: "text", id: "email" },
                        domProps: { value: _vm.contact.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.contact, "email", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "phoneFixed" } },
                      [_vm._v("Telefon:")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.contact.phoneFixed,
                            expression: "contact.phoneFixed",
                          },
                        ],
                        staticClass: "input",
                        attrs: { type: "text", id: "phoneFixed" },
                        domProps: { value: _vm.contact.phoneFixed },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.contact,
                              "phoneFixed",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "phoneMobile" } },
                      [_vm._v("Mobil:")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.contact.phoneMobile,
                            expression: "contact.phoneMobile",
                          },
                        ],
                        staticClass: "input",
                        attrs: { type: "text", id: "phoneMobile" },
                        domProps: { value: _vm.contact.phoneMobile },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.contact,
                              "phoneMobile",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "fax" } },
                      [_vm._v("Fax:")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.contact.fax,
                            expression: "contact.fax",
                          },
                        ],
                        staticClass: "input",
                        attrs: { type: "text", id: "fax" },
                        domProps: { value: _vm.contact.fax },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.contact, "fax", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "languages" } },
                      [_vm._v("Sprache:")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c(
                          "multiselect",
                          {
                            attrs: {
                              "track-by": "label",
                              label: "name",
                              options: _vm.languages,
                              multiple: "",
                            },
                            model: {
                              value: _vm.contactLanguages,
                              callback: function ($$v) {
                                _vm.contactLanguages = $$v
                              },
                              expression: "contactLanguages",
                            },
                          },
                          [
                            _c("span", {
                              attrs: { slot: "caret" },
                              slot: "caret",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label", attrs: { for: "" } }, [
                      _vm._v("Primärkontakt:"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "control" }, [
                      _c("label", { staticClass: "radio" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.contact.type,
                              expression: "contact.type",
                            },
                          ],
                          attrs: { type: "radio", value: "primary" },
                          domProps: {
                            checked: _vm._q(_vm.contact.type, "primary"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.contact, "type", "primary")
                            },
                          },
                        }),
                        _vm._v("ja\n                        "),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "radio" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.contact.type,
                              expression: "contact.type",
                            },
                          ],
                          attrs: { type: "radio", value: "0" },
                          domProps: { checked: _vm._q(_vm.contact.type, "0") },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.contact, "type", "0")
                            },
                          },
                        }),
                        _vm._v("nein\n                        "),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }