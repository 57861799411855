<template>
    <div class="new-request">
        <div class="overall-buttons">
            <button class="button is-large" v-on:click="$emit('close')"><i aria-hidden="true" class="fa fa-long-arrow-left"></i> Zurück zur Übersicht</button>
        </div>


        <accordion v-bind:isCollapsible="false">
            <div slot="header">
                <div>
                    <request-score v-if="newRequest.train" v-bind:rating="newRequest.train.buspaketRating" />
                    <i aria-hidden="true" class="fa fa-plus-circle" v-else></i>
                </div>

                <div>
                    LEISTUNGSTRÄGER HINZUFÜGEN

                    <provider-info class="header-extra-info" v-bind:provider="newRequest.train">
                        <template v-if="newRequest.type === 'day'">
                            <div>
                                <i class="material-icons spacing-left">perm_identity</i> Kundenkontingent:
                                {{ $store.state.hotelContingent.info.personsAsked }} Personen
                            </div>
                        </template>
                        <template v-else>
                            <div>
                                <i class="material-icons spacing-left">perm_identity</i> Kundenkontingent:
                                <i class="material-icons no-spacing">local_hotel</i>
                                <i class="material-icons">local_hotel</i>
                                {{ $store.state.hotelContingent.info.doubleRooms.totalAsked }} Doppelzimmer
                            </div>
                            <div>
                                <i class="material-icons">local_hotel</i>
                                {{ $store.state.hotelContingent.info.singleRooms.totalAsked }} Einzelzimmer
                            </div>
                            <div>
                                <i class="material-icons no-spacing">local_hotel</i>
                                <i class="material-icons no-spacing">local_hotel</i>
                                <i class="material-icons">local_hotel</i>
                                {{ $store.state.hotelContingent.info.otherRooms.totalAsked }} Mehrbettzimmer
                            </div>
                        </template>
                    </provider-info>
                </div>

                <div>
                    <label v-on:click.stop>
                        <input type="checkbox" v-model="isMainRequest" />
                        als Leistung festlegen
                    </label>
                </div>
            </div>


            <div slot="content">
                <div class="bp-box is-info">
                    <main>
                        <i class="material-icons">business</i> Zuggesellschaft:

                        <div>
                            <train-select
                                v-model                = "newRequest.train"
                                v-bind:placeholderInfo = "localPlaceholderInfo"
                            ></train-select>
                        </div>


                        <agency-select
                            v-bind:provider = "newRequest.train"
                            text            = "Agentur"
                            v-on:update     = "agency => newRequest.agency = agency"
                            prefill
                        ></agency-select>


                        <div class="is-second-row">

                            <div>
                                <i class="material-icons">format_list_numbered</i> Reservierungs-Nr:
                                <input type="text" class="input" v-model="newRequest.optionNr" />
                            </div>
                        </div>
                    </main>
                </div>


                <div class="columns">
                    <div class="column" v-if="newRequest.type === 'night'">
                        <simple-train-contingent
                            v-if="newRequest.contingent"
                            v-model="newRequest.contingent.doubleCabins"
                            type="train"
                            :day="false"
                            :category="placeholderInfo.category"
                            v-bind:nrOfPersons="2"
                            v-bind:types="trainTypes"
                            v-bind:isOnlyAsked="true"
                        ></simple-train-contingent>
                    </div>

                    <div class="column">
                        <simple-train-contingent
                            v-if="newRequest.contingent"
                            v-model="newRequest.contingent.singleCabins"
                            type="train"
                            :category="placeholderInfo.category"
                            :day="newRequest.type !== 'night'"
                            v-bind:nrOfPersons="1"
                            v-bind:types="trainTypes"
                            v-bind:isOnlyAsked="true"
                        ></simple-train-contingent>
                    </div>

                    <div class="column" v-if="newRequest.type === 'night'">
                        <train-contingent
                            v-if="newRequest.contingent"
                            v-model="newRequest.contingent.otherCabins"
                            type="train"
                            v-bind:types="trainTypes"
                            v-bind:isOnlyAsked="true"
                        ></train-contingent>
                    </div>
                </div>


                <div class="columns">
                    <div class="column is-offset-6">
                        <item-prices
                            v-bind:isWithoutPrice="true"
                            v-model="newRequest.itemPrices"
                            v-bind:provider="provider"
                        ></item-prices>
                    </div>
                </div>
            </div>
        </accordion>


        <button class="button is-large" v-on:click="$emit('close')"><i class="material-icons">close</i> Abbrechen</button>

        <button
            class="button is-primary is-large"
            v-bind:class="{ 'is-loading': isLoading }"
            v-on:click="saveNewRequest"
        ><i class="material-icons">check</i> speichern und zur Email</button>

        <button
            class="button is-primary is-large"
            v-bind:class="{ 'is-loading': isLoading }"
            v-on:click="saveNewRequest('close')"
        ><i class="material-icons">keyboard_arrow_right</i> speichern und schließen</button>


        <confirmation
            v-bind:isVisible="isVisibleConfirmation"
            is-local
            text="Soll die Beschreibung der Anfrage in den Platzhalter kopiert werden?"
            v-on:input="value => $emit('confirm', value)"
        ></confirmation>
    </div>
</template>



<script>
import { prepareForBackend } from '@utilities/functions';
import AsyncSelect           from '@components/form/AsyncSelect';
import { notifyError }       from '@components/Notification';
import { fullYear }          from '@utilities/functions';
import TrainContingent       from '../components/contingent/Train';
import SimpleTrainContingent from '../components/contingent/SimpleTrain';
import mixins                from './mixins';
import TrainSelect from "@orders/placeholders/components/forms/TrainSelect";


export default {
    mixins: [mixins],


    computed: {
        localPlaceholderInfo: function () {
            return {
                startTrainStation: this.newRequest.startTrainStation,
                endTrainStation:   this.newRequest.endTrainStation,
                type:      this.placeholderInfo.type,
                trainType: this.placeholderInfo.trainType
            };
        },


        peopleContingent: function () { return this.$store.state.hotelContingent.info.personsAsked; },

        trainTypes:       function () { return this.$store.state.optionsTrainCabinTypes; },
        routeTypes:       function () { return this.$store.state.options.trainRoute; },
        provider:         function () { return this.newRequest.train ? this.newRequest.train : null; }
    },


    data: function () {
        return {
            apiEndpoint: 'train_requests',
            hasExtra: false
        };
    },


    methods: {
        saveNewRequest: function (close = false) {
            if (this.newRequest.train === null && this.newRequest.agency === null) {
                notifyError('Sie müssen einen Anbieter oder eine Agentur auswählen!');
                return false;
            }

            let copyNewRequest = JSON.parse(JSON.stringify(this.newRequest));

            // Transform 'kein Provider' => null OR train to its reference
            copyNewRequest.train = copyNewRequest.train && copyNewRequest.train.id ? '/api/trains/' + copyNewRequest.train.id : null;

            // Preparing for backend use
            copyNewRequest = prepareForBackend(copyNewRequest);

            // Remove contingent reference and id from rooms
            for (let cabin of copyNewRequest.contingent.doubleCabins) { delete cabin.id; delete cabin.contingent; }
            for (let cabin of copyNewRequest.contingent.singleCabins) { delete cabin.id; delete cabin.contingent; }
            for (let cabin of copyNewRequest.contingent.otherCabins)  { delete cabin.id; delete cabin.contingent; }

            this.isLoading = true;

            // API call
            this.addRequest(copyNewRequest, close);
        },


        arrangeCabins: function (array) {
            // moving 'outside' first (for the case we have more than 2 items)
            let localArray = [array.filter(x => x.type.type === 'first')[0], ...array.filter(x => x.type.type !== 'first')];

            // moving 'inside' first
            return [localArray.filter(x => x.type.type === 'second')[0], ...localArray.filter(x => x.type.type !== 'second')]
        }
    },


    created: function () {
        let contingent = JSON.parse(JSON.stringify(this.$store.state.hotelContingent));

        if(this.newRequest.type === 'night'){
            this.newRequest.contingent.doubleCabins.find(cabin => cabin.type.type === 'second').askedAmount = contingent.info.doubleRooms.totalAsked;
            this.newRequest.contingent.singleCabins.find(cabin => cabin.type.type === 'second').askedAmount = contingent.info.singleRooms.totalAsked;

            if(contingent.info.tripleRooms.totalAsked > 0) {
                this.newRequest.contingent.otherCabins.push({
                    askedAmount: contingent.info.tripleRooms.totalAsked,
                    type: {
                        persons: 3,
                        type: 'second'
                    }
                })
            }
            if(contingent.info.quadrupleRooms.totalAsked > 0) {
                this.newRequest.contingent.otherCabins.push({
                    askedAmount: contingent.info.quadrupleRooms.totalAsked,
                    type: {
                        persons: 4,
                        type: 'second'
                    }
                })
            }
        } else {
            this.newRequest.contingent.singleCabins.find(cabin => cabin.type.type === 'second').askedAmount = contingent.info.personsAsked;

        }



        this.newRequest.startTrainStation = this.placeholderInfo.startTrainStation;
        this.newRequest.endTrainStation   = this.placeholderInfo.endTrainStation;
    },


    watch: {
        'newRequest.train': function () {
            if (this.newRequest.train) {


                // If we have a ferry setting the agency to 'direkt'
                this.newRequest.agency = null;


            }
        }
    },


    components: {
        TrainSelect,
        AsyncSelect,
        TrainContingent,
        SimpleTrainContingent,
    }
}
</script>
