<template>
    <div>
        <div>
            <h1>Kundenprofil</h1>
            <table-info />
        </div>
        <div class="mt-6">
            <client-visit />
        </div>
    </div>
</template>

<script>
import TableInfo from './TableInfos'
import ClientVisit from './ClientVisits'
export default {
    components: {TableInfo, ClientVisit },
}
</script>
