var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c("a", { attrs: { href: `mailto:${_vm.value}` } }, [
        _vm._v(_vm._s(_vm.value)),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }