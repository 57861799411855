<template>
    <div class="is-white">
        <div class="row">
            <div class="cell is-double" v-if="priceType === 'hotel'">{{roomType}}</div>
            <div class="cell is-double" v-if="(priceType === 'ferry') && !isDay">{{cabinType}}</div>
            <div class="cell is-double" v-if="(priceType === 'ferry') && isDay">{{pricePriceType}}</div>
            <div class="cell is-double" v-if="(priceType === 'train') && !isDay">{{trainCabinType}}</div>
            <div class="cell is-double" v-if="(priceType === 'train') && isDay">{{dayTrainCabinType}}</div>
            <div class="cell is-double" v-if="priceType === 'other' && priceOtherPriceType">{{priceOtherPriceType}}</div>
            <div class="cell">{{price.price.amount | priceView}} {{price.price.currency}}</div>
        </div>
    </div>
</template>

<script>
import has from 'lodash/has';
import { fullYear, priceView, daysToNumbers } from '@utilities/functions';

export default {
    props: {
        price: {
            type: Object,
            required: true
        },

        priceType: {
            type: String,
            required: true
        },

        isDay: {
            type: Boolean,
            required: false
        },

        options: {
            type: Object,
            required: true
        }
    },

    computed: {

        roomTypes: function () {
            if (has(this.options, 'roomTypePrice')) {
                return this.options.roomTypePrice
            } else if (has(this.options, 'roomTypes')) {
                return this.options.roomTypes
            } else if (has(this.options, 'roomType')) {
                return this.options.roomType
            } else {
                return []
            }
        },

        roomType: function () {
            const roomType = this.roomTypes.find(({persons, type}) => {
                return persons === this.price.roomType.persons && type === this.price.roomType.type;
            })

            return !!roomType ? roomType.name : '';
        },

        cabinTypes: function () {
            if (has(this.options, 'cabinTypes')) {
                return this.options.cabinTypes
            } else if (has(this.options, 'cabinType')) {
                return this.options.cabinType
            } else {
                return []
            }
        },

        trainCabinTypes: function () {
            if(has(this.options, 'trainCabinTypes')){
                return this.options.trainCabinTypes;
            }
            return []
        },

        dayTrainCabinTypes: function () {
            if(has(this.options, 'dayTrainCabinTypes')){
                return this.options.dayTrainCabinTypes;
            }
            return []
        },

        priceTypes: function () {
            if(has(this.options, 'priceTypes')){
                return this.options.priceTypes;
            }
            return []
        },

        otherPriceTypes: function () {
            let types = [];
            types['buy_price'] = 'Einkaufspreis';
            types['sell_price'] = 'Verkaufspreis';
            return types
        },

        cabinType: function () {
            const cabinType = this.cabinTypes.find(({persons, type}) => {
                return this.price.cabinType && persons === this.price.cabinType.persons && type === this.price.cabinType.type;
            });
            if (cabinType) {
                return cabinType.name;
            }
            const priceType = this.priceTypes.find(item => {
                return item.key === this.price.priceType });
            if (priceType) {
                return priceType.value
            } else {
                return '';
            }
        },

        trainCabinType: function () {
            const cabinType = this.trainCabinTypes.find(({persons, type}) => {
                return persons === this.price.cabinType.persons && type === this.price.cabinType.type;
            })

            return !!cabinType ? cabinType.name : '';
        },

        dayTrainCabinType: function () {
            const cabinType = this.dayTrainCabinTypes.find(({persons, type}) => {
                return persons === this.price.cabinType.persons && type === this.price.cabinType.type;
            })

            return !!cabinType ? cabinType.name : '';
        },

        pricePriceType: function () {
            const pricePriceType = this.priceTypes.find(({key}) => {
                return key === this.price.priceType;
            })
            return !!pricePriceType ? pricePriceType.value : '';
        },
        priceOtherPriceType: function () {
            return this.otherPriceTypes[this.price.priceType] ? this.otherPriceTypes[this.price.priceType] : ''
        },
    },

    filters: {
        fullYear,
        priceView
    },
}
</script>
