var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bp-row-form" }, [
    _c("div", [
      _c("div", { staticClass: "bp-row-form__title" }, [_vm._v("Fährstrecke")]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "i",
            { staticClass: "material-icons", attrs: { title: "Fähre" } },
            [_vm._v("directions_boat")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "buttons has-addons toggle-switch has-margin-right",
            },
            [
              _c(
                "button",
                {
                  staticClass: "button is-large",
                  class: { "is-primary": _vm.copyPlaceholder.type === "night" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.copyPlaceholder.type = "night"
                    },
                  },
                },
                [_vm._v("Nacht")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button is-large",
                  class: { "is-primary": _vm.copyPlaceholder.type === "day" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.copyPlaceholder.type = "day"
                    },
                  },
                },
                [_vm._v("Tag")]
              ),
            ]
          ),
          _vm._v(" "),
          _c("async-select", {
            ref: "startPort",
            staticClass: "has-margin-right",
            attrs: {
              api: "ports",
              "custom-label": (item) => item.place.name,
              crudLinkTitle: "Fügen Sie einen neuen Port hinzu",
              clearable: false,
              "allow-empty": false,
              placeholder: "Abfahrtshafen",
            },
            model: {
              value: _vm.copyPlaceholder.startPort,
              callback: function ($$v) {
                _vm.$set(_vm.copyPlaceholder, "startPort", $$v)
              },
              expression: "copyPlaceholder.startPort",
            },
          }),
          _vm._v(" "),
          _c("div", [
            _c(
              "button",
              {
                staticClass: "btn-single-toggle has-margin-right",
                class: [_vm.endPortRestriction ? "" : "is-active"],
                attrs: {
                  title:
                    "Nur bestehende Fährverbindungen suchen/alle Häfen suchen",
                },
                on: {
                  click: function ($event) {
                    _vm.endPortRestriction = !_vm.endPortRestriction
                  },
                },
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    staticStyle: { "vertical-align": "-2px" },
                  },
                  [_vm._v("timeline")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("async-select", {
            attrs: {
              api: "ports",
              "custom-label": (item) => item.place.name,
              crudLinkTitle: "Fügen Sie einen neuen Port hinzu",
              clearable: false,
              "allow-empty": false,
              params:
                _vm.endPortRestriction && _vm.copyPlaceholder.startPort
                  ? {
                      _groups: ["port:service_day_time"],
                      "endRoutes.startPort.id":
                        _vm.copyPlaceholder.startPort.id,
                    }
                  : { _groups: ["port:service_day_time"] },
              placeholder: "Ankunftshafen",
            },
            model: {
              value: _vm.copyPlaceholder.endPort,
              callback: function ($$v) {
                _vm.$set(_vm.copyPlaceholder, "endPort", $$v)
              },
              expression: "copyPlaceholder.endPort",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "is-160 has-time" }, [
      _c("div", { staticClass: "bp-row-form__title" }, [_vm._v("Zeitangaben")]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.serviceDayTimes.length > 0
            ? _c(
                "button",
                {
                  staticClass: "btn-single-toggle has-margin-right",
                  class: [_vm.onlyTime ? "" : "is-active"],
                  attrs: { title: "Uhrzeit" },
                  on: {
                    click: function ($event) {
                      _vm.onlyTime = !_vm.onlyTime
                    },
                  },
                },
                [
                  _c(
                    "i",
                    {
                      staticClass: "material-icons",
                      staticStyle: { "vertical-align": "-1px" },
                    },
                    [_vm._v("access_time")]
                  ),
                ]
              )
            : _c(
                "i",
                { staticClass: "material-icons", attrs: { title: "Uhrzeit" } },
                [_vm._v("access_time")]
              ),
          _vm._v(" "),
          _vm.onlyTime
            ? [
                _c("date-time", {
                  staticClass: "has-margin-right",
                  attrs: { "time-only": "" },
                  model: {
                    value: _vm.copyPlaceholder.startTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.copyPlaceholder, "startTime", $$v)
                    },
                    expression: "copyPlaceholder.startTime",
                  },
                }),
                _vm._v(" -\n                "),
                _c("date-time", {
                  staticClass: "has-margin-left",
                  attrs: { "time-only": "" },
                  model: {
                    value: _vm.copyPlaceholder.endTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.copyPlaceholder, "endTime", $$v)
                    },
                    expression: "copyPlaceholder.endTime",
                  },
                }),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "select" },
                  [
                    _c("multiselect", {
                      attrs: {
                        options: _vm.serviceDayTimes,
                        "track-by": "id",
                        "custom-label": _vm.serviceDayTimeLabel,
                      },
                      on: { select: _vm.selectServiceTime },
                      model: {
                        value: _vm.serviceDayTimeSelected,
                        callback: function ($$v) {
                          _vm.serviceDayTimeSelected = $$v
                        },
                        expression: "serviceDayTimeSelected",
                      },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _vm.onlyTime
        ? _c(
            "div",
            [
              _c(
                "i",
                {
                  staticClass: "material-icons",
                  attrs: { title: "Einfschiffung" },
                },
                [_vm._v("av_timer")]
              ),
              _vm._v(" "),
              _c("date-time", {
                attrs: { "time-only": "" },
                model: {
                  value: _vm.copyPlaceholder.checkInTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.copyPlaceholder, "checkInTime", $$v)
                  },
                  expression: "copyPlaceholder.checkInTime",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", [
      _c("div", { staticClass: "bp-row-form__title" }, [_vm._v("Abrechnung")]),
      _vm._v(" "),
      _c("div", [
        _c(
          "i",
          {
            staticClass: "material-icons",
            attrs: { title: "Set as Additional" },
          },
          [_vm._v("shopping_cart")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "select" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.copyPlaceholder.clientOffer.status,
                  expression: "copyPlaceholder.clientOffer.status",
                },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.copyPlaceholder.clientOffer,
                    "status",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "included" } }, [
                _vm._v("inkludiert"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "optional" } }, [
                _vm._v("optional"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "additional" } }, [
                _vm._v("gesondert"),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "button is-close",
        attrs: { title: "Verwerfen" },
        on: {
          click: function ($event) {
            return _vm.$emit("cancel")
          },
        },
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "button is-save is-primary",
        class: { "is-loading": _vm.isLoading },
        attrs: { title: "Speichern" },
        on: { click: _vm.save },
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("check")])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }