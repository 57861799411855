var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isOther
        ? _c("other-service-icons", { attrs: { type: _vm.provider.type } })
        : _c("i", { staticClass: "material-icons mr" }, [
            _vm._v(_vm._s(_vm.providerIcon)),
          ]),
      _vm._v(" "),
      _vm.provider
        ? _c("span", [_vm._v(_vm._s(_vm.provider.name))])
        : _vm.agency
        ? _c("span", [_vm._v(_vm._s(_vm.agency.name))])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }