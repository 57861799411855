var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cell-content is-full is-flex" },
    [!_vm.hasAddress ? [_vm._v("-")] : [_vm._v(_vm._s(_vm.address.zip))]],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }