var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "menu" }, [
    _c("p", { staticClass: "menu-label level" }, [
      _c("span", [_vm._v("Ordner")]),
      _vm._v(" "),
      _c(
        "i",
        {
          staticClass: "material-icons is-pointer",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.startAddMode.apply(null, arguments)
            },
          },
        },
        [_vm._v("add_circle")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "menu-list" },
      [
        _c("li", [
          _c(
            "a",
            {
              class: { "is-active": !_vm.value },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.selectFolder(null)
                },
              },
            },
            [_vm._v("Ungeordnet")]
          ),
        ]),
        _vm._v(" "),
        _vm._l(_vm.folders, function (folder) {
          return _c("li", { key: folder.id }, [
            _vm.editMode !== folder.id
              ? _c(
                  "a",
                  {
                    staticClass: "level level-left",
                    class: {
                      "is-active": _vm.value && _vm.value.id === folder.id,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.selectFolder(folder)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "level-item" }, [
                      _vm._v(_vm._s(folder.name)),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "level hover-anchor" }, [
                      _c(
                        "i",
                        {
                          staticClass: "material-icons",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.startEditMode(folder)
                            },
                          },
                        },
                        [_vm._v("edit")]
                      ),
                      _vm._v(" "),
                      _c(
                        "i",
                        {
                          staticClass: "material-icons",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.deleteFolder(folder)
                            },
                          },
                        },
                        [_vm._v("delete")]
                      ),
                    ]),
                  ]
                )
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.name,
                      expression: "form.name",
                    },
                  ],
                  ref: "input",
                  refInFor: true,
                  staticClass: "input",
                  attrs: { type: "text", placeholder: "Folder name" },
                  domProps: { value: _vm.form.name },
                  on: {
                    keydown: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape",
                          ])
                        )
                          return null
                        _vm.editMode = false
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.editFolder.apply(null, arguments)
                      },
                    ],
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "name", $event.target.value)
                    },
                  },
                }),
          ])
        }),
        _vm._v(" "),
        _vm.addMode
          ? _c("li", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.name,
                    expression: "form.name",
                  },
                ],
                ref: "input",
                staticClass: "input",
                attrs: { type: "text", placeholder: "Folder name" },
                domProps: { value: _vm.form.name },
                on: {
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      )
                        return null
                      _vm.addMode = false
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.addFolder.apply(null, arguments)
                    },
                  ],
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "name", $event.target.value)
                  },
                },
              }),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }