<template>
    <crud-table
        ref="table"

        api="orders"
        url="/orders"
        :storeKey="`otherServiceTypes/${item.id}/orders`"

        :filter="{'days.otherPlaceholders.otherServiceType.id': item.id}"

        label="Stammdaten"
        :name="{singular: 'reise', plural: 'reisen'}"

        :columns="[
            { key: 'id', label: '# ReiseNr' },
            'client.companyName',
            'groupName',
            { key: 'startAt', filter: 'startAt[after]' },
            { key: 'endAt', filter: 'startAt[before]' },
            { key: 'trip.destinations', sortable: 'trip.destinations.name' },
            { key: 'user', filter: 'user.id', sortable: 'user.username' },
            { key: 'mostUrgentTodo.dueDate', label: 'Deadline', sortable: 'mostUrgentDueDate' },
            { key: 'currentStatus', filter: 'currentStatus.status', sortable: 'currentStatus.status' },
            { key: 'hotelContingent', sortable: false },
            '_importId',
        ]"
    >
        <h2 slot="header">Verknüpfungen mit Reisen</h2>
    </crud-table>
</template>

<script>
import { CrudTable } from '@components/CRUD';
import tab from './tab';

export default {
    mixins: [tab],

    components: {
        CrudTable
    }
}
</script>
