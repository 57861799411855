var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      staticClass: "is-image-form",
      attrs: {
        createTitle: "Neue Bild",
        editTitle: "Bild bearbeiten",
        editButton: "Speichern",
      },
    },
    [
      _c(
        "table",
        [
          !_vm.multiple
            ? _c("tr", [
                _c("th", [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "title" } },
                    [_vm._v("Bildtitel:")]
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "control" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.title,
                          expression: "form.title",
                        },
                      ],
                      staticClass: "input",
                      attrs: { type: "text", id: "title" },
                      domProps: { value: _vm.form.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "title", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showType
            ? _c("tr", [
                _c("th", [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "type" } },
                    [_vm._v("Kategorie:")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.options.categories
                      ? _c("multiselect", {
                          attrs: {
                            options: _vm.options.categories,
                            "track-by": "key",
                            label: "value",
                          },
                          model: {
                            value: _vm.type,
                            callback: function ($$v) {
                              _vm.type = $$v
                            },
                            expression: "type",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isHomepageImage
            ? _c("tr", [
                _c("th", [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "folder" } },
                    [_vm._v("Ordner:")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.imageFolders
                      ? _c("multiselect", {
                          attrs: {
                            options: _vm.imageFolders,
                            "track-by": "id",
                            label: "name",
                          },
                          model: {
                            value: _vm.folder,
                            callback: function ($$v) {
                              _vm.folder = $$v
                            },
                            expression: "folder",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isHomepageImage
            ? _c("tr", [
                _c("th", [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "alternativeText" } },
                    [_vm._v("Alternativ-Text:")]
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.alternativeText,
                        expression: "form.alternativeText",
                      },
                    ],
                    staticClass: "input",
                    attrs: { type: "text" },
                    domProps: { value: _vm.form.alternativeText },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.form,
                          "alternativeText",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isHomepageImage
            ? _c("tr", [
                _c("th", [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "backgroundText" } },
                    [_vm._v("Hintergrund-Text:")]
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.backgroundText,
                        expression: "form.backgroundText",
                      },
                    ],
                    staticClass: "textarea",
                    attrs: { rows: "3" },
                    domProps: { value: _vm.form.backgroundText },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.form,
                          "backgroundText",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.multiple && !_vm.isHomepageImage
            ? [
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label" }, [_vm._v("Art:")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "toggle-switch",
                        {
                          model: {
                            value: _vm.form.isGoogleMaps,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isGoogleMaps", $$v)
                            },
                            expression: "form.isGoogleMaps",
                          },
                        },
                        [
                          _c("option", { domProps: { value: false } }, [
                            _vm._v("Datei"),
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: true } }, [
                            _vm._v("Bild von Google Maps"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.form.isGoogleMaps
                  ? _c("map-form", {
                      attrs: { options: _vm.options },
                      model: {
                        value: _vm.form.googleMap,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "googleMap", $$v)
                        },
                        expression: "form.googleMap",
                      },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }