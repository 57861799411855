<template>
    <modal-form
        editTitle="Verkaufpreis editieren"
        createTitle="Neuer Verkaufpreis"
        class="is-medium"
    >
        <table>
            <tr>
                <td colspan="2">
                    <h2>GÜLTIKEIT UND REFERENZ-ZEITRAUM FESTLEGEN</h2>
                </td>
            </tr>

            <template v-if="!editMode">
                <tr>
                    <td colspan="2">
                        <toggle-switch v-model="periodType">
                            <option value="months">Mehrere Monate auswählen</option>
                            <option value="quick">Zeitraum schnell auswählen</option>
                            <option value="dates">Genauen Zeitraum auswählen</option>
                        </toggle-switch>
                    </td>
                </tr>

                <template v-if="periodType === 'months'">
                    <tr>
                        <th>
                            <label class="label">Für folgende Monate eine Kalkulation erstellen:</label>
                        </th>
                        <td>
                            <div class="level-left">
                                <div class="level-item">
                                    <month-select v-model="form.months" multiple />
                                </div>

                                <multiselect
                                    v-model="form.year"
                                    v-bind:options="years"
                                    class="is-small"
                                />
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <th><label class="label">Start-Wochentag der Reise:</label></th>
                        <td>
                            <multiselect
                                v-model="startDay"
                                v-bind:options="weekDays"
                                label="label"
                                track-by="value"
                                class="is-medium"
                            />
                        </td>
                    </tr>
                </template>

                <template v-if="periodType === 'quick'">
                    <tr>
                        <th>
                            <label class="label">Jahr:</label>
                        </th>
                        <td>
                            <multiselect
                                v-model="form.year"
                                v-bind:options="years"
                                class="is-small"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label class="label">Startmonat:</label>
                        </th>
                        <td>
                            <div class="level-left">
                                <div class="level-item">
                                    <month-select v-model="form.startMonth" />
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label class="label">Endmonat:</label>
                        </th>
                        <td>
                            <div class="level-left">
                                <div class="level-item">
                                    <month-select v-model="form.endMonth" />
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <th><label class="label">Start-Wochentag der Reise:</label></th>
                        <td>
                            <multiselect
                                v-model="startDay"
                                v-bind:options="weekDays"
                                label="label"
                                track-by="value"
                                class="is-medium"
                            />
                        </td>
                    </tr>
                </template>
            </template>

            <tr v-if="editMode || periodType === 'dates'">
                <th>
                    <label class="label">Gültigkeit der Preise:
                        <i class="material-icons is-pointer" v-on:click="addPeriod" v-if="canAddPeriod">add_circle</i>
                    </label>
                </th>
                <td>
                    <div class="level-left" v-for="period in form.seasonTimes" v-bind:key="period.id ? period.id : period.key">
                        <div class="flatpickr-combined level-item">
                            <input-date v-model="period.startAt" class="is-small"  />
                            <span>-</span>
                            <input-date v-model="period.endAt" class="is-small" v-on:input="fillRef(period)" />
                        </div>
                        <i
                            v-if="form.seasonTimes.length > 1"
                            class="material-icons is-pointer"
                            v-on:click="deletePeriod(period)"
                        >delete</i>
                    </div>
                </td>
            </tr>

            <tr v-if="!editMode && periodType === 'dates'">
                <th>
                    <label class="label">Referenzreise-Datum:</label>
                </th>
                <td>
                    <div class="flatpickr-combined">
                        <input-date v-model="form.refStartAt" class="is-small"  />
                        <span>-</span>
                        <input-date v-model="form.refEndAt" class="is-small"  />
                    </div>
                </td>
            </tr>

            <tr v-if="!editMode">
                <th><label class="label">Hotelkategorie ändern:</label></th>
                <td>
                    <multiselect
                        v-if="options.hotelTypes"
                        v-model="form.hotelType"
                        track-by="name"
                        label="name"
                        v-bind:options="options.hotelTypes"
                        :placeholder="hotelTypePlaceholder"
                        v-on:select="setTitle"
                    />
                </td>
            </tr>

            <tr>
                <th><label class="label">Zusatztitel (z.B. 4* Superior):</label></th>
                <td>
                    <input type="text" class="input" v-model="form.subTitle">
                </td>
            </tr>

            <tr>
                <td colspan="2">
                    <h2>NÄCHTE UND LÄNGE VON ZUSATZLEISTUNGEN FESTLEGEN</h2>
                </td>
            </tr>

            <tr>
                <td colspan="2">
                    <concept-preview v-model="form.concept" />
                </td>
            </tr>
        </table>
    </modal-form>
</template>

<script>
import TabForm from '../../form'
import { InputDate, ToggleSwitch, MonthSelect, Multiselect } from '@components/form'
import ConceptPreview from './ConceptPreview'
import { weekDays } from '@utilities/variables'
import { addDays} from "@utilities/functions";
import { preSelections } from '@clientSpecific/utilities/variables';

export default {
    mixins: [TabForm],

    props: {
        hotelTypePlaceholder: {
            required: false,
            default: 'Nicht ändern',
        }
    },

    form: {
        refStartAt: null,
        refEndAt: null,
        startAt: null,
        startMonth: null,
        endMonth: null,
        endAt: null,
        concept: false,
        subTitle: "",
        hotelType: null,
        seasonTimes: [{
            startAt: null,
            endAt: null,
            key: + new Date
        }],

        startDay: 'friday',
        months: [4,5,6,9,10],
        year: new Date().getFullYear() + 1
    },

    components: {
        InputDate,
        ToggleSwitch,
        MonthSelect,
        Multiselect,
        ConceptPreview
    },

    data: function () {
        return {
            periodType: preSelections.order_concept.sellingPriceForm.periodType,
            weekDays,
            years: [...Array(6).keys()].map(n => n +new Date().getFullYear() - 2)
        }
    },

    computed: {
        startDay: {
            get: function () {
                return weekDays.find(({value}) => value === this.form.startDay)
            },

            set: function (weekDay) {
                this.form.startDay = !!weekDay ? weekDay.value : null
            }
        }
    },

    methods: {
        handleFormSubmit: function () {
            this.$emit('submit', JSON.parse(JSON.stringify({...this.form, periodType: this.periodType})))
        },
        setTitle: function (value) {
            if(!this.form.subTitle){
                this.form.subTitle = value ? value.name : '';
            }
        },
        fillRef: function (season) {
            if(!this.form.refStartAt){
                this.form.refStartAt = season.startAt;
                this.form.refEndAt = addDays(season.startAt, this.form.concept.days.length - 1);
            }
        },
        canAddPeriod: function () {
            return this.form.seasonTimes
                .filter(({startAt, endAt}) => !startAt || !endAt)
                .length === 0
        },
        addPeriod: function () {
            this.form.seasonTimes.push({
                startAt: null,
                endAt: null,
                key: + new Date
            })
        },
        deletePeriod: function (period) {
            this.form.seasonTimes = this.form.seasonTimes
                .filter(time => period.id ? period.id !== time.id : period.key !== time.key)
        },
    }
}
</script>
