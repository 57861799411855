var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "has-margin-top" },
    [
      _c("tip-tap", {
        attrs: { "default-size": _vm.component.props.defaultSize },
        on: {
          input: function ($event) {
            return _vm.saveForm(_vm.component.props)
          },
        },
        model: {
          value: _vm.item[_vm.component.props.property],
          callback: function ($$v) {
            _vm.$set(_vm.item, _vm.component.props.property, $$v)
          },
          expression: "item[component.props.property]",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }