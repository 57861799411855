<template>
    <span v-if="value">{{hotelType}}</span>
</template>

<script>
import { getValueWithKey } from '@utilities/functions';
import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        hotelType: function () {
            return `${this.stars}* ${this.category}`
        },

        category: function () {
            return getValueWithKey({key: this.value.category, optionList: this.options.category});
        },

        stars: function () {
            return getValueWithKey({key: this.value.stars, optionList: this.options.stars})
        },
    }
}
</script>
