var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-dropzone",
    {
      ref: "dropzone",
      staticClass: "dropzone--images-tab",
      attrs: {
        id: "dropzone",
        options: _vm.dropzoneOptions,
        useCustomSlot: "",
      },
      on: {
        "vdropzone-sending": _vm.sendingEvent,
        "vdropzone-success": _vm.fileSuccess,
        "vdropzone-complete": _vm.fileCompleted,
        "vdropzone-files-added": _vm.filesAdded,
      },
    },
    [
      _c(
        "div",
        { staticClass: "columns" },
        [
          _c(
            "div",
            { staticClass: "column is-one-fifth" },
            [
              _c("folders", {
                model: {
                  value: _vm.selectedFolder,
                  callback: function ($$v) {
                    _vm.selectedFolder = $$v
                  },
                  expression: "selectedFolder",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("gallery", {
            attrs: {
              "folder-images": _vm.folderImages,
              "enable-google-maps": true,
              "enable-zoom": true,
              "column-classes":
                "is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen",
              "multi-images": true,
            },
            on: {
              openForm: _vm.openForm,
              deleteImage: _vm.deleteImage,
              zoom: _vm.zoom,
            },
          }),
          _vm._v(" "),
          _c("vue-gallery", {
            attrs: { images: _vm.gallery, index: _vm.zoomIndex },
            on: {
              close: function ($event) {
                _vm.zoomIndex = null
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "dz-area" }, [
            _c("span", [_vm._v("Zum Hochladen Bild hier hineinziehen")]),
          ]),
          _vm._v(" "),
          _c("image-form", {
            ref: "form",
            on: { submit: _vm.handleImageSubmit },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }