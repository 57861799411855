<template>

    <div class="has-margin-top">
        <div class="bp-panel has-no-title" style="min-height: auto">
            <div class="panel-content">
                <div class="field">
                    <div class="control">
                        <toggle-switch div-class="is-full-width" btn-class="is-half" v-model="readyForStaging" v-on:input="$emit('saveItem', 'readyForStaging', readyForStaging, true)">
                            <option :value="false">soll noch nicht veröffentlicht werden</option>
                            <option :value="true">kann veröffentlicht werden</option>
                        </toggle-switch>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>

import { ToggleSwitch } from '@components/form';
import Mixin from '../../tab';




export default {
    mixins: [ Mixin ],

    data: function() {
        return {
            readyForStaging: false,
        }
    },

    mounted: function () {
        this.readyForStaging = !!this.item.readyForStaging;
    },

    components: {
        ToggleSwitch,
    },
}
</script>
