<template>
    <tr>
        <th><label class="label">{{label}}:</label></th>
        <td>
            <multiselect
                v-bind:options="options"
                v-model="tags"
                v-bind:taggable="true"
                multiple
                v-on:tag="newTag => { value.push(newTag); }"
            >
                <span slot="caret" ></span>
            </multiselect>
        </td>
    </tr>
</template>

<script>

import Multiselect from "@components/form/Multiselect";

export default {
    props: {
        value: { required: true },
        label: { default: 'Tags'},
        options: {  default: () => {} }
    },

    computed: {
        tags: {
            get: function () { return this.value; },
            set: function (value) { this.$emit('input', value) }
        }
    },

    components: {
        Multiselect
    }
}
</script>
