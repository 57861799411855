var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showIcon
        ? _c("i", { staticClass: "material-icons" }, [_vm._v("assignment")])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "multiselect",
        {
          staticClass: "is-hotel-search",
          style: _vm.multiSelectStyle,
          attrs: {
            options: _vm.items,
            loading: _vm.isLoadingItems,
            "allow-empty": false,
            placeholder: "tippen, um Hotel zu finden",
            label: "name",
            "track-by": "id",
            "select-label": "",
            "deselect-label": "Löschen",
            "internal-search": false,
          },
          on: { "search-change": _vm.searchHotels, input: _vm.fillLocation },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function (props) {
                return [
                  props.option.id !== -1
                    ? [
                        _c("rating", {
                          staticClass: "level-item",
                          attrs: {
                            radius: 12,
                            rating: props.option.buspaketRating
                              ? props.option.buspaketRating
                              : -1,
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("h1", [_vm._v(_vm._s(props.option.name))]),
                            _vm._v(" "),
                            _c("stars", {
                              attrs: {
                                count:
                                  props.option.hotelType &&
                                  props.option.hotelType.stars,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : [_vm._v(_vm._s(props.option.name))],
                ]
              },
            },
          ]),
          model: {
            value: _vm.hotelSelected,
            callback: function ($$v) {
              _vm.hotelSelected = $$v
            },
            expression: "hotelSelected",
          },
        },
        [
          _vm._v(" "),
          _c("template", { slot: "noResult" }, [
            _vm._v("Keine passenden Hotels"),
          ]),
          _vm._v(" "),
          _c("span", { attrs: { slot: "caret" }, slot: "caret" }, [
            _c(
              "a",
              {
                staticClass: "multiselect-crud-link",
                attrs: {
                  href: "/hotels?add",
                  title: "ein neues Hotel hinzufügen",
                  target: "_blank",
                },
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("add")])]
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }