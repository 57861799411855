import AirlinePrice from './Airline'
import FerryPrice from './Ferry'
import HotelPrice from './Hotel'
import OtherPrice from './Other'
import TrainPrice from './Train'

import IncludedPrice from './Included'

import AirlineSubrows from './Subrows/Airline'
import FerrySubrows from './Subrows/Ferry'
import HotelSubrows from './Subrows/Hotel'
import OtherSubrows from './Subrows/Other'
import TrainSubrows from './Subrows/Train'


import ItemPricesSubrows from './Subrows/ItemPrices'

export default {
    AirlinePrice,
    FerryPrice,
    HotelPrice,
    OtherPrice,
    TrainPrice,

    IncludedPrice,

    AirlineSubrows,
    FerrySubrows,
    HotelSubrows,
    OtherSubrows,
    TrainSubrows,

    ItemPricesSubrows
}

export {
    IncludedPrice
}
