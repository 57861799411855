var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "redesign-table" }, [
        _c(
          "div",
          { staticClass: "table-wrapper" },
          [
            _c("div", { staticClass: "row is-head" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "cell" }, [
                _vm._v("\n                    Preisart\n                "),
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "cell is-actions" }, [
                _c("button", { staticClass: "btn-edit" }, [
                  _c(
                    "i",
                    {
                      staticClass: "material-icons is-pointer",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openForm.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("add_circle")]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.hasExtras
              ? _vm._l(_vm.extras, function (extra) {
                  return _c("div", { key: extra.id, staticClass: "row" }, [
                    _c("div", { staticClass: "cell" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(extra.name) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cell" }, [
                      _c("div", { staticClass: "level-left" }, [
                        _c("i", { staticClass: "material-icons level-item" }, [
                          _vm._v(_vm._s(_vm.quantityIcon(extra))),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.quantityType(extra)))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "cell" },
                      [
                        extra.startAt
                          ? [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm._f("fullYear")(extra.startAt)) +
                                  "\n                        "
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        extra.startAt && extra.endAt ? [_vm._v("-")] : _vm._e(),
                        _vm._v(" "),
                        extra.endAt
                          ? [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm._f("fullYear")(extra.endAt)) +
                                  "\n                        "
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "cell" },
                      [
                        extra.minPersons !== null
                          ? [
                              _vm._v(
                                _vm._s(
                                  extra.limitType === "validIfLimit"
                                    ? "ab"
                                    : "für"
                                ) +
                                  " " +
                                  _vm._s(extra.minPersons || 0)
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        extra.maxPersons !== null
                          ? [_vm._v("bis " + _vm._s(extra.maxPersons || 0))]
                          : _vm._e(),
                        _vm._v(" "),
                        extra.minPersons || extra.maxPersons
                          ? [
                              extra.quantityType === "per_single_room"
                                ? [_vm._v("EZ")]
                                : [_vm._v("Pax")],
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "cell" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(extra.price.formatted) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "cell is-actions" },
                      [
                        _c(
                          "update-popover",
                          {
                            attrs: {
                              update: {
                                updatedAt: extra.updatedAt,
                                updatedBy: extra.updatedBy,
                              },
                              api: "/api/provider_item_prices/" + extra.id,
                            },
                          },
                          [
                            _c("button", { staticClass: "btn-edit" }, [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("update"),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn-edit",
                            on: {
                              click: function ($event) {
                                return _vm.openForm(extra)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("edit"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn-delete",
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem("itemPrices", extra)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("delete"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                })
              : _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "cell has-text-centered text-small" },
                    [
                      _vm._v(
                        "\n                    Keine Zuschläge vorhanden - klicke auf "
                      ),
                      _c(
                        "i",
                        {
                          staticClass:
                            "is-pointer material-icons is-vertical-middle",
                          on: { click: _vm.openForm },
                        },
                        [_vm._v("add_circle")]
                      ),
                      _vm._v(" um einen hinzuzufügen\n                "),
                    ]
                  ),
                ]),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("extra-form", { ref: "form" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cell" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("assignment")]),
      _vm._v("\n                        Zuschlag\n                "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cell" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("date_range")]),
      _vm._v("\n                    von - bis\n                "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cell" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("people")]),
      _vm._v("\n                    Gültigkeit\n                "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cell" }, [
      _c("i", { staticClass: "fa fa-tag fa-lg" }),
      _vm._v("\n                    Preis\n                "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }