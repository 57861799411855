var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.stars
    ? _c(
        "div",
        { staticClass: "stars", class: _vm.sizeClass },
        _vm._l(_vm.stars, function (n) {
          return _c("i", { key: n, staticClass: "material-icons active" }, [
            _vm._v("star"),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }