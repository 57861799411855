var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          class: [
            "btn-single-toggle",
            _vm.isRouteDescription ? "is-active" : "",
          ],
          staticStyle: { border: "1px solid #dddddd" },
          on: {
            click: function ($event) {
              _vm.isRouteDescription = !_vm.isRouteDescription
            },
          },
        },
        [_c("i", { staticClass: "material-icons" }, [_vm._v("place")])]
      ),
      _vm._v(" "),
      _c(
        "multiselect",
        {
          attrs: {
            options: _vm.items,
            loading: _vm.isLoadingItems,
            "allow-empty": false,
            "custom-label": _vm.serviceLabel,
            placeholder: "Zusatzleistung auswählen",
            "track-by": "id",
            "select-label": "",
            "deselect-label": "Löschen",
          },
          on: { "search-change": _vm.searchOtherServiceTypes },
          model: {
            value: _vm.otherServiceTypeSelected,
            callback: function ($$v) {
              _vm.otherServiceTypeSelected = $$v
            },
            expression: "otherServiceTypeSelected",
          },
        },
        [
          _c("template", { slot: "noResult" }, [
            _vm._v("Keine passenden Leistungsträger"),
          ]),
          _vm._v(" "),
          _c("span", { attrs: { slot: "caret" }, slot: "caret" }, [
            _c(
              "a",
              {
                staticClass: "multiselect-crud-link",
                attrs: {
                  href: "/other_service_types?add",
                  title: "Füge einen neuen Leistungsträger hinzu",
                  target: "_blank",
                },
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("add")])]
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }