<template>
    <loading v-if="isLoading"></loading>

    <div class="is-empty-cost" v-else-if="isEmpty">
        Berechnung ohne Preise nicht möglich
    </div>

    <div v-else>
        <div class="redesign-table is-costs">
            <div class="row is-head">
                <div class="cell">Leistung</div>
                <div class="cell"></div>
                <div class="cell">Anzahl</div>
                <div class="cell">Einzelpreis</div>
                <div class="cell">Gesamtpreis</div>
            </div>


            <package
                v-if="packageID"
                v-bind:calculations="calculations"
                v-bind:roomTypes="roomTypes"
                v-bind:cabinTypes="cabinTypes"
                v-bind:trainCabinTypes="trainCabinTypes"
                v-bind:isLoading="isLoading"
            ></package>

            <div class="is-zebra" v-else>

                <div
                    class="row"
                    v-for="room in sortedRooms"
                    v-bind:key="room.id"
                >
                    <div class="cell"><span :style="isStandardRoom(room) ? 'font-weight: bold' : ''">{{ roomLabel(room) }}</span></div>
                    <div class="cell"></div>
                    <div class="cell">{{ roomPersons(room) }}</div>
                    <div class="cell">{{ roomPrice(room) | priceView }}</div>
                    <div class="cell"><b>{{ roomTotal(room) | priceView }}</b></div>
                </div>

                <div
                    class="row"
                    v-for="room in sortedRooms"
                    v-bind:key="'extra-' + room.id"
                    v-if="room.extraReservedAmount"
                >
                    <div class="cell">Extra - {{ getAccommodationLabel(room.type.type, room.type.persons, types) }} - {{ room.extraDescription }}</div>
                    <div class="cell"></div>
                    <div class="cell">{{ room.extraReservedAmount * room.type.persons }}</div>
                    <div class="cell">{{ room.absolutePrice.amount | priceView }}</div>
                    <div class="cell"><b>{{ room.totalPriceExtraReserved.amount | priceView }}</b></div>
                </div>

                <div
                    class="row"
                    v-for="room in sortedRooms"
                    v-bind:key="'free-' + room.id"
                    v-if="room.personsFreePlaces"
                >
                    <div class="cell">{{freePlaceLabel(room)}}</div>
                    <div class="cell"></div>
                    <div class="cell">{{ room.personsFreePlaces }}</div>
                    <div class="cell">{{ -1 * room.absolutePrice.amount | priceView }}</div>
                    <div class="cell"><b>{{ -1 * room.totalPriceFreePlaces.amount | priceView }}</b></div>
                </div>


                <div class="row"
                    v-for="item in calculations.itemPrices"
                    v-bind:key="'item-' + item.id"
                    v-if="item.billingType !== 'info' && item.limitIsValid"
                >
                    <div class="cell">Zuschlag - {{ item.name }}</div>
                    <div class="cell">{{ item.quantityType === 'per_person_night' || item.quantityType === 'per_single_room_night' ? calculations.durationInDays : ''}}</div>
                    <div class="cell">{{ item.calculatedQuantity}}</div>
                    <div class="cell">{{ item.price.amount | priceView }}</div>
                    <div class="cell"><b>{{ item.totalPrice.amount | priceView }}</b></div>
                </div>

            </div>






            <loading v-if="isLoading"></loading>
        </div>

        <div class="for-costs-total">
            <div>TOTAL ({{calculations.totalPrice.currency}})</div>
            <div><b>{{ calculations.totalPrice.amount | priceView }}</b></div>
        </div>
    </div>
</template>



<script>
import mixins from './mixins';


export default {
    mixins: [mixins],


    data: function () {
        return {
            apiEndpoint: 'train_requests/'
        }
    },

    computed: {
        sortedRooms: function () {
            let rooms = this.calculations.contingent.all;
            if(this.calculations.placeholder.category !== 'first_and_second') {
                rooms = rooms.filter(item => item.type.type === this.calculations.placeholder.category);
            }
            let roomTypes = this.types;
            return rooms.sort(function (a, b){
                if(!roomTypes){
                    return 0;
                }
                const indexA = roomTypes.findIndex(item => item.persons === a.type.persons && item.type === a.type.type);
                const indexB = roomTypes.findIndex(item => item.persons === b.type.persons && item.type === b.type.type);
                return indexA - indexB;
            });
        },

        standardRoom: function () {
            return this.sortedRooms.find(room => this.isStandardRoom(room));
        },

        types: function () {
            if(this.calculations.placeholder.type === 'night') {
                return this.trainCabinTypes;
            } else {
                return this.dayTrainCabinTypes;
            }
        },

        standardRoomType: function () {
            return this.calculations.placeholder.category === 'first' ? 'first' : 'second';
        }
    },

    methods: {
        isStandardRoom: function (room) {
            if(this.calculations.placeholder.type === 'night') {
                return room.type.type === this.standardRoomType && room.type.persons === 2;
            }
            return room.type.type === this.standardRoomType & room.type.persons === 1;
        },

        isNegative: function(room) {
            return this.standardRoom.absolutePrice.amount > room.absolutePrice.amount;
        },

        roomLabel: function (room) {
            return this.isStandardRoom(room) ?
                this.calculations.info.title + ' - Preis pro Person'
                : this.getAccommodationLabel(room.type.type, room.type.persons, this.types)
        },

        freePlaceLabel: function (room) {
            if(this.calculations.placeholder.type === 'day') {
                return 'Freiplatz';
            }
            return this.isStandardRoom(room) ? 'Freiplatz im halben Doppelabteil' : 'Freiplatz im Einzelabteil';
        },

        roomPersons: function (room) {
            if(this.supplementPrices && this.isStandardRoom(room)){
                return this.calculations.personsReserved
            }
            return this.supplementPrices ? room.personsForCosts : room.reservedAmount * room.type.persons;
        },

        roomPrice: function (room) {
            return !this.supplementPrices || this.isStandardRoom(room) ? room.absolutePrice.amount : room.premiumPrice.amount;
        },

        roomTotal: function (room) {
            if(this.isStandardRoom(room)) {
                return room.totalPriceReservedStandard.amount ;
            }
            return !this.supplementPrices ? room.totalPriceReserved.amount : room.totalPriceReservedPremium.amount
        },

        roomExtraTotal: function (room) {
            return room.totalPriceExtraReservedPremium.amount
        }
    },
}
</script>
