var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "redesign-table" },
    [
      _c(
        "div",
        { staticClass: "row is-header" },
        [
          _c("h2", [_vm._v("Verkaufspreise")]),
          _vm._v(" "),
          _c("simple-menu", [
            _vm.singleRoomLimit
              ? _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addPackage({ persons: 1 })
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getAccommodationLabel(
                            "standard",
                            1,
                            _vm.roomTypes
                          )
                        ) +
                        "\n                "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.addPackage({ persons: 2 })
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                _vm._v(" Paketpreis\n                "),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "has-sub-menu" }, [
              _c("button", [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("chevron_left"),
                ]),
                _vm._v(" Extra"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sub-menu" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addExtra({
                          offerTextBlock: "hotelSuplements",
                        })
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                    _vm._v(
                      " Mehrbettzimmer und sonstige Zimmer\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addExtra({
                          offerTextBlock: "ferrySuplements",
                        })
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                    _vm._v(" Außenkabinen\n                        "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addExtra({
                          offerTextBlock: "trainSuplements",
                        })
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                    _vm._v(" Zugfahrten 1. Klasse\n                        "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addExtra({ offerTextBlock: "ferryOther" })
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                    _vm._v(
                      " Sonstige Leistungen auf der Fähre\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addExtra({ offerTextBlock: "foodOnBoard" })
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                    _vm._v(
                      " zusätzliche Leistungen an Bord der Fähre\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addExtra({
                          offerTextBlock: "tourGuides",
                          type: "per_group",
                        })
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                    _vm._v(
                      " zusätzliche Reiseleiter-Leistungen\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addExtra({
                          offerTextBlock: "groupServices",
                          type: "per_group",
                        })
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                    _vm._v(
                      " zusätzliche Gruppen-Leistungen\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addExtra({ offerTextBlock: "services" })
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                    _vm._v(
                      " zusätzliche Leistungen pro Person\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addExtra({
                          offerTextBlock: "mainPriceDates",
                        })
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                    _vm._v(" Paketpreise\n                        "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addExtra({ offerTextBlock: "tax" })
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                    _vm._v(" Kurtaxe\n                        "),
                  ]
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.hide
        ? _c(
            "row",
            { staticClass: "is-head" },
            [
              _c("template", { slot: "type" }, [_vm._v("Art")]),
              _vm._v(" "),
              _c("template", { slot: "date" }, [_c("div")]),
              _vm._v(" "),
              _c("template", { slot: "nights" }, [_c("div")]),
              _vm._v(" "),
              _c("template", { slot: "name" }, [_c("div")]),
              _vm._v(" "),
              _c("template", { slot: "marginTax" }, [
                _vm._v("Margensteuer (" + _vm._s(_vm.orderCurrency) + ")"),
              ]),
              _vm._v(" "),
              _vm.hasTwoCurrencies
                ? _c("template", { slot: "buyPriceCalculation" }, [
                    _vm._v("EK (" + _vm._s(_vm.calculationCurrency) + ")"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("template", { slot: "buyPrice" }, [
                _vm._v(
                  _vm._s(
                    _vm.hasTwoCurrencies
                      ? "EK (" + _vm.orderCurrency + ")"
                      : "Einkaufspreis"
                  )
                ),
              ]),
              _vm._v(" "),
              _vm.hasTwoCurrencies
                ? _c("template", { slot: "sellPriceCalculation" }, [
                    _vm._v("VK (" + _vm._s(_vm.calculationCurrency) + ")"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("template", { slot: "sellPrice" }, [
                _vm._v(
                  _vm._s(
                    _vm.hasTwoCurrencies
                      ? "VK (" + _vm.orderCurrency + ")"
                      : "Verkaufspreis"
                  )
                ),
              ]),
              _vm._v(" "),
              _vm.hasTwoCurrencies
                ? _c("template", { slot: "profitCalculation" }, [
                    _vm._v("GS (" + _vm._s(_vm.calculationCurrency) + ")"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("template", { slot: "profit" }, [
                _vm._v(
                  _vm._s(
                    _vm.hasTwoCurrencies
                      ? "GS (" + _vm.orderCurrency + ")"
                      : "Gewinnspanne"
                  )
                ),
              ]),
              _vm._v(" "),
              _c("template", { slot: "margin" }, [_vm._v("Marge (%)")]),
              _vm._v(" "),
              _c("template", { slot: "actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-single-toggle level-item",
                    class: { "is-active": _vm.marginsEnabled },
                    attrs: {
                      title: _vm.marginsEnabled
                        ? "Margensteuer deaktivieren"
                        : "Margensteuer aktivieren",
                    },
                    on: {
                      click: function ($event) {
                        _vm.marginsEnabled = !_vm.marginsEnabled
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-percent" })]
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.packagePrices.double.length > 0 ||
      _vm.packagePrices.single.length > 0 ||
      _vm.mainPriceDates.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [
              _vm._v("Paketpreise pro Person"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.packagePrices.double, function (price) {
              return _c("package-price", {
                key: `package-${price.key}`,
                attrs: { price: price },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.packagePrices.single, function (price) {
              return _c("package-price", {
                key: `package-${price.key}`,
                attrs: { price: price },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.mainPriceDates, function (price) {
              return _c("extra-price", {
                key: `extra-${price.key}`,
                attrs: { price: price },
              })
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.treatPackages.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [_vm._v("Pakete")]),
            _vm._v(" "),
            _vm._l(_vm.treatPackages, function (treatPackage) {
              return _c("treat-package", {
                key: `treatPackage-${treatPackage.id}`,
                attrs: { treatPackage: treatPackage },
              })
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.hotelContingentPrices.length > 0 ||
      _vm.extraHotelSuplementPrices.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [
              _vm._v("Mehrbettzimmer und sonstige Zimmer"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.hotelContingentPrices, function (price) {
              return _c("hotel-contingent-price", {
                key: `contingent-${price.key}`,
                attrs: { price: price },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.extraHotelSuplementPrices, function (price) {
              return _c("extra-price", {
                key: `extra-${price.key}`,
                attrs: { price: price },
              })
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.ferryContingentPrices.length > 0 ||
      _vm.extraFerrySuplementPrices.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [
              _vm._v("Außenkabinen (insgesamt für alle Fähren)"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.ferryContingentPrices, function (price) {
              return _c("ferry-contingent-price", {
                key: `contingent-${price.key}`,
                attrs: { price: price },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.extraFerrySuplementPrices, function (price) {
              return _c("extra-price", {
                key: `extra-${price.key}`,
                attrs: { price: price },
              })
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.trainContingentPrices.length > 0 ||
      _vm.extraTrainSuplementPrices.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [
              _vm._v("Zugfahrten 1. Klasse (insgesamt für alle Züge)"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.trainContingentPrices, function (price) {
              return _c("train-contingent-price", {
                key: `contingent-${price.key}`,
                attrs: { price: price },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.extraTrainSuplementPrices, function (price) {
              return _c("extra-price", {
                key: `extra-${price.key}`,
                attrs: { price: price },
              })
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.extraFerryPrices.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [
              _vm._v("Sonstige Leistungen auf der Fähre"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.extraFerryPrices, function (price) {
              return _c("extra-price", {
                key: `extra-${price.key}`,
                attrs: { price: price },
              })
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.airlinePrices.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [
              _vm._v("Flüge pro Person"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.airlinePrices, function (price) {
              return _c("provider-price", {
                key: `airline-${price.key}`,
                attrs: { price: price },
              })
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.hotelPrices.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [
              _vm._v("zusätzliche Hotelleistungen pro Person"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.hotelPrices, function (price) {
              return _c("provider-price", {
                key: `hotel-${price.key}`,
                attrs: { price: price },
              })
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.ferryPrices.night.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [
              _vm._v(
                "zusätzliche Nachtfähren pro Person (inkl. Fährpassagen für alle Fahrzeuge)"
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.ferryPrices.night, function (price) {
              return _c("ferry-price", {
                key: `ferry-${price.key}`,
                attrs: { price: price },
              })
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.ferryPrices.day.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [
              _vm._v(
                "zusätzliche Tagfähren pro Person (inkl. Fährpassage für alle Fahrzeuge)"
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.ferryPrices.day, function (price) {
              return _c("ferry-price", {
                key: `ferry-${price.key}`,
                attrs: { price: price },
              })
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.trainPrices.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [
              _vm._v("zusätzliche Zugfahrten pro Person"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.trainPrices, function (price) {
              return _c("provider-price", {
                key: `train-${price.key}`,
                attrs: { price: price },
              })
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.extraFoodPrices.length > 0 || _vm.otherFoodPrices.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [
              _vm._v("zusätzliche Leistungen an Bord der Fähre"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.otherFoodPrices, function (price) {
              return _c("provider-price", {
                key: `other-${price.key}`,
                attrs: { price: price },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.extraFoodPrices, function (price) {
              return _c("extra-price", {
                key: `extra-${price.key}`,
                attrs: { price: price },
              })
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.extraTourPrices.length > 0 || _vm.otherTourPrices.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [
              _vm._v("zusätzliche Reiseleiter-Leistungen"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.otherTourPrices, function (price) {
              return _c("provider-price", {
                key: `other-${price.key}`,
                attrs: { price: price },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.extraTourPrices, function (price) {
              return _c("extra-price", {
                key: `extra-${price.key}`,
                attrs: { price: price },
              })
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.extraGroupPrices.length > 0 || _vm.otherGroupPrices.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [
              _vm._v("zusätzliche Gruppen-Leistungen"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.otherGroupPrices, function (price) {
              return _c("provider-price", {
                key: `other-${price.key}`,
                attrs: { price: price },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.extraGroupPrices, function (price) {
              return _c("extra-price", {
                key: `extra-${price.key}`,
                attrs: { price: price },
              })
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.extraPersonPrices.length > 0 || _vm.otherPersonPrices.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [
              _vm._v("zusätzliche Leistungen pro Person"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.otherPersonPrices, function (price) {
              return _c("provider-price", {
                key: `other-${price.key}`,
                attrs: { price: price },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.extraPersonPrices, function (price) {
              return _c("extra-price", {
                key: `extra-${price.key}`,
                attrs: { price: price },
              })
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.taxPrices.length > 0
        ? [
            _c("div", { staticClass: "row is-headline" }, [_vm._v("Kurtaxe")]),
            _vm._v(" "),
            _vm._l(_vm.taxPrices, function (price) {
              return _c("extra-price", {
                key: `extra-${price.key}`,
                attrs: { price: price },
              })
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }