<template>
    <div class="margin-tax">
        <template v-if="marginTaxOn || marginOn">
            <label class="checkbox" @click.stop="">
                <input type="checkbox" v-model="enabled">
                <template v-if="enabled">{{ tax | priceView }}</template>
                <template v-else>-</template>
            </label>

            <popover trigger="hover">
                <i class="material-icons" v-if="enabled">info</i>
                <template slot="popover">
                    <strong>Bruttomarge (%):</strong> {{ margin | percentage }}
                </template>
            </popover>
        </template>
        <template v-else>
            -
        </template>
    </div>
</template>

<script>
import { priceView } from '@utilities/functions'
import Popover from '@components/Popover';
import Template from "@orders/subpages/TripDetails/General/Template";

export default {
    props: {
        value: Boolean,
        tax: [Number, String],
        margin: [Number, String],
        marginOn: Boolean
    },

    computed: {
        enabled: {
            get() { return this.value },
            set(value) { this.$emit('input', value )}
        },
        marginTaxOn: function() { return this.$store.state.marginTaxOn; },
    },

    components: {
        Template,
        Popover
    },

    filters: {
        priceView,
        percentage(value) {
            return (parseFloat(value) * 100).toFixed(2)
        }
    }
}
</script>
