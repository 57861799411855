<template>
        <div class="ml-5">
            <template v-if="row.complexity">
                <popover trigger="hover" >
                    <div class="circles" :class="getRentabilityIndexColorClass(row.complexity.rentabilityIndex)" v-bind:data-p="100" :title="'Erwartete Marge pro Person: ' + row.complexity.rentabilityIndex"><span>{{row.complexity.rentabilityIndex}}</span></div>
                    <div slot="popover">
                        <div class="level-left">
                            <span class="level-item">Marge:</span>
                            <div class="circles circles-popover level-item" :class="getRentabilityColorClass(row.complexity.rentability)" v-bind:data-p="100" :title="'Marge pro Person: ' + row.complexity.rentability"><span>{{row.complexity.rentability}}</span></div>
                        </div>
                        <div class="level-left">
                            <span class="level-item">Buchungswahrscheinlichkeit:</span>
                            <div class="circles circles-popover level-item" :class="getColorClass(row.complexity.bookingProbability)" v-bind:data-p="100" :title="'Buchungswahrscheinlichkeit: ' + row.complexity.bookingProbability + ' %'"><span>{{row.complexity.bookingProbability}}</span></div>
                        </div>
                        <div class="level-left">
                            <span class="level-item">Durchführungswahrscheinlichkeit:</span>
                            <div class="circles circles-popover level-item" :class="getColorClass(row.complexity.executionProbability)" v-bind:data-p="100" :title="'Durchführungswahrscheinlichkeit: ' + row.complexity.executionProbability + ' %'"><span>{{row.complexity.executionProbability}}</span></div>
                        </div>
                    </div>
                </popover>
            </template>
            <template v-else>
                    <div class="circles" data-p="0" title="Durchschnittspreis pro Person: ?"><span>?</span></div>
            </template>
        </div>
</template>

<script>
import Cell from './cell';
import Popover from "@components/Popover";
export default {
    mixins: [ Cell ],

    methods: {
        getColorClass(value) {
            if(value >= 80) {
                return 'is-green';
            }
            if(value <= 30) {
                return 'is-red';
            }
            return 'is-yellow';
        },

        getRentabilityIndexColorClass (value) {
            if(value >= 40) {
                return 'is-green';
            }
            if(value <= 15) {
                return 'is-red';
            }
            return 'is-yellow';
        },

        getRentabilityColorClass (value) {
             if(value <= 30 ) {
                 return 'is-red';
             }
             if(value >= 80) {
                 return 'is-green';
             }
             return 'is-yellow'
         },
    },
    components: {Popover}
}
</script>
