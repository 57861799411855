<template>
    <div>
        <main-object
            v-bind:headline="headline"
            v-bind:levels="levels"
            v-bind:priceType="priceType"
            v-bind:providerType="providerType"
            v-bind:show-filters="showFilters"
            v-bind:filter-start-end-keys="filterStartEndKeys"

            v-on:openForm="openTrainForm"
            v-on:openTrainRouteForm="openTrainRouteForm"
            ref="mainObject"
        />
        <item-form ref="form" v-on:submit="handleSubmit" v-bind:provider-type="providerType"/>
        <route-form ref="route" v-on:submit="handleSubmit" v-bind:provider-type="providerType"/>

    </div>



</template>

<script>

import Mixin from '../tab';
import PriceMixin from './mixins'
import ItemForm from './Forms/Train';
import RouteForm from './Forms/TrainRoute';

import MainObject from "@components/CRUD/Item/Tabs/ProviderPrices/Main";
import {getTrainRoute} from "@utilities/api";
import moment from "moment";



export default {
    mixins: [ Mixin, PriceMixin ],

    components: {ItemForm, MainObject, RouteForm},

    data: function () {
        return {
            headline: 'Zugverbindungen',
            levels: ['level', 'trainRoute', 'priceGroup', 'Price'],
            priceType: 'train',
            providerType: 'agency',
            showFilters: {
                'train_stations': "",
                'priceGroups.timePeriods.startAt[before]': '',
                'priceGroups.timePeriods.endAt[after]': '',
            },
            filterStartEndKeys: ['priceGroups.timePeriods.endAt[after]', 'priceGroups.timePeriods.startAt[before]'],
        }
    },

    methods: {
        openTrainRouteForm: function(route = {}) {
            if(route.id && !route.startTrainStation){
                this.isLoading = true
                getTrainRoute(route.id, '?_groups[]=agency_read&_groups[]=train_read&_groups[]=location_train_station&_groups[]=place_read&_groups[]=service_day_time').then(response => {
                    route = response.data
                    this.$refs.route.setType(response.data.agency ? 'agency' : 'direct');
                    this.$refs.route.open(route);
                }).then(() => { this.isLoading = false; });
            } else {
                this.$refs.route.open(route);
            }
        },

        openTrainForm: function (data = {}) {
            this.openPriceForm(data);
            this.$refs.form.setSettings();

        },
    }


}
</script>
