<template>
    <div class="tags">
        <span class="tag" :class="`is-${value}`" v-if="value">{{ category }}</span>
    </div>
</template>

<script>
import has from 'lodash/has';
import { getValueWithKey } from '@utilities/functions';

import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        category: function () {
            if (has(this.options, 'categories')) {
                return getValueWithKey({key: this.value, optionList: this.options.categories})
            } else {
                return getValueWithKey({key: this.value, optionList: this.options.category})
            }
        }
    }
}
</script>
