var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "row",
        { staticClass: "is-head" },
        [
          _c("template", { slot: "type" }, [_vm._v("Art")]),
          _vm._v(" "),
          _c("template", { slot: "name" }, [_vm._v("Leistung")]),
          _vm._v(" "),
          _c("template", { slot: "marginTax" }, [
            _vm._v(
              "Margensteuer (" +
                _vm._s(this.calculationOrder.orderCurrency) +
                ")"
            ),
          ]),
          _vm._v(" "),
          _vm.hasTwoCurrencies
            ? _c("template", { slot: "buyPriceCalculation" }, [
                _vm._v(
                  "EK (" +
                    _vm._s(this.calculationOrder.calculationCurrency) +
                    ")"
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("template", { slot: "buyPrice" }, [
            _vm._v(
              _vm._s(
                _vm.hasTwoCurrencies
                  ? "EK (" + this.calculationOrder.orderCurrency + ")"
                  : "Einkaufspreis"
              )
            ),
          ]),
          _vm._v(" "),
          _vm.hasTwoCurrencies
            ? _c("template", { slot: "sellPriceCalculation" }, [
                _vm._v(
                  "VK (" +
                    _vm._s(this.calculationOrder.calculationCurrency) +
                    ")"
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("template", { slot: "sellPrice" }, [
            _vm._v(
              _vm._s(
                _vm.hasTwoCurrencies
                  ? "VK (" + this.calculationOrder.orderCurrency + ")"
                  : "Verkaufspreis"
              )
            ),
          ]),
          _vm._v(" "),
          _vm.hasTwoCurrencies
            ? _c("template", { slot: "profitCalculation" }, [
                _vm._v(
                  "GS (" +
                    _vm._s(this.calculationOrder.calculationCurrency) +
                    ")"
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("template", { slot: "profit" }, [
            _vm._v(
              _vm._s(
                _vm.hasTwoCurrencies
                  ? "GS (" + this.calculationOrder.orderCurrency + ")"
                  : "Gewinnspanne"
              )
            ),
          ]),
          _vm._v(" "),
          _c("template", { slot: "margin" }, [_vm._v("Marge (%)")]),
          _vm._v(" "),
          _c("template", { slot: "actions" }, [
            _c(
              "button",
              {
                staticClass: "btn-single-toggle level-item",
                class: { "is-active": _vm.marginsEnabled },
                attrs: {
                  title: _vm.marginsEnabled
                    ? "Margensteuer deaktivieren"
                    : "Margensteuer aktivieren",
                },
                on: {
                  click: function ($event) {
                    _vm.marginsEnabled = !_vm.marginsEnabled
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-percent" })]
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _vm.doublePrices.length > 0 || _vm.singlePrices.length > 0
        ? [
            _vm._l(_vm.doublePrices, function (price) {
              return _c("package-price", {
                key: price.id,
                attrs: {
                  "has-two-currencies": _vm.hasTwoCurrencies,
                  "calculation-to-order-exchange-rate":
                    _vm.calculationToOrderExchangeRate,
                  price: price,
                },
                on: { "update:price": _vm.handlePriceUpdate },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.singlePrices, function (price) {
              return _c("package-price", {
                key: price.id,
                attrs: {
                  "has-two-currencies": _vm.hasTwoCurrencies,
                  "calculation-to-order-exchange-rate":
                    _vm.calculationToOrderExchangeRate,
                  price: price,
                },
                on: { "update:price": _vm.handlePriceUpdate },
              })
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }