import axios from 'axios';
import has   from 'lodash/has';

import Days  from '@components/Days';
import {
    Multiselect,
    InputMonth,
    InputPrice,
    DestinationSelect,
    PlaceSelect,
    AreaSelect,
    AsyncSelect
} from '@components/form';
import { notifySuccess, notifyError } from '@components/Notification';
import { daysToNumbers, numbersToDays, prepareForBackend } from '@utilities/functions';

import Buttons from './Buttons';


export default {
    props: {
        value: {},
        options: {
            type: Object,
            required: true
        },
    },

    form: {},

    data() {
        return {
            form: JSON.parse(JSON.stringify(this.value)),
            isLoading: false
        }
    },

    mounted: function () {
        if (!this.isEditMode) {
            this.form = JSON.parse(JSON.stringify(this.$options.form))
        }
    },

    computed: {
        url: function () {
            return this.$parent.api;
        },

        isEditMode: function () {
            return has(this.form, 'id');
        },

        preparedForBackend: function () {
            return prepareForBackend(this.form);
        },

        days: {
            get: function () {
                return daysToNumbers(this.form);
            },

            set: function (v) {
                this.form = Object.assign({}, this.form, numbersToDays(v));
            }
        },

        isValid: function () {
            return true;
        },

        _groups: function () {
            const formKeys = Object.keys(this.form);

            return [
                ...formKeys.includes('roomType') ? ['order_contingent_item_write'] : [],
                ...formKeys.includes('cabinType') ? ['order_contingent_item_write'] : []
            ]
        }
    },

    methods: {
        // Form
        cancelRow: function () {
            this.$parent.cancelEditing();
        },

        submitRow: function () {
            if (!this.isValid) {
                notifyError('Please fill fields!');
            } else {
                this.saveRow()
                    .then(response => {
                        this.$parent.refresh();
                        notifySuccess('Die Daten wurden erfolgreich gespeichert');
                    }, error => {
                        notifyError('Die Daten konnten nicht gespeichert werden');
                    })
            }
        },

        saveRow: function (row) {
            const data = this.preparedForBackend;
            if (this.isEditMode) {
                return axios.put(`/api/${this.url}/${this.form.id}`, data, {
                    params: {
                        _groups: this._groups
                    }
                })
            } else {
                return axios.post(`/api/${this.url}`, data, {
                    params: {
                        _groups: this._groups
                    }
                });
            }
        },
    },

    components: {
        Days,
        Multiselect,
        DestinationSelect,
        PlaceSelect,
        AreaSelect,
        AsyncSelect,
        InputMonth,
        InputPrice,
        Buttons
    }
}
