var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bp-panel is-form-contingent" }, [
    _c("div", { staticClass: "panel-label" }, [
      _vm._v("Fähre - " + _vm._s(_vm.nrOfPersons === 1 ? "EZ" : "DZ")),
    ]),
    _vm._v(" "),
    _c("header", [
      _c("div", [
        _vm._v(_vm._s(_vm.nrOfPersons) + " x "),
        _c("i", { staticClass: "material-icons" }, [_vm._v("directions_boat")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          !_vm.isOnlyAsked
            ? [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("check_circle"),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", [
        !_vm.isInPackage
          ? _c(
              "button",
              {
                staticClass: "has-margin-top",
                attrs: { title: "Extraticket/-kabine für Reisebegleitung" },
                on: { click: _vm.addExtraPeople },
              },
              [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("person_add"),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "main",
      { class: { "has-extra": _vm.hasExtra && !_vm.isInPackage } },
      [
        _vm._l(_vm.sortedAccomodations, function (accomodation, index) {
          return _c("div", { key: accomodation.id, staticClass: "row" }, [
            _c("div", [
              _c(
                "div",
                [
                  _vm.nrOfPersons === 1
                    ? [_vm._v("Einzelzimmer - ")]
                    : [_vm._v("Doppelzimmer - ")],
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.getValueWithKey({
                          key: accomodation.type.type,
                          optionList: _vm.types,
                        })
                      ) +
                      "\n                "
                  ),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              !_vm.isOnlyAsked
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: accomodation.reservedAmount,
                        expression: "accomodation.reservedAmount",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "input",
                    attrs: { type: "text" },
                    domProps: { value: accomodation.reservedAmount },
                    on: {
                      focus: function ($event) {
                        return $event.target.select()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          accomodation,
                          "reservedAmount",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: accomodation.askedAmount,
                    expression: "accomodation.askedAmount",
                    modifiers: { number: true },
                  },
                ],
                staticClass: "input",
                attrs: { type: "text" },
                domProps: { value: accomodation.askedAmount },
                on: {
                  focus: function ($event) {
                    return $event.target.select()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      accomodation,
                      "askedAmount",
                      _vm._n($event.target.value)
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", [
              !_vm.isOnlyAsked
                ? _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { title: "Copy value" },
                      on: {
                        click: function ($event) {
                          return _vm.setValues(accomodation)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("check_circle"),
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fa fa-long-arrow-left",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("help"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ])
        }),
        _vm._v(" "),
        _vm.hasExtra && !_vm.isInPackage
          ? _c("div", { staticClass: "row extra-people has-border-top" }, [
              _c("div", [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("person_add"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.accomodations[_vm.standardIndex].extraDescription,
                      expression:
                        "accomodations[standardIndex].extraDescription",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text" },
                  domProps: {
                    value:
                      _vm.accomodations[_vm.standardIndex].extraDescription,
                  },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.accomodations[_vm.standardIndex],
                        "extraDescription",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn-delete",
                    attrs: { title: "Löschen" },
                    on: { click: _vm.deleteExtra },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("delete"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                !_vm.isOnlyAsked
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value:
                            _vm.accomodations[_vm.standardIndex]
                              .extraReservedAmount,
                          expression:
                            "accomodations[standardIndex].extraReservedAmount",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "input",
                      attrs: { type: "text" },
                      domProps: {
                        value:
                          _vm.accomodations[_vm.standardIndex]
                            .extraReservedAmount,
                      },
                      on: {
                        focus: function ($event) {
                          return $event.target.select()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.accomodations[_vm.standardIndex],
                            "extraReservedAmount",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value:
                        _vm.accomodations[_vm.standardIndex].extraAskedAmount,
                      expression:
                        "accomodations[standardIndex].extraAskedAmount",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text" },
                  domProps: {
                    value:
                      _vm.accomodations[_vm.standardIndex].extraAskedAmount,
                  },
                  on: {
                    focus: function ($event) {
                      return $event.target.select()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.accomodations[_vm.standardIndex],
                        "extraAskedAmount",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", [
                !_vm.isOnlyAsked
                  ? _c(
                      "button",
                      {
                        staticClass: "button",
                        attrs: { title: "Copy value" },
                        on: { click: _vm.setExtraValues },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("check_circle"),
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "fa fa-long-arrow-left",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" "),
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("help"),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row has-border-top" }, [
          _c("div", [
            _c(
              "b",
              [
                _vm.nrOfPersons === 1
                  ? [_vm._v("Einzelkabine - ")]
                  : [_vm._v("Doppelkabine - ")],
                _vm._v("\n                    Gesamt\n                "),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            !_vm.isOnlyAsked
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.totalReserved) +
                      " (" +
                      _vm._s(
                        _vm.accomodations[_vm.standardIndex].extraReservedAmount
                      ) +
                      ")"
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "span",
              [
                _vm._v(_vm._s(_vm.totalAsk) + " "),
                _vm.accomodations[_vm.standardIndex].extraAskedAmount
                  ? [
                      _vm._v(
                        "(" +
                          _vm._s(
                            _vm.accomodations[_vm.standardIndex]
                              .extraAskedAmount
                          ) +
                          ")"
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div"),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("help")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }