var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "redesign-table" }, [
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _c("table-header", {
            attrs: {
              sellingPrices: _vm.sellingPrices,
              section: "calculation",
              item: _vm.sellingPrice,
              options: _vm.options,
            },
            on: {
              "change:section": _vm.toggleSection,
              "change:hotelType": _vm.changeHotelType,
              delete: function ($event) {
                return _vm.$emit("delete")
              },
              edit: function ($event) {
                return _vm.$emit("edit")
              },
              reset: function ($event) {
                return _vm.$emit("reset")
              },
              copy: (v) => _vm.$emit("copy", v),
            },
            model: {
              value: _vm.selectedSellingPrice,
              callback: function ($$v) {
                _vm.selectedSellingPrice = $$v
              },
              expression: "selectedSellingPrice",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "calculation" },
      [
        _c("calculation", {
          on: { update: (calculation) => _vm.$emit("update", calculation) },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }