var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "row",
    [
      _c("template", { slot: "type" }, [
        _c("i", { staticClass: "material-icons" }, [_vm._v("local_hotel")]),
        _vm._v(" "),
        _vm.isDouble
          ? _c("i", { staticClass: "material-icons" }, [_vm._v("local_hotel")])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("template", { slot: "name" }, [
        _c("div", { staticClass: "level-left" }, [
          _c(
            "span",
            { staticClass: "level-item" },
            [
              _vm.isSingle
                ? [
                    _vm._v(
                      "Einzelzimmerzuschlag inkl. Einzelkabinenzuschlag bis"
                    ),
                  ]
                : [_vm._v("Paketpreis im Doppelzimmer ab")],
              _vm._v(
                "\n                " +
                  _vm._s(_vm.price.numberOfPersons) +
                  " Pax\n            "
              ),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "marginTax" },
        [
          _c("margin-tax", {
            attrs: {
              tax: _vm.marginTax,
              margin: _vm.grossMargin,
              "margin-on": true,
            },
            model: {
              value: _vm.marginTaxEnabled,
              callback: function ($$v) {
                _vm.marginTaxEnabled = $$v
              },
              expression: "marginTaxEnabled",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasTwoCurrencies
        ? _c(
            "template",
            { slot: "buyPriceCalculation" },
            [
              _c("input-price", {
                attrs: {
                  readOnly: "",
                  value: _vm.buyPriceInCalculationCurrency,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "template",
        { slot: "buyPrice" },
        [
          _c("input-price", {
            attrs: {
              readOnly: "",
              value: _vm.buyPrice,
              "is-blue": _vm.hasTwoCurrencies,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasTwoCurrencies
        ? _c(
            "template",
            { slot: "sellPriceCalculation" },
            [
              _c("input-price", {
                model: {
                  value: _vm.sellPriceInCalculationCurrency,
                  callback: function ($$v) {
                    _vm.sellPriceInCalculationCurrency = $$v
                  },
                  expression: "sellPriceInCalculationCurrency",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "template",
        { slot: "sellPrice" },
        [
          _c("input-price", {
            attrs: { "is-blue": _vm.hasTwoCurrencies },
            model: {
              value: _vm.sellPrice,
              callback: function ($$v) {
                _vm.sellPrice = $$v
              },
              expression: "sellPrice",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasTwoCurrencies
        ? _c(
            "template",
            { slot: "profitCalculation" },
            [
              _c("input-price", {
                model: {
                  value: _vm.profitInCalculationCurrency,
                  callback: function ($$v) {
                    _vm.profitInCalculationCurrency = $$v
                  },
                  expression: "profitInCalculationCurrency",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "template",
        { slot: "profit" },
        [
          _c("input-price", {
            attrs: { "is-blue": _vm.hasTwoCurrencies },
            model: {
              value: _vm.profit,
              callback: function ($$v) {
                _vm.profit = $$v
              },
              expression: "profit",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "margin" },
        [
          _c("input-price", {
            model: {
              value: _vm.margin,
              callback: function ($$v) {
                _vm.margin = $$v
              },
              expression: "margin",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }