<template>
    <div class="row is-editing">
        <div class="cell" v-if="hotel || ferry">
            <multiselect
                v-model="type"
                track-by="key"
                label="label"
                v-bind:options="options.types"
            />
        </div>
        <div class="cell">
            <div class="flatpickr-combined">
                <input-date v-model="form.startAt" />
                <span>-</span>
                <input-date v-model="form.endAt" />
            </div>
        </div>
        <div class="cell">
            <async-select
                api     = "agencies"
                v-model = "form.agency"
                placeholder   = "direkt"
                crudLinkTitle = "Neue Agentur hinzufügen"
            />
        </div>
        <div class="cell">
            <input type="text" class="input is-small" v-model.number="form.numberOfPersons" />
        </div>
        <div class="cell is-actions">
            <buttons
                v-on:cancel="$emit('cancel')"
                v-on:submit="$emit('submit')"
                v-bind:loading="isLoading"
            />
        </div>
    </div>
</template>



<script>
import { TableForm } from '@components/CRUD/DataTable';
import { InputDate } from '@components/form'


export default {
    mixins: [ TableForm ],


    computed: {
        hotel: function () {
            return this.$parent.hotel
        },


        ferry: function () {
            return this.$parent.ferry;
        },


        type: {
            get: function () {
                return this.options.types.find(type => type.key === this.form.type)
            },

            set: function (value) {
                this.form.type = !!value ? value.key : null;
            }
        },
    },

    components: {
        InputDate
    }
}
</script>
