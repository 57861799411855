var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.request.type === "night"
      ? _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c("div", { staticClass: "bp-box is-price" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "main",
                [
                  _c(
                    "div",
                    { staticClass: "is-standard calculation" },
                    [
                      _c("div", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.getAccommodationLabel(
                                _vm.standardCabin.type.type,
                                _vm.standardCabin.type.persons,
                                _vm.cabinTypes
                              )
                            ) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("input-price", {
                        attrs: {
                          "provider-prices": _vm.standardCabin.providerPrices,
                          "small-input": true,
                          "price-currency": _vm.request.requestCurrency,
                        },
                        model: {
                          value: _vm.standardPrice,
                          callback: function ($$v) {
                            _vm.standardPrice = $$v
                          },
                          expression: "standardPrice",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.doubleCabins, function (accomodation) {
                    return _c(
                      "div",
                      { key: accomodation.id, staticClass: "calculation" },
                      [
                        _c("div", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.getAccommodationLabel(
                                  accomodation.type.type,
                                  accomodation.type.persons,
                                  _vm.cabinTypes
                                )
                              ) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("input-price", {
                          attrs: {
                            "provider-prices": accomodation.providerPrices,
                            "small-input": true,
                            value: _vm.getDifference(accomodation),
                            "price-currency": _vm.request.requestCurrency,
                          },
                          on: {
                            input: (v) => {
                              _vm.updatePrices(v, accomodation, "doubleCabins")
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "column" }, [
            _c("div", { staticClass: "bp-box is-price calculation" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "main",
                _vm._l(_vm.singleCabins, function (accomodation) {
                  return _c(
                    "div",
                    { key: accomodation.id },
                    [
                      _c("div", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.getAccommodationLabel(
                                accomodation.type.type,
                                accomodation.type.persons,
                                _vm.cabinTypes
                              )
                            ) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("input-price", {
                        attrs: {
                          "provider-prices": accomodation.providerPrices,
                          "small-input": true,
                          value: _vm.getDifference(accomodation),
                          "price-currency": _vm.request.requestCurrency,
                        },
                        on: {
                          input: (v) => {
                            _vm.updatePrices(v, accomodation, "singleCabins")
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              { staticClass: "bp-box is-price calculation" },
              [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "bus-prices",
                  {
                    model: {
                      value: _vm.request.contingent,
                      callback: function ($$v) {
                        _vm.$set(_vm.request, "contingent", $$v)
                      },
                      expression: "request.contingent",
                    },
                  },
                  _vm._l(_vm.otherCabins, function (accomodation) {
                    return _c(
                      "div",
                      { key: accomodation.id },
                      [
                        _c("div", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.getAccommodationLabel(
                                  accomodation.type.type,
                                  accomodation.type.persons,
                                  _vm.cabinTypes
                                )
                              ) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("input-price", {
                          attrs: {
                            "provider-prices": accomodation.providerPrices,
                            "small-input": true,
                            value: _vm.getDifference(accomodation),
                            "price-currency": _vm.request.requestCurrency,
                          },
                          on: {
                            input: (v) => {
                              _vm.updatePrices(v, accomodation, "otherCabins")
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
        ])
      : _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c("div", { staticClass: "bp-box is-price calculation" }, [
              _vm._m(3),
              _vm._v(" "),
              _c("main", [
                _c("div", { staticClass: "is-standard" }, [
                  _vm._m(4),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("input-price", {
                        attrs: {
                          "provider-prices":
                            _vm.request.contingent.providerPrices.ticket_price,
                          "small-input": true,
                          "price-currency": _vm.request.requestCurrency,
                        },
                        model: {
                          value: _vm.ticketPrice,
                          callback: function ($$v) {
                            _vm.ticketPrice = $$v
                          },
                          expression: "ticketPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              { staticClass: "bp-box is-price calculation" },
              [
                _vm._m(5),
                _vm._v(" "),
                _c("bus-prices", {
                  model: {
                    value: _vm.request.contingent,
                    callback: function ($$v) {
                      _vm.$set(_vm.request, "contingent", $$v)
                    },
                    expression: "request.contingent",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column is-one-third" },
        [
          _c("free-places", {
            attrs: { request: _vm.request },
            model: {
              value: _vm.request.freePlaces,
              callback: function ($$v) {
                _vm.$set(_vm.request, "freePlaces", $$v)
              },
              expression: "request.freePlaces",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c("item-prices", {
            attrs: { provider: _vm.provider },
            model: {
              value: _vm.request.itemPrices,
              callback: function ($$v) {
                _vm.$set(_vm.request, "itemPrices", $$v)
              },
              expression: "request.itemPrices",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons no-spacing" }, [
          _vm._v("directions_boat"),
        ]),
        _c("i", { staticClass: "material-icons" }, [_vm._v("directions_boat")]),
        _vm._v(" "),
        _c("b", [_vm._v("Doppelkabine")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons" }, [_vm._v("directions_boat")]),
        _vm._v(" "),
        _c("b", [_vm._v("Einzelkabine")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons" }, [_vm._v("help")]),
        _vm._v(" "),
        _c("b", [_vm._v("Sonstige Preise")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons" }, [_vm._v("credit_card")]),
        _vm._v(" Tickets Tagfähre"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("accessibility")]),
      _vm._v(" Pax"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons" }, [_vm._v("help")]),
        _vm._v(" "),
        _c("b", [_vm._v("Sonstige Preise")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }