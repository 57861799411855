var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "margin-tax" },
    [
      _vm.marginTaxOn || _vm.marginOn
        ? [
            _c(
              "label",
              {
                staticClass: "checkbox",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.enabled,
                      expression: "enabled",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.enabled)
                      ? _vm._i(_vm.enabled, null) > -1
                      : _vm.enabled,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.enabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.enabled = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.enabled = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.enabled = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _vm.enabled
                  ? [_vm._v(_vm._s(_vm._f("priceView")(_vm.tax)))]
                  : [_vm._v("-")],
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "popover",
              { attrs: { trigger: "hover" } },
              [
                _vm.enabled
                  ? _c("i", { staticClass: "material-icons" }, [_vm._v("info")])
                  : _vm._e(),
                _vm._v(" "),
                _c("template", { slot: "popover" }, [
                  _c("strong", [_vm._v("Bruttomarge (%):")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("percentage")(_vm.margin)) +
                      "\n            "
                  ),
                ]),
              ],
              2
            ),
          ]
        : [_vm._v("\n        -\n    ")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }