<template>
    <div>
        <div class="columns" v-if="request.type === 'night'">
            <div class="column">
                <div class="bp-box is-price">
                    <header>
                        <div><i class="material-icons no-spacing">directions_boat</i><i class="material-icons">directions_boat</i> <b>Doppelkabine</b></div>
                    </header>

                    <main>
                        <!-- Standard -->
                        <div class="is-standard calculation">
                            <div>
                                {{ getAccommodationLabel(standardCabin.type.type, standardCabin.type.persons, cabinTypes)}}
                            </div>
                            <input-price
                                v-model="standardPrice"
                                :provider-prices="standardCabin.providerPrices"
                                :small-input="true"
                                :price-currency="request.requestCurrency"
                            />
                        </div>


                        <!-- The rest -->
                        <div
                            v-for="accomodation in doubleCabins"
                            v-bind:key="accomodation.id"
                            class="calculation"
                        >
                            <div>
                                {{ getAccommodationLabel(accomodation.type.type, accomodation.type.persons, cabinTypes)}}
                            </div>
                            <input-price
                                :provider-prices="accomodation.providerPrices"
                                :small-input="true"
                                v-bind:value="getDifference(accomodation)"
                                v-on:input="(v) => { updatePrices(v, accomodation, 'doubleCabins') }"
                                :price-currency="request.requestCurrency"
                            />
                        </div>
                    </main>
                </div>
            </div>


            <div class="column">
                <div class="bp-box is-price calculation">
                    <header>
                        <div><i class="material-icons">directions_boat</i> <b>Einzelkabine</b></div>
                    </header>

                    <main>
                        <div v-for="accomodation in singleCabins" v-bind:key="accomodation.id">
                            <div>
                                {{ getAccommodationLabel(accomodation.type.type, accomodation.type.persons, cabinTypes)}}
                            </div>
                            <input-price
                                :provider-prices="accomodation.providerPrices"
                                :small-input="true"
                                v-bind:value="getDifference(accomodation)"
                                v-on:input="(v) => { updatePrices(v, accomodation, 'singleCabins') }"
                                :price-currency="request.requestCurrency"
                            />
                        </div>
                    </main>
                </div>
            </div>


            <div class="column">
                <div class="bp-box is-price calculation">
                    <header>
                        <div><i class="material-icons">help</i> <b>Sonstige Preise</b></div>
                    </header>

                    <bus-prices v-model="request.contingent">
                        <div v-for="accomodation in otherCabins" v-bind:key="accomodation.id">
                            <div>
                                {{ getAccommodationLabel(accomodation.type.type, accomodation.type.persons, cabinTypes)}}
                            </div>
                            <input-price
                                :provider-prices="accomodation.providerPrices"
                                :small-input="true"
                                v-bind:value="getDifference(accomodation)"
                                v-on:input="(v) => { updatePrices(v, accomodation, 'otherCabins') }"
                                :price-currency="request.requestCurrency"
                            />
                        </div>
                    </bus-prices>
                </div>
            </div>
        </div>

        <div class="columns" v-else>
            <div class="column">
                <div class="bp-box is-price calculation">
                    <header><div><i class="material-icons">credit_card</i> Tickets Tagfähre</div></header>

                    <main>
                        <div class="is-standard">
                            <div><i class="material-icons">accessibility</i> Pax</div>
                            <div>
                                <input-price
                                    v-model="ticketPrice"
                                    :provider-prices="request.contingent.providerPrices.ticket_price"
                                    :small-input="true"
                                    :price-currency="request.requestCurrency"
                                />
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <div class="column">
                <div class="bp-box is-price calculation">
                    <header>
                        <div><i class="material-icons">help</i> <b>Sonstige Preise</b></div>
                    </header>

                    <bus-prices v-model="request.contingent" />
                </div>
            </div>
        </div>


        <div class="columns">
            <div class="column is-one-third">
                <free-places v-model="request.freePlaces" v-bind:request="request"></free-places>
            </div>
            <div class="column">
                <item-prices v-model="request.itemPrices" v-bind:provider="provider"></item-prices>
            </div>
        </div>
        <div></div>
    </div>
</template>



<script>
import toNumber from 'lodash/toNumber';
import isNumber from 'lodash/isNumber';

import mixins from '../mixinsIndividually';
import BusPrices from './BusPrices';


export default {
    mixins: [mixins],


    components: { BusPrices },


    computed: {
        provider:  function () { return this.request.ferry },
        types:     function () { return this.$store.state.optionsCabinTypes; },
        cabinTypes: function() {return this.$store.state.options.cabinTypes.crud_prices ? this.$store.state.options.cabinTypes.crud_prices : [] },


        doubleCabins: function () { return this.request.contingent.doubleCabins.filter(cabin => cabin.type.type !== 'inside') },
        singleCabins: function () {
            let singleCabins = this.request.contingent.singleCabins;

            // moving 'outside' first (for the case we have more than 2 items)
            singleCabins = [
                    singleCabins.filter(x => x.type.type === 'outside')[0],
                   ...singleCabins.filter(x => x.type.type !== 'outside')
                ];

            // moving 'inside' first
            singleCabins = [
                    singleCabins.filter(x => x.type.type === 'inside')[0],
                    ...singleCabins.filter(x => x.type.type !== 'inside')
                ];

            return singleCabins;
        },
        otherCabins:  function () { return this.request.contingent.otherCabins },

        standardCabin: function () { return this.request.contingent.doubleCabins.find(cabin => cabin.type.type === 'inside')},

        ticketPrice: {
            get: function () {
                return this.request.contingent.ticketPrice ? this.request.contingent.ticketPrice.amount : 0
            },
            set: function (amount) {
                this.request.contingent.ticketPrice  = {
                    amount: amount,
                    currency: this.request.requestCurrency
                }
            }

        },

        standardPrice: {
            get: function () {
                const price = this.standardCabin && this.standardCabin.price && this.standardCabin.price.amount;
                return !!price ? price : '0';
            },

            set: function (price) {
                const newPrice = !isNumber(price) ? toNumber(price) : price;

                this.request.contingent.doubleCabins = this.request.contingent.doubleCabins
                    .map(cabin => {
                        if(cabin.type.type !== 'inside'){
                            return cabin;
                        }
                        return Object.assign({}, cabin, {
                            price: {
                                amount: newPrice.toString(),
                                currency: this.request.requestCurrency
                            }
                        })
                    });
            }
        },
    },


    methods: {
        updatePrices: function (newDifference, accomodation, type) {
            const newPrice = toNumber(newDifference);

            this.request.contingent[type] = this.request.contingent[type]
                .map(cabin => Object.assign({}, cabin, {
                    price: {
                        amount: cabin.id === accomodation.id ? newPrice.toString() : cabin.price.amount,
                        currency: this.request.requestCurrency
                    }

                }))
        },


        savePrice: function ({type, persons, price}) {
            const newPrice = !isNumber(price) ? toNumber(price) : price;

            if (type === 'inside' && persons === 2) {
                this.standardPrice = newPrice
            } else {
                const cabinType = persons === 2 ? 'doubleCabins' : (persons === 1 ? 'singleCabins' : 'otherCabins');
                this.request.contingent[cabinType] = this.request.contingent[cabinType]
                    .map(cabin => {
                        if (cabin.type.type === type && cabin.type.persons === persons) {
                            return Object.assign({}, cabin, {
                                price: {
                                    amount: newPrice.toString(),
                                    currency: this.request.requestCurrency
                                }
                            })
                        }

                        return cabin;
                    })
            }
        },


        savePrices: function (prices) {
            prices.forEach(price => {
                this.savePrice(price);
            })
        }
    },
}
</script>
