var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "level  is-align-items-baseline": !_vm.simple || _vm.showPerPage,
        "is-inline-flex": _vm.simple && !_vm.showPerPage,
      },
    },
    [
      !_vm.simple
        ? _c("p", { staticClass: "pagination-info" }, [
            _vm._v(
              "Auf der Seite werden " +
                _vm._s(_vm.range.from) +
                "-" +
                _vm._s(_vm.range.to) +
                " von " +
                _vm._s(_vm.pagination.items) +
                " " +
                _vm._s(_vm.namePlural) +
                " gezeigt"
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldShowPagination
        ? _c(
            "nav",
            {
              staticClass: "pagination is-centered",
              attrs: { role: "navigation", "aria-label": "navigation" },
            },
            [
              _c(
                "a",
                {
                  staticClass: "pagination-previous",
                  attrs: { disabled: _vm.pagination.current === 1 },
                  on: {
                    click: function ($event) {
                      return _vm.pageClicked(_vm.pagination.current - 1)
                    },
                  },
                },
                [
                  !_vm.simple
                    ? [_vm._v("Zurück")]
                    : _c("i", { staticClass: "material-icons" }, [
                        _vm._v("keyboard_arrow_left"),
                      ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "pagination-list" },
                [
                  _vm.hasFirst
                    ? _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "pagination-link",
                            class: { "is-current": _vm.isActive(1) },
                            on: {
                              click: function ($event) {
                                return _vm.pageClicked(1)
                              },
                            },
                          },
                          [_vm._v("1")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasFirstEllipsis
                    ? _c("li", [
                        _c("span", { staticClass: "pagination-ellipsis" }, [
                          _vm._v("…"),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.pages, function (page) {
                    return _c("li", { key: page }, [
                      _c(
                        "a",
                        {
                          staticClass: "pagination-link",
                          class: {
                            "is-current": _vm.isActive(page),
                            disabled: page === "...",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.pageClicked(page)
                            },
                          },
                        },
                        [_vm._v(_vm._s(page))]
                      ),
                    ])
                  }),
                  _vm._v(" "),
                  _vm.hasLastEllipsis
                    ? _c("li", [
                        _c("span", { staticClass: "pagination-ellipsis" }, [
                          _vm._v("…"),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasLast
                    ? _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "pagination-link",
                            class: {
                              "is-current": _vm.isActive(this.pagination.pages),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.pageClicked(_vm.pagination.pages)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.pagination.pages))]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "pagination-next",
                  attrs: {
                    disabled: _vm.pagination.current === _vm.pagination.pages,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.pageClicked(_vm.pagination.current + 1)
                    },
                  },
                },
                [
                  !_vm.simple
                    ? [_vm._v("Weiter")]
                    : _c("i", { staticClass: "material-icons" }, [
                        _vm._v("keyboard_arrow_right"),
                      ]),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.simple || _vm.showPerPage
        ? _c("div", { staticClass: "custom-page" }, [
            _c("p", [_vm._v("Elemente pro Seite:")]),
            _vm._v(" "),
            _c("input", {
              staticClass: "input is-shorter",
              attrs: { type: "text" },
              domProps: { value: _vm.pagination.perPage },
              on: { input: _vm.perPageChange },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }