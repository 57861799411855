var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row is-h-auto" }, [
    _c(
      "div",
      {
        staticClass: "cell is-gapless self-stretch",
        on: {
          click: function ($event) {
            return _vm.$refs.input.focus()
          },
        },
      },
      [
        _c("div", { staticClass: "input-wrapper" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.title,
                expression: "form.title",
              },
            ],
            ref: "input",
            staticClass: "input",
            attrs: { type: "text", readonly: _vm.readonly },
            domProps: { value: _vm.form.title },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "title", $event.target.value)
              },
            },
          }),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cell is-gapless" },
      [
        !_vm.readonly
          ? _c("resizable", [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.text,
                    expression: "form.text",
                  },
                ],
                staticClass: "textarea",
                attrs: { rows: "1" },
                domProps: { value: _vm.form.text },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "text", $event.target.value)
                  },
                },
              }),
            ])
          : _c("input", {
              staticClass: "input is-readonly",
              attrs: {
                type: "text",
                readonly: "",
                value: "wird in Reise festgelegt",
              },
            }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "cell is-70px-cell level-right" }, [
      _c(
        "div",
        { staticClass: "sort-number level-item" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c(
        "i",
        {
          staticClass: "material-icons",
          on: {
            click: function ($event) {
              return _vm.$emit("delete")
            },
          },
        },
        [_vm._v("delete")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }