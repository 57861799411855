<template>
    <div v-bind:class="['is-package', addition !== '' ? 'has-overlay' : '']">
        <div class="buttons has-addons">
            <!-- <button class="button" v-on:click="addition = 'package'">Leistung zu bestehendem Paket hinzufügen</button> -->
            <button class="button" v-on:click="addition = 'requests'">Weitere Leistungen zum Paket hinzufügen</button>
        </div>


        <!-- <add-package
            v-if="addition === 'package'"
            v-bind:request="request"
            v-on:update="updatePackage"
            v-on:cancel="addition = ''"
        ></add-package> -->


        <add-requests
            v-if="addition === 'requests'"
            v-bind:request="request"
            v-on:update="updatePackage"
            v-on:cancel="addition = ''"
        ></add-requests>


        <template v-if="!isLoading && request.package && request.package.id !== -1">
            <div class="bp-box is-package-list">
                <header><i class="fa fa-book"></i> {{ request.agency ? 'Paketleistungen von ' + request.agency.name : 'direkt' }}</header>

                <main>
                    <table>
                        <tr v-for="requestItem in request.package.requests" v-bind:key="requestItem.id">
                            <td>
                                <i class="material-icons" v-if="requestItem.requestType === 'hotel'">home</i>
                                <i class="material-icons" v-else-if="requestItem.requestType === 'ferry'">directions_boat</i>
                                <i class="material-icons" v-else-if="requestItem.requestType === 'airline'">airplanemode_active</i>
                                <other-service-icons v-else-if="requestItem.requestType === 'other'" v-bind:type="requestItem.otherServiceType.category"></other-service-icons>
                                <i title="Other" class="material-icons" v-else>map</i>
                                {{ requestItem.info.title }}
                            </td>

                            <td>
                                <template v-if="requestItem.info.provider.name">
                                    <i class="material-icons" title="Provider name">business</i> {{ requestItem.info.provider.name }}
                                </template>
                            </td>

                            <td>
                                <template v-if="requestItem.requestType === 'hotel'"><i class="material-icons">local_dining</i> {{ getValueWithKey({ key: requestItem.board, optionList: boardList }) }}</template>
                                <template v-else-if="requestItem.ferry === 'ferry' && requestItem.type === 'night'"><i class="material-icons">brightness_4</i> Nachtfähre</template>
                            </td>

                            <td>
                                <i class="material-icons" title="Status">map</i> {{ getValueWithKey({ key: requestItem.requestStatus.status, optionList: requestStatuses }) }}
                            </td>

                            <td>
                                <button
                                    v-if="requestItem.id !== request.id"
                                    v-on:click="deleteRequest(requestItem.id)"
                                    class="btn-delete"
                                    title="Löschen"
                                ><i class="material-icons">delete</i></button>
                            </td>
                        </tr>
                    </table>
                </main>
            </div>

            <div class="columns" v-if="packageHasHotel">
                <div class="column">
                    <div class="bp-box is-price">
                        <header>
                            <div><i class="material-icons no-spacing">local_hotel</i><i class="material-icons">local_hotel</i> <b>Paketpreise - Doppelzimmer</b></div>
                        </header>

                        <main>
                            <!-- Standard -->
                            <div class="is-standard">
                                <div>
                                    <i class="material-icons no-spacing">local_hotel</i>
                                    Paket - {{ getAccommodationLabel('standard', 2, packageLabels)}}
                                </div>
                                <div>
                                    <input-price v-model="basePrice" prefix=""></input-price>
                                </div>
                            </div>


                            <!-- The rest of rooms -->
                            <div
                                v-for="(accomodation, index) in request.package.basePrices"
                                v-bind:key="accomodation.id"
                                v-if="accomodation.roomType.persons === 2 && accomodation.roomType.type !== 'standard'"
                            >
                                <div>
                                    <i class="material-icons no-spacing">local_hotel</i>
                                    Paket - {{ getAccommodationLabel(accomodation.roomType.type, accomodation.roomType.persons, packageLabels)}}
                                </div>
                                <div>
                                    <input-price
                                        prefix=""
                                        v-bind:value="getDifference(accomodation.price.amount)"
                                        v-on:input="newDifference => setPrice(request.package.basePrices[index], newDifference)"
                                    ></input-price>
                                </div>
                            </div>


                            <!-- Ferry -->
                            <div
                                v-for="(accomodation, index) in request.package.basePrices"
                                v-bind:key="accomodation.id"
                                v-if="accomodation.cabinType.persons === 2 && accomodation.cabinType.type === 'outside'"
                            >
                                <div>
                                    <i class="material-icons no-spacing">directions_boat</i>
                                    Paket - {{ getAccommodationLabel('standard_' + accomodation.cabinType.type, accomodation.cabinType.persons, packageLabels)}}
                                </div>
                                <div>
                                    <input-price
                                        prefix=""
                                        v-bind:value="getDifference(accomodation.price.amount)"
                                        v-on:input="newDifference => setPrice(request.package.basePrices[index], newDifference)"
                                    ></input-price>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>

                <div class="column">
                    <div class="bp-box is-price">
                        <header>
                            <div><i class="material-icons no-spacing">local_hotel</i><i class="material-icons no-spacing">local_hotel</i><i class="material-icons">local_hotel</i> <b>Paketpreise - Einzelzimmer</b></div>
                        </header>

                        <main>
                            <div
                                v-for="(accomodation, index) in request.package.basePrices"
                                v-bind:key="accomodation.id"
                                v-if="accomodation.roomType.persons === 1"
                            >
                                <div>
                                    <i class="material-icons">local_hotel</i>
                                    Paket - {{ getAccommodationLabel(accomodation.roomType.type, accomodation.roomType.persons, packageLabels)}}
                                </div>
                                <div>
                                    <input-price
                                        prefix=""
                                        v-bind:value="getDifference(accomodation.price.amount)"
                                        v-on:input="newDifference => setPrice(request.package.basePrices[index], newDifference)"
                                    ></input-price>
                                </div>
                            </div>


                            <!-- Ferry -->
                            <div
                                v-for="(accomodation, index) in request.package.basePrices"
                                v-bind:key="accomodation.id"
                                v-if="accomodation.cabinType.persons === 1 && accomodation.cabinType.type === 'outside'"
                            >
                                <div>
                                    <i class="material-icons">directions_boat</i>
                                    Paket - {{ getAccommodationLabel('standard_' + accomodation.cabinType.type, accomodation.cabinType.persons, packageLabels)}}
                                </div>
                                <div>
                                    <input-price
                                        prefix=""
                                        v-bind:value="getDifference(accomodation.price.amount)"
                                        v-on:input="newDifference => setPrice(request.package.basePrices[index], newDifference)"
                                    ></input-price>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>

                <div class="column">
                    <div class="bp-box is-price">
                        <header>
                            <div><i class="material-icons no-spacing">local_hotel</i><i class="material-icons no-spacing">local_hotel</i><i class="material-icons">local_hotel</i> <b>Paketpreise - Mehrbettabschläge</b></div>
                        </header>

                        <main>
                            <div
                                v-for="(accomodation, index) in request.package.basePrices"
                                v-bind:key="accomodation.id"
                                v-if="accomodation.roomType.persons > 2"
                            >
                                <div>
                                    <i class="material-icons no-spacing">local_hotel</i>
                                    Paket - {{ getAccommodationLabel(accomodation.roomType.type, accomodation.roomType.persons, packageLabels)}}
                                </div>
                                <div>
                                    <input-price
                                        prefix=""
                                        v-bind:value="getDifference(accomodation.price.amount)"
                                        v-on:input="newDifference => setPrice(request.package.basePrices[index], newDifference)"
                                    ></input-price>
                                </div>
                            </div>

                            <div
                                v-for="(accomodation, index) in request.package.basePrices"
                                v-bind:key="accomodation.id"
                                v-if="accomodation.cabinType.persons > 2"
                            >
                                <div>
                                    <i class="material-icons no-spacing">directions_boat</i>
                                    Paket - {{ getAccommodationLabel('standard_' + accomodation.cabinType.type, accomodation.cabinType.persons, packageLabels)}}
                                </div>
                                <div>
                                    <input-price
                                        prefix=""
                                        v-bind:value="getDifference(accomodation.price.amount)"
                                        v-on:input="newDifference => setPrice(request.package.basePrices[index], newDifference)"
                                    ></input-price>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>


            <div class="columns" v-else-if="packageHasFerry">
                <div class="column">
                    <div class="bp-box is-price">
                        <header>
                            <div><i class="material-icons no-spacing">directions_boat</i><i class="material-icons">directions_boat</i> <b>Paketpreise - Doppelkabinenpreise und -zuschläge</b></div>
                        </header>

                        <main>
                            <!-- Standard -->
                            <div class="is-standard">
                                <div>
                                    <i class="material-icons no-spacing">directions_boat</i>
                                    Paket - {{ getAccommodationLabel('inside', 2, packageLabels)}}
                                </div>
                                <div>
                                    <input-price v-model="basePrice" prefix=""></input-price>
                                </div>
                            </div>

                            <div
                                v-for="(accomodation, index) in request.package.basePrices"
                                v-bind:key="accomodation.id"
                                v-if="accomodation.cabinType.persons === 2 && accomodation.cabinType.type !== 'inside'"
                            >
                                <div>
                                    <i class="material-icons no-spacing">directions_boat</i>
                                    Paket - {{ getAccommodationLabel(accomodation.cabinType.type, accomodation.cabinType.persons, packageLabels)}}
                                </div>
                                <div>
                                    <input-price
                                        prefix=""
                                        v-bind:value="getDifference(accomodation.price.amount)"
                                        v-on:input="newDifference => setPrice(request.package.basePrices[index], newDifference)"
                                    ></input-price>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>

                <div class="column">
                    <div class="bp-box is-price">
                        <header>
                            <div><i class="material-icons">directions_boat</i> <b>Paketpreise - Einzelkabinen</b></div>
                        </header>

                        <main>
                            <div
                                v-for="(accomodation, index) in request.package.basePrices"
                                v-bind:key="accomodation.id"
                                v-if="accomodation.cabinType.persons === 1"
                            >
                                <div>
                                    <i class="material-icons">directions_boat</i>
                                    Paket - {{ getAccommodationLabel(accomodation.cabinType.type, accomodation.cabinType.persons, packageLabels)}}
                                </div>
                                <div>
                                    <input-price
                                        prefix=""
                                        v-bind:value="getDifference(accomodation.price.amount)"
                                        v-on:input="newDifference => setPrice(request.package.basePrices[index], newDifference)"
                                    ></input-price>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>

                <div class="column">
                    <div class="bp-box is-price">
                        <header>
                            <div><i class="material-icons no-spacing">directions_boat</i><i class="material-icons no-spacing">directions_boat</i><i class="material-icons">directions_boat</i> <b>Paketpreise - Mehrbettkabinen</b></div>
                        </header>

                        <main>
                            <div
                                v-for="(accomodation, index) in request.package.basePrices"
                                v-bind:key="accomodation.id"
                                v-if="accomodation.cabinType.persons > 2"
                            >
                                <div>
                                    <i class="material-icons">directions_boat</i>
                                    Paket - {{ getAccommodationLabel(accomodation.cabinType.type, accomodation.cabinType.persons, packageLabels)}}
                                </div>
                                <div>
                                    <input-price
                                        prefix=""
                                        v-bind:value="getDifference(accomodation.price.amount)"
                                        v-on:input="newDifference => setPrice(request.package.basePrices[index], newDifference)"
                                    ></input-price>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>

            <div class="columns" v-else-if="packageHasTrain">
                <div class="column">
                    <div class="bp-box is-price">
                        <header>
                            <div><i class="material-icons no-spacing">directions_train</i><i class="material-icons">directions_train</i> <b>Paketpreise - Doppelabteil</b></div>
                        </header>

                        <main>
                            <!-- Standard -->
                            <div class="is-standard">
                                <div>
                                    <i class="material-icons no-spacing">directions_train</i>
                                    Paket - {{ getAccommodationLabel(standardCategory, 2, packageLabels)}}
                                </div>
                                <div>
                                    <input-price v-model="basePrice" prefix=""></input-price>
                                </div>
                            </div>

                            <div
                                v-for="(accomodation, index) in request.package.basePrices"
                                v-bind:key="accomodation.id"
                                v-if="accomodation.trainCabinType.persons === 2 && accomodation.trainCabinType.type !== standardCategory"
                            >
                                <div>
                                    <i class="material-icons no-spacing">directions_train</i>
                                    Paket - {{ getAccommodationLabel(accomodation.trainCabinType.type, accomodation.trainCabinType.persons, packageLabels)}}
                                </div>
                                <div>
                                    <input-price
                                        prefix=""
                                        v-bind:value="getDifference(accomodation.price.amount)"
                                        v-on:input="newDifference => setPrice(request.package.basePrices[index], newDifference)"
                                    ></input-price>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>

                <div class="column">
                    <div class="bp-box is-price">
                        <header>
                            <div><i class="material-icons">directions_train</i> <b>Paketpreise - Einzelabteile</b></div>
                        </header>

                        <main>
                            <div
                                v-for="(accomodation, index) in request.package.basePrices"
                                v-bind:key="accomodation.id"
                                v-if="accomodation.trainCabinType.persons === 1"
                            >
                                <div>
                                    <i class="material-icons">directions_train</i>
                                    Paket - {{ getAccommodationLabel(accomodation.trainCabinType.type, accomodation.trainCabinType.persons, packageLabels)}}
                                </div>
                                <div>
                                    <input-price
                                        prefix=""
                                        v-bind:value="getDifference(accomodation.price.amount)"
                                        v-on:input="newDifference => setPrice(request.package.basePrices[index], newDifference)"
                                    ></input-price>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>

                <div class="column">
                    <div class="bp-box is-price">
                        <header>
                            <div><i class="material-icons no-spacing">directions_boat</i><i class="material-icons no-spacing">directions_boat</i><i class="material-icons">directions_boat</i> <b>Paketpreise - Mehrbettkabinen</b></div>
                        </header>

                        <main>
                            <div
                                v-for="(accomodation, index) in request.package.basePrices"
                                v-bind:key="accomodation.id"
                                v-if="accomodation.cabinType.persons > 2"
                            >
                                <div>
                                    <i class="material-icons">directions_boat</i>
                                    Paket - {{ getAccommodationLabel(accomodation.cabinType.type, accomodation.cabinType.persons, packageLabels)}}
                                </div>
                                <div>
                                    <input-price
                                        prefix=""
                                        v-bind:value="getDifference(accomodation.price.amount)"
                                        v-on:input="newDifference => setPrice(request.package.basePrices[index], newDifference)"
                                    ></input-price>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>

            <template v-else>
                <div class="columns">
                    <div class="column is-one-third">
                        <div class="bp-box is-price">
                            <header>
                                <div>Paketpreise</div>
                            </header>

                            <main>
                                <div
                                    v-for="(accomodation, index) in request.package.basePrices"
                                    v-bind:key="accomodation.id"
                                    v-if="!accomodation.cabinType.type && !accomodation.roomType.type"
                                    class="is-standard"
                                >
                                    <div>
                                        Paketpreise
                                    </div>
                                    <div>
                                        <input-price v-model="basePrice" prefix=""></input-price>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </template>


            <div class="columns" v-if="request.package && request.package.id">
                <div class="column">
                    <free-places v-model="request.package.freePlaces" v-bind:request="request"></free-places>
                </div>
                <div class="column">
                    <item-prices :is-package="true" v-model="request.package.itemPrices"></item-prices>
                </div>
            </div>
        </template>


        <loading v-if="isLoading"></loading>
    </div>
</template>



<script>
import { store } from '@root/pages/orders/store';
import { editPackage, getPackage } from '@api';
import { getValueWithKey }         from '@utilities/functions';
import { notifySuccess, notifyError } from '@components/Notification';
import Loading                       from '@components/Loading';
import OtherServiceIcons             from '@components/OtherServiceIcons';
import { InputPrice }                from '@components/form';
/*import AddPackage  from './AddPackage';*/
import AddRequests from './AddRequests';
import ItemPrices  from '../ItemPrices';
import FreePlaces  from './FreePlaces';
import { getAccommodationLabel } from '@utilities/functions';

export default {
    store: store,


    props: {
        value: { type: Object, required: true },
        placeholder: {}
    },


    data: function () {
        return {
            request: JSON.parse(JSON.stringify(this.value)),
            // A boolean var for adding `REQUESTS` or `PACKAGES`
            addition: '',
            isLoading: false
        }
    },


    computed: {
        standardCategory: function () {
            return this.request.package.trainCategory === 'first' ? 'first' : 'second';
        },

        packageHasHotel: function () {
            if (this.request.package.id) {
                for (let i = 0, len = this.request.package.requests.length; i < len; i++) {
                    if (this.request.package.requests[i].requestType === 'hotel') {
                        return true;
                    }
                }
            }

            return false;
        },


        packageHasFerry: function () {
            if (this.request.package.id) {
                for (let i = 0, len = this.request.package.requests.length; i < len; i++) {
                    if (this.request.package.requests[i].requestType === 'ferry' &&
                        this.request.package.requests[i].type  === 'night') {
                        return true;
                    }
                }
            }

            return false;
        },

        packageHasTrain: function () {
            if (this.request.package.id) {
                for (let i = 0, len = this.request.package.requests.length; i < len; i++) {
                    if (this.request.package.requests[i].requestType === 'train' && this.request.package.hasNightTrain) {
                        return true;
                    }
                }
            }

            return false;
        },

        basePrice: {
            get: function () {
                return this.request.package.basePrices[this.standardIndex].price.amount;
            },
            set: function (value) {
                this.request.package.basePrices[this.standardIndex].price = {
                    amount: value,
                    currency: this.request.requestCurrency
                }
            }
        },


        standardIndex: function () {
            if (this.packageHasHotel) {
                return this.request.package.basePrices.findIndex(
                    x => x.roomType && x.roomType.type === 'standard' && x.roomType.persons === 2
                );

            } else if (this.packageHasFerry) {
                return this.request.package.basePrices.findIndex(
                    x => x.cabinType && x.cabinType.type === 'inside' && x.cabinType.persons === 2
                );

            } else if (this.packageHasTrain) {
                return this.request.package.basePrices.findIndex(
                    x => x.trainCabinType && x.trainCabinType.type === this.standardCategory && x.trainCabinType.persons === 2
                );

            } else {
                return 0;
            }
        },


        standardPrice: function () {
            return Number(this.request.package.basePrices[this.standardIndex].price.amount);
        },

        //isLoading:       function () { return this.$store.state.isLoading.modalRequest; },
        packageLabels:       function() {return this.$store.state.options.package.crud_prices ? this.$store.state.options.package.crud_prices : [] },
        requestStatuses: function () { return this.$store.state.options.requestStatuses; },
        boardList:       function () { return this.$store.state.options.boardList; }
    },


    methods: {
        getAccommodationLabel,

        // When adding a request to the package or when changing the package
        updatePackage: function (newPackage) {
            // Updating the package
            this.request.package = newPackage;
            // Hidding the modal (AddPackage or AddRequests)
            this.addition = '';
            // If the package is just a reference we need to get it
            this.getPackage();
        },


        // when getting the request from the API (the first time or on update) the package is only a reference
        getPackage: function () {
            if ((typeof this.request.package).toLowerCase() === 'string') {
                this.$store.commit('updateIsLoadingModalRequest', true);

                let id = parseInt(this.request.package.replace('/api/packages/', ''));

                getPackage(id, '?_groups[]=modal_read&_groups[]=order_request_read').then(response => {
                    this.request.package = response.data;
                    this.$store.commit('updateIsLoadingModalRequest', false);

                }, error => {
                    notifyError('Couldn\'t get the packages! Server error!');
                });
            }
        },


        deleteRequest: function (requestID) {
            if (confirm('Bist du sicher, dass du die Anfrage vom Preispaket entfernen möchtest?')) {
                let requestsCopy = JSON.parse(JSON.stringify(this.request.package.requests)),
                    index        = requestsCopy.findIndex(x => x.id === requestID);

                this.$store.commit('updateIsLoadingModalRequest', true);
                requestsCopy.splice(index, 1);
                requestsCopy = requestsCopy.map(x => '/api/requests/' + x.id);

                editPackage({ id: this.request.package.id, data: {
                    requests: requestsCopy,
                    basePrices:  this.request.package.basePrices,
                    freePlaces:  this.request.package.freePlaces,
                    invoiceItem: this.request.package.invoiceItem
                }, params: '?_groups[]=modal_read&_groups[]=order_request_read' }).then(response => {
                    this.request.package = response.data;
                    notifySuccess('Die Anfrage wurde vom Paket entfernt!');
                    this.$store.commit('updateIsLoadingModalRequest', false);

                }, error => {
                    notifyError('Das Preispaket konnte nicht gelöscht werden! Server Error!');
                });
            }
        },


        getDifference: function (price) {
            return parseFloat(price);
        },


        setPrice: function (accomodation, newDifference) {
            accomodation.price = {
                amount: (parseFloat(newDifference)).toFixed(2),
                currency: this.request.requestCurrency
            }
        },


        getValueWithKey
    },


    created: function () {
        let id = null;

        if (!this.request.package.id) {
            id = parseInt(this.request.package.replace('/api/packages/', ''));

        } else if (this.request.package.id !== -1) {
            id = this.request.package.id;
        }

        if (id !== null) {
            this.isLoading = true;

            getPackage(id, '?_groups[]=modal_read&_groups[]=order_request_read').then(response => {
                this.request.package = response.data;
                this.isLoading = false;

            }, error => {
                notifyError('Couldn\'t get the Package! Server Error!');
            });
        }
    },


    watch: {
        request: {
            handler: function () {
                this.$emit('input', this.request);
            },
            deep: true
        }
    },


    components: {
        /*AddPackage,*/
        AddRequests,
        FreePlaces,
        InputPrice,
        ItemPrices,
        Loading,
        OtherServiceIcons
    }
}
</script>
