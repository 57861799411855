<template>
    <div class="ml-5">
        <template v-if="row.complexity">
            <div class="circles" :class="getColorClass(row.complexity.offerComplexity)" v-bind:data-p="100" :title="'Angebot-Komplexität: ' + row.complexity.offerComplexity"><span>{{ row.complexity.offerComplexity }}</span></div>
        </template>
        <template v-else>
            <div class="circles" data-p="0" title="Angebot-Komplexität: ?"><span>?</span></div>
        </template>
    </div>
</template>

<script>
import Cell from './cell';

export default {
    mixins: [ Cell ],

    methods: {
        getColorClass(value) {
            if(value === 5) {
              return 'is-red';
            }
            if(value === 3 || value === 4) {
                return 'is-yellow';
            }
            if(value === 1 || value === 2) {
              return 'is-green'
            }
        }
    },
}
</script>
