<template>
    <loading v-if="isLoading"></loading>

    <div class="is-empty-cost" v-else-if="isEmpty">
        Berechnung ohne Preise nicht möglich
    </div>

    <div v-else>
        <div class="redesign-table is-costs">
            <div class="row is-head">
                <div class="cell">Leistung</div>
                <div class="cell"></div>
                <div class="cell">Anzahl</div>
                <div class="cell">Einzelpreis</div>
                <div class="cell">Gesamtpreis</div>
            </div>


            <package
                v-if="packageID"
                v-bind:calculations="calculations"
                v-bind:roomTypes="roomTypes"
                v-bind:cabinTypes="cabinTypes"
                v-bind:trainCabinTypes="trainCabinTypes"
                v-bind:isLoading="isLoading"
            ></package>


            <div class="is-zebra" v-else>
                <div class="row">
                    <div class="cell" v-if="calculations.contingent.priceType==='per_person'">{{ calculations.info.title }} - Preis pro Person</div>
                    <div class="cell" v-if="calculations.contingent.priceType==='per_group'">{{ calculations.info.title }} - Preis pro Gruppe</div>
                    <div class="cell">{{ calculations.durationInDays }}</div>
                    <div class="cell" v-if="calculations.contingent.priceType==='per_person'">{{ calculations.personsReserved }}</div>
                    <div class="cell" v-if="calculations.contingent.priceType==='per_group'">1</div>
                    <div class="cell">{{ calculations.info.standardPrice.amount | priceView }}</div>
                    <div class="cell"><b>{{ calculations.totalPriceReserved.amount | priceView }}</b></div>
                </div>

                <div class="row"
                    v-if="calculations.contingent.extraReservedAmount"
                >
                    <div class="cell">Zusätzlicher Teilnehmer - {{ calculations.contingent.extraDescription }}</div>
                    <div class="cell"></div>
                    <div class="cell">{{ calculations.contingent.extraReservedAmount }}</div>
                    <div class="cell">{{ calculations.contingent.price.amount | priceView }}</div>
                    <div class="cell"><b>{{ calculations.totalPriceExtraReserved.amount | priceView }}</b></div>
                </div>

                <div class="row"
                    v-if="calculations.personsFreePlaces"
                >
                    <div class="cell">Freiplatz</div>
                    <div class="cell"></div>
                    <div class="cell">{{ calculations.personsFreePlaces }}</div>
                    <div class="cell">{{ -1 * calculations.contingent.price.amount | priceView }}</div>
                    <div class="cell"><b>{{ -1 * calculations.totalPriceFreePlaces.amount | priceView }}</b></div>
                </div>


                <!-- ================ Item Prices ================  -->
                <div class="row"
                    v-for="item in calculations.itemPrices"
                    v-bind:key="'item-' + item.id"
                    v-if="item.billingType !== 'info' && item.limitIsValid"
                >
                    <div class="cell">Zuschlag - {{ item.name }}</div>
                    <div class="cell">{{ item.quantityType === 'per_person_night' || item.quantityType === 'per_single_room_night' ? calculations.durationInDays : ''}}</div>
                    <div class="cell">{{ item.calculatedQuantity}}</div>
                    <div class="cell">{{ item.price.amount | priceView }}</div>
                    <div class="cell"><b>{{ item.totalPrice.amount | priceView }}</b></div>
                </div>
            </div>

            <loading v-if="isLoading"></loading>
        </div>

        <div class="for-costs-total">
            <div>TOTAL ({{calculations.totalPrice.currency}})</div>
            <div><b>{{ calculations.totalPrice.amount | priceView }}</b></div>
        </div>
    </div>
</template>



<script>
import mixins from './mixins';


export default {
    mixins: [mixins],


    data: function () {
        return {
            apiEndpoint: 'other_requests/'
        }
    }
}
</script>
