<template>
    <div class="cell-content is-full is-flex">
        <template v-if="!hasProviders">-</template>
        <a
            v-else
            class="is-link is-truncated mr"
            :title="firstProvider.name"
            :href="providerLink(firstProvider)"
        >{{ firstProvider.name }}</a>
        <popover trigger="hover" v-if="otherProviders.length > 0">
            <div class="rounded-btn" v-if="otherProviders.length < 10">+{{otherProviders.length}}</div>
            <div class="rounded-btn" v-if="otherProviders.length > 9">> 9</div>

            <template slot="popover" v-if="otherProviders.length > 0">
                <div
                    v-for="provider in otherProviders"
                    :key="provider.id"
                >
                    <a class="is-link" :href="providerLink(provider)">{{ provider.name }}</a>
                </div>
            </template>
        </popover>
    </div>
</template>

<script>
import uniqBy from 'lodash/uniqBy'
import Popover from '@components/Popover'
import Cell from './cell';

export default {
    mixins: [ Cell ],

    props: {
        value: {
            type: [Array]
        }
    },

    methods: {
        providerLink(provider) {
            if(provider && provider.type === 'hotel'){
                return "/hotels/" + provider.id;
            }
            if(provider && provider.type === 'ferry'){
                return "/ferries/" + provider.id;
            }
            if(provider && provider.type === 'other'){
                return "/others/" + provider.id;
            }
            if(provider && provider.type === 'airline'){
                return "/airlines/" + provider.id;
            }
            return '';
        }
    },

    computed: {
        providers() {
            return uniqBy(this.value, provider => provider.id)
        },

        hasProviders() {
            return this.providers && this.providers.length > 0
        },

        firstProvider() {
            return this.providers[0]
        },

        otherProviders() {
            return this.providers.slice(1)
        }
    },

    components: {
        Popover
    }
}
</script>
