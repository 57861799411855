var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.concepts, function (concept) {
      return _c(
        "div",
        {
          key: concept.id,
          staticClass: "is-variation",
          class: { "is-selected": _vm.value === concept },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.selectConcept(concept)
            },
          },
        },
        [
          concept.category
            ? _c("div", { staticClass: "tag" }, [
                _c("span", { staticClass: "is-truncated" }, [
                  _vm._v(_vm._s(_vm.categoryName(concept))),
                ]),
              ])
            : _c("div", { staticClass: "tag is-placeholder" }),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: { content: concept.name, delay: { show: 1000 } },
                  expression: "{content: concept.name, delay: {show: 1000}}",
                },
              ],
              staticClass: "is-truncated",
            },
            [_vm._v(_vm._s(concept.name))]
          ),
          _vm._v(" "),
          _vm.icons(concept).length > 0
            ? _c(
                "div",
                { staticClass: "icons" },
                _vm._l(_vm.icons(concept), function (icon) {
                  return _c("i", { key: icon, staticClass: "material-icons" }, [
                    _vm._v(_vm._s(icon)),
                  ])
                }),
                0
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }