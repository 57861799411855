<template>
    <modal-form
        editTitle="Adresse bearbeiten"
        createTitle="Neue Adresse hinzufügen"
    >
        <table>
            <tr>
                <th><label for="title" class="label">Firma:</label></th>
                <td>
                    <div class="control">
                        <input type="text" class="input" v-model="form.title" id="title">
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="streetAddress" class="label">Straße:</label></th>
                <td>
                    <div class="control">
                        <input type="text" class="input" v-model="form.streetAddress" id="streetAddress">
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="zip" class="label">Postleitzahl:</label></th>
                <td>
                    <div class="control">
                        <input type="text" class="input" v-model="form.zip" id="zip">
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="city" class="label">Stadt:</label></th>
                <td>
                    <div class="control">
                        <input type="text" class="input" v-model="form.city" id="city">
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="country" class="label">Land:</label></th>
                <td>
                    <div class="control">
                        <multiselect
                            v-model="country"
                            track-by="label"
                            label="name"
                            v-bind:options="countries"
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <th>
                    <label for="type" class="label">Adressart:</label>
                </th>
                <td>
                    <div class="control">
                        <div class="select">
                            <select id="type" v-model="form.type">
                                <option
                                    v-for="option in options.type"
                                    v-bind:key="option.key"
                                    v-bind:value="option.key"
                                >{{option.value}}</option>
                            </select>
                        </div>
                    </div>
                </td>
            </tr>
            <tr v-if="form.type === 'port'">
                <th>
                    <label for="port" class="label">Hafen:</label>
                </th>
                <td>
                    <async-select
                        api     = "ports"
                        v-model = "form.port"
                        v-bind:custom-label = "item => item.place.name"
                        crudLinkTitle       = "Fügen Sie einen neuen Port hinzu"
                    />
                </td>
            </tr>
            <tr v-if="form.type === 'airport'">
                <th>
                    <label for="airport" class="label">Flughafen:</label>
                </th>
                <td>
                    <async-select
                        api           = "airports"
                        v-model       = "form.airport"
                        crudLinkTitle = "Fügen Sie einen neuen Flughafen hinzu"
                    />
                </td>
            </tr>

            <template v-if="!editMode && hasContacts">
                <tr>
                    <td colspan="2">
                        <h2>Kontaktdaten</h2>
                    </td>
                </tr>
                <tr>
                    <th><label for="email" class="label">Email:</label></th>
                    <td>
                        <div class="control">
                            <input type="text" id="email" class="input" v-model="contact.email">
                        </div>
                    </td>
                </tr>
                <tr>
                    <th><label for="phoneFixed" class="label">Telefon:</label></th>
                    <td>
                        <div class="control">
                            <input type="text" id="phoneFixed" class="input" v-model="contact.phoneFixed">
                        </div>
                    </td>
                </tr>
                <tr>
                    <th><label for="phoneMobile" class="label">Mobil:</label></th>
                    <td>
                        <div class="control">
                            <input type="text" id="phoneMobile" class="input" v-model="contact.phoneMobile">
                        </div>
                    </td>
                </tr>
                <tr>
                    <th><label for="fax" class="label">Fax:</label></th>
                    <td>
                        <div class="control">
                            <input type="text" id="fax" class="input" v-model="contact.fax">
                        </div>
                    </td>
                </tr>
                <tr>
                    <th><label for="languages" class="label">Sprache:</label></th>
                    <td>
                        <div class="control">
                            <multiselect
                                v-model="contactLanguages"
                                track-by="label"
                                label="name"
                                v-bind:options="languages"
                                multiple
                            >
                                <span slot="caret"></span>
                            </multiselect>
                        </div>
                    </td>
                </tr>

                <tr>
                    <th><label for="" class="label">Primärkontakt:</label></th>
                    <td>
                        <div class="control">
                            <label class="radio">
                                <input type="radio" value="primary" v-model="contact.type">ja
                            </label>
                            <label class="radio">
                                <input type="radio" value="0" v-model="contact.type">nein
                            </label>
                        </div>
                    </td>
                </tr>
            </template>
        </table>
    </modal-form>
</template>



<script>
import includes from 'lodash/includes';
import omit from 'lodash/omit'

import { countries, languages } from '@utilities/variables';
import TabForm from '../form';
import has from "lodash/has";

const EMTPY_CONTACT = {
    email: '',
    phoneFixed: '',
    phoneMobile: '',
    fax: '',
    languages: [],
    type: 'primary'
};

export default {
    mixins: [TabForm],

    form: {
        title: '',
        streetAddress: '',
        zip: '',
        city: '',
        country: '',
        type: '',
        port: null,
        airport: null,
    },

    property: 'addresses',

    data: function () {
        return {
            countries,
            languages,

            contact: JSON.parse(JSON.stringify(EMTPY_CONTACT))
        }
    },

    computed: {
        hasContacts: function () {
            return has(this.$store.state.contacts, 'contacts');
        },

        country: {
            get() {
                return countries.find(country => country.label === this.form.country)
            },

            set(country) {
                this.form.country = !!country ? country.label : null
            }
        },

        contactLanguages: {
            get: function () {
                return languages.filter(language => includes(this.contact.languages, language.label));
            },

            set: function (v) {
                this.contact.languages = v.map(language => language.label);
            }
        },

        api: function() {
            return this.$store.state.api;
        },

        apiURL: function() {
            const id = this.$store.state.id;
            return `/api/${this.api}/${id}`
        }
    },

    methods: {
        handleFormSubmit: function() {
            this.$store.dispatch('updateProperty', {
                key: this.$options.property,
                data: {
                    ...omit(this.form, ['contacts']),
                    ...!this.form.id && {
                        contacts: [{
                            role: 'general_contact',
                            [{
                                agencies: 'agency',
                                clients: 'client',
                                hotels: 'provider',
                                ferries: 'provider',
                                others: 'provider',
                                airlines: 'provider'
                            }[this.api]]: [this.apiURL],
                            ...this.contact
                        }]
                    }
                }
            }).then(response => {
                        this.$store.commit('updateData', {
                            contacts: response.contacts,
                        });
                    this.visible = false;
                    this.notifySuccess('Addresse wurde hinzugefügt!')
                }, error => {
                    this.notifyError("Addresse konnte nicht hinzugefügt werden! Error happened")
                })
        },

        openCallback: function() {
            this.contact = JSON.parse(JSON.stringify(EMTPY_CONTACT))
        },
    }
}
</script>
