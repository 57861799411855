var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Passende Leistungsträger auswählen",
        createTitle: "Passende Leistungsträger auswählen",
        isLoading: _vm.isLoading,
      },
    },
    [
      _c(
        "table",
        [
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [
              _c("h2", [
                _vm._v("Passende Leistungsträger "),
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    on: { click: _vm.addProvider },
                  },
                  [_vm._v("add_circle")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.form.providers, function (provider) {
            return [
              _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Klassifizierung:"),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "columns" }, [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _vm.classifications
                          ? _c("multiselect", {
                              staticClass: "is-shorter",
                              attrs: {
                                "track-by": "key",
                                label: "value",
                                options: _vm.classifications,
                              },
                              model: {
                                value: provider.classification,
                                callback: function ($$v) {
                                  _vm.$set(provider, "classification", $$v)
                                },
                                expression: "provider.classification",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "column is-narrow",
                        staticStyle: { "flex-direction": "row" },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn-delete",
                            attrs: { type: "button", title: "Löschen" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteProvider(provider)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("delete"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Leistungsträger:"),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "columns" }, [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c("async-select", {
                          attrs: {
                            api: _vm.api,
                            placeholder: "Leistungsträger auswählen",
                            params: _vm.params,
                          },
                          model: {
                            value: provider.provider,
                            callback: function ($$v) {
                              _vm.$set(provider, "provider", $$v)
                            },
                            expression: "provider.provider",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "column is-narrow",
                        staticStyle: { "flex-direction": "row" },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn-delete",
                            attrs: { type: "button", title: "Löschen" },
                            on: {
                              click: function ($event) {
                                _vm.isLinked = !_vm.isLinked
                              },
                            },
                          },
                          [
                            _c("i", {
                              class: [
                                _vm.isLinked
                                  ? "fa fa-link fa-lg"
                                  : "fa fa-unlink fa-lg",
                              ],
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Agentur:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("async-select", {
                      attrs: { api: "agencies", placeholder: "direkt" },
                      model: {
                        value: provider.agency,
                        callback: function ($$v) {
                          _vm.$set(provider, "agency", $$v)
                        },
                        expression: "provider.agency",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [_c("td", { attrs: { colspan: "2" } }, [_c("h2")])]),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }