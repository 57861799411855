<template>
    <div>
        <other-service-icons v-if="isOther" :type="provider.type" />
        <i v-else class="material-icons mr">{{providerIcon}}</i>
        <span v-if="provider">{{provider.name}}</span>
        <span v-else-if="agency">{{agency.name}}</span>
    </div>
</template>

<script>
import Cell from './cell';
import OtherServiceIcons from '@components/OtherServiceIcons';

export default {
    mixins: [ Cell ],
    
    computed: {
        provider: function () {
            return !!this.row.provider ? this.row.provider : false
        },

        agency: function () {
            return !!this.row.agency ? this.row.agency : false;
        },

        isOther: function () {
            return !!this.provider && this.value.providerType === 'other'
        },

        providerIcon() {
            if (!!this.provider) {
                if (this.value.providerType === 'ferry') {
                    return 'directions_boat'
                } else if (this.value.providerType === 'hotel') {
                    return 'home'
                } else if (this.value.providerType === 'airline') {
                    return 'airplanemode_active'
                }
            }
            
            return 'help'
        },
    },

    components: {
        OtherServiceIcons
    }
}
</script>
