var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row is-editing" }, [
    _vm.hotel || _vm.ferry
      ? _c(
          "div",
          { staticClass: "cell" },
          [
            _c("multiselect", {
              attrs: {
                "track-by": "key",
                label: "label",
                options: _vm.options.types,
              },
              model: {
                value: _vm.type,
                callback: function ($$v) {
                  _vm.type = $$v
                },
                expression: "type",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "cell" }, [
      _c(
        "div",
        { staticClass: "flatpickr-combined" },
        [
          _c("input-date", {
            model: {
              value: _vm.form.startAt,
              callback: function ($$v) {
                _vm.$set(_vm.form, "startAt", $$v)
              },
              expression: "form.startAt",
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("-")]),
          _vm._v(" "),
          _c("input-date", {
            model: {
              value: _vm.form.endAt,
              callback: function ($$v) {
                _vm.$set(_vm.form, "endAt", $$v)
              },
              expression: "form.endAt",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cell" },
      [
        _c("async-select", {
          attrs: {
            api: "agencies",
            placeholder: "direkt",
            crudLinkTitle: "Neue Agentur hinzufügen",
          },
          model: {
            value: _vm.form.agency,
            callback: function ($$v) {
              _vm.$set(_vm.form, "agency", $$v)
            },
            expression: "form.agency",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "cell" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.form.numberOfPersons,
            expression: "form.numberOfPersons",
            modifiers: { number: true },
          },
        ],
        staticClass: "input is-small",
        attrs: { type: "text" },
        domProps: { value: _vm.form.numberOfPersons },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "numberOfPersons", _vm._n($event.target.value))
          },
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cell is-actions" },
      [
        _c("buttons", {
          attrs: { loading: _vm.isLoading },
          on: {
            cancel: function ($event) {
              return _vm.$emit("cancel")
            },
            submit: function ($event) {
              return _vm.$emit("submit")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }