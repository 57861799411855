import { Multiselect, DateTime } from '@components/form';


export default {
    props: {
        options:     {},
        placeholder: {},
        // For saving action
        isLoading:   { type: Boolean, required: true }
    },


    data: function () {
        return {
            copyPlaceholder: null,

            placeholderStringify: ''
        };
    },


    methods: {
        init: function () {
            if (this.placeholder) {
                this.copyPlaceholder = JSON.parse(JSON.stringify(this.placeholder));
            }
        },


        save: function () {
            this.copyPlaceholder.serviceDescription = null;
            this.copyPlaceholder.routeDescription = null;
            this.$emit('save', this.copyPlaceholder);
        },


        onKeyDown: function (event) {
            // Initial value save
            if (event.keyCode === 13) {
                this.placeholderStringify = JSON.stringify(this.copyPlaceholder);
            }
        },


        onKeyUp:   function () { /* polymorphism */ },


        onEnterSubmit: function () {
            window.addEventListener('keydown', this.onKeyDown);
            window.addEventListener('keyup',   this.onKeyUp);
        }
    },


    created: function () {
        this.init();
        this.onEnterSubmit();
    },


    beforeDestroy: function () {
        window.removeEventListener('keydown', this.onKeyDown);
        window.removeEventListener('keyup',   this.onKeyUp);
    },


    components: {
        DateTime,
        Multiselect
    }
};
