var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.start && _vm.end
    ? _c(
        "span",
        [
          _vm.time(_vm.end) !== "00:00"
            ? [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("time")(_vm.start)) +
                    " - " +
                    _vm._s(_vm._f("time")(_vm.end)) +
                    "\n    "
                ),
              ]
            : _vm.time(_vm.start) !== "00:00"
            ? [
                _vm._v(
                  "\n        " + _vm._s(_vm._f("time")(_vm.start)) + "\n    "
                ),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }