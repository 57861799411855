var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "row is-headline is-small",
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.toggleCollapse.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "level-left" }, [
            _c(
              "i",
              {
                staticClass: "collapse-icon material-icons level-item",
                class: { "is-rotated": !_vm.isOpen },
              },
              [_vm._v("keyboard_arrow_up")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "level-item" }, [
              _vm._v(_vm._s(_vm.boardName)),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.isOpen
        ? _vm._l(_vm.groups, function (priceGroup) {
            return _c("price-group-row", {
              key: priceGroup.id,
              attrs: {
                priceGroup: priceGroup,
                options: _vm.options,
                "show-next-row": _vm.board.groups.length < 2,
                "price-type": "hotel",
              },
              on: {
                updatePriceGroup: _vm.updatePriceGroup,
                editPriceGroup: (priceGroup) =>
                  _vm.$emit("editPriceGroup", priceGroup),
                deletePriceGroup: (priceGroup) =>
                  _vm.$emit("deletePriceGroup", priceGroup),
              },
            })
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }