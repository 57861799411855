var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.sortedPlaceholders, function (placeholder) {
      return _c(
        "div",
        {
          key: placeholder.placeholderType + "-" + placeholder.id,
          staticClass: "is-placeholder",
          class: {
            "is-optional": _vm.isOptional(placeholder),
            "has-more-info": placeholder.placeholderType === "route",
            "is-additional": _vm.isAdditional(placeholder),
          },
        },
        [
          _c(
            "div",
            { staticClass: "level-left" },
            [
              placeholder.placeholderType === "ferry"
                ? _c("i", { staticClass: "material-icons" }, [
                    _vm._v("directions_boat"),
                  ])
                : placeholder.placeholderType === "airline"
                ? _c("i", { staticClass: "material-icons" }, [
                    _vm._v("airplanemode_active"),
                  ])
                : placeholder.placeholderType === "other"
                ? _c("other-service-icons", {
                    attrs: { type: placeholder.otherServiceType.category },
                  })
                : placeholder.placeholderType === "hotel"
                ? _c("i", { staticClass: "material-icons" }, [_vm._v("home")])
                : _vm._e(),
              _vm._v(" "),
              placeholder.placeholderType === "route"
                ? _c("i", { staticClass: "material-icons" }, [
                    _vm._v("filter_hdr"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: _vm.description(placeholder),
                        delay: { show: 1000 },
                      },
                      expression:
                        "{content: description(placeholder), delay: {show: 1000}}",
                    },
                  ],
                  class: {
                    "is-optional": _vm.isOptional(placeholder),
                    "is-additional": _vm.isAdditional(placeholder),
                  },
                },
                [
                  _c("span", { staticClass: "is-truncated" }, [
                    _vm.isOptional(placeholder)
                      ? _c("span", [_vm._v("Möglichkeit:")])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.description(placeholder)))]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          placeholder.placeholderType === "hotel"
            ? _c("div", { staticClass: "form" }, [
                _c("input", {
                  staticClass: "input is-tiny",
                  attrs: { type: "text" },
                  domProps: { value: placeholder.nights },
                  on: {
                    input: (e) => {
                      _vm.updatePlaceholder(placeholder, e.target.value)
                    },
                  },
                }),
                _vm._v("\n            Nächte\n        "),
              ])
            : _vm._e(),
          _vm._v(" "),
          placeholder.placeholderType === "other"
            ? _c("div", { staticClass: "form" }, [
                _c("input", {
                  staticClass: "input is-tiny",
                  attrs: { type: "text" },
                  domProps: { value: placeholder.durationDays },
                  on: {
                    input: (e) => {
                      _vm.updatePlaceholder(placeholder, e.target.value)
                    },
                  },
                }),
                _vm._v("\n            Tage\n        "),
              ])
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }