var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is-light" },
    [
      _c(
        "row",
        [
          _c("template", { slot: "type" }, [
            _c("i", { staticClass: "material-icons" }, [
              _vm._v("subdirectory_arrow_right"),
            ]),
          ]),
          _vm._v(" "),
          _c("template", { slot: "date" }, [_vm._v("-")]),
          _vm._v(" "),
          _c("template", { slot: "nights" }, [_vm._v("-")]),
          _vm._v(" "),
          _c("template", { slot: "name" }, [
            _vm._v("Buspreis für die Fähre (inklusive Steuern)"),
          ]),
          _vm._v(" "),
          _c("template", { slot: "provider" }, [_vm._v("-")]),
          _vm._v(" "),
          _c("template", { slot: "currency" }, [
            _c("span", { staticClass: "is-currency" }, [
              _vm._v(_vm._s(_vm.placeholder.placeholderCurrency)),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "sellPrice" },
            [
              _c("input-price", {
                attrs: {
                  linked: _vm.hasCalculationRequest,
                  "value-in-order-currency": _vm.busBuyPriceInOrderCurrency,
                  "provider-prices": _vm.getProviderPrices(this.groupPriceType),
                  "price-currency": _vm.currency,
                },
                model: {
                  value: _vm.busBuyPrice,
                  callback: function ($$v) {
                    _vm.busBuyPrice = $$v
                  },
                  expression: "busBuyPrice",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "profit" },
            [
              _c("input-price", {
                attrs: {
                  value: _vm.busPrice,
                  readOnly: "",
                  linked: _vm.hasCalculationRequest,
                  highlight: !!_vm.highlight.person,
                  "value-in-order-currency": _vm.busPriceInOrderCurrency,
                  "price-currency": _vm.currency,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "margin" },
            [
              _vm.supplementPrices
                ? _c("span", { staticClass: "empty" }, [_vm._v("-")])
                : _c("input-price", {
                    attrs: {
                      value: _vm.busPrice,
                      readOnly: true,
                      linked: _vm.hasCalculationRequest,
                      highlight: !!_vm.highlight.single,
                      "value-in-order-currency": _vm.busPriceInOrderCurrency,
                      "price-currency": _vm.currency,
                    },
                  }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }