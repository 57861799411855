<template>
    <div
        class="input-price field has-addons"
        :class="{'is-read-only': isReadOnly, 'is-highlight': highlight, 'is-darken': isDarken}"
        v-on:click="focus"
        v-on:keyup.tab="focus"
    >
        <loaded-price
            v-if="hasLoadedPrices && !showExchangeRates"
            :providerPrices="!!providerPrices ? providerPrices : []"
            :value="localPrice"
            v-on:input="updateFromLoadedPrices"
            :small-input="smallInput"
            :price-currency="priceCurrency ? priceCurrency : orderCurrency"
        />

        <div v-if="invisible">
            <span
                class="circle is-invisible"
            ></span>
        </div>

        <div class="link" v-if="!showExchangeRates && linked" v-on:click.stop="$emit('unlink')">
            <i class="material-icons is-pointer">link</i>
        </div>

        <div v-if="(alwaysShowOrderCurrency || showExchangeRates) && valueInOrderCurrency" class="inOrderCurrency">{{ valueInOrderCurrency | priceView}} <template v-if="valueInOrderCurrency < 1000"> {{ orderCurrency }}</template></div>

        <div v-if="defaultExchangeRate" class="inOrderCurrency">{{ defaultExchangeRate | priceView(this.digits)}}</div>


        <div class="control is-expanded">
            <input
                class="input"
                :class="{'is-blue': isBlue}"
                :style="inputStyle"
                type="text"
                ref="input"
                v-bind:value="inputValue"
                v-on:blur="updatePrice"
                v-on:focus="focus"
                v-bind:disabled="disabled"
                v-bind:readOnly="isReadOnly"
                :placeholder="placeholder"
            />
        </div>
        <div class="control" v-if="suffix">
            <div class="button is-static" >{{suffix}}</div>
            <slot name="prefix"></slot>
        </div>
    </div>
</template>

<script>
import { InputPrice } from '@components/form';
import LoadedPrice from './LoadedPrice';
import store       from '../store';
import {monthYear, priceView} from '@utilities/functions';
import Template from "@orders/subpages/TripDetails/General/Template";


export default {
    store,

    mixins: [ InputPrice ],

    props: {
        highlight: {
            type: Boolean,
            default: false,
        },

        smallInput: {
            type: Boolean,
            default: false
        },

        providerPrices: {},

        linked: {
            type: Boolean,
            default: false
        },

        placeholder: {
            type: String,
            default: ''
        },

        valueInOrderCurrency: {
            default: ''
        },

        currency: {
            default: null
        },

        priceCurrency: {
            default: null
        },

        defaultExchangeRate: {
            default: ''
        },

        digits: {
            default: 2,
        },

        alwaysShowOrderCurrency: {
            default: false
        },

        suffix: {
            default: ''
        },

        isBlue: {
            default: false,
        },

        isDarken: {
            default: false,
        },

        inputStyle: {
            default: null,
        }
    },

    computed: {
        showExchangeRates: function () {
            return this.$store.state.showExchangeRates;
        },

        orderCurrency: function () {
            return this.currency ? this.currency : this.$store.state.order.calculationCurrency;
        },

        isInfinity: function () {
            return this.value === "Infinity"
        },

        inputValue: function () {
            if (this.isInfinity) {
                return '∞'
            } else {
                return this.localPrice;
            }
        },

        invisible: function () {
            return !!this.smallInput && !this.hasLoadedPrices
        },

        hasLoadedPrices: function () {
            return !!this.providerPrices && ((!!this.providerPrices.mainPrices && this.providerPrices.mainPrices.length > 0) || (!!this.providerPrices.loadedPrices && this.providerPrices.loadedPrices.length > 0))
        },

        isReadOnly: function () {
            return this.readOnly || this.linked;
        },
    },

    methods: {
        updateFromLoadedPrices: function (price) {
            if (!this.isReadOnly) {
                this.updatePrice({target: { value: price }})
            }
        }
    },

    components: {
        Template,
        LoadedPrice
    },

    filters: {
        priceView
    },
}
</script>

