<template>
    <div>
        <loading v-if="isLoading"/>

        <div class="columns " v-for="row in rows" v-else>
            <div v-for="col in row.cols" class="column" :class="col.columnClass" :style="col.columnStyle">
                <div>
                    <h2 class="is-headline">{{col.label}}</h2>
                </div>
                <div>
                    <component
                        :is="col.component.type"
                        :component="col.component"
                        @saveItem="saveItem"
                    />

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import debounce        from 'lodash/debounce';
import Mixin from '../tab';
import HomepagePublish from './Components/HomepagePublish'
import HomepageImage from './Components/HomepageImage';
import HomepageInput from "./Components/HomepageInput";
import HomepagePriceTable from "./Components/HomepagePriceTable";
import HomepageEditor from "./Components/HomepageEditor";
import HomepageStatus from "./Components/HomepageStatus";
import HomepageReady from "./Components/HomepageReady";
import {notifyError, notifySuccess} from "@components/Notification";
import Loading from "@components/Loading";

export default {
    mixins: [ Mixin ],

    data: function() {
        return {
            isLoading: false,
        };
    },

    computed: {
        rows: function() {
            if(this.api === 'destinations'){
                return [
                    {
                        cols: [
                            {
                                label: 'Status',
                                columnClass: 'is-half',
                                columnStyle: {},
                                component: {
                                    type: 'homepageReady',
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Einstellungen',
                                columnClass: 'is-half',
                                columnStyle: {},
                                component: {
                                    type: 'homepageInput',
                                    props: {
                                        rows: [
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Anzeigen in folgenden Kategorien',
                                                    },
                                                    {
                                                        type: 'multiselect',
                                                        options: ['Home', 'Classcis', 'Experience'],
                                                        property: 'categories',
                                                        multiple: true
                                                    },
                                                ]
                                            }
                                        ],
                                    }
                                }
                            },
                            {
                                label: 'Titelbild',
                                columnClass: 'is-half',
                                columnStyle: {},
                                component: {
                                    type: 'homepageImage',
                                    props: {
                                        filterParams: {type: 'homepage_main'},
                                        columnStyle: {'padding-top': 0, 'height': '230px','max-width':'240px'},
                                        columnClasses: '',
                                        multiImages: false,
                                        enableZoom: false
                                    }
                                }
                            },
                        ]
                    },
                ]
            }
            if(this.api === 'day_concepts'){
                return [
                    {
                        cols: [
                            {
                                label: 'Status',
                                columnClass: 'is-half',
                                columnStyle: {},
                                component: {
                                    type: 'homepageReady',
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Kurztext',
                                columnClass: 'is-half',
                                columnStyle: {},
                                component: {
                                    type: 'homepageEditor',
                                    props: {
                                        property: 'shortText',
                                        defaultSize: 500,
                                    }
                                }
                            },
                            {
                                label: 'Langtext',
                                columnClass: 'is-half',
                                columnStyle: {},
                                component: {
                                    type: 'homepageEditor',
                                    props: {
                                        property: 'catalogText',
                                        defaultSize: 500,
                                    }
                                }
                            },
                        ]
                    },
                ]
            }
            if(this.api === 'hotels'){
                return [
                    {
                        cols: [
                            {
                                label: 'Status',
                                columnClass: 'is-half',
                                columnStyle: {},
                                component: {
                                    type: 'homepageReady',
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Hotelbeschreibung',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    type: 'homepageEditor',
                                    props: {
                                        property: 'description',
                                        defaultSize: 150,
                                    }
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Bilder für die Homepage',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    type: 'homepageImage',
                                    props: {
                                        filterParams: {type: 'homepage_image'},
                                        columnStyle: {'padding-top': 0, 'height': '230px','max-width':'240px'},
                                        columnClasses: '',
                                        multiImages: true,
                                        enableZoom: false
                                    }
                                }
                            },
                        ],
                    },
                ]
            }
            if(this.api === 'order_concepts'){
                return [
                    {
                        cols: [
                            {
                                label: 'Veröffentlichen',
                                columnClass: 'is-half',
                                columnStyle: {},
                                component: {
                                    type: 'homepagePublish',
                                    props: {
                                        collectionName: 'Orders'
                                    }
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Status der verknüpften Destinationen',
                                columnClass: 'is-one-third',
                                columnStyle: {},
                                component: {
                                    type: 'homepageStatus',
                                    props: {
                                        object: 'destination'
                                    }
                                }
                            },
                            {
                                label: 'Status der verknüpften Tage',
                                columnClass: 'is-one-third',
                                columnStyle: {},
                                component: {
                                    type: 'homepageStatus',
                                    props: {
                                        object: 'day',
                                    }
                                }
                            },
                            {
                                label: 'Status der verknüpften Hotels',
                                columnClass: 'is-one-third',
                                columnStyle: {},
                                component: {
                                    type: 'homepageStatus',
                                    props: {
                                        object: 'hotel'
                                    }
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Titelbild',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    type: 'homepageImage',
                                    props: {
                                        dropzoneID: 'dropzone-1',
                                        filterParams: {type: 'homepage_main'},
                                        columnStyle: {'padding-top': 0, 'height': '500px'},
                                        columnClasses: '',
                                        multiImages: false,
                                        enableZoom: false
                                    }
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Routenkarte',
                                columnClass: '',
                                columnStyle: {'max-width': '350px'},
                                component: {
                                    type: 'homepageImage',
                                    props: {
                                        dropzoneID: 'dropzone-3',
                                        filterParams: {type: 'homepage_map'},
                                        columnStyle: {'padding-top': 0, 'height': '350px', 'max-width': '350px'},
                                        columnClasses: '',
                                        multiImages: false,
                                        enableZoom: false
                                    }
                                }
                            },
                            {
                                label: 'Einleitungstext',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    type: 'homepageEditor',
                                    props: {
                                        property: 'catalogText',
                                        defaultSize: 305,
                                    }
                                }
                            },
                            {
                                label: 'Highlights',
                                columnClass: '',
                                component: {
                                    type: 'homepageEditor',
                                    props: {
                                        property: 'highlights',
                                        defaultSize: 305,
                                    }
                                }
                            },
                        ]
                    },
                    {
                        cols: [
                            {
                                label: 'Reiseimpressionen für die Homepage',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    type: 'homepageImage',
                                    props: {
                                        dropzoneID: 'dropzone-2',
                                        filterParams: {type: 'homepage_image'},
                                        columnStyle: {'padding-top': 0, 'height': '230px','max-width':'240px'},
                                        columnClasses: '',
                                        multiImages: true,
                                        enableZoom: false
                                    }
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Preise und Leistungen',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    type: 'homepagePriceTable',
                                    props: {
                                        property: 'pricesForHomepage',
                                    }
                                }
                            },
                        ],
                    },

                    /*{
                        cols: [
                            {
                                label: 'Select Hotels',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    type: 'homepageSelect',
                                    props: {
                                        getPropertyArray: ,
                                        columnStyle: {'padding-top': 0, 'height': '230px','max-width':'240px'},
                                        columnClasses: '',
                                        multiImages: true,
                                        enableZoom: false
                                    }
                                }
                            },
                        ],
                    },*/
                ]
            }
        }

    },


    methods: {
        saveItem: debounce(function(key, data, update = false, publish = false) {
            if(publish){
                this.isLoading = true;
            }
            this.$store.dispatch('storeProperty', {
                key: key,
                data: data,
                update: update,
                publish: publish
            }).then(data => {
                notifySuccess('Die Daten wurden gespeichert!')
            }, error => {
                notifyError("Die Daten konnten nicht gespeichert werden! Error happened")
            }).then(() => {this.isLoading = false;})
        }, 1000),
    },

    components: {
        Loading,
        HomepageInput,
        HomepageImage,
        HomepageEditor,
        HomepagePriceTable,
        HomepageStatus,
        HomepageReady,
        HomepagePublish,
    },
}
</script>
