var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Menü bearbeiten",
        createTitle: "Neues Menü hinzufügen",
      },
    },
    [
      _c("table", [
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "title" } }, [
              _vm._v("Titel:"),
            ]),
          ]),
          _vm._v(" "),
          _c("td", [
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.title,
                    expression: "form.title",
                  },
                ],
                staticClass: "input",
                attrs: { type: "text", id: "title" },
                domProps: { value: _vm.form.title },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "title", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", { staticClass: "is-top" }, [
            _c("label", { staticClass: "label" }, [_vm._v("Menü:")]),
          ]),
          _vm._v(" "),
          _c("td", [
            _c(
              "div",
              { staticClass: "control" },
              [
                _c("tip-tap", {
                  attrs: {
                    isNotAlone: "",
                    menuItems: [
                      "fullscreen",
                      "bold",
                      "italic",
                      "underline",
                      "bullet_list",
                      "ordered_list",
                      "color",
                      "undo",
                    ],
                  },
                  model: {
                    value: _vm.form.content,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "content", $$v)
                    },
                    expression: "form.content",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }