<template>
    <span>{{ country }}</span>
</template>

<script>
import { countries } from '@utilities/variables';
import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        country: function () {
            const country = countries.find(country => country.label === this.value);
            return country ? country.name : this.value;
        }
    }
}
</script>
