<template>
    <div class="is-light" v-if="itemPrices.length > 0">
        <item-price
            v-for="itemPrice in itemPrices"
            v-bind:key="itemPrice.id"
            v-bind:price="itemPrice"
            v-on:unlink="$emit('unlink')"
        />
    </div>
</template>

<script>
import { ItemPrice } from '@calculations/components'
import mixin from './mixin';

export default {
    mixins: [mixin],

    computed: {
        itemPrices: function () {
            return [...this.price.itemPrices]
                .map(price => ({
                    ...price,
                    ...price.minPersons === null && { minPersons : 0}
                }))
                .sort((a, b) => {
                    if (a.minPersons === b.minPersons) {
                        return a.maxPersons - b.maxPersons;
                    } else {
                        return a.minPersons - b.minPersons
                    }
                })
        }
    },

    components: {
        ItemPrice
    }
}
</script>

