<template>
    <crud-table
        ref="table"

        api="other_services"
        :url="url"

        :name="{singular: 'anbieter', plural: 'anbieter'}"
        :filter="{
            'otherServiceType.id': parent.id,
            'provider[existsOr]': true,
            'agency[existsOr]': true,

        }"

        v-on:add="openItemForm"

        :columns="[
            {key: 'serviceProvider', label: 'Leistungsträger'},
            'otherServiceType.name',
            'otherServiceType.routeDescription',
            { key: 'location', label: 'Ort/Raum/Destination'}
        ]"
    >
        <a
            slot="actions"
            slot-scope="row"

            v-if="url(row)"
            v-bind:href="url(row)"
            target="_blank"
        >
            <i class="material-icons">launch</i>
        </a>

        <item-form
            slot="form"
            slot-scope="{options}"

            ref="itemForm"
            v-on:submit="storeProvider"
            v-bind:options="formOptions(options)"
            v-bind:parent="parent"
        />

    </crud-table>
</template>

<script>
import { CrudList } from '@components/CRUD';
import ItemForm from './Form';
import Panel from '@components/Panel';
import { providerURL } from '@utilities/functions';
import { addOtherService } from '@api';
import { notifySuccess, notifyError } from '@components/Notification';

export default {
    mixins: [ CrudList ],

    props: {
        parent: {
            type: Object,
            required: true
        }
    },

    methods: {
        openItemForm: function () {
            this.$refs.itemForm.openModal();
        },

        storeProvider: function (data) {
            if (data.link) {
                Promise.all(
                    data.providers.map(provider => {
                        return this.addOtherService(provider, data)
                    })
                ).then(() => {
                    notifySuccess("Successfully added providers");
                    this.$refs.table.refresh();
                })
            } else {
                this.addOtherService(data.provider, data)
                    .then((response) => {
                        notifySuccess("Successfully added provider");
                        this.$refs.table.refresh();
                    })
            }
        },

        addOtherService: function (provider, data) {
            const providerLink = `/api${this.providerLink(provider)}`;
            const formData = {
                ...providerLink.includes('/agencies') && { 'agency' : providerLink },
                ...!providerLink.includes('/agencies') && { 'provider' : providerLink },
                otherServiceType: `/api/other_service_types/${this.parent.id}`,
                ...(!!data.area && !!provider.place) && { 'area' : `/api/areas/${provider.place.area.id}`},
                ...(!!data.place && !!provider.place) && { 'place' : `/api/places/${provider.place.id}`},
                ...(!!data.destination && !!provider.place) && { 'destination' : `/api/destinations/${provider.place.destination.id}`},
                serviceDescription: provider.serviceDescription,
                routeDescription: provider.routeDescription,
                priceType: this.parent.priceType,
            };

            return addOtherService(formData)
        },

        providerLink(item) {
            if (!!item.providerType) {
                if (item.providerType === 'ferry') {
                    return `/ferries/${item.id}`
                } else if (item.providerType === 'hotel') {
                    return `/hotels/${item.id}`
                } else if (item.providerType === 'airline') {
                    return `/airlines/${item.id}`
                } else if (item.providerType === 'other') {
                    return `/others/${item.id}`
                }
            } else {
                return `/agencies/${item.id}`
            }
        },

        formOptions(options) {
            return {
                serviceDescription: this.parent.name,
                routeDescription: this.parent.routeDescription,
                ...options
            }
        },

        url: function (item) {
            if (item.provider !== null) {
                return providerURL(item.provider)
            } else if(item.agency) {
                return `/agencies/${item.agency.id}`
            }
            return '';
        }
    },

    components: {
        Panel,
        ItemForm
    }
}
</script>
