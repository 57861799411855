<template>
    <div>
        <slot></slot>

        <!--<template v-if="hasProvider">
            <div v-if="provider.url">
                <i class="material-icons">language</i>
                <a target="_blank" v-bind:href="url(provider.url)" v-bind:title="provider.name">{{ provider.url }}</a>
            </div>


            <template v-if="contact">
                <div v-if="contact.lastName || contact.firstName">
                    <i class="material-icons">contact_mail</i>
                    {{ contact.lastName }} {{ contact.firstName }}
                </div>

                <div v-if="contact.phoneMobile">
                    <i class="material-icons">phone</i>
                    {{ contact.phoneMobile }}
                </div>

                <div v-else-if="contact.phoneFixed">
                    <i class="material-icons">phone</i>
                    {{ contact.phoneFixed }}
                </div>

                <div v-if="contact.email">
                    <i class="material-icons">email</i>
                    <a v-bind:href="'mailto:' + contact.email">{{ contact.email }}</a>
                </div>
            </template>


            <div v-for="address in addresses" :key="address.id" class="flex-child is-truncated" v-tooltip="{delay: 1000, content: `${address.streetAddress}, ${address.zip} ${address.city}`}">
                <span class="is-truncated">{{ address.streetAddress }}, {{ address.zip }} {{ address.city }}</span>
            </div>
        </template>-->
    </div>
</template>



<script>
import { url } from '@utilities/functions';


export default {
    props: {
        provider: { required: true }
    },


    computed: {
        hasProvider: function() {
            return this.provider !== null
        },

        contact: function () {
            var contactObject = null;

            if (!this.provider.contacts || this.provider.contacts.length === 0) {
                return null;
            }

            contactObject = this.provider.contacts.filter(x => x.type === 'primary');

            if (contactObject.length > 0) {
                return contactObject[0];

            } else {
                return this.provider.contacts[0];
            }
        },


        addresses: function () {
            var billing        = this.provider.addresses.filter(x => x.type === 'billing')[0],
                delivery       = this.provider.addresses.filter(x => x.type === 'delivery')[0],
                addressesArray = [];

            if (billing)  { addressesArray.push(billing); }
            if (delivery) { addressesArray.push(delivery); }

            return addressesArray;
        }
    },


    methods: {
        url
    }
}
</script>
