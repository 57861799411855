<template>
    <div>{{ serviceProvider }} </div>
</template>

<script>
import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        serviceProvider() {
            const service = this.row;

            if (!!service.provider) {
                return service.provider.name;
            } else if (!!service.agency){
                return service.agency.name;
            }
            return '-';
        },
    }
}
</script>
