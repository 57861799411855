<template>
    <columns property="infos" v-on:add="openForm" :filter="{ type: 'menu' }">
        <column-item
            v-bind:update="{updatedBy: item.updatedBy, updatedAt: item.updatedAt}"
            :api="'/api/infos/' + item.id"
            slot-scope="{item}"
            v-on:edit="openForm(item)"
            v-on:delete="deleteItem('infos', item)"
        >
            <ul>
                <li class="content"><strong>{{item.title}}</strong></li>
                <li class="nl2br content" v-html="item.content"></li>
            </ul>
        </column-item>

        <menu-form ref="form" slot="form" />
    </columns>
</template>

<script>
import Mixin from '../tab';
import MenuForm from './Form';

export default {
    mixins: [ Mixin ],

    components: { MenuForm },
}
</script>
