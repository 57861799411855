<template>
    <layout>
        <loading v-if="!isLoaded" />

        <div class="crud" v-else-if="!is404">
            <aside class="main-panel" :class="{'is-active': infos.isActive, 'is-visible': infos.isVisible}">
                <div class="level">
                    <a v-bind:href="listURL" class="button">
                        <i class="material-icons">keyboard_arrow_left</i>zur Übersicht
                    </a>

                    <user-image
                        v-if="has(item, 'mainUsers')"
                        v-bind:user="item.mainUsers"
                        editable
                        v-on:input="changeUser"
                    />
                </div>


                <item-top></item-top>


                <div class="level mb">
                    <h2 class="mb-0">HAUPTINFORMATIONEN</h2>

                    <simple-menu icon="more_vert" material :buttonWrapper="false">
                        <button v-on:click.prevent="openForm"><i class="material-icons">edit</i> Bearbeiten</button>
                        <button v-on:click.prevent="deleteItem"><i class="material-icons">delete</i> Löschen</button>
                    </simple-menu>
                </div>


                <item-aside class="mb-2x"
                />

<!--                <template v-if="hasDescription">
                    <h2 class="mb">Beschreibung</h2>
                    <tip-tap v-model="description" v-on:input="storeDescription" class="mb" />
                </template>-->

                <template v-if="hasNotes">
                    <h2 class="mb">Interne Notizen</h2>
                    <tip-tap v-model="notes" v-on:input="storeNotes" class="mb" />
                </template>
            </aside>
            <div class="overlay" v-on:click="hideOverlay"></div>

            <item-tabs v-bind:tabs="tabs" />

            <item-form
                ref="form"
                v-bind:options="options"
                v-on:updated="handleFormUpdate"
            />
        </div>



        <div v-else>
            <div class="box">
                <h1>Page not found</h1>
                <a v-bind:href="listURL" class="button">
                    <i class="material-icons">keyboard_arrow_left</i>zur Übersicht
                </a>
            </div>
        </div>

        <slot />
    </layout>
</template>



<script>
import debounce from 'lodash/debounce';
import has      from 'lodash/has';

import { notifySuccess, notifyError } from '@components/Notification';
import { TipTap } from '@components/form';
import UserImage  from '@components/UserImage';
import Loading    from '@components/Loading';
import SimpleMenu from '@components/SimpleMenu';

import ItemTop   from './ItemTop';
import ItemTabs  from './ItemTabs';
import ItemAside from './ItemAside';
import { store } from './store';


export default {
    store,

    api: '',
    url: '',
    options: true,

    tabs: [],

    page: '',

    computed: {
        infos: function () { return this.$layout.infos },
        store: function () { return this.$store.state },

        api: function () { return this.$store.state.api },

        tabs: function () {
            if (this.isMuseum) {
                return this.$options.tabs
                    .filter(item => !['menus', 'bank-accounts', 'freeplaces'].includes(item))
            } else if (!this.isMuseum) {
                return this.$options.tabs
                    .filter(item => !['entrances'].includes(item))
            }

            return this.$options.tabs;
        },
        isLoaded: function () { return this.$store.state.isLoaded },
        item: function () { return this.$store.state.data },
        options: function () { return this.$store.state.options },
        id: function () { return this.$store.state.id },
        name: function () { return this.item.name },
        is404: function () { return this.$store.state.is404 },
        isMuseum: function () { return this.$store.getters.isMuseum },

        listURL: function () {
            const url = !!this.$options.url ? this.$options.url : this.$options.api;
            return `/${url}`
        },

        // Notes
        hasNotes: function () { return has(this.item, 'notes')},
        notes: {
            get: function () { return this.item.notes },
            set: function (notes) { this.$store.commit('updateData', { notes })}
        },

        hasDescription: function () { return has(this.item, 'description')},
        description: {
            get: function () { return this.item.description ? this.item.description : '' },
            set: function (description) { this.$store.commit('updateData', { description })}
        },

        _groups: function () {
            return [

                'data_check_read',
                ...(['order_concepts'].includes(this.$options.api) ? ['concept_order:destinations', 'order_list_read', 'tag_trait'] : []),
                ...(['clients'].includes(this.$options.api) ? ['user_read'] : []),
                ...(['banks'].includes(this.$options.api) ? ['bank:organisation', 'organisation'] : []),
                ...(['other_service_types'].includes(this.$options.api) ? ['place_read', 'area_read', 'destination_read'] : []),
                ...(['other_service_types'].includes(this.$options.api) ? ['translations'] : []),
                ...(['hotels', 'airlines', 'ferries', 'others', 'trains'].includes(this.$options.api) ? ['agency_read_list', 'port_read', 'place_read', 'provider_property'] : ''),
                ...this.tabs.includes('bank-accounts') ? ['bank_account_read'] : [],
                ...this.tabs.includes('conditions') ? ['provider_condition_read'] : [],
                ...this.tabs.includes('freeplaces') ? ['provider_freeplace_read'] : [],
                ...this.tabs.includes('webflow') ? ['webflow'] : [],
                ...this.tabs.some(tab => ['prices'].includes(tab)) ? ['order_contingent_item_read'] : [],
                ...this.tabs.some(tab => ['itemPrices', 'otherServices'].includes(tab)) ? ['provider_read', 'item_price_read'] : [],
                ...this.tabs.includes('addresses') ? ['address_read', 'address_read_relations', 'port_read', 'airport_read'] : [],
                ...this.tabs.includes('accounts') ? ['organisation_bank_account', 'organisation:organisation_bank_account', 'bank', 'organisation_bank_account:account_plan_item', 'account_plan_item', 'check_book', 'organisation:account_plan_item'] : [],
                ...this.tabs.includes('directions') ? ['order_route_predefined_read', 'predefined_free_text_item'] : [],
                ...this.tabs.includes('contacts') ? ['place_read','contact_read', 'contact_read_relations', 'contact_write_relations'] : [],
                ...this.tabs.includes('trip-details') ? ['image_read'] : [],
                ...this.tabs.includes('images') ? ['image_folder_read'] : [],
                ...this.tabs.includes('infos') ? ['info_read'] : [],
                ...this.tabs.includes('sales') ? ['client:client_visit', 'client_visit', 'client:table_info', 'client_visit:contact', 'table_info', 'client_visit:user', 'user_read'] : [],
            ]
        }
    },


    methods: {
        handleFormUpdate: function (data) {
            this.$store.commit('updateData', data);
        },

        openForm: function () {
            this.$refs.form.openModal(this.item);
        },

        deleteItem: function () {
            if (confirm(`Bist du sicher dass du das Element löschen willst?`)) {
                this.$store.dispatch('delete')
                    .then(() => {
                        window.location.href = this.listURL
                    })
            }
        },

        storeNotes: debounce(function (data) {
            this.$store.dispatch('storeProperty', {key: 'notes', data, update: false})
                .then(() => notifySuccess('Der Text wurde gespeichert!'), error => notifyError('Notes not saved!'))
        }, 1000),

        storeDescription: debounce(function (data) {
            this.$store.dispatch('storeProperty', {key: 'description', data, update: false})
                .then(() => notifySuccess('Die Beschreibung wurde gespeichert!'), error => notifyError('Description not saved!'))
        }, 1000),

        changeUser: function (newUser) {
            this.$store.dispatch('storeProperty', {
                key: 'mainUsers',
                data: newUser
            }).then(response => notifySuccess('Der Ansprechpartner für den Kunden wurde geändert'),
                error => notifyError('Error while saving new user!')
            )
        },

        hideOverlay: function (event) {
            this.$layout.hideInfos()
        },

        has,
    },


    created: function () {
        const ID = window.location.pathname.split('/').pop();

        this.$store.commit('updateApi', this.$options.api);
        this.$store.commit('updateID', ID);

        this.$store.dispatch('fetchItem', { _groups: this._groups});
        if (this.$options.options) {
            this.$store.dispatch('fetchOptions')
        }
    },

    mounted: function() {
        this.$layout.setPageName(this.$options.page);
        this.$layout.updateInfos({ showButton: true })
    },

    components: {
        ItemTop,
        ItemTabs,
        ItemAside,

        TipTap,

        UserImage,
        SimpleMenu,
        Loading
    }
}
</script>
