var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section" }, [
    _c("div", { staticClass: "section-title" }, [
      _c("span", { staticClass: "h2" }, [
        _c("span", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c(
          "i",
          {
            staticClass: "material-icons",
            on: {
              click: function ($event) {
                _vm.showForm = true
              },
            },
          },
          [_vm._v("add_circle")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "redesign-table" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "draggable",
          {
            attrs: { element: "div", value: _vm.typeProviderTripDetails },
            on: { input: _vm.updateSequence },
          },
          _vm._l(
            _vm.typeProviderTripDetails,
            function (providerTripDetail, index) {
              return _c(
                "item",
                {
                  key: providerTripDetail.id,
                  attrs: { value: providerTripDetail },
                  on: {
                    submit: _vm.handleSubmit,
                    delete: function ($event) {
                      return _vm.handleDelete(providerTripDetail)
                    },
                  },
                },
                [_vm._v(_vm._s(index + 1))]
              )
            }
          ),
          1
        ),
        _vm._v(" "),
        _vm.showForm
          ? _c(
              "item",
              {
                attrs: { value: { type: _vm.type } },
                on: {
                  submit: _vm.handleSubmit,
                  delete: function ($event) {
                    _vm.showForm = false
                  },
                },
              },
              [_vm._v(_vm._s(_vm.typeProviderTripDetails.length + 1))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.typeProviderTripDetails.length === 0 && !_vm.showForm
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "cell has-text-centered" }, [
                _vm._v("There are no provider_info $providerTripDetail"),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row is-head" }, [
      _c("div", { staticClass: "cell" }, [_vm._v("Überschrift")]),
      _vm._v(" "),
      _c("div", { staticClass: "cell" }, [_vm._v("Beschreibung")]),
      _vm._v(" "),
      _c("div", { staticClass: "cell is-70px-cell" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }