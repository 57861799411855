<template>
    <modal-form
        editTitle="Menü bearbeiten"
        createTitle="Neues Menü hinzufügen"
    >
        <table>
            <tr>
                <th><label for="title" class="label">Titel:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="title" class="input" v-model="form.title">
                    </div>
                </td>
            </tr>
            <tr>
                <th class="is-top"><label class="label">Menü:</label></th>
                <td>
                    <div class="control">
                        <tip-tap
                            v-model="form.content"
                            isNotAlone
                            :menuItems="['fullscreen', 'bold', 'italic', 'underline', 'bullet_list', 'ordered_list', 'color', 'undo']"
                        />
                    </div>
                </td>
            </tr>
        </table>
    </modal-form>

</template>

<script>
import { TipTap } from '@components/form';
import TabForm from '../form' ;

export default {
    mixins: [TabForm],

    form: {
        title: '',
        content: '<ul><li></li></ul>',
        type: 'menu'
    },

    property: 'infos',

    components: {
        TipTap
    }
}
</script>
