import PackagePrice from './Package'

import HotelContingentPrice from './ContingentHotel'
import FerryContingentPrice from './ContingentFerry'
import TrainContingentPrice from './ContingentTrain'


import ExtraPrice from './Extra';

import ProviderPrice from './Provider'
import FerryPrice from './FerryProvider';

export default {
    PackagePrice,

    HotelContingentPrice,
    FerryContingentPrice,
    TrainContingentPrice,

    ExtraPrice,

    ProviderPrice,
    FerryPrice
}
