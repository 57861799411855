<script>
import mixin from './mixin'
export default {
    mixins: [ mixin ],

    icon: 'directions_boat',

    computed: {
        isDay: function () { return this.placeholder.type === 'day' },
        isNight: function () { return !this.isDay },

        hasPersonPrice: function () { return true },
        personPrice: {
            get: function () {
                return this.isNight
                    ? this.price.insideDoubleCabinBuyPrice.amount
                    : this.price.dayTicketBuyPrice.amount
            },

            set: function (value) {
                this.$store.commit('updatePrice', {
                    ferryPrices: {
                        id: this.price.id,
                        ...this.isNight && { insideDoubleCabinBuyPrice: {
                                amount: value,
                                currency: this.placeholder.placeholderCurrency
                            } },
                        ...!this.isNight && { dayTicketBuyPrice: {
                                amount: value,
                                currency: this.placeholder.placeholderCurrency
                            } },
                        buyPrice: {
                            amount: value,
                            currency: this.placeholder.placeholderCurrency
                        }
                    }
                })
            }
        },
        personPriceType: function () {
            return this.isNight ? 'insideDoubleCabinBuyPrice' : 'dayTicketBuyPrice';
        },
        singlePriceType: function () {
            return this.isNight ? 'insideSingleCabinPremiumBuyPrice' : 'dayTicketBuyPrice';
        },

        hasSinglePrice: function () { return !this.supplementPrices || this.isNight },
        singlePrice: {
            get: function () {
                return this.isNight ? this.price.insideSingleCabinPremiumBuyPrice.amount : this.price.dayTicketBuyPrice.amount;
            },
            set: function (value) {
                if(this.isNight){
                    this.$store.commit('updatePrice', {
                        ferryPrices: {
                            id: this.price.id,
                            insideSingleCabinPremiumBuyPrice: {
                                amount: value,
                                currency: this.placeholder.placeholderCurrency
                            }
                        }
                    })
                }
            }
        },
    }
}
</script>
