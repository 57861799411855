var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "multiselect",
    {
      attrs: {
        options: _vm.items,
        loading: _vm.isLoadingItems,
        "allow-empty": false,
        "custom-label": _vm.customLabel,
        placeholder: "Fähre",
        "track-by": "id",
        "group-values": "items",
        "group-label": "type",
        "select-label": "",
      },
      model: {
        value: _vm.ferrySelected,
        callback: function ($$v) {
          _vm.ferrySelected = $$v
        },
        expression: "ferrySelected",
      },
    },
    [
      _c("template", { slot: "noResult" }, [_vm._v("Keine passenden Fähren")]),
      _vm._v(" "),
      _c("span", { attrs: { slot: "caret" }, slot: "caret" }, [
        _c(
          "a",
          {
            staticClass: "multiselect-crud-link",
            attrs: {
              href: "/ferries?add",
              title: "Fügen Sie eine neue Fähre hinzu",
              target: "_blank",
            },
          },
          [_c("i", { staticClass: "material-icons" }, [_vm._v("add")])]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }