var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "td",
      [
        _c(
          "toggle-switch",
          {
            model: {
              value: _vm.replace,
              callback: function ($$v) {
                _vm.replace = $$v
              },
              expression: "replace",
            },
          },
          [
            _c("option", { domProps: { value: false } }, [_vm._v("Nein")]),
            _vm._v(" "),
            _c("option", { domProps: { value: true } }, [_vm._v("Ja")]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _c("label", { staticClass: "label", attrs: { for: "replace" } }, [
        _vm._v("Überall Ersetzten:"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }