<template>
    <div>
        <span class="badges">
            <span class="badge is-icon" v-for="icon in icons" v-bind:key="icon">
                <i class="material-icons">
                    {{icon}}
                </i>
            </span>
            <span
                class="badge"
                v-for="badge in badges"
                v-bind:class="`is-${badge}`"
                v-bind:key="badge"
            >{{badgeName(badge)}}</span>
        </span>
    </div>
</template>

<script>
import { tags, tagIcons } from '@utilities/variables';
import Cell from './cell';

export default {
    mixins: [ Cell ],

    props: {
        value: {
            type: Array,
        },
    },

    computed: {
        tags: function () {
            return [...tags, ...tagIcons]
        },

        icons: function () {
            return tagIcons.filter(tag => {
                return this.value.includes(tag.label)
            }).map(tag => tag.label);
        },

        hasIcons: function () {
            return this.icons.length > 0;
        },

        badges: function () {
            return tags.filter(tag => {
                return this.value.includes(tag.label)
            }).map(tag => tag.label);
        },

        hasBadges: function () {
            return this.badges.length > 0;
        }
    },

    methods: {
        badgeName: function (badge) {
            const foundBadge = this.tags && this.tags.find(tag => tag.label === badge);
            return !!foundBadge ? foundBadge.name : '';
        },
    }
}
</script>

