<template>
    <modal-form
        editTitle="Anbieter mit Leistung verknüpfen"
        createTitle="Anbieter mit Leistung verknüpfen"
    >
        <table>
            <tr>
                <th><label class="label">Verküpfung mit:</label></th>
                <td>
                    <div class="buttons has-addons">
                        <button
                            class="button"
                            v-on:click.prevent="changeLink(false)"
                            v-bind:class="{'is-primary': !form.link}"
                        >einz. Leistungsträgern</button>
                        <button
                            class="button"
                            v-on:click.prevent="changeLink(true)"
                            v-bind:class="{'is-primary': form.link}"
                        >allen Leistungsträgern einer Kategorie</button>
                    </div>
                </td>
            </tr>
            <tr>
                <th class="is-top">
                    <label for="providerType" class="label">
                        <template v-if="form.link">Leistungsträger - Kategorie:</template>
                        <template v-else>Leistungsträger:</template>
                    </label>
                </th>
                <td>
                    <div class="buttons has-addons">
                        <button
                            class="button"
                            v-on:click.prevent="changeType('hotel')"
                            v-bind:class="{'is-primary': form.providerType === 'hotel'}"
                        >Hotels</button>
                        <button
                            class="button"
                            v-on:click.prevent="changeType('ferry')"
                            v-bind:class="{'is-primary': form.providerType === 'ferry'}"
                        >Fähren</button>
                        <button
                            class="button"
                            v-on:click.prevent="changeType('airline')"
                            v-bind:class="{'is-primary': form.providerType === 'airline'}"
                        >Airlines</button>
                        <button
                            class="button"
                            v-on:click.prevent="changeType('other')"
                            v-bind:class="{'is-primary': form.providerType === 'other'}"
                        >Sonstige</button>
                        <button
                            class="button"
                            v-on:click.prevent="changeType('agency')"
                            v-bind:class="{'is-primary': form.providerType === 'agency'}"
                        >Agentur</button>
                    </div>

                    <template v-if="!form.link">
                        <async-select
                            v-model="form.provider"
                            :api="apiType"
                            placeholder="Select provider"
                            crudLinkTitle = "Fügen Sie eine neue Leistungsträger hinzu"
                        />
                    </template>
                    <template v-else-if="form.link && form.providerType === 'other'">
                        <multiselect
                            v-model="form.otherType"
                            track-by="key"
                            label="value"
                            v-bind:options="options.otherTypes"
                        />
                    </template>
                </td>
            </tr>

            <template v-if="form.link">
                <tr v-if="form.providerType !== 'airline' && form.providerType !== 'ferry'">
                    <th>
                        <label class="label">Leistung gültig für:</label>
                    </th>
                    <td>
                        <location-select v-model="location" hasDestination />
                    </td>
                </tr>

                <template>
                    <tr>
                        <td colspan="2">
                                <h2>Anbieter auswwählen</h2>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <button
                                class="button"
                                v-on:click.prevent="changeSelection"
                                v-if="allSelected"
                            >Auswahl entfernen</button>
                            <button
                                class="button"
                                v-on:click.prevent="changeSelection"
                                v-else
                            >Alles auswählen</button>
                        </td>
                    </tr>

                    <tr>
                        <td colspan="2">
                            <list-select
                                :api="apiType"
                                v-model="form.providers"
                                :params="listParams"
                                v-on:updateItems="updateItems"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" >
                            <pagination v-model="pagination" simple show-per-page/>
                        </td>
                    </tr>
                </template>
            </template>
        </table>
    </modal-form>
</template>

<script>
import ItemForm from '@components/CRUD/Item/ItemForm';
import { Multiselect, AsyncSelect, ListSelect, LocationSelect } from '@components/form';
import { Pagination } from '@components/CRUD';

export default {
    mixins: [ ItemForm ],

    props: {
        parent: {
            type: Object,
            required: true
        }
    },

    data: function () {
        return {
            emptyForm: {
                providerType: 'hotel',
                providers: [],
                provider: null,
                place: null,
                area: null,
                destination: null,
                otherType: null,
                link: false,
            },
            allSelected: true,
            pagination: {
                current: 1,
                perPage: 50,
                items: 0
            },
            oldProviders: []
        }
    },

    computed: {
        apiType: function () {
            return {
                'hotel': 'hotels',
                'ferry': 'ferries',
                'airline': 'airlines',
                'other': 'others',
                'agency': 'agencies'
            }[this.form.providerType]
        },

        location: {
            get: function () {
                return {
                    area: this.form.area,
                    place: this.form.place,
                    destination: this.form.destination
                }
            },

            set: function ({area, place, destination}) {
                this.form.area = area;
                this.form.place = place;
                this.form.destination = destination;
            }
        },

        listParams: function () {
            const { area, place, destination, otherType } = this.form;
            return {
                ...!!place && { 'place.id': place.id },
                ...!!area && { 'place.area.id': area.id },
                ...!!destination && { 'place.destination.id': destination.id },
                ...!!otherType && { 'type' : otherType.key },
                ...this.parent && {'not_otherServiceType[]': this.parent.id},
                _page: this.pagination.current,
                _itemsPerPage: this.pagination.perPage,
            }
        }
    },

    methods: {
        changeType: function (type) {
            this.form.providerType = type;
            this.form.providers = []
            this.location = { area: null, place: null, destination: null }
        },

        changeSelection: function() {
            if(this.allSelected){
                this.oldProviders = this.form.providers;
                this.form.providers = [];
            } else {
                this.form.providers = this.oldProviders;
            }
            this.allSelected = !this.allSelected;
        },

        changeLink: function (value) {
            this.form.link = value;
            this.form.providers = []
            this.location = { area: null, place: null, destination: null }
        },

        updateItems: function(items){
            this.allSelected = true;
            this.pagination.items = items
        },
    },

    components: {
        Multiselect, AsyncSelect, LocationSelect, ListSelect, Pagination
    }
}
</script>
