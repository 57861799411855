var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("user-image", {
    attrs: { user: _vm.value, "multiple-in-table": "" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }