import omit from 'lodash/omit';
import moment from 'moment';
import { fullYear } from '@utilities/functions';

export default {
    data: function () {
        return {
            options: {
                _importChecked: []
            }
        }
    },

    methods: {
        fullYear,
    },

    computed: {
        filterCheckedAt: {
            get: function () { return this.options._importChecked.find(i => i.key === this.filter['checkedAt']) },
            set: function (v) { this.filter['checkedAt'] = !!v ? v.key : null; }
        },

        filters: function () {
            const oneYearBefore = moment().subtract(1, 'year').format('DD.MM.YYYY')

            return Object.assign({}, omit(this.filter, ['checkedAt']), {
                ...(this.filter['checkedAt'] === 'true')  && { 'dataCheckedAt[after]':  oneYearBefore},
                ...(this.filter['checkedAt'] === 'false') && { 'dataCheckedAt[before]':  oneYearBefore},
            })
        }
    }
}
