var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["is-package", _vm.addition !== "" ? "has-overlay" : ""] },
    [
      _c("div", { staticClass: "buttons has-addons" }, [
        _c(
          "button",
          {
            staticClass: "button",
            on: {
              click: function ($event) {
                _vm.addition = "requests"
              },
            },
          },
          [_vm._v("Weitere Leistungen zum Paket hinzufügen")]
        ),
      ]),
      _vm._v(" "),
      _vm.addition === "requests"
        ? _c("add-requests", {
            attrs: { request: _vm.request },
            on: {
              update: _vm.updatePackage,
              cancel: function ($event) {
                _vm.addition = ""
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && _vm.request.package && _vm.request.package.id !== -1
        ? [
            _c("div", { staticClass: "bp-box is-package-list" }, [
              _c("header", [
                _c("i", { staticClass: "fa fa-book" }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.request.agency
                        ? "Paketleistungen von " + _vm.request.agency.name
                        : "direkt"
                    )
                ),
              ]),
              _vm._v(" "),
              _c("main", [
                _c(
                  "table",
                  _vm._l(_vm.request.package.requests, function (requestItem) {
                    return _c("tr", { key: requestItem.id }, [
                      _c(
                        "td",
                        [
                          requestItem.requestType === "hotel"
                            ? _c("i", { staticClass: "material-icons" }, [
                                _vm._v("home"),
                              ])
                            : requestItem.requestType === "ferry"
                            ? _c("i", { staticClass: "material-icons" }, [
                                _vm._v("directions_boat"),
                              ])
                            : requestItem.requestType === "airline"
                            ? _c("i", { staticClass: "material-icons" }, [
                                _vm._v("airplanemode_active"),
                              ])
                            : requestItem.requestType === "other"
                            ? _c("other-service-icons", {
                                attrs: {
                                  type: requestItem.otherServiceType.category,
                                },
                              })
                            : _c(
                                "i",
                                {
                                  staticClass: "material-icons",
                                  attrs: { title: "Other" },
                                },
                                [_vm._v("map")]
                              ),
                          _vm._v(
                            "\n                            " +
                              _vm._s(requestItem.info.title) +
                              "\n                        "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          requestItem.info.provider.name
                            ? [
                                _c(
                                  "i",
                                  {
                                    staticClass: "material-icons",
                                    attrs: { title: "Provider name" },
                                  },
                                  [_vm._v("business")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(requestItem.info.provider.name) +
                                    "\n                            "
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          requestItem.requestType === "hotel"
                            ? [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("local_dining"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getValueWithKey({
                                        key: requestItem.board,
                                        optionList: _vm.boardList,
                                      })
                                    )
                                ),
                              ]
                            : requestItem.ferry === "ferry" &&
                              requestItem.type === "night"
                            ? [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("brightness_4"),
                                ]),
                                _vm._v(" Nachtfähre"),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            attrs: { title: "Status" },
                          },
                          [_vm._v("map")]
                        ),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getValueWithKey({
                                key: requestItem.requestStatus.status,
                                optionList: _vm.requestStatuses,
                              })
                            ) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        requestItem.id !== _vm.request.id
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-delete",
                                attrs: { title: "Löschen" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteRequest(requestItem.id)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("delete"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.packageHasHotel
              ? _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }, [
                    _c("div", { staticClass: "bp-box is-price" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "main",
                        [
                          _c("div", { staticClass: "is-standard" }, [
                            _c("div", [
                              _c(
                                "i",
                                { staticClass: "material-icons no-spacing" },
                                [_vm._v("local_hotel")]
                              ),
                              _vm._v(
                                "\n                                Paket - " +
                                  _vm._s(
                                    _vm.getAccommodationLabel(
                                      "standard",
                                      2,
                                      _vm.packageLabels
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("input-price", {
                                  attrs: { prefix: "" },
                                  model: {
                                    value: _vm.basePrice,
                                    callback: function ($$v) {
                                      _vm.basePrice = $$v
                                    },
                                    expression: "basePrice",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.request.package.basePrices,
                            function (accomodation, index) {
                              return accomodation.roomType.persons === 2 &&
                                accomodation.roomType.type !== "standard"
                                ? _c("div", { key: accomodation.id }, [
                                    _c("div", [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons no-spacing",
                                        },
                                        [_vm._v("local_hotel")]
                                      ),
                                      _vm._v(
                                        "\n                                Paket - " +
                                          _vm._s(
                                            _vm.getAccommodationLabel(
                                              accomodation.roomType.type,
                                              accomodation.roomType.persons,
                                              _vm.packageLabels
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c("input-price", {
                                          attrs: {
                                            prefix: "",
                                            value: _vm.getDifference(
                                              accomodation.price.amount
                                            ),
                                          },
                                          on: {
                                            input: (newDifference) =>
                                              _vm.setPrice(
                                                _vm.request.package.basePrices[
                                                  index
                                                ],
                                                newDifference
                                              ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e()
                            }
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.request.package.basePrices,
                            function (accomodation, index) {
                              return accomodation.cabinType.persons === 2 &&
                                accomodation.cabinType.type === "outside"
                                ? _c("div", { key: accomodation.id }, [
                                    _c("div", [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons no-spacing",
                                        },
                                        [_vm._v("directions_boat")]
                                      ),
                                      _vm._v(
                                        "\n                                Paket - " +
                                          _vm._s(
                                            _vm.getAccommodationLabel(
                                              "standard_" +
                                                accomodation.cabinType.type,
                                              accomodation.cabinType.persons,
                                              _vm.packageLabels
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c("input-price", {
                                          attrs: {
                                            prefix: "",
                                            value: _vm.getDifference(
                                              accomodation.price.amount
                                            ),
                                          },
                                          on: {
                                            input: (newDifference) =>
                                              _vm.setPrice(
                                                _vm.request.package.basePrices[
                                                  index
                                                ],
                                                newDifference
                                              ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e()
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }, [
                    _c("div", { staticClass: "bp-box is-price" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "main",
                        [
                          _vm._l(
                            _vm.request.package.basePrices,
                            function (accomodation, index) {
                              return accomodation.roomType.persons === 1
                                ? _c("div", { key: accomodation.id }, [
                                    _c("div", [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("local_hotel")]
                                      ),
                                      _vm._v(
                                        "\n                                Paket - " +
                                          _vm._s(
                                            _vm.getAccommodationLabel(
                                              accomodation.roomType.type,
                                              accomodation.roomType.persons,
                                              _vm.packageLabels
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c("input-price", {
                                          attrs: {
                                            prefix: "",
                                            value: _vm.getDifference(
                                              accomodation.price.amount
                                            ),
                                          },
                                          on: {
                                            input: (newDifference) =>
                                              _vm.setPrice(
                                                _vm.request.package.basePrices[
                                                  index
                                                ],
                                                newDifference
                                              ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e()
                            }
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.request.package.basePrices,
                            function (accomodation, index) {
                              return accomodation.cabinType.persons === 1 &&
                                accomodation.cabinType.type === "outside"
                                ? _c("div", { key: accomodation.id }, [
                                    _c("div", [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("directions_boat")]
                                      ),
                                      _vm._v(
                                        "\n                                Paket - " +
                                          _vm._s(
                                            _vm.getAccommodationLabel(
                                              "standard_" +
                                                accomodation.cabinType.type,
                                              accomodation.cabinType.persons,
                                              _vm.packageLabels
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c("input-price", {
                                          attrs: {
                                            prefix: "",
                                            value: _vm.getDifference(
                                              accomodation.price.amount
                                            ),
                                          },
                                          on: {
                                            input: (newDifference) =>
                                              _vm.setPrice(
                                                _vm.request.package.basePrices[
                                                  index
                                                ],
                                                newDifference
                                              ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e()
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }, [
                    _c("div", { staticClass: "bp-box is-price" }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "main",
                        [
                          _vm._l(
                            _vm.request.package.basePrices,
                            function (accomodation, index) {
                              return accomodation.roomType.persons > 2
                                ? _c("div", { key: accomodation.id }, [
                                    _c("div", [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons no-spacing",
                                        },
                                        [_vm._v("local_hotel")]
                                      ),
                                      _vm._v(
                                        "\n                                Paket - " +
                                          _vm._s(
                                            _vm.getAccommodationLabel(
                                              accomodation.roomType.type,
                                              accomodation.roomType.persons,
                                              _vm.packageLabels
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c("input-price", {
                                          attrs: {
                                            prefix: "",
                                            value: _vm.getDifference(
                                              accomodation.price.amount
                                            ),
                                          },
                                          on: {
                                            input: (newDifference) =>
                                              _vm.setPrice(
                                                _vm.request.package.basePrices[
                                                  index
                                                ],
                                                newDifference
                                              ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e()
                            }
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.request.package.basePrices,
                            function (accomodation, index) {
                              return accomodation.cabinType.persons > 2
                                ? _c("div", { key: accomodation.id }, [
                                    _c("div", [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons no-spacing",
                                        },
                                        [_vm._v("directions_boat")]
                                      ),
                                      _vm._v(
                                        "\n                                Paket - " +
                                          _vm._s(
                                            _vm.getAccommodationLabel(
                                              "standard_" +
                                                accomodation.cabinType.type,
                                              accomodation.cabinType.persons,
                                              _vm.packageLabels
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c("input-price", {
                                          attrs: {
                                            prefix: "",
                                            value: _vm.getDifference(
                                              accomodation.price.amount
                                            ),
                                          },
                                          on: {
                                            input: (newDifference) =>
                                              _vm.setPrice(
                                                _vm.request.package.basePrices[
                                                  index
                                                ],
                                                newDifference
                                              ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e()
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ])
              : _vm.packageHasFerry
              ? _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }, [
                    _c("div", { staticClass: "bp-box is-price" }, [
                      _vm._m(3),
                      _vm._v(" "),
                      _c(
                        "main",
                        [
                          _c("div", { staticClass: "is-standard" }, [
                            _c("div", [
                              _c(
                                "i",
                                { staticClass: "material-icons no-spacing" },
                                [_vm._v("directions_boat")]
                              ),
                              _vm._v(
                                "\n                                Paket - " +
                                  _vm._s(
                                    _vm.getAccommodationLabel(
                                      "inside",
                                      2,
                                      _vm.packageLabels
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("input-price", {
                                  attrs: { prefix: "" },
                                  model: {
                                    value: _vm.basePrice,
                                    callback: function ($$v) {
                                      _vm.basePrice = $$v
                                    },
                                    expression: "basePrice",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.request.package.basePrices,
                            function (accomodation, index) {
                              return accomodation.cabinType.persons === 2 &&
                                accomodation.cabinType.type !== "inside"
                                ? _c("div", { key: accomodation.id }, [
                                    _c("div", [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons no-spacing",
                                        },
                                        [_vm._v("directions_boat")]
                                      ),
                                      _vm._v(
                                        "\n                                Paket - " +
                                          _vm._s(
                                            _vm.getAccommodationLabel(
                                              accomodation.cabinType.type,
                                              accomodation.cabinType.persons,
                                              _vm.packageLabels
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c("input-price", {
                                          attrs: {
                                            prefix: "",
                                            value: _vm.getDifference(
                                              accomodation.price.amount
                                            ),
                                          },
                                          on: {
                                            input: (newDifference) =>
                                              _vm.setPrice(
                                                _vm.request.package.basePrices[
                                                  index
                                                ],
                                                newDifference
                                              ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e()
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }, [
                    _c("div", { staticClass: "bp-box is-price" }, [
                      _vm._m(4),
                      _vm._v(" "),
                      _c(
                        "main",
                        _vm._l(
                          _vm.request.package.basePrices,
                          function (accomodation, index) {
                            return accomodation.cabinType.persons === 1
                              ? _c("div", { key: accomodation.id }, [
                                  _c("div", [
                                    _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("directions_boat"),
                                    ]),
                                    _vm._v(
                                      "\n                                Paket - " +
                                        _vm._s(
                                          _vm.getAccommodationLabel(
                                            accomodation.cabinType.type,
                                            accomodation.cabinType.persons,
                                            _vm.packageLabels
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _c("input-price", {
                                        attrs: {
                                          prefix: "",
                                          value: _vm.getDifference(
                                            accomodation.price.amount
                                          ),
                                        },
                                        on: {
                                          input: (newDifference) =>
                                            _vm.setPrice(
                                              _vm.request.package.basePrices[
                                                index
                                              ],
                                              newDifference
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e()
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }, [
                    _c("div", { staticClass: "bp-box is-price" }, [
                      _vm._m(5),
                      _vm._v(" "),
                      _c(
                        "main",
                        _vm._l(
                          _vm.request.package.basePrices,
                          function (accomodation, index) {
                            return accomodation.cabinType.persons > 2
                              ? _c("div", { key: accomodation.id }, [
                                  _c("div", [
                                    _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("directions_boat"),
                                    ]),
                                    _vm._v(
                                      "\n                                Paket - " +
                                        _vm._s(
                                          _vm.getAccommodationLabel(
                                            accomodation.cabinType.type,
                                            accomodation.cabinType.persons,
                                            _vm.packageLabels
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _c("input-price", {
                                        attrs: {
                                          prefix: "",
                                          value: _vm.getDifference(
                                            accomodation.price.amount
                                          ),
                                        },
                                        on: {
                                          input: (newDifference) =>
                                            _vm.setPrice(
                                              _vm.request.package.basePrices[
                                                index
                                              ],
                                              newDifference
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e()
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                ])
              : _vm.packageHasTrain
              ? _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }, [
                    _c("div", { staticClass: "bp-box is-price" }, [
                      _vm._m(6),
                      _vm._v(" "),
                      _c(
                        "main",
                        [
                          _c("div", { staticClass: "is-standard" }, [
                            _c("div", [
                              _c(
                                "i",
                                { staticClass: "material-icons no-spacing" },
                                [_vm._v("directions_train")]
                              ),
                              _vm._v(
                                "\n                                Paket - " +
                                  _vm._s(
                                    _vm.getAccommodationLabel(
                                      _vm.standardCategory,
                                      2,
                                      _vm.packageLabels
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("input-price", {
                                  attrs: { prefix: "" },
                                  model: {
                                    value: _vm.basePrice,
                                    callback: function ($$v) {
                                      _vm.basePrice = $$v
                                    },
                                    expression: "basePrice",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.request.package.basePrices,
                            function (accomodation, index) {
                              return accomodation.trainCabinType.persons ===
                                2 &&
                                accomodation.trainCabinType.type !==
                                  _vm.standardCategory
                                ? _c("div", { key: accomodation.id }, [
                                    _c("div", [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons no-spacing",
                                        },
                                        [_vm._v("directions_train")]
                                      ),
                                      _vm._v(
                                        "\n                                Paket - " +
                                          _vm._s(
                                            _vm.getAccommodationLabel(
                                              accomodation.trainCabinType.type,
                                              accomodation.trainCabinType
                                                .persons,
                                              _vm.packageLabels
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c("input-price", {
                                          attrs: {
                                            prefix: "",
                                            value: _vm.getDifference(
                                              accomodation.price.amount
                                            ),
                                          },
                                          on: {
                                            input: (newDifference) =>
                                              _vm.setPrice(
                                                _vm.request.package.basePrices[
                                                  index
                                                ],
                                                newDifference
                                              ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e()
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }, [
                    _c("div", { staticClass: "bp-box is-price" }, [
                      _vm._m(7),
                      _vm._v(" "),
                      _c(
                        "main",
                        _vm._l(
                          _vm.request.package.basePrices,
                          function (accomodation, index) {
                            return accomodation.trainCabinType.persons === 1
                              ? _c("div", { key: accomodation.id }, [
                                  _c("div", [
                                    _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("directions_train"),
                                    ]),
                                    _vm._v(
                                      "\n                                Paket - " +
                                        _vm._s(
                                          _vm.getAccommodationLabel(
                                            accomodation.trainCabinType.type,
                                            accomodation.trainCabinType.persons,
                                            _vm.packageLabels
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _c("input-price", {
                                        attrs: {
                                          prefix: "",
                                          value: _vm.getDifference(
                                            accomodation.price.amount
                                          ),
                                        },
                                        on: {
                                          input: (newDifference) =>
                                            _vm.setPrice(
                                              _vm.request.package.basePrices[
                                                index
                                              ],
                                              newDifference
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e()
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }, [
                    _c("div", { staticClass: "bp-box is-price" }, [
                      _vm._m(8),
                      _vm._v(" "),
                      _c(
                        "main",
                        _vm._l(
                          _vm.request.package.basePrices,
                          function (accomodation, index) {
                            return accomodation.cabinType.persons > 2
                              ? _c("div", { key: accomodation.id }, [
                                  _c("div", [
                                    _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("directions_boat"),
                                    ]),
                                    _vm._v(
                                      "\n                                Paket - " +
                                        _vm._s(
                                          _vm.getAccommodationLabel(
                                            accomodation.cabinType.type,
                                            accomodation.cabinType.persons,
                                            _vm.packageLabels
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _c("input-price", {
                                        attrs: {
                                          prefix: "",
                                          value: _vm.getDifference(
                                            accomodation.price.amount
                                          ),
                                        },
                                        on: {
                                          input: (newDifference) =>
                                            _vm.setPrice(
                                              _vm.request.package.basePrices[
                                                index
                                              ],
                                              newDifference
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e()
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                ])
              : [
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column is-one-third" }, [
                      _c("div", { staticClass: "bp-box is-price" }, [
                        _vm._m(9),
                        _vm._v(" "),
                        _c(
                          "main",
                          _vm._l(
                            _vm.request.package.basePrices,
                            function (accomodation, index) {
                              return !accomodation.cabinType.type &&
                                !accomodation.roomType.type
                                ? _c(
                                    "div",
                                    {
                                      key: accomodation.id,
                                      staticClass: "is-standard",
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          "\n                                    Paketpreise\n                                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        [
                                          _c("input-price", {
                                            attrs: { prefix: "" },
                                            model: {
                                              value: _vm.basePrice,
                                              callback: function ($$v) {
                                                _vm.basePrice = $$v
                                              },
                                              expression: "basePrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e()
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ],
            _vm._v(" "),
            _vm.request.package && _vm.request.package.id
              ? _c("div", { staticClass: "columns" }, [
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c("free-places", {
                        attrs: { request: _vm.request },
                        model: {
                          value: _vm.request.package.freePlaces,
                          callback: function ($$v) {
                            _vm.$set(_vm.request.package, "freePlaces", $$v)
                          },
                          expression: "request.package.freePlaces",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c("item-prices", {
                        attrs: { "is-package": true },
                        model: {
                          value: _vm.request.package.itemPrices,
                          callback: function ($$v) {
                            _vm.$set(_vm.request.package, "itemPrices", $$v)
                          },
                          expression: "request.package.itemPrices",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading ? _c("loading") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons no-spacing" }, [
          _vm._v("local_hotel"),
        ]),
        _c("i", { staticClass: "material-icons" }, [_vm._v("local_hotel")]),
        _vm._v(" "),
        _c("b", [_vm._v("Paketpreise - Doppelzimmer")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons no-spacing" }, [
          _vm._v("local_hotel"),
        ]),
        _c("i", { staticClass: "material-icons no-spacing" }, [
          _vm._v("local_hotel"),
        ]),
        _c("i", { staticClass: "material-icons" }, [_vm._v("local_hotel")]),
        _vm._v(" "),
        _c("b", [_vm._v("Paketpreise - Einzelzimmer")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons no-spacing" }, [
          _vm._v("local_hotel"),
        ]),
        _c("i", { staticClass: "material-icons no-spacing" }, [
          _vm._v("local_hotel"),
        ]),
        _c("i", { staticClass: "material-icons" }, [_vm._v("local_hotel")]),
        _vm._v(" "),
        _c("b", [_vm._v("Paketpreise - Mehrbettabschläge")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons no-spacing" }, [
          _vm._v("directions_boat"),
        ]),
        _c("i", { staticClass: "material-icons" }, [_vm._v("directions_boat")]),
        _vm._v(" "),
        _c("b", [_vm._v("Paketpreise - Doppelkabinenpreise und -zuschläge")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons" }, [_vm._v("directions_boat")]),
        _vm._v(" "),
        _c("b", [_vm._v("Paketpreise - Einzelkabinen")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons no-spacing" }, [
          _vm._v("directions_boat"),
        ]),
        _c("i", { staticClass: "material-icons no-spacing" }, [
          _vm._v("directions_boat"),
        ]),
        _c("i", { staticClass: "material-icons" }, [_vm._v("directions_boat")]),
        _vm._v(" "),
        _c("b", [_vm._v("Paketpreise - Mehrbettkabinen")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons no-spacing" }, [
          _vm._v("directions_train"),
        ]),
        _c("i", { staticClass: "material-icons" }, [
          _vm._v("directions_train"),
        ]),
        _vm._v(" "),
        _c("b", [_vm._v("Paketpreise - Doppelabteil")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons" }, [
          _vm._v("directions_train"),
        ]),
        _vm._v(" "),
        _c("b", [_vm._v("Paketpreise - Einzelabteile")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons no-spacing" }, [
          _vm._v("directions_boat"),
        ]),
        _c("i", { staticClass: "material-icons no-spacing" }, [
          _vm._v("directions_boat"),
        ]),
        _c("i", { staticClass: "material-icons" }, [_vm._v("directions_boat")]),
        _vm._v(" "),
        _c("b", [_vm._v("Paketpreise - Mehrbettkabinen")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [_c("div", [_vm._v("Paketpreise")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }