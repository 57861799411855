var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bp-box is-extra is-flex mb-0" }, [
    _c("header", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("star")]),
      _vm._v(
        " Zuschläge und sonstige Leistungen (Preis pro Einheit)\n        "
      ),
      _c("button", { on: { click: _vm.addItemPrices } }, [
        _c("i", {
          staticClass: "fa fa-plus-circle",
          attrs: { "aria-hidden": "true" },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "main",
      { staticClass: "flex-grow" },
      [
        _vm._l(_vm.localItemPrices, function (extra, index) {
          return _c("div", { key: extra.id }, [
            !_vm.isCalculationRequest
              ? _c("i", { staticClass: "material-icons" }, [
                  _vm._v("insert_drive_file"),
                ])
              : _c(
                  "button",
                  {
                    staticClass: "btn-single-toggle has-border is-large",
                    class: { "is-active": extra.inCalculation },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        extra.inCalculation = !extra.inCalculation
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-calculator" })]
                ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "multiselect",
                  {
                    attrs: {
                      options: _vm.providerPrices,
                      "track-by": "id",
                      label: "name",
                      value: _vm.localItemPrices[index]
                        ? _vm.localItemPrices[index]
                        : null,
                      taggable: true,
                    },
                    on: {
                      input: (value) => {
                        _vm.priceSelected(value, index)
                      },
                      tag: (value) => _vm.addPriceSelected(value, index),
                      "search-change": _vm.searchPriceSelect,
                      close: function ($event) {
                        return _vm.closePriceSelect(index)
                      },
                      open: _vm.getItemPrices,
                    },
                  },
                  [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "select" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: extra.limitType,
                        expression: "extra.limitType",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          extra,
                          "limitType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "validIfLimit" } }, [
                      _vm._v("für alle"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "validForLimit" } }, [
                      _vm._v("für Limit"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: extra.minPersons,
                    expression: "extra.minPersons",
                    modifiers: { number: true },
                  },
                ],
                staticClass: "input is-tiny",
                attrs: { type: "text", placeholder: "Min" },
                domProps: { value: extra.minPersons },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(extra, "minPersons", _vm._n($event.target.value))
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("-")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: extra.maxPersons,
                    expression: "extra.maxPersons",
                    modifiers: { number: true },
                  },
                ],
                staticClass: "input is-tiny",
                attrs: { type: "text", placeholder: "Max" },
                domProps: { value: extra.maxPersons },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(extra, "maxPersons", _vm._n($event.target.value))
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("format_list_numbered"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "select" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: extra.quantityType,
                          expression: "extra.quantityType",
                        },
                      ],
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              extra,
                              "quantityType",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            extra.quantity =
                              extra.quantityType !== "custom"
                                ? null
                                : extra.quantity
                          },
                        ],
                      },
                    },
                    [
                      !_vm.isPackage
                        ? _c(
                            "option",
                            { attrs: { value: "per_person_night" } },
                            [_vm._v("pro Person und Nacht")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "per_person" } }, [
                        _vm._v("pro Person"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "per_group" } }, [
                        _vm._v("pro Gruppe"),
                      ]),
                      _vm._v(" "),
                      !_vm.isPackage
                        ? _c(
                            "option",
                            { attrs: { value: "per_single_room_night" } },
                            [_vm._v("pro EZ und Nacht")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "per_single_room" } }, [
                        _vm._v("pro EZ"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "custom" } }, [
                        _vm._v("Anzahl eingeben"),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: extra.quantity,
                      expression: "extra.quantity",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "input",
                  attrs: { disabled: extra.quantityType !== "custom" },
                  domProps: { value: extra.quantity },
                  on: {
                    focus: function ($event) {
                      return $event.target.select()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(extra, "quantity", _vm._n($event.target.value))
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" "),
                !_vm.isWithoutPrice
                  ? _c("input-price", {
                      attrs: { prefix: extra.price.currency },
                      model: {
                        value: extra.price.amount,
                        callback: function ($$v) {
                          _vm.$set(extra.price, "amount", $$v)
                        },
                        expression: "extra.price.amount",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isWithoutPrice
                  ? _c("div", { staticClass: "buttons has-addons" }, [
                      _c(
                        "button",
                        {
                          class: [
                            "button",
                            extra.billingType === "info" ? "is-primary" : "",
                          ],
                          attrs: {
                            title: "Kunde zahlt selbst vor Ort (nur Info)",
                          },
                          on: {
                            click: function ($event) {
                              extra.billingType = "info"
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-info",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          class: [
                            "button",
                            extra.billingType === "included"
                              ? "is-primary"
                              : "",
                          ],
                          attrs: {
                            title:
                              "von uns bezhalt und im Paketpreis inkludiert",
                          },
                          on: {
                            click: function ($event) {
                              extra.billingType = "included"
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("shopping_cart"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          class: [
                            "button",
                            extra.billingType === "additional"
                              ? "is-primary"
                              : "",
                          ],
                          attrs: { title: "gesondert berechnet" },
                          on: {
                            click: function ($event) {
                              extra.billingType = "additional"
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("playlist_add"),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn-delete",
                    attrs: { title: "Löschen" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteItemPrices(index)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("delete"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ])
        }),
        _vm._v(" "),
        !_vm.localItemPrices || _vm.localItemPrices.length === 0
          ? _c("div", [_vm._v("Es wurden keine Zuschläge eingetragen")])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }