<template>
    <div>
        <slot>{{ value.companyName }}</slot>
        <button
            v-if="isDummyTrip"
            class="btn-single-toggle is-active"
        >D</button>
    </div>
</template>

<script>

import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        isDummyTrip: function() {
            return this.row.trip && this.row.trip.dummy;
        }
    }
}
</script>

