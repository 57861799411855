var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "redesign-table" }, [
    _c(
      "div",
      { staticClass: "table-wrapper" },
      [
        _c("table-filters", {
          attrs: { options: _vm.filterOptions },
          model: {
            value: _vm.filters,
            callback: function ($$v) {
              _vm.filters = $$v
            },
            expression: "filters",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "row is-head" }, [
          _vm.hotel || _vm.ferry
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "orderBy",
                      rawName: "v-orderBy",
                      value: "type",
                      expression: "'type'",
                    },
                  ],
                  staticClass: "cell",
                },
                [_vm._v("Freiplatz pro Person")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "orderBy",
                  rawName: "v-orderBy.date",
                  value: "startAt",
                  expression: "'startAt'",
                  modifiers: { date: true },
                },
              ],
              staticClass: "cell",
            },
            [_vm._v("Zeitraum")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "orderBy",
                  rawName: "v-orderBy",
                  value: "agency.name",
                  expression: "'agency.name'",
                },
              ],
              staticClass: "cell",
            },
            [_vm._v("Agentur")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "orderBy",
                  rawName: "v-orderBy.number",
                  value: "numberOfPersons",
                  expression: "'numberOfPersons'",
                  modifiers: { number: true },
                },
              ],
              staticClass: "cell",
            },
            [_vm._v("ab Pax")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "cell is-actions" }, [
            _c("button", { staticClass: "btn-edit" }, [
              _c(
                "i",
                {
                  staticClass: "material-icons is-pointer",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.openForm.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("add_circle")]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.loading
          ? _c("loading")
          : _c(
              "div",
              { staticClass: "is-zebra" },
              [
                _vm._l(_vm.paginatedRows, function (item) {
                  return [
                    !_vm.isEditing(item)
                      ? _c("div", { key: item.id, staticClass: "row" }, [
                          _vm.hotel || _vm.ferry
                            ? _c("div", { staticClass: "cell" }, [
                                _vm._v(_vm._s(_vm.type(item))),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell" }, [
                            _vm._v(
                              _vm._s(_vm._f("fullYear")(item.startAt)) +
                                " - " +
                                _vm._s(_vm._f("fullYear")(item.endAt))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell" }, [
                            _vm._v(
                              _vm._s(
                                !!item.agency ? item.agency.name : "direkt"
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell" }, [
                            _vm._v(_vm._s(item.numberOfPersons)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "cell is-actions" },
                            [
                              _c(
                                "update-popover",
                                {
                                  attrs: {
                                    update: {
                                      updatedAt: item.updatedAt,
                                      updatedBy: item.updatedBy,
                                    },
                                    api: "/api/provider_free_places/" + item.id,
                                  },
                                },
                                [
                                  _c("button", { staticClass: "btn-edit" }, [
                                    _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("update"),
                                    ]),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-edit",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openForm(item)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("edit"),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.startDelete(item)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("delete"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _c("table-form", {
                          key: `form-${item.id}`,
                          attrs: {
                            options: _vm.options,
                            loading: _vm.isSubmitting(item),
                          },
                          on: {
                            submit: _vm.submitForm,
                            cancel: _vm.cancelForm,
                          },
                          model: {
                            value: _vm.form,
                            callback: function ($$v) {
                              _vm.form = $$v
                            },
                            expression: "form",
                          },
                        }),
                  ]
                }),
                _vm._v(" "),
                !_vm.isEditMode && _vm.showForm
                  ? _c("table-form", {
                      attrs: {
                        options: _vm.options,
                        loading: _vm.isSubmitting(_vm.form),
                      },
                      on: { submit: _vm.submitForm, cancel: _vm.cancelForm },
                      model: {
                        value: _vm.form,
                        callback: function ($$v) {
                          _vm.form = $$v
                        },
                        expression: "form",
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
      ],
      1
    ),
    _vm._v(" "),
    !_vm.loading
      ? _c(
          "div",
          { staticClass: "redesign-table__footer" },
          [
            _c(
              "a",
              {
                staticClass: "button is-primary",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.openForm.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Neuer Freiplatz")]
            ),
            _vm._v(" "),
            _c("pagination", {
              model: {
                value: _vm.pagination,
                callback: function ($$v) {
                  _vm.pagination = $$v
                },
                expression: "pagination",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }