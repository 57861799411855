var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("main-object", {
        ref: "mainObject",
        attrs: {
          headline: _vm.headline,
          levels: _vm.levels,
          priceType: _vm.priceType,
          providerType: _vm.providerType,
          "show-filters": _vm.showFilters,
          "filter-start-end-keys": _vm.filterStartEndKeys,
        },
        on: { openForm: _vm.openPriceForm },
      }),
      _vm._v(" "),
      _c("item-form", {
        ref: "form",
        attrs: { "provider-type": _vm.providerType },
        on: { submit: _vm.handleSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }