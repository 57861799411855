var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _vm.hasType
      ? _c(
          "div",
          { staticClass: "cell is-icon-cell has-text-centered" },
          [_vm._t("type")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasDate
      ? _c(
          "div",
          { staticClass: "cell is-multiple-date-cell" },
          [_vm._t("date")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasNights
      ? _c(
          "div",
          { staticClass: "cell is-small-cell has-text-centered" },
          [_vm._t("nights")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasName
      ? _c(
          "div",
          {
            staticClass: "cell is-name-cell",
            class: { "has-provider": _vm.hasProvider },
          },
          [_vm._t("name")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasProvider
      ? _c(
          "div",
          { staticClass: "cell is-provider-cell" },
          [_vm._t("provider")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasCurrency
      ? _c(
          "div",
          { staticClass: "cell is-price-cell is-small is-editable" },
          [_vm._t("currency")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasMarginTax
      ? _c(
          "div",
          { staticClass: "cell is-price-cell" },
          [_vm._t("marginTax")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasBuyPriceCalculation
      ? _c(
          "div",
          { staticClass: "cell is-price-cell is-half" },
          [_vm._t("buyPriceCalculation")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasBuyPrice
      ? _c(
          "div",
          {
            staticClass: "cell is-price-cell",
            class: { "is-half": _vm.hasBuyPriceCalculation },
          },
          [_vm._t("buyPrice")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasSellPriceCalculation
      ? _c(
          "div",
          { staticClass: "cell is-price-cell is-half" },
          [_vm._t("sellPriceCalculation")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "cell is-price-cell",
        class: { "is-half": _vm.hasSellPriceCalculation },
      },
      [_vm._t("sellPrice")],
      2
    ),
    _vm._v(" "),
    _vm.hasProfitCalculation
      ? _c(
          "div",
          { staticClass: "cell is-price-cell is-half" },
          [_vm._t("profitCalculation")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "cell is-price-cell",
        class: { "is-half": _vm.hasProfitCalculation },
      },
      [_vm._t("profit")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cell is-price-cell is-margin-cell" },
      [_vm._t("margin")],
      2
    ),
    _vm._v(" "),
    _vm.actions
      ? _c(
          "div",
          { staticClass: "cell is-actions is-small-cell" },
          [_vm._t("actions")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }