import moment from 'moment';
import { currentUser } from '@utilities/functions';

export default {
    data: function () {
        return {
            importChecked: false,

            emptyForm: {
                dataCheckedAt: null,
                dataCheckedBy: null,
            }
        }
    },

    methods: {
        afterDataLoad: function () {
            this.importChecked = !!this.form.dataCheckedAt;
            const { dataCheckedBy } = this.form


            this.form.dataCheckedBy = !!dataCheckedBy && typeof dataCheckedBy === 'object'
                ? `/api/users/${dataCheckedBy.id}`
                : dataCheckedBy
        },
    },

    watch: {
        importChecked: function (isChecked) {
            if (isChecked) {
                this.form.dataCheckedAt = moment().format('DD.MM.YYYY HH:mm:ss');

                const userID = currentUser('id');
                if (!!userID) {
                    this.form.dataCheckedBy = `/api/users/${userID}`;
                }

            } else {
                this.form.dataCheckedAt = null;
                this.form.dataCheckedBy = null;
            }
        }
    }
}
