<template>
    <div class="status-progress">
        <div class="pie-chart" v-bind:data-p="value.progress" v-if="value.progress" :class="colorClass"></div>
        <span :style="bookingSituationStyle"> {{status}}</span>
    </div>
</template>

<script>
import has from 'lodash/has';
import { getValueWithKey } from '@utilities/functions'
import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {
        bookingSituationStyle: function () {
            return {
                ...this.row.bookingSituation === 'completed' && {'font-weight' : 'bold'},
                ...this.row.bookingSituation === 'tax' && {'font-weight' : 'bold', color: '#5FA3AA'},
            }
        },

        isOrderStatus: function () {
            return has(this.row, 'order') && this.row.order.currentStatus.status === this.value.status
                || has(this.row, 'day') && this.row.day.order.currentStatus.status === this.value.status
                || !!this.row.currentStatus && this.row.currentStatus.status === this.value.status
        },

        status: function () {
            if (this.isOrderStatus) {
                if (has(this.options, 'orderStatus')) {
                    return getValueWithKey({key: this.value.status, optionList: this.options.orderStatus})
                } else if (has(this.options, 'status')) {
                    return getValueWithKey({key: this.value.status, optionList: this.options.status})
                }
            }

            const key = has(this.value, 'status') ? this.value.status : this.value;
            return getValueWithKey({ key, optionList: this.options.status })
        },

        colorClass: function () {
            return {
                'is-red': ['booking_cancelled', 'offer_declined', 'deleted'].includes(this.value.status)
            }
        }
    }
}
</script>

