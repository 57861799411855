<template>
    <div class="bp-row-form">
        <div>
            <div class="bp-row-form__title">Zugverbindung</div>

            <div>
                <i class="material-icons" title="Zug">directions_train</i>

                <div class="buttons has-addons toggle-switch has-margin-right">
                    <button
                        class="button is-large"
                        v-on:click.prevent="copyPlaceholder.type = 'night'"
                        v-bind:class="{ 'is-primary': copyPlaceholder.type === 'night' }"
                    >Nacht</button>
                    <button
                        class="button is-large"
                        v-on:click.prevent="copyPlaceholder.type = 'day'"
                        v-bind:class="{ 'is-primary': copyPlaceholder.type === 'day' }"
                    >Tag</button>
                </div>


                <async-select
                    style="width: 150px;"
                    api     = "train_stations"
                    class   = "has-margin-right"
                    ref     = "startTrainStation"
                    v-model = "copyPlaceholder.startTrainStation"
                    v-bind:custom-label = "item => item.name"
                    crudLinkTitle       = "Fügen Sie einen neuen Bahnhof hinzu"
                    v-bind:clearable    = "false"
                    v-bind:allow-empty  = "false"
                    placeholder         ="Abfahrtsbahnhof"
                />
                <div>
                    <button
                        class        = "btn-single-toggle has-margin-right"
                        v-bind:class = "[endTrainStationRestriction ? '' : 'is-active']"
                        v-on:click   = "endTrainStationRestriction = !endTrainStationRestriction"
                        title = "Nur bestehende Zugverbindungen suchen"
                    ><i class="material-icons" style="vertical-align: -2px;">timeline</i></button>
                </div>
                <async-select
                    style="width: 150px;"

                    api     = "train_stations"
                    v-model = "copyPlaceholder.endTrainStation"
                    v-bind:custom-label = "item => item.name"
                    crudLinkTitle       = "Fügen Sie einen neuen Bahnhof hinzu"
                    v-bind:clearable    = "false"
                    v-bind:allow-empty  = "false"
                    :params="endTrainStationRestriction && copyPlaceholder.startTrainStation ? {_groups: ['provider_train_route', 'provider_read', 'location_train_station:provider_train_route'], 'endRoutes.startTrainStation.id': copyPlaceholder.startTrainStation.id } : {_groups: ['provider_train_route', 'provider_read', 'location_train_station:provider_train_route']}"
                    placeholder         = "Ankunftsbahnhof"
                />


            </div>
        </div>


        <div class="is-160 has-time">
            <div class="bp-row-form__title">Zeitangaben</div>

            <div>
                <button
                    class        = "btn-single-toggle has-margin-right"
                    v-bind:class = "[onlyTime ? '' : 'is-active']"
                    v-on:click   = "onlyTime = !onlyTime"
                    title = "Uhrzeit"
                    v-if="serviceDayTimes.length > 0"
                ><i class="material-icons" style="vertical-align: -1px;">access_time</i></button>
                <i title="Uhrzeit" class="material-icons" v-else>access_time</i>
                <template v-if="onlyTime">
                    <date-time class="has-margin-right" v-model="copyPlaceholder.startTime" time-only></date-time> -
                    <date-time class="has-margin-left" v-model="copyPlaceholder.endTime" time-only></date-time>
                </template>
                <template v-else>
                    <div class="select">
                        <multiselect
                            v-bind:options="serviceDayTimes"
                            track-by="id"
                            v-bind:custom-label="serviceDayTimeLabel"
                            v-model="serviceDayTimeSelected"
                            v-on:select="selectServiceTime"
                        />
                    </div>
                </template>
            </div>
            <div v-if="onlyTime">
                <i title="Einkunft am Bahnhof" class="material-icons">av_timer</i>
                <date-time v-model="copyPlaceholder.checkInTime" time-only></date-time>
            </div>
        </div>

        <div>
            <div class="bp-row-form__title">Besondere Zugedetails</div>


            <div>
                <i class="material-icons" title="Klasse">receipt</i>

                <multiselect
                    style="width: 180px"
                    v-if="(options.trainLabels.categorySelect)"
                    v-model="category"
                    track-by="key"
                    label="value"
                    placeholder="Klasse"
                    v-bind:options="objectToKeyValueArray(options.trainLabels.categorySelect)"
                />
            </div>

            <div>
                <i class="material-icons" title="Klasse">receipt</i>

                <multiselect
                    style="width: 180px"
                    v-if="(trainTypes)"
                    v-model="trainType"
                    track-by="key"
                    label="value"
                    placeholder="Besonderer Zug"
                    v-bind:options="objectToKeyValueArray(trainTypes)"
                />
            </div>

            <div v-if="copyPlaceholder.trainType !== 'standard' && copyPlaceholder.type === 'day'">
                <i class="material-icons" title="Klasse">receipt</i>

                <multiselect
                    style="width: 180px"
                    v-if="(wagonTypes)"
                    v-model="wagonType"
                    track-by="key"
                    label="value"
                    placeholder="Wagen"
                    v-bind:options="objectToKeyValueArray(wagonTypes)"
                />
            </div>


        </div>


        <div>
            <div class="bp-row-form__title">Abrechnung</div>

            <div>
                <i title="Set as Additional" class="material-icons">shopping_cart</i>
                <div class="select">
                    <select v-model="copyPlaceholder.clientOffer.status">
                        <option value="included">inkludiert</option>
                        <option value="optional">optional</option>
                        <option value="additional">gesondert</option>
                    </select>
                </div>
            </div>
        </div>

        <div v-if="hasOwnRouteDescription">
            <div class="bp-row-form__title">Routenbeschreibung</div>

            <div>
                <textarea id="description" rows="4" class="textarea" v-model="copyPlaceholder.routeDescription"></textarea>
            </div>
        </div>


        <button
            class="button is-close"
            v-on:click="$emit('cancel')"
            title="Verwerfen"
        ><i class="material-icons">close</i></button>

        <button
            class        = "button is-save is-primary"
            v-bind:class = "{ 'is-loading': isLoading }"
            v-on:click   = "save"
            title        = "Speichern"
        ><i class="material-icons">check</i></button>
    </div>
</template>



<script>
import AsyncSelect from '@components/form/AsyncSelect';
import mixins from './mixins';
import {objectToKeyValueArray} from "@utilities/functions";
import {getAxios} from "@api";


export default {
    mixins: [mixins],


    data: function () {
        return {
            copyPlaceholder: {
                startTrainStation: null,
                endTrainStation: null,
                clientOffer: {
                    status: 'included',
                    priceType: 'per_person',
                    price: '0.00'
                },
                startTime: '00:00',
                endTime:   '00:00',
                checkInTime: '00:00',
                type: 'day',
                trainType: 'standard',
                wagonType: 'standard',
                category: 'second',
                routeDescription: '',
            },
            trainRoutes: [],
            selectedRouteTemplate: null,
            serviceDayTimeSelected: null,
            onlyTime:      true,
            endTrainStationRestriction: true,
        }
    },

    computed: {
        serviceDayTimes: function () {
            let serviceDayTimes = [];
            this.trainRoutes.forEach(route => {
                route.allServiceDayTimes.forEach(time => {
                    serviceDayTimes.push({
                        ...time,
                        route: route,
                    })
                })
            });
            return serviceDayTimes;
        },

        hasOwnRouteDescription: function () {
            return this.options.clientConfig && this.options.clientConfig.crud.train.hasOwnRouteDescription;
        },

        trainTypes: function () {
            let trainLabels = this.options.trainLabels.trainTypes;
            trainLabels.standard = 'Keine Besonderheit';
            return trainLabels;
        },

        wagonTypes: function () {
            let trainLabels = this.options.trainLabels.wagonTypes;
            trainLabels.standard = 'Standardwagen';
            return trainLabels;
        },

        trainType: {
            get: function () {
                return objectToKeyValueArray(this.trainTypes).find(label => label.key === this.copyPlaceholder.trainType);
            },
            set: function (value) {
                this.copyPlaceholder.trainType = value.key;
            }
        },

        category: {
            get: function () {
                return objectToKeyValueArray(this.options.trainLabels.categorySelect).find(label => label.key === this.copyPlaceholder.category);

            },
            set: function (value) {
                this.copyPlaceholder.category = value.key;
            }
        },


        wagonType: {
            get: function () {
                return objectToKeyValueArray(this.wagonTypes).find(label => label.key === this.copyPlaceholder.wagonType);
            },
            set: function (value) {
                this.copyPlaceholder.wagonType = value.key;

            }
        },
    },


    methods: {
        objectToKeyValueArray,

        save: function () {
            this.copyPlaceholder.serviceDescription = null;
            this.$emit('save', this.copyPlaceholder);
        },

        onKeyUp: function (event) {
            if (event.keyCode === 13 &&
                this.placeholder === undefined &&
                this.copyPlaceholder.startTrainStation &&
                this.copyPlaceholder.endTrainStation
                ) {
                this.$nextTick(() => {
                    if (this.placeholderStringify === JSON.stringify(this.copyPlaceholder)) {
                        this.save();
                    }
                });
            }
        },

        selectServiceTime: function (selectedTime) {
            //setTime
            if(selectedTime){
                const startAt = selectedTime.startTimeOfDay ? selectedTime.startTimeOfDay : '00:00:00';
                const endAt = selectedTime.endTimeOfDay ? selectedTime.endTimeOfDay : '00:00:00';
                const checkInAt = selectedTime.checkInTimeOfDay ? selectedTime.checkInTimeOfDay : '00:00:00';
                this.copyPlaceholder.startTime = startAt;
                this.copyPlaceholder.endTime = endAt;
                this.copyPlaceholder.checkInTime = checkInAt;
                this.copyPlaceholder.trainType = selectedTime.route.trainType;
                this.copyPlaceholder.wagonType = selectedTime.route.wagonType;
                this.copyPlaceholder.routeDescription = selectedTime.route.routeDescription;
                this.onlyTime = true;
            }
        },

        serviceDayTimeLabel: function (serviceDayTime) {
            let customLabel = serviceDayTime.startTimeOfDay.substring(0, serviceDayTime.startTimeOfDay.length - 3);
            customLabel += serviceDayTime.endTimeOfDay ? ' - ' + serviceDayTime.endTimeOfDay.substring(0, serviceDayTime.endTimeOfDay.length - 3) : '';
            customLabel += serviceDayTime.checkInTimeOfDay ? ' (Ankunft am Bhf: ' + serviceDayTime.checkInTimeOfDay.substring(0, serviceDayTime.checkInTimeOfDay.length - 3) + ')' : '';
            customLabel += ' - ' + serviceDayTime.provider;
            return customLabel;
        },
    },


    mounted: function () {
        if (!this.placeholder) {
            this.$refs.startTrainStation.$el.querySelector('input').focus();
        }
    },

    watch: {
        'copyPlaceholder.endTrainStation': function() {
            if(this.copyPlaceholder.endTrainStation && this.copyPlaceholder.startTrainStation){

                getAxios('train_routes', {
                    'startTrainStation.id': this.copyPlaceholder.startTrainStation.id,
                    'endTrainStation.id': this.copyPlaceholder.endTrainStation.id,
                    '_groups': ['service_day_time']
                }).then(response => {
                    this.trainRoutes = response.data;
                    if(this.trainRoutes.length > 0) {
                        this.copyPlaceholder.trainType = this.trainRoutes[0].trainType;
                        this.copyPlaceholder.wagonType = this.trainRoutes[0].wagonType;
                        if(this.hasOwnRouteDescription) {
                            this.copyPlaceholder.routeDescription = this.trainRoutes[0].routeDescription;
                        }
                    }
                    this.onlyTime = this.serviceDayTimes.length === 0;
                })


            }
        },
    },


    components: { AsyncSelect }
}
</script>
