<template>
    <div>
        <div class="redesign-table">
            <div class="table-wrapper">
                <table-header
                    v-bind:sellingPrices="sellingPrices"
                    v-on:change:section="toggleSection"
                    v-on:change:hotelType="changeHotelType"
                    section="calculation"
                    v-bind:item="sellingPrice"

                    v-on:delete="$emit('delete')"
                    v-on:edit="$emit('edit')"
                    v-on:reset="$emit('reset')"
                    v-on:copy="v => $emit('copy', v)"
                    :options="options"
                    v-model="selectedSellingPrice"
                />
            </div>
        </div>

        <div class="calculation">
            <calculation v-on:update="calculation => $emit('update', calculation)" />
        </div>
    </div>
</template>

<script>
import Calculation from '@orders/subpages/Calculations/Calculation';
import store from '@orders/subpages/Calculations/store';

import TableHeader from './Header'
import { optionsOrders, changeOrderHotelTypes } from '@api'
import {notifyError, notifySuccess} from "@components/Notification";

export default {
    store,

    props: {
        sellingPrices: {
            type: Array,
            required: true
        },

        sellingPrice: {
            type: Object,
            required: true
        }
    },

    data: function () {
        return {
            options: {},
            canceled: false
        }
    },

    mounted: function () {
        optionsOrders()
            .then(response => {
                this.options = response.data;
                this.setSellingPrice(this.sellingPrice, true)
            })
    },

    methods: {
        getConformation: function(force = true) {
            return true;
            if(force) {
                return true;
            }
            return confirm('Kalkulation wechseln? Nicht gespeicherte Änderungen gehen verloren!')
        },

        setSellingPrice(sellingPrice, force = true) {
            if ((sellingPrice !== this.$store.state.sellingPrice) && (!this.$store.state.shouldSave ||  this.getConformation(force))) {

                this.$emit('setSellingPrice', sellingPrice);
                this.$store.commit('resetState')
                this.$store.dispatch('setOrder', sellingPrice.calculationOrder)
                this.$store.commit('setOptions', this.options)
                this.$store.commit('updateSellingPrice', sellingPrice);
                this.$emit('input', sellingPrice);
            }
        },

        toggleSection: function (section) {
            if (section === 'sellingPrices' && (!this.$store.state.shouldSave || this.getConformation(true))) {
                this.$emit('close')
                this.$store.commit('resetState')
            }
        },
        changeHotelType: function(hotelType){
            changeOrderHotelTypes(this.$store.state.order.id, {
                stars: hotelType.stars,
                category: hotelType.category
            }).then(response => {
                    this.setSellingPrice(this.sellingPrice, true)
                    notifySuccess('Die Hotelkategorie wurde geändert');
                }, error => {notifyError('Die Hotelkategorie konnte nicht geändert werden! Server Error!'); }
            );
        }
    },

    computed: {
        selectedSellingPrice: {
            get: function() {
                return this.sellingPrice;
            },
            set: function(value) {
                this.setSellingPrice(value, true)
            }
        }
    },

    components: {
        Calculation,
        TableHeader
    },
}
</script>

