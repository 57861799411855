var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Besuchsbericht bearbeiten",
        createTitle: "Neuen Besuchsbericht hinzufügen",
      },
    },
    [
      _c("table", [
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "date" } }, [
              _vm._v("Datum:"),
            ]),
          ]),
          _vm._v(" "),
          _c("td", [
            _c(
              "div",
              { staticClass: "control" },
              [
                _c("input-date", {
                  staticClass: "flatpickr--range",
                  model: {
                    value: _vm.form.date,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "date", $$v)
                    },
                    expression: "form.date",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "contact" } }, [
              _vm._v("Kontaktperson:"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c(
                "multiselect",
                {
                  attrs: {
                    multiple: "",
                    "track-by": "id",
                    "custom-label": _vm.contactLabel,
                    options: _vm.contacts,
                  },
                  model: {
                    value: _vm.form.contacts,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "contacts", $$v)
                    },
                    expression: "form.contacts",
                  },
                },
                [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c("label", { staticClass: "label", attrs: { for: "report" } }, [
              _vm._v("Bericht:"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("tip-tap", {
                model: {
                  value: _vm.form.report,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "report", $$v)
                  },
                  expression: "form.report",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }