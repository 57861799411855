<template>
    <div>
        <div class="bp-box is-add-request">
            <header><i class="material-icons">format_list_numbered</i> Leistung</header>

            <main>
                <template v-if="isLoading === false">
                    <div class="is-empty" v-if="requests.length === 0 || requests.length === 1 && requests[0].id === request.id">Es gibt keine Anfragen</div>

                    <table v-else>
                        <tr v-for="request in requests" v-bind:key="request.id" v-if="!isAlreadyInPackage(request.id)">
                            <td>
                                <label class="checkbox" title="Wird bereits im Paket abgerechnet">
                                    <input type="checkbox" v-on:change="checked(request.id, $event)" v-bind:disabled="isAlreadyInPackage(request.id)" />
                                    <i class="material-icons" v-if="request.requestType === 'hotel'">home</i>
                                    <i class="material-icons" v-else-if="request.requestType === 'ferry'">directions_boat</i>
                                    <i class="material-icons" v-else-if="request.requestType === 'airline'">airplanemode_active</i>
                                    <i class="material-icons" v-else-if="request.requestType === 'train'">directions_train</i>
                                    <other-service-icons v-else-if="request.requestType === 'other'" v-bind:type="request.otherServiceType.category"></other-service-icons>
                                    {{ request.info.title }}
                                </label>
                            </td>

                            <!-- <td>
                                <i class="material-icons" title="Date">date_range</i>
                                <template v-if="request.hotel">{{ request.startAt | dayMonth }} - {{ request.endAt | dayMonth }}</template>
                                <template v-if="request.ferry">
                                    <template v-if="request.startAt === request.endAt">{{ request.startAt | dayMonth }}</template>
                                    <template v-else>{{ request.startAt | dayMonth }} - {{ request.endAt | dayMonth }}</template>
                                </template>
                                <template v-else>{{ request.startAt | dayMonth }}</template>
                            </td> -->

                            <td>
                                <template v-if="request.info.provider.name">
                                <i class="material-icons" title="Provider name">business</i> {{ request.info.provider.name }}
                                </template>
                            </td>

                            <td>
                                <template v-if="request.requestType === 'hotel'"><i class="material-icons">local_dining</i> {{ getValueWithKey({ key: request.board, optionList: boardList }) }}</template>
                                <template v-else-if="request.ferry && request.type === 'night'"><i class="material-icons">brightness_4</i> Nachtfähre</template>
                            </td>

                            <td>
                                <i class="material-icons" title="Status">map</i> {{ getValueWithKey({ key: request.requestStatus.status, optionList: requestStatuses }) }}
                            </td>
                        </tr>
                    </table>
                </template>

                <loading v-else></loading>
            </main>
        </div>


        <button class="button is-large" v-on:click="$emit('cancel')"><i class="material-icons">close</i> Abbrechen</button>
        <button class="button is-primary is-large" v-bind:class="{ 'is-loading' : isSaving }" v-on:click="save"><i class="material-icons">check</i> Hinzufügen</button>
    </div>
</template>



<script>
import { store } from '@root/pages/orders/store';
import { addPackage, editPackage, getRequestsByOrder } from '@api';
import { dayMonth, getValueWithKey } from '@utilities/functions';
import { notifySuccess, notifyError } from '@components/Notification';
import Loading from '@components/Loading';
import OtherServiceIcons from '@components/OtherServiceIcons';


export default {
    store: store,


    props: {
        request:  { type: Object,  required: true }
    },


    data: function () {
        return {
            requests: [],
            listOfRequests: [],

            isLoading: false,
            isSaving: false
        }
    },


    computed: {
        orderID:         function () { return this.$store.state.orderID; },
        requestStatuses: function () { return this.$store.state.options.requestStatuses; },
        boardList:       function () { return this.$store.state.options.boardList; },
        providerID:        function () {
            return this.request.hotel ? this.request.hotel.id :
                this.request.ferry   ? this.request.ferry.id :
                this.request.airline ? this.request.airline.id :
                this.request.otherService && this.request.otherService.provider ? this.request.otherService.provider.id :
                this.request.provider ? this.request.provider.id :
                null
        }
    },


    methods: {
        checked(id, event) {
            if (event.target.checked) {
                this.listOfRequests.push(id);

            } else {
                this.listOfRequests = this.listOfRequests.filter(x => x !== id);
            }
        },


        save: function () {
            if (this.listOfRequests.length > 0) {
                let copyRequest = JSON.parse(JSON.stringify(this.request)),
                    data = {
                        agency:   copyRequest.agency ? '/api/agencies/' + copyRequest.agency.id : null,
                        provider: (!copyRequest.agency && !!this.providerID) ? `/api/providers/${this.providerID}` : null,
                        order:    '/api/orders/'   + this.orderID,
                        requests: []
                    };

                this.isSaving = true;

                if (copyRequest.package.id === -1) {
                    copyRequest.package = null;
                }

                this.listOfRequests.push(copyRequest.id);
                // Adding the request from the current package (if any)
                if (copyRequest.package !== null) {
                    for (let item of copyRequest.package.requests) {
                        this.listOfRequests.push(item.id);
                    }
                }

                // Transforms the list of request IDs into an array of references
                console.log('test')
                console.log(this.listOfRequests)
                data.requests = this.listOfRequests.map(id => '/api/requests/' + id);


                if (copyRequest.package === null) {
                    // PUT
                    addPackage(data, '?_groups[]=modal_read&_groups[]=order_request_read').then(response => {
                        this.$emit('update', response.data);
                        notifySuccess('Das Preispaket wurde hinzugefügt!');
                        this.isSaving = false;

                    }, error => {
                        notifyError('Das Preispaket konnte nicht hinzugefügt werden! Server Error!');
                        this.isSaving = false;
                    });

                } else {
                    // Post
                    editPackage({ id: copyRequest.package.id, data: data, params: '?_groups[]=modal_read&_groups[]=order_request_read' }).then(response => {
                        this.$emit('update', response.data);
                        notifySuccess('Das Preispaket wurde aktualisert!');
                        this.isSaving = false;

                    }, error => {
                        notifyError('Das Preispaket konnte nicht aktualisiert werden! Server Error!');
                        this.isSaving = false;
                    })
                }

            } else {
                notifyError('Es gibt keine Preispakete, die man speichern könnte!');
            }
        },


        isAlreadyInPackage: function (id) {
            if (this.request.id === id) {
                return true;
            }

            if (this.request.package !== null && this.request.package.id !== -1) {
                for (let item of this.request.package.requests) {
                    if (item.id === id) {
                        return true;
                    }
                }
            }

            return false;
        },


        getValueWithKey
    },


    beforeMount: function () {
        let params        = '',
            providerParams = null,
            groups = '&_groups[]=order_package_read&_groups[]=provider_read&_groups[]=other_service_type_read&_groups[]=other_service_read&_groups[]=order_request_status_read';

        if (this.request.agency) {
            params = '&agency=' + this.request.agency.id;

        } else {
            params = '&agency[exists]=false';
            providerParams = this.request.hotel   ? this.request.hotel.id :
                             this.request.ferry   ? this.request.ferry.id :
                             this.request.airline ? this.request.airline.id :
                                 this.request.otherService.provider.id;
        }

        this.isLoading = true;

        getRequestsByOrder(this.orderID, groups + params, null, providerParams)
            .then(responses => {
                this.requests = [].concat(...responses.map(response => response.data));
                this.isLoading = false;

            }, error => {
                notifyError('Die Anfragen konnten nicht geladen werden! Server Error!');
                this.isLoading = false;
            }
        );
    },


    filters: {
        dayMonth
    },


    components: {
        Loading,
        OtherServiceIcons
    }
}
</script>
