<template>
    <free-form
        v-model                    = "copyPlaceholder"
        v-bind:isConcept           = "true"
        v-bind:limitedDestinations = "options.dayDestinations"
        v-bind:isTaggable          = "false"
    >
        <button
            class="button is-close"
            v-on:click="$emit('cancel')"
            title="Verwerfen"
        ><i class="material-icons">close</i></button>

        <button
            class        = "button is-save is-primary"
            v-bind:class = "{ 'is-loading': isLoading }"
            v-on:click   = "save"
            title        = "Speichern"
        ><i class="material-icons">check</i></button>


    </free-form>
</template>



<script>
import { notifyError } from '@components/Notification';
import FreeForm from '@orders/placeholders/components/forms/Free';
import mixins from './mixins';
import {time} from "@utilities/functions";

export default {
    mixins: [mixins],


    data: function () {
        return {
            copyPlaceholder: {
                predefinedFreeTextItem: null,
            },
        }
    },
    methods: {
        save: function () {
            if (this.copyPlaceholder.predefinedFreeTextItem && this.copyPlaceholder.predefinedFreeTextItem.id){
                this.copyPlaceholder.predefinedFreeTextItem = '/api/predefined_free_text_items/' + this.copyPlaceholder.predefinedFreeTextItem.id
            }
            if (this.copyPlaceholder.text){
                this.$emit('save', this.copyPlaceholder);
            } else {
                notifyError('Der Freitext kann nicht leer sein!');
            }
        },
    },


    components: {
        FreeForm
    }
}
</script>
