<template>
    <span>
        {{ row.startAirport && row.startAirport.place.name }}
        <template v-if="row.endAirport && row.endAirport">-</template>
        {{ row.endAirport && row.endAirport.place.name }}
    </span>
</template>

<script>
import Cell from './cell';

export default {
    mixins: [ Cell ],
}
</script>
