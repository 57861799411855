<template>
    <div>
        <div class="is-half is-flex is-justify-content-space-between">
            <h1>Kundenbesuche</h1>
            <i class="material-icons is-pointer" @click="openForm">add_circle</i>
        </div>
        <div class="client-visit is-half">
            <div v-for="clientVisit in item.clientVisits">
                <accordion class="client-accordion" :is-open="false">
                    <div slot="header" class="is-flex">
                        <div class="is-half is-flex is-align-items-center">
                                <h4>{{clientVisit.date}}</h4>
                                <i class="material-icons is-pointer mr-1" @click="deleteItem('clientVisits', clientVisit)">delete</i>
                                <i class="material-icons is-pointer" @click="openForm(clientVisit)">edit</i>
                        </div>
                        <div class="is-half is-flex is-align-items-center" v-if="clientVisit.user">
                            <h4>{{contactString(clientVisit)}}</h4>
                            <user-image
                                class="user-image mt-0"
                                v-bind:user="clientVisit.user"
                                small
                            />
                        </div>
                    </div>
                    <div slot="content">
                        <div class="nl2br py-3 mx-3" v-html="clientVisit.report"></div>
                    </div>
                </accordion>
            </div>
        </div>
        <client-visit-form ref="form" slot="form" />
    </div>

</template>

<script>
import Mixin from '../../tab';
import ClientVisitForm from './Form';
import { fullYear } from '@utilities/functions';
import Accordion   from '@components/Accordion';
import UserImage from '@components/UserImage';
export default {
    mixins: [ Mixin ],

    components: {ClientVisitForm, Accordion, UserImage },

    computed: {
    },

    methods: {
        contactString: function(item) {
            return item.contacts.map(contact => contact.fullName).join(' | ');
        },

        label: function (item) {
            return `${fullYear(item.startAt)} - ${fullYear(item.endAt)}`
        }
    }
}
</script>
