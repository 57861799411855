var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "row",
    [
      _c("template", { slot: "type" }, [
        _c("i", { staticClass: "material-icons" }, [
          _vm._v("subdirectory_arrow_right"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "date" },
        [
          !_vm.included
            ? [_vm._v("Im Preis inkludieren:")]
            : [_vm._v("bereits inkludiert")],
        ],
        2
      ),
      _vm._v(" "),
      _c("template", { slot: "nights" }, [_vm._v("-")]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "name" },
        [
          _c("multiselect", {
            staticClass: "is-medium",
            attrs: {
              options: _vm.options,
              label: "label",
              "track-by": "id",
              placeholder: "Nichts",
            },
            model: {
              value: _vm.airlinePrice,
              callback: function ($$v) {
                _vm.airlinePrice = $$v
              },
              expression: "airlinePrice",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "empty", attrs: { slot: "buyPrice" }, slot: "buyPrice" },
        [_vm._v("-")]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "empty",
          attrs: { slot: "sellPrice" },
          slot: "sellPrice",
        },
        [_vm._v("-")]
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "empty", attrs: { slot: "profit" }, slot: "profit" },
        [_vm._v("-")]
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "empty", attrs: { slot: "margin" }, slot: "margin" },
        [_vm._v("-")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }