<template>
    <modal-form
        editTitle="Kundentermin bearbeiten"
        createTitle="Neuen Kundentermin hinzufügen"
    >
        <table>
            <tr>
                <th>
                    <label for="name" class="label">Name:</label>
                </th>
                <td>
                    <div class="control">
                        <input type="text" class="input" v-model="form.name" />
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="description" class="label">Beschreibung:</label></th>
                <td>
                    <tip-tap v-model="form.description" />
                </td>
            </tr>

            <tr>
                <th>
                    <label for="startAt" class="label">Datum:</label>
                </th>
                <td>
                    <div class="flatpickr-combined">
                        <input-date v-model="form.startAt" />
                        <span>-</span>
                        <input-date v-model="form.endAt" />
                    </div>
                </td>
            </tr>
        </table>
    </modal-form>
</template>

<script>
import TabForm from '../form'
import { TipTap, InputDate } from '@components/form';

export default {
    mixins: [ TabForm ],

    props: {
        isMuseum: {
            type: Boolean,
            default: false,
        }
    },

    form: {
        name: '',
        description: '',
        startAt: null,
        endAt: null,
    },

    property: 'clientDates',

    components: {
        TipTap, InputDate
    }
}
</script>
