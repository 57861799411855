var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "configurator search-day-concept is-gapless" },
    [
      _c("div", { staticClass: "column is-half" }, [
        _c("div", { staticClass: "panel" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "panel-block is-spaceless" },
            [
              _c("concept-days", {
                staticClass: "day-concepts",
                attrs: {
                  concept: _vm.value,
                  options: { day: { categories: [] } },
                },
                on: { select: (day) => (_vm.selectedDay = day) },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "column is-half" }, [
        _c("div", { staticClass: "panel" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "panel-block is-spaceless" },
            [
              _vm.selectedDay
                ? _c("day", {
                    staticClass: "day-concepts",
                    on: { input: _vm.updateDay },
                    model: {
                      value: _vm.selectedDay,
                      callback: function ($$v) {
                        _vm.selectedDay = $$v
                      },
                      expression: "selectedDay",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-heading" }, [
      _c("span", [_vm._v("Vorschau:")]),
      _vm._v(" "),
      _c("span", { staticClass: "is-light" }, [
        _vm._v(
          "auf Tag klicken um Nächte und Länge von Zusatzleistungen festzulegen"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-heading" }, [
      _c("span", [_vm._v("Vorschau Des Tages:")]),
      _vm._v(" "),
      _c("span", { staticClass: "is-light" }, [
        _vm._v("Nächte und Länge von Zusatzleistungen festlegen"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }