<template>
    <div class="new-request">
        <div class="overall-buttons">
            <button class="button is-large" v-on:click="$emit('close')"><i aria-hidden="true" class="fa fa-long-arrow-left"></i> Zurück zur Übersicht</button>
        </div>


        <accordion v-bind:isCollapsible="false">
            <div slot="header">
                <div>
                    <request-score v-if="newRequest.airline" v-bind:rating="newRequest.airline.buspaketRating" />
                    <i aria-hidden="true" class="fa fa-plus-circle" v-else></i>
                </div>

                <div>
                    LEISTUNGSTRÄGER HINZUFÜGEN
                    <provider-info class="header-extra-info" v-bind:provider="newRequest.airline">
                        <div>
                            <i class="material-icons spacing-left">perm_identity</i> Kundenkontingent:
                            {{ airlineContingent }} Tickets
                        </div>
                    </provider-info>
                </div>

                <div>
                    <label v-on:click.stop>
                        <input type="checkbox" v-model="isMainRequest" />
                        als Leistung festlegen
                    </label>
                </div>
            </div>


            <div slot="content">
                <div class="bp-box is-info">
                    <main>
                        <i class="material-icons">business</i> Airline:

                        <airline-select
                            v-model                 = "newRequest.airline"
                            v-bind:placeholderInfo  = "placeholderInfo"
                            v-on:updateStartAirport = "value => newRequest.startAirport = value"
                            v-on:updateEndAirport   = "value => newRequest.endAirport   = value"
                            :show-airport-selects="false"
                            multi-select-style="width:400px"
                        ></airline-select>

                        <agency-select
                            class="has-margin-left"
                            v-bind:provider = "newRequest.airline"
                            text            = "Agentur"
                            v-on:update     = "agency => newRequest.agency = agency"
                            prefill
                        ></agency-select>


                        <div>
                            <i class="material-icons">image_aspect_ratio</i> Flugnummer
                            <input class="input" type="text" v-model="newRequest.flightNumber" />
                        </div>

                        <div>
                            <i class="material-icons">image_aspect_ratio</i> PNR
                            <input class="input" type="text" v-model="newRequest.ticketNr" />
                        </div>
                    </main>
                </div>


                <div class="columns">
                    <div class="column">
                        <div class="bp-box is-tickets">
                            <header>
                                <div><i class="material-icons" title="Tickets">airplanemode_active</i> Airline</div>
                                <div><i class="material-icons">help</i></div>
                                <div><button title="Extraticket für Airline" v-on:click="addExtra"><i class="material-icons">person_add</i></button></div>
                            </header>

                            <main>
                                <div>
                                    <div><i class="material-icons" title="Tickets">airplanemode_active</i> Tickets</div>
                                    <div>
                                        <input
                                            class="input"
                                            type="text"
                                            v-model.number="newRequest.contingent.askedAmount"
                                            v-on:focus="$event.target.select()"
                                        />
                                    </div>
                                    <div></div>
                                </div>

                                <div class="extra-people" v-if="hasExtra">
                                    <div>
                                        <i class="material-icons">person_add</i>
                                        <input
                                            class="input"
                                            type="text"
                                            v-model="newRequest.contingent.extraDescription"
                                        />
                                        <button class="btn-delete" title="Löschen" v-on:click="deleteExtra" tabindex="-1"><i class="material-icons">delete</i></button>
                                    </div>
                                    <div>
                                        <div class="control"><input
                                            class="input"
                                            type="text"
                                            v-model.number="newRequest.contingent.extraAskedAmount"
                                            v-on:focus="$event.target.select()"
                                        /></div>
                                    </div>
                                    <div></div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>


                <div class="columns">
                    <div class="column is-offset-6">
                        <item-prices
                            v-bind:isWithoutPrice="true"
                            v-model="newRequest.itemPrices"
                            v-bind:provider="provider"
                        ></item-prices>
                    </div>
                </div>
            </div>
        </accordion>


        <button class="button is-large" v-on:click="$emit('close')"><i class="material-icons">close</i> Abbrechen</button>

        <button
            class="button is-primary is-large"
            v-bind:class="{ 'is-loading': isLoading }"
            v-on:click="saveNewRequest"
        ><i class="material-icons">check</i> speichern und zur Email</button>

        <button
            class="button is-primary is-large"
            v-bind:class="{ 'is-loading': isLoading }"
            v-on:click="saveNewRequest('close')"
        ><i class="material-icons">keyboard_arrow_right</i> speichern und schließen</button>


        <confirmation
            v-bind:isVisible="isVisibleConfirmation"
            is-local
            text="Soll die Beschreibung der Anfrage in den Platzhalter kopiert werden?"
            v-on:input="value => $emit('confirm', value)"
        ></confirmation>
    </div>
</template>



<script>
import mixins                from './mixins';
import { prepareForBackend } from '@utilities/functions';
import { notifyError }       from '@components/Notification';
import AirlineSelect         from '../components/AirlineSelect';


export default {
    mixins: [mixins],


    computed: {
        airlineContingent: function () { return this.$store.state.hotelContingent.info.personsAsked; },
        provider: function () { return this.newRequest.airline ? this.newRequest.airline : null }
    },


    data: function () {
        return {
            apiEndpoint: 'airline_requests',
            hasExtra: false
        };
    },


    methods: {
        saveNewRequest: function (close = false) {
            if (this.newRequest.airline === null && this.newRequest.agency === null) {
                notifyError('Sie müssen einen Anbieter oder eine Agentur auswählen!');
                return false;
            }

            let copyNewRequest = JSON.parse(JSON.stringify(this.newRequest));

            // Transform 'kein Provider' => null OR airline to its reference
            copyNewRequest.airline = copyNewRequest.airline && copyNewRequest.airline.id ? '/api/airlines/' + copyNewRequest.airline.id : null;

            // preparing for backend use
            copyNewRequest = prepareForBackend(copyNewRequest);

            this.isLoading = true;

            // API call
            this.addRequest(copyNewRequest, close);
        },


        addExtra: function () {
            this.newRequest.contingent.extraDescription    = '';
            this.newRequest.contingent.extraReservedAmount = 0;
            this.newRequest.contingent.extraAskedAmount    = 0;
            this.hasExtra = true
        },


        deleteExtra: function () {
            this.newRequest.contingent.extraDescription    = null;
            this.newRequest.contingent.extraReservedAmount = null;
            this.newRequest.contingent.extraAskedAmount    = null;
            this.hasExtra = false;
        }
    },


    created: function () {
        this.newRequest.contingent.askedAmount = this.airlineContingent;
    },


    watch: {
        'newRequest.airline': function () {
            if (this.newRequest.airline) {
                this.newRequest.agency = null;
            }
        }
    },


    components: {
        AirlineSelect
    }
}
</script>
