<template>
<div class="users">
    <div class="user is-favicon is-small" style="z-index: 2;">
        <user-image v-if="order.user" v-bind:user="order.user" :favicon="true" />
    </div>
    <div class="user is-favicon is-small" style="z-index: 1;">
        <div class="user__avatar" v-bind:style="faviconStyle(order.organisationFilter)"></div>
    </div>
</div>
</template>

<script>
import UserImage from '@components/UserImage';
import Cell from './cell';
import has from "lodash/has";

export default {
    mixins: [ Cell ],

    computed: {
        order: function () {
            return has(this.row, 'order') ? this.row.order : this.row;
        }
    },

    methods: {
        faviconStyle: function (organisation) {
            if(!organisation){
                return {
                    'background-image' : `url(/clientSpecific/favicon/default.ico)`
                }
            }
            return {
                'background-image' : `url(/clientSpecific/favicon/${organisation.id}.ico)`,
            }
        }
    },

    components: {
        UserImage
    }
}
</script>
