<template>
    <modal
        v-bind:classes  = "'placeholder'"
        v-bind:visible  = "isModalVisible"
        v-on:closeModal = "closeModal"
    >
        <template v-if="modalLoading">
            <div slot="content">
                <loading textInFront="Daten werden aktualisiert"></loading>
            </div>
        </template>


        <template v-else>
            <template slot="header">
                <div class="is-saving">
                    <i class="material-icons" v-if="isSaving === 'ok'">check</i>
                    <i class="material-icons icon-spin" v-else-if="isSaving">refresh</i>
                </div>

                <div class="name">
                    <template v-if="localPlaceholder.serviceDescription">{{ localPlaceholder.serviceDescription }} </template>
                    <template v-else>{{ localPlaceholder.otherServiceType.name }} </template>
                </div>

                 <div class="bp-row-form extra-info">

                    <div>
                        <div>
                            <i class="material-icons" style="font-size: 21px">explore</i>

                            <div class="select">
                                <select v-model="categorySelected" ref="category">
                                    <option v-bind:value="null" disabled selected>Kategorie</option>
                                    <option v-for="category in categories" v-bind:value="category.key">{{ category.value }}</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <button
                                v-bind:class="['btn-single-toggle',  onlyFromOrder ? '' : 'is-active']"
                                v-on:click="onlyFromOrder = !onlyFromOrder"
                                v-bind:title="onlyFromOrder ? 'Only from trip destinations' : 'All'"
                                style="border: 1px solid #dddddd"
                            ><i class="material-icons">flag</i></button>

                            <location-select
                                ref="location"
                                v-bind:value="{
                                    area:  localPlaceholder.area,
                                    place: localPlaceholder.place}"
                                isNullable
                                hasDestination
                                v-bind:limitedDestinations="destinationsForLocation"
                                v-on:input="value => {
                                    localPlaceholder.area  = value.area;
                                    localPlaceholder.place = value.place;}"
                            ></location-select>
                        </div>
                    </div>


                    <div class="is-flex-grow">
                        <other-service-type-select
                            v-model="localPlaceholder.otherServiceType"
                            v-bind:placeholderInfo="placeholderInfo"
                            :show-service-day-times="true"
                        ></other-service-type-select>
                        <div>
                            <i class="material-icons" title="Treffpunkt" style="font-size: 21px">my_location</i>
                            <input
                                class          = "input"
                                type           = "text"
                                v-model = "localPlaceholder.meetingPoint"
                                placeholder="Treffpunkt"
                            />
                        </div>

                    </div>

                    <div>
                        <div class="has-time">
                            <i class="material-icons">date_range</i>
                            <span class="is-static-date">{{ localPlaceholder.startAt | dayMonth }} </span>
                            -
                            <datepicker
                                placeholder="Ende"
                                class="has-margin-left"
                                v-bind:config="{
                                dateFormat: 'd.m.Y H:i:S',
                                minDate: addDays(localPlaceholder.startAt, 1),
                                defaultDate: today,
                                parseDate:  function (dateStr) { return moment(dateStr, 'DD.MM.YYYY hh:mm:ss').toDate(); },
                                formatDate: function (dateObj) { return moment(dateObj).format('DD.MM.YYYY') + ' 00:00:00'; }}"
                                v-model="localPlaceholder.endAt">
                            </datepicker>
                        </div>
                        <div>
                            <button
                                class        = "btn-single-toggle has-margin-right"
                                v-bind:class = "[onlyTime ? '' : 'is-active']"
                                v-on:click   = "onlyTime = !onlyTime"
                                title = "Uhrzeit"
                                v-if="serviceDayTimes.length > 0"
                            ><i class="material-icons" style="vertical-align: -1px;">access_time</i></button>
                            <i class="material-icons" v-else>access_time</i>

                            <template v-if="onlyTime">
                                <date-time class="has-margin-right" v-model="localPlaceholder.startAt"></date-time> -
                                <date-time class="has-margin-left" v-model="localPlaceholder.endAt" placeholderText="Ende"></date-time>
                            </template>

                            <template v-else>
                                <div class="select">
                                    <multiselect
                                        v-bind:options="serviceDayTimes"
                                        track-by="id"
                                        v-bind:custom-label="serviceDayTimeLabel"
                                        v-model="serviceDayTimeSelected"
                                        v-on:select="selectServiceTime"
                                    />
                                </div>
                            </template>
                        </div>

                    </div>

                    <div>
                        <div>
                            <i title="Set as Additional" class="material-icons">shopping_cart</i>
                            <div class="select">
                                <select v-model="localPlaceholder.clientOffer.status">
                                    <option value="included">inkludiert</option>
                                    <option value="optional">optional</option>
                                    <option value="additional">gesondert</option>
                                </select>
                            </div>
                        </div>
                        <div v-if="includes(['optional', 'additional', 'included'], localPlaceholder.clientOffer.status)">
                            <i class="material-icons">people</i>
                            <div class="select">
                                <select v-model="localPlaceholder.clientOffer.priceType">
                                    <option value="per_person">Pro Person</option>
                                    <option value="per_group">Pro Gruppe</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </div>


                <button class="button is-large is-success is-add-new" style="top:33px;" v-on:click="addNewRequest"><i class="fa fa-plus-circle" aria-hidden="true"></i> Leistungsträger hinzufügen</button>
            </template>


            <div slot="content">
                <request
                    v-for="(request, index) in localPlaceholder.requests"
                    v-bind:key="request.id"
                    v-if="!isNewRequestVisible && request.id"
                    v-bind:placeholderInfo="placeholderInfo"
                    v-model="localPlaceholder.requests[index]"
                    v-on:closeModal="$emit('closeModal', localPlaceholder)"
                    v-bind:calculationRequest="calculationRequest"
                    v-on:setCalculationRequest="setCalculationRequest"
                    v-on:saved="handleRequestSaved"
                ></request>


                <new-request
                    v-if="isNewRequestVisible"
                    v-bind:placeholderID="localPlaceholder.id"
                    v-bind:placeholderInfo="placeholderInfo"
                    v-model="localPlaceholder.requests[localPlaceholder.requests.length - 1]"
                    v-on:close="closeNewRequestModal"
                    v-on:closeModal="$emit('closeModal', localPlaceholder)"
                    v-on:saved="handleRequestSaved"
                ></new-request>
            </div>
        </template>
    </modal>
</template>



<script>
import pick from 'lodash/pick';
import mixins from './mixins';
import Request    from './requests/Other';
import NewRequest from './newRequests/Other';
import moment from "moment";
import axios from "axios";
import OtherServiceTypeSelect from "@orders/placeholders/components/forms/OtherServiceTypeSelect";
import includes from "lodash/includes";


export default {
    mixins: [mixins],


    computed: {
        emptyRequest: function () {
            return {
                agency:           null,
                startAt:          this.localPlaceholder.startAt,
                endAt:            this.localPlaceholder.endAt,
                checkInAt:        this.localPlaceholder.checkInAt,
                assignedContact:  null,
                otherServiceType: this.localPlaceholder.otherServiceType,
                otherService:     null,
                placeholder:      '/api/other_placeholders/' + this.localPlaceholder.id,
                contingent:       {
                    extraDescription: null,
                    extraAskedAmount: null,
                    extraReservedAmount: null
                },
                optionTodo: {dueDate: null},
                itemPrices: []
            }
        },


        placeholderInfo: function () { return pick(this.localPlaceholder, 'area', 'place', 'destination', 'serviceDescription', 'otherServiceType'); }
    },


    data: function () {
        return {
            typeOfPlaceholder: 'otherPlaceholders',
            apiEndpoint: 'other_placeholders',
            categorySelected:     this.initialValue.otherServiceType ? this.initialValue.otherServiceType.category : null,
            categories: [],
            oldValue: null,
            onlyFromOrder: true,
            serviceDayTimes: [],
            serviceDayTimeSelected: null,
            onlyTime:      true,
        }
    },
    created: function () {
        this.getCategoryOptions();

    },
    methods: {
        selectServiceTime: function (selectedTime) {
            //setTime
            if(selectedTime){
                const startAt = selectedTime.startTimeOfDay ? selectedTime.startTimeOfDay : '00:00:00';
                const endAt = selectedTime.endTimeOfDay ? selectedTime.endTimeOfDay : '00:00:00';
                const checkInAt = selectedTime.checkInTimeOfDay ? selectedTime.checkInTimeOfDay : '00:00:00';
                this.localPlaceholder.startAt = moment(this.localPlaceholder.startAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') + ' ' + startAt;
                this.localPlaceholder.endAt = moment(this.localPlaceholder.endAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') + ' ' + endAt;
                this.localPlaceholder.checkInAt = moment(this.localPlaceholder.checkInAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') + ' ' + checkInAt;
                this.onlyTime = true;
            }

        },
        serviceDayTimeLabel: function (serviceDayTime) {
            let customLabel = serviceDayTime.startTimeOfDay.substring(0, serviceDayTime.startTimeOfDay.length - 3);
            customLabel += serviceDayTime.endTimeOfDay ? ' - ' + serviceDayTime.endTimeOfDay.substring(0, serviceDayTime.endTimeOfDay.length - 3) : '';
            customLabel += serviceDayTime.checkInTimeOfDay ? ' (CheckIn: ' + serviceDayTime.checkInTimeOfDay.substring(0, serviceDayTime.checkInTimeOfDay.length - 3) + ')' : '';
            customLabel += ' - ' + serviceDayTime.provider;
            return customLabel;
        },
        getCategoryOptions: function () {
            let options = JSON.parse(localStorage.getItem('options'));

            if (options && options.otherServiceType && options.otherServiceType.category &&
                options.otherServiceType.categoryOptions && options.lastUpdates &&
                options.lastUpdates.otherServiceType && moment(options.lastUpdates.otherServiceType) >= moment('26.11.2020', 'DD.MM.YYYY')) {
                this.categories = options.otherServiceType.category;
                this.categoryOptions = options.otherServiceType.categoryOptions;

            } else {
                axios.options('/api/other_service_types').then(response => {
                    if (!options)                  { options                  = {}; }
                    if (!options.otherServiceType) { options.otherServiceType = {}; }
                    if (!options.categoryOptions) { options.categoryOptions = {}; }
                    if (!options.lastUpdates) { options.lastUpdates = {}; }

                    options.otherServiceType.category = response.data.category;
                    options.otherServiceType.categoryOptions = response.data.categoryOptions
                    options.lastUpdates.otherServiceType = moment().startOf('day');


                    this.categories = options.otherServiceType.category;
                    this.categoryOptions = options.otherServiceType.categoryOptions;


                    localStorage.setItem('options', JSON.stringify(options));
                });
            }
        },
        includes
    },
    watch : {
        'localPlaceholder': {
            deep: true,
            handler: function (value) {
                value=_.pick(value, 'id', 'category', 'area', 'place','otherServiceType', 'meetingPoint', 'startAt', 'endAt', 'serviceDayTimeSelected', 'clientOffer.status', 'clientOffer.priceType')
                if (this.oldValue && JSON.stringify(this.oldValue) !== JSON.stringify(value) ) {
                    this.savePlaceholder(value)
                }
                this.oldValue= JSON.parse(JSON.stringify(value))
            }
        }
    },


    components: {
        NewRequest,
        Request,
        OtherServiceTypeSelect
    }
}
</script>
