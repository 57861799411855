<template>
    <div>
        <!-- Bus Prices -->
        <div class="row">
            <div class="cell">Buspreis</div>
            <div class="cell"></div>
            <div class="cell">1</div>
            <div class="cell">{{ calculations.contingent.busPrice.amount | priceView }}</div>
            <div class="cell"><b>{{ calculations.contingent.busPrice.amount | priceView }}</b></div>
        </div>
        <div class="row">
            <div class="cell">Steuern - Bus</div>
            <div class="cell"></div>
            <div class="cell">1</div>
            <div class="cell">{{ calculations.contingent.busTax.amount | priceView }}</div>
            <div class="cell"><b>{{ calculations.contingent.busTax.amount | priceView }}</b></div>
        </div>
        <div class="row">
            <div class="cell">Steuern - pro Person</div>
            <div class="cell"></div>
            <div class="cell">{{calculations.personsReserved + calculations.personsExtraReserved}}</div>
            <div class="cell">{{ calculations.contingent.ticketTax.amount | priceView }}</div>
            <div class="cell"><b>{{((calculations.personsReserved + calculations.personsExtraReserved) * calculations.contingent.ticketTax.amount) | priceView}}</b></div>
        </div>
    </div>
</template>

<script>
import { priceView } from '@utilities/functions'
export default {
    props: {
        calculations: {
            type: Object,
            required: true
        }
    },

    filters: {
        priceView
    }
}
</script>
