<template>
    <div class="configurator">
        <div class="columns">
            <div class="column is-one-third">
                <search
                    v-model="searchConcept"
                    v-on:input="inputSearchConcept"
                />
            </div>
        </div>

        <main v-if="searchResults.length > 0 || isLoading">
            <div class="search-day-concept" v-if="searchResults.length > 0">
                <div class="column is-one-third">
                    <div class="panel">
                        <div class="panel-heading">Suchergebnisse - Bestehende Gesamtkonzepte:</div>
                        <div class="panel-block is-spaceless">
                            <concepts
                                class="day-concepts"
                                v-bind:concepts="searchResults"
                                v-on:select="selectConcept"
                                v-bind:value="conceptSelected"
                                v-bind:options="options"
                            />
                        </div>
                    </div>
                </div>

                <div class="column is-one-third">
                    <div class="panel">
                        <div class="panel-heading">
                            <span>Vorschau:</span>
                            <span class="is-light">auf Tag klicken um Nächte und Länge von Zusatzleistungen festzulegen</span>
                        </div>
                        <div class="panel-block is-spaceless">
                            <concept-days
                                class="day-concepts"
                                v-bind:concept="conceptSelected"
                                v-on:select="selectDay"
                                v-bind:options="options"
                            />
                        </div>
                    </div>
                </div>

                <div class="column is-one-third">
                    <div class="panel">
                        <div class="panel-heading">
                            <span>Vorschau Des Tages:</span>
                            <span class="is-light">Nächte und Länge von Zusatzleistungen festlegen</span>
                        </div>
                        <div class="panel-block is-spaceless">
                            <day
                                class="day-concepts"
                                v-if="daySelected"
                                v-model="daySelected"
                                v-on:input="updateDay"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <loading v-if="isLoading" />
        </main>
    </div>
</template>



<script>
import debounce from 'lodash/debounce';
import Loading from '@components/Loading';
import { notifySuccess, notifyError } from '@components/Notification';
import { getOrderConcepts, optionsDayConcept, optionsOrderConcept } from '@api';

import ConceptDays from './ConceptDays';
import Concepts from './Concepts';
import Day from './Day';
import Search from './Search'

export default {
    props: {
        minDays: {
            type: Number,
            default: 0,
        }
    },


    data: function () {
        return {
            searchConcept: '',
            conceptSelected: false,
            daySelected: false,
            searchResults: {},
            options: {
                order: {},
                day: {}
            },
            isLoading: false
        }
    },


    computed: {
        params: function () {
            return `?name=${this.searchConcept}&_groups[]=concept_order:days&_groups[]=concept_day_read`;
        },

        components: function () {
            return this.$options.components
        }
    },


    methods: {
        selectDay: function (day) {
            this.daySelected = JSON.parse(JSON.stringify(day))
        },


        updateDay: function (newDay) {
            const conceptSelected = {
                ...this.conceptSelected,
                days: [
                    ...this.conceptSelected.days
                ].map(day => {
                    if (day.id === newDay.id) {
                        return {
                            ...day,
                            ...newDay
                        }
                    } else {
                        return day;
                    }
                })
            }

            this.$emit('change', conceptSelected);
            this.conceptSelected = conceptSelected
        },


        inputSearchConcept: debounce(function () {
            if (!this.searchConcept)
                return;
            this.searchResults = [];
            this.isLoading = true;

            getOrderConcepts(this.params)
                .then(response => {
                    this.isLoading = false;
                    this.searchResults = response.data;
                    this.selectConcept({});
                }, error => {
                    notifyError('Couldn\'t retrieve concepts! Server Error!');
                });
        }, 250),


        selectConcept: function (concept) {
            if (concept.days) {
                if ( concept.days.length <= this.minDays + 1) {
                    for (let i=0; i<concept.days.length; i++)
                    {
                        for(let t=0; t<concept.orderOfDays.length; t++){
                            if (concept.days[i].id === parseInt(concept.orderOfDays[t])){
                                concept.days[i].position = t;
                            }
                        }
                    }

                    concept.days.sort(function (a,b){ return a.position - b.position;});

                    this.$emit('change', concept);
                    this.conceptSelected = concept;


                } else {
                    notifyError(`Für das Konzept werden ${concept.days.length} Tage benötigt! Die aktuelle Reisedauer beträgt nur ${this.minDays + 1} Tage`);
                }
            }
        }
    },


    created: function () {
        Promise.all([
            optionsOrderConcept(),
            optionsDayConcept()
        ]).then(([orderConcept, dayConcept]) => {
            this.options = {
                order: Object.assign({}, this.options.order, orderConcept.data),
                day: dayConcept.data
            }
        });
    },


    components: {
        Loading,

        Search, Concepts, ConceptDays, Day
    }
}

export {
    Search,
    Day,
    ConceptDays,
    Concepts
}

</script>
