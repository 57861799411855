var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [_c("h1", [_vm._v("Kundenprofil")]), _vm._v(" "), _c("table-info")],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mt-6" }, [_c("client-visit")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }