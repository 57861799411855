<script>
import mixin from './mixin'
export default {
    mixins: [ mixin ],

    icon: 'directions_train',

    computed: {
        isDay: function () { return this.placeholder.type === 'day' },
        isNight: function () { return !this.isDay },

        hasPersonPrice: function () { return true },
        personPrice: {
            get: function () {
                return this.isNight
                    ? this.price[this.personPriceType].amount
                    : this.price[this.singlePriceType].amount
            },

            set: function (value) {
                this.$store.commit('updatePrice', {
                    trainPrices: {
                        id: this.price.id,
                        ...this.isNight && { [this.personPriceType]: {
                                amount: value,
                                currency: this.placeholder.placeholderCurrency
                            } },
                        ...!this.isNight && { [this.singlePriceType]: {
                                amount: value,
                                currency: this.placeholder.placeholderCurrency
                            } },
                    }
                })
            }
        },
        personPriceType: function (){
            if(this.price.placeholder.category === 'first'){
                return this.isNight ? 'firstDoubleCabinBuyPrice' : 'firstSingleCabinPremiumBuyPrice';
            }

            return this.isNight ? 'secondDoubleCabinBuyPrice' : 'secondSingleCabinPremiumBuyPrice';
        },
        singlePriceType: function () {
            if(this.price.placeholder.category === 'first'){
                return this.isNight ? 'firstSingleCabinPremiumBuyPrice' : 'firstSingleCabinPremiumBuyPrice';
            }
            return this.isNight ? 'secondSingleCabinPremiumBuyPrice' : 'secondSingleCabinPremiumBuyPrice';
        },

        hasSinglePrice: function () { return !this.supplementPrices || this.isNight },
        singlePrice: {
            get: function () {
                return this.price[this.singlePriceType].amount;
            },
            set: function (value) {
                if(this.isNight){
                    this.$store.commit('updatePrice', {
                        trainPrices: {
                            id: this.price.id,
                            [this.singlePriceType]: {
                                amount: value,
                                currency: this.placeholder.placeholderCurrency
                            }
                        }
                    })
                }
            }
        },
    }
}
</script>
