<template>
    <columns property="airlineRoutes" v-on:add="openForm" v-bind:loadOptions="false">
        <column-item
            v-bind:update="{updatedBy: item.updatedBy, updatedAt: item.updatedAt}"
            :api="'/api/airline_routes/' + item.id"
            slot-scope="{item}"
            v-on:edit="openForm(item)"
            v-on:delete="deleteItem('airlineRoutes', item)"
        >
            <dl>
                <dt>Flughafen Abflug</dt>
                <dd>
                    {{airportName(item.startAirport)}}
                    <a v-bind:href="`/airports/${item.startAirport.id}`" target="_blank">
                        <i class="fa fa-external-link-square"></i>
                    </a>
                </dd>

                <dt>Flughafen Ankunft</dt>
                <dd>
                    {{airportName(item.endAirport)}}
                    <a v-bind:href="`/airports/${item.endAirport.id}`" target="_blank">
                        <i class="fa fa-external-link-square"></i>
                    </a>
                </dd>
            </dl>
        </column-item>

        <flight-form ref="form" slot="form" />
    </columns>
</template>

<script>
import Mixin from '../tab';
import FlightForm from './Form';

export default {
    mixins: [Mixin],

    methods: {
        airportName: function (airport) {
            return airport && airport.name;
        },
    },

    components: { FlightForm },
}
</script>

