var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "users" }, [
    _c(
      "div",
      {
        staticClass: "user is-favicon is-small",
        staticStyle: { "z-index": "2" },
      },
      [
        _vm.order.user
          ? _c("user-image", { attrs: { user: _vm.order.user, favicon: true } })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "user is-favicon is-small",
        staticStyle: { "z-index": "1" },
      },
      [
        _c("div", {
          staticClass: "user__avatar",
          style: _vm.faviconStyle(_vm.order.organisationFilter),
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }