var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Fährverbindung  bearbeiten",
        createTitle: "Neue Fährverbindung hinzufügen",
      },
    },
    [
      _c("table", [
        _c("tr", [
          _c("th", [
            _c(
              "label",
              { staticClass: "label", attrs: { for: "startAirport" } },
              [_vm._v("Flughafen Abflug:")]
            ),
          ]),
          _vm._v(" "),
          _c("td", [
            _c(
              "div",
              { staticClass: "control" },
              [
                _c("async-select", {
                  attrs: {
                    api: "airports",
                    crudLinkTitle: "Fügen Sie einen neuen Flughafen hinzu",
                  },
                  model: {
                    value: _vm.form.startAirport,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "startAirport", $$v)
                    },
                    expression: "form.startAirport",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [
            _c(
              "label",
              { staticClass: "label", attrs: { for: "endAirport" } },
              [_vm._v("Flughafen Ankunft:")]
            ),
          ]),
          _vm._v(" "),
          _c("td", [
            _c(
              "div",
              { staticClass: "control" },
              [
                _c("async-select", {
                  attrs: {
                    api: "airports",
                    crudLinkTitle: "Fügen Sie einen neuen Flughafen hinzu",
                  },
                  model: {
                    value: _vm.form.endAirport,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "endAirport", $$v)
                    },
                    expression: "form.endAirport",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        !_vm.form.id
          ? _c("tr", [
              _c("th", [
                _c("label", { staticClass: "label" }, [_vm._v("Option:")]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("div", { staticClass: "control" }, [
                  _c("label", { staticClass: "checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.return,
                          expression: "form.return",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        value: true,
                        checked: Array.isArray(_vm.form.return)
                          ? _vm._i(_vm.form.return, true) > -1
                          : _vm.form.return,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.form.return,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = true,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(_vm.form, "return", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.form,
                                  "return",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.form, "return", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(
                      "Rückflug autmoatisch hinzufügen\n                    "
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }