<template>
    <div>

        <div class="row">
            <div class="cell is-full is-relative" v-on:click.prevent.stop="toggleCollapse">
                <div class="level-left">
                    <i
                        class="collapse-icon material-icons level-item"
                        v-bind:class="{'is-rotated' : !isOpen}"
                    >keyboard_arrow_up</i>
                    <span class="level-item">{{name}}</span>
                </div>
            </div>
            <div class="cell is-full is-visible-action is-relative">
                <div class="buttons">
                    <button class="button is-smaller is-120" v-if="!priceGroup.dataCheckedAt" @click="toggleChecked">
                        <i class="material-icons has-margin-right" style="color:#e68c87">cancel</i> zu überprüfen
                    </button>
                    <button class="button is-smaller is-120" @click="toggleChecked" v-else>
                        <i class="material-icons has-margin-right" style="color:#60A4AB">check_circle</i> überprüft
                    </button>

                    <dropdown>
                        <button class="button is-smaller" @click="loadTimePeriods">
                            <i class="material-icons">date_range</i> Genaue Zeiträume anzeigen
                        </button>

                        <ul slot="list">
                            <li v-for="period in timePeriods" v-bind:key="period.id">
                                <div class="level">
                                    {{period.startAt | fullYear}} - {{period.endAt | fullYear}}
                                    <days :value="daysToNumbers(period)" />
                                </div>
                            </li>
                        </ul>
                    </dropdown>



                </div>

            </div>

            <!--<div class="cell is-days-cell">
                <days :value="days" />
            </div>-->

            <div class="cell is-small-cell is-actions">
                <update-popover
                    v-bind:update="{updatedAt: priceGroup.updatedAt, updatedBy: priceGroup.updatedBy}"
                    :api="'/api/price_groups/' + priceGroup.id"
                >
                    <i class="material-icons">update</i>
                </update-popover>
                <button
                    class="btn-edit"
                    v-on:click="$emit('editPriceGroup', priceGroup)"
                ><i class="material-icons">edit</i></button>
                <button
                    class="btn-delete"
                    v-on:click="$emit('deletePriceGroup', priceGroup)"
                ><i class="material-icons">delete</i></button>
            </div>
        </div>



        <template v-if="isOpen">
            <loading v-if="isLoading" />
            <price-row
                v-else
                v-for="price in providerPrices"
                v-bind:key="price.id"
                v-bind:options="options"
                v-bind:price="price"
                v-bind:price-type="priceType"
                v-bind:isDay="isDay"
            />
        </template>



    </div>
</template>

<script>

import Loading from '@components/Loading';

import Dropdown from '@components/Dropdown';
import Days from '@components/Days';


import {currentUser, fullYear, daysToNumbers, time, compareDates} from '@utilities/functions'
import Mixins from './mixins'
import PriceRow from './Price';
import {providerPrices} from '@api';
import moment from "moment";
import {notifySuccess} from "@components/Notification";
import UpdatePopover from "@components/UpdatePopover";


export default {
    mixins: [Mixins],

    data: function () {
        return {
            isLoading: false,
        }
    },

    props: {
        priceGroup: {
            type: Object,
            required: true
        },

        options: {
            type: Object,
        },

        priceType: {
            type: String,
            required: true
        }
    },

    methods: {
        daysToNumbers,

        updatePriceGroup: function(data) {
            this.$emit('updatePriceGroup', data)
        },

        loadTimePeriods: function () {
            if(!this.priceGroup.timePeriods){
                this.isLoading = true;
                providerPrices[this.priceType + 'PriceGroups'].get(this.priceGroup.id).then(response => {{
                    this.updatePriceGroup(response.data)
                    this.isLoading = false;
                }})
            }
        },

        toggleChecked: function() {
            const userID = currentUser('id');
            const priceGroup = this.priceGroup;
            if (!this.priceGroup.dataCheckedAt && !!userID) {
                priceGroup.dataCheckedBy = `/api/users/${userID}`;
                priceGroup.dataCheckedAt = moment().format('DD.MM.YYYY');
            } else {
                priceGroup.dataCheckedBy = null;
                priceGroup.dataCheckedAt = null;
            }

            const data = {
                id: priceGroup.id,
                dataCheckedBy: priceGroup.dataCheckedBy,
                dataCheckedAt: priceGroup.dataCheckedAt
            }

            providerPrices[this.priceType + 'PriceGroups'].put({...data})
                .then(response => {
                    this.priceGroup.dataCheckedAt = priceGroup.dataCheckedAt;
                    this.updatePriceGroup(priceGroup);
                    notifySuccess('Die Preise wurden erfolgreich aktualisiert!')
                }, error => {
                    this.notifyError("Die Preise konnte nicht aktualisiert werden!")
                })
        }
    },
    mounted() {
        this.loadTimePeriods();
    },

    computed: {
        isDay: function () {
            return this.priceGroup && this.priceGroup.route && this.priceGroup.route.type === 'day'
        },

        timePeriods: function() {
            return this.priceGroup.timePeriods ? this.priceGroup.timePeriods.sort((a,b) => {
                return compareDates(fullYear(a.startAt), fullYear(b.startAt))
            }) : [];
        },

        name: function() {
            const addition = this.priceGroup.dayTimes && this.priceGroup.dayTimes.length > 0 ? ' (' + this.priceGroup.dayTimes.map(myTime => myTime.substring(0, 5) + ' Uhr').join(' | ') + ')' : ''
            return this.priceGroup.title + addition;
        },

        providerPrices: function() {
            return this.priceGroup.providerPrices ?
                this.priceGroup.providerPrices.sort((a,b) => {
                   return a.sortOrder - b.sortOrder
                }) : []
        }

       /* periods: function () {
            return flow(
                prices => prices.map(({startAt, endAt}) => ({startAt, endAt})),
                prices => uniqBy(prices, ({startAt, endAt}) => `${startAt}-${endAt}`)
            )(this.priceGroup.prices)
        },

        days: function () {
            return daysToNumbers(this.priceGroup.prices[0])
        },

        hasPrices: function () {
            return this.priceGroup.prices.length > 0
        },

        prices: function () {
            return uniqBy(this.priceGroup.prices, ({roomType}) => `${roomType.type}-${roomType.persons}`)
        },

        doublePrices: function () {
            return this.prices.filter(({roomType}) => roomType.persons === 2 && roomType.type !== 'standard')
        },

        singlePrices: function () {
            return this.prices.filter(({roomType}) => roomType.persons === 1 && roomType.type !== 'standard')
        },

        otherPrices: function () {
            return this.prices.filter(({roomType}) => roomType.type !== 'standard' && ![1,2].includes(roomType.persons))
        },

        doubleStandard: function () {
            return this.prices.find(({roomType}) => roomType.type === 'standard' && roomType.persons == 2)
        },

        singleStandard: function () {
            return this.prices.find(({roomType}) => roomType.type === 'standard' && roomType.persons == 1)
        }*/
    },

    components: {
        UpdatePopover,
        PriceRow,
        Dropdown,
        Days,
        Loading
    },

    filters: {
        fullYear,
    },
}
</script>
