<template>
    <row v-if="!hide">
        <template slot="type">
            <template v-if="persons > 2">
                {{persons}} x <i class="material-icons">directions_train</i>
            </template>
            <template v-else>
                <i class="material-icons" v-for="n in persons" v-bind:key="n">directions_train</i>
            </template>
        </template>
        <template slot="name">{{name}}</template>

        <template slot="marginTax">
            <margin-tax v-model="marginTaxEnabled" :tax="marginTax" :margin="grossMargin" />
        </template>

        <template slot="buyPriceCalculation" v-if="showExchangeRates">
            <input-price
                v-model="buyPriceInCalculationCurrency"
            />
        </template>
        <template slot="buyPrice">
            <input-price
                v-model="buyPrice"
                :is-blue="hasTwoCurrencies"
            />
        </template>

        <template slot="sellPriceCalculation" v-if="showExchangeRates">
            <input-price
                v-model="sellPriceInCalculationCurrency"
            />
        </template>
        <template slot="sellPrice">
            <input-price
                v-model="sellPrice"
                :is-blue="hasTwoCurrencies"
            />
        </template>

        <template slot="profitCalculation" v-if="showExchangeRates">
            <input-price
                v-model="profitInCalculationCurrency"
            />
        </template>
        <template slot="profit">
            <input-price
                v-model="profit"
                :is-blue="hasTwoCurrencies"
            />
        </template>

        <template slot="margin">
            <input-price v-model="margin" />
        </template>
    </row>
</template>

<script>
import price from './price' ;
import { contingentRoomTypes } from '@utilities/variables';

export default {
    mixins: [ price ],

    computed: {
        orderTrainCabinType: function () { return this.price.trainCabinType },

        hasNightTrains: function () {
            return this.$store.getters.nightTrainPrices.length > 0;
        },

        persons: function () { return this.orderTrainCabinType.persons },

        name: function () {
            const { persons } = this.orderTrainCabinType;
            let addition = this.hasNightTrains ? ` im ${persons === 2 ? 'Doppelabteil' : 'Einzelabteil'}` : '';

            return this.supplementPrices ? `Zuschlag 1.Klasse${addition}` :
                `1. Klasse${addition}`
        }
    },

    methods: {
        updatePrice: function (obj = {}) {
            this.$store.commit('updatePrice', {
                contingentPrices: {
                    id: this.price.id,
                    ...obj
                }
            })
        }
    }
}
</script>
