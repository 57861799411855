<template>
    <columns property="addresses" v-on:add="openForm">
        <column-item
            v-bind:update="{updatedBy: item.updatedBy, updatedAt: item.updatedAt}"
            slot-scope="{item}"
            :api="'/api/addresses/' + item.id"
            v-bind:label="label(item)"
            v-on:edit="openForm(item)"
            v-on:delete="deleteItem('addresses', item)"
        >
            <ul>
                <li v-if="item.title"><strong>{{item.title}}</strong></li>
                <li v-if="item.streetAddress">{{item.streetAddress}}</li>
                <li v-if="item.zip">{{item.zip}} {{item.city}}</li>
                <li v-if="item.country">{{country(item)}}</li>

                <li v-if="item.port">
                    <a v-bind:href="`/ports/${item.port.id}`" target="_blank">
                        <i class="material-icons">directions_boat</i>
                    {{item.port.place.name}}
                    </a>
                </li>

                <li v-if="item.airport">
                    <a v-bind:href="`/airport/${item.airport.id}`" target="_blank">
                        <i class="material-icons">airplanemode_active</i>
                        {{item.airport.name}}
                    </a>
                </li>
            </ul>

            <a v-bind:href="googleMapsLink(item)" target="_blank" slot="actions">
                <i class="material-icons">location_on</i>Maps
            </a>

        </column-item>

        <address-form ref="form" slot="form" />
    </columns>
</template>

<script>
import Mixin from '../tab';
import AddressForm from './Form';
export default {
    mixins: [ Mixin ],

    components: {AddressForm},

    computed: {
        types: function () {return this.options.addresses ? this.options.addresses.type : [] }
    },
    methods: {
        label: function (item) {
            const type = this.types.find(t => t.key === item.type)
            return !!type ? type.value : "";
        },
        openForm: function (data = {}) {
            this.$refs.form.open({
                title: this.item.name ? this.item.name : this.item.companyName,
                ...data
            });
        },
    },
    mounted() {
        this.label();

    }
}
</script>
