<template>
    <div class="is-light">
        <row>
            <template slot="type">
                <i class="material-icons">subdirectory_arrow_right</i>
            </template>
            <template slot="date">-</template>
            <template slot="nights">-</template>
            <template slot="name">Buspreis für die Fähre (inklusive Steuern)</template>
            <template slot="provider">-</template>
            <template slot="currency"><span class="is-currency">{{placeholder.placeholderCurrency}}</span></template>

            <template slot="sellPrice">
                <input-price
                    v-model="busBuyPrice"
                    :linked="hasCalculationRequest"
                    :value-in-order-currency="busBuyPriceInOrderCurrency"
                    :provider-prices="getProviderPrices(this.groupPriceType)"
                    :price-currency="currency"
                />
            </template>
            <template slot="profit">
                <input-price
                    :value="busPrice"
                    readOnly
                    :linked="hasCalculationRequest"
                    :highlight="!!highlight.person"
                    :value-in-order-currency="busPriceInOrderCurrency"
                    :price-currency="currency"
                />
            </template>
            <template slot="margin">
                <span class="empty" v-if="supplementPrices">-</span>
                <input-price
                    v-else
                    :value="busPrice"
                    :readOnly="true"
                    :linked="hasCalculationRequest"
                    :highlight="!!highlight.single"
                    :value-in-order-currency="busPriceInOrderCurrency"
                    :price-currency="currency"
                />
            </template>
        </row>
    </div>
</template>

<script>
import mixin from './mixin';
export default {
    mixins: [mixin],

    computed: {
        isNight: function () {
            return this.placeholder.type === 'night'
        },
        persons: function () {
            return this.$store.getters.persons
        },
        doublePersons: function () { return this.$store.getters.rooms.double },

        highlight: function () { return this.$store.state.highlight },

        busPrice: function () {
            return this.doublePersons ? this.busBuyPrice / this.doublePersons : 0;
        },

        currency: function() {
            return this.placeholder && this.placeholder.placeholderCurrency? this.placeholder.placeholderCurrency : null;
        },

        exchangeRate: function () {
            return this.$store.state.exchangeRates && this.$store.state.exchangeRates[this.currency] ? this.$store.state.exchangeRates[this.currency] : 0;
        },

        busPriceInOrderCurrency: function () { return this.busPrice * this.exchangeRate },
        busBuyPriceInOrderCurrency: function () { return this.busBuyPrice * this.exchangeRate },


        // personPriceInOrderCurrency: function () { return this.price._summary.person },
        // groupPriceInOrderCurrency: function () { return this.price._summary.group },
        // singlePriceInOrderCurrency: function () { return this.price._summary.single },

        busBuyPrice: {
            get: function () { return this.price.busBuyPrice.amount },
            set: function (value) { this.$store.commit('updatePrice', {
                ferryPrices: {
                    id: this.price.id,
                    busBuyPrice: {
                        amount: value,
                        currency: this.placeholder.placeholderCurrency
                    }
                }
            })}
        },

        groupPriceType: function () {
            return 'busBuyPrice';
        },

    }
}
</script>

