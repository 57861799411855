<template>
    <div class="rating">
        <div class="rating__circle">
            <div class="rating__value" :style="{'line-height': `${size}px`}">{{ rating > 0 ? rating : '-' }}</div>
            <svg
                v-bind:width="size"
                v-bind:height="size"
                v-bind:viewBox="`0 0 ${size} ${size}`"
            >
                <circle
                    class="circle__meter"
                    v-bind:cx="halfSize"
                    v-bind:cy="halfSize"
                    v-bind:r="radius"
                    v-bind:stroke-width="weight"
                />
                <circle
                    v-bind:style="{strokeDashoffset: progress, strokeDasharray: circumference}"
                    class="circle__value"
                    v-bind:cx="halfSize"
                    v-bind:cy="halfSize"
                    v-bind:r="radius"
                    v-bind:stroke-width="weight"
                />
            </svg>
        </div>

        <slot />
    </div>
</template>


<script>
// https://codepen.io/xgad/post/svg-radial-progress-meters
export default {
    props: {
        rating: { type: Number, default: 0, },
        radius: { type: Number, default: 26 },
        weight: { type: Number, default: 2 },
        totalOptions: {type: Number, default: 10}
    },


    computed: {
        progress: function () {
           const progress = (this.rating > 0 ? this.rating : 0) / this.totalOptions;
           const dashOffset = this.circumference * (1 - progress);
           return dashOffset;
        },


        circumference: function () { return 2 * Math.PI * this.radius; },
        size:          function () { return this.radius * 2 + this.weight * 2; },
        halfSize:      function () { return this.size / 2; }
    }
}
</script>
