<template>
    <multiselect
        v-model             = "trainSelect"
        v-bind:options      = "items"
        v-bind:loading      = "isLoadingItems"
        v-bind:allow-empty  = "false"
        v-bind:custom-label = "customLabel"

        placeholder  = "Zuggesellschaft"
        track-by     = "id"
        group-values = "items"
        group-label  = "type"
        select-label = ""
    >
        <template slot="noResult">Kein Ergebnis</template>
        <span slot="caret">
            <a
                class  = "multiselect-crud-link"
                href   = "/trains?add"
                title  = "Fügen Sie eine neue Gesellschaft hinzu"
                target = "_blank"
            ><i class="material-icons">add</i></a>
        </span>
    </multiselect>
</template>



<script>
import has                    from 'lodash/has';
import { Multiselect }        from '@components/form';
import {get, getAxios} from '@api';
import { time }               from '@utilities/functions';
import { notifyError }        from '@components/Notification';
import mixins from "@orders/placeholders/components/forms/mixins";
import axios from "axios";


const emptyItem = { id: -1, name: 'No-Name' };


export default {
    mixins: [mixins],
    props: {
        value: {},
        placeholderInfo: { type: Object, required: true },
        nullable:        { type: Boolean, default: true },
        // When new list select the first
        autoSelect:      { type: Boolean, default: true }
    },


    computed: {
        trainSelect: {
            get: function () {
                return this.value ? JSON.parse(JSON.stringify(this.value)) : emptyItem;
            },
            set: function (value) {
                this.$emit('input', value.id !== -1 ? value : null);
            }
        }
    },


    data: function () {
        return {
            items: [],
            isLoadingItems: false
        }
    },


    methods: {
        customLabel: function (item) {
            return item.name;
        },


        getList: function () {
            if (this.placeholderInfo.startTrainStation && this.placeholderInfo.endTrainStation && !this.isLoadingItems) {
                this.isLoadingItems = true;

                getAxios('trains', {
                        'trainRoutes.startTrainStation.id': this.placeholderInfo.startTrainStation.id,
                        'trainRoutes.endTrainStation.id': this.placeholderInfo.endTrainStation.id,
                    }, {cancelToken: this.cancelSource.token}).then(response => {
                        this.items[1].items = response.data;

                        if (!has(this.trainSelect, 'id')) {
                            if (this.nullable) {
                                this.trainSelect = this.items[0].items[0];

                            } else if (this.items[1].items.length > 0) {
                                if (this.autoSelect) {
                                    this.trainSelect = this.items[1].items[0];

                                } else {
                                    this.trainSelect = emptyItem;
                                }
                            }
                        }

                    }, error => {
                        if(!axios.isCancel(error)) {
                            notifyError('Die Züge konnten nicht geladen werden! Server error!');
                        }
                })
                    .then(() => { this.isLoadingItems = false; });
            }
        }
    },


    created: function () {
        this.getList();

        if (this.nullable) {
            this.items = [
                {
                    type: 'Keine Zuggesellschaft',
                    items: [emptyItem]
                },
                {
                    type:  'Zuggesellschaften',
                    items: []
                }
            ];
        } else {
            this.items = [
                {},
                {
                    type:  'Zuggesellschaften',
                    items: []
                }
            ];
        }
    },


    watch: {
        'placeholderInfo.startTrainStation': function () { this.getList(); },
        'placeholderInfo.endTrainStation':   function () { this.getList(); },
    },


    components: {
        Multiselect
    }
}
</script>
