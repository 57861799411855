<template>
    <div class="select">
        <select v-model="localHotelType">
            <template v-for="type in hotelTypes">
                <option v-bind:value="type">{{ type.stars + '* ' + type.category }}</option>
            </template>
        </select>
    </div>
</template>



<script>
import axios from 'axios';


export default {
    props: {
        value: { required: true }
    },


    computed: {
        localHotelType: {
            get: function () { return this.value; },
            set: function (type) { this.$emit('input', type); }
        }
    },


    data: function () {
        return  {
            hotelTypes: []
        }
    },


    methods: {
        initialValue: function () {
            if (this.value === null) {
                this.localHotelType = this.hotelTypes[2];
            }
        }
    },


    created: function () {
        let options = JSON.parse(localStorage.getItem('options'));

        if (options && options.hotel && options.hotel.hotelTypes) {
            this.hotelTypes = options.hotel.hotelTypes;
            this.initialValue();

        } else {
            axios.options('/api/hotels').then(response => {
                if (!options)       { options       = {}; }
                if (!options.hotel) { options.hotel = {}; }

                options.hotel.hotelTypes = response.data.hotelTypes.map(type => ({
                        category: type.category,
                        stars:    type.stars
                    }));

                this.hotelTypes = options.hotel.hotelTypes;
                localStorage.setItem('options', JSON.stringify(options));
                this.initialValue();
            });
        }
    }
}
</script>
