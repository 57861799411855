<template>
    <modal-form
        editTitle="Preise für Zusatzleistung bearbeiten"
        createTitle="Preise für Zusatzleistung hinzufügen"
    >
        <table>
            <template v-if="initialValue">
                <tr>
                    <th><label class="label">Preis:</label></th>
                    <td>
                        <toggle-switch v-model="priceType">
                            <option value="calculation">Kalkulation</option>
                            <option value="provider">Anbieter</option>
                            <option value="agency">Agentur</option>
                        </toggle-switch>
                    </td>
                </tr>
                <tr v-if="priceType === 'agency'">
                    <th><label class="label">Agentur:</label></th>
                    <td>
                        <async-select
                            api="agencies"
                            v-model="agency"
                        />
                    </td>
                </tr>
                <tr v-if="priceType === 'provider' || priceType === 'agency'">
                    <th><label class="label">Leistungsträger:</label></th>
                    <td>
                        <async-select
                            api="providers"
                            v-model="provider"
                        />
                    </td>
                </tr>
            </template>
            <tr>
                <th><label class="label">Bezeichnung:</label></th>
                <td>
                    <toggle-switch v-model="ownName">
                        <option :value="true">Individuell</option>
                        <option :value="false">Automatisch (nach Datum)</option>
                    </toggle-switch>
                </td>
            </tr>
            <tr v-if="ownName">
                <th><label class="label">Eigene Bezeichnung:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="name" class="input" v-model="form.name">
                    </div>
                </td>
            </tr>
            <tr>
                <th class="is-top">
                    <label class="label">
                        Zeitraum:
                        <i class="material-icons is-pointer" v-on:click="addPeriod" v-if="canAddPeriod">add_circle</i>
                    </label>
                </th>

                <td>
                    <!-- Time periods -->
                    <div class="level-left" v-for="period in timePeriods" v-bind:key="period.key">
                        <div class="flatpickr-combined level-item">
                            <input-date v-model="period.startAt" class="is-small"  />
                            <span>-</span>
                            <input-date v-model="period.endAt" class="is-small"  />
                        </div>
                        <div class="level-item">
                            <days v-model="period.days" picker/>
                        </div>
                        <i
                            v-if="timePeriods.length > 1"
                            class="material-icons is-pointer"
                            v-on:click="deletePeriod(period)"
                        >delete</i>
                    </div>
                </td>
            </tr>

            <service-day-time-field label="Gültig für" :fields="['startTimeOfDay', 'endTimeOfDay', 'checkInTimeOfDay']" v-model="form.serviceDayTimes" :predefined-day-times="predefinedDayTimes" />


            <!-- Preise -->
            <tr>
                <td colspan="2">
                    <h2>Preise</h2>
                </td>
            </tr>

            <tr>
                <th><label class="label">Überprüft:</label></th>
                <td>
                    <import-checked-field v-model="importChecked" />
                </td>
            </tr>




            <tr>
                <th><label class="label">Einkaufspreis:</label></th>
                <td>
                    <div class="level-left">
                        <multiselect
                            v-model="buyCurrency"
                            style="width: 70px"
                            :options="currencies"
                            :allow-empty="false"
                            placeholder=""
                            class="level-item"
                        />
                        <input-price v-model="buyPrice" class="is-medium level-item" placeholder="0,00" :prefix="buyCurrency"/>
                    </div>

                </td>
            </tr>
            <tr>
                <th><label class="label">Verkaufspreis:</label></th>
                <td>
                    <div class="level-left">
                        <multiselect
                            v-model="sellCurrency"
                            style="width: 70px"
                            :options="currencies"
                            :allow-empty="false"
                            placeholder=""
                            class="level-item"
                        />

                    <input-price v-model="sellPrice" class="is-medium level-item" placeholder="0,00" :prefix="sellCurrency"/>
                    </div>
                </td>
            </tr>

            <tr v-if="existingPrices.length > 0">
                <td class="alert is-danger"></td>
                <td>
                    <div class="alert is-danger" >
                        <p>
                            <strong>Preise überschreiben?</strong>
                        </p>
                        <p>
                            Im angegebenen Preise existieren bereits folgende Preise, die beim erneuten Klick auf Speichern überschrieben werden:
                        </p>
                        <ul>
                            <li v-for="price in existingPrices">{{ price.timePeriods.map(item => item.startAt + ' - ' + item.endAt).join(' | ') }} - EK-Preis: {{ price.providerPrices.filter(item => item.priceType === 'buy_price').map(item => item.price.formatted).join(' | ') }}</li>
                        </ul>
                    </div>
                </td>
            </tr>

        </table>
    </modal-form>
</template>

<script>
import has from 'lodash/has';
import Days from '@components/Days';
import { InputDate, DateTime, InputPrice, ToggleSwitch, AsyncSelect } from '@components/form';
import {dateISOtoView, numbersToDays} from '@utilities/functions';
import TabForm from '../../form'
import { importCheckedMixin, importCheckedField, serviceDayTimeField, serviceDayTimeMixin } from '@utilities/mixins';
import {
    addOtherService,
    getOtherPlaceholder,
    getOtherServices,
    providerPrices,
    getOtherPriceGroups,
} from '@api'
import {notifySuccess} from "@components/Notification";
import moment from "moment";



export default {
    mixins: [ TabForm, importCheckedMixin, serviceDayTimeMixin ],

    props: {
        providerType: {
            type: String,
            required: true
        }
    },

    components: {
        Days,
        InputDate,
        InputPrice,
        DateTime,
        ToggleSwitch,
        importCheckedField,
        serviceDayTimeField,
        AsyncSelect
    },

    form: {
        otherService: null,
        name: '',
        serviceDayTimes: [],
        updatedAt: dateISOtoView((new Date()).toISOString()),

        providerPrices: [],
        timePeriods: [],
    },

    data: function () {
        return {
            ownName: false,
            buyCurrency: this.$store.state.data.mainCurrency ? this.$store.state.data.mainCurrency : 'EUR',
            sellCurrency: this.$store.state.data.mainCurrency ? this.$store.state.data.mainCurrency : 'EUR',
            priceType: 'calculation',
            existingPrices: [],
        }
    },

    computed: {

        predefinedDayTimes: function () {
            return this.form.otherService && this.form.otherService && this.form.otherService.serviceDayTimes ? this.form.otherService.serviceDayTimes.map(detail => {
                detail.key = detail.id;
                delete detail.id;
                return detail;
            }) : [];
        },

        currencies: function () {
            return this.options.potentialCurrencies ? this.options.potentialCurrencies : [];
        },

        timePeriods: function () {
            this.afterDataLoad();
            this.setCurrency();

            return this.form.timePeriods ? this.form.timePeriods : []
        },

        dataCheckedAtCheck: function() {
            if(this.form.otherService){
                this.afterDataLoad();
                return this.form.dataCheckedAt ? moment(this.form.dataCheckedAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') : null;
            }
            return null
        },

        canAddPeriod: function () {
            return this.timePeriods
                .filter(({startAt, endAt}) => !startAt || !endAt)
                .length === 0
        },

        agency: {
            get: function () { return this.form.otherService.agency && !!this.form.otherService.agency.id ? this.form.otherService.agency : null },
            set: function (agency) { this.form.otherService.agency = agency }
        },

        provider: {
            get: function () { return this.form.otherService.provider && !!this.form.otherService.provider.id ? this.form.otherService.provider : null },
            set: function (provider) { this.form.otherService.provider = provider }
        },


        // Prices
        prices: function () { return has(this.form, 'providerPrices') ? this.form.providerPrices : [] },

        buy: function () { return findPriceTypePrice(this.prices, 'buy_price') },
        buyPrice: {
            get: function () { return this.buy ? this.buy.price.amount : null },
            set: function (price) { this.updatePrice({priceType: 'buy_price', price}) }
        },

        sell: function () { return findPriceTypePrice(this.prices, 'sell_price') },
        sellPrice: {
            get: function () { return this.sell ? this.sell.price.amount : null },
            set: function (price) { this.updatePrice({priceType: 'sell_price', price}) }
        },



    },

    methods: {
        openCallback: function(form) {
            if(form && form.id && !form.name){
                this.ownName = false;
            }
        },

        handleInitialValue: function(initialValue) {

            this.open();

            if(this.initialValue.sellingPrice){
                this.form.name = this.initialValue.sellingPrice.subTitle;
                if(!this.form.name){
                    this.ownName = false;
                }
                this.form.timePeriods = this.initialValue.sellingPrice.seasonTimes.map(season => {
                    return {
                        days: [0,1,2,3,4,5,6],
                        key: season.id,
                        startAt: season.startAt,
                        endAt: season.endAt
                    }
                })
            } else {
                this.form.timePeriods = [{
                    days: [0,1,2,3,4,5,6],
                    startAt: null,
                    endAt: null,
                    key: 1
                }]
            }

            if(this.initialValue.price){

                getOtherPlaceholder(initialValue.price.placeholder.id, '?_groups[]=modal_read&_groups[]=order_placeholder_displayed_request_read').then(response => {
                    const {otherServiceType, displayedRequest} = response.data;
                    let otherService = {}
                    if(displayedRequest && displayedRequest.otherService){
                        otherService = displayedRequest.otherService;
                    } else {
                        otherService = {
                            provider: null,
                            agency: null,
                            otherServiceType: otherServiceType,
                        }
                    }
                    this.buyPrice = initialValue.price.buyPrice.amount;
                    this.buyCurrency = initialValue.price.buyPrice.currency;
                    this.sellPrice = initialValue.price.sellPrice.amount;
                    this.sellCurrency = initialValue.price.sellPrice.currency;


                    this.form.otherService = otherService
                    if(otherService.agency){
                        this.priceType = 'agency';
                    } else if(otherService.provider){
                        this.priceType = 'provider';
                    }
                })
            }
        },

        setCurrency: function() {
            if(this.buyPrice && this.buy.price.currency){
                this.buyCurrency = this.buy.price.currency;
            }
            if(this.sellPrice && this.sell.price.currency){
                this.sellCurrency = this.sell.price.currency;
            }
        },

        addPeriod: function () {
            this.form.timePeriods.push({
                startAt: null,
                endAt: null,
                days: [0,1,2,3,4,5,6],
                key: + new Date
            })
        },



        deletePeriod: function (period) {
            this.form.timePeriods = this.form.timePeriods
                .filter(({key}) => period.key !== key)
        },



        updatePrice: function ({price, priceType}) {
            const priceIndex = this.form.providerPrices.findIndex(price => priceType === price.priceType)
            if (priceIndex === -1) {
                this.form.providerPrices.push({
                    price: {amount: price},
                    priceType: priceType,
                    key: +new Date
                })
            } else {
                this.form.providerPrices.splice(priceIndex, 1, {
                    ...this.form.providerPrices[priceIndex],
                    price: {amount: price},
                })
            }
        },

        getIDFromString: function(apiString) {
            if(typeof apiString === 'object'){
                return apiString.id;
            }
            let stringArray = apiString.split('/');
            return stringArray[stringArray.length - 1];
        },

        checkOverlappingPrices: function () {
            let params = {};
            if(this.existingPrices.length > 0){
                this.addPriceGroup();
            } else {
                let periods = [];
                this.form.timePeriods.forEach(period => {
                    periods.push(period, {...period, startAt: null})
                });
                Promise.all(periods.map(period => {
                    params = {
                        'otherService.id': this.getIDFromString(this.form.otherService),
                        'timePeriods.startAt[before]': period.endAt,
                        'timePeriods.endAt[after]': period.startAt,
                        'timePeriods.monday': period.monday.toString(),
                        'timePeriods.tuesday': period.tuesday.toString(),
                        'timePeriods.wednesday': period.wednesday.toString(),
                        'timePeriods.thursday': period.thursday.toString(),
                        'timePeriods.friday': period.friday.toString(),
                        'timePeriods.saturday ': period.saturday.toString() ,
                        'timePeriods.sunday': period.sunday.toString(),
                        ...!period.startAt && {'timePeriods.endAt[exists]': 'false'},
                        '_groups': ['provider_price_group:provider_price', 'provider_price', 'provider_price_group:price_time_period', 'price_time_period']
                    };

                    return getOtherPriceGroups(params)
                })).then(responses => {
                    this.existingPrices = [].concat(...(responses.map(response => response.data)));
                    if(this.existingPrices.length === 0){
                        this.addPriceGroup();
                    }
                })
            }
        },

        deletePriceGroups: function (groups) {
            groups.map(group => providerPrices.otherPriceGroups.delete(group.id).then(response => {}, error => {
                this.notifyError("Die bestehenden Preise konnten nicht überschrieben werden. Doppelte Preise bitte manuell ändern!")
            }))
        },

        addPriceGroup: function () {
            providerPrices.otherPriceGroups.post({
                ...this.form,
                ...{otherService: this.form.otherService.id ? `/api/other_services/${this.form.otherService.id}` : this.form.otherService},
            })
                .then(response => {
                    notifySuccess('Die Preise wurden erfolgreich hinzugefügt!')
                    this.deletePriceGroups(this.existingPrices);
                    this.$emit('submit')
                    this.isLoading = false;
                    this.visible = false;

                }, error => {
                    this.notifyError("Die Preise konnte nicht hinzugefügt werden!")
                    this.isLoading = false;
                })
        },

        handleSubmit: function () {

            this.isLoading = true;
            this.form.timePeriods = this.form.timePeriods.map(period => { return {...period, ...numbersToDays(period.days)}})
            this.form.dataCheckedAt = this.dataCheckedAtCheck;
            this.form.name = this.ownName ? this.form.name : '';


            this.form.providerPrices = this.form.providerPrices.map(price => {
                return Object.assign({}, price, {
                    price: {
                        amount: price.price.amount,
                        currency: price.priceType === 'buy_price' ? this.buyCurrency : this.sellCurrency,
                    }
                })
            });

            if(this.form.id){
                providerPrices.otherPriceGroups.put(
                    {
                        ...this.form,
                        ...{otherService: this.form.otherService.id ? `/api/other_services/${this.form.otherService.id}` : this.form.otherService},
                })
                    .then(response => {
                        notifySuccess('Die Preise wurden erfolgreich aktualisiert!')
                        this.isLoading = false;
                        this.$emit('submit')
                        this.visible = false;

                    }, error => {
                        this.notifyError("Die Preise konnte nicht aktualisiert werden!")
                        this.isLoading = false;
                    })
            } else if(this.initialValue && this.form.otherService.otherServiceType) {

                if(this.priceType === 'calculation'){
                    this.form.otherService.provider = null;
                    this.form.otherService.agency = null;
                }
                if(this.priceType === 'provider'){
                    this.form.otherService.agency = null;
                }

                //try to find otherService
                getOtherServices('', {
                    params: {
                        'otherServiceType.id': this.form.otherService.otherServiceType.id,
                        ...this.form.otherService.agency && {'agency.id': this.form.otherService.agency.id},
                        ...this.form.otherService.provider && {'provider.id': this.form.otherService.provider.id},
                        ...!this.form.otherService.agency && {'agency[exists]': false},
                        ...!this.form.otherService.provider && {'provider[exists]': false},
                    }
                }).then(response => {
                    let otherServiceId = response.data.length > 0 ? response.data[0].id : null;
                    if(otherServiceId){
                        this.form.otherService.id = otherServiceId;
                        this.checkOverlappingPrices();
                    } else {
                        this.form.otherService.otherServiceType = '/api/other_service_types/' + this.form.otherService.otherServiceType.id;
                        this.form.otherService.agency = this.form.otherService.agency ? '/api/agencies/' + this.form.otherService.agency.id : null;
                        this.form.otherService.provider = this.form.otherService.provider ? '/api/providers/' + this.form.otherService.provider.id : null;
                        addOtherService(this.form.otherService).then(response => {
                            this.form.otherService = `/api/other_services/${response.data.id}`;
                            this.addPriceGroup();
                        })
                    }
                });
            } else {
                this.addPriceGroup();
            }
        }
    },
}

const findPriceTypePrice = function (prices, priceType) {
    return prices.find(price => price.priceType === priceType)
}

</script>
