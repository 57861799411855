<template>
    <columns property="bankAccounts" v-on:add="openForm" v-bind:loadOptions="false">
        <column-item
            v-bind:update="{updatedBy: item.updatedBy, updatedAt: item.updatedAt}"
            :api="'/api/bank_accounts/' + item.id"
            slot-scope="{item}"
            v-bind:label="label(item)"
            v-on:edit="openForm(item)"
            v-on:delete="deleteItem('bankAccounts', item)"
        >
            <ul>
                <li><strong>{{item.iban}}</strong></li>
                <li><em>{{item.bic}}</em></li>
                <li v-if="item.bank">
                    <i class="material-icons">account_balance</i>{{item.bank}}
                </li>
                <li v-if="!!item.accountHolder" class="mb">
                    <i class="material-icons">person</i>{{item.accountHolder}}
                </li>
                <li v-if="item.notes !== ''">
                    <hr class="has-margin-top">
                    <p v-html="item.notes"></p>
                </li>
            </ul>
        </column-item>

        <bank-account-form ref="form" slot="form" />
    </columns>
</template>

<script>
import Mixin from '../tab';
import BankAccountForm from './Form';

export default {
    mixins: [ Mixin ],

    methods: {
        label: function ({primaryAccount}) {
            return primaryAccount ? 'Primärkonto' : ''
        }
    },

    components: {
        BankAccountForm,
    }
}
</script>
