<template>
    <div
        class="bp-panel"
        :class="{'is-hover': isHover && !isForm, 'is-form': isForm}"

        v-on:mouseenter="isHover = true"
        v-on:mouseleave="isHover = false"
        v-on:click="isHover = false"
    >
        <span class="panel-label">
            <slot />
        </span>

        <div class="panel-content">
            <div class="field">
                <multiselect
                    v-bind:options="templates"
                    v-model="form.info"
                    label="title"
                    track-by="id"
                    placeholder="Vorlage asuwählen"
                />
            </div>

            <template v-if="value.info">
                <hr>
                <h2>{{value.title}}</h2>
                <div class="columns is-multiline">
                    <div class="column is-one-quarter" v-for="image in value.images" :key="image.id">
                        <a :href="image.publicPath" :title="image.title" target="_blank">
                            <img :src="image.publicPath" :title="image.title">
                        </a>
                    </div>
                </div>
                <div v-html="value.text" class="nl2br"></div>
            </template>
        </div>

        <div class="panel-actions">
            <button v-if="value.info" @click.prevent="$emit('edit')">
                <i class="material-icons">edit</i>
                Hinweis bearbeiten
            </button>

            <button v-on:click.prevent="$emit('delete')">
                <i class="material-icons">delete</i>
                <template v-if="!isForm">Delete</template>
                <template v-else>Discard</template>
            </button>
        </div>
    </div>
</template>

<script>
import tripDetail from '../tripDetail'
import { Multiselect, ImageUpload, ImageSelect } from '@components/form'

export default {
    mixins: [ tripDetail ],

    data: function () {
        return {
            isHover: false,
        }
    },

    components: {
        Multiselect, ImageUpload, ImageSelect
    },

    methods: {
        openImageSelect: function () {
            this.$refs.imageSelect.open();
        },
    }
}
</script>
