var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "route-form",
    {
      attrs: {
        isConcept: true,
        limitedDestinations: _vm.options.dayDestinations,
        isTaggable: false,
      },
      model: {
        value: _vm.copyPlaceholder,
        callback: function ($$v) {
          _vm.copyPlaceholder = $$v
        },
        expression: "copyPlaceholder",
      },
    },
    [
      _c(
        "button",
        {
          staticClass: "button is-close",
          attrs: { title: "Verwerfen" },
          on: {
            click: function ($event) {
              return _vm.$emit("cancel")
            },
          },
        },
        [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-save is-primary",
          class: { "is-loading": _vm.isLoading },
          attrs: { title: "Speichern" },
          on: { click: _vm.save },
        },
        [_c("i", { staticClass: "material-icons" }, [_vm._v("check")])]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }