var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "crud-table",
    {
      ref: "table",
      attrs: {
        api: "order_concepts",
        url: "/concepts/orders",
        storeKey: `concepts/days//${_vm.item.id}/orderConcepts`,
        filter: { "days.id": _vm.item.id },
        label: "Stammdaten",
        name: { singular: "reisekonzept", plural: "reisekonzepten" },
        deleteRow: false,
        columns: [
          {
            key: "name",
            label: "Name",
            searchable: true,
            class: "is-one-fifth",
          },
          { key: "category", filter: "category" },
          { key: "destinations", filter: "destinations.id" },
          { key: "tags", sortable: false, filter: "tags" },
          { key: "days.length", sortable: false },
          { key: "dataCheckedAt", filter: "checkedAt" },
        ],
      },
    },
    [
      _c("h2", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Reise-Gesamtkonzepte"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }