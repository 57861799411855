<template>
    <div class="bp-row-form">
        <div>
            <div class="bp-row-form__title">Flugstrecke</div>

            <div>
                <i class="material-icons" title="Flug">airplanemode_active</i>
                <async-select
                    ref                 = "startAirport"
                    class               = "has-margin-right"
                    v-model             = "copyPlaceholder.startAirport"
                    api                 = "airports"
                    v-bind:custom-label = "airport => airport.name + ' - ' + airport.place.name"
                    crudLinkTitle       = "Fügen Sie einen neuen Flughafen hinzu"
                    v-bind:clearable    = "false"
                    v-bind:allow-empty  = "false"
                    placeholder         = "Flughafen finden"
                ></async-select>

                <async-select
                    class               = "has-margin-right"
                    v-model             = "copyPlaceholder.endAirport"
                    api                 = "airports"
                    v-bind:custom-label = "airport => airport.name + ' - ' + airport.place.name"
                    crudLinkTitle       = "Fügen Sie einen neuen Flughafen hinzu"
                    v-bind:clearable    = "false"
                    v-bind:allow-empty  = "false"
                    placeholder         = "Flughafen finden"
                ></async-select>
            </div>
        </div>


        <div class="is-160 has-time">
            <div class="bp-row-form__title">Zeitangaben</div>

            <div>
                <i title="Uhrzeit" class="material-icons">access_time</i>
                <date-time v-model="copyPlaceholder.startTime" time-only></date-time> -
                <date-time v-model="copyPlaceholder.endTime" time-only></date-time>
            </div>
        </div>


        <div>
            <div class="bp-row-form__title">Abrechnung</div>

            <div>
                <i title="Set as Additional" class="material-icons">shopping_cart</i>
                <div class="select">
                    <select v-model="copyPlaceholder.clientOffer.status">
                        <option value="included">inkludiert</option>
                        <option value="optional">optional</option>
                        <option value="additional">gesondert</option>
                    </select>
                </div>
            </div>
        </div>


        <button
            class="button is-close"
            v-on:click="$emit('cancel')"
            title="Verwerfen"
        ><i class="material-icons">close</i></button>

        <button
            class        = "button is-save is-primary"
            v-bind:class = "{ 'is-loading': isLoading }"
            v-on:click   = "save"
            title        = "Speichern"
        ><i class="material-icons">check</i></button>
    </div>
</template>



<script>
import AsyncSelect from '@components/form/AsyncSelect';
import mixins from './mixins';


export default {
    mixins: [mixins],


    data: function () {
        return {
            copyPlaceholder: {
                startAirport: null,
                endAirport: null,
                clientOffer: {
                    status: 'included',
                    priceType: 'per_person',
                    price: '0.00'
                },
                startTime: '00:00',
                endTime:   '00:00',
            },
        }
    },


    methods: {
        onKeyUp: function (event) {
            if (event.keyCode === 13 &&
                this.placeholder === undefined    &&
                this.copyPlaceholder.startAirport &&
                this.copyPlaceholder.endAirport
                ) {
                this.$nextTick(() => {
                    if (this.placeholderStringify === JSON.stringify(this.copyPlaceholder)) {
                        this.save();
                    }
                });
            }
        }
    },


    mounted: function () {
        if (!this.placeholder) {
            this.$refs.startAirport.$el.querySelector('input').focus();
        }
    },


    components: { AsyncSelect }
}
</script>
