var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm._v(
        "\n    " +
          _vm._s(_vm.row.startAirport && _vm.row.startAirport.place.name) +
          "\n    "
      ),
      _vm.row.endAirport && _vm.row.endAirport ? [_vm._v("-")] : _vm._e(),
      _vm._v(
        "\n    " +
          _vm._s(_vm.row.endAirport && _vm.row.endAirport.place.name) +
          "\n"
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }