<template>
    <div>
        <year-tab-filter
            v-model="filters"
            v-bind:filter-start-end-keys="filterStartEndKeys"
        />

        <div class="redesign-table">
            <div class="row is-header is-space-between">
                <h2>{{ headline }}</h2>
                <button
                    class="btn-edit"
                    v-on:click="$emit('addPriceGroup')"
                ><i class="material-icons is-pointer" v-on:click="handleFormByType({})">add_circle</i></button>
            </div>
            <table-filters
                class="row is-filters"
                v-model="filters"
                v-bind:options="options"
                v-on:input="getPrices()"
            />

            <loading v-if="isLoading" />

            <template v-else>

                <level-row
                    v-if="levels[0] === 'level'"
                    v-for="level in groupedRows"
                    v-bind:key="level.key"
                    v-bind:level="level"
                    v-bind:levels="allLevels"
                    v-bind:options="options"
                    v-bind:show-next-row="showNextRow"

                    v-on:editRoute="route => $emit('openRouteForm', route.route)"
                    v-on:deleteRoute="route => deleteRoute(route.route)"

                    v-on:editTrainRoute="trainRoute => $emit('openTrainRouteForm', trainRoute.route)"
                    v-on:deleteTrainRoute="trainRoute => deleteTrainRoute(trainRoute.route)"

                    v-on:editOtherService="otherService => $emit('openOtherServiceForm', otherService.otherService)"
                    v-on:deleteOtherService="otherService => $emit('deleteOtherService', otherService.otherService)"

                    v-on:addPriceGroup="addPriceGroup"
                    v-on:editPriceGroup="editPriceGroup"
                    v-on:deletePriceGroup="deletePriceGroup"
                    v-on:updateLevel="updateRow"
                />

            </template>
            <div v-if="!isLoading" class="redesign-table__footer">
            </div>
        </div>
    </div>
</template>

<script>

    import moment from 'moment';

    import {setStorageFilter, getStorageFilter, daysToNumbers} from '@utilities/functions';
    import Loading from '@components/Loading';
    import {
        getGroupedHotelProviderPrices,
        getGroupedFerryRoutes,
        getGroupedTrainRoutes,
        getGroupedOtherServices,
        providerPrices,
        deleteFerryRoute,
        deleteTrainRoute,
        deleteOtherService
    } from '@api';

    import Mixin from '../tab';
    import TableFilters from '@components/CRUD/Table/Filters';
    import LevelRow from './Rows';

    import omit from "lodash/omit";
    import pickBy from "lodash/pickBy";
    import has from "lodash/has";
    import YearTabFilter from "@components/CRUD/Table/YearTabFilter";


    export default {
        mixins: [ Mixin ],

        components: {YearTabFilter, TableFilters, LevelRow, Loading },

        props: {
            headline: {
                type: String,
                required: true
            },
            levels: {
                type: Array,
                required: true
            },
            priceType: {
                type: String,
                required: true
            },
            providerType: {
                type: String,
                required: true
            },
            showFilters: {
                type: Object,
                required: true
            },
            filterStartEndKeys: {
                type: Array,
                required: true
            }
        },

        data: function () {
            return {
                isLoading: false,
                showNextRow: false,
                filters: {},
            }
        },

        mounted: function () {

            this.showFilters['_search'] = {
                value: '',
                key: '_search'
            };

            this.filters = {
                ...this.showFilters,
                //...getStorageFilter(`${this.api}/${this.priceType}ProviderPrices`)
            }
        },

        activated() {
            this.getPrices();
        },

        computed: {
            allLevels: function() {
                return {
                    key: 1,
                    levels: this.levels
                }
            },

            groupedRows: function () {
                return this.item[this.priceType + 'ProviderPrices'];
            },

            filterParams: function () {

                const oneYearBefore = moment().subtract(1, 'year').format('DD.MM.YYYY');

                // Special cases - checkedAt, _search
                let filters = Object.assign({}, omit(this.filters, ['checkedAt', '_search', 'days', 'months', 'ports']), {
                    ...(!!this.filters['checkedAt'] && this.filters['checkedAt'] === 'true')  && { 'dataCheckedAt[after]':  oneYearBefore},
                    ...(!!this.filters['checkedAt'] && this.filters['checkedAt'] === 'false') && { 'dataCheckedAt[before]':  oneYearBefore},
                    ...(!!this.filters['_search'] && this.filters['_search'].value && { _search : this.filters['_search'].value })
                }, this.filter);

                if(this.priceType === 'ferry' || this.priceType === 'other'){
                    [
                        'timePeriods.startAt[before]',
                        'timePeriods.endAt[after]',
                    ].forEach(filter => {
                        filters['priceGroups.' + filter] = filters[filter];
                        filters[filter] = '';
                    })
                }

                filters = pickBy(filters, (value => value !== '' && value !== null));



                // Days filter
                ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                    .forEach(dayName => {
                        if (has(filters, dayName)) {
                            if (filters[dayName]) {
                                filters[dayName] = 1
                            } else {
                                delete filters[dayName]
                            }
                        }
                    });

                // Nested objects
                Object.keys(filters).forEach(key => {
                    if (typeof filters[key] === 'object' && !Array.isArray(filters[key])) {
                        Object.keys(filters[key]).forEach(subKey => {
                            filters[`${key}.${subKey}`] = filters[key][subKey];
                        });

                        delete filters[key];
                    }
                });


                return filters;
            },
        },

        methods: {
            handleForm: function (data = {}) {
                this.$emit('openForm', data);
            },

            handleFormByType: function (data = {}) {
                if(this.priceType === 'ferry'){
                    this.$emit('openRouteForm', data);
                } else if(this.priceType === 'train') {
                    this.$emit('openTrainRouteForm', data);
                }else if(this.priceType === 'other') {
                    this.$emit('openOtherServiceForm', data);
                } else {
                    this.$emit('openForm', data);
                }
            },

            deleteRoute: function (ferryRoute) {
                if (confirm('Soll die Fährverbindung wirklich gelöscht werden?')) {
                    deleteFerryRoute(ferryRoute.id)
                        .then(() => {
                            this.getPrices();
                            this.notifySuccess('Die Fährverbindung wurde erfolgreich gelöscht!')
                        }, error => {
                            this.notifyError('Problem! Ferry route is not deleted')
                        })
                }
            },
            deleteTrainRoute: function (trainRoute) {
                if (confirm('Soll die Zugverbindung wirklich gelöscht werden?')) {
                    deleteTrainRoute(trainRoute.id)
                        .then(() => {
                            this.getPrices();
                            this.notifySuccess('Die Zugverbindung wurde erfolgreich gelöscht!')
                        }, error => {
                            this.notifyError('Problem! Train route is not deleted')
                        })
                }
            },

            deleteOtherService: function (otherService) {
                if (confirm('Soll die Zusatzleistung wirklich gelöscht werden?')) {
                    deleteOtherService(otherService.id)
                        .then(() => {
                            this.getPrices();
                            this.notifySuccess('Die Zusatzleistung wurde erfolgreich gelöscht!')
                        }, error => {
                            this.notifyError('Problem! Other Service is not deleted')
                        })
                }
            },

            getPrices: function() {

                if(!this.isLoading) {
                    this.isLoading = true;

                    this.getPricesByType()
                        .then(response => {
                            this.$store.commit('updateData', {
                                [this.priceType + 'ProviderPrices']: response.data
                            })
                            this.showNextRow = response.data.length < 2;
                            if(this.showNextRow){
                                response.data.forEach(level => {
                                    if(!['Direktpreis', 'Direktbuchungen', ''].includes(level.level)){
                                        this.showNextRow = false
                                    }
                                });
                            }
                        }).then(() => {
                        this.isLoading = false;
                    })
                }
            },

            getPricesByType: function() {
                let params = {}

                    if(this.providerType === 'provider'){
                        params = {'provider.id': this.item.id};
                    }
                    if(this.providerType === 'agency'){
                        params = {'agency.id': this.item.id};
                    }
                    if(this.providerType === 'calculation'){
                        if(this.item && this.item.id && this.priceType === 'other'){
                            params = {
                                'provider[exists]': false,
                                'agency[exists]': false,
                                'otherServiceType.id': this.item.id
                            };
                        } else {
                            params = {
                                'provider[exists]': false,
                                'agency[exists]': false
                            };
                        }

                    }
                if(this.priceType === 'hotel'){
                    return getGroupedHotelProviderPrices({
                        ...params,
                        ...this.filterParams,
                        _groups: ['data_check_read'],
                        provider_type: this.providerType,
                        _page: 1,
                        _itemsPerPage: 9999999,
                    })
                }
                if(this.priceType === 'ferry'){
                    if(this.providerType === 'provider'){
                        params = {'ferry.id': this.item.id};
                    }
                    if(this.providerType === 'calculation'){
                        params = {
                            'ferry[exists]': false,
                            'agency[exists]': false
                        };
                    }
                    return getGroupedFerryRoutes({
                        ...params,
                        ...this.filterParams,
                        _groups: ['data_check_read', 'provider_price_group'],
                        provider_type: this.providerType,
                        _page: 1,
                        _itemsPerPage: 9999999,
                    })
                }
                if(this.priceType === 'train'){
                    if(this.providerType === 'provider'){
                        params = {'train.id': this.item.id};
                    }
                    if(this.providerType === 'calculation'){
                        params = {
                            'train[exists]': false,
                            'agency[exists]': false
                        };
                    }
                    return getGroupedTrainRoutes({
                        ...params,
                        ...this.filterParams,
                        _groups: ['data_check_read', 'provider_price_group', 'service_day_time'],
                        provider_type: this.providerType,
                        _page: 1,
                        _itemsPerPage: 9999999,
                    })
                }

                return getGroupedOtherServices({
                    ...params,
                    ...this.filterParams,
                    _groups: ['data_check_read', 'provider_price_group'],
                    provider_type: this.providerType,
                    _page: 1,
                    _itemsPerPage: 9999999,
                })


            },

            updateRow: function(level) {

                const rows = this.groupedRows ;
                const index = rows.findIndex(oldLevel => oldLevel.id === level.id)
                rows.splice(index, 1, {
                    ...level,
                })

                this.item[this.priceType + 'ProviderPrices'] = rows;
            },

            editPriceGroup: function (priceGroup) {
                priceGroup.timePeriods = priceGroup.timePeriods.map(period => { return {...period, ...{days: daysToNumbers(period)}}})
                this.handleForm(priceGroup)
            },

            addPriceGroup: function (object) {
                this.handleForm(object)
            },

            deletePriceGroup: function (priceGroup) {
                if (confirm('Sollen die Preise wirklich gelöscht werden?')) {
                    providerPrices[this.priceType + 'PriceGroups'].delete(priceGroup.id)
                        .then(() => {
                            this.notifySuccess('Die Preise wurden erfolgreich gelöscht!')
                            this.getPrices();
                        }, error => {
                            this.notifyError('Error! Die Preise konnten nicht gelöscht werden!')
                        })
                }
            },
        },

        watch: {
            filters: {
                deep: true,
                handler: function (filters) {
                    //setStorageFilter(`${this.api}/${this.priceType}ProviderPrices`, filters);
                }
            }
        }
    }
</script>
