<template>
    <div class="redesign-table">
        <table-header
            v-bind:item="item"
            v-on:change:section="toggleSection"
            v-on:delete="$emit('delete')"
            v-on:edit="$emit('edit')"
            v-on:copy="v => $emit('copy', v)"
            v-on:reset="$emit('reset')"
        />

        <selling-prices
            v-bind:calculationOrder="item.calculationOrder"
            v-on:editContingentPrice="price => $emit('editContingentPrice', item, price)"
        />
    </div>
</template>

<script>
import TableHeader from '../Header'
import SellingPrices from './SellingPrices'

export default {
    props: {
        item: {
            required: true,
            type: Object,
        },
    },

    methods: {
        toggleSection: function (section) {
            if (section === 'calculation') {
                this.$emit('open')
            }
        }
    },

    components: {
        TableHeader,
        SellingPrices
    }
}
</script>

