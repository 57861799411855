var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bp-panel",
      class: { "is-hover": _vm.isHover },
      on: {
        mouseenter: function ($event) {
          _vm.isHover = true
        },
        mouseleave: function ($event) {
          _vm.isHover = false
        },
        click: function ($event) {
          _vm.isHover = false
        },
      },
    },
    [
      _c("div", { staticClass: "panel-content" }, [
        _c("h3", [_vm._v(_vm._s(_vm.template.title))]),
        _vm._v(" "),
        _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "panel-actions" }, [
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.$emit("add")
              },
            },
          },
          [
            _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
            _vm._v("\n            HINZUFÜGEN\n        "),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }