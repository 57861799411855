<template>
    <div class="panel" v-bind:class="[classNames, !isOpen ? 'is-collapsed' : '']">
        <div class="panel-heading" v-on:click="toggleCollapse(!isOpen)" v-bind:style="headingStyle">
            <span v-if="label">{{label}}</span>
            <div class="panel-label" v-else>
                <slot name="label"></slot>
            </div>
            <div class="panel-buttons">
                <slot name="buttons" />
                <i class="is-pointer material-icons"
                    v-on:click.stop="$emit('save')"
                    v-if="hasSave"
                    v-bind:title="saveTitle"
                >speichern</i>
                <button
                    v-if="hasForm"
                    v-on:click.stop="$emit('add')"
                ><i class="material-icons">add_circle</i></button>
                <i
                    class="collapse-icon material-icons"
                    v-bind:class="{'is-rotated' : !isOpen}"
                    v-if="isCollapsible"
                >keyboard_arrow_up</i>
            </div>

        </div>
        <div class="panel-block">
            <div ref="collapse" class="panel-content"><slot></slot></div>
        </div>

        <slot name="modal"></slot>
    </div>
</template>


<script>
import get from 'lodash/get';
import anime from 'animejs';

export default {
    props: {
        classNames: {
            default: ''
        },

        label: {
            type: String
        },

        open: {
            type: Boolean,
            default: false,
        },

        isCollapsible: {
            type: Boolean,
            default: false
        },

        saveTitle: {
            type: String,
            default: 'speichern'
        }
    },


    data: function () {
        return {
            isOpen: this.isCollapsible ? this.open : true
        }
    },


    computed: {
        headingStyle: function () {
            const color = get(this, '$parent.$parent.color');

            if(!!color) {
                return { 'border-top-color': color }
            }

            return;
        },

        hasForm: function () {
            return !!this.$listeners.add;
        },

        hasSave: function () {
            return !!this.$listeners.save;
        }
    },


    mounted: function () {
        const self = this;
        this.$parent.$on('toggle', function (open) {
            self.toggleCollapse(open);
        });

        if (!this.isOpen) {
            this.collapse(0);
        }
    },


    methods : {
        collapse: function (duration = 400) {
            const targets = this.$refs.collapse;
            this.isOpen = false;

            anime({
                targets,
                duration: duration,
                easing: 'easeOutExpo',
                opacity: [1, 0],
                height: 0,
            });
        },

        expand: function () {
            const targets = this.$refs.collapse;
            const height = targets.scrollHeight;
            this.isOpen = true;

            anime({
                targets,
                duration: 400,
                easing: 'easeOutExpo',
                opacity: [0, 1],
                height,
                complete: function () {
                    targets.removeAttribute('style');
                }
            });
        },

        toggleCollapse: function (open) {
            if (this.isCollapsible) {
                if (open) {
                    this.expand();
                } else {
                    this.collapse();
                }
            }
        }
    }
}
</script>
