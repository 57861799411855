var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "crud-table",
    {
      ref: "table",
      attrs: {
        api: "account_plan_items",
        label: "Buchungskonten",
        "load-options": false,
        filter: { "organisation.id": _vm.item.id },
        "passed-options": _vm.options,
        name: { singular: "Buchungskonto", plural: "Buchungskonten" },
        columns: [
          "name",
          { key: "accountingNumber", label: "Buchungskonto" },
          { key: "isDefaultFor", label: "Voreingestellt für" },
          { key: "translations", label: "Übersetzungen", sortable: false },
        ],
      },
      on: { add: _vm.openForm, edit: _vm.openForm },
    },
    [
      _c("h2", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("KONTENPLAN"),
      ]),
      _vm._v(" "),
      _c("account-plan-form", {
        ref: "form",
        attrs: { slot: "form" },
        on: {
          success: function ($event) {
            return _vm.$refs.table.refresh()
          },
        },
        slot: "form",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }