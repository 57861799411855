var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "level-left" }, [
    _c("i", { staticClass: "material-icons level-item" }, [_vm._v("search")]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.searchConcept,
          expression: "searchConcept",
        },
      ],
      staticClass: "input",
      attrs: { type: "text", placeholder: "Konzept suchen" },
      domProps: { value: _vm.searchConcept },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.searchConcept = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }