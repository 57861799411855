<template>
    <crud-table
        ref="table"
        
        api="day_concepts"
        url="/concepts/days"
        :storeKey="`otherServiceTypes/${item.id}/dayConcepts`"
        
        :filter="{'otherPlaceholders.otherServiceType.id': item.id}"

        label="Stammdaten"
        :name="{singular: 'tageskonzept', plural: 'tageskonzepten'}"

        :columns="[
            { key: 'name', label: 'Name', searchable: true,  class: 'is-one-quarter' },
            { key: 'included', sortable: false, label: 'inkl',  class: 'is-one-fifth' },
            { key: 'optional', sortable: false, label: 'Optional',  class: 'is-one-fifth' },
            { key: 'subtitle',  class: 'is-one-tenth'},
            { key: 'category', filter: 'category'},
            { key: 'destinations', filter: 'destinations.id' },
            { key: 'checkedCatalogText', filter: 'checkedCatalogText', sortable: false, class: 'is-one-twentieth' },
            { key: 'dataCheckedAt', filter: 'checkedAt', class: 'is-one-twentieth' }
        ]"
    >
        <h2 slot="header">Verknüpfungen mit Tageskonzepten</h2>

        <template slot="included" slot-scope="{row}">
            {{ included(row) }}
        </template>
        <template slot="optional" slot-scope="{row}">
            {{ optional(row) }}
        </template>
    </crud-table>
</template>

<script>
import { CrudTable } from '@components/CRUD';
import tab from './tab';

export default {
    mixins: [tab],

    components: {
        CrudTable
    },

    methods: {
        included: function (row) {
            return row.otherPlaceholders
                .filter(placeholder => placeholder.clientOffer && placeholder.clientOffer.status !== 'optional')
                .filter(placeholder => placeholder.otherServiceType)
                .map(placeholder => placeholder.otherServiceType.name)
                .join(', ')
        },


        optional: function (row) {
            return row.otherPlaceholders
                .filter(placeholder => placeholder.clientOffer && placeholder.clientOffer.status === 'optional')
                .filter(placeholder => placeholder.otherServiceType)
                .map(placeholder => placeholder.otherServiceType.name)
                .join(', ')
        },
    }
}
</script>