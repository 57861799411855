<template>
    <span v-if="start && end">
        <template v-if="time(end) !== '00:00'">
            {{ start | time }} - {{ end | time }}
        </template>
        <template v-else-if="time(start) !== '00:00'">
            {{ start | time }}
        </template>
    </span>
</template>



<script>
import { time } from '@utilities/functions';


export default {
    props: {
        start:  { required: true },
        end:    { required: true }
    },


    methods: {
        time
    },


    filters: {
        time
    }
}
</script>
