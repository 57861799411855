<template>
    <section class="section">
        <div class="section-title">
            <span class="h2">
                <span>{{title}}</span>
                <i class="material-icons" v-on:click="showForm = true">add_circle</i>
            </span>
        </div>

        <draggable
            class="columns is-multiline"
            element="div"
            :value="typeProviderTripDetails"
            v-on:input="updateSequence"
            :options="{draggable: '.draggable'}"
        >
            <div
                class="column is-half draggable"
                v-for="(providerTripDetail, index) in typeProviderTripDetails"
                v-bind:key="providerTripDetail.id"
            >
                <item
                    :value="providerTripDetail"
                    v-on:delete="handleDelete(providerTripDetail)"
                    v-on:submit="handleSubmit"
                    v-on:edit="editInfo(providerTripDetail.info)"
                >{{index+1}}</item>
            </div>

            <div class="column is-half">
                <div v-if="!showForm" class="bp-panel is-add" v-on:click="showForm = true">
                    <i class="material-icons">add</i>

                    <div class="panel-actions">
                        <button v-on:click.prevent.stop="addInfo()">
                            <i class="material-icons">map</i>
                            Neuen Hinweis hinzufügen
                        </button>
                    </div>
                </div>

                <item
                    v-else
                    v-on:submit="handleSubmit"
                    v-on:delete="showForm = false"
                    :value="{type}"
                />
            </div>
        </draggable>

        <info-form ref="infoForm" v-on:success="handleInfoSuccess"/>
    </section>
</template>

<script>
import InfoForm from '@components/CRUD/Item/Tabs/Infos/Form'
import tripDetails from '../tripDetails'
import Item from './Item';

export default {
    mixins: [ tripDetails ],
    components: { Item, InfoForm },

    methods: {
        addInfo: function() {
            this.$refs.infoForm.open()
        },

        editInfo: function(info) {
            this.$refs.infoForm.open(info)
        },

        handleInfoSuccess: function({ data, form }) {
            let providerTripDetails = (this.$store.state.data.providerTripDetails || [])
            if(form.id) {
                providerTripDetails = providerTripDetails
                    .map(item => ({
                        ...item,
                        ...item.info && item.info.id === form.id && {
                            info: data,
                            text: data.content,
                            title: data.title,
                            images: data.images,
                        }
                    }))
            } else {
                providerTripDetails = (data.providerTripDetails || []);
            }

            this.$store.commit('updateData', {
                providerTripDetails: providerTripDetails
            });

        },
    }
}
</script>
