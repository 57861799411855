<template>
    <div>
        <div class="row is-headline is-small">
            <div class="is-flex">
                <div class="level level-left mb-0" v-on:click="toggleCollapse">
                    <i
                        v-if="hasPrices"
                        class="collapse-icon material-icons level-item"
                        v-bind:class="{'is-rotated' : !isOpen}"
                    >keyboard_arrow_up</i>
                    <i
                        v-else
                        class="material-icons level-item"
                    >close</i>
                    <span class="level-item">{{name}}</span>
                    <popover trigger="hover" v-if="otherService.otherService.description">
                        <i class="material-icons m-r">info</i>
                        <template slot="popover">
                            <div style="text-align: left">
                                <h3>Beschreibung:</h3>
                                <p v-html="otherService.otherService.description"></p>
                            </div>
                        </template>
                    </popover>
                    <a v-bind:href="`/other_service_types/${id}`" target="_blank" class="level-item"> <i class="material-icons">  link</i> </a>

                </div>


            </div>
            <div>
                <button
                    class="btn-edit"
                    v-on:click="$emit('addPriceGroup')"
                ><i class="fa fa-calendar-plus-o fa-lg"></i></button>
                <button
                    class="btn-edit"
                    v-on:click="$emit('editOtherService')"
                ><i class="material-icons">edit</i></button>
                <button
                    class="btn-delete"
                    v-on:click="$emit('deleteOtherService')"
                ><i class="material-icons">delete</i></button>
            </div>
        </div>

        <template v-if="isOpen">
            <price-group-row
                v-for="priceGroup in groups"
                v-bind:key="priceGroup.id"
                v-bind:priceGroup="priceGroup"
                v-bind:options="options"
                v-bind:show-next-row="otherService.groups.length < 2"
                price-type="other"

                v-on:updatePriceGroup="updatePriceGroup"

                v-on:editPriceGroup="$emit('editPriceGroup', priceGroup)"
                v-on:deletePriceGroup="$emit('deletePriceGroup', priceGroup)"
            >

        </price-group-row>
        </template>

    </div>
</template>

<script>

import PriceGroupRow from './PriceGroup';

import Mixins from "./mixins";
import Template from "@orders/subpages/TripDetails/General/Template";
import Popover from "@components/Popover";
import {compareDates, fullYear} from "@utilities/functions";

export default {
    mixins: [Mixins],

    props: {
        otherService: {
            type: Object,
            required: true
        },

        options: {
            type: Object,
        }
    },

    methods: {
        addPrice: function() {

        },



        updatePriceGroup: function(group) {
            const otherService = this.otherService;
            const index = otherService.groups.findIndex(oldGroup => oldGroup.id === group.id)
            otherService.groups.splice(index, 1, {
                ...group,
            })

            this.$emit('updateOtherService', otherService)
        }
    },

    computed: {
        name: function() {
            const otherServiceType = this.otherService.otherService.otherServiceType;
            const addition = this.otherService.otherService.subtitle ? ' - ' + this.otherService.otherService.subtitle : '';
            return otherServiceType + addition;
        },
        id: function() {
            return this.otherService.otherService.otherServiceTypeId;
        },

        hasPrices: function() {
            return this.otherService.groups.length > 0
        },

        groups: function () {
            return this.otherService.groups;
        },
    },

    components: {
        Template,
        PriceGroupRow,
        Popover
    },

    filters: {
        time: function(string) {
            return string.substring(0, 5);
        }
    }
}
</script>
