var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "bp-box is-price" }, [
          _c("header", [
            _vm._m(0),
            _vm._v(" "),
            _c("div", [
              _c("i", { staticClass: "material-icons" }, [
                _vm._v("local_dining"),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.getValueWithKey({
                      key: _vm.request.board,
                      optionList: _vm.boardList,
                    })
                  )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "main",
            [
              _c(
                "div",
                { staticClass: "is-standard calculation" },
                [
                  _c("div", [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.getAccommodationLabel(
                            _vm.standardRoom.type.type,
                            _vm.standardRoom.type.persons,
                            _vm.roomTypes
                          )
                        ) +
                        "\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("input-price", {
                    attrs: {
                      "provider-prices": _vm.standardRoom.providerPrices,
                      "small-input": true,
                      "price-currency": _vm.request.requestCurrency,
                    },
                    model: {
                      value: _vm.standardPrice,
                      callback: function ($$v) {
                        _vm.standardPrice = $$v
                      },
                      expression: "standardPrice",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.doubleRooms, function (accomodation) {
                return _c(
                  "div",
                  { key: accomodation.id, staticClass: "calculation" },
                  [
                    _c("div", [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.getAccommodationLabel(
                              accomodation.type.type,
                              accomodation.type.persons,
                              _vm.roomTypes
                            )
                          ) +
                          "\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("input-price", {
                      attrs: {
                        "small-input": true,
                        value: _vm.getDifference(accomodation),
                        "provider-prices": accomodation.providerPrices,
                        "price-currency": _vm.request.requestCurrency,
                      },
                      on: {
                        input: (v) => {
                          _vm.updatePrices(v, accomodation, "doubleRooms")
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "bp-box is-price calculation" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "main",
            _vm._l(_vm.singleRooms, function (accomodation) {
              return _c(
                "div",
                { key: accomodation.id },
                [
                  _c("div", [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.getAccommodationLabel(
                            accomodation.type.type,
                            accomodation.type.persons,
                            _vm.roomTypes
                          )
                        ) +
                        "\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("input-price", {
                    attrs: {
                      "small-input": true,
                      "provider-prices": accomodation.providerPrices,
                      value: _vm.getDifference(accomodation),
                      "price-currency": _vm.request.requestCurrency,
                    },
                    on: {
                      input: (v) => {
                        _vm.updatePrices(v, accomodation, "singleRooms")
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "bp-box is-price calculation" }, [
          _vm._m(2),
          _vm._v(" "),
          _c(
            "main",
            _vm._l(_vm.otherRooms, function (accomodation) {
              return _c(
                "div",
                { key: accomodation.id },
                [
                  _c("div", [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.getAccommodationLabel(
                            accomodation.type.type,
                            accomodation.type.persons,
                            _vm.roomTypes
                          )
                        ) +
                        "\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("input-price", {
                    attrs: {
                      "small-input": true,
                      "provider-prices": accomodation.providerPrices,
                      value: _vm.getDifference(accomodation),
                      "price-currency": _vm.request.requestCurrency,
                    },
                    on: {
                      input: (v) => {
                        _vm.updatePrices(v, accomodation, "otherRooms")
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column is-one-third" },
        [
          _c("free-places", {
            attrs: { request: _vm.request },
            model: {
              value: _vm.request.freePlaces,
              callback: function ($$v) {
                _vm.$set(_vm.request, "freePlaces", $$v)
              },
              expression: "request.freePlaces",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c("item-prices", {
            attrs: { provider: _vm.provider },
            model: {
              value: _vm.request.itemPrices,
              callback: function ($$v) {
                _vm.$set(_vm.request, "itemPrices", $$v)
              },
              expression: "request.itemPrices",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "material-icons no-spacing" }, [
        _vm._v("local_hotel"),
      ]),
      _c("i", { staticClass: "material-icons" }, [_vm._v("local_hotel")]),
      _vm._v(" "),
      _c("b", [_vm._v("Doppelzimmer")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons" }, [_vm._v("local_hotel")]),
        _vm._v(" "),
        _c("b", [_vm._v("Einzelzimmer")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons no-spacing" }, [
          _vm._v("local_hotel"),
        ]),
        _c("i", { staticClass: "material-icons no-spacing" }, [
          _vm._v("local_hotel"),
        ]),
        _c("i", { staticClass: "material-icons" }, [_vm._v("local_hotel")]),
        _vm._v(" "),
        _c("b", [_vm._v("Mehrbettzimmer")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }