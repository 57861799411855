<template>
    <columns property="clientDates" v-on:add="openForm"  v-bind:loadOptions="false">
        <column-item
            :api="'/api/client_dates/' + item.id"
            v-bind:update="{updatedBy: item.updatedBy, updatedAt: item.updatedAt}"
            slot-scope="{item}"
            v-bind:label="label(item)"
            v-on:edit="openForm(item)"
            v-on:delete="deleteItem('clientDates', item)"
        >
            <ul>
                <li v-if="item.name"><strong>{{item.name}}</strong></li>
                <li v-if="item.description" v-html="item.description"></li>
            </ul>
        </column-item>

        <client-date-form ref="form" slot="form" />
    </columns>
</template>

<script>
import Mixin from '../tab';
import ClientDateForm from './Form';
import { fullYear } from '@utilities/functions';

export default {
    mixins: [ Mixin ],

    components: { ClientDateForm },

    methods: {
        label: function (item) {
            return `${fullYear(item.startAt)} - ${fullYear(item.endAt)}`
        }
    }
}
</script>
