var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.itemPrices.length > 0
    ? _c(
        "div",
        { staticClass: "is-light" },
        _vm._l(_vm.itemPrices, function (itemPrice) {
          return _c("item-price", {
            key: itemPrice.id,
            attrs: { price: itemPrice },
            on: {
              unlink: function ($event) {
                return _vm.$emit("unlink")
              },
            },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }