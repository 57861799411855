<template>
    <div>
        <row class="is-head" >
            <template slot="type">Art</template>
            <template slot="name">Leistung</template>
            <template slot="marginTax">Margensteuer ({{this.calculationOrder.orderCurrency}})</template>
            <template slot="buyPriceCalculation" v-if="hasTwoCurrencies">EK ({{this.calculationOrder.calculationCurrency}})</template>
            <template slot="buyPrice">{{hasTwoCurrencies ? 'EK (' + this.calculationOrder.orderCurrency + ')' : 'Einkaufspreis'}}</template>
            <template slot="sellPriceCalculation" v-if="hasTwoCurrencies">VK ({{this.calculationOrder.calculationCurrency}})</template>
            <template slot="sellPrice">{{hasTwoCurrencies ? 'VK (' + this.calculationOrder.orderCurrency + ')' : 'Verkaufspreis'}}</template>
            <template slot="profitCalculation" v-if="hasTwoCurrencies">GS ({{this.calculationOrder.calculationCurrency}})</template>
            <template slot="profit">{{hasTwoCurrencies ? 'GS (' + this.calculationOrder.orderCurrency + ')' : 'Gewinnspanne'}}</template>
            <template slot="margin">Marge (%)</template>
            <template slot="actions">
               <button
                    class="btn-single-toggle level-item"
                    v-bind:class="{'is-active': marginsEnabled}"
                    v-on:click   = "marginsEnabled = !marginsEnabled"
                    v-bind:title = "marginsEnabled ? 'Margensteuer deaktivieren' : 'Margensteuer aktivieren'"
                >
                    <i class="fa fa-percent"></i>
                </button>

            </template>
        </row>

        <template v-if="doublePrices.length > 0 || singlePrices.length > 0">
            <package-price
                v-for="price in doublePrices"
                v-bind:key="price.id"
                :has-two-currencies="hasTwoCurrencies"
                :calculation-to-order-exchange-rate="calculationToOrderExchangeRate"
                v-bind:price="price"
                v-on:update:price="handlePriceUpdate"
            />

            <package-price
                v-for="price in singlePrices"
                v-bind:key="price.id"
                :has-two-currencies="hasTwoCurrencies"
                :calculation-to-order-exchange-rate="calculationToOrderExchangeRate"
                v-bind:price="price"
                v-on:update:price="handlePriceUpdate"
            />
        </template>
    </div>
</template>

<script>
import { notifySuccess, notifyError } from '@components/Notification';
import { Row } from '@orders/subpages/Calculations/components';
import PackagePrice from './PackagePrice'
import { editCalculationContingentPrice } from '@api'

export default {
    props: {
        calculationOrder: {
            type: Object,
            required: true
        }
    },

    data: function () {
        return {
            contingentPrices: [],
            marginTax: this.calculationOrder.calculation.marginTax,
        }
    },

    mounted: function () {
        this.contingentPrices = this.calculationOrder.calculation.contingentPrices;
    },

    computed: {
        marginsEnabled: {
            get() { return this.marginTax },
            set(v) {
                this.marginTax = v;
                this.contingentPrices
                    .map(price => {
                        let newPrice = {
                            ...price,
                            marginTax: v
                        }
                        this.handlePriceUpdate(newPrice);
                        //editCalculation()
                    })
            }
        },

        calculationToOrderExchangeRate: function () {
            let rate = this.calculationOrder.calculation.rates.find(rate =>
                rate.fromCurrencyCode === this.calculationOrder.calculationCurrency &&
                rate.toCurrencyCode === this.calculationOrder.orderCurrency
            );

            return rate ? rate.rate : 1;
        },

        hasTwoCurrencies: function () {
            return this.calculationOrder.orderCurrency !== this.calculationOrder.calculationCurrency;
        },

        packagePrices: function () {
            return this.contingentPrices
                .filter(price => price.orderHotelRoom)
                .filter(price => {
                    const { type, persons } = price.orderHotelRoom.type;
                    return type === 'standard' && [1,2].includes(persons);
                })
        },

        doublePrices: function () {
            return [...this.packagePrices]
                .filter(price => price.orderHotelRoom.type.persons === 2)
                .sort((a,b) => {
                    if (a.numberOfPersons === null) return 1;
                    return a.numberOfPersons < b.numberOfPersons ? -1 : 1;
                })
        },

        singlePrices: function () {
            return [...this.packagePrices]
                .filter(price => price.orderHotelRoom.type.persons === 1)
                .sort((a,b) => {
                    if (a.numberOfPersons === null) return 1;
                    return a.numberOfPersons < b.numberOfPersons ? -1 : 1;
                })
        },
    },

    methods: {
        handlePriceUpdate(newPrice) {
            this.contingentPrices = this.contingentPrices
                .map(price => ({
                    ...price,
                    ...newPrice.id === price.id && {
                        sellPrice: newPrice.sellPrice,
                        buyPrice: newPrice.buyPrice,
                        marginTax: newPrice.marginTax
                    }
                }))

            editCalculationContingentPrice({
                id: newPrice.id,
                data: {
                    sellPrice: {
                        amount: newPrice.sellPrice.amount.toString(),
                        currency: newPrice.sellPrice.currency,
                    },
                    marginTax: newPrice.marginTax,
                }
            }).then(() => {
                this.$emit('editContingentPrice', newPrice);

                notifySuccess('Price updated successfully!')}
            , error => notifyError('Something went wrong'))
        }
    },

    components: {
        PackagePrice,
        Row,
    }
}
</script>
