import Draggable from 'vuedraggable';
import { notifySuccess, notifyError } from '@components/Notification';
import { editProviderTripDetailSequence } from '@api'
import { store } from '@components/CRUD/Item/store';


export default {
    store,

    components: {
        Draggable
    },

    props: {
        type: {
            type: String,
            default: 'provider_info'
        },
    },

    data: function () {
        return {
            showForm: false,
        }
    },

    computed: {
        item: function () { return this.$store.state.data },
        providerTripDetails: function () { return this.item.providerTripDetails },

        typeProviderTripDetails: function () {
            const providerTripDetails = this.providerTripDetails.filter(providerTripDetail => providerTripDetail.type === this.type)
            return [...providerTripDetails].sort((a,b) => a.positionInDocument > b.positionInDocument ? 1 : -1)
        },

        title: function () {
            return {
                'box_map': 'HINWEIS',
                'box': 'HINWEISBOX',
                'provider_info': 'TABELLARISCHE INFORMATIONEN'
            }[this.type]
        },
    },

    methods: {
        updateSequence: function (items) {
            return new Promise((resolve, reject) => {
                const sequence = items.map(item => item.id);

                this.$store.commit('updateSequence', {key: 'providerTripDetails', sequence})

                editProviderTripDetailSequence(sequence)
                    .then(response => {
                        notifySuccess('Order updated')
                        resolve(response)
                    }, error => {
                        notifyError('Error while saving order, please try again')
                        reject(error)
                    })
            })
        },

        maybeUpdateSequence: function () {
            return new Promise((resolve, reject) => {
                const invalidPositions = this.typeProviderTripDetails
                    .filter(providerTripDetail => providerTripDetail.positionInDocument === null || providerTripDetail.positionInDocument === 'undefined')

                if (invalidPositions.length > 0){
                    this.updateSequence(this.typeProviderTripDetails)
                        .then(resolve, reject)
                } else {
                    resolve()
                }
            })
        },

        handleDelete: function (item) {
            this.$emit('delete', item);
        },

        handleSubmit: function (data) {
            this.$store.dispatch('updateProperty', {
                key: 'providerTripDetails',
                data: {
                    ...data,
                    provider: {
                        id: this.item.id
                    }
                }
            })
            this.showForm = false;
        }
    }
}
