import Vuex from 'vuex';


export default new Vuex.Store({
    state: {
        placeholdersSelected:      [],
        /* We have 2 types of actions
                1. Modification (null, hiking, bus, ...)
                2. Non-Modification (false)
        */
        placeholdersGroupToUpdate: false,
        day: {}
    },


    mutations: {
        togglePlaceholder: function (state, placeholder) {
            const index = state.placeholdersSelected.findIndex(item => item.sort === placeholder.sort);

            if (index !== -1) {
                state.placeholdersSelected.splice(index, 1);

            } else {
                state.placeholdersSelected.push(placeholder);
            }
        },

        updateDay: function (state, day) {
            state.day = day;
        },


        placeholdersGroupToUpdate: function (state, group) {
            state.placeholdersGroupToUpdate = group;
        }
    }
});
