<template>
    <div>
        <div class="bp-panel is-half pt-5" v-for="row in rows">
            <div v-for="col in row.cols" class="column is-flex is-justify-content-space-between py-0" :class="col.columnClass" :style="col.columnStyle">
                <div class="is-flex mb-2">
                    <span class="my-0 is-align-self-center">{{col.label}}</span>
                </div>
                <div class="column is-7 mb-2 py-0">
                    <component
                        :is="col.component.type"
                        :component="col.component"
                        :value="getItem(col.label)"
                        :key="col.label"
                        @input="saveItem(col, $event)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Mixin from '../../tab';
import {notifyError, notifySuccess} from "@components/Notification";
import FormElements      from '@components/form'
import axios from "axios";
import {edit, post} from "@api";

export default {
    mixins: [ Mixin ],
    computed: {
        api: function() {
            return this.$store.state.api;
        },

        apiURL: function() {
            const id = this.$store.state.id;
            return `/api/${this.api}/${id}`
        },

        rows: function () {
            return [
                {
                    cols: [
                        {
                            label: 'Veröffentlichungstermine Kataloge:',
                            columnClass: '',
                            columnStyle: {},
                            component: {
                                type: 'TextInput'
                            }
                        },
                        {
                            label: 'Termine Hausmessen',
                            columnClass: '',
                            columnStyle: {},
                            component: {
                                type: 'TextInput'
                            }
                        },
                        {
                            label: 'Preisstruktur',
                            columnClass: '',
                            columnStyle: {},
                            component: {
                                type: 'TextInput'
                            }
                        },
                        {
                            label: 'Portfolio',
                            columnClass: '',
                            columnStyle: {},
                            component: {
                                type: 'TextInput'
                            }
                        },
                        {
                            label: 'Zielgruppe',
                            columnClass: '',
                            columnStyle: {},
                            component: {
                                type: 'TextInput'
                            }
                        },
                        {
                            label: 'Einzugsgebiet',
                            columnClass: '',
                            columnStyle: {},
                            component: {
                                type: 'TextInput'
                            }
                        },
                        {
                            label: 'Distributionskanäle',
                            columnClass: '',
                            columnStyle: {},
                            component: {
                                type: 'TextInput'
                            }
                        },
                        {
                            label: 'Wettbewerber',
                            columnClass: '',
                            columnStyle: {},
                            component: {
                                type: 'TextInput'
                            }
                        },
                        {
                            label: 'Aktuelle Situation',
                            columnClass: '',
                            columnStyle: {},
                            component: {
                                type: 'TextInput'
                            }
                        },
                        {
                            label: 'Anforderungen an Paketer',
                            columnClass: '',
                            columnStyle: {},
                            component: {
                                type: 'TextInput'
                            }
                        },
                    ],
                },
            ]
        },
        getTableInfos: function() {return this.item.tableInfos}
    },
    methods: {
        getItem: function (property) {
            let item = this.getTableInfos.find(item => item.property === property);
            if (item) {
                return item.value
            }
            else {
                post('table_infos',{
                    property: property,
                    client: this.apiURL,
                    value: ''
                }, {_groups: ['table_info:client']});
            }
        },
        saveItem: function(col, event) {
            let item = this.getTableInfos.find(item => item.property === col.label);
            if (item) {
                edit('table_infos/'+item.id, {
                    value: event
                }).then(res => {
                    notifySuccess('Die Daten wurden gespeichert!')
                }, error => {
                    notifyError("Die Daten konnten nicht gespeichert werden! Error happened")
                })
            }
        },
    },
    components: {
        ...FormElements
    },

}
</script>
