<template>
    <modal-form
        editTitle="Buchungskonto bearbeiten"
        createTitle="Neues Buchungskonto hinzufügen"
    >
        <table>
            <tr>
                <th><label for="name" class="label">Bezeichnung:</label></th>
                <td>
                    <div class="control">
                        <input type="text" class="input" v-model="form.name" id="name">
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="accountingNumber" class="label">Buchungskonto:</label></th>
                <td>
                    <div class="control">
                        <input type="text" class="input" v-model="form.accountingNumber" id="accountingNumber">
                    </div>
                </td>
            </tr>
            <tr>
                 <th>
                     <label class="label">Voreingestllt für:</label>
                 </th>
                 <td>
                     <multiselect
                         v-model="isDefaultFor"
                         :options="options.defaultList"
                         multiple
                         track-by="key"
                         label="value"
                     />
                 </td>
             </tr>
            <translations-field v-model="locale" :translations="form.translations" :translation-options="translationOptions" v-on:changeTranslation="changeTranslation"></translations-field>

        </table>
    </modal-form>
</template>



<script>

import TabForm from '../form';
import Mixin from '../tab';
import includes from 'lodash/includes';
import {translationsMixin, translationsField} from "@utilities/mixins";
import {addAccountPlanItem} from "@api";


export default {
    components: {translationsField},
    mixins: [TabForm, Mixin, translationsMixin],

    data : function() {
        return {
        }
    },
    form: {
        name: '',
        isDefaultFor: [],
        accountingNumber: '',
        organisation: null,
        translations: {}
    },
    property: 'accountPlanItems',

    methods: {
        openCallback: function() {
              this.form.organisation = this.apiURL;
            this.locale = this.defaultTranslation;

            if(!this.form.translations || Object.keys(this.form.translations).length === 0){
                this.form.translations = Object.assign({}, {   [this.defaultTranslation] : {}     });
                //this.form.translations.push({   [this.defaultTranslation] : {}     })
            }
        },


        update: function () {
            return this.$store.dispatch('updateProperty', {
                key: this.$options.property,
                data: this.form,
                locale: this.locale,
            })
        },

        create: function () {


            return new Promise((resolve, reject) => {
                addAccountPlanItem(this.form, {
                    _groups: ['translations', 'account_plan_item:organisation']
                }).then(response => {
                    this.$store.commit('updateDataElement', { key: this.$options.property, data: response.data, isNew: true });
                    resolve(response.data);
                })
            });


        }
    },

    computed: {
        defaultList: function() {
            return this.options && this.options.defaultList ? this.options.defaultList : [];
        },

        isDefaultFor: {
            get: function() {
                return this.defaultList.filter(item => includes(this.form.isDefaultFor, item.key));
            },
            set: function(values) {
                this.form.isDefaultFor = values.map(item => item.key);
            }
        },

        api: function() {
            return this.$store.state.api;
        },

        apiURL: function() {
            const id = this.$store.state.id;
            return `/api/${this.api}/${id}`
        },
    },



}
</script>
