<template>
    <div class="is-truncated is-250">
        <a v-if="agency" v-bind:href="'/agencies/' + agency.id" v-bind:title="agency.name" target="_blank">{{ agency.name }}</a>

        <template v-if="agency && agency.id && provider && provider.id || !agency && !provider">-</template>

        <a v-if="provider && provider.id" v-bind:href="provider.api" v-bind:title="provider.name" target="_blank">{{ provider.name }}</a>
    </div>
</template>



<script>
export default {
    props: {
        agency:      { required: true },
        provider:    { required: true }
    },


    computed: {

    }
}
</script>
