<template>

    <div class="has-margin-top">
        <div class="bp-panel is-form has-no-title" style="min-height: auto">
            <div class="panel-content is-table-form">
                <table>
                    <template v-for="row in component.props.rows">
                            <tr>
                                <template v-for="field in row.fields">
                                    <th v-if="field.type === 'label'">
                                        <label class="label">{{field.label}}:</label>
                                    </th>
                                    <td v-if="field.type === 'multiselect'">
                                        <div class="control">
                                            <multiselect
                                                v-model="item[field.property]"
                                                v-bind:options="field.options"
                                                @input="saveItem(field)"
                                                :multiple="field.multiple"
                                            />
                                        </div>
                                    </td>
                                </template>
                            </tr>
                    </template>
                </table>
            </div>
        </div>
    </div>


</template>

<script>

import Mixin from '../../tab';
import {Multiselect} from "@components/form";


export default {
    mixins: [ Mixin ],

    props: {
        component: {
            type: Object,
            required: true
        }
    },

    methods: {
        saveItem: function(field) {
            this.$emit('saveItem', field.property, this.item[field.property])
        },
    },

    computed: {},

    components: {
        Multiselect,
    },
}
</script>
