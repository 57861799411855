import debounce from 'lodash/debounce'
import { store } from '@components/CRUD/Item/store'

export default {
    store,

    props: {
        value: {
            type: Object,
        },
    },

    computed: {
        isForm: function () { return !this.form.id },
        provider: function () { return this.$store.state.data; },
        providerTripDetails: function () { return this.provider.providerTripDetails; },
        templates: function () {
            // return !!this.form.type ? this.provider.infos.filter(info => info.type === this.form.type) : [] // https://buspaket.atlassian.net/browse/TRAV-3571
            return this.provider.infos.filter(({ id }) => {
                return !this.providerTripDetails.some(tripDetail => tripDetail.info && tripDetail.info.id === id);
            });
        },
        readonly: function () { return this.value.isOnlyEditableInOrder; }
    },

    data: function () {
        return {
            form: {
                title: '',
                text: '',
                type: null,
                image: null,
                info: null,
                ...this.value
            }
        }
    },

    methods: {
        fillDetail: function (info) {
            this.form.title = info.title;
            this.form.text = info.content;
        },

        handleFormSubmit: debounce(function (obj) {
            this.$emit('submit', {
                ...this.form,
                ...obj
            })
        }, 1000),

        debounceFormSubmit: debounce(function (obj) {
            this.handleFormSubmit(obj)
        }, 1000)
    },

    watch: {
        'form.image': function (image) {
            this.$emit('submit', {
                ...this.form,
                image
            })
        },
        'form.title': function (newValue) {
            if (!this.isForm) {
                this.debounceFormSubmit({ title: newValue });
            }
        },
        'form.text': function (newValue) {
            if (!this.isForm) {
                this.debounceFormSubmit({ text: newValue })
            }
        },
        'form.info': function (newValue) {
            this.handleFormSubmit({ info: newValue })
        }
    },
}
