<template>
    
</template>

<script>
import mixin from './mixin';
export default {
    mixins: [mixin]
}
</script>

